import React from 'react';
import { Route, Switch } from 'react-router-dom';
import AccessControl from '../../AccessControl';
import { PERMISSIONS_KEY, ROUTES } from '../../common/constants';
import './exports.less';
import Exports from './pages/Exports';

const ExportsWrapper = () => (
  <Switch>
    <Route
      exact
      path={`${ROUTES?.EXPORTS}`}
      render={(props) => (
        <AccessControl permissionKey={PERMISSIONS_KEY?.EXPORTS}>
          <Exports {...props} />
        </AccessControl>
      )}
    />
  </Switch>
);

export default ExportsWrapper;
