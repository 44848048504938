import { useMutation } from '@apollo/client';
import { Button, Card, Form } from 'antd';
import React, { useContext, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { AppContext } from '../../../../../AppContext';
import { ROUTES } from '../../../../../common/constants';
import GoBackButton from '../../../../../components/GoBackButton';
import Portal from '../../../../../components/Portal';
import GiftCardForm from '../components/GiftCardForm';
import { CREATE_GIFT_CARD } from '../graphql/Mutations';

const CreateGiftCard = () => {
  const history = useHistory();
  const [form] = Form.useForm();
  const { dispatch } = useContext(AppContext);
  const [isDisabled, setIsDisabled] = useState(true);
  const [isSubmitLoading, setIsSubmitLoading] = useState(false);

  const [createGiftCard] = useMutation(CREATE_GIFT_CARD, {
    onError: () => {},
  });

  const createGiftCardFun = async (planDetails) => {
    setIsSubmitLoading(true);
    dispatch({
      type: 'SET_SHOW_PROMPT',
      data: false,
    });
    const response = await createGiftCard({
      variables: {
        data: {
          subscriptionPlanId: planDetails?.plan,
          notes: planDetails?.notes?.trim(),
          expiredAt: planDetails?.expiresOn?.endOf('day'),
          startedAt: planDetails?.startsOn,
        },
      },
    });
    if (response?.data) {
      history?.replace(`${ROUTES?.OFFERS}${ROUTES?.GIFT_CARD}`);
    }
    setIsSubmitLoading(false);
  };

  const handleShowPrompt = () => {
    setIsDisabled(false);
    dispatch({
      type: 'SET_SHOW_PROMPT',
      data: true,
    });
  };

  return (
    <>
      <Portal portalId="header-title-content">
        <GoBackButton customLink={`${ROUTES?.OFFERS}${ROUTES?.GIFT_CARD}`} />
        <div className="portal-header">Add New Gift Card</div>
      </Portal>
      <Portal portalId="header-right-content">
        <div className="header-btn-wrapper">
          <Button
            type="primary"
            className="common-button mr-16"
            size="middle"
            onClick={() =>
              history?.push(`${ROUTES?.OFFERS}${ROUTES?.GIFT_CARD}`)
            }
          >
            Cancel
          </Button>
          <Button
            className="common-button"
            size="middle"
            onClick={form.submit}
            loading={isSubmitLoading}
            disabled={isDisabled}
          >
            Save
          </Button>
        </div>
      </Portal>
      <Portal portalId="header-right-content-phones">
        <div className="header-btn-wrapper">
          <Button
            type="primary"
            className="common-button mr-16"
            size="middle"
            onClick={() =>
              history?.push(`${ROUTES?.OFFERS}${ROUTES?.GIFT_CARD}`)
            }
          >
            Cancel
          </Button>
          <Button
            className="common-button"
            size="middle"
            onClick={form.submit}
            loading={isSubmitLoading}
            disabled={isDisabled}
          >
            Save
          </Button>
        </div>
      </Portal>
      <Card className="full-height-with-nav" title="Gift Card Details">
        <div className="toy-form">
          <GiftCardForm
            form={form}
            onFinish={createGiftCardFun}
            handleShowPrompt={handleShowPrompt}
          />
        </div>
      </Card>
    </>
  );
};

export default CreateGiftCard;
