import { Button } from 'antd';
import React, { useContext, useState } from 'react';
import { AppContext } from '../../../../../AppContext';
import {
  MODULES,
  PERMISSIONS_KEY,
  PERMISSION_TYPE,
} from '../../../../../common/constants';
import { hasPermission } from '../../../../../common/utils';
import Portal from '../../../../../components/Portal';
import LibraryTable from '../components/LibraryTable';
import LibraryTabs from '../components/LibraryTabs';

const Library = () => {
  const {
    state: { permissions },
  } = useContext(AppContext);
  const [isInvoiceModalOpen, setIsInvoiceModalOpen] = useState(false);
  const [isEditMode, setIsEditMode] = useState(false);

  return (
    <>
      <Portal portalId="header-title-content">
        <div className="portal-header">{MODULES?.LIBRARY}</div>
      </Portal>
      <Portal portalId="header-right-content">
        {hasPermission(
          PERMISSIONS_KEY?.PAYMENTS_LIBRARY,
          PERMISSION_TYPE?.CREATE,
          permissions,
        ) && (
          <Button
            className="common-button"
            size="middle"
            onClick={() => {
              setIsEditMode(false);
              setIsInvoiceModalOpen(true);
            }}
          >
            Upload Invoice
          </Button>
        )}
      </Portal>
      <Portal portalId="header-right-content-phones">
        {hasPermission(
          PERMISSIONS_KEY?.PAYMENTS_LIBRARY,
          PERMISSION_TYPE?.CREATE,
          permissions,
        ) && (
          <Button
            className="common-button"
            size="middle"
            onClick={() => {
              setIsEditMode(false);
              setIsInvoiceModalOpen(true);
            }}
          >
            Upload Invoice
          </Button>
        )}
      </Portal>
      <LibraryTabs>
        <div className="tab-table">
          <LibraryTable
            isInvoiceModalOpen={isInvoiceModalOpen}
            setIsInvoiceModalOpen={setIsInvoiceModalOpen}
            isEditMode={isEditMode}
            setIsEditMode={setIsEditMode}
          />
        </div>
      </LibraryTabs>
    </>
  );
};

export default Library;
