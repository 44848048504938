import { ExportOutlined, PlusOutlined } from '@ant-design/icons';
import { useMutation } from '@apollo/client';
import { Button, Card, Form } from 'antd';
import React, { useContext, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { AppContext } from '../../../../../AppContext';
import {
  EXPORT_TYPES,
  MODULES,
  PERMISSIONS_KEY,
  PERMISSION_TYPE,
  ROUTES,
  USER_ROLES,
} from '../../../../../common/constants';
import { hasPermission } from '../../../../../common/utils';
import ExportDateRangeModal from '../../../../../components/ExportDateRangeModal';
import Portal from '../../../../../components/Portal';
import { REQUEST_USER_EXPORT } from '../../../graphql/Mutations';
import AdminsTable from '../components/AdminsTable';

const roleFilter = [
  USER_ROLES?.POWER_ADMIN?.key,
  USER_ROLES?.BASIC_ADMIN?.key,
  USER_ROLES?.ORDER_INVENTORY_ADMIN?.key,
];

const Admins = () => {
  const {
    state: { permissions },
  } = useContext(AppContext);
  const history = useHistory();
  const [form] = Form.useForm();
  const [exportFilter, setExportFilter] = useState({ role: [] });
  const [isExportLoading, setIsExportLoading] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const [requestUserExport] = useMutation(REQUEST_USER_EXPORT, {
    onError: () => {},
  });

  const handleExport = async (values) => {
    setIsExportLoading(true);
    const res = await requestUserExport({
      variables: {
        data: {
          type: EXPORT_TYPES?.ADMIN,
        },
        filter: {
          search: exportFilter?.search,
          roles:
            exportFilter?.role?.length > 0 ? exportFilter?.role : roleFilter,
          dateRange: {
            from: values?.date?.[0]?.startOf('day'),
            to: values?.date?.[1]?.endOf('day'),
          },
          isActive: exportFilter?.status,
          isArchived: exportFilter?.isArchived,
        },
      },
    });
    if (res?.data) {
      setIsModalOpen(false);
      form?.resetFields();
    }
    setIsExportLoading(false);
  };

  return (
    <>
      <Portal portalId="header-title-content">
        <div className="portal-header">{MODULES?.ADMINS}</div>
      </Portal>
      <Portal portalId="header-right-content">
        {hasPermission(
          PERMISSIONS_KEY?.SYSTEM_USERS_ADMINS,
          PERMISSION_TYPE?.CREATE,
          permissions,
        ) && (
          <Button
            className="common-button"
            size="middle"
            icon={<PlusOutlined />}
            onClick={() =>
              history?.push(`${ROUTES?.SYSTEM_USERS}${ROUTES?.ADMINS}/add`)
            }
          >
            Add New Admin
          </Button>
        )}
        <Button
          className="common-button ml-16"
          size="middle"
          icon={<ExportOutlined />}
          onClick={() => setIsModalOpen(true)}
          loading={isExportLoading}
        >
          Export
        </Button>
      </Portal>
      <Portal portalId="header-right-content-phones">
        {hasPermission(
          PERMISSIONS_KEY?.SYSTEM_USERS_ADMINS,
          PERMISSION_TYPE?.CREATE,
          permissions,
        ) && (
          <Button
            className="common-button"
            size="middle"
            icon={<PlusOutlined />}
            onClick={() =>
              history?.push(`${ROUTES?.SYSTEM_USERS}${ROUTES?.ADMINS}/add`)
            }
          >
            Add New Admin
          </Button>
        )}
        <Button
          className="common-button ml-16"
          size="middle"
          icon={<ExportOutlined />}
          onClick={() => setIsModalOpen(true)}
          loading={isExportLoading}
        >
          Export
        </Button>
      </Portal>
      <ExportDateRangeModal
        form={form}
        onFinish={handleExport}
        loadings={isExportLoading}
        isModalOpen={isModalOpen}
        setIsModalOpen={setIsModalOpen}
      />
      <Card className="full-height-without-nav">
        <AdminsTable
          exportFilter={exportFilter}
          setExportFilter={setExportFilter}
        />
      </Card>
    </>
  );
};

export default Admins;
