import { ExportOutlined } from '@ant-design/icons';
import { useMutation } from '@apollo/client';
import { Button, Form } from 'antd';
import React, { useState } from 'react';
import { EXPORT_TYPES, MODULES } from '../../../../../common/constants';
import ExportDateRangeModal from '../../../../../components/ExportDateRangeModal';
import Portal from '../../../../../components/Portal';
import ReturnTable from '../components/NewReturnTable';
import NewTabs from '../components/NewTabs';
import { REQUEST_NEW_RETURN_ORDER_EXPORT_ADMIN } from '../graphql/Mutations';

const NewReturn = () => {
  const [form] = Form.useForm();
  const [isExportLoading, setIsExportLoading] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const [requestNewReturnOrderExportAdmin] = useMutation(
    REQUEST_NEW_RETURN_ORDER_EXPORT_ADMIN,
    {
      onError: () => {},
    },
  );

  const handleExport = async (values) => {
    setIsExportLoading(true);
    const res = await requestNewReturnOrderExportAdmin({
      variables: {
        data: {
          type: EXPORT_TYPES?.NEW_RETURN_ORDERS,
        },
        filter: {
          dateRange: {
            from: values?.date?.[0]?.startOf('day'),
            to: values?.date?.[1]?.endOf('day'),
          },
        },
      },
    });
    if (res?.data) {
      setIsModalOpen(false);
      form?.resetFields();
    }
    setIsExportLoading(false);
  };

  return (
    <>
      <Portal portalId="header-title-content">
        <div className="portal-header">{MODULES?.NEW}</div>
      </Portal>
      <Portal portalId="header-right-content">
        <Button
          className="common-button ml-16"
          size="middle"
          icon={<ExportOutlined />}
          onClick={() => setIsModalOpen(true)}
          loading={isExportLoading}
        >
          Export
        </Button>
      </Portal>
      <Portal portalId="header-right-content-phones">
        <Button
          className="common-button ml-16"
          size="middle"
          icon={<ExportOutlined />}
          onClick={() => setIsModalOpen(true)}
          loading={isExportLoading}
        >
          Export
        </Button>
      </Portal>
      <ExportDateRangeModal
        form={form}
        onFinish={handleExport}
        loadings={isExportLoading}
        isModalOpen={isModalOpen}
        setIsModalOpen={setIsModalOpen}
      />
      <NewTabs>
        <div className="tab-table">
          <ReturnTable />
        </div>
      </NewTabs>
    </>
  );
};

export default NewReturn;
