import { gql } from '@apollo/client';

// eslint-disable-next-line import/prefer-default-export
export const UPDATE_LIBRARY = gql`
  mutation UpdateLibrary($data: UpdateLibraryInput!) {
    updateLibrary(data: $data) {
      message
    }
  }
`;
