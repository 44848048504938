import { useLazyQuery } from '@apollo/client';
import { Empty, Select } from 'antd';
import { debounce, uniqBy } from 'lodash';
import React, { useEffect, useState } from 'react';
import { LIMIT, SORT } from '../../../../../common/constants';
import SelectComponent from '../../../../../components/SelectComponent';
import { GENRES_ADMIN } from '../graphql/Queries';

const { Option } = Select;

const GenresSelect = (props) => {
  const { toyDetail, ...rest } = props;
  const [genreList, setGenreList] = useState([]);
  const [genreSearchTerm, setGenreSearchTerm] = useState('');
  const [isAllGenreFetched, setIsAllGenreFetched] = useState(false);
  const [isGenreAllowClear, setIsGenreAllowClear] = useState(false);
  const [isGenreDropdownVisible, setIsGenreDropdownVisible] = useState(false);
  const [isFetchMoreGenreLoading, setIsFetchMoreGenreLoading] = useState(false);
  const [isGenreSelected, setIsGenreSelected] = useState(false);


  const [genresAdmin, { loading: isGenreLoading }] = useLazyQuery(GENRES_ADMIN, {
    onCompleted: (response) => {
      if (response?.genresAdmin?.data?.length < LIMIT) {
        setIsAllGenreFetched(true);
      }
      setIsGenreSelected(false);
      setIsFetchMoreGenreLoading(false);
      if (toyDetail) {
        setGenreList(
          uniqBy(
            [
              ...(toyDetail?.genres || []),
              ...genreList,
              ...response?.genresAdmin?.data,
            ],
            'id',
          ),
        );
        return;
      }
      setGenreList([...genreList, ...response?.genresAdmin?.data]);
    },
    fetchPolicy: 'network-only',
    onError() { },
  });

  useEffect(() => {
    if (!isGenreDropdownVisible) {
      setGenreSearchTerm('');
      setGenreList([]);
      genresAdmin({
        variables: {
          filter: {
            limit: LIMIT,
            skip: 0,
            search: genreSearchTerm,
          },
          sort: {
            field: 'name',
            order: SORT?.ASC,
          },
        },
      });
    }
    setIsAllGenreFetched(false);
  }, [isGenreDropdownVisible]);

  useEffect(() => {
    if (isGenreSelected && isGenreDropdownVisible && genreSearchTerm) {
      setGenreSearchTerm('');
      setGenreList([]);
      genresAdmin({
        variables: {
          filter: {
            limit: LIMIT,
            skip: 0,
          },
          sort: {
            field: 'name',
            order: SORT?.ASC,
          },
        },
      });
    }
    setIsAllGenreFetched(false);
  }, [isGenreSelected, isGenreDropdownVisible, genreSearchTerm]);

  const handleGenreScroll = (event) => {
    if (!isAllGenreFetched && !isGenreLoading) {
      const target = event?.target;
      const currentLength = genreList?.length;
      if (
        target?.scrollTop + target?.offsetHeight >=
        target?.scrollHeight - 5
      ) {
        setIsFetchMoreGenreLoading(true);
        genresAdmin({
          variables: {
            filter: {
              limit: LIMIT,
              skip: currentLength,
              search: genreSearchTerm,
            },
            sort: {
              field: 'name',
              order: SORT?.ASC,
            },
          },
        });
      }
    }
  };

  const handleGenreSearch = (searchTerm) => {
    const trimSearch = searchTerm?.trim();
    setIsAllGenreFetched(false);
    setGenreList([]);
    setGenreSearchTerm(trimSearch);
    genresAdmin({
      variables: {
        filter: {
          limit: LIMIT,
          skip: 0,
          search: trimSearch,
        },
        sort: {
          field: 'name',
          order: SORT?.ASC,
        },
      },
    });
  };

  const handleGenreScrollDebounce = debounce(handleGenreScroll, 500);
  const handleSearchGenreDebounce = debounce(handleGenreSearch, 500);

  useEffect(() => {
    handleGenreScrollDebounce?.cancel();
  }, [handleGenreScrollDebounce]);

  useEffect(() => {
    handleSearchGenreDebounce?.cancel();
  }, [handleSearchGenreDebounce]);

  useEffect(() => {
    if (isGenreLoading) {
      setIsGenreAllowClear(false);
    } else {
      setIsGenreAllowClear(true);
    }
  }, [isGenreLoading]);

  return (
    <SelectComponent
      mode="multiple"
      showSearch
      loading={(isGenreLoading && isGenreDropdownVisible) || isFetchMoreGenreLoading}
      placeholder="Select Genres"
      optionFilterProp="children"
      allowClear={isGenreAllowClear}
      onPopupScroll={handleGenreScrollDebounce}
      onSearch={handleSearchGenreDebounce}
      onDropdownVisibleChange={(visible) => setIsGenreDropdownVisible(visible)}
      filterOption={false}
      onChange={() => setIsGenreAllowClear(true)}
      onSelect={() => setIsGenreSelected(true)}
      maxGenreCount={false}
      notFoundContent={
        !isFetchMoreGenreLoading && isGenreLoading ? (
          <span>Loading...</span>
        ) : (
          <Empty image={Empty?.PRESENTED_IMAGE_SIMPLE} />
        )
      }
      {...rest}
    >
      {genreList?.map((genre) => (
        <Option key={genre?.id} value={genre?.id}>
          {genre?.name?.replace(/\s/g, '\u00a0')}
        </Option>
      ))}
    </SelectComponent>
  );
};

export default GenresSelect;
