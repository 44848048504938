import { Divider, Form, Input } from 'antd';
import React, { useState } from 'react';
import { formValidatorRules } from '../../../common/utils';
import ModalComponent from '../../../components/ModalComponent';

const { Item } = Form;
const { TextArea } = Input;
const { required, whitespaceNotAllowed } = formValidatorRules;

const CommentModal = ({
  form,
  isModalOpen,
  setIsModalOpen,
  isBtnLoading,
  onFinish,
  title,
  commentRequired = false,
}) => {
  const [validationTriggered, setValidationTriggered] = useState(false);

  return (
    <ModalComponent
      title={title}
      okText="Save"
      cancelText="Cancel"
      open={isModalOpen}
      onOk={form?.submit}
      onCancel={() => {
        setIsModalOpen(false);
        form?.resetFields();
      }}
      confirmLoading={isBtnLoading}
    >
      <Divider />
      <Form
        form={form}
        layout="vertical"
        onFinish={onFinish}
        onFinishFailed={() => setValidationTriggered(true)}
        validateTrigger={validationTriggered ? 'onChange' : 'onSubmit'}
      >
        <Item
          name="comment"
          label="Comment"
          rules={
            commentRequired
              ? [
                  { ...required, message: 'Please Enter Comment!' },
                  whitespaceNotAllowed,
                ]
              : [whitespaceNotAllowed]
          }
        >
          <TextArea
            rows={4}
            allowClear
            placeholder="Enter Comment"
            showCount
            maxLength={700}
          />
        </Item>
      </Form>
    </ModalComponent>
  );
};

export default CommentModal;
