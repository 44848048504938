import { CopyOutlined } from '@ant-design/icons';
import { Card, Dropdown, Tag } from 'antd';
import React from 'react';
import { ReactComponent as MoreIcon } from '../../../../../assets/svg/ellipsis-vertical-solid.svg';
import {
  ACTIONS,
  COUPON_TYPE,
  DATE_FORMATS,
  STATUS,
} from '../../../../../common/constants';
import { formatDate, handleCopy } from '../../../../../common/utils';

const items = [
  {
    label: ACTIONS?.REFERRAL_LINK?.label,
    key: ACTIONS?.REFERRAL_LINK?.value,
  },
];

const CouponCard = ({
  coupon,
  infiniteScrollRef,
  setIsShareModalOpen,
  handleShare,
}) => {
  const handleMenuClick = () => {
    setIsShareModalOpen(true);
    handleShare(coupon?.code);
  };

  const menuProps = {
    items,
    onClick: handleMenuClick,
  };

  return (
    <Card
      className="ml-12 mr-12 mb-24 mt-12 mobile-card"
      title={
        <div className="overflow-scroll no-scroll-bar">{coupon?.code}</div>
      }
      extra={
        <div className="d-flex align-center ml-16">
          <Tag
            color={
              coupon?.isActive ? STATUS?.ACTIVE?.color : STATUS?.INACTIVE?.color
            }
          >
            {coupon?.isActive ? STATUS?.ACTIVE?.label : STATUS?.INACTIVE?.label}
          </Tag>{' '}
          <CopyOutlined
            className="copy-icon mr-16"
            onClick={() => handleCopy(coupon?.code)}
          />
          <Dropdown
            getPopupContainer={(trigger) => trigger?.parentNode}
            menu={menuProps}
          >
            <MoreIcon className="pointer mobile-svg-icon" />
          </Dropdown>
        </div>
      }
      ref={infiniteScrollRef}
    >
      <div className="mb-8">
        <span className="mobile-card-label">Name: </span>
        <span>{coupon?.name || 'No Name'}</span>
      </div>
      <div className="mb-8">
        <span className="mobile-card-label">Commission: </span>
        <span>{coupon?.commission || 'No Commission'}</span>
      </div>
      <div className="mb-8">
        <span className="mobile-card-label">Maximum Redemptions: </span>
        <span>{coupon?.maxRedemptions || 'No Maximum Redemptions'}</span>
      </div>
      <div className="mb-8">
        <span className="mobile-card-label">Start On: </span>
        <span>
          {formatDate(coupon?.validFrom, DATE_FORMATS?.REGULAR) ||
            'No Start On'}
        </span>
      </div>
      <div className="mb-8">
        <span className="mobile-card-label">Expire On: </span>
        <span>
          {formatDate(coupon?.validTo, DATE_FORMATS?.REGULAR) || 'No Expire On'}
        </span>
      </div>
      <div className="mb-8">
        <span className="mobile-card-label">Amount: </span>
        <span>{coupon?.value || 'No Amount'}</span>
      </div>
      <div className="mb-8">
        <span className="mobile-card-label">Used By: </span>
        <span>{coupon?.usedCouponCount || 'No Used By'}</span>
      </div>
      <div className="mb-8">
        <span className="mobile-card-label">Description: </span>
        <span>{coupon?.description || 'No Description'}</span>
      </div>
      <div className="mb-8">
        <span className="mobile-card-label">Type: </span>
        <span>{COUPON_TYPE?.[coupon?.type]?.label}</span>
      </div>
      <div className="mb-8">
        <span className="mobile-card-label">Generated On: </span>
        <span>
          {formatDate(coupon?.createdAt, DATE_FORMATS?.REGULAR) ||
            'No Generated On'}
        </span>
      </div>
    </Card>
  );
};

export default CouponCard;
