import { Empty } from 'antd';
import React from 'react';
import LoaderComponent from '../../../components/LoaderComponent';
import DamageCard from './DamageCard';

const DamageList = ({
  damageList,
  infiniteScrollRef,
  isFetchMoreDamagesLoading,
  setVisible,
  setToyImages,
  isEmptyDamageList,
  isDamageLoading,
  setDamage,
  setIsCommentPrompts,
  setIsContentMasterModalOpen,
}) => (
  <div>
    {!isEmptyDamageList &&
      damageList?.map((damage) => (
        <DamageCard
          key={damage?.id}
          damage={damage}
          infiniteScrollRef={infiniteScrollRef}
          setVisible={setVisible}
          setToyImages={setToyImages}
          setDamage={setDamage}
          setIsCommentPrompts={setIsCommentPrompts}
          setIsContentMasterModalOpen={setIsContentMasterModalOpen}
        />
      ))}
    {(isFetchMoreDamagesLoading || isDamageLoading) && (
      <LoaderComponent size="small" setHeight={10} />
    )}
    {isEmptyDamageList && <Empty image={Empty?.PRESENTED_IMAGE_SIMPLE} />}
  </div>
);

export default DamageList;
