import { gql } from '@apollo/client';

// eslint-disable-next-line import/prefer-default-export
export const INVOICES_ADMIN = gql`
  query InvoicesAdmin($filter: InvoiceFilter, $sort: InvoicesSort) {
    invoicesAdmin(filter: $filter, sort: $sort) {
      count
      data {
        id
        invoiceNumber
        library {
          id
          name
          libraryNumber
        }
        createdAt
        amount
        status
        attachment
        reason
        updatedAt
        paidOn
      }
    }
  }
`;

export const GET_ATTACHMENT_SIGNED_PUT_URL = gql`
  query GetAttachmentSignedPutUrl($data: getAttachmentSignedPutUrlInput) {
    getAttachmentSignedPutUrl(data: $data) {
      fileName
      signedUrl
    }
  }
`;
