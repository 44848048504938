import { Input } from 'antd';
import React, { useState } from 'react';
import { NUMBER_FIELD_TYPES, REGEX } from '../common/constants';
import './styles/NumberComponent.less';

const NumberComponent = (props) => {
  const {
    name = '',
    className = '',
    min = 0,
    isFractional = true,
    type,
    ...rest
  } = props;
  const [inputValue, setInputValue] = useState('');

  const disallowedKeys = ['e', 'E', '+', '-'];
  if (!isFractional) {
    disallowedKeys?.push('.');
  }

  const handleKeyDown = (evt) => {
    if (disallowedKeys?.includes(evt?.key)) {
      evt?.preventDefault();
    }
  };

  const handleOnInput = (evt) => {
    const { value } = evt?.target;
    let maxLength;

    switch (type) {
      case NUMBER_FIELD_TYPES.PRICE:
        maxLength = value?.includes('.') ? 11 : 8;
        break;
      case NUMBER_FIELD_TYPES.STOCK:
        maxLength = 8;
        break;
      case NUMBER_FIELD_TYPES.AGE:
        maxLength = 4;
        break;
      case NUMBER_FIELD_TYPES.PINCODE:
        maxLength = 6;
        break;
      case NUMBER_FIELD_TYPES.DELIVERY:
        maxLength = 2;
        break;
      case NUMBER_FIELD_TYPES.CONFIG:
        maxLength = 4;
        break;
      case NUMBER_FIELD_TYPES.QUANTITY:
        maxLength = 4;
        break;
      case NUMBER_FIELD_TYPES.TRIAL_PLAN_DAYS:
        maxLength = 3;
        break;
      default:
        maxLength = 20;
        break;
    }

    if (evt?.nativeEvent?.inputType === 'deleteContentBackward') {
      setInputValue(evt.target.value);
      return;
    }

    if (!REGEX?.IS_DIGIT?.test(evt.target.value)) {
      // eslint-disable-next-line no-param-reassign
      evt.target.value = inputValue;
    } else {
      setInputValue(evt.target.value);
      if (maxLength && value?.length > maxLength) {
        // eslint-disable-next-line no-param-reassign
        evt.target.value = value?.slice(0, maxLength);
      }
    }
  };

  return (
    <Input
      className={`number-component ${className}`}
      type="number"
      allowClear
      placeholder={`Enter ${name || ''}`}
      min={min}
      onKeyDown={handleKeyDown}
      onInput={handleOnInput}
      {...rest}
    />
  );
};

export default NumberComponent;
