import { useLazyQuery, useMutation } from '@apollo/client';
import { Button, Card, Form } from 'antd';
import dayjs from 'dayjs';
import React, { useContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { AppContext } from '../../../../../AppContext';
import { ROUTES } from '../../../../../common/constants';
import GoBackButton from '../../../../../components/GoBackButton';
import LoaderComponent from '../../../../../components/LoaderComponent';
import Portal from '../../../../../components/Portal';
import GiftCardForm from '../components/GiftCardForm';
import { UPDATE_GIFT_CARD } from '../graphql/Mutations';
import { GIFT_CARD } from '../graphql/Queries';

const EditGiftCard = (props) => {
  const { match: { params: { id } = {} } = {} } = props;

  const history = useHistory();
  const [form] = Form.useForm();
  const { dispatch } = useContext(AppContext);
  const [giftCardDetail, setGiftCardDetail] = useState({});
  const [isDisabled, setIsDisabled] = useState(true);
  const [isSubmitLoading, setIsSubmitLoading] = useState(false);
  const [isGiftCardLoading, setIsGiftCardLoading] = useState(true);

  const [updateUser] = useMutation(UPDATE_GIFT_CARD, {
    onError: () => {},
  });

  const [giftCard] = useLazyQuery(GIFT_CARD, {
    onCompleted: (response) => {
      setGiftCardDetail(response?.giftCard?.data);
      setIsGiftCardLoading(false);
    },
    fetchPolicy: 'network-only',
    onError() {},
  });

  useEffect(() => {
    setIsGiftCardLoading(true);
    setGiftCardDetail([]);
    giftCard({
      variables: {
        where: {
          id,
        },
      },
    });
  }, []);

  const updateGiftCardFun = async (giftCardDetails) => {
    setIsSubmitLoading(true);
    dispatch({
      type: 'SET_SHOW_PROMPT',
      data: false,
    });
    const response = await updateUser({
      variables: {
        where: {
          id,
        },
        data: {
          status: giftCardDetails?.status,
          notes: giftCardDetails?.notes?.trim(),
          expiredAt: giftCardDetails?.expiresOn?.endOf('day'),
          startedAt: giftCardDetails?.startsOn?.startOf('day'),
        },
      },
    });
    if (response?.data) {
      history?.replace(`${ROUTES?.OFFERS}${ROUTES?.GIFT_CARD}`);
    }
    setIsSubmitLoading(false);
  };

  const handleShowPrompt = () => {
    setIsDisabled(false);
    dispatch({
      type: 'SET_SHOW_PROMPT',
      data: true,
    });
  };

  const initialValues = {
    ...giftCardDetail,
    plan: giftCardDetail?.subscription?.id,
    price: giftCardDetail?.subscription?.price,
    notes: giftCardDetail?.notes,
    expiresOn: dayjs(giftCardDetail?.expiredAt),
    startsOn: dayjs(giftCardDetail?.startedAt),
  };

  return (
    <>
      <Portal portalId="header-title-content">
        <GoBackButton customLink={`${ROUTES?.OFFERS}${ROUTES?.GIFT_CARD}`} />
        <div className="portal-header">Edit Gift Card</div>
      </Portal>
      <Portal portalId="header-right-content">
        <div className="header-btn-wrapper">
          <Button
            type="primary"
            className="common-button mr-16"
            size="middle"
            onClick={() =>
              history?.push(`${ROUTES?.OFFERS}${ROUTES?.GIFT_CARD}`)
            }
          >
            Cancel
          </Button>
          <Button
            className="common-button"
            size="middle"
            onClick={form.submit}
            loading={isSubmitLoading}
            disabled={isDisabled}
          >
            Save
          </Button>
        </div>
      </Portal>
      <Portal portalId="header-right-content-phones">
        <div className="header-btn-wrapper">
          <Button
            type="primary"
            className="common-button mr-16"
            size="middle"
            onClick={() =>
              history?.push(`${ROUTES?.OFFERS}${ROUTES?.GIFT_CARD}`)
            }
          >
            Cancel
          </Button>
          <Button
            className="common-button"
            size="middle"
            onClick={form.submit}
            loading={isSubmitLoading}
            disabled={isDisabled}
          >
            Save
          </Button>
        </div>
      </Portal>
      <Card className="full-height-with-nav" title="Gift Card Details">
        <div className="toy-form">
          {isGiftCardLoading ? (
            <LoaderComponent
              size="large"
              setHeight="60"
              spinning={isGiftCardLoading}
            />
          ) : (
            <div className="admin-form">
              <GiftCardForm
                form={form}
                onFinish={updateGiftCardFun}
                handleShowPrompt={handleShowPrompt}
                initialValues={initialValues}
                giftCardDetail={giftCardDetail}
              />
            </div>
          )}
        </div>
      </Card>
    </>
  );
};

export default EditGiftCard;
