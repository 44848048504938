import { Tabs } from 'antd';
import React, { useContext, useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { AppContext } from '../../../../../AppContext';
import { PERMISSIONS_KEY, ROUTES, TABS } from '../../../../../common/constants';
import { filterTabsByPermissions } from '../../../../../common/utils';

const AllTabs = ({ children }) => {
  const history = useHistory();
  const location = useLocation();
  const {
    state: { permissions },
  } = useContext(AppContext);
  const [activeTabKey, setActiveTabKey] = useState(TABS?.DELIVERY?.key);

  const tabList = [
    {
      key: TABS?.DELIVERY?.key,
      label: TABS?.DELIVERY?.label,
      children,
      permissionKey: PERMISSIONS_KEY?.ORDERS_ALL_DELIVERY,
    },
    {
      key: TABS?.RETURN?.key,
      label: TABS?.RETURN?.label,
      children,
      permissionKey: PERMISSIONS_KEY?.ORDERS_ALL_RETURN,
    },
  ];

  const filteredTabs = filterTabsByPermissions(tabList, permissions);

  useEffect(() => {
    if (location?.pathname?.includes(ROUTES?.DELIVERY)) {
      setActiveTabKey(TABS?.DELIVERY?.key);
    }
    if (location?.pathname?.includes(ROUTES?.RETURN)) {
      setActiveTabKey(TABS?.RETURN?.key);
    }
  }, [location]);

  const onTabChange = (key) => {
    setActiveTabKey(key);
    switch (key) {
      case TABS?.DELIVERY?.key:
        history?.push(`${ROUTES?.ORDERS}${ROUTES?.ALL}${ROUTES?.DELIVERY}`);
        break;
      case TABS?.RETURN?.key:
        history?.push(`${ROUTES?.ORDERS}${ROUTES?.ALL}${ROUTES?.RETURN}`);
        break;
      default:
        break;
    }
  };

  return (
    <>
      <div className="card-container">
        <Tabs
          activeKey={activeTabKey}
          type="card"
          className="full-width"
          onChange={(key) => onTabChange(key)}
          items={filteredTabs}
          destroyInactiveTabPane
        />
      </div>
    </>
  );
};

export default AllTabs;
