import React from 'react';
import { Route, Switch } from 'react-router-dom';
import { ROUTES } from '../../../../common/constants';
import New from './pages/New';

const NewWrapper = () => (
  <Switch>
    <Route
      exact
      path={`${ROUTES?.ORDERS}${ROUTES?.NEW}`}
      render={(props) => <New {...props} />}
    />
  </Switch>
);

export default NewWrapper;
