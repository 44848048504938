import React from 'react';
import { Route, Switch } from 'react-router-dom';
import AccessControl from '../../../AccessControl';
import { PERMISSIONS_KEY, ROUTES } from '../../../common/constants';
import Error404 from '../../../Error404';
import Banner from './pages/Banner';

const BannerWrapper = () => (
  <Switch>
    <Route
      exact
      path={`${ROUTES?.APP_CONTENT}${ROUTES?.BANNER}`}
      render={(props) => (
        <AccessControl permissionKey={PERMISSIONS_KEY?.APP_CONTENT_BANNER}>
          <Banner {...props} />
        </AccessControl>
      )}
    />
    <Route path="*" exact component={Error404} />
  </Switch>
);

export default BannerWrapper;
