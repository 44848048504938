import { Card } from 'antd';
import React from 'react';
import { ROUTES } from '../../../common/constants';
import GoBackButton from '../../../components/GoBackButton';
import Portal from '../../../components/Portal';
import BulkUpdateLogTable from '../components/BulkUpdateLogTable';

const BulkUpdateLog = () => (
  <>
    <Portal portalId="header-title-content">
      <GoBackButton customLink={ROUTES?.INVENTORY} />
      <div className="portal-header">View Bulk Update Log</div>
    </Portal>
    <Card className="mobile-with-search-container mobile-card-main">
      <BulkUpdateLogTable />
    </Card>
  </>
);

export default BulkUpdateLog;
