import { Card } from 'antd';
import React from 'react';
import { MODULES } from '../../../../../common/constants';
import Portal from '../../../../../components/Portal';
import ReferralDetails from '../components/ReferralDetails';

const Referral = () => (
  <>
    <Portal portalId="header-title-content">
      <div className="portal-header">{MODULES?.REFERRAL}</div>
    </Portal>
    <Card className="only-card-container">
      <ReferralDetails />
    </Card>
  </>
);

export default Referral;
