import React from 'react';
import { MODULES } from '../../../../../common/constants';
import Portal from '../../../../../components/Portal';
import EncashmentTable from '../components/EncashmentTable';
import EndUserTabs from '../components/EndUserTabs';

const Encashment = () => (
  <>
    <Portal portalId="header-title-content">
      <div className="portal-header">{MODULES?.END_USER}</div>
    </Portal>
    <EndUserTabs>
      <div className="tab-table">
        <EncashmentTable />
      </div>
    </EndUserTabs>
  </>
);

export default Encashment;
