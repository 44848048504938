import React from 'react';
import { Route, Switch } from 'react-router-dom';
import AccessControl from '../../../../AccessControl';
import { PERMISSIONS_KEY, ROUTES } from '../../../../common/constants';
import Error404 from '../../../../Error404';
import Bulk from './pages/Bulk';
import ViewBulk from './pages/ViewBulk';

const BulkWrapper = () => (
  <Switch>
    <Route
      exact
      path={`${ROUTES?.REPORTS}${ROUTES?.BULK_UPDATE}`}
      render={(props) => (
        <AccessControl permissionKey={PERMISSIONS_KEY?.REPORTS_BULK_UPDATE}>
          <Bulk {...props} />
        </AccessControl>
      )}
    />
    <Route
      exact
      path={`${ROUTES?.REPORTS}${ROUTES?.BULK_UPDATE}/view/:id`}
      render={(props) => (
        <AccessControl permissionKey={PERMISSIONS_KEY?.REPORTS_BULK_UPDATE}>
          <ViewBulk {...props} />
        </AccessControl>
      )}
    />
    <Route path="*" exact component={Error404} />
  </Switch>
);

export default BulkWrapper;
