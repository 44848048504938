import { PlusOutlined } from '@ant-design/icons';
import { Divider, Form, Input, Upload } from 'antd';
import React, { useEffect, useState } from 'react';
import {
  formValidatorRules,
  getBase64,
  handleInput,
  validateImage,
} from '../../../../../common/utils';
import ModalComponent from '../../../../../components/ModalComponent';

const { Item } = Form;
const { TextArea } = Input;
const { whitespaceNotAllowed, required } = formValidatorRules;

const ContentMasterModal = ({
  isModalOpen,
  setIsModalOpen,
  onFinish,
  loadings,
  form,
  title,
  contentMaster,
  setContentMaster,
  contentMasterImages,
  setContentMasterImages,
  setVisible,
  setContentMasterPreviewImage,
}) => {
  const [isValidateTrigger, setIsValidateTrigger] = useState(false);

  const handleCancel = () => {
    setIsModalOpen(false);
    setContentMaster();
    form?.resetFields();
  };

  useEffect(() => {
    form?.setFieldsValue(contentMaster);
  }, [contentMaster]);

  const handleImageChange = (info) => {
    validateImage(info, form, setContentMasterImages, 'image');
  };

  const handlePreview = async (fileObj) => {
    let preview;
    if (!fileObj?.url && !fileObj?.preview) {
      preview = await getBase64(fileObj?.originFileObj);
    }
    setVisible(true);
    setContentMasterPreviewImage(fileObj?.url || preview);
  };

  return (
    <>
      <ModalComponent
        open={isModalOpen}
        onCancel={handleCancel}
        onOk={form?.submit}
        title={title}
        okText="Save"
        confirmLoading={loadings}
        destroyOnClose
        forceRender
      >
        <Divider />
        <Form
          form={form}
          onFinish={(values) => {
            onFinish(values);
            setIsValidateTrigger(true);
          }}
          autoComplete="off"
          layout="vertical"
          validateTrigger={isValidateTrigger ? 'onChange' : 'onSubmit'}
          onFinishFailed={() => setIsValidateTrigger(true)}
        >
          <Item
            label="Key"
            name="key"
            rules={[{ ...required, message: 'Please Enter Key!' }]}
          >
            <Input
              allowClear
              placeholder="Enter Key"
              maxLength={50}
              onInput={handleInput}
            />
          </Item>
          <Item
            label="Label"
            name="value"
            rules={[{ ...required, message: 'Please Enter Label!' }]}
          >
            <Input allowClear placeholder="Enter Label" maxLength={50} />
          </Item>
          <Item
            label="Description"
            name="description"
            rules={[whitespaceNotAllowed]}
          >
            <TextArea
              rows={3}
              allowClear
              placeholder="Enter Description"
              showCount
              maxLength={900}
            />
          </Item>
          <Item label="Image" name="image">
            <Upload
              listType="picture-card"
              maxCount={1}
              fileList={contentMasterImages}
              beforeUpload={() => false}
              onPreview={handlePreview}
              onChange={handleImageChange}
              showUploadList={{
                showDownloadIcon: false,
                showPreviewIcon: true,
                showRemoveIcon: true,
              }}
            >
              {contentMasterImages?.length !== 1 && <PlusOutlined />}
            </Upload>
          </Item>
        </Form>
      </ModalComponent>
    </>
  );
};

export default ContentMasterModal;
