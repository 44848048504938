import { gql } from '@apollo/client';

// eslint-disable-next-line import/prefer-default-export
export const CREATE_REQUEST_LIBRARY = gql`
  mutation CreateRequestLibrary($data: CreateRequestLibraryInput) {
    createRequestLibrary(data: $data) {
      message
    }
  }
`;

export const UPDATE_REQUEST_LIBRARY = gql`
  mutation UpdateRequestLibrary(
    $where: RequestUniqueInput!
    $data: UpdateRequestInput
  ) {
    updateRequestLibrary(where: $where, data: $data) {
      message
    }
  }
`;
