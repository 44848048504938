import { gql } from '@apollo/client';

// eslint-disable-next-line import/prefer-default-export
export const CREATE_VIDEO = gql`
  mutation CreateVideo($data: CreateVideoInput!) {
    createVideo(data: $data) {
      message
    }
  }
`;

export const UPDATE_VIDEO = gql`
  mutation UpdateVideo($where: VideoUniqueInput!, $data: UpdateVideoInput!) {
    updateVideo(where: $where, data: $data) {
      message
    }
  }
`;
