import { FilterFilled, FilterOutlined } from '@ant-design/icons';
import { useLazyQuery } from '@apollo/client';
import {
  Badge,
  Button,
  Checkbox,
  Divider,
  Empty,
  Image,
  Tag,
  Tooltip,
} from 'antd';
import { debounce, filter, forEach, isEmpty, map } from 'lodash';
import React, { useContext, useEffect, useState } from 'react';
import { AppContext } from '../../../AppContext';
import { ReactComponent as FilterIcon } from '../../../assets/svg/sliders-solid.svg';
import { BREAKPOINTS, LIMIT, SORT, SORTING } from '../../../common/constants';
import { hasNonEmptyValueObj } from '../../../common/utils';
import CommonPreviewTable from '../../../components/CommonPreviewTable';
import CommonTable from '../../../components/CommonTable';
import LoaderComponent from '../../../components/LoaderComponent';
import SearchComponent from '../../../components/SearchComponent';
import SortDropdown from '../../../components/SortDropdown';
import useInfiniteScroll from '../../../hooks/useInfiniteScroll';
import { CATEGORIES } from '../../../modules/lists/modules/toy/graphql/Queries';
import { LIBRARY_INVENTORY_BULK_UPDATE_LOGS } from '../graphql/Queries';
import AllFilterBulkUpdateLogModal from './AllFilterBulkUpdateLogModal';
import BulkUpdateLogList from './BulkUpdateLogList';

const initialPaginationValue = {
  total: 0,
  current: 1,
  pageSize: 10,
};

const initialToyFilter = {
  limit: LIMIT,
  search: '',
  skip: 0,
};

const initialToySort = {
  field: 'createdAt',
  order: 'DESC',
};

const initialTableFilter = {
  skip: 0,
  limit: LIMIT,
  search: '',
};

const initialTableSort = {
  field: 'name',
  order: 'ASC',
};

let filterScrollDebounceJob = null;

const sort = [
  {
    label: SORTING?.PRODUCT_NAME_A_Z?.label,
    value: SORTING?.PRODUCT_NAME_A_Z?.value,
    field: SORTING?.PRODUCT_NAME_A_Z?.field,
    sort: SORTING?.PRODUCT_NAME_A_Z?.sort,
  },
  {
    label: SORTING?.PRODUCT_NAME_Z_A?.label,
    value: SORTING?.PRODUCT_NAME_Z_A?.value,
    field: SORTING?.PRODUCT_NAME_Z_A?.field,
    sort: SORTING?.PRODUCT_NAME_Z_A?.sort,
  },
];

const BulkUpdateLogTable = () => {
  const {
    state: { isDesktop },
  } = useContext(AppContext);
  const [inventoryList, setInventoryList] = useState([]);
  const [inventorySearchTerm, setInventorySearchTerm] = useState('');
  const [isEmptyInventoryList, setIsEmptyInventoryList] = useState(false);
  const [query, setQuery] = useState('');
  const [sortedInfo, setSortedInfo] = useState({});
  const [paginationProp, setPaginationProp] = useState(initialPaginationValue);

  const [isInventoryLoading, setIsInventoryLoading] = useState(true);

  const [visible, setVisible] = useState(false);
  const [toyImages, setToyImages] = useState([]);

  const [filterSearch, setFilterSearch] = useState('');
  const [filterList, setFilterList] = useState([]);
  const [whereFilter, setWhereFilter] = useState();
  const [filterLoading, setFilterLoading] = useState(false);
  const [filterTags, setFilterTags] = useState(null);
  const [filterVisible, setFilterVisible] = useState(false);
  const [filterIndex, setFilterIndex] = useState(null);
  const [finalWhereFilters, setFinalWhereFilters] = useState();
  const [finalFilterTags, setFinalFilterTags] = useState(null);
  const [filterIsEnd, setFilterIsEnd] = useState(false);
  const [isEmptyFilterList, setIsEmptyFilterList] = useState(false);

  const [isAllFilterModalOpen, setIsAllFilterModalOpen] = useState(false);
  const [isFetchMoreToysLoading, setIsFetchMoreToysLoading] = useState(false);
  const [isActive, setIsActive] = useState(true);
  const [isAllSortPopoverOpen, setIsAllSortPopoverOpen] = useState(false);

  const [libraryInventories] = useLazyQuery(
    LIBRARY_INVENTORY_BULK_UPDATE_LOGS,
    {
      onCompleted: (response) => {
        if (isDesktop) {
          setInventoryList([...response?.libraryInventoryBulkUpdateLogs?.data]);
        } else {
          setInventoryList([
            ...inventoryList,
            ...response?.libraryInventoryBulkUpdateLogs?.data,
          ]);
        }
        if (
          response?.libraryInventoryBulkUpdateLogs?.data?.length === 0 &&
          initialPaginationValue?.total === 0
        ) {
          setIsEmptyInventoryList(true);
        } else {
          setIsEmptyInventoryList(false);
        }
        if (response?.libraryInventoryBulkUpdateLogs?.data?.length < LIMIT) {
          setIsActive(false);
        } else {
          setIsActive(true);
        }
        const pagination = {
          ...paginationProp,
          defaultPageSize: LIMIT,
          total: response?.libraryInventoryBulkUpdateLogs?.count,
        };
        setPaginationProp(pagination);
        setIsInventoryLoading(false);
        setIsFetchMoreToysLoading(false);
      },
      fetchPolicy: 'network-only',
      onError() {},
    },
  );

  const [categories, { loading: isCategoriesLoading }] = useLazyQuery(
    CATEGORIES,
    {
      onCompleted: (response) => {
        if (response?.categories?.data?.length < LIMIT) {
          setFilterIsEnd(true);
        }
        if (response?.categories?.count === 0) {
          setIsEmptyFilterList(true);
        } else {
          setIsEmptyFilterList(false);
        }
        const optionsCopy = [];
        forEach(response?.categories?.data, (item) =>
          optionsCopy?.push({ label: item?.name, key: item?.id }),
        );
        setFilterList([...filterList, ...optionsCopy]);
        setFilterLoading(false);
      },
      fetchPolicy: 'network-only',
      onError() {
        setFilterLoading(false);
      },
    },
  );

  useEffect(() => {
    setPaginationProp({ ...paginationProp, current: 1, skip: 0 });
    setIsInventoryLoading(true);
    setInventoryList([]);
    libraryInventories({
      variables: {
        filter: {
          ...initialToyFilter,
          search: inventorySearchTerm,
          brandIds: finalWhereFilters?.brand,
          categoryIds: finalWhereFilters?.category,
          materialIds: finalWhereFilters?.material,
          ageGroupIds: finalWhereFilters?.ageGroup,
        },
        sort: sortedInfo?.column
          ? {
              field: sortedInfo?.field,
              order: sortedInfo?.order === 'ascend' ? SORT?.ASC : SORT?.DESC,
            }
          : initialToySort,
      },
    });
  }, [isDesktop]);

  const handleTableChange = (pagination, tableFilter, sorter) => {
    const { current } = pagination;
    const skip = (current - 1) * (pagination?.pageSize || 0);
    setPaginationProp({ ...paginationProp, ...pagination });
    setIsInventoryLoading(true);
    setSortedInfo(sorter);
    libraryInventories({
      variables: {
        filter: {
          ...initialToyFilter,
          skip,
          limit: pagination?.pageSize,
          search: inventorySearchTerm,
          brandIds: finalWhereFilters?.brand,
          categoryIds: finalWhereFilters?.category,
          materialIds: finalWhereFilters?.material,
          ageGroupIds: finalWhereFilters?.ageGroup,
        },
        sort: sorter?.column
          ? {
              field: sorter?.field,
              order: sorter?.order === 'ascend' ? SORT?.ASC : SORT?.DESC,
            }
          : initialToySort,
      },
    });
  };

  const handleSearch = (value) => {
    const trimValue = value?.trim();
    setInventorySearchTerm(trimValue);
    setInventoryList([]);
    setPaginationProp({ ...paginationProp, current: 1, skip: 0 });
    setIsInventoryLoading(true);
    libraryInventories({
      variables: {
        filter: {
          ...initialToyFilter,
          limit: paginationProp?.pageSize || LIMIT,
          search: trimValue,
          brandIds: finalWhereFilters?.brand,
          categoryIds: finalWhereFilters?.category,
          materialIds: finalWhereFilters?.material,
          ageGroupIds: finalWhereFilters?.ageGroup,
        },
        sort: sortedInfo?.column
          ? {
              field: sortedInfo?.field,
              order: sortedInfo?.order === 'ascend' ? SORT?.ASC : SORT?.DESC,
            }
          : initialToySort,
      },
    });
  };

  const handleBulkUpdateScroll = () => {
    if (!isFetchMoreToysLoading && !isInventoryLoading) {
      const currentLength = inventoryList?.length;
      setIsFetchMoreToysLoading(true);
      libraryInventories({
        variables: {
          filter: {
            ...initialToyFilter,
            skip: currentLength,
            limit: paginationProp?.pageSize || LIMIT,
            search: inventorySearchTerm,
            brandIds: finalWhereFilters?.brand,
            categoryIds: finalWhereFilters?.category,
            materialIds: finalWhereFilters?.material,
            ageGroupIds: finalWhereFilters?.ageGroup,
          },
          sort: sortedInfo?.column
            ? {
                field: sortedInfo?.field,
                order: sortedInfo?.order === 'ascend' ? SORT?.ASC : SORT?.DESC,
              }
            : initialToySort,
        },
      });
    }
  };

  const handleFilter = (values) => {
    setPaginationProp({ ...paginationProp, current: 1, skip: 0 });
    setIsInventoryLoading(true);
    setIsAllFilterModalOpen(false);
    setInventoryList([]);
    setIsActive(true);
    setWhereFilter(values);
    setFinalWhereFilters(values);
    libraryInventories({
      variables: {
        filter: {
          ...initialToyFilter,
          limit: paginationProp?.pageSize || LIMIT,
          search: inventorySearchTerm,
          brandIds: values?.brand,
          categoryIds: values?.category,
          materialIds: values?.material,
          ageGroupIds: values?.ageGroup,
        },
        sort: sortedInfo?.column
          ? {
              field: sortedInfo?.field,
              order: sortedInfo?.order === 'ascend' ? SORT?.ASC : SORT?.DESC,
            }
          : initialToySort,
      },
    });
  };

  const handleSort = (values) => {
    setPaginationProp({ ...paginationProp, current: 1, skip: 0 });
    setIsInventoryLoading(true);
    setIsAllSortPopoverOpen(false);
    setIsActive(true);
    setInventoryList([]);
    const filteredSort = sort?.filter((item) => item?.value === values?.sort);
    if (filteredSort?.[0]?.field) {
      setSortedInfo({
        ...sortedInfo,
        field: filteredSort?.[0]?.field,
        columnKey: filteredSort?.[0]?.field,
        order: filteredSort?.[0]?.sort === SORT?.ASC ? 'ascend' : 'descend',
        column: filteredSort?.[0]?.field,
      });
    }
    libraryInventories({
      variables: {
        filter: {
          ...initialToyFilter,
          limit: paginationProp?.pageSize || LIMIT,
          search: inventorySearchTerm,
          brandIds: finalWhereFilters?.brand,
          categoryIds: finalWhereFilters?.category,
          materialIds: finalWhereFilters?.material,
          ageGroupIds: finalWhereFilters?.ageGroup,
        },
        sort: filteredSort?.[0]
          ? {
              field: filteredSort?.[0]?.field,
              order: filteredSort?.[0]?.sort,
            }
          : initialToySort,
      },
    });
  };

  // eslint-disable-next-line react-hooks/rules-of-hooks, no-use-before-define
  const infiniteScrollRef = useInfiniteScroll(handleBulkUpdateScroll, isActive);

  const getFilterData = (confirm) => {
    setIsInventoryLoading(true);
    libraryInventories({
      variables: {
        filter: {
          ...initialToyFilter,
          search: inventorySearchTerm,
          brandIds: whereFilter?.brand,
          categoryIds: whereFilter?.category,
          materialIds: whereFilter?.material,
          ageGroupIds: whereFilter?.ageGroup,
        },
        sort: sortedInfo?.column
          ? {
              field: sortedInfo?.field,
              order: sortedInfo?.order === 'ascend' ? SORT?.ASC : SORT?.DESC,
            }
          : initialToySort,
      },
    });
    setFinalWhereFilters({ ...finalWhereFilters, ...whereFilter });
    setFinalFilterTags(filterTags);
    setPaginationProp({ ...paginationProp, skip: 0, current: 1 });
    if (confirm) {
      confirm();
    }
  };

  const handleReset = (clearFilters, dataIndex, confirm) => {
    const tagsCopy = {
      ...filterTags,
      [dataIndex]: [],
    };
    const whereFiltersCopy = {
      ...whereFilter,
      [dataIndex]: [],
    };
    setFilterVisible(false);
    setFinalFilterTags({ ...filterTags, ...tagsCopy });
    setFinalWhereFilters({ ...finalWhereFilters, ...whereFiltersCopy });
    setIsInventoryLoading(true);
    libraryInventories({
      variables: {
        filter: {
          ...initialToyFilter,
          search: inventorySearchTerm,
          brandIds: whereFiltersCopy?.brand,
          categoryIds: whereFiltersCopy?.category,
          materialIds: whereFiltersCopy?.material,
          ageGroupIds: whereFiltersCopy?.ageGroup,
        },
        sort: sortedInfo?.column
          ? {
              field: sortedInfo?.field,
              order: sortedInfo?.order === 'ascend' ? SORT?.ASC : SORT?.DESC,
            }
          : initialToySort,
      },
    });
    setPaginationProp({ ...paginationProp, skip: 0, current: 1 });
    clearFilters();
    setFilterVisible(false);
    if (confirm) {
      confirm();
    }
  };

  useEffect(() => {
    if (filterVisible) {
      setFilterList([]);
      setFilterLoading(true);
      setIsEmptyFilterList(false);
      setFilterTags({ ...filterTags, ...finalFilterTags });
      setWhereFilter({ ...whereFilter, ...finalWhereFilters });
      setFilterIsEnd(false);
      switch (filterIndex) {
        case 'category':
          categories({
            variables: {
              filter: initialTableFilter,
              sort: initialTableSort,
            },
          });
          break;

        default:
          break;
      }
    }
    if (!filterVisible) {
      setFilterTags(finalFilterTags);
      setWhereFilter(finalWhereFilters);
      setFilterSearch('');
    }
  }, [filterVisible]);

  const onFilterScroll = (event, dataIndex) => {
    if (filterScrollDebounceJob) {
      filterScrollDebounceJob?.cancel();
      filterScrollDebounceJob = null;
    }
    if (!isCategoriesLoading && !filterIsEnd) {
      const target = event?.target;
      const currentLength = filterList?.length;
      if (
        target?.scrollTop + target?.offsetHeight >=
        target?.scrollHeight - 5
      ) {
        filterScrollDebounceJob = debounce(() => {
          setFilterLoading(true);
          switch (dataIndex) {
            case 'category':
              categories({
                variables: {
                  filter: {
                    limit: LIMIT,
                    skip: currentLength,
                    search: filterSearch,
                  },
                  sort: initialTableSort,
                },
              });
              break;

            default:
              break;
          }
        }, 500);
        filterScrollDebounceJob();
      }
    }
  };

  const handleFilterSearch = (value, dataIndex) => {
    setFilterSearch(value);
    setFilterIsEnd(false);
    setFilterLoading(true);
    setFilterList([]);
    switch (dataIndex) {
      case 'category':
        categories({
          variables: {
            filter: {
              ...initialTableFilter,
              limit: LIMIT,
              search: value,
            },
            sort: initialTableSort,
          },
        });
        break;

      default:
        break;
    }
  };

  const changeFilter = (newFilterObj, dataIndex) => {
    const {
      target: { value },
    } = newFilterObj;

    const filterTagsCopy = { ...filterTags };
    let newTagList;
    if (whereFilter?.[dataIndex]?.includes(value?.key)) {
      newTagList = {
        ...filterTagsCopy,
        [dataIndex]: filter(filterTagsCopy?.[dataIndex], (item) => {
          if (item?.label) {
            return item?.key !== value?.key;
          }
          return item !== value;
        }),
      };
    } else {
      newTagList = {
        ...filterTagsCopy,
        [dataIndex]: filterTagsCopy?.[dataIndex]
          ? [...filterTagsCopy?.[dataIndex], value]
          : [value],
      };
    }

    const updatedWhereFilter = {
      ...whereFilter,
      [dataIndex]: newTagList?.[dataIndex]?.map((item) => item?.key),
    };
    setWhereFilter(updatedWhereFilter);
    setFilterTags(newTagList);
  };

  const handleDeleteFilter = (value, dataIndex) => {
    let newTagList;

    if (whereFilter?.[dataIndex]?.includes(value?.key)) {
      newTagList = {
        ...filterTags,
        [dataIndex]: filter(filterTags?.[dataIndex], (item) => {
          if (item?.key) {
            return item?.key !== value?.key;
          }
          return item !== value;
        }),
      };
    }

    const updatedWhereFilter = {
      ...whereFilter,
      [dataIndex]: newTagList?.[dataIndex]?.map((item) => item?.key),
    };
    setWhereFilter(updatedWhereFilter);
    setFilterTags(newTagList);
  };

  const filterPopup = (dataIndex, showSearch = true) => ({
    filterDropdown: ({ confirm, clearFilters }) => (
      <div className="custom-filter-dropdown">
        <LoaderComponent spinning={filterLoading} setHeight={35} size="medium">
          {showSearch && (
            <SearchComponent
              className="filter-search"
              id="search-container-id-roles"
              placeholder="Search..."
              name={dataIndex}
              query={filterSearch}
              setQuery={setFilterSearch}
              getData={(value) => handleFilterSearch(value, dataIndex)}
            />
          )}
          {filterTags?.[dataIndex]?.length > 0 && (
            <div className="filter-tags-wrapper">
              {map(filterTags?.[dataIndex], (item) => (
                <Tag
                  key={item?.key}
                  closable
                  onClose={() => handleDeleteFilter(item, dataIndex)}
                  className="filter-tag"
                >
                  <span>{item?.label}</span>
                </Tag>
              ))}
            </div>
          )}
          <div
            className="filter-checkboxes"
            onScroll={(e) => onFilterScroll(e, dataIndex)}
          >
            {filterList?.length > 0
              ? map(filterList, (item) => (
                  <div className="single-checkbox-div" key={item?.key}>
                    <Checkbox
                      value={item}
                      checked={whereFilter?.[dataIndex]?.includes(item?.key)}
                      key={item?.key || item}
                      onChange={(e) => changeFilter(e, dataIndex)}
                      className="single-checkbox"
                    >
                      <span>{item?.label}</span>
                    </Checkbox>
                  </div>
                ))
              : isEmptyFilterList && (
                  <Empty image={Empty?.PRESENTED_IMAGE_SIMPLE} />
                )}
          </div>
        </LoaderComponent>
        <Divider className="divider-filter" />
        <div className="d-flex justify-center">
          <Button
            size="small"
            className="reset-button"
            id="roles-filter-reset"
            onClick={() => handleReset(clearFilters, dataIndex, confirm)}
          >
            Reset
          </Button>
          <Button
            size="small"
            className="common-button ok-button"
            id="roles-filter-ok"
            type="primary"
            onClick={() => getFilterData(confirm, dataIndex)}
          >
            Ok
          </Button>
        </div>
      </div>
    ),
    filterIcon: () =>
      finalWhereFilters?.[dataIndex]?.length > 0 ||
      !isEmpty(finalWhereFilters?.[dataIndex]) ? (
        <FilterFilled className="filter-icon filter-filled" />
      ) : (
        <FilterOutlined className="filter-icon" />
      ),
    onFilterDropdownOpenChange: (isVisible) => {
      setFilterIndex(dataIndex);
      setFilterVisible(isVisible);
    },
  });

  const columns = [
    {
      title: 'PRODUCT NAME',
      dataIndex: 'name',
      key: 'name',
      ellipsis: true,
      sorter: true,
      width: 250,
      align: 'left',
      className: 'max-width-column',
      // eslint-disable-next-line no-undef
      fixed: window.innerWidth > BREAKPOINTS.desktop ? 'left' : false,
      sortOrder: sortedInfo?.columnKey === 'name' && sortedInfo?.order,
      render: (_, record) => (
        <>
          <div className="table-data-with-id">
            {record?.libraryInventory?.toy?.name}
          </div>
          <div className="font-size-12">
            ({record?.libraryInventory?.toy?.toyNumber})
          </div>
        </>
      ),
    },
    {
      title: 'IMAGES',
      dataIndex: 'images',
      key: 'images',
      ellipsis: true,
      width: 100,
      align: 'left',
      className: 'max-width-column',
      render: (_, record) => (
        <span>
          {record?.libraryInventory?.toy?.images?.length > 0 ? (
            <>
              <Image
                preview={{
                  visible: false,
                }}
                width={20}
                height={20}
                src={record?.libraryInventory?.toy?.images?.[0]?.url}
                onClick={(e) => {
                  e?.stopPropagation();
                  setVisible(true);
                  setToyImages(record?.libraryInventory?.toy?.images);
                }}
                className="pointer"
              />
            </>
          ) : (
            <span>No Images</span>
          )}
          {record?.libraryInventory?.toy?.images?.length > 1
            ? ` + ${record?.libraryInventory?.toy?.images?.length - 1}`
            : ''}
        </span>
      ),
    },
    {
      title: 'CATEGORIES',
      dataIndex: 'category',
      key: 'category',
      width: 300,
      align: 'left',
      className: 'max-width-column',
      render: (_, record) =>
        record?.libraryInventory?.toy?.categories?.map((category) => (
          <Tag key={category?.id}>{category?.name}</Tag>
        )),
      ...filterPopup('category'),
    },
    {
      title: 'OLD QUANTITY',
      dataIndex: 'oldQuantity',
      key: 'oldQuantity',
      ellipsis: true,
      width: 150,
      align: 'left',
      className: 'max-width-column',
    },
    {
      title: 'UPDATED QUANTITY',
      dataIndex: 'updatedQuantity',
      key: 'updatedQuantity',
      ellipsis: true,
      width: 150,
      align: 'left',
      className: 'max-width-column',
    },
    {
      title: 'REASON',
      dataIndex: 'reason',
      key: 'reason',
      ellipsis: true,
      width: 150,
      align: 'left',
      className: 'max-width-column table-tooltip-cell',
      render: (_, record) => (
        <Tooltip
          title={record?.reason ? record?.reason : 'No Reason'}
          placement="topLeft"
          trigger="click"
          className="pointer"
        >
          {record?.reason || '-'}
        </Tooltip>
      ),
    },
  ];

  const locale = {
    emptyText: isEmptyInventoryList ? '' : <span />,
  };

  return (
    <>
      <CommonPreviewTable
        visible={visible}
        setVisible={setVisible}
        images={toyImages}
        setImages={setToyImages}
      />
      <div className="d-flex justify-end mb-16 align-center">
        <SearchComponent
          className={isDesktop ? 'search-box' : ''}
          query={query}
          setQuery={setQuery}
          getData={handleSearch}
        />
        {!isDesktop && (
          <>
            <Badge dot={hasNonEmptyValueObj(finalWhereFilters)} size="small">
              <FilterIcon
                className="mobile-svg-icon ml-16"
                onClick={() => setIsAllFilterModalOpen(true)}
              />
            </Badge>
            <div className="ml-16 d-flex align-center">
              <SortDropdown
                isOpen={isAllSortPopoverOpen}
                setIsOpen={setIsAllSortPopoverOpen}
                options={map(sort, (item) => item)}
                sortedInfo={sortedInfo}
                setSortedInfo={setSortedInfo}
                onFinish={handleSort}
              />
            </div>
          </>
        )}
      </div>
      {isDesktop ? (
        <CommonTable
          locale={locale}
          columns={columns}
          data={inventoryList || []}
          loading={isInventoryLoading}
          onChange={handleTableChange}
          paginationConfig={paginationProp}
          rowKey={(record) => record?.id}
        />
      ) : (
        <div className="mobile-container no-scroll-bar">
          <AllFilterBulkUpdateLogModal
            onFinish={handleFilter}
            isModalOpen={isAllFilterModalOpen}
            setIsModalOpen={setIsAllFilterModalOpen}
            finalWhereFilters={finalWhereFilters}
            setFinalWhereFilters={setFinalWhereFilters}
          />
          <BulkUpdateLogList
            inventoryList={inventoryList}
            setVisible={setVisible}
            setToyImages={setToyImages}
            infiniteScrollRef={infiniteScrollRef}
            isFetchMoreToysLoading={isFetchMoreToysLoading}
            isEmptyInventoryList={isEmptyInventoryList}
            isInventoryLoading={isInventoryLoading}
          />
        </div>
      )}
    </>
  );
};

export default BulkUpdateLogTable;
