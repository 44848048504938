import { Card } from 'antd';
import React from 'react';
import { MODULES } from '../../../../../common/constants';
import Portal from '../../../../../components/Portal';
import BulkTable from '../components/BulkTable';

const Bulk = () => (
  <>
    <Portal portalId="header-title-content">
      <div className="portal-header">{MODULES?.BULK_UPDATE}</div>
    </Portal>
    <Card className="full-height-without-nav">
      <BulkTable />
    </Card>
  </>
);

export default Bulk;
