import React from 'react';
import { Route, Switch } from 'react-router-dom';
import { ROUTES } from '../../common/constants';
import RackMasterInventory from './components/RackMasterInventory';
import RackMaster from './pages/RackMaster';

const RackMasterWrapper = () => (
  <Switch>
    <Route exact path={ROUTES?.RACK_MASTER} render={RackMaster} />
    <Route
      exact
      path={`${ROUTES?.RACK_MASTER}/view/:rackId`}
      component={RackMasterInventory}
    />
  </Switch>
);

export default RackMasterWrapper;
