import { gql } from '@apollo/client';

// eslint-disable-next-line import/prefer-default-export
export const GIFT_CARDS = gql`
  query GiftCards($filter: GiftCardsFilter, $sort: GiftCardsSort) {
    giftCards(filter: $filter, sort: $sort) {
      data {
        sender {
          email
          contactNo
          name
        }
        shippingAddress {
          name
          line1
          line2
          city
          phone
          state
          postalCode
        }
        billingAddress {
          name
          line1
          line2
          city
          phone
          state
          postalCode
        }
        user {
          id
          lastName
          firstName
          userNumber
        }
        subscription {
          price
          name
          type
        }
        status
        notes
        isArchived
        id
        expiredAt
        startedAt
        createdAt
        code
      }
      count
    }
  }
`;

export const GIFT_CARD = gql`
  query GiftCard($where: GiftCardUniqueInput!) {
    giftCard(where: $where) {
      data {
        user {
          id
          lastName
          firstName
          userNumber
        }
        subscription {
          id
          price
          name
          type
        }
        status
        notes
        isArchived
        id
        expiredAt
        startedAt
        createdAt
        code
      }
    }
  }
`;
