import { useLazyQuery } from '@apollo/client';
import { Tooltip } from 'antd';
import React, { useContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { AppContext } from '../../../../../AppContext';
import { ReactComponent as CommentIcon } from '../../../../../assets/svg/comment-dots-solid.svg';
import {
  DATE_FORMATS,
  LIMIT,
  PAYMENT_PROVIDERS_TYPE,
  PLAN_NAMES,
  PLAN_STATUS,
  PLAN_TYPES,
  ROUTES,
  SORT,
  SUBSCRIPTION_CANCELLED_TYPES,
} from '../../../../../common/constants';
import { formatDate } from '../../../../../common/utils';
import CommonTable from '../../../../../components/CommonTable';
import { USER_SUBSCRIPTION_HISTORIES } from '../graphql/Queries';
import SubscriptionNoteModal from './SubscriptionNoteModal';

const initialPaginationValue = {
  total: 0,
  current: 1,
  pageSize: 10,
};

const initialInflowFilter = {
  limit: 10,
  search: '',
  skip: 0,
};

const initialInflowSort = {
  field: 'createdAt',
  order: 'DESC',
};

const SubscriptionHistoryTable = ({ userId }) => {
  const {
    state: {
      commonPermissions: { isUserPermission },
    },
  } = useContext(AppContext);
  const history = useHistory();
  const [statusList, setStatusList] = useState([]);
  const [isEmptyStatusList, setIsEmptyStatusList] = useState(false);
  const [subscriptionDetails, setSubscriptionDetails] = useState('');
  const [paginationProp, setPaginationProp] = useState(initialPaginationValue);
  const [isStatusLoading, setIsStatusLoading] = useState(true);
  const [isNotePrompts, setIsNotePrompts] = useState(false);

  const [userSubscriptionHistories] = useLazyQuery(
    USER_SUBSCRIPTION_HISTORIES,
    {
      onCompleted: (response) => {
        setStatusList([...response?.userSubscriptionHistories?.data]);
        if (
          response?.userSubscriptionHistories?.count === 0 &&
          initialPaginationValue?.total === 0
        ) {
          setIsEmptyStatusList(true);
        } else {
          setIsEmptyStatusList(false);
        }
        const pagination = {
          ...paginationProp,
          defaultPageSize: LIMIT,
          total: response?.userSubscriptionHistories?.count,
        };
        setPaginationProp(pagination);
        setIsStatusLoading(false);
      },
      fetchPolicy: 'network-only',
      onError() {},
    },
  );

  useEffect(() => {
    setPaginationProp({ ...paginationProp, current: 1, skip: 0 });
    setIsStatusLoading(true);
    userSubscriptionHistories({
      variables: {
        where: userId && {
          id: userId,
        },
        filter: {
          ...initialInflowFilter,
          limit: paginationProp?.pageSize || LIMIT,
        },
        sort: initialInflowSort,
      },
    });
  }, []);

  const handleTableChange = (pagination, tableFilter, sorter) => {
    const { current } = pagination;
    const skip = (current - 1) * (pagination?.pageSize || 0);
    setPaginationProp({ ...paginationProp, ...pagination });
    setIsStatusLoading(true);
    userSubscriptionHistories({
      variables: {
        where: userId && {
          id: userId,
        },
        filter: {
          ...initialInflowFilter,
          skip,
          limit: pagination?.pageSize,
        },
        sort: sorter?.column
          ? {
              field: sorter?.field,
              order: sorter?.order === 'ascend' ? SORT?.ASC : SORT?.DESC,
            }
          : initialInflowSort,
      },
    });
  };

  const columns = [
    {
      title: 'USER NAME',
      dataIndex: 'name',
      key: 'name',
      ellipsis: true,
      width: 250,
      align: 'left',
      className: `max-width-column`,
      onCell: (record) => ({
        onClick: () =>
          isUserPermission &&
          record?.user?.id &&
          history?.push(
            `${ROUTES?.SYSTEM_USERS}${ROUTES?.END_USERS}${ROUTES?.BASIC_DETAILS}/view/${record?.user?.id}/${record?.user?.userNumber}`,
          ),
        className: `${
          isUserPermission && record?.user?.id && 'pointer table-cell'
        }`,
      }),
      render: (_, record) => {
        const userData = record?.user;
        return (
          <>
            {userData?.firstName || userData?.lastName ? (
              <div className="table-data-with-id">{`${
                userData?.firstName ? userData?.firstName : ''
              } ${userData?.lastName ? userData?.lastName : ''}`}</div>
            ) : (
              '-'
            )}
            {userData?.userNumber ? (
              <div className="font-size-12">({userData?.userNumber})</div>
            ) : (
              ''
            )}
          </>
        );
      },
    },
    {
      title: 'GENERATED ON',
      dataIndex: 'generatedOn',
      key: 'generatedOn',
      width: 150,
      ellipsis: true,
      align: 'left',
      className: 'max-width-column',
      render: (_, record) =>
        formatDate(record?.createdAt, DATE_FORMATS?.REGULAR) || '-',
    },
    {
      title: 'PLAN SUBSCRIBED',
      dataIndex: 'planSubscribed',
      key: 'planSubscribed',
      ellipsis: true,
      width: 150,
      align: 'left',
      className: 'max-width-column',
      render: (_, record) =>
        PLAN_NAMES?.[record?.subscriptionPlan?.name]?.key || '-',
    },
    {
      title: 'BILLING CYCLE',
      dataIndex: 'billingCycle',
      key: 'billingCycle',
      ellipsis: true,
      width: 150,
      align: 'left',
      className: 'max-width-column',
      render: (_, record) => {
        const planTypeKey = PLAN_TYPES?.[record?.subscriptionPlan?.type]?.key;
        const planVersion = record?.subscriptionFeatures?.planVersion;
        if (!planTypeKey && !planVersion) {
          return '-';
        }
        return `${planTypeKey || ''} ${planVersion ? `(${planVersion})` : ''}`;
      },
    },
    {
      title: 'STATUS',
      dataIndex: 'status',
      key: 'status',
      ellipsis: true,
      width: 200,
      align: 'left',
      className: 'max-width-column',
      render: (_, record) =>
        record?.status ? PLAN_STATUS?.[record?.status]?.text : '-',
    },
    {
      title: 'AMOUNT',
      dataIndex: 'amount',
      key: 'amount',
      width: 150,
      ellipsis: true,
      align: 'left',
      className: 'max-width-column',
      render: (_, record) => {
        const gst = record?.metaData?.taxesApplied?.percentage
          ? record?.price * (record?.metaData?.taxesApplied?.percentage / 100)
          : 0;
        return record?.price + gst;
      },
    },
    {
      title: 'PAYMENT PROVIDER',
      dataIndex: 'paymentProvider',
      key: 'paymentProvider',
      ellipsis: true,
      width: 180,
      align: 'left',
      className: 'max-width-column',
      render: (_, record) =>
        PAYMENT_PROVIDERS_TYPE?.[record?.metaData?.paymentProvider]?.label ||
        '-',
    },
    {
      title: 'STARTED AT',
      dataIndex: 'startedAt',
      key: 'startedAt',
      width: 150,
      ellipsis: true,
      align: 'left',
      className: 'max-width-column',
      render: (_, record) =>
        formatDate(record?.startedAt, DATE_FORMATS?.REGULAR) || '-',
    },
    {
      title: 'EXPIRE AT',
      dataIndex: 'expireAt',
      key: 'expireAt',
      width: 150,
      ellipsis: true,
      align: 'left',
      className: 'max-width-column',
      render: (_, record) =>
        formatDate(record?.expireAt, DATE_FORMATS?.REGULAR) || '-',
    },
    {
      title: 'CANCELLED AT',
      dataIndex: 'cancelledAt',
      key: 'cancelledAt',
      width: 150,
      ellipsis: true,
      align: 'left',
      className: 'max-width-column',
      render: (_, record) =>
        formatDate(record?.cancelledAt, DATE_FORMATS?.REGULAR) || '-',
    },
    {
      title: 'CANCELLED TYPE',
      dataIndex: 'cancelledType',
      key: 'cancelledType',
      ellipsis: true,
      width: 150,
      align: 'left',
      className: 'max-width-column',
      render: (value) => SUBSCRIPTION_CANCELLED_TYPES?.[value]?.label || '-',
    },
    {
      title: 'CANCELLATION NOTE',
      dataIndex: 'note',
      key: 'note',
      ellipsis: true,
      align: 'left',
      width: 180,
      className: 'max-width-column',
      render: (_, record) => (
        <div className="action-button">
          {SUBSCRIPTION_CANCELLED_TYPES?.[record?.cancelledType] ? (
            <Tooltip title="View comment" placement="top" zIndex={4}>
              <CommentIcon
                className="mr-24 pointer svg-icon"
                onClick={() => {
                  setSubscriptionDetails(record);
                  setIsNotePrompts(true);
                }}
              />
            </Tooltip>
          ) : (
            <span className="mr-24">-</span>
          )}
        </div>
      ),
    },
  ];

  const locale = {
    emptyText: isEmptyStatusList ? '' : <span />,
  };

  return (
    <>
      <CommonTable
        locale={locale}
        columns={columns}
        data={statusList || []}
        loading={isStatusLoading}
        onChange={handleTableChange}
        paginationConfig={paginationProp}
        rowKey={(record) => record?.id}
      />
      <SubscriptionNoteModal
        note={subscriptionDetails}
        isModalOpen={isNotePrompts}
        setIsModalOpen={setIsNotePrompts}
      />
    </>
  );
};

export default SubscriptionHistoryTable;
