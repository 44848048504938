import React from 'react';
import ModalComponent from '../../../components/ModalComponent';

const ToyVideoModal = ({
  isModalOpen,
  setIsModalOpen,
  videoUrl,
  title,
  onCancel,
  isIframe = false,
}) => (
  <ModalComponent
    title={title}
    open={isModalOpen}
    onCancel={() => {
      setIsModalOpen(false);
      if (onCancel) {
        onCancel();
      }
    }}
    footer={null}
    className="toy-video-modal"
    destroyOnClose
  >
    {isIframe ? (
      <iframe
        width="100%"
        height="325"
        title="toy-video"
        src={videoUrl || ''}
        allowFullScreen
        frameBorder="0"
      />
    ) : (
      <video
        width="100%"
        height="100%"
        controls
        src={videoUrl || ''}
        className="toy-video"
        autoPlay
      >
        <track kind="captions" />
      </video>
    )}
  </ModalComponent>
);

export default ToyVideoModal;
