import React, { useContext } from 'react';
import { Route, Switch } from 'react-router-dom';
import AccessControl from '../../../../AccessControl';
import { MENU_ITEMS } from '../../../../app/components/sidebar/Sidebar';
import { AppContext } from '../../../../AppContext';
import { MODULES, PERMISSIONS_KEY, ROUTES } from '../../../../common/constants';
import { getRedirectSubmenuItem } from '../../../../common/utils';
import Error404 from '../../../../Error404';
import RedirectControl from '../../../../RedirectControl';
import AllDelivery from './pages/AllDelivery';
import AllReturn from './pages/AllReturn';

const tabs = MENU_ITEMS?.find(
  (menuItem) => menuItem?.key === MODULES?.ORDERS,
)?.subMenu?.find((menu) => menu?.key === MODULES?.ALL)?.tabKeys;

const AllOrdersWrapper = () => {
  const {
    state: { permissions },
  } = useContext(AppContext);

  const redirectArray = getRedirectSubmenuItem(permissions, tabs);

  return (
    <Switch>
      <Route
        exact
        path={`${ROUTES?.ORDERS}${ROUTES?.ALL}`}
        render={() => (
          <RedirectControl
            redirectArray={redirectArray}
            route={`${ROUTES?.ORDERS}${ROUTES?.ALL}`}
          />
        )}
      />
      <Route
        exact
        path={`${ROUTES?.ORDERS}${ROUTES?.ALL}${ROUTES?.DELIVERY}`}
        render={(props) => (
          <AccessControl permissionKey={PERMISSIONS_KEY?.ORDERS_ALL_DELIVERY}>
            <AllDelivery {...props} />
          </AccessControl>
        )}
      />
      <Route
        exact
        path={`${ROUTES?.ORDERS}${ROUTES?.ALL}${ROUTES?.RETURN}`}
        render={(props) => (
          <AccessControl permissionKey={PERMISSIONS_KEY?.ORDERS_ALL_RETURN}>
            <AllReturn {...props} />
          </AccessControl>
        )}
      />
      <Route path="*" exact component={Error404} />
    </Switch>
  );
};

export default AllOrdersWrapper;
