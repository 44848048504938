import { Col, Form, Input, List, Radio, Row } from 'antd';
import React, { useEffect, useState } from 'react';
import {
  ADMIN_ROLES,
  GENDERS,
  ROLE_FORM_OPTIONS,
  USER_FORM_STATUS,
} from '../../../../../common/constants';
import { formValidatorRules } from '../../../../../common/utils';
import CitySelect from '../../../../../components/CitySelect';
import CountrySelect from '../../../../../components/CountrySelect';
import PincodeSelectName from '../../../../../components/PincodeSelectName';
import SelectComponent from '../../../../../components/SelectComponent';
import StateSelect from '../../../../../components/StateSelect';
import BasicAdminPermission from './BasicAdminPermission';
import OrderInventoryPermission from './OrderInventoryPermission';
import PowerAdminPermission from './PowerAdminPermission';

const { Item } = Form;
const { Group } = Radio;
const { required, email, name, phone } = formValidatorRules;

const AdminForm = ({
  form,
  onFinish,
  handleShowPrompt,
  initialValues,
  isView,
  adminDetail,
}) => {
  const [adminRole, setAdminRole] = useState(
    initialValues?.role || ADMIN_ROLES?.POWER_ADMIN?.key,
  );
  const [validationTriggered, setValidationTriggered] = useState(false);
  const [countryId, setCountryId] = useState('');
  const [stateId, setStateId] = useState(initialValues?.state);
  const [isStateIdChanged, setIsStateIdChanged] = useState(false);

  useEffect(() => {
    const initialValueFun = () => {
      form?.setFieldsValue({
        ...initialValues,
        country: countryId,
      });
    };
    setTimeout(initialValueFun, 300);
  }, [countryId]);

  const onFinishFailed = () => {
    setValidationTriggered(true);
  };

  const onRoleChange = ({ target: { value } }) => {
    setAdminRole(value);
  };

  return (
    <Form
      layout="vertical"
      form={form}
      onFinish={onFinish}
      onFieldsChange={handleShowPrompt}
      disabled={!!isView || adminDetail?.isArchived}
      onFinishFailed={onFinishFailed}
      className={isView ? 'common-view-form' : 'form'}
      validateTrigger={validationTriggered ? 'onChange' : 'onSubmit'}
    >
      <Row gutter={[16, 0]}>
        <Col xs={24} sm={12} md={12} lg={8} xl={8} xxl={8}>
          <Item
            label="First Name"
            name="firstName"
            rules={[{ ...required, message: 'Please Enter First Name!' }, name]}
          >
            <Input allowClear placeholder="Enter First Name" maxLength={50} />
          </Item>
        </Col>
        <Col xs={24} sm={12} md={12} lg={8} xl={8} xxl={8}>
          <Item
            label="Last Name"
            name="lastName"
            rules={[{ ...required, message: 'Please Enter Last Name!' }, name]}
          >
            <Input allowClear placeholder="Enter Last Name" maxLength={50} />
          </Item>
        </Col>
        <Col xs={24} sm={12} md={12} lg={8} xl={8} xxl={8}>
          <Item
            label="Email ID"
            name="email"
            rules={[{ ...required, message: 'Please Enter Email!' }, email]}
          >
            <Input allowClear placeholder="Enter Email" maxLength={255} />
          </Item>
        </Col>
        <Col xs={24} sm={12} md={12} lg={8} xl={8} xxl={8}>
          <Item
            label="Gender"
            name="gender"
            rules={[
              {
                required: true,
                message: 'Please Enter Gender!',
              },
            ]}
          >
            <SelectComponent placeholder="Select Gender" options={GENDERS} />
          </Item>
        </Col>
        <Col xs={24} sm={12} md={12} lg={8} xl={8} xxl={8}>
          <Item
            label="Contact Details"
            name="contact"
            rules={[
              {
                ...required,
                message: 'Please Enter Contact Details!',
              },
              phone,
            ]}
          >
            <Input
              allowClear
              placeholder="Enter Contact Details"
              maxLength={12}
            />
          </Item>
        </Col>
        <Col xs={24} sm={12} md={12} lg={8} xl={8} xxl={8}>
          <Item
            label="Address"
            name="address"
            rules={[
              {
                ...required,
                message: 'Please Enter Address!',
              },
            ]}
          >
            <Input allowClear placeholder="Enter Address" maxLength={250} />
          </Item>
        </Col>
        <Col xs={24} sm={12} md={12} lg={6} xl={6} xxl={6}>
          <Form.Item
            label="Country"
            name="country"
            rules={[{ required: true, message: 'Please Select Country!' }]}
          >
            <CountrySelect setCountryId={setCountryId} />
          </Form.Item>
        </Col>
        <Col xs={24} sm={12} md={12} lg={6} xl={6} xxl={6}>
          <Form.Item
            label="State"
            name="state"
            rules={[{ required: true, message: 'Please Select State!' }]}
          >
            <StateSelect
              countryId={countryId}
              setStateId={setStateId}
              userData={adminDetail}
              onChange={(value) => {
                form?.setFieldsValue({
                  city: null,
                });
                setStateId(value);
                setIsStateIdChanged(true);
              }}
              isView={isView}
            />
          </Form.Item>
        </Col>
        <Col xs={24} sm={12} md={12} lg={6} xl={6} xxl={6}>
          <Form.Item
            label="City"
            name="city"
            rules={[{ required: true, message: 'Please Select City!' }]}
          >
            <CitySelect
              stateId={stateId}
              userData={adminDetail}
              isView={isView}
              isStateIdChanged={isStateIdChanged}
            />
          </Form.Item>
        </Col>
        <Col xs={24} sm={12} md={12} lg={6} xl={6} xxl={6}>
          <Form.Item
            label="Pincode"
            name="pincode"
            rules={[{ required: true, message: 'Please Enter Pincode!' }]}
          >
            <PincodeSelectName userData={adminDetail} />
          </Form.Item>
        </Col>
        <Col span={24}>
          <Item
            label="Status"
            name="status"
            initialValue={!initialValues}
            required
          >
            <Group options={USER_FORM_STATUS} />
          </Item>
        </Col>
        <Col span={24}>
          <Item
            label="Admin Roles"
            name="role"
            rules={[{ required: true, message: 'Please Select Admin Role!' }]}
            initialValue={ADMIN_ROLES?.POWER_ADMIN?.key}
          >
            <Group options={ROLE_FORM_OPTIONS} onChange={onRoleChange} />
          </Item>
        </Col>
      </Row>
      <Item label="Permissions">
        <List>
          {adminRole === ADMIN_ROLES?.BASIC_ADMIN?.key && (
            <BasicAdminPermission />
          )}
          {adminRole === ADMIN_ROLES?.POWER_ADMIN?.key && (
            <PowerAdminPermission />
          )}
          {adminRole === ADMIN_ROLES?.ORDER_INVENTORY_ADMIN?.key && (
            <OrderInventoryPermission />
          )}
        </List>
      </Item>
    </Form>
  );
};

export default AdminForm;
