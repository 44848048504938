import { FilterFilled, FilterOutlined } from '@ant-design/icons';
import { useLazyQuery, useMutation } from '@apollo/client';
import {
  Badge,
  Button,
  Checkbox,
  DatePicker,
  Divider,
  Empty,
  Form,
  Modal,
  Radio,
  Tag,
  Tooltip,
} from 'antd';
import { debounce, filter, forEach, isEmpty, map } from 'lodash';
import React, { useContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';
import { AppContext } from '../../../../../AppContext';
import { ReactComponent as EditIcon } from '../../../../../assets/svg/pen-to-square-solid.svg';
import {
  BREAKPOINTS,
  DATE_FORMATS,
  LIMIT,
  PAYMENT_STATUS,
  PAYMENT_STATUS_OPTIONS,
  PERMISSIONS_KEY,
  PERMISSION_TYPE,
  ROUTES,
  SORT,
} from '../../../../../common/constants';
import {
  fileUpload,
  formatDate,
  hasPermission,
  openNotification,
} from '../../../../../common/utils';
import CommonTable from '../../../../../components/CommonTable';
import LoaderComponent from '../../../../../components/LoaderComponent';
import SearchComponent from '../../../../../components/SearchComponent';
import { LIBRARIES } from '../../../../requests/modules/library/graphql/Queries';
import { CREATE_INVOICE, UPDATE_INVOICE } from '../graphql/Mutations';
import {
  GET_ATTACHMENT_SIGNED_PUT_URL,
  INVOICES_ADMIN,
} from '../graphql/Queries';
import InvoiceModal from './InvoiceModal';

const { RangePicker } = DatePicker;
const { Group } = Radio;

const initialPaginationValue = {
  total: 0,
  current: 1,
  pageSize: 10,
};

const initialInvoiceFilter = {
  limit: LIMIT,
  search: '',
  skip: 0,
};

const initialInvoiceSort = {
  field: 'createdAt',
  order: 'DESC',
};

const initialTableFilter = {
  skip: 0,
  limit: LIMIT,
  search: '',
};

const initialTableSort = {
  field: 'createdAt',
  order: 'DESC',
};

let filterScrollDebounceJob = null;

const LibraryTable = ({
  isInvoiceModalOpen,
  setIsInvoiceModalOpen,
  isEditMode,
  setIsEditMode,
}) => {
  const {
    state: {
      permissions,
      commonPermissions: { isLibraryPermission },
    },
  } = useContext(AppContext);
  const history = useHistory();
  const [invoiceForm] = Form.useForm();
  const [invoiceList, setInvoiceList] = useState([]);
  const [invoiceSearchTerm, setInvoiceSearchTerm] = useState('');
  const [isEmptyInvoiceList, setIsEmptyInvoiceList] = useState(false);
  const [query, setQuery] = useState('');
  const [invoice, setInvoice] = useState();
  const [sortedInfo, setSortedInfo] = useState({});
  const [paginationProp, setPaginationProp] = useState(initialPaginationValue);
  const [invoiceFile, setInvoiceFile] = useState([]);

  const [isInvoiceLoading, setIsInvoiceLoading] = useState(true);
  const [isInvoiceBtnLoading, setIsInvoiceBtnLoading] = useState(false);
  const [isResolveLoading, setIsResolveLoading] = useState(false);
  const [isResolvePrompts, setIsResolvePrompts] = useState(false);

  const [filterSearch, setFilterSearch] = useState('');
  const [filterList, setFilterList] = useState([]);
  const [whereFilter, setWhereFilter] = useState();
  const [filterLoading, setFilterLoading] = useState(false);
  const [filterTags, setFilterTags] = useState(null);
  const [filterVisible, setFilterVisible] = useState(false);
  const [filterIndex, setFilterIndex] = useState(null);
  const [finalWhereFilters, setFinalWhereFilters] = useState();
  const [finalFilterTags, setFinalFilterTags] = useState(null);
  const [filterIsEnd, setFilterIsEnd] = useState(false);
  const [isEmptyFilterList, setIsEmptyFilterList] = useState(false);

  const [invoicesAdmin] = useLazyQuery(INVOICES_ADMIN, {
    onCompleted: (response) => {
      setInvoiceList([...response?.invoicesAdmin?.data]);
      if (
        response?.invoicesAdmin?.count === 0 &&
        initialPaginationValue?.total === 0
      ) {
        setIsEmptyInvoiceList(true);
      } else {
        setIsEmptyInvoiceList(false);
      }
      const pagination = {
        ...paginationProp,
        defaultPageSize: LIMIT,
        total: response?.invoicesAdmin?.count,
      };
      setPaginationProp(pagination);
      setIsInvoiceLoading(false);
    },
    fetchPolicy: 'network-only',
    onError() {},
  });

  const [libraries, { loading: isLibrariesLoading }] = useLazyQuery(LIBRARIES, {
    onCompleted: (response) => {
      if (response?.libraries?.data?.length < LIMIT) {
        setFilterIsEnd(true);
      }
      if (response?.libraries?.count === 0) {
        setIsEmptyFilterList(true);
      } else {
        setIsEmptyFilterList(false);
      }
      const optionsCopy = [];
      forEach(response?.libraries?.data, (item) =>
        optionsCopy?.push({ label: item?.name, key: item?.id }),
      );
      setFilterList([...filterList, ...optionsCopy]);
      setFilterLoading(false);
    },
    fetchPolicy: 'network-only',
    onError() {
      setFilterLoading(false);
    },
  });

  const [getAttachmentSignedPutUrl] = useLazyQuery(
    GET_ATTACHMENT_SIGNED_PUT_URL,
    {
      fetchPolicy: 'network-only',
      onError() {},
    },
  );

  const [createInvoice] = useMutation(CREATE_INVOICE, {
    onError: () => {},
  });
  const [updateInvoice] = useMutation(UPDATE_INVOICE, {
    onError: () => {},
  });

  useEffect(() => {
    setPaginationProp({ ...paginationProp, current: 1, skip: 0 });
    setIsInvoiceLoading(true);
    invoicesAdmin({
      variables: {
        filter: {
          ...initialInvoiceFilter,
          search: invoiceSearchTerm,
          limit: LIMIT,
        },
        sort: initialInvoiceSort,
      },
    });
  }, []);

  const handleTableChange = (pagination, tableFilter, sorter) => {
    const { current } = pagination;
    const skip = (current - 1) * (pagination?.pageSize || 0);
    setPaginationProp({ ...paginationProp, ...pagination });
    setIsInvoiceLoading(true);
    setSortedInfo(sorter);
    invoicesAdmin({
      variables: {
        filter: {
          ...initialInvoiceFilter,
          skip,
          limit: pagination?.pageSize,
          search: invoiceSearchTerm,
          libraryIds: finalWhereFilters?.name,
          createdAt: {
            from: finalWhereFilters?.createdAt?.from?.$d,
            to: finalWhereFilters?.createdAt?.to?.$d,
          },
          paidOn: {
            from: finalWhereFilters?.paidOn?.from?.$d,
            to: finalWhereFilters?.paidOn?.to?.$d,
          },
          status: finalWhereFilters?.status,
        },
        sort: sorter?.column
          ? {
              field: sorter?.field,
              order: sorter?.order === 'ascend' ? SORT?.ASC : SORT?.DESC,
            }
          : initialInvoiceSort,
      },
    });
  };

  const handleSearch = (value) => {
    const trimValue = value?.trim();
    setInvoiceSearchTerm(trimValue);
    setPaginationProp({ ...paginationProp, current: 1, skip: 0 });
    setIsInvoiceLoading(true);
    invoicesAdmin({
      variables: {
        filter: {
          ...initialInvoiceFilter,
          limit: paginationProp?.pageSize || LIMIT,
          search: trimValue,
          libraryIds: finalWhereFilters?.name,
          createdAt: {
            from: finalWhereFilters?.createdAt?.from?.$d,
            to: finalWhereFilters?.createdAt?.to?.$d,
          },
          paidOn: {
            from: finalWhereFilters?.paidOn?.from?.$d,
            to: finalWhereFilters?.paidOn?.to?.$d,
          },
          status: finalWhereFilters?.status,
        },
        sort: sortedInfo?.column
          ? {
              field: sortedInfo?.field,
              order: sortedInfo?.order === 'ascend' ? SORT?.ASC : SORT?.DESC,
            }
          : initialInvoiceSort,
      },
    });
  };

  const handleInvoice = async (values) => {
    if (invoiceFile?.length > 0) {
      setIsInvoiceBtnLoading(true);
      const uuid = uuidv4();
      let invoiceFileName = '';

      if (!invoiceFile?.[0]?.name || values?.invoiceFile?.file) {
        const { name } = values?.invoiceFile?.file;
        const ext = name?.substring(name?.lastIndexOf('.') + 1);
        const timestamp = Date?.now();
        const filename = name?.split('.')?.slice(0, -1)?.join('.');
        const newFilename = `${timestamp}_${filename}.${ext}`;
        const fileKey = `invoice/${uuid}/${newFilename}`;

        const res = await getAttachmentSignedPutUrl({
          variables: {
            data: {
              fileName: fileKey,
              libraryId: values?.libraryName,
            },
          },
        });

        if (res) {
          const { signedUrl, fileName } = res?.data?.getAttachmentSignedPutUrl;
          try {
            await fileUpload(signedUrl, values?.invoiceFile?.file)?.catch(
              (error) => {
                if (error === 403) {
                  openNotification(
                    'error',
                    `${values?.invoiceFile?.file?.name
                      ?.toString()
                      ?.toUpperCase()} upload failed. Please try again.`,
                  );
                }
              },
            );
            invoiceFileName = fileName;
          } catch (error) {
            setIsInvoiceBtnLoading(false);
            return error;
          }
        }
      }
      try {
        let response;
        if (!invoice) {
          response = await createInvoice({
            variables: {
              data: {
                amount: parseFloat(values?.amount),
                attachment: invoiceFileName,
                libraryId: values?.libraryName,
                reason: values?.reason,
              },
            },
          });
        } else {
          response = await updateInvoice({
            variables: {
              where: {
                id: invoice?.id,
              },
              data: {
                amount: parseFloat(values?.amount),
                attachment: invoiceFileName || undefined,
                reason: values?.reason,
              },
            },
          });
        }
        if (response?.data) {
          setIsInvoiceModalOpen(false);
          setIsInvoiceBtnLoading(false);
          setIsInvoiceLoading(true);
          setInvoice({});
          invoicesAdmin({
            variables: {
              filter: {
                ...initialInvoiceFilter,
                skip:
                  (paginationProp?.current - 1) *
                  (paginationProp?.pageSize || 0),
                limit: paginationProp?.pageSize || LIMIT,
                search: invoiceSearchTerm,
                libraryIds: finalWhereFilters?.name,
                createdAt: {
                  from: finalWhereFilters?.createdAt?.from?.$d,
                  to: finalWhereFilters?.createdAt?.to?.$d,
                },
                paidOn: {
                  from: finalWhereFilters?.paidOn?.from?.$d,
                  to: finalWhereFilters?.paidOn?.to?.$d,
                },
                status: finalWhereFilters?.status,
              },
              sort: sortedInfo?.column
                ? {
                    field: sortedInfo?.field,
                    order:
                      sortedInfo?.order === 'ascend' ? SORT?.ASC : SORT?.DESC,
                  }
                : initialInvoiceSort,
            },
          });
        }
      } catch (error) {
        return error;
      }
      setIsInvoiceBtnLoading(false);
    }
  };

  const handleResolve = async (id) => {
    setIsResolveLoading(true);
    const response = await updateInvoice({
      variables: {
        where: {
          id,
        },
        data: {
          status: PAYMENT_STATUS?.PAID?.value,
        },
      },
    });
    if (response?.data) {
      setIsResolveLoading(true);
      setIsResolvePrompts(false);
      invoicesAdmin({
        variables: {
          filter: {
            ...initialInvoiceFilter,
            skip:
              (paginationProp?.current - 1) * (paginationProp?.pageSize || 0),
            limit: paginationProp?.pageSize || LIMIT,
            search: invoiceSearchTerm,
            libraryIds: finalWhereFilters?.name,
            createdAt: {
              from: finalWhereFilters?.createdAt?.from?.$d,
              to: finalWhereFilters?.createdAt?.to?.$d,
            },
            paidOn: {
              from: finalWhereFilters?.paidOn?.from?.$d,
              to: finalWhereFilters?.paidOn?.to?.$d,
            },
            status: finalWhereFilters?.status,
          },
          sort: sortedInfo?.column
            ? {
                field: sortedInfo?.field,
                order: sortedInfo?.order === 'ascend' ? SORT?.ASC : SORT?.DESC,
              }
            : initialInvoiceSort,
        },
      });
    }
    setIsResolveLoading(false);
  };

  const getFilterData = (confirm) => {
    setIsInvoiceLoading(true);
    invoicesAdmin({
      variables: {
        filter: {
          ...initialInvoiceFilter,
          limit: paginationProp?.pageSize || LIMIT,
          search: invoiceSearchTerm,
          libraryIds: whereFilter?.name,
          createdAt: {
            from: whereFilter?.createdAt?.from?.$d,
            to: whereFilter?.createdAt?.to?.$d,
          },
          paidOn: {
            from: whereFilter?.paidOn?.from?.$d,
            to: whereFilter?.paidOn?.to?.$d,
          },
          status:
            whereFilter?.status === PAYMENT_STATUS?.PAID?.value ||
            whereFilter?.status === PAYMENT_STATUS?.UNPAID?.value
              ? whereFilter?.status
              : undefined,
        },
        sort: sortedInfo?.column
          ? {
              field: sortedInfo?.field,
              order: sortedInfo?.order === 'ascend' ? SORT?.ASC : SORT?.DESC,
            }
          : initialInvoiceSort,
      },
    });
    setFinalWhereFilters({ ...finalWhereFilters, ...whereFilter });
    setFinalFilterTags(filterTags);
    setPaginationProp({ ...paginationProp, skip: 0, current: 1 });
    if (confirm) {
      confirm();
    }
  };

  const handleReset = (clearFilters, dataIndex, confirm) => {
    const tagsCopy = {
      ...filterTags,
      [dataIndex]: [],
    };
    let whereFiltersCopy;
    if (dataIndex === 'status') {
      whereFiltersCopy = {
        ...whereFilter,
        [dataIndex]: null,
      };
    } else {
      whereFiltersCopy = {
        ...whereFilter,
        [dataIndex]: [],
      };
    }
    setFilterVisible(false);
    setFinalFilterTags({ ...filterTags, ...tagsCopy });
    setFinalWhereFilters({ ...finalWhereFilters, ...whereFiltersCopy });
    setIsInvoiceLoading(true);
    invoicesAdmin({
      variables: {
        filter: {
          ...initialInvoiceFilter,
          limit: paginationProp?.pageSize || LIMIT,
          search: invoiceSearchTerm,
          libraryIds: whereFiltersCopy?.name,
          createdAt: {
            from: whereFiltersCopy?.createdAt?.from?.$d,
            to: whereFiltersCopy?.createdAt?.to?.$d,
          },
          paidOn: {
            from: whereFiltersCopy?.paidOn?.from?.$d,
            to: whereFiltersCopy?.paidOn?.to?.$d,
          },
          status:
            whereFilter?.status === PAYMENT_STATUS?.PAID?.value ||
            whereFilter?.status === PAYMENT_STATUS?.UNPAID?.value
              ? whereFilter?.status
              : undefined,
        },
        sort: sortedInfo?.column
          ? {
              field: sortedInfo?.field,
              order: sortedInfo?.order === 'ascend' ? SORT?.ASC : SORT?.DESC,
            }
          : initialInvoiceSort,
      },
    });
    setPaginationProp({ ...paginationProp, skip: 0, current: 1 });
    clearFilters();
    setFilterVisible(false);
    if (confirm) {
      confirm();
    }
  };

  useEffect(() => {
    if (filterVisible) {
      setFilterList([]);
      setFilterLoading(true);
      setIsEmptyFilterList(false);
      setFilterTags({ ...filterTags, ...finalFilterTags });
      setWhereFilter({ ...whereFilter, ...finalWhereFilters });
      setFilterIsEnd(false);
      const optionsCopy = [];

      switch (filterIndex) {
        case 'name':
          libraries({
            variables: {
              filter: initialTableFilter,
              sort: initialTableSort,
            },
          });
          break;

        case 'status':
          forEach(PAYMENT_STATUS_OPTIONS, (item) => {
            optionsCopy?.push({ label: item?.label, key: item?.value });
          });
          setFilterList(optionsCopy);
          setFilterLoading(false);
          break;

        default:
          break;
      }
    }
    if (!filterVisible) {
      setFilterTags(finalFilterTags);
      setWhereFilter(finalWhereFilters);
      setFilterSearch('');
    }
  }, [filterVisible]);

  const onFilterScroll = (event, dataIndex) => {
    if (filterScrollDebounceJob) {
      filterScrollDebounceJob?.cancel();
      filterScrollDebounceJob = null;
    }
    if (!isLibrariesLoading && !filterIsEnd) {
      const target = event?.target;
      const currentLength = filterList?.length;
      if (
        target?.scrollTop + target?.offsetHeight >=
        target?.scrollHeight - 5
      ) {
        filterScrollDebounceJob = debounce(() => {
          setFilterLoading(true);
          switch (dataIndex) {
            case 'name':
              libraries({
                variables: {
                  filter: {
                    limit: LIMIT,
                    skip: currentLength,
                    search: filterSearch,
                  },
                  sort: initialTableSort,
                },
              });
              break;

            default:
              break;
          }
        }, 500);
        filterScrollDebounceJob();
      }
    }
  };

  const handleFilterSearch = (value, dataIndex) => {
    setFilterSearch(value);
    setFilterIsEnd(false);
    setFilterLoading(true);
    setFilterList([]);
    switch (dataIndex) {
      case 'name':
        libraries({
          variables: {
            filter: {
              ...initialTableFilter,
              limit: LIMIT,
              search: value,
            },
            sort: initialTableSort,
          },
        });
        break;

      default:
        break;
    }
  };

  const changeFilter = (newFilterObj, dataIndex) => {
    const {
      target: { value },
    } = newFilterObj;

    const filterTagsCopy = { ...filterTags };
    let newTagList;
    if (whereFilter?.[dataIndex]?.includes(value?.key)) {
      newTagList = {
        ...filterTagsCopy,
        [dataIndex]: filter(filterTagsCopy?.[dataIndex], (item) => {
          if (item?.label) {
            return item?.key !== value?.key;
          }
          return item !== value;
        }),
      };
    } else {
      newTagList = {
        ...filterTagsCopy,
        [dataIndex]: filterTagsCopy?.[dataIndex]
          ? [...filterTagsCopy?.[dataIndex], value]
          : [value],
      };
    }

    const updatedWhereFilter = {
      ...whereFilter,
      [dataIndex]: newTagList?.[dataIndex]?.map((item) => item?.key),
    };
    setWhereFilter(updatedWhereFilter);
    setFilterTags(newTagList);
  };

  const handleDeleteFilter = (value, dataIndex) => {
    let newTagList;

    if (whereFilter?.[dataIndex]?.includes(value?.key)) {
      newTagList = {
        ...filterTags,
        [dataIndex]: filter(filterTags?.[dataIndex], (item) => {
          if (item?.key) {
            return item?.key !== value?.key;
          }
          return item !== value;
        }),
      };
    }

    const updatedWhereFilter = {
      ...whereFilter,
      [dataIndex]: newTagList?.[dataIndex]?.map((item) => item?.key),
    };
    setWhereFilter(updatedWhereFilter);
    setFilterTags(newTagList);
  };

  const onRangePickerChange = (values, dataIndex) => {
    const rangeObj = {
      from: values[0]?.startOf('day'),
      to: values?.[1]?.endOf('day'),
    };

    const filtersCopy = {
      ...whereFilter,
      [dataIndex]: rangeObj,
    };
    if (!values?.length) {
      delete whereFilter?.[dataIndex];
    }
    setWhereFilter(filtersCopy);
  };

  const onRadioChange = (value, dataIndex) => {
    const filtersCopy = {
      ...whereFilter,
      [dataIndex]: value,
    };
    if (!value?.length) {
      delete whereFilter?.[dataIndex];
    }
    setWhereFilter(filtersCopy);
  };

  const filterPopup = (dataIndex, showSearch = true) => ({
    filterDropdown: ({ confirm, clearFilters }) => {
      if (dataIndex === 'createdAt' || dataIndex === 'paidOn') {
        return (
          <div className="custom-filter-dropdown">
            <RangePicker
              allowClear={false}
              format={DATE_FORMATS?.REGULAR}
              onChange={(values) => onRangePickerChange(values, dataIndex)}
              value={
                whereFilter?.[dataIndex]?.from
                  ? [
                      whereFilter?.[dataIndex]?.from,
                      whereFilter?.[dataIndex]?.to,
                    ]
                  : null
              }
            />
            <Divider className="divider-filter" />
            <div className="d-flex justify-center">
              <Button
                size="small"
                className="reset-button"
                id="roles-filter-reset"
                onClick={() => handleReset(clearFilters, dataIndex, confirm)}
              >
                Reset
              </Button>
              <Button
                size="small"
                className="common-button ok-button"
                id="roles-filter-ok"
                type="primary"
                onClick={() => getFilterData(confirm, dataIndex)}
              >
                Ok
              </Button>
            </div>
          </div>
        );
      }

      if (dataIndex === 'status') {
        return (
          <div className="custom-filter-dropdown">
            <Group
              options={PAYMENT_STATUS_OPTIONS}
              value={whereFilter?.[dataIndex]}
              onChange={(e) => onRadioChange(e?.target?.value, dataIndex)}
            />
            <Divider className="divider-filter" />
            <div className="d-flex justify-center">
              <Button
                size="small"
                className="reset-button"
                id="roles-filter-reset"
                onClick={() => handleReset(clearFilters, dataIndex, confirm)}
              >
                Reset
              </Button>
              <Button
                size="small"
                className="common-button ok-button"
                id="roles-filter-ok"
                type="primary"
                onClick={() => getFilterData(confirm, dataIndex)}
              >
                Ok
              </Button>
            </div>
          </div>
        );
      }

      return (
        <div className="custom-filter-dropdown">
          <LoaderComponent
            spinning={filterLoading}
            setHeight={35}
            size="medium"
          >
            {showSearch && (
              <SearchComponent
                className="filter-search"
                id="search-container-id-roles"
                placeholder="Search..."
                name={dataIndex}
                query={filterSearch}
                setQuery={setFilterSearch}
                getData={(value) => handleFilterSearch(value, dataIndex)}
              />
            )}
            {filterTags?.[dataIndex]?.length > 0 && (
              <div className="filter-tags-wrapper">
                {map(filterTags?.[dataIndex], (item) => (
                  <Tag
                    key={item?.key}
                    closable
                    onClose={() => handleDeleteFilter(item, dataIndex)}
                    className="filter-tag"
                  >
                    <span>{item?.label}</span>
                  </Tag>
                ))}
              </div>
            )}
            <div
              className="filter-checkboxes"
              onScroll={(e) => onFilterScroll(e, dataIndex)}
            >
              {filterList?.length > 0
                ? map(filterList, (item) => (
                    <div className="single-checkbox-div" key={item?.key}>
                      <Checkbox
                        value={item}
                        checked={whereFilter?.[dataIndex]?.includes(item?.key)}
                        key={item?.key || item}
                        onChange={(e) => changeFilter(e, dataIndex)}
                        className="single-checkbox"
                      >
                        <span>{item?.label}</span>
                      </Checkbox>
                    </div>
                  ))
                : isEmptyFilterList && (
                    <Empty image={Empty?.PRESENTED_IMAGE_SIMPLE} />
                  )}
            </div>
          </LoaderComponent>
          <Divider className="divider-filter" />
          <div className="d-flex justify-center">
            <Button
              size="small"
              className="reset-button"
              id="roles-filter-reset"
              onClick={() => handleReset(clearFilters, dataIndex, confirm)}
            >
              Reset
            </Button>
            <Button
              size="small"
              className="common-button ok-button"
              id="roles-filter-ok"
              type="primary"
              onClick={() => getFilterData(confirm, dataIndex)}
            >
              Ok
            </Button>
          </div>
        </div>
      );
    },
    filterIcon: () =>
      finalWhereFilters?.[dataIndex]?.length > 0 ||
      !isEmpty(finalWhereFilters?.[dataIndex]) ||
      finalWhereFilters?.[dataIndex] === PAYMENT_STATUS?.PAID?.value ||
      finalWhereFilters?.[dataIndex] === PAYMENT_STATUS?.UNPAID?.value ? (
        <FilterFilled className="filter-icon filter-filled" />
      ) : (
        <FilterOutlined className="filter-icon" />
      ),
    onFilterDropdownOpenChange: (visible) => {
      setFilterIndex(dataIndex);
      setFilterVisible(visible);
    },
  });

  const columns = [
    {
      title: 'LIBRARY NAME',
      dataIndex: 'name',
      key: 'name',
      width: 160,
      align: 'left',
      ellipsis: true,
      className: `max-width-column`,
      onCell: (record) => ({
        onClick: () =>
          isLibraryPermission &&
          record?.library?.id &&
          history?.push(
            `${ROUTES?.MASTER_LISTS}${ROUTES?.LIBRARY}${ROUTES?.BASIC_DETAILS}/view/${record?.library?.id}/${record?.library?.libraryNumber}`,
          ),
        className: `${
          isLibraryPermission && record?.library?.id && 'pointer table-cell'
        }`,
      }),
      render: (_, record) => (
        <>
          <div className="table-data-with-id">{record?.library?.name}</div>
          <div className="font-size-12">({record?.library?.libraryNumber})</div>
        </>
      ),
      ...filterPopup('name'),
    },
    {
      title: 'INVOICE NUMBER',
      dataIndex: 'invoiceNumber',
      key: 'invoiceNumber',
      width: 150,
      ellipsis: true,
      align: 'left',
      className: 'max-width-column',
      render: (_, record) => record?.invoiceNumber,
    },
    {
      title: 'GENERATED ON',
      dataIndex: 'generatedOn',
      key: 'generatedOn',
      width: 150,
      ellipsis: true,
      align: 'left',
      className: 'max-width-column',
      render: (_, record) =>
        formatDate(record?.createdAt, DATE_FORMATS?.REGULAR),
      ...filterPopup('createdAt'),
    },
    {
      title: 'PAID ON',
      dataIndex: 'paidOn',
      key: 'paidOn',
      width: 150,
      ellipsis: true,
      align: 'left',
      className: 'max-width-column',
      render: (_, record) =>
        record?.paidOn
          ? formatDate(record?.paidOn, DATE_FORMATS?.REGULAR)
          : '-',
      ...filterPopup('paidOn'),
    },
    {
      title: 'AMOUNT',
      dataIndex: 'amount',
      key: 'amount',
      width: 150,
      ellipsis: true,
      align: 'left',
      className: 'max-width-column',
      render: (_, record) => record?.amount,
    },
    {
      title: 'STATUS',
      dataIndex: 'status',
      key: 'status',
      ellipsis: true,
      width: 150,
      align: 'left',
      className: 'max-width-column',
      render: (_, record) => (
        <Badge
          color={PAYMENT_STATUS?.[record?.status]?.color}
          text={PAYMENT_STATUS?.[record?.status]?.text}
        />
      ),
      ...filterPopup('status'),
    },
    {
      title: 'ACTIONS',
      dataIndex: 'actions',
      key: 'actions',
      ellipsis: true,
      width: 150,
      // eslint-disable-next-line no-undef
      fixed: window.innerWidth > BREAKPOINTS.desktop ? 'right' : false,
      render: (_, record) => (
        <>
          <div className="action-button">
            <>
              <Button
                type="primary"
                size="small"
                className="table-action-primary-btn"
                onClick={() => {
                  setIsResolvePrompts(true);
                  setInvoice(record);
                }}
                disabled={record?.status === PAYMENT_STATUS?.PAID?.value}
              >
                Resolve
              </Button>
              <div className="ml-16 d-flex">
                <Tooltip
                  title="Edit"
                  placement="top"
                  className="table-action-button"
                  zIndex={4}
                >
                  <Button
                    disabled={record?.status === PAYMENT_STATUS?.PAID?.value}
                    type="link"
                    onClick={() => {
                      setIsEditMode(true);
                      setIsInvoiceModalOpen(true);
                      setInvoice(record);
                    }}
                  >
                    <EditIcon
                      className={
                        record?.status === PAYMENT_STATUS?.PAID?.value
                          ? 'svg-icon-grey'
                          : 'svg-icon'
                      }
                    />
                  </Button>
                </Tooltip>
              </div>
            </>
          </div>
        </>
      ),
    },
  ]?.filter((item) => {
    if (
      item?.dataIndex === 'actions' &&
      !hasPermission(
        PERMISSIONS_KEY?.PAYMENTS_LIBRARY,
        PERMISSION_TYPE?.UPDATE,
        permissions,
      )
    ) {
      return item?.hidden;
    }
    return !item?.hidden;
  });

  const locale = {
    emptyText: isEmptyInvoiceList ? '' : <span />,
  };

  return (
    <>
      <Modal
        title="Caution"
        okText="Yes"
        cancelText="No"
        open={isResolvePrompts}
        onOk={() => handleResolve(invoice?.id)}
        onCancel={() => setIsResolvePrompts(false)}
        okButtonProps={{ loading: isResolveLoading }}
      >
        Are you sure you want to
        <strong> Resolve </strong>
        this?
      </Modal>
      <InvoiceModal
        form={invoiceForm}
        onFinish={handleInvoice}
        loadings={isInvoiceBtnLoading}
        isModalOpen={isInvoiceModalOpen}
        setIsModalOpen={setIsInvoiceModalOpen}
        invoiceFile={invoiceFile}
        setInvoiceFile={setInvoiceFile}
        invoice={invoice}
        setInvoice={setInvoice}
        isEditMode={isEditMode}
      />
      <div className="d-flex justify-end mb-16 flex-wrap">
        <SearchComponent
          className="search-box"
          query={query}
          setQuery={setQuery}
          getData={handleSearch}
        />
      </div>
      <CommonTable
        locale={locale}
        columns={columns}
        data={invoiceList || []}
        loading={isInvoiceLoading}
        onChange={handleTableChange}
        paginationConfig={paginationProp}
        rowKey={(record) => record?.id}
      />
    </>
  );
};

export default LibraryTable;
