import { FilterFilled, FilterOutlined } from '@ant-design/icons';
import { useLazyQuery, useMutation } from '@apollo/client';
import {
  Badge,
  Button,
  Checkbox,
  DatePicker,
  Divider,
  Empty,
  Form,
  Image,
  Radio,
  Tag,
  Tooltip,
} from 'antd';
import { filter, forEach, isEmpty, map } from 'lodash';
import React, { useContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { AppContext } from '../../../../../AppContext';
import { ReactComponent as CommentIcon } from '../../../../../assets/svg/comment-dots-solid.svg';
import { ReactComponent as PlayIcon } from '../../../../../assets/svg/play-solid.svg';
import {
  BREAKPOINTS,
  DATE_FORMATS,
  LIMIT,
  PERMISSIONS_KEY,
  PERMISSION_TYPE,
  REPORT_DAMAGE_OPTIONS,
  REPORT_DAMAGE_REQUEST_TYPES,
  REQUEST_STATUS,
  REQUEST_TYPES,
  ROUTES,
  SORT,
  STATUS_LIBRARY_DAMAGE_FILTER,
} from '../../../../../common/constants';
import { formatDate, hasPermission } from '../../../../../common/utils';
import CommonPreviewTable from '../../../../../components/CommonPreviewTable';
import CommonTable from '../../../../../components/CommonTable';
import LoaderComponent from '../../../../../components/LoaderComponent';
import SearchComponent from '../../../../../components/SearchComponent';
import ToyVideoModal from '../../../../../libraryModules/raiseRequest/components/ToyVideoModal';
import { UPDATE_REQUEST_ADMIN } from '../../../graphql/Mutations';
import { USER_DAMAGE_REQUESTS_ADMIN } from '../graphql/Queries';
import ResolveCommentModal from './ResolveCommentModal';
import ViewCommentModal from './ViewCommentModal';

const { RangePicker } = DatePicker;
const { Group } = Radio;

const initialPaginationValue = {
  total: 0,
  current: 1,
  pageSize: 10,
};

const initialDamageFilter = {
  limit: 10,
  search: '',
  skip: 0,
};

const initialDamageSort = {
  field: 'createdAt',
  order: 'DESC',
};

const requestType = REQUEST_TYPES?.RECEIVED_DAMAGED;

const ReportDamageTable = ({ exportFilter, setExportFilter }) => {
  const {
    state: {
      permissions,
      commonPermissions: {
        isUserPermission,
        isDeliveryPartnerPermission,
        isToyPermission,
      },
    },
  } = useContext(AppContext);
  const history = useHistory();
  const [form] = Form.useForm();
  const [damageList, setDamageList] = useState([]);
  const [damageSearchTerm, setDamageSearchTerm] = useState('');
  const [isEmptyDamageList, setIsEmptyDamageList] = useState(false);
  const [damage, setDamage] = useState({});
  const [query, setQuery] = useState('');
  const [sortedInfo, setSortedInfo] = useState({});
  const [paginationProp, setPaginationProp] = useState(initialPaginationValue);

  const [isCommentLoading, setIsCommentLoading] = useState(false);
  const [visible, setVisible] = useState(false);
  const [toyImages, setToyImages] = useState([]);
  const [isCommentPrompts, setIsCommentPrompts] = useState(false);
  const [isViewCommentPrompts, setIsViewCommentPrompts] = useState(false);
  const [isVideoModalOpen, setIsVideoModalOpen] = useState(false);

  const [isDamageLoading, setIsDamageLoading] = useState(true);
  const [filterList, setFilterList] = useState([]);
  const [whereFilter, setWhereFilter] = useState();
  const [filterLoading, setFilterLoading] = useState(false);
  const [filterTags, setFilterTags] = useState(null);
  const [filterVisible, setFilterVisible] = useState(false);
  const [filterIndex, setFilterIndex] = useState(null);
  const [finalWhereFilters, setFinalWhereFilters] = useState();
  const [finalFilterTags, setFinalFilterTags] = useState(null);
  const [isEmptyFilterList, setIsEmptyFilterList] = useState(false);

  const [requestsAdmin] = useLazyQuery(USER_DAMAGE_REQUESTS_ADMIN, {
    onCompleted: (response) => {
      setDamageList([...response?.requestsAdmin?.data]);
      if (
        response?.requestsAdmin?.count === 0 &&
        initialPaginationValue?.total === 0
      ) {
        setIsEmptyDamageList(true);
      } else {
        setIsEmptyDamageList(false);
      }
      const pagination = {
        ...paginationProp,
        defaultPageSize: LIMIT,
        total: response?.requestsAdmin?.count,
      };
      setPaginationProp(pagination);
      setIsDamageLoading(false);
    },
    fetchPolicy: 'network-only',
    onError() {},
  });

  const [updateRequestAdmin] = useMutation(UPDATE_REQUEST_ADMIN, {
    onError: () => {},
  });

  const hasUpdatePermission = hasPermission(
    PERMISSIONS_KEY?.REQUESTS_END_USER_REPORT_DAMAGE,
    PERMISSION_TYPE?.UPDATE,
    permissions,
  );

  useEffect(() => {
    setPaginationProp({ ...paginationProp, current: 1, skip: 0 });
    setIsDamageLoading(true);
    requestsAdmin({
      variables: {
        filter: {
          ...initialDamageFilter,
          type: requestType,
        },
        sort: initialDamageSort,
      },
    });
  }, []);

  const handleTableChange = (pagination, tableFilter, sorter) => {
    const { current } = pagination;
    const skip = (current - 1) * (pagination?.pageSize || 0);
    setPaginationProp({ ...paginationProp, ...pagination });
    setIsDamageLoading(true);
    setSortedInfo(sorter);
    requestsAdmin({
      variables: {
        filter: {
          ...initialDamageFilter,
          skip,
          limit: pagination?.pageSize,
          search: damageSearchTerm,
          type: requestType,
          dateRange: {
            from: finalWhereFilters?.createdAt?.from?.$d,
            to: finalWhereFilters?.createdAt?.to?.$d,
          },
          requestType: finalWhereFilters?.type,
          status:
            finalWhereFilters?.status?.length > 0
              ? finalWhereFilters?.status
              : undefined,
        },
        sort: sorter?.column
          ? {
              field: sorter?.field,
              order: sorter?.order === 'ascend' ? SORT?.ASC : SORT?.DESC,
            }
          : initialDamageSort,
      },
    });
  };

  const handleSearch = (value) => {
    const trimValue = value?.trim();
    setDamageSearchTerm(trimValue);
    setPaginationProp({ ...paginationProp, current: 1, skip: 0 });
    setIsDamageLoading(true);
    requestsAdmin({
      variables: {
        filter: {
          ...initialDamageFilter,
          limit: paginationProp?.pageSize || LIMIT,
          search: trimValue,
          type: requestType,
          dateRange: {
            from: finalWhereFilters?.createdAt?.from?.$d,
            to: finalWhereFilters?.createdAt?.to?.$d,
          },
          requestType: finalWhereFilters?.type,
          status:
            finalWhereFilters?.status?.length > 0
              ? finalWhereFilters?.status
              : undefined,
        },
        sort: sortedInfo?.column
          ? {
              field: sortedInfo?.field,
              order: sortedInfo?.order === 'ascend' ? SORT?.ASC : SORT?.DESC,
            }
          : initialDamageSort,
      },
    });
    setExportFilter({ ...exportFilter, search: trimValue });
  };

  const handleAddComment = async (values) => {
    setIsCommentLoading(true);
    const response = await updateRequestAdmin({
      variables: {
        where: {
          id: damage?.id,
        },
        data: {
          userDamage: {
            comment: values?.comment,
          },
          status: REQUEST_STATUS?.RESOLVED?.value,
        },
      },
    });
    if (response?.data) {
      setIsDamageLoading(true);
      setIsCommentPrompts(false);
      form?.resetFields();
      requestsAdmin({
        variables: {
          filter: {
            ...initialDamageFilter,
            skip:
              (paginationProp?.current - 1) * (paginationProp?.pageSize || 0),
            limit: paginationProp?.pageSize || LIMIT,
            search: damageSearchTerm,
            type: requestType,
            dateRange: {
              from: finalWhereFilters?.createdAt?.from?.$d,
              to: finalWhereFilters?.createdAt?.to?.$d,
            },
            requestType: finalWhereFilters?.type,
            status:
              finalWhereFilters?.status?.length > 0
                ? finalWhereFilters?.status
                : undefined,
          },
          sort: sortedInfo?.column
            ? {
                field: sortedInfo?.field,
                order: sortedInfo?.order === 'ascend' ? SORT?.ASC : SORT?.DESC,
              }
            : initialDamageSort,
        },
      });
    }
    setIsCommentLoading(false);
  };

  const getFilterData = (confirm) => {
    setIsDamageLoading(true);
    requestsAdmin({
      variables: {
        filter: {
          ...initialDamageFilter,
          limit: paginationProp?.pageSize || LIMIT,
          search: damageSearchTerm,
          type: requestType,
          dateRange: {
            from: whereFilter?.createdAt?.from?.$d,
            to: whereFilter?.createdAt?.to?.$d,
          },
          requestType:
            whereFilter?.type ===
              REPORT_DAMAGE_REQUEST_TYPES?.DAMAGED_DURING_RENTAL_PERIOD
                ?.value ||
            whereFilter?.type ===
              REPORT_DAMAGE_REQUEST_TYPES?.RECEIVED_DAMAGED?.value
              ? whereFilter?.type
              : undefined,
          status:
            whereFilter?.status?.length > 0 ? whereFilter?.status : undefined,
        },
        sort: sortedInfo?.column
          ? {
              field: sortedInfo?.field,
              order: sortedInfo?.order === 'ascend' ? SORT?.ASC : SORT?.DESC,
            }
          : initialDamageSort,
      },
    });
    setFinalWhereFilters({ ...finalWhereFilters, ...whereFilter });
    setExportFilter({ ...exportFilter, ...finalWhereFilters, ...whereFilter });
    setFinalFilterTags(filterTags);
    setPaginationProp({ ...paginationProp, skip: 0, current: 1 });
    if (confirm) {
      confirm();
    }
  };

  const handleReset = (clearFilters, dataIndex, confirm) => {
    const tagsCopy = {
      ...filterTags,
      [dataIndex]: [],
    };
    let whereFiltersCopy;
    if (dataIndex === 'type') {
      whereFiltersCopy = {
        ...whereFilter,
        [dataIndex]: null,
      };
    } else {
      whereFiltersCopy = {
        ...whereFilter,
        [dataIndex]: [],
      };
    }
    setFilterVisible(false);
    setFinalFilterTags({ ...filterTags, ...tagsCopy });
    setFinalWhereFilters({ ...finalWhereFilters, ...whereFiltersCopy });
    setExportFilter({
      ...exportFilter,
      ...finalWhereFilters,
      ...whereFiltersCopy,
    });
    setIsDamageLoading(true);
    requestsAdmin({
      variables: {
        filter: {
          ...initialDamageFilter,
          limit: paginationProp?.pageSize || LIMIT,
          search: damageSearchTerm,
          type: requestType,
          dateRange: {
            from: whereFiltersCopy?.createdAt?.from?.$d,
            to: whereFiltersCopy?.createdAt?.to?.$d,
          },
          requestType:
            whereFiltersCopy?.type ===
              REPORT_DAMAGE_REQUEST_TYPES?.DAMAGED_DURING_RENTAL_PERIOD
                ?.value ||
            whereFiltersCopy?.type ===
              REPORT_DAMAGE_REQUEST_TYPES?.RECEIVED_DAMAGED?.value
              ? whereFiltersCopy?.type
              : undefined,
          status:
            whereFiltersCopy?.status?.length > 0
              ? whereFiltersCopy?.status
              : undefined,
        },
        sort: sortedInfo?.column
          ? {
              field: sortedInfo?.field,
              order: sortedInfo?.order === 'ascend' ? SORT?.ASC : SORT?.DESC,
            }
          : initialDamageSort,
      },
    });
    setPaginationProp({ ...paginationProp, skip: 0, current: 1 });
    clearFilters();
    setFilterVisible(false);
    if (confirm) {
      confirm();
    }
  };

  useEffect(() => {
    if (filterVisible) {
      setFilterList([]);
      setFilterLoading(true);
      setIsEmptyFilterList(false);
      setFilterTags({ ...filterTags, ...finalFilterTags });
      setWhereFilter({ ...whereFilter, ...finalWhereFilters });
      const optionsCopy = [];

      switch (filterIndex) {
        case 'type':
          forEach(REPORT_DAMAGE_OPTIONS, (item) => {
            optionsCopy?.push({ label: item?.label, key: item?.value });
          });
          setFilterList(optionsCopy);
          setFilterLoading(false);
          break;

        case 'status':
          forEach(STATUS_LIBRARY_DAMAGE_FILTER, (item) => {
            optionsCopy?.push({ label: item?.label, key: item?.key });
          });
          setFilterList(optionsCopy);
          setFilterLoading(false);
          break;

        default:
          break;
      }
    }
    if (!filterVisible) {
      setFilterTags(finalFilterTags);
      setWhereFilter(finalWhereFilters);
    }
  }, [filterVisible]);

  const changeFilter = (newFilterObj, dataIndex) => {
    const {
      target: { value },
    } = newFilterObj;

    const filterTagsCopy = { ...filterTags };
    let newTagList;
    if (whereFilter?.[dataIndex]?.includes(value?.key)) {
      newTagList = {
        ...filterTagsCopy,
        [dataIndex]: filter(filterTagsCopy?.[dataIndex], (item) => {
          if (item?.label) {
            return item?.key !== value?.key;
          }
          return item !== value;
        }),
      };
    } else {
      newTagList = {
        ...filterTagsCopy,
        [dataIndex]: filterTagsCopy?.[dataIndex]
          ? [...filterTagsCopy?.[dataIndex], value]
          : [value],
      };
    }

    const updatedWhereFilter = {
      ...whereFilter,
      [dataIndex]: newTagList?.[dataIndex]?.map((item) => item?.key),
    };
    setWhereFilter(updatedWhereFilter);
    setFilterTags(newTagList);
  };

  const handleDeleteFilter = (value, dataIndex) => {
    let newTagList;

    if (whereFilter?.[dataIndex]?.includes(value?.key)) {
      newTagList = {
        ...filterTags,
        [dataIndex]: filter(filterTags?.[dataIndex], (item) => {
          if (item?.key) {
            return item?.key !== value?.key;
          }
          return item !== value;
        }),
      };
    }

    const updatedWhereFilter = {
      ...whereFilter,
      [dataIndex]: newTagList?.[dataIndex]?.map((item) => item?.key),
    };
    setWhereFilter(updatedWhereFilter);
    setFilterTags(newTagList);
  };

  const onRangePickerChange = (values, dataIndex) => {
    const rangeObj = {
      from: values[0]?.startOf('day'),
      to: values?.[1]?.endOf('day'),
    };

    const filtersCopy = {
      ...whereFilter,
      [dataIndex]: rangeObj,
    };
    if (!values?.length) {
      delete whereFilter?.[dataIndex];
    }
    setWhereFilter(filtersCopy);
  };

  const onRadioChange = (value, dataIndex) => {
    const filtersCopy = {
      ...whereFilter,
      [dataIndex]: value,
    };
    if (!value?.length) {
      delete whereFilter?.[dataIndex];
    }
    setWhereFilter(filtersCopy);
  };

  const filterPopup = (dataIndex) => ({
    filterDropdown: ({ confirm, clearFilters }) => {
      if (dataIndex === 'createdAt') {
        return (
          <div className="custom-filter-dropdown">
            <RangePicker
              allowClear={false}
              format={DATE_FORMATS?.REGULAR}
              onChange={(values) => onRangePickerChange(values, dataIndex)}
              value={
                whereFilter?.[dataIndex]?.from
                  ? [
                      whereFilter?.[dataIndex]?.from,
                      whereFilter?.[dataIndex]?.to,
                    ]
                  : null
              }
            />
            <Divider className="divider-filter" />
            <div className="d-flex justify-center">
              <Button
                size="small"
                className="reset-button"
                id="roles-filter-reset"
                onClick={() => handleReset(clearFilters, dataIndex, confirm)}
              >
                Reset
              </Button>
              <Button
                size="small"
                className="common-button ok-button"
                id="roles-filter-ok"
                type="primary"
                onClick={() => getFilterData(confirm, dataIndex)}
              >
                Ok
              </Button>
            </div>
          </div>
        );
      }

      if (dataIndex === 'type') {
        return (
          <div className="custom-filter-dropdown type-filter">
            <Group
              options={REPORT_DAMAGE_OPTIONS}
              value={whereFilter?.[dataIndex]}
              onChange={(e) => onRadioChange(e?.target?.value, dataIndex)}
            />
            <Divider className="divider-filter" />
            <div className="d-flex justify-center">
              <Button
                size="small"
                className="reset-button"
                id="roles-filter-reset"
                onClick={() => handleReset(clearFilters, dataIndex, confirm)}
              >
                Reset
              </Button>
              <Button
                size="small"
                className="common-button ok-button"
                id="roles-filter-ok"
                type="primary"
                onClick={() => getFilterData(confirm, dataIndex)}
              >
                Ok
              </Button>
            </div>
          </div>
        );
      }

      return (
        <div className="custom-filter-dropdown">
          <LoaderComponent
            spinning={filterLoading}
            setHeight={35}
            size="medium"
          >
            {filterTags?.[dataIndex]?.length > 0 && (
              <div className="filter-tags-wrapper">
                {map(filterTags?.[dataIndex], (item) => (
                  <Tag
                    key={item?.key}
                    closable
                    onClose={() => handleDeleteFilter(item, dataIndex)}
                    className="filter-tag"
                  >
                    <span>{item?.label}</span>
                  </Tag>
                ))}
              </div>
            )}
            <div className="filter-checkboxes">
              {filterList?.length > 0
                ? map(filterList, (item) => (
                    <div className="single-checkbox-div" key={item?.key}>
                      <Checkbox
                        value={item}
                        checked={whereFilter?.[dataIndex]?.includes(item?.key)}
                        key={item?.key || item}
                        onChange={(e) => changeFilter(e, dataIndex)}
                        className="single-checkbox"
                      >
                        <span>{item?.label}</span>
                      </Checkbox>
                    </div>
                  ))
                : isEmptyFilterList && (
                    <Empty image={Empty?.PRESENTED_IMAGE_SIMPLE} />
                  )}
            </div>
          </LoaderComponent>
          <Divider className="divider-filter" />
          <div className="d-flex justify-center">
            <Button
              size="small"
              className="reset-button"
              id="roles-filter-reset"
              onClick={() => handleReset(clearFilters, dataIndex, confirm)}
            >
              Reset
            </Button>
            <Button
              size="small"
              className="common-button ok-button"
              id="roles-filter-ok"
              type="primary"
              onClick={() => getFilterData(confirm, dataIndex)}
            >
              Ok
            </Button>
          </div>
        </div>
      );
    },
    filterIcon: () =>
      finalWhereFilters?.[dataIndex]?.length > 0 ||
      !isEmpty(finalWhereFilters?.[dataIndex]) ||
      finalWhereFilters?.[dataIndex] ===
        REPORT_DAMAGE_REQUEST_TYPES?.DAMAGED_DURING_RENTAL_PERIOD?.value ||
      finalWhereFilters?.[dataIndex] ===
        REPORT_DAMAGE_REQUEST_TYPES?.RECEIVED_DAMAGED?.value ? (
        <FilterFilled className="filter-icon filter-filled" />
      ) : (
        <FilterOutlined className="filter-icon" />
      ),
    onFilterDropdownOpenChange: (isVisible) => {
      setFilterIndex(dataIndex);
      setFilterVisible(isVisible);
    },
  });

  const columns = [
    {
      title: 'USER NAME',
      dataIndex: 'name',
      key: 'name',
      ellipsis: true,
      sorter: true,
      width: 300,
      align: 'left',
      className: `max-width-column`,
      // eslint-disable-next-line no-undef
      fixed: window.innerWidth > BREAKPOINTS.desktop ? 'left' : false,
      sortOrder: sortedInfo?.columnKey === 'name' && sortedInfo?.order,
      onCell: (record) => ({
        onClick: () =>
          isUserPermission &&
          record?.createdBy?.id &&
          history?.push(
            `${ROUTES?.SYSTEM_USERS}${ROUTES?.END_USERS}${ROUTES?.BASIC_DETAILS}/view/${record?.createdBy?.id}/${record?.createdBy?.userNumber}`,
          ),
        className: `${
          isUserPermission && record?.createdBy?.id && 'pointer table-cell'
        }`,
      }),
      render: (_, record) => {
        const userData = record?.createdBy;
        return (
          <>
            {userData?.firstName || userData?.lastName ? (
              <div className="table-data-with-id">{`${
                userData?.firstName ? userData?.firstName : ''
              } ${userData?.lastName ? userData?.lastName : ''}`}</div>
            ) : (
              '-'
            )}
            {userData?.userNumber ? (
              <div className="font-size-12">({userData?.userNumber})</div>
            ) : (
              ''
            )}
          </>
        );
      },
    },
    {
      title: 'REQUEST ID',
      dataIndex: 'id',
      key: 'id',
      ellipsis: true,
      width: 170,
      align: 'left',
      className: 'max-width-column',
      render: (_, record) => record?.requestNumber,
    },
    {
      title: 'REQUEST DATE',
      dataIndex: 'requestDate',
      key: 'requestDate',
      ellipsis: true,
      width: 300,
      align: 'left',
      className: 'max-width-column',
      render: (_, record) =>
        formatDate(record?.createdAt, DATE_FORMATS?.REGULAR) || '-',
      ...filterPopup('createdAt'),
    },
    {
      title: 'IMAGES',
      dataIndex: 'images',
      key: 'images',
      ellipsis: true,
      width: 150,
      align: 'left',
      className: 'max-width-column',
      render: (_, record) => (
        <span>
          {record?.images?.length > 0 ? (
            <>
              <Image
                preview={{
                  visible: false,
                }}
                width={20}
                height={20}
                src={record?.images?.[0]?.url}
                onClick={(e) => {
                  e?.stopPropagation();
                  setVisible(true);
                  setToyImages(record?.images);
                }}
                className="pointer"
              />
            </>
          ) : (
            <span>No Images</span>
          )}
          {record?.images?.length > 1 ? ` + ${record?.images?.length - 1}` : ''}
        </span>
      ),
    },
    {
      title: 'VIDEO',
      dataIndex: 'video',
      key: 'video',
      ellipsis: true,
      width: 100,
      align: 'left',
      className: 'max-width-column',
      render: (_, record) => (
        <span>
          {record?.videoUrl ? (
            <Tooltip title="Play" placement="top" zIndex={4}>
              <PlayIcon
                onClick={() => {
                  setDamage(record);
                  setIsVideoModalOpen(true);
                }}
                className="mr-16 pointer svg-icon"
              />
            </Tooltip>
          ) : (
            '-'
          )}
        </span>
      ),
    },
    {
      title: 'DELIVERY PARTNER NAME',
      dataIndex: 'deliveryPartnerName',
      key: 'deliveryPartnerName',
      ellipsis: true,
      width: 300,
      align: 'left',
      className: `max-width-column`,
      onCell: (record) => ({
        onClick: () =>
          isDeliveryPartnerPermission &&
          record?.orderItemDetails?.[0]?.order?.deliveryPartner?.id &&
          history?.push(
            `${ROUTES?.SYSTEM_USERS}${ROUTES?.DELIVERY_PARTNERS}/view/${record?.orderItemDetails?.[0]?.order?.deliveryPartner?.id}`,
          ),
        className: `${
          isDeliveryPartnerPermission &&
          record?.orderItemDetails?.[0]?.order?.deliveryPartner?.id &&
          'pointer table-cell'
        }`,
      }),
      render: (_, record) => {
        const deliveryPartnerData =
          record?.orderItemDetails?.[0]?.order?.deliveryPartner;
        return (
          <>
            {deliveryPartnerData?.firstName || deliveryPartnerData?.lastName ? (
              <div className="table-data-with-id">{`${
                deliveryPartnerData?.firstName
                  ? deliveryPartnerData?.firstName
                  : ''
              } ${
                deliveryPartnerData?.lastName
                  ? deliveryPartnerData?.lastName
                  : ''
              }`}</div>
            ) : (
              '-'
            )}
            {deliveryPartnerData?.userNumber ? (
              <div className="font-size-12">
                ({deliveryPartnerData?.userNumber})
              </div>
            ) : (
              ''
            )}
          </>
        );
      },
    },
    {
      title: 'PRODUCT NAME',
      dataIndex: 'productName',
      key: 'productName',
      ellipsis: true,
      width: 300,
      align: 'left',
      className: `max-width-column`,
      onCell: (record) => ({
        onClick: () =>
          isToyPermission &&
          record?.orderItemDetails?.[0]?.id &&
          history?.push(
            `${ROUTES?.MASTER_LISTS}${ROUTES?.TOY}/view/${record?.orderItemDetails?.[0]?.id}`,
          ),
        className: `${
          isToyPermission &&
          record?.orderItemDetails?.[0]?.id &&
          'pointer table-cell'
        }`,
      }),
      render: (_, record) => {
        const toyData = record?.orderItemDetails?.[0]?.toy;
        return (
          <>
            <div className="table-data-with-id">{toyData?.name || '-'}</div>
            {toyData?.toyNumber && (
              <div className="font-size-12">({toyData?.toyNumber})</div>
            )}
          </>
        );
      },
    },
    {
      title: 'TYPE',
      dataIndex: 'type',
      key: 'type',
      ellipsis: true,
      width: 100,
      align: 'left',
      className: 'max-width-column',
      render: (_, record) => REPORT_DAMAGE_REQUEST_TYPES?.[record?.type]?.label,
      ...filterPopup('type'),
    },
    {
      title: 'DESCRIPTION',
      dataIndex: 'description',
      key: 'description',
      ellipsis: true,
      width: 300,
      align: 'left',
      className: 'max-width-column table-tooltip-cell',
      render: (_, record) => (
        <Tooltip
          title={record?.description ? record?.description : 'No Description'}
          placement="topLeft"
          trigger="click"
          className="pointer"
        >
          {record?.description || '-'}
        </Tooltip>
      ),
    },
    {
      title: 'RESOLVING COMMENT',
      dataIndex: 'resolvingComment',
      key: 'resolvingComment',
      ellipsis: true,
      width: 150,
      render: (_, record) =>
        record?.metaData?.comment ? (
          <>
            <div className="action-button">
              <CommentIcon
                className="mr-16 pointer svg-icon"
                onClick={() => {
                  setIsViewCommentPrompts(true);
                  setDamage(record);
                }}
              />
            </div>
          </>
        ) : (
          '-'
        ),
    },
    {
      title: 'STATUS',
      dataIndex: 'status',
      key: 'status',
      ellipsis: true,
      width: 150,
      align: 'left',
      className: 'max-width-column',
      render: (_, record) => (
        <Badge
          color={REQUEST_STATUS?.[record?.status]?.color}
          text={REQUEST_STATUS?.[record?.status]?.text}
        />
      ),
    },
    {
      title: 'ACTIONS',
      dataIndex: 'actions',
      key: 'actions',
      ellipsis: true,
      width: 150,
      // eslint-disable-next-line no-undef
      fixed: window.innerWidth > BREAKPOINTS.desktop ? 'right' : false,
      render: (_, record) => (
        <>
          <div className="action-button">
            <Button
              type="primary"
              size="small"
              className="table-action-primary-btn"
              onClick={() => {
                setIsCommentPrompts(true);
                setDamage(record);
              }}
              disabled={record?.status === REQUEST_STATUS?.RESOLVED?.value}
            >
              Resolve
            </Button>
          </div>
        </>
      ),
    },
  ]?.filter((item) => {
    if (item?.dataIndex === 'actions' && !hasUpdatePermission) {
      return item?.hidden;
    }
    return !item?.hidden;
  });

  const locale = {
    emptyText: isEmptyDamageList ? '' : <span />,
  };

  return (
    <>
      <ToyVideoModal
        title={`Video - ${damage?.createdBy?.userNumber}`}
        isModalOpen={isVideoModalOpen}
        setIsModalOpen={setIsVideoModalOpen}
        videoUrl={damage?.videoUrl}
      />
      <CommonPreviewTable
        visible={visible}
        setVisible={setVisible}
        images={toyImages}
        setImages={setToyImages}
      />
      <ViewCommentModal
        comment={damage?.metaData?.comment}
        isModalOpen={isViewCommentPrompts}
        setIsModalOpen={setIsViewCommentPrompts}
      />
      <ResolveCommentModal
        form={form}
        isModalOpen={isCommentPrompts}
        setIsModalOpen={setIsCommentPrompts}
        isBtnLoading={isCommentLoading}
        onFinish={handleAddComment}
      />
      <div className="d-flex justify-end mb-16 flex-wrap">
        <SearchComponent
          className="search-box"
          query={query}
          setQuery={setQuery}
          getData={handleSearch}
        />
      </div>
      <CommonTable
        locale={locale}
        columns={columns}
        data={damageList || []}
        loading={isDamageLoading}
        onChange={handleTableChange}
        paginationConfig={paginationProp}
        rowKey={(record) => record?.id}
      />
    </>
  );
};

export default ReportDamageTable;
