import { Divider, Form, Input, Select } from 'antd';
import React, { useEffect, useState } from 'react';
import { COLLECTION_ORDER } from '../../../../common/constants';
import {
  formValidatorRules,
} from '../../../../common/utils';
import ModalComponent from '../../../../components/ModalComponent';

const { Item } = Form;
const { required } = formValidatorRules;
const { Option } = Select;

const CollectionsModal = ({
  isModalOpen,
  setIsModalOpen,
  onFinish,
  loadings,
  form,
  collections,
  setCollections,
}) => {
  const [isValidateTrigger, setIsValidateTrigger] = useState(false);

  const handleCancel = () => {
    setIsModalOpen(false);
    setCollections();
    form?.resetFields();
  };
  useEffect(() => {
    form?.setFieldsValue({ name: collections?.name, order: collections?.order, description: collections?.description, shortDescription: collections?.shortDescription, sortingType: collections?.sortingType });
  }, [collections]);

  return (
    <>
      <ModalComponent
        open={isModalOpen}
        onCancel={handleCancel}
        onOk={form?.submit}
        title={collections ? 'Edit Collection' : 'Add New Collection'}
        okText="Save"
        confirmLoading={loadings}
        destroyOnClose
        forceRender
      >
        <Divider />
        <Form
          form={form}
          onFinish={(values) => {
            onFinish(values);
            setIsValidateTrigger(true);
          }}
          autoComplete="off"
          layout="vertical"
          validateTrigger={isValidateTrigger ? 'onChange' : 'onSubmit'}
          onFinishFailed={() => setIsValidateTrigger(true)}
        >
          <Item
            label="Name"
            name="name"
            rules={[{ ...required, message: 'Please Enter Name!' }]}
          >
            <Input allowClear placeholder="Enter Name" maxLength={50} />
          </Item>
          <Item
            label="Order"
            name="order"
          >
            <Select placeholder="Select Order" allowClear >
              {
                COLLECTION_ORDER?.map((option) => (
                  <Option key={option} value={option} >
                    {option}{' '}
                  </Option>
                ))
              }
            </Select>
          </Item>
          <Item
            label="Short Description"
            name="shortDescription"
            rules={[{ ...required, message: 'Please Enter Short Description!' }]}
          >
            <Input allowClear placeholder="Enter Short Description" maxLength={50} />
          </Item>
          <Item
            label="sorting type"
            name="sortingType"
          >
            <Select placeholder="Select Sorting Type" allowClear  >
              <Option key='random' value='random'>Random</Option>
              <Option key='sorting' value='sorting'>Sorting</Option>
            </Select>
          </Item>
          {/* <Item
            name="description"
            label="Description"
            rules={[whitespaceNotAllowed]}
          >
            <TextArea
              rows={4}
              allowClear
              placeholder="Enter Description"
              showCount
              maxLength={700}
            />
          </Item> */}
        </Form>
      </ModalComponent>
    </>
  );
};

export default CollectionsModal;
