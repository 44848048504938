import { CopyOutlined } from '@ant-design/icons';
import { useLazyQuery } from '@apollo/client';
import { Button, Input, Space, Tooltip } from 'antd';
import React, { useEffect, useState } from 'react';
import {
  FacebookIcon,
  FacebookShareButton,
  LinkedinIcon,
  LinkedinShareButton,
  TelegramIcon,
  TelegramShareButton,
  TwitterShareButton,
  WhatsappIcon,
  WhatsappShareButton,
  XIcon,
} from 'react-share';
import { handleCopy } from '../../../../../common/utils';
import LoaderComponent from '../../../../../components/LoaderComponent';
import { LIBRARY } from '../graphql/Queries';

const ReferralDetails = () => {
  const [libraryDetail, setLibraryDetail] = useState({});
  const [isLibraryLoading, setIsLibraryLoading] = useState(true);

  const [library] = useLazyQuery(LIBRARY, {
    onCompleted: (response) => {
      setLibraryDetail(response?.library);
      setIsLibraryLoading(false);
    },
    fetchPolicy: 'network-only',
    onError() {},
  });

  useEffect(() => {
    setIsLibraryLoading(true);
    setLibraryDetail({});
    library();
  }, []);

  const shareUrl = libraryDetail?.referralUrl;
  const referralCode = libraryDetail?.referralCode;
  const shareTitle = `Parents, stop buying toys! Try the Elefant Toy Library instead.\nBecome a member today & use my code ${referralCode} at checkout to get Rs 1500 off any membership plans!\nClick the link & become a member!`;
  return (
    <>
      {isLibraryLoading ? (
        <LoaderComponent
          size="large"
          setHeight="50"
          spinning={isLibraryLoading}
        />
      ) : (
        <>
          <div className="container mb-24">
            <h1>
              Refer your friends and family to the EleFant Toy Library and earn
              referrals!
            </h1>
            <p>
              Wondering how? When your referrals purchase a membership plan
              using your unique code, you'll get a revenue share on what they
              pay.
            </p>
            <p>
              Spread the word, share your code, and watch your earnings grow
              while bringing joy and learning to others with the EleFant!
            </p>
          </div>
          <div className="d-flex align-center">
            <div className="referral-code-label">Referral Code:</div>
            <div className="referral-code-box">
              <h3 className="referral-code">{referralCode}</h3>
            </div>
            <CopyOutlined
              className="copy-icon ml-8"
              onClick={() => handleCopy(referralCode)}
            />
          </div>
          <div className="d-flex flex-wrap mb-16 mt-24 referral-share">
            <WhatsappShareButton
              url={shareUrl}
              title={shareTitle}
              className="m-8"
            >
              <WhatsappIcon size={40} round />
              <div>Whatsapp</div>
            </WhatsappShareButton>

            <FacebookShareButton
              url={shareUrl}
              quote={shareTitle}
              className="m-8"
            >
              <FacebookIcon size={40} round />
              <div>Facebook</div>
            </FacebookShareButton>

            <TelegramShareButton
              url={shareUrl}
              title={shareTitle}
              className="m-8"
            >
              <TelegramIcon size={40} round />
              <div>Telegram</div>
            </TelegramShareButton>

            <LinkedinShareButton
              url={shareUrl}
              title={shareTitle}
              className="m-8"
            >
              <LinkedinIcon size={40} round />
              <div>LinkedIn</div>
            </LinkedinShareButton>

            <TwitterShareButton
              url={shareUrl}
              title={shareTitle}
              className="m-8"
            >
              <XIcon size={40} round />
              <div>Twitter</div>
            </TwitterShareButton>
            <Space.Compact className="copy-to-clipboard mb-16 mt-16">
              <Input defaultValue={shareUrl} disabled />
              <Tooltip
                title="Copied!"
                trigger="focus"
                onClick={(e) => e?.stopPropagation()}
              >
                <Button type="primary" onClick={() => handleCopy(shareUrl)}>
                  Copy
                </Button>
              </Tooltip>
            </Space.Compact>
          </div>
        </>
      )}
    </>
  );
};

export default ReferralDetails;
