import { useLazyQuery } from '@apollo/client';
import { Button, Card, Form } from 'antd';
import dayjs from 'dayjs';
import React, { useContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { AppContext } from '../../../../../AppContext';
import {
  GIFT_CARD_STATUS,
  PERMISSIONS_KEY,
  PERMISSION_TYPE,
  ROUTES,
} from '../../../../../common/constants';
import { hasPermission } from '../../../../../common/utils';
import GoBackButton from '../../../../../components/GoBackButton';
import LoaderComponent from '../../../../../components/LoaderComponent';
import Portal from '../../../../../components/Portal';
import GiftCardForm from '../components/GiftCardForm';
import { GIFT_CARD } from '../graphql/Queries';

const ViewGiftCard = (props) => {
  const { match: { params: { id } = {} } = {} } = props;
  const {
    state: { permissions },
  } = useContext(AppContext);

  const [form] = Form.useForm();
  const history = useHistory();
  const [giftCardDetail, setGiftCardDetail] = useState({});
  const [isGiftCardLoading, setIsGiftCardLoading] = useState(true);

  const [giftCard] = useLazyQuery(GIFT_CARD, {
    onCompleted: (response) => {
      setGiftCardDetail(response?.giftCard?.data);
      setIsGiftCardLoading(false);
    },
    fetchPolicy: 'network-only',
    onError() {},
  });

  useEffect(() => {
    setIsGiftCardLoading(true);
    setGiftCardDetail([]);
    giftCard({
      variables: {
        where: {
          id,
        },
      },
    });
  }, []);

  const initialValues = {
    ...giftCardDetail,
    plan: giftCardDetail?.subscription?.id,
    price: giftCardDetail?.subscription?.price,
    notes: giftCardDetail?.notes,
    expiresOn: dayjs(giftCardDetail?.expiredAt),
    startsOn: dayjs(giftCardDetail?.startedAt),
  };

  return (
    <>
      <Portal portalId="header-title-content">
        <GoBackButton customLink={`${ROUTES?.OFFERS}${ROUTES?.GIFT_CARD}`} />
        <div className="portal-header">View Gift Card</div>
      </Portal>
      <Portal portalId="header-right-content">
        {hasPermission(
          PERMISSIONS_KEY?.OFFERS_GIFT_CARD,
          PERMISSION_TYPE?.UPDATE,
          permissions,
        ) && (
          <div className="header-btn-wrapper">
            <Button
              className="common-button mr-16"
              size="middle"
              onClick={() =>
                history?.push(
                  `${ROUTES?.OFFERS}${ROUTES?.GIFT_CARD}/edit/${id}`,
                )
              }
              disabled={
                giftCardDetail?.isArchived ||
                giftCardDetail?.status === GIFT_CARD_STATUS?.EXPIRED?.value
              }
            >
              Edit Gift Card
            </Button>
          </div>
        )}
      </Portal>
      <Portal portalId="header-right-content-phones">
        {hasPermission(
          PERMISSIONS_KEY?.OFFERS_GIFT_CARD,
          PERMISSION_TYPE?.UPDATE,
          permissions,
        ) && (
          <div className="header-btn-wrapper">
            <Button
              className="common-button mr-16"
              size="middle"
              onClick={() =>
                history?.push(
                  `${ROUTES?.OFFERS}${ROUTES?.GIFT_CARD}/edit/${id}`,
                )
              }
              disabled={
                giftCardDetail?.isArchived ||
                giftCardDetail?.status === GIFT_CARD_STATUS?.EXPIRED?.value
              }
            >
              Edit Gift Card
            </Button>
          </div>
        )}
      </Portal>
      <Card className="full-height-with-nav" title="Gift Card Details">
        <div className="toy-form">
          {isGiftCardLoading ? (
            <LoaderComponent
              size="large"
              setHeight="60"
              spinning={isGiftCardLoading}
            />
          ) : (
            <div className="admin-form">
              <GiftCardForm
                form={form}
                initialValues={initialValues}
                isView
                giftCardDetail={giftCardDetail}
              />
            </div>
          )}
        </div>
      </Card>
    </>
  );
};

export default ViewGiftCard;
