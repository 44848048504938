import { UserOutlined } from '@ant-design/icons';
import { useMutation } from '@apollo/client';
import { Button, Card, Form, Image, Input, Spin } from 'antd';
import React from 'react';
import { useHistory } from 'react-router-dom';
import { libraryUrls, ROUTES } from '../../common/constants';
import { formValidatorRules } from '../../common/utils';
import LogoTextComponent from '../../components/LogoTextComponent';
import MobileLogoComponent from '../../components/MobileLogoComponent';
import { FORGOT_PASSWORD } from './graphql/Mutations';

const { required, email } = formValidatorRules;

const ForgetPassword = () => {
  const history = useHistory();
  // eslint-disable-next-line no-undef
  const currentUrl = window?.location?.href;

  const shouldHide = libraryUrls?.some((url) => currentUrl?.startsWith(url));

  const [forgetPasswordMutating, { loading }] = useMutation(FORGOT_PASSWORD, {
    onError() {}, // Always write this method for error handling in all mutation.
  });

  const onFinish = async (values) => {
    try {
      const formValues = {
        email: values?.email?.trim()?.toLowerCase(),
      };
      await forgetPasswordMutating({
        variables: { data: { ...formValues } },
      });
    } catch (error) {
      return error;
    }
  };
  return (
    <>
      {!shouldHide ? (
        <div className="login-wrap d-flex align-center justify-center">
          <Card className="full-width">
            <div className="text-center">
              <div className="text-center mb-8 d-flex justify-center align-center">
                <MobileLogoComponent height="100px" />
              </div>
              <div className="text-center mb-32 d-flex justify-center align-center">
                <LogoTextComponent height="26px" />
              </div>
              <h2 className="text-center mt-8">Forgot Your Password ?</h2>
              <p className="text-center">
                Don't worry. Recovering the password is easy. Just tell us the
                email.
              </p>
            </div>
            <Spin spinning={false}>
              <Form layout="vertical" onFinish={onFinish} size="large">
                <Form.Item
                  name="email"
                  rules={[
                    { ...required, message: 'Please Enter Email!' },
                    email,
                  ]}
                >
                  <Input prefix={<UserOutlined />} placeholder="Enter email" />
                </Form.Item>
                <Form.Item className="full-width mb-8">
                  <Button
                    type="primary"
                    loading={loading}
                    className="full-width"
                    htmlType="submit"
                  >
                    Reset Password
                  </Button>
                </Form.Item>
                <Form.Item className="text-center mb-0">
                  <Button
                    type="link"
                    onClick={() => {
                      history?.push(ROUTES?.LOGIN);
                    }}
                  >
                    Cancel
                  </Button>
                </Form.Item>
              </Form>
            </Spin>
          </Card>
        </div>
      ) : (
        <>
          <div className="librarian-login d-flex justify-between">
            <div className="login-container">
              <div className="library-login">
                <div className="title">Forgot Your Password ?</div>
                <div className="description">
                  Don't worry. Recovering the password is easy. Just tell us the
                  email.
                </div>
                <div className="login-form">
                  <Form layout="vertical" onFinish={onFinish} size="large">
                    <Form.Item
                      name="email"
                      label="Email ID"
                      className="mb-24"
                      rules={[
                        {
                          ...required,
                          message: 'Please Enter Email!',
                        },
                        email,
                      ]}
                    >
                      <Input
                        prefix={<UserOutlined />}
                        placeholder="Enter your email here"
                      />
                    </Form.Item>
                    <Form.Item className="full-width mb-8">
                      <Button
                        type="primary"
                        loading={loading}
                        className="full-width login-btn"
                        htmlType="submit"
                      >
                        Reset Password
                      </Button>
                    </Form.Item>
                    <Form.Item className="text-center mb-0">
                      <Button
                        type="link"
                        className="full-width login-btn"
                        onClick={() => {
                          history?.push(ROUTES?.LOGIN);
                        }}
                      >
                        Cancel
                      </Button>
                    </Form.Item>
                  </Form>
                </div>
              </div>
            </div>
            <div className="image-container">
              <Image
                src={process.env?.REACT_APP_LIBRARIAN_IMG_URL || ''}
                preview={false}
                className="librarian-image"
                alt="librarian-image"
              />
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default ForgetPassword;
