import React from 'react';
import { Route, Switch } from 'react-router-dom';
import AccessControl from '../../../../AccessControl';
import { PERMISSIONS_KEY, ROUTES } from '../../../../common/constants';
import Holding from './pages/Holding';

const HoldingPeriodWrapper = () => (
  <Switch>
    <Route
      exact
      path={`${ROUTES?.REPORTS}${ROUTES?.HOLDING_PERIOD}`}
      render={(props) => (
        <AccessControl permissionKey={PERMISSIONS_KEY?.REPORTS_HOLDING_PERIOD}>
          <Holding {...props} />
        </AccessControl>
      )}
    />
  </Switch>
);

export default HoldingPeriodWrapper;
