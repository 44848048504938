import { CopyOutlined, FilterFilled, FilterOutlined } from '@ant-design/icons';
import { useLazyQuery, useMutation } from '@apollo/client';
import {
  Badge,
  Button,
  Checkbox,
  DatePicker,
  Divider,
  Empty,
  Radio,
  Tag,
  Tooltip,
} from 'antd';
import { debounce, filter, forEach, isEmpty, map } from 'lodash';
import React, { useContext, useEffect, useState } from 'react';
import { AppContext } from '../../../../../AppContext';
import { ReactComponent as FilterIcon } from '../../../../../assets/svg/sliders-solid.svg';
import {
  BREAKPOINTS,
  COUPON_TYPE,
  DATE_FORMATS,
  LIMIT,
  SORT,
  STATUS,
  USER_FORM_STATUS,
} from '../../../../../common/constants';
import {
  formatDate,
  handleCopy,
  hasNonEmptyValueObj,
} from '../../../../../common/utils';
import CommonTable from '../../../../../components/CommonTable';
import LoaderComponent from '../../../../../components/LoaderComponent';
import SearchComponent from '../../../../../components/SearchComponent';
import useInfiniteScroll from '../../../../../hooks/useInfiniteScroll';
import ShareModal from '../../../../../modules/offers/modules/coupon/components/ShareModal';
import { COUPON_REFERRAL_LINK } from '../../../../../modules/offers/modules/coupon/graphql/Mutations';
import { LIBRARIES } from '../../../../raiseRequest/graphql/Queries';
import { COUPONS } from '../graphql/Queries';
import AllFilterCouponModal from './AllFilterCouponModal';
import CouponList from './CouponList';

const { RangePicker } = DatePicker;
const { Group } = Radio;

const initialPaginationValue = {
  total: 0,
  current: 1,
  pageSize: 10,
};

const initialFilter = {
  limit: LIMIT,
  search: '',
  skip: 0,
};

const initialSort = {
  field: 'createdAt',
  order: 'DESC',
};

const initialTableFilter = {
  skip: 0,
  limit: LIMIT,
  search: '',
};

const initialTableSort = {
  field: 'createdAt',
  order: 'DESC',
};

let filterScrollDebounceJob = null;

const CouponTable = () => {
  const {
    state: { isDesktop },
  } = useContext(AppContext);
  const [couponList, setCouponList] = useState([]);
  const [couponSearchTerm, setGiftCardSearchTerm] = useState('');
  const [isEmptyCouponList, setIsEmptyCouponList] = useState(false);
  const [query, setQuery] = useState('');
  const [sortedInfo, setSortedInfo] = useState({});
  const [paginationProp, setPaginationProp] = useState(initialPaginationValue);

  const [isCouponLoading, setIsCouponLoading] = useState(true);
  const [isShareModalOpen, setIsShareModalOpen] = useState(false);
  const [isShareLoading, setIsShareLoading] = useState(false);
  const [shareTitle, setShareTitle] = useState(false);
  const [shareUrl, setShareUrl] = useState(false);

  const [filterSearch, setFilterSearch] = useState('');
  const [filterList, setFilterList] = useState([]);
  const [whereFilter, setWhereFilter] = useState();
  const [filterLoading, setFilterLoading] = useState(false);
  const [filterTags, setFilterTags] = useState(null);
  const [filterVisible, setFilterVisible] = useState(false);
  const [filterIndex, setFilterIndex] = useState(null);
  const [finalWhereFilters, setFinalWhereFilters] = useState();
  const [finalFilterTags, setFinalFilterTags] = useState(null);
  const [filterIsEnd, setFilterIsEnd] = useState(false);
  const [isEmptyFilterList, setIsEmptyFilterList] = useState(false);

  const [isAllFilterModalOpen, setIsAllFilterModalOpen] = useState(false);
  const [isFetchMoreCouponLoading, setIsFetchMoreCouponLoading] = useState(
    false,
  );
  const [isActive, setIsActive] = useState(true);

  const [coupons] = useLazyQuery(COUPONS, {
    onCompleted: (response) => {
      if (isDesktop) {
        setCouponList([...response?.coupons?.data]);
      } else {
        setCouponList([...couponList, ...response?.coupons?.data]);
      }
      if (
        response?.coupons?.count === 0 &&
        initialPaginationValue?.total === 0
      ) {
        setIsEmptyCouponList(true);
      } else {
        setIsEmptyCouponList(false);
      }
      if (response?.coupons?.data?.length < LIMIT) {
        setIsActive(false);
      }
      const pagination = {
        ...paginationProp,
        defaultPageSize: LIMIT,
        total: response?.coupons?.count,
      };
      setPaginationProp(pagination);
      setIsCouponLoading(false);
      setIsFetchMoreCouponLoading(false);
    },
    fetchPolicy: 'network-only',
    onError() {},
  });

  const [libraries, { loading: isLibrariesLoading }] = useLazyQuery(LIBRARIES, {
    onCompleted: (response) => {
      if (response?.libraries?.data?.length < LIMIT) {
        setFilterIsEnd(true);
      }
      if (response?.libraries?.count === 0) {
        setIsEmptyFilterList(true);
      } else {
        setIsEmptyFilterList(false);
      }
      const optionsCopy = [];
      forEach(response?.libraries?.data, (item) =>
        optionsCopy?.push({ label: item?.name, key: item?.id }),
      );
      setFilterList([...filterList, ...optionsCopy]);
      setFilterLoading(false);
    },
    fetchPolicy: 'network-only',
    onError() {
      setFilterLoading(false);
    },
  });

  const [couponReferralLink] = useMutation(COUPON_REFERRAL_LINK, {
    onError: () => {},
  });

  useEffect(() => {
    setPaginationProp({ ...paginationProp, current: 1, skip: 0 });
    setIsCouponLoading(true);
    setCouponList([]);
    coupons({
      variables: {
        filter: {
          ...initialFilter,
          limit: paginationProp?.pageSize || LIMIT,
          search: couponSearchTerm,
          dateRange: {
            from: finalWhereFilters?.createdAt?.from?.$d,
            to: finalWhereFilters?.createdAt?.to?.$d,
          },
          type: finalWhereFilters?.type,
          libraryIds: finalWhereFilters?.name,
          isActive: finalWhereFilters?.status,
        },
        sort: sortedInfo?.column
          ? {
              field: sortedInfo?.field,
              order: sortedInfo?.order === 'ascend' ? SORT?.ASC : SORT?.DESC,
            }
          : initialSort,
      },
    });
  }, [isDesktop]);

  const handleTableChange = (pagination, tableFilter, sorter) => {
    const { current } = pagination;
    const skip = (current - 1) * (pagination?.pageSize || 0);
    setPaginationProp({ ...paginationProp, ...pagination });
    setIsCouponLoading(true);
    setSortedInfo(sorter);
    coupons({
      variables: {
        filter: {
          ...initialFilter,
          skip,
          limit: pagination?.pageSize,
          search: couponSearchTerm,
          dateRange: {
            from: finalWhereFilters?.createdAt?.from?.$d,
            to: finalWhereFilters?.createdAt?.to?.$d,
          },
          type: finalWhereFilters?.type,
          libraryIds: finalWhereFilters?.name,
          isActive: finalWhereFilters?.status,
        },
        sort: sorter?.column
          ? {
              field: sorter?.field,
              order: sorter?.order === 'ascend' ? SORT?.ASC : SORT?.DESC,
            }
          : initialSort,
      },
    });
  };

  const handleSearch = (value) => {
    const trimValue = value?.trim();
    setGiftCardSearchTerm(trimValue);
    setCouponList([]);
    setPaginationProp({ ...paginationProp, current: 1, skip: 0 });
    setIsCouponLoading(true);
    coupons({
      variables: {
        filter: {
          ...initialFilter,
          limit: paginationProp?.pageSize || LIMIT,
          search: trimValue,
          dateRange: {
            from: finalWhereFilters?.createdAt?.from?.$d,
            to: finalWhereFilters?.createdAt?.to?.$d,
          },
          type: finalWhereFilters?.type,
          libraryIds: finalWhereFilters?.name,
          isActive: finalWhereFilters?.status,
        },
        sort: sortedInfo?.column
          ? {
              field: sortedInfo?.field,
              order: sortedInfo?.order === 'ascend' ? SORT?.ASC : SORT?.DESC,
            }
          : initialSort,
      },
    });
  };

  const handleFilter = (values) => {
    setPaginationProp({ ...paginationProp, current: 1, skip: 0 });
    setIsCouponLoading(true);
    setIsAllFilterModalOpen(false);
    setCouponList([]);
    setIsActive(true);
    const filters = {
      status: values?.status,
      type: values?.type,
      createdAt: values?.createdAt?.[1]
        ? { from: values?.createdAt?.[0], to: values?.createdAt?.[1] }
        : null,
    };
    setWhereFilter(filters);
    setFinalWhereFilters(filters);
    coupons({
      variables: {
        filter: {
          ...initialFilter,
          limit: paginationProp?.pageSize || LIMIT,
          search: couponSearchTerm,
          dateRange: {
            from: values?.createdAt?.[0],
            to: values?.createdAt?.[1],
          },
          type: values?.type,
          isActive: values?.status,
        },
        sort: sortedInfo?.column
          ? {
              field: sortedInfo?.field,
              order: sortedInfo?.order === 'ascend' ? SORT?.ASC : SORT?.DESC,
            }
          : initialSort,
      },
    });
  };

  const handleCouponScroll = () => {
    if (!isFetchMoreCouponLoading && !isCouponLoading) {
      const currentLength = couponList?.length;
      setIsFetchMoreCouponLoading(true);
      coupons({
        variables: {
          filter: {
            ...initialFilter,
            skip: currentLength,
            limit: paginationProp?.pageSize || LIMIT,
            search: couponSearchTerm,
            dateRange: {
              from: finalWhereFilters?.createdAt?.from?.$d,
              to: finalWhereFilters?.createdAt?.to?.$d,
            },
            type: finalWhereFilters?.type,
            isActive: finalWhereFilters?.status,
          },
          sort: sortedInfo?.column
            ? {
                field: sortedInfo?.field,
                order: sortedInfo?.order === 'ascend' ? SORT?.ASC : SORT?.DESC,
              }
            : initialSort,
        },
      });
    }
  };

  // eslint-disable-next-line react-hooks/rules-of-hooks, no-use-before-define
  const infiniteScrollRef = useInfiniteScroll(handleCouponScroll, isActive);

  const getFilterData = (confirm) => {
    setIsCouponLoading(true);
    coupons({
      variables: {
        filter: {
          ...initialFilter,
          limit: paginationProp?.pageSize || LIMIT,
          search: couponSearchTerm,
          dateRange: {
            from: whereFilter?.createdAt?.from?.$d,
            to: whereFilter?.createdAt?.to?.$d,
          },
          type:
            whereFilter?.type === COUPON_TYPE?.FLAT?.value ||
            COUPON_TYPE?.PERCENT?.value
              ? whereFilter?.type
              : undefined,
          libraryIds: whereFilter?.name,
          isActive:
            whereFilter?.status === true || whereFilter?.status === false
              ? whereFilter?.status
              : undefined,
        },
        sort: sortedInfo?.column
          ? {
              field: sortedInfo?.field,
              order: sortedInfo?.order === 'ascend' ? SORT?.ASC : SORT?.DESC,
            }
          : initialSort,
      },
    });
    setFinalWhereFilters({ ...finalWhereFilters, ...whereFilter });
    setFinalFilterTags(filterTags);
    setPaginationProp({ ...paginationProp, skip: 0, current: 1 });
    if (confirm) {
      confirm();
    }
  };

  const handleReset = (clearFilters, dataIndex, confirm) => {
    const tagsCopy = {
      ...filterTags,
      [dataIndex]: [],
    };
    let whereFiltersCopy;
    if (dataIndex === 'type' || dataIndex === 'status') {
      whereFiltersCopy = {
        ...whereFilter,
        [dataIndex]: null,
      };
    } else {
      whereFiltersCopy = {
        ...whereFilter,
        [dataIndex]: [],
      };
    }
    setFilterVisible(false);
    setFinalFilterTags({ ...filterTags, ...tagsCopy });
    setFinalWhereFilters({ ...finalWhereFilters, ...whereFiltersCopy });
    setIsCouponLoading(true);
    coupons({
      variables: {
        filter: {
          ...initialFilter,
          limit: paginationProp?.pageSize || LIMIT,
          search: couponSearchTerm,
          dateRange: {
            from: whereFiltersCopy?.createdAt?.from?.$d,
            to: whereFiltersCopy?.createdAt?.to?.$d,
          },
          type:
            whereFiltersCopy?.type === COUPON_TYPE?.FLAT?.value ||
            COUPON_TYPE?.PERCENT?.value
              ? whereFiltersCopy?.type
              : undefined,
          libraryIds: whereFiltersCopy?.name,
          isActive:
            whereFiltersCopy?.status === true ||
            whereFiltersCopy?.status === false
              ? whereFiltersCopy?.status
              : undefined,
        },
        sort: sortedInfo?.column
          ? {
              field: sortedInfo?.field,
              order: sortedInfo?.order === 'ascend' ? SORT?.ASC : SORT?.DESC,
            }
          : initialSort,
      },
    });
    setPaginationProp({ ...paginationProp, skip: 0, current: 1 });
    clearFilters();
    setFilterVisible(false);
    if (confirm) {
      confirm();
    }
  };

  useEffect(() => {
    if (filterVisible) {
      setFilterList([]);
      setFilterLoading(true);
      setIsEmptyFilterList(false);
      setFilterTags({ ...filterTags, ...finalFilterTags });
      setWhereFilter({ ...whereFilter, ...finalWhereFilters });
      setFilterIsEnd(false);

      switch (filterIndex) {
        case 'name':
          libraries({
            variables: {
              filter: initialTableFilter,
              sort: initialTableSort,
            },
          });
          break;

        default:
          break;
      }
    }
    if (!filterVisible) {
      setFilterTags(finalFilterTags);
      setWhereFilter(finalWhereFilters);
      setFilterSearch('');
    }
  }, [filterVisible]);

  const onFilterScroll = (event, dataIndex) => {
    if (filterScrollDebounceJob) {
      filterScrollDebounceJob?.cancel();
      filterScrollDebounceJob = null;
    }
    if (!isLibrariesLoading && !filterIsEnd) {
      const target = event?.target;
      const currentLength = filterList?.length;
      if (
        target?.scrollTop + target?.offsetHeight >=
        target?.scrollHeight - 5
      ) {
        filterScrollDebounceJob = debounce(() => {
          setFilterLoading(true);
          switch (dataIndex) {
            case 'name':
              libraries({
                variables: {
                  filter: {
                    limit: LIMIT,
                    skip: currentLength,
                    search: filterSearch,
                  },
                  sort: initialTableSort,
                },
              });
              break;

            default:
              break;
          }
        }, 500);
        filterScrollDebounceJob();
      }
    }
  };

  const handleFilterSearch = (value, dataIndex) => {
    setFilterSearch(value);
    setFilterIsEnd(false);
    setFilterLoading(true);
    setFilterList([]);
    switch (dataIndex) {
      case 'name':
        libraries({
          variables: {
            filter: {
              ...initialTableFilter,
              limit: LIMIT,
              search: value,
            },
            sort: initialTableSort,
          },
        });
        break;

      default:
        break;
    }
  };

  const handleShare = async (code) => {
    setIsShareLoading(true);
    const response = await couponReferralLink({
      variables: {
        where: {
          code,
        },
      },
    });
    if (response?.data) {
      setShareUrl(response?.data?.couponReferralLink?.data?.url);
      setShareTitle(
        `Parents, stop buying toys! Try the Elefant Toy Library instead.\nBecome a member today & use my code ${code} at checkout to get Discount!\nClick the link & become a member!`,
      );
      setIsShareLoading(false);
    }
  };

  const changeFilter = (newFilterObj, dataIndex) => {
    const {
      target: { value },
    } = newFilterObj;

    const filterTagsCopy = { ...filterTags };
    let newTagList;
    if (whereFilter?.[dataIndex]?.includes(value?.key)) {
      newTagList = {
        ...filterTagsCopy,
        [dataIndex]: filter(filterTagsCopy?.[dataIndex], (item) => {
          if (item?.label) {
            return item?.key !== value?.key;
          }
          return item !== value;
        }),
      };
    } else {
      newTagList = {
        ...filterTagsCopy,
        [dataIndex]: filterTagsCopy?.[dataIndex]
          ? [...filterTagsCopy?.[dataIndex], value]
          : [value],
      };
    }

    const updatedWhereFilter = {
      ...whereFilter,
      [dataIndex]: newTagList?.[dataIndex]?.map((item) => item?.key),
    };
    setWhereFilter(updatedWhereFilter);
    setFilterTags(newTagList);
  };

  const handleDeleteFilter = (value, dataIndex) => {
    let newTagList;

    if (whereFilter?.[dataIndex]?.includes(value?.key)) {
      newTagList = {
        ...filterTags,
        [dataIndex]: filter(filterTags?.[dataIndex], (item) => {
          if (item?.key) {
            return item?.key !== value?.key;
          }
          return item !== value;
        }),
      };
    }

    const updatedWhereFilter = {
      ...whereFilter,
      [dataIndex]: newTagList?.[dataIndex]?.map((item) => item?.key),
    };
    setWhereFilter(updatedWhereFilter);
    setFilterTags(newTagList);
  };

  const onRangePickerChange = (values, dataIndex) => {
    const rangeObj = {
      from: values[0]?.startOf('day'),
      to: values?.[1]?.endOf('day'),
    };

    const filtersCopy = {
      ...whereFilter,
      [dataIndex]: rangeObj,
    };
    if (!values?.length) {
      delete whereFilter?.[dataIndex];
    }
    setWhereFilter(filtersCopy);
  };

  const onRadioChange = (value, dataIndex) => {
    const filtersCopy = {
      ...whereFilter,
      [dataIndex]: value,
    };
    if (!value?.length) {
      delete whereFilter?.[dataIndex];
    }
    setWhereFilter(filtersCopy);
  };

  const filterPopup = (dataIndex, showSearch = true) => ({
    filterDropdown: ({ confirm, clearFilters }) => {
      if (dataIndex === 'createdAt') {
        return (
          <div className="custom-filter-dropdown">
            <RangePicker
              allowClear={false}
              onChange={(values) => onRangePickerChange(values, dataIndex)}
              value={
                whereFilter?.[dataIndex]?.from
                  ? [
                      whereFilter?.[dataIndex]?.from,
                      whereFilter?.[dataIndex]?.to,
                    ]
                  : null
              }
            />
            <Divider className="divider-filter" />
            <div className="d-flex justify-center">
              <Button
                size="small"
                className="reset-button"
                id="roles-filter-reset"
                onClick={() => handleReset(clearFilters, dataIndex, confirm)}
              >
                Reset
              </Button>
              <Button
                size="small"
                className="common-button ok-button"
                id="roles-filter-ok"
                type="primary"
                onClick={() => getFilterData(confirm, dataIndex)}
              >
                Ok
              </Button>
            </div>
          </div>
        );
      }

      if (dataIndex === 'type') {
        return (
          <div className="custom-filter-dropdown">
            <Group
              options={map(COUPON_TYPE, (item) => ({
                label: item?.label,
                value: item?.value,
              }))}
              value={whereFilter?.[dataIndex]}
              onChange={(e) => onRadioChange(e?.target?.value, dataIndex)}
            />
            <Divider className="divider-filter" />
            <div className="d-flex justify-center">
              <Button
                size="small"
                className="reset-button"
                id="roles-filter-reset"
                onClick={() => handleReset(clearFilters, dataIndex, confirm)}
              >
                Reset
              </Button>
              <Button
                size="small"
                className="common-button ok-button"
                id="roles-filter-ok"
                type="primary"
                onClick={() => getFilterData(confirm, dataIndex)}
              >
                Ok
              </Button>
            </div>
          </div>
        );
      }

      if (dataIndex === 'status') {
        return (
          <div className="custom-filter-dropdown">
            <Group
              options={USER_FORM_STATUS}
              value={whereFilter?.[dataIndex]}
              onChange={(e) => onRadioChange(e?.target?.value, dataIndex)}
            />
            <Divider className="divider-filter" />
            <div className="d-flex justify-center">
              <Button
                size="small"
                className="reset-button"
                id="roles-filter-reset"
                onClick={() => handleReset(clearFilters, dataIndex, confirm)}
              >
                Reset
              </Button>
              <Button
                size="small"
                className="common-button ok-button"
                id="roles-filter-ok"
                type="primary"
                onClick={() => getFilterData(confirm, dataIndex)}
              >
                Ok
              </Button>
            </div>
          </div>
        );
      }

      return (
        <div className="custom-filter-dropdown">
          <LoaderComponent
            spinning={filterLoading}
            setHeight={35}
            size="medium"
          >
            {showSearch && (
              <SearchComponent
                className="filter-search"
                id="search-container-id-roles"
                placeholder="Search..."
                name={dataIndex}
                query={filterSearch}
                setQuery={setFilterSearch}
                getData={(value) => handleFilterSearch(value, dataIndex)}
              />
            )}
            {filterTags?.[dataIndex]?.length > 0 && (
              <div className="filter-tags-wrapper">
                {map(filterTags?.[dataIndex], (item) => (
                  <Tag
                    key={item?.key}
                    closable
                    onClose={() => handleDeleteFilter(item, dataIndex)}
                    className="filter-tag"
                  >
                    <span>{item?.label}</span>
                  </Tag>
                ))}
              </div>
            )}
            <div
              className="filter-checkboxes"
              onScroll={(e) => onFilterScroll(e, dataIndex)}
            >
              {filterList?.length > 0
                ? map(filterList, (item) => (
                    <div className="single-checkbox-div" key={item?.key}>
                      <Checkbox
                        value={item}
                        checked={whereFilter?.[dataIndex]?.includes(item?.key)}
                        key={item?.key || item}
                        onChange={(e) => changeFilter(e, dataIndex)}
                        className="single-checkbox"
                      >
                        <span>{item?.label}</span>
                      </Checkbox>
                    </div>
                  ))
                : isEmptyFilterList && (
                    <Empty image={Empty?.PRESENTED_IMAGE_SIMPLE} />
                  )}
            </div>
          </LoaderComponent>
          <Divider className="divider-filter" />
          <div className="d-flex justify-center">
            <Button
              size="small"
              className="reset-button"
              id="roles-filter-reset"
              onClick={() => handleReset(clearFilters, dataIndex, confirm)}
            >
              Reset
            </Button>
            <Button
              size="small"
              className="common-button ok-button"
              id="roles-filter-ok"
              type="primary"
              onClick={() => getFilterData(confirm, dataIndex)}
            >
              Ok
            </Button>
          </div>
        </div>
      );
    },
    filterIcon: () =>
      finalWhereFilters?.[dataIndex]?.length > 0 ||
      !isEmpty(finalWhereFilters?.[dataIndex]) ||
      finalWhereFilters?.[dataIndex] === true ||
      finalWhereFilters?.[dataIndex] === false ? (
        <FilterFilled className="filter-icon filter-filled" />
      ) : (
        <FilterOutlined className="filter-icon" />
      ),
    onFilterDropdownOpenChange: (visible) => {
      setFilterIndex(dataIndex);
      setFilterVisible(visible);
    },
  });

  const columns = [
    {
      title: 'CODE',
      dataIndex: 'code',
      key: 'code',
      ellipsis: true,
      width: 200,
      align: 'left',
      // eslint-disable-next-line no-undef
      fixed: window.innerWidth > BREAKPOINTS.desktop ? 'left' : false,
      className: 'max-width-column',
      render: (_, record) => (
        <div className="d-flex">
          <span className="pr-8">{record?.code}</span>
          <CopyOutlined
            className="copy-icon"
            onClick={() => handleCopy(record?.code)}
          />
        </div>
      ),
    },
    {
      title: 'NAME',
      dataIndex: 'name',
      key: 'name',
      ellipsis: true,
      width: 250,
      align: 'left',
      className: 'max-width-column',
      render: (_, record) => record?.name || '-',
    },
    {
      title: 'COMMISSION',
      dataIndex: 'commission',
      key: 'commission',
      ellipsis: true,
      width: 150,
      align: 'left',
      className: 'max-width-column',
      render: (_, record) => record?.commission || '-',
    },
    {
      title: 'MAXIMUM REDEMPTIONS',
      dataIndex: 'maxRedemptions',
      key: 'maxRedemptions',
      ellipsis: true,
      width: 150,
      align: 'left',
      className: 'max-width-column',
      render: (_, record) => record?.maxRedemptions || '-',
    },
    {
      title: 'START ON',
      dataIndex: 'validFrom',
      key: 'validFrom',
      width: 150,
      ellipsis: true,
      align: 'left',
      className: 'max-width-column',
      render: (_, record) =>
        formatDate(record?.validFrom, DATE_FORMATS?.REGULAR) || '-',
    },
    {
      title: 'EXPIRE ON',
      dataIndex: 'validTo',
      key: 'validTo',
      width: 150,
      ellipsis: true,
      align: 'left',
      className: 'max-width-column',
      render: (_, record) =>
        formatDate(record?.validTo, DATE_FORMATS?.REGULAR) || '-',
    },
    {
      title: 'AMOUNT',
      dataIndex: 'value',
      key: 'value',
      ellipsis: true,
      width: 150,
      align: 'left',
      className: 'max-width-column',
      render: (_, record) => record?.value,
    },
    {
      title: 'USED BY',
      dataIndex: 'usedBy',
      key: 'usedBy',
      ellipsis: true,
      width: 100,
      render: (_, record) => record?.usedCouponCount,
    },
    {
      title: 'DESCRIPTION',
      dataIndex: 'description',
      key: 'description',
      ellipsis: true,
      width: 150,
      align: 'left',
      className: 'max-width-column table-tooltip-cell',
      render: (_, record) => (
        <Tooltip
          title={record?.description ? record?.description : 'No Description'}
          placement="topLeft"
          trigger="click"
          className="pointer"
        >
          {record?.description || '-'}
        </Tooltip>
      ),
    },
    {
      title: 'GENERATED ON',
      dataIndex: 'createdAt',
      key: 'createdAt',
      width: 150,
      ellipsis: true,
      align: 'left',
      className: 'max-width-column',
      render: (_, record) =>
        formatDate(record?.createdAt, DATE_FORMATS?.REGULAR) || '-',
      ...filterPopup('createdAt'),
    },
    {
      title: 'TYPE',
      dataIndex: 'type',
      key: 'type',
      ellipsis: true,
      width: 150,
      align: 'left',
      className: 'max-width-column',
      render: (_, record) => COUPON_TYPE?.[record?.type]?.label,
      ...filterPopup('type'),
    },
    {
      title: 'STATUS',
      dataIndex: 'status',
      key: 'status',
      ellipsis: true,
      width: 150,
      align: 'left',
      className: 'max-width-column',
      render: (_, record) => (
        <Badge
          color={
            record?.isActive ? STATUS?.ACTIVE?.color : STATUS?.INACTIVE?.color
          }
          text={
            record?.isActive ? STATUS?.ACTIVE?.label : STATUS?.INACTIVE?.label
          }
        />
      ),
      ...filterPopup('status'),
    },
    {
      title: 'ACTIONS',
      dataIndex: 'actions',
      key: 'actions',
      ellipsis: true,
      width: 150,
      render: (_, record) => (
        <Button
          type="primary"
          size="small"
          className="table-action-primary-btn"
          onClick={() => {
            setIsShareModalOpen(true);
            handleShare(record?.code);
          }}
        >
          Referral Link
        </Button>
      ),
    },
  ];

  const locale = {
    emptyText: isEmptyCouponList ? '' : <span />,
  };

  return (
    <>
      <ShareModal
        title="Share"
        isModalOpen={isShareModalOpen}
        setIsModalOpen={setIsShareModalOpen}
        isShareLoading={isShareLoading}
        setIsShareLoading={setIsShareLoading}
        shareTitle={shareTitle}
        shareUrl={shareUrl}
      />
      <div className="d-flex justify-end mb-16 align-center">
        <SearchComponent
          className={isDesktop ? 'search-box' : ''}
          query={query}
          setQuery={setQuery}
          getData={handleSearch}
        />
        {!isDesktop && (
          <Badge dot={hasNonEmptyValueObj(finalWhereFilters)} size="small">
            <FilterIcon
              className="mobile-svg-icon ml-16"
              onClick={() => setIsAllFilterModalOpen(true)}
            />
          </Badge>
        )}
      </div>
      {isDesktop ? (
        <CommonTable
          locale={locale}
          columns={columns}
          data={couponList || []}
          loading={isCouponLoading}
          onChange={handleTableChange}
          paginationConfig={paginationProp}
          rowKey={(record) => record?.id}
        />
      ) : (
        <div className="mobile-container no-scroll-bar">
          <AllFilterCouponModal
            onFinish={handleFilter}
            isModalOpen={isAllFilterModalOpen}
            setIsModalOpen={setIsAllFilterModalOpen}
            finalWhereFilters={finalWhereFilters}
            setFinalWhereFilters={setFinalWhereFilters}
          />
          <CouponList
            couponList={couponList}
            infiniteScrollRef={infiniteScrollRef}
            isFetchMoreCouponLoading={isFetchMoreCouponLoading}
            isEmptyCouponList={isEmptyCouponList}
            isCouponLoading={isCouponLoading}
            setIsShareModalOpen={setIsShareModalOpen}
            handleShare={handleShare}
          />
        </div>
      )}
    </>
  );
};

export default CouponTable;
