import { Tabs } from 'antd';
import React, { useContext, useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { AppContext } from '../../../../../AppContext';
import { PERMISSIONS_KEY, ROUTES, TABS } from '../../../../../common/constants';
import { filterTabsByPermissions } from '../../../../../common/utils';

const EndUserTabs = ({ children }) => {
  const history = useHistory();
  const location = useLocation();
  const {
    state: { permissions },
  } = useContext(AppContext);
  const [activeTabKey, setActiveTabKey] = useState(TABS?.SUGGESTED_TOY?.key);

  const tabList = [
    {
      key: TABS?.SUGGESTED_TOY?.key,
      label: TABS?.SUGGESTED_TOY?.label,
      children,
      permissionKey: PERMISSIONS_KEY?.REQUESTS_END_USER_CHANGE_ADDRESS,
    },
    {
      key: TABS?.CHANGE_ADDRESS?.key,
      label: TABS?.CHANGE_ADDRESS?.label,
      children,
      permissionKey: PERMISSIONS_KEY?.REQUESTS_END_USER_CHANGE_ADDRESS,
    },
    {
      key: TABS?.REPORT_DAMAGE?.key,
      label: TABS?.REPORT_DAMAGE?.label,
      children,
      permissionKey: PERMISSIONS_KEY?.REQUESTS_END_USER_REPORT_DAMAGE,
    },
    {
      key: TABS?.ENCASHMENT?.key,
      label: TABS?.ENCASHMENT?.label,
      children,
      permissionKey: PERMISSIONS_KEY?.REQUESTS_END_USER_ENCASHMENT,
    },
  ];

  const filteredTabs = filterTabsByPermissions(tabList, permissions);

  useEffect(() => {
    if (location?.pathname?.includes(ROUTES?.SUGGESTED_TOY)) {
      setActiveTabKey(TABS?.SUGGESTED_TOY?.key);
    }
    if (location?.pathname?.includes(ROUTES?.CHANGE_ADDRESS)) {
      setActiveTabKey(TABS?.CHANGE_ADDRESS?.key);
    }
    if (location?.pathname?.includes(ROUTES?.REPORT_DAMAGE)) {
      setActiveTabKey(TABS?.REPORT_DAMAGE?.key);
    }
    if (location?.pathname?.includes(ROUTES?.ENCASHMENT)) {
      setActiveTabKey(TABS?.ENCASHMENT?.key);
    }
  }, [location]);

  const onTabChange = (key) => {
    setActiveTabKey(key);
    switch (key) {
      case TABS?.SUGGESTED_TOY?.key:
        history?.push(
          `${ROUTES?.REQUESTS}${ROUTES?.END_USER}${ROUTES?.SUGGESTED_TOY}`,
        );
        break;
      case TABS?.CHANGE_ADDRESS?.key:
        history?.push(
          `${ROUTES?.REQUESTS}${ROUTES?.END_USER}${ROUTES?.CHANGE_ADDRESS}`,
        );
        break;
      case TABS?.REPORT_DAMAGE?.key:
        history?.push(
          `${ROUTES?.REQUESTS}${ROUTES?.END_USER}${ROUTES?.REPORT_DAMAGE}`,
        );
        break;
      case TABS?.ENCASHMENT?.key:
        history?.push(
          `${ROUTES?.REQUESTS}${ROUTES?.END_USER}${ROUTES?.ENCASHMENT}`,
        );
        break;
      default:
        break;
    }
  };

  return (
    <div className="card-container">
      <Tabs
        activeKey={activeTabKey}
        type="card"
        className="full-width"
        onChange={(key) => onTabChange(key)}
        items={filteredTabs}
        destroyInactiveTabPane
      />
    </div>
  );
};

export default EndUserTabs;
