import { FilterFilled, FilterOutlined } from '@ant-design/icons';
import { useLazyQuery, useMutation } from '@apollo/client';
import {
  Badge,
  Button,
  Checkbox,
  DatePicker,
  Divider,
  Empty,
  Modal,
  Radio,
  Switch,
  Tag,
  Tooltip,
} from 'antd';
import { filter, forEach, isEmpty, map } from 'lodash';
import React, { useContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { AppContext } from '../../../../../AppContext';
import { ReactComponent as ArchiveIcon } from '../../../../../assets/svg/box-archive-solid.svg';
import { ReactComponent as ResetpasswordIcon } from '../../../../../assets/svg/lock-solid.svg';
import { ReactComponent as EditIcon } from '../../../../../assets/svg/pen-to-square-solid.svg';
import { ReactComponent as UnarchiveIcon } from '../../../../../assets/svg/right-from-bracket-solid.svg';
import { ReactComponent as ActiveIcon } from '../../../../../assets/svg/user-solid.svg';
import {
  ADMIN_ROLES,
  ARCHIVING_STATE,
  BREAKPOINTS,
  DATE_FORMATS,
  LIMIT,
  PERMISSIONS_KEY,
  PERMISSION_TYPE,
  ROUTES,
  SORT,
  STATUS,
  USER_FORM_STATUS,
  USER_ROLES,
} from '../../../../../common/constants';
import { formatDate, hasPermission } from '../../../../../common/utils';
import CommonTable from '../../../../../components/CommonTable';
import LoaderComponent from '../../../../../components/LoaderComponent';
import SearchComponent from '../../../../../components/SearchComponent';
import { FORGOT_PASSWORD } from '../../../../auth/graphql/Mutations';
import { UPDATE_USER } from '../../../graphql/Mutations';
import { USERS } from '../../../graphql/Queries';

const { RangePicker } = DatePicker;
const { Group } = Radio;

const initialPaginationValue = {
  total: 0,
  current: 1,
  pageSize: 10,
};

const initialUsersFilter = {
  limit: LIMIT,
  search: '',
  skip: 0,
};

const initialUsersSort = {
  field: 'createdAt',
  order: 'DESC',
};

const roleFilter = [
  USER_ROLES?.POWER_ADMIN?.key,
  USER_ROLES?.BASIC_ADMIN?.key,
  USER_ROLES?.ORDER_INVENTORY_ADMIN?.key,
];

const AdminsTable = ({ exportFilter, setExportFilter }) => {
  const {
    state: { permissions },
  } = useContext(AppContext);
  const history = useHistory();
  const [adminList, setAdminList] = useState([]);
  const [adminSearchTerm, setAdminSearchTerm] = useState('');
  const [isEmptyAdminList, setIsEmptyAdminList] = useState(false);
  const [admin, setAdmin] = useState({});
  const [query, setQuery] = useState('');
  const [sortedInfo, setSortedInfo] = useState({});
  const [paginationProp, setPaginationProp] = useState(initialPaginationValue);

  const [isActivePrompts, setIsActivePrompts] = useState(false);
  const [isArchivePrompts, setIsArchivePrompts] = useState(false);
  const [isResetPrompts, setIsResetPrompts] = useState(false);

  const [isAdminLoading, setIsAdminLoading] = useState(true);
  const [isActiveLoading, setIsActiveLoading] = useState(false);
  const [isArchiveLoading, setIsArchiveLoading] = useState(false);
  const [isResetPassLoading, setIsResetPassLoading] = useState(false);

  const [isArchiveFilter, setIsArchiveFilter] = useState(false);
  const [filterList, setFilterList] = useState([]);
  const [whereFilter, setWhereFilter] = useState({ role: [] });
  const [filterLoading, setFilterLoading] = useState(false);
  const [filterTags, setFilterTags] = useState(null);
  const [filterVisible, setFilterVisible] = useState(false);
  const [filterIndex, setFilterIndex] = useState(null);
  const [finalWhereFilters, setFinalWhereFilters] = useState({ role: [] });
  const [finalFilterTags, setFinalFilterTags] = useState(null);

  const [forgotPassword] = useMutation(FORGOT_PASSWORD, {
    onError: () => {},
  });
  const [updateUser] = useMutation(UPDATE_USER, {
    onError: () => {},
  });

  const [users] = useLazyQuery(USERS, {
    onCompleted: (response) => {
      setAdminList([...response?.users?.data]);
      if (response?.users?.count === 0 && initialPaginationValue?.total === 0) {
        setIsEmptyAdminList(true);
      } else {
        setIsEmptyAdminList(false);
      }
      const pagination = {
        ...paginationProp,
        defaultPageSize: LIMIT,
        total: response?.users?.count,
      };
      setPaginationProp(pagination);
      setIsAdminLoading(false);
    },
    fetchPolicy: 'network-only',
    onError() {},
  });

  const hasUpdatePermission = hasPermission(
    PERMISSIONS_KEY?.SYSTEM_USERS_ADMINS,
    PERMISSION_TYPE?.UPDATE,
    permissions,
  );

  useEffect(() => {
    setPaginationProp({ ...paginationProp, current: 1, skip: 0 });
    setIsAdminLoading(true);
    users({
      variables: {
        filter: {
          ...initialUsersFilter,
          limit: paginationProp?.pageSize || LIMIT,
          search: adminSearchTerm,
          isArchived: isArchiveFilter,
          roles:
            finalWhereFilters?.role?.length > 0
              ? finalWhereFilters?.role
              : roleFilter,
          dateRange: {
            from: finalWhereFilters?.createdAt?.from?.$d,
            to: finalWhereFilters?.createdAt?.to?.$d,
          },
          isActive: finalWhereFilters?.status,
        },
        sort: sortedInfo?.column
          ? {
              field: sortedInfo?.field,
              order: sortedInfo?.order === 'ascend' ? SORT?.ASC : SORT?.DESC,
            }
          : initialUsersSort,
      },
    });
    setExportFilter({ ...exportFilter, isArchived: isArchiveFilter });
  }, [isArchiveFilter]);

  const handleTableChange = (pagination, tableFilter, sorter) => {
    const { current } = pagination;
    const skip = (current - 1) * (pagination?.pageSize || 0);
    setPaginationProp({ ...paginationProp, ...pagination });
    setIsAdminLoading(true);
    setSortedInfo(sorter);
    users({
      variables: {
        filter: {
          ...initialUsersFilter,
          skip,
          limit: pagination?.pageSize,
          search: adminSearchTerm,
          isArchived: isArchiveFilter,
          roles:
            finalWhereFilters?.role?.length > 0
              ? finalWhereFilters?.role
              : roleFilter,
          dateRange: {
            from: finalWhereFilters?.createdAt?.from?.$d,
            to: finalWhereFilters?.createdAt?.to?.$d,
          },
          isActive: finalWhereFilters?.status,
        },
        sort: sorter?.column
          ? {
              field: sorter?.field,
              order: sorter?.order === 'ascend' ? SORT?.ASC : SORT?.DESC,
            }
          : initialUsersSort,
      },
    });
  };

  const handleSearch = (value) => {
    const trimValue = value?.trim();
    setAdminSearchTerm(trimValue);
    setPaginationProp({ ...paginationProp, current: 1, skip: 0 });
    setIsAdminLoading(true);
    users({
      variables: {
        filter: {
          ...initialUsersFilter,
          limit: paginationProp?.pageSize || LIMIT,
          search: trimValue,
          isArchived: isArchiveFilter,
          roles:
            finalWhereFilters?.role?.length > 0
              ? finalWhereFilters?.role
              : roleFilter,
          dateRange: {
            from: finalWhereFilters?.createdAt?.from?.$d,
            to: finalWhereFilters?.createdAt?.to?.$d,
          },
          isActive: finalWhereFilters?.status,
        },
        sort: sortedInfo?.column
          ? {
              field: sortedInfo?.field,
              order: sortedInfo?.order === 'ascend' ? SORT?.ASC : SORT?.DESC,
            }
          : initialUsersSort,
      },
    });
    setExportFilter({ ...exportFilter, search: trimValue });
  };

  const handleActive = async (id, isActive) => {
    setIsActiveLoading(true);
    const response = await updateUser({
      variables: {
        where: {
          id,
        },
        data: {
          isActive: !isActive,
        },
      },
    });
    if (response?.data) {
      setIsActiveLoading(false);
      setIsAdminLoading(true);
      setIsActivePrompts(false);
      users({
        variables: {
          filter: {
            ...initialUsersFilter,
            skip:
              (paginationProp?.current - 1) * (paginationProp?.pageSize || 0),
            limit: paginationProp?.pageSize || LIMIT,
            search: adminSearchTerm,
            roles:
              finalWhereFilters?.role?.length > 0
                ? finalWhereFilters?.role
                : roleFilter,
            dateRange: {
              from: finalWhereFilters?.createdAt?.from?.$d,
              to: finalWhereFilters?.createdAt?.to?.$d,
            },
            isActive: finalWhereFilters?.status,
          },
          sort: sortedInfo?.column
            ? {
                field: sortedInfo?.field,
                order: sortedInfo?.order === 'ascend' ? SORT?.ASC : SORT?.DESC,
              }
            : initialUsersSort,
        },
      });
    }
    setIsActiveLoading(false);
  };

  const handleArchive = async (id, isArchived) => {
    setIsArchiveLoading(true);
    const response = await updateUser({
      variables: {
        where: {
          id,
        },
        data: {
          isArchived: !isArchived,
        },
      },
    });
    if (response?.data) {
      setIsArchiveLoading(false);
      setIsAdminLoading(true);
      setIsArchivePrompts(false);
      users({
        variables: {
          filter: {
            ...initialUsersFilter,
            skip:
              (paginationProp?.current - 1) * (paginationProp?.pageSize || 0),
            limit: paginationProp?.pageSize || LIMIT,
            search: adminSearchTerm,
            isArchived: isArchiveFilter,
            roles:
              finalWhereFilters?.role?.length > 0
                ? finalWhereFilters?.role
                : roleFilter,
            dateRange: {
              from: finalWhereFilters?.createdAt?.from?.$d,
              to: finalWhereFilters?.createdAt?.to?.$d,
            },
            isActive: finalWhereFilters?.status,
          },
          sort: sortedInfo?.column
            ? {
                field: sortedInfo?.field,
                order: sortedInfo?.order === 'ascend' ? SORT?.ASC : SORT?.DESC,
              }
            : initialUsersSort,
        },
      });
    }
    setIsArchiveLoading(false);
  };

  const handleResetPassword = async (email) => {
    setIsResetPassLoading(true);
    const response = await forgotPassword({
      variables: {
        data: {
          email,
        },
      },
    });
    if (response?.data) {
      setIsResetPrompts(false);
    }
    setIsResetPassLoading(false);
  };

  const getFilterData = (confirm) => {
    setIsAdminLoading(true);
    users({
      variables: {
        filter: {
          ...initialUsersFilter,
          limit: paginationProp?.pageSize || LIMIT,
          search: adminSearchTerm,
          isArchived: isArchiveFilter,
          roles: whereFilter?.role?.length > 0 ? whereFilter?.role : roleFilter,
          dateRange: {
            from: whereFilter?.createdAt?.from?.$d,
            to: whereFilter?.createdAt?.to?.$d,
          },
          isActive:
            whereFilter?.status === true || whereFilter?.status === false
              ? whereFilter?.status
              : undefined,
        },
        sort: sortedInfo?.column
          ? {
              field: sortedInfo?.field,
              order: sortedInfo?.order === 'ascend' ? SORT?.ASC : SORT?.DESC,
            }
          : initialUsersSort,
      },
    });
    setFinalWhereFilters({ ...finalWhereFilters, ...whereFilter });
    setExportFilter({ ...exportFilter, ...finalWhereFilters, ...whereFilter });
    setFinalFilterTags(filterTags);
    setPaginationProp({ ...paginationProp, skip: 0, current: 1 });
    if (confirm) {
      confirm();
    }
  };

  const handleReset = (clearFilters, dataIndex, confirm) => {
    const tagsCopy = {
      ...filterTags,
      [dataIndex]: [],
    };
    let whereFiltersCopy;
    if (dataIndex === 'status') {
      whereFiltersCopy = {
        ...whereFilter,
        [dataIndex]: null,
      };
    } else {
      whereFiltersCopy = {
        ...whereFilter,
        [dataIndex]: [],
      };
    }
    setFilterVisible(false);
    setFinalFilterTags({ ...filterTags, ...tagsCopy });
    setFinalWhereFilters({ ...finalWhereFilters, ...whereFiltersCopy });
    setExportFilter({
      ...exportFilter,
      ...finalWhereFilters,
      ...whereFiltersCopy,
    });
    setIsAdminLoading(true);
    users({
      variables: {
        filter: {
          ...initialUsersFilter,
          limit: paginationProp?.pageSize || LIMIT,
          search: adminSearchTerm,
          isArchived: isArchiveFilter,
          roles:
            whereFiltersCopy?.role?.length > 0
              ? whereFiltersCopy?.role
              : roleFilter,
          dateRange: {
            from: whereFiltersCopy?.createdAt?.from?.$d,
            to: whereFiltersCopy?.createdAt?.to?.$d,
          },
          isActive:
            whereFiltersCopy?.status === true ||
            whereFiltersCopy?.status === false
              ? whereFiltersCopy?.status
              : undefined,
        },
        sort: sortedInfo?.column
          ? {
              field: sortedInfo?.field,
              order: sortedInfo?.order === 'ascend' ? SORT?.ASC : SORT?.DESC,
            }
          : initialUsersSort,
      },
    });
    setPaginationProp({ ...paginationProp, skip: 0, current: 1 });
    clearFilters();
    setFilterVisible(false);
    if (confirm) {
      confirm();
    }
  };

  useEffect(() => {
    if (filterVisible) {
      setFilterList([]);
      setFilterLoading(true);
      setFilterTags({ ...filterTags, ...finalFilterTags });
      setWhereFilter({ ...whereFilter, ...finalWhereFilters });
      const optionsCopy = [];

      switch (filterIndex) {
        case 'status':
          forEach(STATUS, (item) => {
            optionsCopy?.push({ label: item?.label, key: item?.key });
          });
          setFilterList(optionsCopy);
          setFilterLoading(false);
          break;

        case 'role':
          forEach(ADMIN_ROLES, (item) => {
            optionsCopy?.push({ label: item?.label, key: item?.key });
          });
          setFilterList(optionsCopy);
          setFilterLoading(false);
          break;

        default:
          break;
      }
    }
    if (!filterVisible) {
      setFilterTags(finalFilterTags);
      setWhereFilter(finalWhereFilters);
    }
  }, [filterVisible]);

  const changeFilter = (newFilterObj, dataIndex) => {
    const {
      target: { value },
    } = newFilterObj;

    const filterTagsCopy = { ...filterTags };
    let newTagList;
    if (whereFilter?.[dataIndex]?.includes(value?.key)) {
      newTagList = {
        ...filterTagsCopy,
        [dataIndex]: filter(filterTagsCopy?.[dataIndex], (item) => {
          if (item?.label) {
            return item?.key !== value?.key;
          }
          return item !== value;
        }),
      };
    } else {
      newTagList = {
        ...filterTagsCopy,
        [dataIndex]: filterTagsCopy?.[dataIndex]
          ? [...filterTagsCopy?.[dataIndex], value]
          : [value],
      };
    }

    const updatedWhereFilter = {
      ...whereFilter,
      [dataIndex]: newTagList?.[dataIndex]?.map((item) => item?.key),
    };
    setWhereFilter(updatedWhereFilter);
    setFilterTags(newTagList);
  };

  const handleDeleteFilter = (value, dataIndex) => {
    let newTagList;

    if (whereFilter?.[dataIndex]?.includes(value?.key)) {
      newTagList = {
        ...filterTags,
        [dataIndex]: filter(filterTags?.[dataIndex], (item) => {
          if (item?.key) {
            return item?.key !== value?.key;
          }
          return item !== value;
        }),
      };
    }

    const updatedWhereFilter = {
      ...whereFilter,
      [dataIndex]: newTagList?.[dataIndex]?.map((item) => item?.key),
    };
    setWhereFilter(updatedWhereFilter);
    setFilterTags(newTagList);
  };

  const onRangePickerChange = (values, dataIndex) => {
    const rangeObj = {
      from: values[0]?.startOf('day'),
      to: values?.[1]?.endOf('day'),
    };

    const filtersCopy = {
      ...whereFilter,
      [dataIndex]: rangeObj,
    };
    if (!values?.length) {
      delete whereFilter?.[dataIndex];
    }
    setWhereFilter(filtersCopy);
  };

  const onRadioChange = (value, dataIndex) => {
    const filtersCopy = {
      ...whereFilter,
      [dataIndex]: value,
    };
    if (!value?.length) {
      delete whereFilter?.[dataIndex];
    }
    setWhereFilter(filtersCopy);
  };

  const filterPopup = (dataIndex) => ({
    filterDropdown: ({ confirm, clearFilters }) => {
      if (dataIndex === 'createdAt') {
        return (
          <div className="custom-filter-dropdown">
            <RangePicker
              allowClear={false}
              format={DATE_FORMATS?.REGULAR}
              onChange={(values) => onRangePickerChange(values, dataIndex)}
              value={
                whereFilter?.[dataIndex]?.from
                  ? [
                      whereFilter?.[dataIndex]?.from,
                      whereFilter?.[dataIndex]?.to,
                    ]
                  : null
              }
            />
            <Divider className="divider-filter" />
            <div className="d-flex justify-center">
              <Button
                size="small"
                className="reset-button"
                id="roles-filter-reset"
                onClick={() => handleReset(clearFilters, dataIndex, confirm)}
              >
                Reset
              </Button>
              <Button
                size="small"
                className="common-button ok-button"
                id="roles-filter-ok"
                type="primary"
                onClick={() => getFilterData(confirm, dataIndex)}
              >
                Ok
              </Button>
            </div>
          </div>
        );
      }

      if (dataIndex === 'status') {
        return (
          <div className="custom-filter-dropdown">
            <Group
              options={USER_FORM_STATUS}
              value={whereFilter?.[dataIndex]}
              onChange={(e) => onRadioChange(e?.target?.value, dataIndex)}
            />
            <Divider className="divider-filter" />
            <div className="d-flex justify-center">
              <Button
                size="small"
                className="reset-button"
                id="roles-filter-reset"
                onClick={() => handleReset(clearFilters, dataIndex, confirm)}
              >
                Reset
              </Button>
              <Button
                size="small"
                className="common-button ok-button"
                id="roles-filter-ok"
                type="primary"
                onClick={() => getFilterData(confirm, dataIndex)}
              >
                Ok
              </Button>
            </div>
          </div>
        );
      }

      return (
        <div className="custom-filter-dropdown">
          <LoaderComponent spinning={filterLoading} setHeight={35}>
            {filterTags?.[dataIndex]?.length > 0 && (
              <div className="filter-tags-wrapper">
                {map(filterTags?.[dataIndex], (item) => (
                  <Tag
                    key={item?.key}
                    closable
                    onClose={() => handleDeleteFilter(item, dataIndex)}
                    className="filter-tag"
                  >
                    <span>{item?.label}</span>
                  </Tag>
                ))}
              </div>
            )}
            <div className="filter-checkboxes">
              {filterList?.length > 0 ? (
                map(filterList, (item) => (
                  <div className="single-checkbox-div" key={item?.key}>
                    <Checkbox
                      value={item}
                      checked={whereFilter?.[dataIndex]?.includes(item?.key)}
                      key={item?.key || item}
                      onChange={(e) => changeFilter(e, dataIndex)}
                      className="single-checkbox"
                    >
                      <span>{item?.label}</span>
                    </Checkbox>
                  </div>
                ))
              ) : (
                <Empty image={Empty?.PRESENTED_IMAGE_SIMPLE} />
              )}
            </div>
          </LoaderComponent>
          <Divider className="divider-filter" />
          <div className="d-flex justify-center">
            <Button
              size="small"
              className="reset-button"
              id="roles-filter-reset"
              onClick={() => handleReset(clearFilters, dataIndex, confirm)}
            >
              Reset
            </Button>
            <Button
              size="small"
              className="common-button ok-button"
              id="roles-filter-ok"
              type="primary"
              onClick={() => getFilterData(confirm, dataIndex)}
            >
              Ok
            </Button>
          </div>
        </div>
      );
    },
    filterIcon: () =>
      finalWhereFilters?.[dataIndex]?.length > 0 ||
      !isEmpty(finalWhereFilters?.[dataIndex]) ||
      finalWhereFilters?.[dataIndex] === true ||
      finalWhereFilters?.[dataIndex] === false ? (
        <FilterFilled className="filter-icon filter-filled" />
      ) : (
        <FilterOutlined className="filter-icon" />
      ),
    onFilterDropdownOpenChange: (visible) => {
      setFilterIndex(dataIndex);
      setFilterVisible(visible);
    },
  });

  const columns = [
    {
      title: 'ADMIN NAME',
      dataIndex: 'firstName',
      key: 'firstName',
      ellipsis: true,
      sorter: true,
      width: 250,
      align: 'left',
      // eslint-disable-next-line no-undef
      fixed: window.innerWidth > BREAKPOINTS.desktop ? 'left' : false,
      className: 'max-width-column',
      sortOrder: sortedInfo?.columnKey === 'firstName' && sortedInfo?.order,
      render: (_, record) => (
        <>
          {record?.firstName || record?.lastName ? (
            <div className="table-data-with-id">{`${
              record?.firstName ? record?.firstName : ''
            } ${record?.lastName ? record?.lastName : ''}`}</div>
          ) : (
            '-'
          )}
          {record?.userNumber ? (
            <div className="font-size-12">({record?.userNumber})</div>
          ) : (
            ''
          )}
        </>
      ),
    },
    {
      title: 'EMAIL',
      dataIndex: 'email',
      key: 'email',
      ellipsis: true,
      width: 250,
      sorter: true,
      align: 'left',
      className: 'max-width-column',
      sortOrder: sortedInfo?.columnKey === 'email' && sortedInfo?.order,
      render: (_, record) => record?.email || '-',
    },
    {
      title: 'CONTACT',
      dataIndex: 'contactNo',
      key: 'contactNo',
      ellipsis: true,
      width: 250,
      align: 'left',
      className: 'max-width-column',
      render: (_, record) => record?.contactNo || '-',
    },
    {
      title: 'ROLE',
      dataIndex: 'role',
      key: 'role',
      ellipsis: true,
      width: 200,
      align: 'left',
      className: 'max-width-column',
      render: (_, record) => USER_ROLES?.[record?.roles?.[0]]?.label,
      ...filterPopup('role'),
    },
    {
      title: 'ONBOARDED ON',
      dataIndex: 'createdAt',
      key: 'createdAt',
      width: 150,
      ellipsis: true,
      align: 'left',
      className: 'max-width-column',
      render: (_, record) =>
        formatDate(record?.createdAt, DATE_FORMATS?.REGULAR) || '-',
      ...filterPopup('createdAt'),
    },
    {
      title: 'STATUS',
      dataIndex: 'status',
      key: 'status',
      ellipsis: true,
      width: 150,
      align: 'left',
      className: 'max-width-column',
      render: (_, record) => (
        <Badge
          color={
            record?.isActive ? STATUS?.ACTIVE?.color : STATUS?.INACTIVE?.color
          }
          text={
            record?.isActive ? STATUS?.ACTIVE?.label : STATUS?.INACTIVE?.label
          }
        />
      ),
      ...filterPopup('status'),
    },
    {
      title: 'ACTIONS',
      dataIndex: 'actions',
      key: 'actions',
      ellipsis: true,
      width: 150,
      // eslint-disable-next-line no-undef
      fixed: window.innerWidth > BREAKPOINTS.desktop ? 'right' : false,
      className: 'cursor-auto',
      onCell: () => ({
        onClick: (e) => e?.stopPropagation(),
      }),
      render: (_, record) => (
        <>
          <div className="action-button">
            {isArchiveFilter ? (
              <Tooltip title="Unarchive" placement="top" zIndex={4}>
                <UnarchiveIcon
                  onClick={(e) => {
                    e?.stopPropagation();
                    setIsArchivePrompts(true);
                    setAdmin(record);
                  }}
                  className="mr-16 pointer svg-icon"
                />
              </Tooltip>
            ) : (
              <>
                <Tooltip
                  title={record?.isActive ? 'Active' : 'Inactive'}
                  placement="top"
                  zIndex={4}
                >
                  <ActiveIcon
                    onClick={(e) => {
                      e?.stopPropagation();
                      setIsActivePrompts(true);
                      setAdmin(record);
                    }}
                    className={
                      record?.isActive
                        ? 'mr-16 pointer svg-icon'
                        : 'mr-16 pointer svg-icon-grey'
                    }
                  />
                </Tooltip>
                <Tooltip title="Edit" placement="top" zIndex={4}>
                  <EditIcon
                    onClick={(e) => {
                      e?.stopPropagation();
                      history?.push(
                        `${ROUTES?.SYSTEM_USERS}${ROUTES?.ADMINS}/edit/${record?.id}`,
                      );
                    }}
                    className="mr-16 pointer svg-icon"
                  />
                </Tooltip>
                <Tooltip title="Archive" placement="top" zIndex={4}>
                  <ArchiveIcon
                    onClick={(e) => {
                      e?.stopPropagation();
                      setIsArchivePrompts(true);
                      setAdmin(record);
                    }}
                    className="mr-16 pointer svg-icon"
                  />
                </Tooltip>
                <Tooltip title="Reset Password" placement="top" zIndex={4}>
                  <ResetpasswordIcon
                    onClick={(e) => {
                      e?.stopPropagation();
                      setIsResetPrompts(true);
                      setAdmin(record);
                    }}
                    className="mr-16 pointer svg-icon"
                  />
                </Tooltip>
              </>
            )}
          </div>
        </>
      ),
    },
  ]?.filter((item) => {
    if (item?.dataIndex === 'actions' && !hasUpdatePermission) {
      return item?.hidden;
    }
    return !item?.hidden;
  });

  const locale = {
    emptyText: isEmptyAdminList ? '' : <span />,
  };

  return (
    <>
      <Modal
        title="Caution"
        okText="Yes"
        cancelText="No"
        open={isActivePrompts}
        onOk={() => handleActive(admin?.id, admin?.isActive)}
        onCancel={() => setIsActivePrompts(false)}
        okButtonProps={{ loading: isActiveLoading }}
      >
        Are you sure you want to
        <strong>
          {' '}
          {!admin?.isActive
            ? STATUS?.ACTIVE?.label
            : STATUS?.INACTIVE?.label}{' '}
        </strong>
        this admin?
      </Modal>
      <Modal
        title="Caution"
        okText="Yes"
        cancelText="No"
        open={isArchivePrompts}
        onOk={() => handleArchive(admin?.id, admin?.isArchived)}
        onCancel={() => setIsArchivePrompts(false)}
        okButtonProps={{ loading: isArchiveLoading }}
      >
        Are you sure you want to
        <strong>
          {' '}
          {!admin?.isArchived
            ? ARCHIVING_STATE?.ARCHIVED
            : ARCHIVING_STATE?.UNARCHIVED}{' '}
        </strong>
        this admin?
      </Modal>
      <Modal
        title="Caution"
        okText="Yes"
        cancelText="No"
        open={isResetPrompts}
        onOk={() => handleResetPassword(admin?.email)}
        onCancel={() => setIsResetPrompts(false)}
        okButtonProps={{ loading: isResetPassLoading }}
      >
        By confirming, link will be sent to {admin?.email} to{' '}
        <strong>reset the password.</strong>
      </Modal>
      <div className="d-flex justify-end mb-16 flex-wrap">
        {hasUpdatePermission && (
          <div className="mr-16 d-flex align-center">
            <h4 className="m-0 mr-8">Only Archived</h4>{' '}
            <Switch
              size="small"
              onChange={(checked) => setIsArchiveFilter(checked)}
            />
          </div>
        )}
        <SearchComponent
          className="search-box"
          query={query}
          setQuery={setQuery}
          getData={handleSearch}
        />
      </div>
      <CommonTable
        locale={locale}
        columns={columns}
        data={adminList || []}
        loading={isAdminLoading}
        onChange={handleTableChange}
        paginationConfig={paginationProp}
        rowKey={(record) => record?.id}
        rowClassName="pointer table-cell"
        onRow={(record) => ({
          onClick: () => {
            history?.push(
              `${ROUTES?.SYSTEM_USERS}${ROUTES?.ADMINS}/view/${record?.id}`,
            );
          },
        })}
      />
    </>
  );
};

export default AdminsTable;
