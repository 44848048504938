import { useLazyQuery, useMutation } from '@apollo/client';
import { Form, Image, Modal, Tooltip } from 'antd';
import React, { useContext, useEffect, useState } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { AppContext } from '../../../../AppContext';
import { ReactComponent as EditIcon } from '../../../../assets/svg/pen-to-square-solid.svg';
import { ReactComponent as UnarchiveIcon } from '../../../../assets/svg/right-from-bracket-solid.svg';
import {
  ARCHIVING_STATE,
  BREAKPOINTS,
  DATE_FORMATS,
  LIMIT,
  PERMISSIONS_KEY,
  PERMISSION_TYPE,
  SORT,
} from '../../../../common/constants';
import {
  fileUpload,
  formatDate,
  hasPermission,
  openNotification,
} from '../../../../common/utils';
import CommonPreview from '../../../../components/CommonPreview';
import CommonTable from '../../../../components/CommonTable';
import SearchComponent from '../../../../components/SearchComponent';
import { CREATE_BANNER, UPDATE_BANNER } from '../graphql/Mutations';
import { BANNERS_ADMIN, GET_BANNER_IMAGE_SIGNED_PUT_URL } from '../graphql/Queries';
import BannerModal from './BannerModal';

const initialPaginationValue = {
  total: 0,
  current: 1,
  pageSize: 10,
};

const initialFilter = {
  limit: LIMIT,
  search: '',
  skip: 0,
};

const initialSort = {
  field: 'name',
  order: 'ASC',
};

const BannerTable = ({
  isBannerModalOpen,
  setIsBannerModalOpen,
  bannerImages,
  setBannerImages,
}) => {
  const {
    state: { permissions },
  } = useContext(AppContext);
  const [form] = Form.useForm();
  const [bannerList, setBannerList] = useState([]);
  const [bannerSearchTerm, setBannerSearchTerm] = useState('');
  const [isEmptyBannerList, setIsEmptyBannerList] = useState(false);
  const [banner, setBanner] = useState();
  const [query, setQuery] = useState('');
  const [sortedInfo, setSortedInfo] = useState({});
  const [paginationProp, setPaginationProp] = useState(initialPaginationValue);
  const [isArchivePrompts, setIsArchivePrompts] = useState(false);
  const [isBannerLoading, setIsBannerLoading] = useState(true);
  const [isArchiveLoading, setIsArchiveLoading] = useState(false);
  // eslint-disable-next-line
  const [isArchiveFilter, setIsArchiveFilter] = useState(false);
  const [isBtnLoading, setIsBtnLoading] = useState(false);
  const [visible, setVisible] = useState(false);
  const [bannerPreviewImage, setBannerPreviewImage] = useState();

  const [createBanner] = useMutation(CREATE_BANNER, {
    onError: () => { },
  });

  const [updateBanner] = useMutation(UPDATE_BANNER, {
    onError: () => { },
  });

  const [getBannerImageSignedPutUrl] = useLazyQuery(GET_BANNER_IMAGE_SIGNED_PUT_URL, {
    fetchPolicy: 'network-only',
    onError() {
      setIsBtnLoading(false);
    },
  });

  const [bannerAdmin] = useLazyQuery(BANNERS_ADMIN, {
    onCompleted: (response) => {
      setBannerList(
        [...response?.bannersAdmin?.data]?.filter(
          (item) => item?.minAge !== 0 || item?.maxAge !== 0,
        ),
      );
      if (
        response?.bannersAdmin?.count === 0 &&
        initialPaginationValue?.total === 0
      ) {
        setIsEmptyBannerList(true);
      } else {
        setIsEmptyBannerList(false);
      }
      const pagination = {
        ...paginationProp,
        defaultPageSize: LIMIT,
        total: response?.bannerAdmin?.count,
      };
      setPaginationProp(pagination);
      setIsBannerLoading(false);
    },
    fetchPolicy: 'network-only',
    onError() { },
  });

  const hasUpdatePermission = hasPermission(
    PERMISSIONS_KEY?.MASTER_LIST_TAG,
    PERMISSION_TYPE?.UPDATE,
    permissions,
  );

  useEffect(() => {
    setPaginationProp({ ...paginationProp, current: 1, skip: 0 });
    setIsBannerLoading(true);
    bannerAdmin({
      variables: {
        filter: {
          ...initialFilter,
          limit: paginationProp?.pageSize || LIMIT,
          search: bannerSearchTerm,
        },
        sort: sortedInfo?.column
          ? {
            field: sortedInfo?.field,
            order: sortedInfo?.order === 'ascend' ? SORT?.ASC : SORT?.DESC,
          }
          : initialSort,
      },
    });
  }, []);

  const handleTableChange = (pagination, tableFilter, sorter) => {
    const { current } = pagination;
    const skip = (current - 1) * (pagination?.pageSize || 0);
    setPaginationProp({ ...paginationProp, ...pagination });
    setIsBannerLoading(true);
    setSortedInfo(sorter);
    bannerAdmin({
      variables: {
        filter: {
          ...initialFilter,
          skip,
          limit: pagination?.pageSize,
          search: bannerSearchTerm,
        },
        sort: sorter?.column
          ? {
            field: sorter?.field,
            order: sorter?.order === 'ascend' ? SORT?.ASC : SORT?.DESC,
          }
          : initialSort,
      },
    });
  };

  const handleSearch = (value) => {
    const trimValue = value?.trim();
    setBannerSearchTerm(trimValue);
    setPaginationProp({ ...paginationProp, current: 1, skip: 0 });
    setIsBannerLoading(true);
    bannerAdmin({
      variables: {
        filter: {
          ...initialFilter,
          limit: paginationProp?.pageSize || LIMIT,
          search: trimValue,
        },
        sort: sortedInfo?.column
          ? {
            field: sortedInfo?.field,
            order: sortedInfo?.order === 'ascend' ? SORT?.ASC : SORT?.DESC,
          }
          : initialSort,
      },
    });
  };

  const handleArchive = async (id, isArchived) => {
    setIsArchiveLoading(true);
    const response = await updateBanner({
      variables: {
        where: {
          id,
        },
        data: {
          isArchived: !isArchived,
        },
      },
    });
    if (response?.data) {
      setIsArchiveLoading(false);
      setIsBannerLoading(true);
      setIsArchivePrompts(false);
      setBanner();
      bannerAdmin({
        variables: {
          filter: {
            ...initialFilter,
            skip:
              (paginationProp?.current - 1) * (paginationProp?.pageSize || 0),
            limit: paginationProp?.pageSize || LIMIT,
            search: bannerSearchTerm,
          },
          sort: sortedInfo?.column
            ? {
              field: sortedInfo?.field,
              order: sortedInfo?.order === 'ascend' ? SORT?.ASC : SORT?.DESC,
            }
            : initialSort,
        },
      });
    }
    setIsArchiveLoading(false);
  };

  const handleBanner = async (values) => {
    setIsBtnLoading(true);
    const uuid = uuidv4();
    let response;
    let image = '';
    if (values?.image?.length > 0 && !bannerImages?.[0]?.url) {
      const { name } = values?.image?.[0];
      const ext = name?.substring(name?.lastIndexOf('.') + 1);
      const timestamp = Date?.now();
      const filename = name?.split('.')?.slice(0, -1)?.join('.');
      const newFilename = `${timestamp}_${filename}.${ext}`;
      const fileKey = `facilitates/${uuid}/${newFilename}`;

      const res = await getBannerImageSignedPutUrl({
        variables: {
          data: {
            fileName: fileKey,
            imageUuid: uuid,
          },
        },
      });
      if (res?.data) {
        try {
          try {
            await fileUpload(
              res?.data?.getBannerImageSignedPutUrl?.signedUrl,
              bannerImages?.[0]?.originFileObj,
            );
            image = res?.data?.getBannerImageSignedPutUrl?.fileName;
          } catch (error) {
            throw new Error(
              `${bannerImages?.name} upload failed. Please try again.`,
            );
          }
        } catch (error) {
          setIsBtnLoading(false);
          openNotification('error', error.message);
        }
      }
    }
    try {
      if (!banner) {
        response = await createBanner({
          variables: {
            data: {
              name: values?.name,
              imageUuid: uuid,
              image: bannerImages?.[0]?.url ? undefined : image,
            },
          },
        });
      } else {
        response = await updateBanner({
          variables: {
            where: {
              id: banner?.id,
            },
            data: {
              name: values?.name,
              imageUuid: banner?.imageUuid,
              image: bannerImages?.[0]?.url ? undefined : image,
            },
          },
        });
      }
    } catch (error) {
      setIsBtnLoading(false);
      return error;
    }
    if (response?.data) {
      setIsBannerModalOpen(false);
      setIsBtnLoading(true);
      setBanner();
      form?.resetFields();
      bannerAdmin({
        variables: {
          filter: {
            ...initialFilter,
            skip:
              (paginationProp?.current - 1) * (paginationProp?.pageSize || 0),
            limit: paginationProp?.pageSize || LIMIT,
            search: bannerSearchTerm,
          },
          sort: sortedInfo?.column
            ? {
              field: sortedInfo?.field,
              order: sortedInfo?.order === 'ascend' ? SORT?.ASC : SORT?.DESC,
            }
            : initialSort,
        },
      });
    }
    setIsBtnLoading(false);
  };

  const columns = [
    {
      title: 'NAME',
      dataIndex: 'name',
      key: 'name',
      ellipsis: true,
      width: 250,
      align: 'left',
      // eslint-disable-next-line no-undef
      fixed: window.innerWidth > BREAKPOINTS.desktop ? 'left' : false,
      className: 'max-width-column',
      render: (_, record) => record?.name,
    },
    {
      title: 'IMAGE',
      dataIndex: 'image',
      key: 'image',
      ellipsis: true,
      width: 100,
      align: 'left',
      className: 'max-width-column',
      render: (_, record) => (
        <span>
          {record?.image ? (
            <>
              <Image
                preview={{
                  visible: false,
                }}
                width={20}
                height={20}
                src={record?.image}
                onClick={(e) => {
                  e?.stopPropagation();
                  setVisible(true);
                  setBannerPreviewImage(record?.image);
                }}
                className="pointer"
              />
            </>
          ) : (
            <span>No Image</span>
          )}
        </span>
      ),
    },
    {
      title: 'CREATED AT',
      dataIndex: 'createdAt',
      key: 'createdAt',
      width: 150,
      ellipsis: true,
      align: 'left',
      className: 'max-width-column',
      render: (_, record) =>
        formatDate(record?.createdAt, DATE_FORMATS?.REGULAR) || '-',
    },
    {
      title: 'ACTIONS',
      dataIndex: 'actions',
      key: 'actions',
      ellipsis: true,
      width: 150,
      // eslint-disable-next-line no-undef
      fixed: window.innerWidth > BREAKPOINTS.desktop ? 'right' : false,
      render: (_, record) => (
        <>
          <div className="action-button">
            {isArchiveFilter ? (
              <Tooltip title="Unarchive" placement="top" zIndex={4}>
                <UnarchiveIcon
                  onClick={() => {
                    setIsArchivePrompts(true);
                    setBanner(record);
                  }}
                  className="mr-16 pointer svg-icon"
                />
              </Tooltip>
            ) : (
              <>
                <Tooltip title="Edit" placement="top" zIndex={4}>
                  <EditIcon
                    onClick={() => {
                      setIsBannerModalOpen(true);
                      setBanner(record);
                      setBannerImages(
                        record?.image ? [{ url: record?.image }] : [],
                      );
                    }}
                    className="mr-16 pointer svg-icon"
                  />
                </Tooltip>
                {/* <Tooltip title="Archive" placement="top" zIndex={4}>
                  <ArchiveIcon
                    onClick={() => {
                      setIsArchivePrompts(true);
                      setBanner(record);
                    }}
                    className="mr-16 pointer svg-icon"
                  />
                </Tooltip> */}
              </>
            )}
          </div>
        </>
      ),
    },
  ]?.filter((item) => {
    if (item?.dataIndex === 'actions' && !hasUpdatePermission) {
      return item?.hidden;
    }
    return !item?.hidden;
  });

  const locale = {
    emptyText: isEmptyBannerList ? '' : <span />,
  };

  return (
    <>
      <Modal
        title="Caution"
        okText="Yes"
        cancelText="No"
        open={isArchivePrompts}
        onOk={() => handleArchive(banner?.id, banner?.isArchived)}
        onCancel={() => {
          setIsArchivePrompts(false);
          setBanner();
        }}
        okButtonProps={{ loading: isArchiveLoading }}
      >
        Are you sure you want to
        <strong>
          {' '}
          {!banner?.isArchived
            ? ARCHIVING_STATE?.ARCHIVED
            : ARCHIVING_STATE?.UNARCHIVED}{' '}
        </strong>
        this banner?
      </Modal>
      <CommonPreview
        visible={visible}
        setVisible={setVisible}
        image={bannerPreviewImage}
        setImage={setBannerPreviewImage}
      />
      <BannerModal
        form={form}
        onFinish={handleBanner}
        loadings={isBtnLoading}
        isModalOpen={isBannerModalOpen}
        setIsModalOpen={setIsBannerModalOpen}
        banner={banner}
        setBanner={setBanner}
        bannerImages={bannerImages}
        setBannerImages={setBannerImages}
        setVisible={setVisible}
        setBannerPreviewImage={setBannerPreviewImage}
      />
      <div className="d-flex justify-end mb-16 flex-wrap">
        {/* {hasUpdatePermission && (
          <div className="mr-16 d-flex align-center">
            <h4 className="m-0 mr-8">Only Archived</h4>{' '}
            <Switch
              size="small"
              onChange={(checked) => setIsArchiveFilter(checked)}
            />
          </div>
        )} */}
        <SearchComponent
          className="search-box"
          query={query}
          setQuery={setQuery}
          getData={handleSearch}
        />
      </div>
      <CommonTable
        locale={locale}
        columns={columns}
        data={bannerList || []}
        loading={isBannerLoading}
        onChange={handleTableChange}
        paginationConfig={paginationProp}
        rowKey={(record) => record?.id}
      />
    </>
  );
};

export default BannerTable;
