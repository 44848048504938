import { Badge, Divider } from 'antd';

import React from 'react';
import {
  DATE_FORMATS,
  FULFILLMENT_STATUS,
  ORDER_STATUS,
} from '../../../../../common/constants';
import { formatDate } from '../../../../../common/utils';
import CommonTable from '../../../../../components/CommonTable';
import ModalComponent from '../../../../../components/ModalComponent';

const ItemStatusModal = ({
  title,
  isModalOpen,
  setIsModalOpen,
  statusHistories,
  isOrderAction,
}) => {
  const columns = [
    {
      title: 'PREVIOUS STATUS',
      dataIndex: 'oldStatus',
      key: 'oldStatus',
      ellipsis: true,
      align: 'left',
      className: 'max-width-column',
      render: (_, record) => {
        const isOldStatusOrder = record?.oldStatus ? (
          <Badge
            color={ORDER_STATUS?.[record?.oldStatus]?.color}
            text={ORDER_STATUS?.[record?.oldStatus]?.text}
          />
        ) : (
          '-'
        );

        const isOldStatusOrderItem =
          record?.oldStatus === FULFILLMENT_STATUS?.FULFILLED?.value ||
          record?.oldStatus === FULFILLMENT_STATUS?.UNFULFILLED?.value ||
          record?.oldStatus === FULFILLMENT_STATUS?.CANCELLED?.value ? (
            <Badge
              color={FULFILLMENT_STATUS?.[record?.oldStatus]?.color}
              text={FULFILLMENT_STATUS?.[record?.oldStatus]?.text}
            />
          ) : (
            '-'
          );

        return isOrderAction ? isOldStatusOrder : isOldStatusOrderItem;
      },
    },
    {
      title: 'UPDATED STATUS',
      dataIndex: 'newStatus',
      key: 'newStatus',
      ellipsis: true,
      align: 'left',
      className: 'max-width-column',
      render: (_, record) => {
        const isNewStatusOrder = record?.newStatus ? (
          <Badge
            color={ORDER_STATUS?.[record?.newStatus]?.color}
            text={ORDER_STATUS?.[record?.newStatus]?.text}
          />
        ) : (
          '-'
        );

        const isNewStatusOrderItem =
          record?.newStatus === FULFILLMENT_STATUS?.FULFILLED?.value ||
          record?.newStatus === FULFILLMENT_STATUS?.UNFULFILLED?.value ||
          record?.newStatus === FULFILLMENT_STATUS?.CANCELLED?.value ? (
            <Badge
              color={FULFILLMENT_STATUS?.[record?.newStatus]?.color}
              text={FULFILLMENT_STATUS?.[record?.newStatus]?.text}
            />
          ) : (
            '-'
          );

        return isOrderAction ? isNewStatusOrder : isNewStatusOrderItem;
      },
    },
    {
      title: 'UPDATED AT',
      dataIndex: 'updatedAt',
      key: 'updatedAt',
      ellipsis: true,
      align: 'left',
      className: 'max-width-column',
      render: (_, record) =>
        formatDate(record?.createdAt, DATE_FORMATS?.WITH_TIME) || '-',
    },
  ];

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  return (
    <ModalComponent
      open={isModalOpen}
      onCancel={handleCancel}
      footer={null}
      title={title}
      width={600}
    >
      <Divider />
      <div className="mt-24">
        <CommonTable
          columns={columns}
          data={statusHistories}
          scroll={{ y: 350 }}
          rowKey={(record) => record?.id}
        />
      </div>
    </ModalComponent>
  );
};

export default ItemStatusModal;
