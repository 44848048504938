import { useLazyQuery, useMutation } from '@apollo/client';
import { Form, Modal, Switch, Tooltip } from 'antd';
import React, { useContext, useEffect, useState } from 'react';
import { AppContext } from '../../../AppContext';
import { ReactComponent as ArchiveIcon } from '../../../assets/svg/box-archive-solid.svg';
import { ReactComponent as EditIcon } from '../../../assets/svg/pen-to-square-solid.svg';
import { ReactComponent as UnarchiveIcon } from '../../../assets/svg/right-from-bracket-solid.svg';
import {
  ARCHIVING_STATE,
  BREAKPOINTS,
  CONFIG_DATA_TYPES,
  CONFIG_TYPES,
  DATE_FORMATS,
  LIMIT,
  PERMISSIONS_KEY,
  PERMISSION_TYPE,
  SORT,
} from '../../../common/constants';
import { formatDate, hasPermission } from '../../../common/utils';
import CommonTable from '../../../components/CommonTable';
import SearchComponent from '../../../components/SearchComponent';
import { UPDATE_CONFIG } from '../graphql/Mutations';
import { CONFIGS } from '../graphql/Queries';
import ConfigModal from './ConfigModal';

const initialPaginationValue = {
  total: 0,
  current: 1,
  pageSize: 10,
};

const initialFilter = {
  limit: LIMIT,
  search: '',
  skip: 0,
};

const initialSort = {
  field: 'createdAt',
  order: 'DESC',
};

const ConfigTable = ({ isSync }) => {
  const {
    state: { permissions },
  } = useContext(AppContext);
  const [form] = Form.useForm();
  const [configList, setConfigList] = useState([]);
  const [configSearchTerm, setConfigSearchTerm] = useState('');
  const [isEmptyConfigList, setIsEmptyConfigList] = useState(false);
  const [config, setConfig] = useState({});
  const [query, setQuery] = useState('');
  const [sortedInfo, setSortedInfo] = useState({});
  const [paginationProp, setPaginationProp] = useState(initialPaginationValue);
  const [isArchivePrompts, setIsArchivePrompts] = useState(false);
  const [isConfigModalOpen, setIsConfigModalOpen] = useState(false);
  const [isConfigBtnLoading, setIsConfigBtnLoading] = useState(false);

  const [isConfigLoading, setIsConfigLoading] = useState(true);
  const [isArchiveLoading, setIsArchiveLoading] = useState(false);

  const [isArchiveFilter, setIsArchiveFilter] = useState(false);

  const [updateConfig] = useMutation(UPDATE_CONFIG, {
    onError: () => {},
  });

  const [configs] = useLazyQuery(CONFIGS, {
    onCompleted: (response) => {
      setConfigList([...response?.configs?.data]);
      if (
        response?.configs?.count === 0 &&
        initialPaginationValue?.total === 0
      ) {
        setIsEmptyConfigList(true);
      } else {
        setIsEmptyConfigList(false);
      }
      const pagination = {
        ...paginationProp,
        defaultPageSize: LIMIT,
        total: response?.configs?.count,
      };
      setPaginationProp(pagination);
      setIsConfigLoading(false);
    },
    fetchPolicy: 'network-only',
    onError() {},
  });

  const hasUpdatePermission = hasPermission(
    PERMISSIONS_KEY?.CONFIGS,
    PERMISSION_TYPE?.UPDATE,
    permissions,
  );

  useEffect(() => {
    setPaginationProp({ ...paginationProp, current: 1, skip: 0 });
    setIsConfigLoading(true);
    configs({
      variables: {
        filter: {
          ...initialFilter,
          limit: paginationProp?.pageSize || LIMIT,
          search: configSearchTerm,
          isArchived: isArchiveFilter,
        },
        sort: sortedInfo?.column
          ? {
              field: sortedInfo?.field,
              order: sortedInfo?.order === 'ascend' ? SORT?.ASC : SORT?.DESC,
            }
          : initialSort,
      },
    });
  }, [isArchiveFilter, isSync]);

  const handleTableChange = (pagination, tableFilter, sorter) => {
    const { current } = pagination;
    const skip = (current - 1) * (pagination?.pageSize || 0);
    setPaginationProp({ ...paginationProp, ...pagination });
    setIsConfigLoading(true);
    setSortedInfo(sorter);
    configs({
      variables: {
        filter: {
          ...initialFilter,
          skip,
          limit: pagination?.pageSize,
          search: configSearchTerm,
          isArchived: isArchiveFilter,
        },
        sort: sorter?.column
          ? {
              field: sorter?.field,
              order: sorter?.order === 'ascend' ? SORT?.ASC : SORT?.DESC,
            }
          : initialSort,
      },
    });
  };

  const handleSearch = (value) => {
    const trimValue = value?.trim();
    setConfigSearchTerm(trimValue);
    setPaginationProp({ ...paginationProp, current: 1, skip: 0 });
    setIsConfigLoading(true);
    configs({
      variables: {
        filter: {
          ...initialFilter,
          limit: paginationProp?.pageSize || LIMIT,
          search: trimValue,
          isArchived: isArchiveFilter,
        },
        sort: sortedInfo?.column
          ? {
              field: sortedInfo?.field,
              order: sortedInfo?.order === 'ascend' ? SORT?.ASC : SORT?.DESC,
            }
          : initialSort,
      },
    });
  };

  const handleArchive = async (key, isArchived) => {
    setIsArchiveLoading(true);
    const response = await updateConfig({
      variables: {
        where: {
          key,
        },
        data: {
          isArchived: !isArchived,
        },
      },
    });
    if (response?.data) {
      setIsArchiveLoading(false);
      setIsConfigLoading(true);
      setIsArchivePrompts(false);
      configs({
        variables: {
          filter: {
            ...initialFilter,
            skip:
              (paginationProp?.current - 1) * (paginationProp?.pageSize || 0),
            limit: paginationProp?.pageSize || LIMIT,
            search: configSearchTerm,
            isArchived: isArchiveFilter,
          },
          sort: {
            ...initialSort,
            field: sortedInfo?.field || 'createdAt',
            order: sortedInfo?.order === 'ascend' ? SORT?.ASC : SORT?.DESC,
          },
        },
      });
    }
    setIsArchiveLoading(false);
  };

  const handleConfig = async (values) => {
    setIsConfigBtnLoading(true);
    const response = await updateConfig({
      variables: {
        where: {
          key: config?.key,
        },
        data: {
          dataType: values?.dataType,
          type: values?.type,
          value: values?.value,
          description: values?.description?.trim(),
        },
      },
    });
    if (response?.data) {
      setIsConfigModalOpen(false);
      setIsConfigLoading(true);
      setConfig({});
      configs({
        variables: {
          filter: {
            ...initialFilter,
            skip:
              (paginationProp?.current - 1) * (paginationProp?.pageSize || 0),
            limit: paginationProp?.pageSize || LIMIT,
            search: configSearchTerm,
            isArchived: isArchiveFilter,
          },
          sort: {
            ...initialSort,
            field: sortedInfo?.field || 'createdAt',
            order: sortedInfo?.order === 'ascend' ? SORT?.ASC : SORT?.DESC,
          },
        },
      });
    }
    setIsConfigBtnLoading(false);
  };

  const columns = [
    {
      title: 'KEY',
      dataIndex: 'key',
      key: 'key',
      ellipsis: true,
      width: 250,
      align: 'left',
      // eslint-disable-next-line no-undef
      fixed: window.innerWidth > BREAKPOINTS.desktop ? 'left' : false,
      className: 'max-width-column',
      render: (_, record) => record?.key || '-',
    },
    {
      title: 'VALUE',
      dataIndex: 'value',
      key: 'value',
      ellipsis: true,
      width: 250,
      align: 'left',
      className: 'max-width-column',
      render: (_, record) => record?.value || '-',
    },
    {
      title: 'TYPE',
      dataIndex: 'type',
      key: 'type',
      ellipsis: true,
      width: 250,
      align: 'left',
      className: `max-width-column`,
      render: (_, record) => CONFIG_TYPES?.[record?.type]?.label || '-',
    },
    {
      title: 'DATA TYPE',
      dataIndex: 'dataType',
      key: 'dataType',
      ellipsis: true,
      width: 150,
      align: 'left',
      className: 'max-width-column',
      render: (_, record) =>
        CONFIG_DATA_TYPES?.[record?.dataType]?.label || '-',
    },
    {
      title: 'DESCRIPTION',
      dataIndex: 'description',
      key: 'description',
      ellipsis: true,
      width: 150,
      align: 'left',
      className: 'max-width-column table-tooltip-cell',
      render: (_, record) => (
        <Tooltip
          title={record?.description ? record?.description : 'No Description'}
          placement="topLeft"
          trigger="click"
          className="pointer"
        >
          {record?.description || '-'}
        </Tooltip>
      ),
    },
    {
      title: 'CREATED AT',
      dataIndex: 'createdAt',
      key: 'createdAt',
      width: 150,
      ellipsis: true,
      align: 'left',
      className: 'max-width-column',
      render: (_, record) =>
        formatDate(record?.createdAt, DATE_FORMATS?.REGULAR) || '-',
    },
    {
      title: 'ACTIONS',
      dataIndex: 'actions',
      key: 'actions',
      ellipsis: true,
      width: 150,
      // eslint-disable-next-line no-undef
      fixed: window.innerWidth > BREAKPOINTS.desktop ? 'right' : false,
      render: (_, record) => (
        <>
          <div className="action-button">
            {isArchiveFilter ? (
              <Tooltip title="Unarchive" placement="top" zIndex={4}>
                <UnarchiveIcon
                  onClick={() => {
                    setIsArchivePrompts(true);
                    setConfig(record);
                  }}
                  className="mr-16 pointer svg-icon"
                />
              </Tooltip>
            ) : (
              <>
                <Tooltip title="Edit" placement="top" zIndex={4}>
                  <EditIcon
                    onClick={() => {
                      setConfig(record);
                      setIsConfigModalOpen(true);
                    }}
                    className="mr-16 pointer svg-icon"
                  />
                </Tooltip>
                <Tooltip title="Archive" placement="top" zIndex={4}>
                  <ArchiveIcon
                    onClick={() => {
                      setIsArchivePrompts(true);
                      setConfig(record);
                    }}
                    className="mr-16 pointer svg-icon"
                  />
                </Tooltip>
              </>
            )}
          </div>
        </>
      ),
    },
  ]?.filter((item) => {
    if (item?.dataIndex === 'actions' && !hasUpdatePermission) {
      return item?.hidden;
    }
    return !item?.hidden;
  });

  const locale = {
    emptyText: isEmptyConfigList ? '' : <span />,
  };

  return (
    <>
      <Modal
        title="Caution"
        okText="Yes"
        cancelText="No"
        open={isArchivePrompts}
        onOk={() => handleArchive(config?.key, config?.isArchived)}
        onCancel={() => setIsArchivePrompts(false)}
        okButtonProps={{ loading: isArchiveLoading }}
      >
        Are you sure you want to
        <strong>
          {' '}
          {!config?.isArchived
            ? ARCHIVING_STATE?.ARCHIVED
            : ARCHIVING_STATE?.UNARCHIVED}{' '}
        </strong>
        this config?
      </Modal>
      <ConfigModal
        form={form}
        onFinish={handleConfig}
        loadings={isConfigBtnLoading}
        isModalOpen={isConfigModalOpen}
        setIsModalOpen={setIsConfigModalOpen}
        config={config}
        setConfig={setConfig}
      />
      <div className="d-flex justify-end mb-16 flex-wrap">
        {hasUpdatePermission && (
          <div className="mr-16 d-flex align-center">
            <h4 className="m-0 mr-8">Only Archived</h4>{' '}
            <Switch
              size="small"
              onChange={(checked) => setIsArchiveFilter(checked)}
            />
          </div>
        )}
        <SearchComponent
          className="search-box"
          query={query}
          setQuery={setQuery}
          getData={handleSearch}
        />
      </div>
      <CommonTable
        locale={locale}
        columns={columns}
        data={configList || []}
        loading={isConfigLoading}
        onChange={handleTableChange}
        paginationConfig={paginationProp}
        rowKey={(record) => record?.id}
      />
    </>
  );
};

export default ConfigTable;
