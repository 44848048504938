import { Button, Result } from 'antd';
import React, { useContext } from 'react';
import { useHistory } from 'react-router-dom';
import { AppContext } from './AppContext';
import { PERMISSION_TYPE, ROUTES, USER_ROLES } from './common/constants';
import { hasPermission } from './common/utils';

const AccessControl = ({
  children,
  permissionKey,
  permissionType = PERMISSION_TYPE?.READ,
}) => {
  const {
    state: { permissions, currentUser },
  } = useContext(AppContext);
  const history = useHistory();

  const hasAccess = hasPermission(permissionKey, permissionType, permissions);
  if (!hasAccess && !currentUser?.roles?.includes(USER_ROLES?.LIBRARIAN?.key)) {
    return (
      <Result
        status="403"
        title="403"
        subTitle="Sorry, you are not authorized to access this page."
        extra={
          <Button type="primary" onClick={() => history?.push(ROUTES?.MAIN)}>
            Back Home
          </Button>
        }
      />
    );
  }

  return <div>{children}</div>;
};

export default AccessControl;
