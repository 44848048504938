import React from 'react';
import { Route, Switch } from 'react-router-dom';
import AccessControl from '../../../AccessControl';
import { PERMISSIONS_KEY, ROUTES } from '../../../common/constants';
import Error404 from '../../../Error404';
import Collections from './pages/Collections';
import ViewCollectionToys from './pages/ViewCollectionToys';

const CollectionsWrapper = () => (
  <Switch>
    <Route
      exact
      path={`${ROUTES?.APP_CONTENT}${ROUTES?.COLLECTION}`}
      render={(props) => (
        <AccessControl permissionKey={PERMISSIONS_KEY?.APP_CONTENT_COLLECTION}>
          <Collections {...props} />
        </AccessControl>
      )}
    />
    <Route
      exact
      path={`${ROUTES?.APP_CONTENT}${ROUTES?.COLLECTION}/view/:id`}
      render={(props) => (
        <AccessControl permissionKey={PERMISSIONS_KEY?.APP_CONTENT_COLLECTION}>
          <ViewCollectionToys {...props} />
        </AccessControl>
      )}
    />
    <Route path="*" exact component={Error404} />
  </Switch>
);

export default CollectionsWrapper;
