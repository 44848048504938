import { FilterFilled, FilterOutlined } from '@ant-design/icons';
import { useLazyQuery, useMutation } from '@apollo/client';
import {
  Button,
  Checkbox,
  DatePicker,
  Divider,
  Empty,
  Form,
  Modal,
  Tag,
  Tooltip,
} from 'antd';
import { debounce, filter, forEach, isEmpty, map } from 'lodash';
import React, { useContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { AppContext } from '../../../../../AppContext';
import { ReactComponent as CommentIcon } from '../../../../../assets/svg/comment-dots-solid.svg';
import {
  BREAKPOINTS,
  COMMENT_ORDER_PHASE,
  COMMENT_TARGET_TYPE,
  DATE_FORMATS,
  LIMIT,
  PERMISSIONS_KEY,
  PERMISSION_TYPE,
  ROUTES,
  SORT,
} from '../../../../../common/constants';
import { formatDate, hasPermission } from '../../../../../common/utils';
import CommonTable from '../../../../../components/CommonTable';
import LoaderComponent from '../../../../../components/LoaderComponent';
import SearchComponent from '../../../../../components/SearchComponent';
import { CREATE_COMMENT } from '../../../../orders/modules/all/graphql/Mutations';
import NoteModal from '../../../../requests/modules/endUser/components/NoteModal';
import { LIBRARIES } from '../../../../requests/modules/library/graphql/Queries';
import { CREATE_RETURN_ORDER_ADMIN } from '../graphql/Mutations';
import { HOLDING_PERIOD, ORDERS_SELECT } from '../graphql/Queries';

const { RangePicker } = DatePicker;

const initialPaginationValue = {
  total: 0,
  current: 1,
  pageSize: 10,
};

const initialOrderFilter = {
  limit: 10,
  search: '',
  skip: 0,
};

const initialOrderSort = {
  field: 'createdAt',
  order: 'DESC',
};

const initialTableFilter = {
  skip: 0,
  limit: LIMIT,
  search: '',
};

const initialTableSort = {
  field: 'createdAt',
  order: 'DESC',
};

let filterScrollDebounceJob = null;

const HoldingTable = ({ exportFilter, setExportFilter }) => {
  const {
    state: {
      permissions,
      commonPermissions: {
        isUserPermission,
        isLibraryPermission,
        isToyPermission,
      },
    },
  } = useContext(AppContext);
  const history = useHistory();
  const [form] = Form.useForm();
  const [orderList, setOrderList] = useState([]);
  const [orderSearchTerm, setOrderSearchTerm] = useState('');
  const [isEmptyOrderList, setIsEmptyOrderList] = useState(false);
  const [order, setOrder] = useState({});
  const [query, setQuery] = useState('');
  const [sortedInfo, setSortedInfo] = useState({});
  const [paginationProp, setPaginationProp] = useState(initialPaginationValue);

  const [isCommentLoading, setIsCommentLoading] = useState(false);
  const [isNotePrompts, setIsNotePrompts] = useState(false);
  const [isRequestPrompts, setIsRequestPrompts] = useState(false);
  const [isRequestLoadings, setIsRequestLoadings] = useState(false);

  const [isOrderLoading, setIsSuggestedToyLoading] = useState(true);
  const [filterSearch, setFilterSearch] = useState('');
  const [filterList, setFilterList] = useState([]);
  const [whereFilter, setWhereFilter] = useState();
  const [filterLoading, setFilterLoading] = useState(false);
  const [filterTags, setFilterTags] = useState(null);
  const [filterVisible, setFilterVisible] = useState(false);
  const [filterIndex, setFilterIndex] = useState(null);
  const [finalWhereFilters, setFinalWhereFilters] = useState();
  const [finalFilterTags, setFinalFilterTags] = useState(null);
  const [filterIsEnd, setFilterIsEnd] = useState(false);
  const [isEmptyFilterList, setIsEmptyFilterList] = useState(false);

  const [holdingPeriod] = useLazyQuery(HOLDING_PERIOD, {
    onCompleted: (response) => {
      setOrderList([...response?.holdingPeriod?.data]);
      if (
        response?.holdingPeriod?.count === 0 &&
        initialPaginationValue?.total === 0
      ) {
        setIsEmptyOrderList(true);
      } else {
        setIsEmptyOrderList(false);
      }
      const pagination = {
        ...paginationProp,
        defaultPageSize: LIMIT,
        total: response?.holdingPeriod?.count,
      };
      setPaginationProp(pagination);
      setIsSuggestedToyLoading(false);
    },
    fetchPolicy: 'network-only',
    onError() {},
  });

  const [libraries, { loading: isLibrariesLoading }] = useLazyQuery(LIBRARIES, {
    onCompleted: (response) => {
      if (response?.libraries?.data?.length < LIMIT) {
        setFilterIsEnd(true);
      }
      if (response?.libraries?.count === 0) {
        setIsEmptyFilterList(true);
      } else {
        setIsEmptyFilterList(false);
      }
      const optionsCopy = [];
      forEach(response?.libraries?.data, (item) =>
        optionsCopy?.push({ label: item?.name, key: item?.id }),
      );
      setFilterList([...filterList, ...optionsCopy]);
      setFilterLoading(false);
    },
    fetchPolicy: 'network-only',
    onError() {
      setFilterLoading(false);
    },
  });

  const [orderSelect, { loading: isOrderSelectLoading }] = useLazyQuery(
    ORDERS_SELECT,
    {
      onCompleted: (response) => {
        if (response?.holdingPeriod?.data?.length < LIMIT) {
          setFilterIsEnd(true);
        }
        if (response?.holdingPeriod?.count === 0) {
          setIsEmptyFilterList(true);
        } else {
          setIsEmptyFilterList(false);
        }
        const optionsCopy = [];
        forEach(response?.holdingPeriod?.data, (item) =>
          optionsCopy?.push({
            label: item?.orderNumber,
            key: item?.orderId,
          }),
        );
        setFilterList([...filterList, ...optionsCopy]);
        setFilterLoading(false);
      },
      fetchPolicy: 'network-only',
      onError() {
        setFilterLoading(false);
      },
    },
  );

  const [createComment] = useMutation(CREATE_COMMENT, {
    onError: () => {},
  });

  const [createReturnOrderAdmin] = useMutation(CREATE_RETURN_ORDER_ADMIN, {
    onError: () => {},
  });

  const hasUpdatePermission = hasPermission(
    PERMISSIONS_KEY?.REPORTS_HOLDING_PERIOD,
    PERMISSION_TYPE?.UPDATE,
    permissions,
  );

  useEffect(() => {
    setPaginationProp({ ...paginationProp, current: 1, skip: 0 });
    setIsSuggestedToyLoading(true);
    holdingPeriod({
      variables: {
        filter: {
          ...initialOrderFilter,
        },
        sort: initialOrderSort,
      },
    });
  }, []);

  const handleTableChange = (pagination, tableFilter, sorter) => {
    const { current } = pagination;
    const skip = (current - 1) * (pagination?.pageSize || 0);
    setPaginationProp({ ...paginationProp, ...pagination });
    setIsSuggestedToyLoading(true);
    setSortedInfo(sorter);
    holdingPeriod({
      variables: {
        filter: {
          ...initialOrderFilter,
          skip,
          limit: pagination?.pageSize,
          search: orderSearchTerm,
          libraryIds: finalWhereFilters?.name,
          orderIds: finalWhereFilters?.orderNumber,
          deliveredRange: {
            from: finalWhereFilters?.deliveredOn?.from?.$d,
            to: finalWhereFilters?.deliveredOn?.to?.$d,
          },
          returnRange: {
            from: finalWhereFilters?.returnDate?.from?.$d,
            to: finalWhereFilters?.returnDate?.to?.$d,
          },
        },
        sort: sorter?.column
          ? {
              field: sorter?.field,
              order: sorter?.order === 'ascend' ? SORT?.ASC : SORT?.DESC,
            }
          : initialOrderSort,
      },
    });
  };

  const handleSearch = (value) => {
    const trimValue = value?.trim();
    setOrderSearchTerm(trimValue);
    setPaginationProp({ ...paginationProp, current: 1, skip: 0 });
    setIsSuggestedToyLoading(true);
    holdingPeriod({
      variables: {
        filter: {
          ...initialOrderFilter,
          limit: paginationProp?.pageSize || LIMIT,
          search: trimValue,
          deliveredRange: {
            from: finalWhereFilters?.deliveredOn?.from?.$d,
            to: finalWhereFilters?.deliveredOn?.to?.$d,
          },
          libraryIds: finalWhereFilters?.name,
          orderIds: finalWhereFilters?.orderNumber,
          returnRange: {
            from: finalWhereFilters?.returnDate?.from?.$d,
            to: finalWhereFilters?.returnDate?.to?.$d,
          },
        },
        sort: sortedInfo?.column
          ? {
              field: sortedInfo?.field,
              order: sortedInfo?.order === 'ascend' ? SORT?.ASC : SORT?.DESC,
            }
          : initialOrderSort,
      },
    });
    setExportFilter({ ...exportFilter, search: trimValue });
  };

  const handleComment = async (values) => {
    setIsCommentLoading(true);
    const response = await createComment({
      variables: {
        data: {
          targetType: COMMENT_TARGET_TYPE?.ORDER_ITEM,
          targetId: order?.id,
          metaData: {
            orderPhase: COMMENT_ORDER_PHASE?.HOLDING_PERIOD,
          },
          isResolved: false,
          comment: values?.note?.trim(),
        },
      },
    });
    if (response?.data) {
      setIsSuggestedToyLoading(true);
      setIsNotePrompts(false);
      form?.resetFields();
      holdingPeriod({
        variables: {
          filter: {
            ...initialOrderFilter,
            skip:
              (paginationProp?.current - 1) * (paginationProp?.pageSize || 0),
            limit: paginationProp?.pageSize || LIMIT,
            search: orderSearchTerm,
            deliveredRange: {
              from: finalWhereFilters?.deliveredOn?.from?.$d,
              to: finalWhereFilters?.deliveredOn?.to?.$d,
            },
            libraryIds: finalWhereFilters?.name,
            orderIds: finalWhereFilters?.orderNumber,
            returnRange: {
              from: finalWhereFilters?.returnDate?.from?.$d,
              to: finalWhereFilters?.returnDate?.to?.$d,
            },
          },
          sort: sortedInfo?.column
            ? {
                field: sortedInfo?.field,
                order: sortedInfo?.order === 'ascend' ? SORT?.ASC : SORT?.DESC,
              }
            : initialOrderSort,
        },
      });
    }
    setIsCommentLoading(false);
  };

  const handleReturnRequest = async () => {
    setIsRequestLoadings(true);
    const response = await createReturnOrderAdmin({
      variables: {
        where: {
          id: order?.id,
        },
      },
    });
    if (response?.data) {
      setIsSuggestedToyLoading(true);
      setIsRequestPrompts(false);
      form?.resetFields();
      holdingPeriod({
        variables: {
          filter: {
            ...initialOrderFilter,
            skip:
              (paginationProp?.current - 1) * (paginationProp?.pageSize || 0),
            limit: paginationProp?.pageSize || LIMIT,
            search: orderSearchTerm,
            deliveredRange: {
              from: finalWhereFilters?.deliveredOn?.from?.$d,
              to: finalWhereFilters?.deliveredOn?.to?.$d,
            },
            libraryIds: finalWhereFilters?.name,
            orderIds: finalWhereFilters?.orderNumber,
            returnRange: {
              from: finalWhereFilters?.returnDate?.from?.$d,
              to: finalWhereFilters?.returnDate?.to?.$d,
            },
          },
          sort: sortedInfo?.column
            ? {
                field: sortedInfo?.field,
                order: sortedInfo?.order === 'ascend' ? SORT?.ASC : SORT?.DESC,
              }
            : initialOrderSort,
        },
      });
    }
    setIsRequestLoadings(false);
  };

  const getFilterData = (confirm) => {
    setIsSuggestedToyLoading(true);
    holdingPeriod({
      variables: {
        filter: {
          ...initialOrderFilter,
          limit: paginationProp?.pageSize || LIMIT,
          search: orderSearchTerm,
          libraryIds: whereFilter?.name,
          orderIds: whereFilter?.orderNumber,
          deliveredRange: {
            from: whereFilter?.deliveredOn?.from?.$d,
            to: whereFilter?.deliveredOn?.to?.$d,
          },
          returnRange: {
            from: whereFilter?.returnDate?.from?.$d,
            to: whereFilter?.returnDate?.to?.$d,
          },
        },
        sort: sortedInfo?.column
          ? {
              field: sortedInfo?.field,
              order: sortedInfo?.order === 'ascend' ? SORT?.ASC : SORT?.DESC,
            }
          : initialOrderSort,
      },
    });
    setFinalWhereFilters({ ...finalWhereFilters, ...whereFilter });
    setExportFilter({ ...exportFilter, ...finalWhereFilters, ...whereFilter });
    setFinalFilterTags(filterTags);
    setPaginationProp({ ...paginationProp, skip: 0, current: 1 });
    if (confirm) {
      confirm();
    }
  };

  const handleReset = (clearFilters, dataIndex, confirm) => {
    const tagsCopy = {
      ...filterTags,
      [dataIndex]: [],
    };
    const whereFiltersCopy = {
      ...whereFilter,
      [dataIndex]: [],
    };
    setFilterVisible(false);
    setFinalFilterTags({ ...filterTags, ...tagsCopy });
    setFinalWhereFilters({ ...finalWhereFilters, ...whereFiltersCopy });
    setExportFilter({
      ...exportFilter,
      ...finalWhereFilters,
      ...whereFiltersCopy,
    });
    setIsSuggestedToyLoading(true);
    holdingPeriod({
      variables: {
        filter: {
          ...initialOrderFilter,
          limit: paginationProp?.pageSize || LIMIT,
          search: orderSearchTerm,
          deliveredRange: {
            from: whereFiltersCopy?.deliveredOn?.from?.$d,
            to: whereFiltersCopy?.deliveredOn?.to?.$d,
          },
          libraryIds: whereFiltersCopy?.name,
          orderIds: whereFiltersCopy?.orderNumber,
          returnRange: {
            from: whereFiltersCopy?.returnDate?.from?.$d,
            to: whereFiltersCopy?.returnDate?.to?.$d,
          },
        },
        sort: sortedInfo?.column
          ? {
              field: sortedInfo?.field,
              order: sortedInfo?.order === 'ascend' ? SORT?.ASC : SORT?.DESC,
            }
          : initialOrderSort,
      },
    });
    setPaginationProp({ ...paginationProp, skip: 0, current: 1 });
    clearFilters();
    setFilterVisible(false);
    if (confirm) {
      confirm();
    }
  };

  useEffect(() => {
    if (filterVisible) {
      setFilterList([]);
      setFilterLoading(true);
      setIsEmptyFilterList(false);
      setFilterTags({ ...filterTags, ...finalFilterTags });
      setWhereFilter({ ...whereFilter, ...finalWhereFilters });
      setFilterIsEnd(false);

      switch (filterIndex) {
        case 'name':
          libraries({
            variables: {
              filter: initialTableFilter,
              sort: initialTableSort,
            },
          });
          break;

        case 'orderNumber':
          orderSelect({
            variables: {
              filter: initialTableFilter,
              sort: { ...initialTableSort, field: 'orderNumber' },
            },
          });
          break;

        default:
          break;
      }
    }
    if (!filterVisible) {
      setFilterTags(finalFilterTags);
      setWhereFilter(finalWhereFilters);
      setFilterSearch('');
    }
  }, [filterVisible]);

  const onFilterScroll = (event, dataIndex) => {
    if (filterScrollDebounceJob) {
      filterScrollDebounceJob?.cancel();
      filterScrollDebounceJob = null;
    }
    if (!isLibrariesLoading && !isOrderSelectLoading && !filterIsEnd) {
      const target = event?.target;
      const currentLength = filterList?.length;
      if (
        target?.scrollTop + target?.offsetHeight >=
        target?.scrollHeight - 5
      ) {
        filterScrollDebounceJob = debounce(() => {
          setFilterLoading(true);
          switch (dataIndex) {
            case 'name':
              libraries({
                variables: {
                  filter: {
                    limit: LIMIT,
                    skip: currentLength,
                    search: filterSearch,
                  },
                  sort: initialTableSort,
                },
              });
              break;

            case 'orderNumber':
              orderSelect({
                variables: {
                  filter: {
                    limit: LIMIT,
                    skip: currentLength,
                    search: filterSearch,
                  },
                  sort: { ...initialTableSort, field: 'orderNumber' },
                },
              });
              break;

            default:
              break;
          }
        }, 500);
        filterScrollDebounceJob();
      }
    }
  };

  const handleFilterSearch = (value, dataIndex) => {
    setFilterSearch(value);
    setFilterIsEnd(false);
    setFilterLoading(true);
    setFilterList([]);
    switch (dataIndex) {
      case 'name':
        libraries({
          variables: {
            filter: {
              ...initialTableFilter,
              limit: LIMIT,
              search: value,
            },
            sort: initialTableSort,
          },
        });
        break;

      case 'orderNumber':
        orderSelect({
          variables: {
            filter: {
              ...initialTableFilter,
              limit: LIMIT,
              search: value,
            },
            sort: { ...initialTableSort, field: 'orderNumber' },
          },
        });
        break;

      default:
        break;
    }
  };

  const changeFilter = (newFilterObj, dataIndex) => {
    const {
      target: { value },
    } = newFilterObj;

    const filterTagsCopy = { ...filterTags };
    let newTagList;
    if (whereFilter?.[dataIndex]?.includes(value?.key)) {
      newTagList = {
        ...filterTagsCopy,
        [dataIndex]: filter(filterTagsCopy?.[dataIndex], (item) => {
          if (item?.label) {
            return item?.key !== value?.key;
          }
          return item !== value;
        }),
      };
    } else {
      newTagList = {
        ...filterTagsCopy,
        [dataIndex]: filterTagsCopy?.[dataIndex]
          ? [...filterTagsCopy?.[dataIndex], value]
          : [value],
      };
    }

    const updatedWhereFilter = {
      ...whereFilter,
      [dataIndex]: newTagList?.[dataIndex]?.map((item) => item?.key),
    };
    setWhereFilter(updatedWhereFilter);
    setFilterTags(newTagList);
  };

  const handleDeleteFilter = (value, dataIndex) => {
    let newTagList;

    if (whereFilter?.[dataIndex]?.includes(value?.key)) {
      newTagList = {
        ...filterTags,
        [dataIndex]: filter(filterTags?.[dataIndex], (item) => {
          if (item?.key) {
            return item?.key !== value?.key;
          }
          return item !== value;
        }),
      };
    }

    const updatedWhereFilter = {
      ...whereFilter,
      [dataIndex]: newTagList?.[dataIndex]?.map((item) => item?.key),
    };
    setWhereFilter(updatedWhereFilter);
    setFilterTags(newTagList);
  };

  const onRangePickerChange = (values, dataIndex) => {
    const rangeObj = {
      from: values[0]?.startOf('day'),
      to: values?.[1]?.endOf('day'),
    };

    const filtersCopy = {
      ...whereFilter,
      [dataIndex]: rangeObj,
    };
    if (!values?.length) {
      delete whereFilter?.[dataIndex];
    }
    setWhereFilter(filtersCopy);
  };

  const filterPopup = (dataIndex, showSearch = true) => ({
    filterDropdown: ({ confirm, clearFilters }) => {
      if (dataIndex === 'deliveredOn' || dataIndex === 'returnDate') {
        return (
          <div className="custom-filter-dropdown">
            <RangePicker
              allowClear={false}
              format={DATE_FORMATS?.REGULAR}
              onChange={(values) => onRangePickerChange(values, dataIndex)}
              value={
                whereFilter?.[dataIndex]?.from
                  ? [
                      whereFilter?.[dataIndex]?.from,
                      whereFilter?.[dataIndex]?.to,
                    ]
                  : null
              }
            />
            <Divider className="divider-filter" />
            <div className="d-flex justify-center">
              <Button
                size="small"
                className="reset-button"
                id="roles-filter-reset"
                onClick={() => handleReset(clearFilters, dataIndex, confirm)}
              >
                Reset
              </Button>
              <Button
                size="small"
                className="common-button ok-button"
                id="roles-filter-ok"
                type="primary"
                onClick={() => getFilterData(confirm, dataIndex)}
              >
                Ok
              </Button>
            </div>
          </div>
        );
      }

      return (
        <div className="custom-filter-dropdown">
          <LoaderComponent
            spinning={filterLoading}
            setHeight={35}
            size="medium"
          >
            {showSearch && (
              <SearchComponent
                className="filter-search"
                id="search-container-id-roles"
                placeholder="Search..."
                name={dataIndex}
                query={filterSearch}
                setQuery={setFilterSearch}
                getData={(value) => handleFilterSearch(value, dataIndex)}
              />
            )}
            {filterTags?.[dataIndex]?.length > 0 && (
              <div className="filter-tags-wrapper">
                {map(filterTags?.[dataIndex], (item) => (
                  <Tag
                    key={item?.key}
                    closable
                    onClose={() => handleDeleteFilter(item, dataIndex)}
                    className="filter-tag"
                  >
                    <span>{item?.label}</span>
                  </Tag>
                ))}
              </div>
            )}
            <div
              className="filter-checkboxes"
              onScroll={(e) => onFilterScroll(e, dataIndex)}
            >
              {filterList?.length > 0
                ? map(filterList, (item) => (
                    <div className="single-checkbox-div" key={item?.key}>
                      <Checkbox
                        value={item}
                        checked={whereFilter?.[dataIndex]?.includes(item?.key)}
                        key={item?.key || item}
                        onChange={(e) => changeFilter(e, dataIndex)}
                        className="single-checkbox"
                      >
                        <span>{item?.label}</span>
                      </Checkbox>
                    </div>
                  ))
                : isEmptyFilterList && (
                    <Empty image={Empty?.PRESENTED_IMAGE_SIMPLE} />
                  )}
            </div>
          </LoaderComponent>
          <Divider className="divider-filter" />
          <div className="d-flex justify-center">
            <Button
              size="small"
              className="reset-button"
              id="roles-filter-reset"
              onClick={() => handleReset(clearFilters, dataIndex, confirm)}
            >
              Reset
            </Button>
            <Button
              size="small"
              className="common-button ok-button"
              id="roles-filter-ok"
              type="primary"
              onClick={() => getFilterData(confirm, dataIndex)}
            >
              Ok
            </Button>
          </div>
        </div>
      );
    },
    filterIcon: () =>
      finalWhereFilters?.[dataIndex]?.length > 0 ||
      !isEmpty(finalWhereFilters?.[dataIndex]) ? (
        <FilterFilled className="filter-icon filter-filled" />
      ) : (
        <FilterOutlined className="filter-icon" />
      ),
    onFilterDropdownOpenChange: (visible) => {
      setFilterIndex(dataIndex);
      setFilterVisible(visible);
    },
  });

  const columns = [
    {
      title: 'USER NAME',
      dataIndex: 'name',
      key: 'name',
      ellipsis: true,
      width: 250,
      align: 'left',
      className: `max-width-column`,
      // eslint-disable-next-line no-undef
      fixed: window.innerWidth > BREAKPOINTS.desktop ? 'left' : false,
      onCell: (record) => ({
        onClick: () =>
          isUserPermission &&
          record?.userId &&
          history?.push(
            `${ROUTES?.SYSTEM_USERS}${ROUTES?.END_USERS}${ROUTES?.BASIC_DETAILS}/view/${record?.userId}/${record?.userNumber}`,
          ),
        className: `${
          isUserPermission && record?.userId && 'pointer table-cell'
        }`,
      }),
      render: (_, record) => (
        <>
          {record?.firstName || record?.lastName ? (
            <div className="table-data-with-id">{`${
              record?.firstName ? record?.firstName : ''
            } ${record?.lastName ? record?.lastName : ''}`}</div>
          ) : (
            '-'
          )}
          {record?.userNumber ? (
            <div className="font-size-12">({record?.userNumber})</div>
          ) : (
            ''
          )}
        </>
      ),
    },
    {
      title: 'PRODUCT NAME',
      dataIndex: 'productName',
      key: 'productName',
      ellipsis: true,
      width: 250,
      align: 'left',
      className: `max-width-column`,
      onCell: (record) => ({
        onClick: () =>
          isToyPermission &&
          record?.toyId &&
          history?.push(
            `${ROUTES?.MASTER_LISTS}${ROUTES?.TOY}/view/${record?.toyId}`,
          ),
        className: `${
          isToyPermission && record?.toyId && 'pointer table-cell'
        }`,
      }),
      render: (_, record) => (
        <>
          <div className="table-data-with-id">{record?.toyName || '-'}</div>
          {record?.toyNumber && (
            <div className="font-size-12">({record?.toyNumber})</div>
          )}
        </>
      ),
    },
    {
      title: 'ORDER ID',
      dataIndex: 'orderNumber',
      key: 'orderNumber',
      ellipsis: true,
      width: 150,
      align: 'left',
      className: 'max-width-column',
      render: (_, record) => record?.orderNumber,
      ...filterPopup('orderNumber'),
    },
    {
      title: 'LIBRARY NAME',
      dataIndex: 'name',
      key: 'name',
      ellipsis: true,
      width: 250,
      align: 'left',
      className: `max-width-column`,
      onCell: (record) => ({
        onClick: () =>
          isLibraryPermission &&
          record?.libraryId &&
          history?.push(
            `${ROUTES?.MASTER_LISTS}${ROUTES?.LIBRARY}${ROUTES?.BASIC_DETAILS}/view/${record?.libraryId}/${record?.libraryNumber}`,
          ),
        className: `${
          isLibraryPermission && record?.libraryId && 'pointer table-cell'
        }`,
      }),
      render: (_, record) => (
        <>
          <div className="table-data-with-id">{record?.libraryName}</div>
          <div className="font-size-12">({record?.libraryNumber})</div>
        </>
      ),
      ...filterPopup('name'),
    },
    {
      title: 'DELIVERED ON',
      dataIndex: 'deliveredOn',
      key: 'deliveredOn',
      ellipsis: true,
      width: 200,
      align: 'left',
      className: 'max-width-column',
      render: (_, record) =>
        formatDate(record?.deliveredOn, DATE_FORMATS?.REGULAR) || '-',
      ...filterPopup('deliveredOn'),
    },
    {
      title: 'EXPECTED RETURN',
      dataIndex: 'returnDate',
      key: 'returnDate',
      ellipsis: true,
      width: 200,
      align: 'left',
      className: 'max-width-column',
      render: (_, record) =>
        formatDate(record?.returnDate, DATE_FORMATS?.REGULAR) || '-',
      ...filterPopup('returnDate'),
    },
    {
      title: 'DUE SINCE',
      dataIndex: 'dueSince',
      key: 'dueSince',
      ellipsis: true,
      width: 200,
      align: 'left',
      className: 'max-width-column',
      render: (_, record) => `${record?.dueSince} days` || '-',
    },
    {
      title: 'ACTIONS',
      dataIndex: 'actions',
      key: 'actions',
      ellipsis: true,
      width: 100,
      // eslint-disable-next-line no-undef
      fixed: window.innerWidth > BREAKPOINTS.desktop ? 'right' : false,
      render: (_, record) => (
        <>
          <div className="action-button">
            <div className="mr-16 d-flex">
              <Tooltip
                title={record?.comment ? 'View Note' : 'Add Note'}
                placement="top"
                className="table-action-button"
                zIndex={4}
              >
                <Button
                  disabled={!record?.comment && !hasUpdatePermission}
                  type="link"
                  onClick={(e) => {
                    e?.stopPropagation();
                    setIsNotePrompts(true);
                    setOrder(record);
                  }}
                >
                  <CommentIcon
                    className={
                      record?.comment
                        ? 'pointer svg-icon'
                        : 'pointer svg-icon-grey'
                    }
                  />
                </Button>
              </Tooltip>
            </div>
            {hasUpdatePermission && (
              <Button
                type="primary"
                size="small"
                className="table-action-primary-btn"
                onClick={() => {
                  setIsRequestPrompts(true);
                  setOrder(record);
                }}
              >
                Return Request
              </Button>
            )}
          </div>
        </>
      ),
    },
  ];

  const locale = {
    emptyText: isEmptyOrderList ? '' : <span />,
  };

  return (
    <>
      <Modal
        title="Caution"
        okText="Yes"
        cancelText="No"
        open={isRequestPrompts}
        onOk={handleReturnRequest}
        onCancel={() => setIsRequestPrompts(false)}
        okButtonProps={{ loading: isRequestLoadings }}
      >
        Are you sure you want to
        <strong> Generate return request </strong>for this?
      </Modal>
      <NoteModal
        form={form}
        note={order?.comment}
        isModalOpen={isNotePrompts}
        setIsModalOpen={setIsNotePrompts}
        isBtnLoading={isCommentLoading}
        onFinish={handleComment}
      />
      <div className="d-flex justify-end mb-16 flex-wrap">
        <SearchComponent
          className="search-box"
          query={query}
          setQuery={setQuery}
          getData={handleSearch}
        />
      </div>
      <CommonTable
        locale={locale}
        columns={columns}
        data={orderList || []}
        loading={isOrderLoading}
        onChange={handleTableChange}
        paginationConfig={paginationProp}
        rowKey={(record) => record?.id}
      />
    </>
  );
};

export default HoldingTable;
