import { Layout } from 'antd';

import React, { useContext, useEffect, useState } from 'react';

import { useLocation } from "react-router-dom";
import { AppContext } from '../AppContext';

import { BREAKPOINTS, USER_ROLES } from '../common/constants';
import RouterPrompt from '../components/RouterPrompt';
import './app.less';
import AppHeader from './components/header/AppHeader';
import Sidebar from './components/sidebar/Sidebar';
import ContentRoutes from './ContentRoutes';
import LibraryContentRoutes from './LibraryContentRoutes';

const { Content, Sider } = Layout;

const App = () => {
  const {
    getCurrentUser,
    state: { showPrompt, isDesktop },
  } = useContext(AppContext);
  const [isActive, setActive] = useState(false);
  const [collapsed, setCollapsed] = useState(false);
  const { roles } = getCurrentUser() || {};
  const location = useLocation();

  const handleOverlay = () => {
    setActive(!isActive);
  };

  useEffect(() => {
    if (
      // eslint-disable-next-line no-undef
      window.innerWidth > BREAKPOINTS.desktop ||
      // eslint-disable-next-line no-undef
      window.innerWidth < BREAKPOINTS.tablet
    ) {
      setCollapsed(false);
    } else {
      setCollapsed(true);
    }
    // eslint-disable-next-line no-undef
  }, [window.innerWidth]);

  useEffect(() => {
    // eslint-disable-next-line no-undef
    if ('serviceWorker' in navigator) {
      // eslint-disable-next-line no-undef
      navigator.serviceWorker.ready.then((registration) => {
        registration.update();
      });
    }
  }, [location?.pathname]);

  return (
    <Layout hasSider>
      <RouterPrompt openPrompt={showPrompt} />
      {!isDesktop && (
        <span
          className={isActive ? 'active overlay-responsive' : 'overlay-disable'}
          onClick={handleOverlay}
        />
      )}
      <Sider
        theme="light"
        collapsed={isDesktop ? collapsed : false}
        className={isActive ? 'close' : null}
        breakpoint="md"
      >
        <Sidebar
          collapsed={collapsed}
          setCollapsed={setCollapsed}
          isDesktop={isDesktop}
          isActive={isActive}
          setActive={setActive}
        />
      </Sider>
      <Layout className="site-layout">
        <AppHeader isDesktop={isDesktop} handleOverlay={handleOverlay} />
        <Content className="wrapper">
          <div id="header-right-content-phones" />
          {roles?.includes(USER_ROLES?.LIBRARIAN?.key) ? (
            <LibraryContentRoutes />
          ) : (
            <ContentRoutes />
          )}
        </Content>
      </Layout>
    </Layout>
  );
};

export default App;
