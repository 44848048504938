import { Divider, Form, Input } from 'antd';
import React, { useState } from 'react';
import { formValidatorRules } from '../../../../../common/utils';
import ModalComponent from '../../../../../components/ModalComponent';

const { Item } = Form;
const { TextArea } = Input;
const { required, whitespaceNotAllowed } = formValidatorRules;

const ResolveCommentModal = ({
  form,
  isModalOpen,
  setIsModalOpen,
  isBtnLoading,
  onFinish,
}) => {
  const [validationTriggered, setValidationTriggered] = useState(false);

  return (
    <ModalComponent
      title="Mark Request as Resolved"
      okText="Save"
      cancelText="Cancel"
      open={isModalOpen}
      onOk={form?.submit}
      onCancel={() => {
        setIsModalOpen(false);
        form?.resetFields();
      }}
      confirmLoading={isBtnLoading}
    >
      <Divider />
      <Form
        form={form}
        layout="vertical"
        onFinish={onFinish}
        onFinishFailed={() => setValidationTriggered(true)}
        validateTrigger={validationTriggered ? 'onChange' : 'onSubmit'}
      >
        <Item
          name="comment"
          label="Resolving Comment"
          rules={[
            { ...required, message: 'Please Enter Comment!' },
            whitespaceNotAllowed,
          ]}
        >
          <TextArea
            rows={4}
            allowClear
            placeholder="Enter Comment"
            showCount
            maxLength={700}
          />
        </Item>
      </Form>
    </ModalComponent>
  );
};

export default ResolveCommentModal;
