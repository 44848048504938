import { Tabs } from 'antd';
import React, { useContext, useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { AppContext } from '../../../AppContext';
import { ROUTES, TABS } from '../../../common/constants';

const RaiseRequestTabs = ({ children }) => {
  const {
    state: { isDesktop },
  } = useContext(AppContext);
  const history = useHistory();
  const location = useLocation();
  const [activeTabKey, setActiveTabKey] = useState(TABS?.DAMAGES?.key);

  const tabList = [
    {
      key: TABS?.DAMAGES?.key,
      label: TABS?.DAMAGES?.label,
      children,
    },
    {
      key: TABS?.CLOSURE_REQUESTS?.key,
      label: TABS?.CLOSURE_REQUESTS?.label,
      children,
    },
    {
      key: TABS?.BAGS?.key,
      label: TABS?.BAGS?.label,
      children,
    },
    {
      key: TABS?.NEW_TOYS?.key,
      label: TABS?.NEW_TOYS?.label,
      children,
    },
  ];

  useEffect(() => {
    if (location?.pathname?.includes(ROUTES?.DAMAGES)) {
      setActiveTabKey(TABS?.DAMAGES?.key);
    }
    if (location?.pathname?.includes(ROUTES?.CLOSURE_REQUESTS)) {
      setActiveTabKey(TABS?.CLOSURE_REQUESTS?.key);
    }
    if (location?.pathname?.includes(ROUTES?.BAGS)) {
      setActiveTabKey(TABS?.BAGS?.key);
    }
    if (location?.pathname?.includes(ROUTES?.NEW_TOYS)) {
      setActiveTabKey(TABS?.NEW_TOYS?.key);
    }
  }, [location]);

  const onTabChange = (key) => {
    setActiveTabKey(key);
    switch (key) {
      case TABS?.DAMAGES?.key:
        history?.push(`${ROUTES?.RAISE_REQUEST}${ROUTES?.DAMAGES}`);
        break;
      case TABS?.CLOSURE_REQUESTS?.key:
        history?.push(`${ROUTES?.RAISE_REQUEST}${ROUTES?.CLOSURE_REQUESTS}`);
        break;
      case TABS?.BAGS?.key:
        history?.push(`${ROUTES?.RAISE_REQUEST}${ROUTES?.BAGS}`);
        break;
      case TABS?.NEW_TOYS?.key:
        history?.push(`${ROUTES?.RAISE_REQUEST}${ROUTES?.NEW_TOYS}`);
        break;
      default:
        break;
    }
  };

  const isDamagePage =
    isDesktop || !location?.pathname?.includes(ROUTES?.DAMAGES);
  const desktopClass = isDesktop
    ? 'card-container'
    : 'mobile-card-container-with-search mobile-card-main';

  const tabClass = isDamagePage
    ? desktopClass
    : 'mobile-card-container mobile-card-main';

  return (
    <div className={tabClass}>
      <Tabs
        activeKey={activeTabKey}
        type="card"
        className="full-width"
        onChange={(key) => onTabChange(key)}
        items={tabList}
        destroyInactiveTabPane
      />
    </div>
  );
};

export default RaiseRequestTabs;
