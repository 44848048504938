import { LockOutlined, UserOutlined } from '@ant-design/icons';
import { useLazyQuery, useMutation } from '@apollo/client';
import { Button, Card, Form, Image, Input, Spin } from 'antd';
import React, { useContext } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { AppContext } from '../../AppContext';
import { libraryUrls, ROUTES } from '../../common/constants';
import { formValidatorRules } from '../../common/utils';
import LogoTextComponent from '../../components/LogoTextComponent';
import MobileLogoComponent from '../../components/MobileLogoComponent';
import './auth.less';
import { LOGIN } from './graphql/Mutations';
import { GET_CURRENT_USER } from './graphql/Queries';

const { required, email } = formValidatorRules;

const Login = () => {
  const [form] = Form.useForm();
  const history = useHistory();
  const { initializeAuth } = useContext(AppContext);
  // eslint-disable-next-line no-undef
  const currentUrl = window?.location?.href;

  const shouldHide = libraryUrls?.some((url) => currentUrl?.startsWith(url));

  const [getCurrentUser, { loading: currentUserLoading }] = useLazyQuery(
    GET_CURRENT_USER,
    {
      fetchPolicy: 'network-only',
      onError() {},
    },
  );
  const [loginMutate, { loading: loginLoading }] = useMutation(LOGIN, {
    onError() {}, // Always write this method for error handling in all mutation.
  });

  function successCallback(accessToken, userData, permissions, refreshToken) {
    initializeAuth(accessToken, userData, permissions, refreshToken);
    history?.replace('/');
  }

  const onFinish = async (values) => {
    try {
      const formValues = {
        email: values?.email?.trim(),
        password: values?.password?.trim(),
      };
      const response = await loginMutate({
        variables: { data: { ...formValues } },
      });
      if (response?.data) {
        const accessToken = response?.data?.signIn?.token;
        const res = await getCurrentUser({
          context: {
            headers: {
              Authorization: `Bearer ${accessToken}`,
            },
          },
        });
        if (res?.data && successCallback) {
          const userData = res?.data?.currentUserProfile;
          successCallback(
            accessToken,
            userData,
            userData?.permissions,
            userData?.refreshToken,
          );
        } else {
          form?.setFieldsValue(values);
        }
      } else {
        form?.setFieldsValue(values);
      }
    } catch (error) {
      // eslint-disable-next-line no-console
      console?.error('error from login => ', error);
    }
  };

  return (
    <>
      {!shouldHide ? (
        <div className="login-wrap d-flex align-center justify-start">
          <Card className="full-width">
            <Spin
              spinning={loginLoading || currentUserLoading}
              wrapperClassName="full-width"
            >
              <div className="text-center mb-8 d-flex justify-center align-center">
                <MobileLogoComponent height="100px" />
              </div>
              <div className="text-center mb-32 d-flex justify-center align-center">
                <LogoTextComponent height="26px" />
              </div>
              <Form
                name="Login"
                initialValues={{ remember: true }}
                onFinish={onFinish}
                size="large"
                form={form}
              >
                <Form.Item
                  name="email"
                  rules={[
                    { ...required, message: 'Please Enter Email!' },
                    email,
                  ]}
                >
                  <Input
                    prefix={<UserOutlined />}
                    placeholder="Enter email"
                    maxLength={255}
                  />
                </Form.Item>
                <Form.Item
                  name="password"
                  className="mb-16"
                  rules={[{ ...required, message: 'Please Enter Password!' }]}
                >
                  <Input.Password
                    prefix={<LockOutlined />}
                    placeholder="Enter password"
                  />
                </Form.Item>
                <Form.Item className=" full-width mb-8">
                  <Button
                    type="primary"
                    className="full-width"
                    htmlType="submit"
                  >
                    Login
                  </Button>
                </Form.Item>
                <Form.Item className="text-center mb-8">
                  <Link to={ROUTES?.FORGET_PASSWORD}>Forgot password ?</Link>
                </Form.Item>
              </Form>
            </Spin>
          </Card>
        </div>
      ) : (
        <>
          <div className="librarian-login d-flex justify-between">
            <div className="login-container">
              <div className="library-login">
                <div className="title">Welcome, Librarians!</div>
                <div className="description">
                  Effortlessly manage our diverse toy collection, stay on top of
                  inventory, and elevate playtime experiences for kids.
                </div>
                <div className="login-form">
                  <Form
                    name="Login"
                    initialValues={{ remember: true }}
                    onFinish={onFinish}
                    size="large"
                    form={form}
                    layout="vertical"
                  >
                    <Form.Item
                      name="email"
                      label="Email ID"
                      className="mb-16"
                      rules={[
                        { ...required, message: 'Please Enter Email!' },
                        email,
                      ]}
                    >
                      <Input
                        prefix={<UserOutlined />}
                        placeholder="Enter your email here"
                        maxLength={255}
                      />
                    </Form.Item>
                    <Form.Item
                      name="password"
                      label="Password"
                      className="mb-16"
                      rules={[
                        { ...required, message: 'Please Enter Password!' },
                      ]}
                    >
                      <Input.Password
                        prefix={<LockOutlined />}
                        placeholder="Enter your password here"
                      />
                    </Form.Item>
                    <Form.Item className="text-right mb-24">
                      <Link to={ROUTES?.FORGET_PASSWORD}>
                        <span className="forgot-password">
                          Forgot password ?
                        </span>
                      </Link>
                    </Form.Item>
                    <Form.Item className=" full-width mb-8">
                      <Button
                        type="primary"
                        className="full-width login-btn"
                        htmlType="submit"
                      >
                        Login
                      </Button>
                    </Form.Item>
                  </Form>
                </div>
              </div>
            </div>
            <div className="image-container">
              <Image
                src={process.env?.REACT_APP_LIBRARIAN_IMG_URL || ''}
                preview={false}
                className="librarian-image"
                alt="librarian-image"
              />
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default Login;
