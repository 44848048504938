import { gql } from '@apollo/client';

// eslint-disable-next-line import/prefer-default-export
export const LIBRARY = gql`
  query Library {
    library {
      referralUrl
      referralCode
      createdAt
      id
      libraryNumber
      name
    }
  }
`;
