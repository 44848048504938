import { Empty } from 'antd';
import React from 'react';
import LoaderComponent from '../../../components/LoaderComponent';
import InventoryCard from './InventoryCard';

const InventoryList = ({
  inventoryList,
  setVisible,
  setToyImages,
  infiniteScrollRef,
  setInventory,
  setIsEditInventoryModalOpen,
  isFetchMoreToysLoading,
  isEmptyInventoryList,
  isInventoryLoading,
}) => (
  <div>
    {inventoryList?.map((inventory) => (
      <InventoryCard
        key={inventory?.id}
        inventory={inventory}
        setVisible={setVisible}
        setToyImages={setToyImages}
        infiniteScrollRef={infiniteScrollRef}
        setInventory={setInventory}
        setIsEditInventoryModalOpen={setIsEditInventoryModalOpen}
      />
    ))}
    {(isFetchMoreToysLoading || isInventoryLoading) && (
      <LoaderComponent size="small" setHeight={10} />
    )}
    {isEmptyInventoryList && <Empty image={Empty?.PRESENTED_IMAGE_SIMPLE} />}
  </div>
);

export default InventoryList;
