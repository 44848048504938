import React from 'react';
import { Route, Switch } from 'react-router-dom';
import AccessControl from '../../AccessControl';
import { PERMISSIONS_KEY, ROUTES } from '../../common/constants';
import HomeVideo from './pages/HomeVideo';

const HomeVideoWrapper = () => (
  <Switch>
    <Route
      exact
      path={ROUTES?.HOME_VIDEO}
      render={(props) => (
        <AccessControl permissionKey={PERMISSIONS_KEY?.VIDEOS}>
          <HomeVideo {...props} />
        </AccessControl>
      )}
    />
  </Switch>
);

export default HomeVideoWrapper;
