import { useLazyQuery } from '@apollo/client';
import { Button, Card, Form } from 'antd';
import React, { useContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { AppContext } from '../../../../../AppContext';
import {
  DATE_FORMATS,
  PERMISSIONS_KEY,
  PERMISSION_TYPE,
  ROUTES,
} from '../../../../../common/constants';
import { formatDate, hasPermission } from '../../../../../common/utils';
import GoBackButton from '../../../../../components/GoBackButton';
import LoaderComponent from '../../../../../components/LoaderComponent';
import Portal from '../../../../../components/Portal';
import { USER } from '../../../graphql/Queries';
import AdminForm from '../components/AdminForm';

const ViewAdmin = (props) => {
  const { match: { params: { id } = {} } = {} } = props;
  const {
    state: { permissions },
  } = useContext(AppContext);

  const [form] = Form.useForm();
  const history = useHistory();
  const [adminDetail, setAdminDetail] = useState({});
  const [isAdminLoading, setIsAdminLoading] = useState(true);

  const [users] = useLazyQuery(USER, {
    onCompleted: (response) => {
      setAdminDetail(response?.user);
      setIsAdminLoading(false);
    },
    fetchPolicy: 'network-only',
    onError() {},
  });

  useEffect(() => {
    setIsAdminLoading(true);
    setAdminDetail([]);
    users({
      variables: {
        where: {
          id,
        },
      },
    });
  }, []);

  const initialValues = {
    ...adminDetail,
    contact: adminDetail?.contactNo,
    role: adminDetail?.roles?.[0],
    status: adminDetail?.isActive,
    state: adminDetail?.state,
    city: adminDetail?.city,
    pincode: adminDetail?.pincode?.pincode,
    isActive: adminDetail?.status,
  };

  return (
    <>
      <Portal portalId="header-title-content">
        <GoBackButton customLink={ROUTES?.SYSTEM_USERS} />
        <div className="portal-header">View Admin</div>
      </Portal>
      <Portal portalId="header-right-content">
        {hasPermission(
          PERMISSIONS_KEY?.SYSTEM_USERS_ADMINS,
          PERMISSION_TYPE?.UPDATE,
          permissions,
        ) && (
          <div className="header-btn-wrapper">
            <Button
              className="common-button mr-16"
              size="middle"
              onClick={() =>
                history?.push(
                  `${ROUTES?.SYSTEM_USERS}${ROUTES?.ADMINS}/edit/${id}`,
                )
              }
              disabled={adminDetail?.isArchived}
            >
              Edit Admin
            </Button>
          </div>
        )}
      </Portal>
      <Portal portalId="header-right-content-phones">
        {hasPermission(
          PERMISSIONS_KEY?.SYSTEM_USERS_ADMINS,
          PERMISSION_TYPE?.UPDATE,
          permissions,
        ) && (
          <div className="header-btn-wrapper">
            <Button
              className="common-button mr-16"
              size="middle"
              onClick={() =>
                history?.push(
                  `${ROUTES?.SYSTEM_USERS}${ROUTES?.ADMINS}/edit/${id}`,
                )
              }
              disabled={adminDetail?.isArchived}
            >
              Edit Admin
            </Button>
          </div>
        )}
      </Portal>
      <Card
        className="full-height-with-nav"
        title="Admin Details"
        extra={
          <strong>
            Onboarded On :{' '}
            {formatDate(adminDetail?.createdAt, DATE_FORMATS?.ONBOARD)}
          </strong>
        }
      >
        {isAdminLoading ? (
          <LoaderComponent
            size="large"
            setHeight="60"
            spinning={isAdminLoading}
          />
        ) : (
          <div className="admin-form">
            <AdminForm
              form={form}
              initialValues={initialValues}
              isView
              adminDetail={adminDetail}
            />
          </div>
        )}
      </Card>
    </>
  );
};

export default ViewAdmin;
