import { LockOutlined } from '@ant-design/icons';
import { useMutation } from '@apollo/client';
import { Button, Card, Form, Image, Input } from 'antd';
import React from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { libraryUrls, ROUTES } from '../../common/constants';
import { formValidatorRules } from '../../common/utils';
import LogoTextComponent from '../../components/LogoTextComponent';
import MobileLogoComponent from '../../components/MobileLogoComponent';
import { RESET_PASSWORD } from './graphql/Mutations';

const { required, password } = formValidatorRules;

const ChangePassword = () => {
  const history = useHistory();
  const location = useLocation();
  const [resetPassword, { loading: reseting }] = useMutation(RESET_PASSWORD);
  const params = new URLSearchParams(location?.search);
  const token = params?.get('token');
  // eslint-disable-next-line no-undef
  const currentUrl = window?.location?.href;

  const shouldHide = libraryUrls?.some((url) => currentUrl?.startsWith(url));

  const onFinish = async (values) => {
    try {
      const response = await resetPassword({
        variables: { data: { token, password: values?.password?.trim() } },
      });
      if (response) {
        history?.replace(ROUTES?.LOGIN);
      }
    } catch (error) {
      // eslint-disable-next-line no-console
      console?.error('error from login => ', error);
    }
  };

  return (
    <>
      {!shouldHide ? (
        <div className="login-wrap d-flex align-center justify-center">
          <Card className="full-width">
            <div className="text-center">
              <div className="text-center mb-8 d-flex justify-center align-center">
                <MobileLogoComponent height="100px" />
              </div>
              <div className="text-center mb-32 d-flex justify-center align-center">
                <LogoTextComponent height="26px" />
              </div>
              <h2 className="text-center">Reset Password</h2>
              <p className="text-center">
                Enter a new password for your account
              </p>
            </div>
            <Form
              name="reset-password"
              initialValues={{ remember: true }}
              onFinish={onFinish}
              size="large"
            >
              <Form.Item
                name="password"
                rules={[
                  { ...required, message: 'Please Enter Password!' },
                  password,
                ]}
              >
                <Input.Password
                  prefix={<LockOutlined />}
                  placeholder="Enter password"
                />
              </Form.Item>
              <Form.Item
                name="retype-password"
                rules={[
                  { ...required, message: 'Please Enter Confirm Password!' },
                  ({ getFieldValue }) => ({
                    validator(_, value) {
                      if (value !== getFieldValue('password')) {
                        return Promise?.reject(
                          new Error('Passwords do not match'),
                        );
                      }
                      return Promise?.resolve();
                    },
                  }),
                ]}
              >
                <Input.Password
                  prefix={<LockOutlined />}
                  placeholder="Enter confirm password"
                />
              </Form.Item>
              <Form.Item className="full-width mb-8">
                <Button type="primary" className="full-width" htmlType="submit">
                  Reset Password
                </Button>
              </Form.Item>
              <Form.Item className="text-center mb-0">
                <Button
                  type="link"
                  loading={reseting}
                  onClick={() => {
                    history?.push(ROUTES?.LOGIN);
                  }}
                >
                  Cancel
                </Button>
              </Form.Item>
            </Form>
          </Card>
        </div>
      ) : (
        <>
          <div className="librarian-login d-flex justify-between">
            <div className="login-container">
              <div className="library-login">
                <div className="title">Reset Password</div>
                <div className="description">
                  Enter a new password for your account
                </div>
                <div className="login-form">
                  <Form
                    name="reset-password"
                    initialValues={{ remember: true }}
                    onFinish={onFinish}
                    size="large"
                    layout="vertical"
                  >
                    <Form.Item
                      name="password"
                      label="Password"
                      className="mb-16"
                      rules={[
                        { ...required, message: 'Please Enter Password!' },
                        password,
                      ]}
                    >
                      <Input.Password
                        prefix={<LockOutlined />}
                        placeholder="Enter password"
                      />
                    </Form.Item>
                    <Form.Item
                      name="retype-password"
                      label="Confirm Password"
                      className="mb-24"
                      rules={[
                        {
                          ...required,
                          message: 'Please Enter Confirm Password!',
                        },
                        ({ getFieldValue }) => ({
                          validator(_, value) {
                            if (value !== getFieldValue('password')) {
                              return Promise?.reject(
                                new Error('Passwords do not match'),
                              );
                            }
                            return Promise?.resolve();
                          },
                        }),
                      ]}
                    >
                      <Input.Password
                        prefix={<LockOutlined />}
                        placeholder="Enter confirm password"
                      />
                    </Form.Item>
                    <Form.Item className="full-width mb-8">
                      <Button
                        type="primary"
                        className="full-width login-btn"
                        htmlType="submit"
                      >
                        Reset Password
                      </Button>
                    </Form.Item>
                    <Form.Item className="text-center mb-0">
                      <Button
                        type="link"
                        className="full-width login-btn"
                        onClick={() => {
                          history?.push(ROUTES?.LOGIN);
                        }}
                      >
                        Cancel
                      </Button>
                    </Form.Item>
                  </Form>
                </div>
              </div>
            </div>
            <div className="image-container">
              <Image
                src={process.env?.REACT_APP_LIBRARIAN_IMG_URL || ''}
                preview={false}
                className="librarian-image"
                alt="librarian-image"
              />
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default ChangePassword;
