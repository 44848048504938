import React from 'react';
import { Route, Switch } from 'react-router-dom';
import { ROUTES } from '../../common/constants';
import Payments from './pages/Payments';

const PaymentsWrapper = () => (
  <Switch>
    <Route
      exact
      path={`${ROUTES?.PAYMENTS}`}
      render={(props) => <Payments {...props} />}
    />
  </Switch>
);

export default PaymentsWrapper;
