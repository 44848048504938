import { Badge, Button, Form, Popover, Radio } from 'antd';
import React, { useEffect } from 'react';
import { ReactComponent as SortIcon } from '../assets/svg/arrow-right-arrow-left-solid.svg';
import { SORT } from '../common/constants';
import { hasNonEmptyValueObj } from '../common/utils';

const { Item } = Form;
const { Group } = Radio;

const SortDropdown = ({
  isOpen,
  setIsOpen,
  options,
  sortedInfo,
  setSortedInfo,
  onFinish,
}) => {
  const [form] = Form.useForm();

  const filteredSort = options?.filter(
    (item) =>
      item?.field === sortedInfo?.columnKey &&
      item?.sort === (sortedInfo?.order === 'ascend' ? SORT?.ASC : SORT?.DESC),
  );

  const handleReset = () => {
    setIsOpen(false);
    form?.resetFields();
    setSortedInfo({});
    form?.submit();
  };

  useEffect(() => {
    form?.setFieldsValue({
      sort: filteredSort?.[0]?.value,
    });
  }, [isOpen]);

  const radioRender = () => (
    <>
      <Form form={form} onFinish={onFinish} autoComplete="off">
        <Item name="sort" className="mb-8">
          <Group>
            {options?.map((option) => (
              <Radio
                key={option?.value}
                value={option?.value}
                className="mb-8 d-flex"
              >
                {option?.label}
              </Radio>
            ))}
          </Group>
        </Item>
      </Form>
      <div className="d-flex justify-end">
        <Button className="mr-8" size="small" onClick={handleReset}>
          Reset
        </Button>
        <Button type="primary" size="small" onClick={form?.submit}>
          Apply
        </Button>
      </div>
    </>
  );

  return (
    <Popover
      content={radioRender}
      open={isOpen}
      placement="bottomRight"
      onOpenChange={(e) => setIsOpen(e)}
      trigger="click"
      forceRender
    >
      <Badge dot={hasNonEmptyValueObj(sortedInfo)} size="small">
        <SortIcon className="svg-icon" />
      </Badge>
    </Popover>
  );
};

export default SortDropdown;
