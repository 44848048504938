import { Divider, Form, Input } from 'antd';
import React, { useEffect, useState } from 'react';
import { formValidatorRules } from '../../../../../common/utils';
import ModalComponent from '../../../../../components/ModalComponent';

const { Item } = Form;
const { required, whitespaceNotAllowed } = formValidatorRules;

const RackMasterModal = ({
  isModalOpen,
  setIsModalOpen,
  onFinish,
  loadings,
  form,
  rackMaster,
  setRackMaster,
}) => {
  const [isValidateTrigger, setIsValidateTrigger] = useState(false);

  const handleCancel = () => {
    setIsModalOpen(false);
    setRackMaster();
    form?.resetFields();
  };

  useEffect(() => {
    form?.setFieldsValue({ name: rackMaster?.name });
  }, [rackMaster]);

  return (
    <>
      <ModalComponent
        open={isModalOpen}
        onCancel={handleCancel}
        onOk={form?.submit}
        title={rackMaster ? 'Edit Rack Master' : 'Add New Rack Master'}
        okText="Save"
        confirmLoading={loadings}
        destroyOnClose
        forceRender
      >
        <Divider />
        <Form
          form={form}
          onFinish={(values) => {
            onFinish(values);
            setIsValidateTrigger(true);
          }}
          autoComplete="off"
          layout="vertical"
          validateTrigger={isValidateTrigger ? 'onChange' : 'onSubmit'}
          onFinishFailed={() => setIsValidateTrigger(true)}
        >
          <Item
            name="name"
            label="Rack Name"
            rules={[
              { ...required, message: 'Please Enter Rack Name!' },
              whitespaceNotAllowed,
            ]}
          >
            <Input allowClear placeholder="Enter Rack Name" maxLength={100} />
          </Item>
        </Form>
      </ModalComponent>
    </>
  );
};

export default RackMasterModal;
