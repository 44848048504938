import { Card } from 'antd';
import React from 'react';
import { MODULES } from '../../../common/constants';
import Portal from '../../../components/Portal';
import PaymentTable from '../components/PaymentTable';

const Payments = () => (
  <>
    <Portal portalId="header-title-content">
      <div className="portal-header">{MODULES?.PAYMENTS}</div>
    </Portal>
    <Card className="mobile-with-search-container mobile-card-main">
      <PaymentTable />
    </Card>
  </>
);

export default Payments;
