import { Divider, Form, Input } from 'antd';
import { map } from 'lodash';
import React, { useEffect, useState } from 'react';
import {
  CONFIG_DATA_TYPES,
  CONFIG_TYPES,
  NUMBER_FIELD_TYPES,
} from '../../../common/constants';
import { formValidatorRules } from '../../../common/utils';
import ModalComponent from '../../../components/ModalComponent';
import NumberComponent from '../../../components/NumberComponent';
import SelectComponent from '../../../components/SelectComponent';

const { Item } = Form;
const { TextArea } = Input;
const { whitespaceNotAllowed, configValue } = formValidatorRules;

const ConfigModal = ({
  isModalOpen,
  setIsModalOpen,
  onFinish,
  loadings,
  form,
  config,
  setConfig,
}) => {
  const [isValidateTrigger, setIsValidateTrigger] = useState(false);
  const [dataTypeValue, setDataTypeValue] = useState('');

  const handleCancel = () => {
    setIsModalOpen(false);
    setConfig({});
    setDataTypeValue('');
  };

  useEffect(() => {
    form?.setFieldsValue(config);
    setDataTypeValue(config?.dataType);
  }, [config]);

  const handleChange = (value) => {
    setDataTypeValue(value);
    form.resetFields(['value']);
  };

  return (
    <>
      <ModalComponent
        open={isModalOpen}
        onCancel={handleCancel}
        onOk={form?.submit}
        title="Edit Config"
        okText="Save"
        confirmLoading={loadings}
        destroyOnClose
        forceRender
      >
        <Divider />
        <Form
          form={form}
          onFinish={(values) => {
            onFinish(values);
            setIsValidateTrigger(true);
          }}
          autoComplete="off"
          layout="vertical"
          validateTrigger={isValidateTrigger ? 'onChange' : 'onSubmit'}
          onFinishFailed={() => setIsValidateTrigger(true)}
        >
          <Item label="Key" name="key">
            <Input placeholder="Enter Key" disabled />
          </Item>
          <Item label="Data Type" name="dataType">
            <SelectComponent
              options={map(CONFIG_DATA_TYPES, (dataType) => dataType)}
              onChange={handleChange}
            />
          </Item>
          {dataTypeValue === CONFIG_DATA_TYPES?.STRING?.value ? (
            <Item label="Value" name="value" rules={[whitespaceNotAllowed]}>
              <Input allowClear placeholder="Enter Value" maxLength={15} />
            </Item>
          ) : (
            <Item label="Value" name="value" rules={[configValue]}>
              <NumberComponent
                allowClear
                isFractional={false}
                placeholder="Enter Value"
                type={NUMBER_FIELD_TYPES?.CONFIG}
              />
            </Item>
          )}
          <Item label="Type" name="type">
            <SelectComponent options={map(CONFIG_TYPES, (type) => type)} />
          </Item>
          <Item
            name="description"
            label="Description"
            rules={[whitespaceNotAllowed]}
          >
            <TextArea
              rows={4}
              allowClear
              placeholder="Enter Description"
              showCount
              maxLength={800}
            />
          </Item>
        </Form>
      </ModalComponent>
    </>
  );
};

export default ConfigModal;
