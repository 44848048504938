import { Card } from 'antd';
import React from 'react';
import { MODULES } from '../../../common/constants';
import Portal from '../../../components/Portal';
import NotificationTable from '../components/NotificationTable';

const Notifications = () => (
  <>
    <Portal portalId="header-title-content">
      <div className="portal-header">{MODULES?.NOTIFICATIONS}</div>
    </Portal>
    <Card className="mobile-with-search-actions mobile-card-main">
      <NotificationTable />
    </Card>
  </>
);

export default Notifications;
