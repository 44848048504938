import { gql } from '@apollo/client';

// eslint-disable-next-line import/prefer-default-export
export const CREATE_GIFT_CARD = gql`
  mutation CreateGiftCard($data: CreateGiftCardInput!) {
    createGiftCard(data: $data) {
      message
    }
  }
`;

export const UPDATE_GIFT_CARD = gql`
  mutation UpdateGiftCard(
    $where: GiftCardUniqueInput!
    $data: UpdateGiftCardInput!
  ) {
    updateGiftCard(where: $where, data: $data) {
      message
    }
  }
`;

export const REQUEST_GIFT_CARD_EXPORT = gql`
  mutation RequestGiftCardExport(
    $data: RequestExportInput!
    $filter: RequestGiftCardExportFilterInput
  ) {
    requestGiftCardExport(data: $data, filter: $filter) {
      message
    }
  }
`;
