import React from 'react';
import { Route, Switch } from 'react-router-dom';
import AccessControl from '../../../../AccessControl';
import { PERMISSIONS_KEY, ROUTES } from '../../../../common/constants';
import Error404 from '../../../../Error404';
import CustomReport from './pages/CustomReport';

const CustomReportsWrapper = () => (
  <Switch>
    <Route
      exact
      path={`${ROUTES?.REPORTS}${ROUTES?.CUSTOM_REPORTS}`}
      render={(props) => (
        <AccessControl permissionKey={PERMISSIONS_KEY?.REPORTS_CUSTOM}>
          <CustomReport {...props} />
        </AccessControl>
      )}
    />
    <Route path="*" exact component={Error404} />
  </Switch>
);

export default CustomReportsWrapper;
