import React from 'react';
import { Route, Switch } from 'react-router-dom';
import { ROUTES } from '../../common/constants';
import Error404 from '../../Error404';
import './inventory.less';
import BulkUpdateLog from './pages/BulkUpdateLog';
import Inventory from './pages/Inventory';
import ViewToy from './pages/ViewToy';

const InventoryWrapper = () => (
  <Switch>
    <Route exact path={ROUTES?.INVENTORY} component={Inventory} />
    <Route
      exact
      path={`${ROUTES?.INVENTORY}${ROUTES?.BULK_UPDATE_LOG}/view`}
      component={BulkUpdateLog}
    />
    <Route
      exact
      path={`${ROUTES?.INVENTORY}${ROUTES?.TOY}/view/:id`}
      component={ViewToy}
    />
    <Route path="*" exact component={Error404} />
  </Switch>
);

export default InventoryWrapper;
