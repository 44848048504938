import { Button, Card, Image, Tag } from 'antd';
import React, { useState } from 'react';

const BulkUpdateLogCard = ({
  inventory,
  setVisible,
  setToyImages,
  infiniteScrollRef,
}) => {
  const maxTagsToShow = 5;
  const [showAllCategories, setShowAllCategories] = useState(false);
  const toy = inventory?.libraryInventory?.toy;

  const renderedCategories = showAllCategories
    ? toy?.categories
    : toy?.categories?.slice(0, maxTagsToShow);

  return (
    <Card
      className="ml-12 mr-12 mb-24 mt-12 mobile-card"
      title={
        <div className="overflow-scroll no-scroll-bar">{toy?.toyNumber}</div>
      }
      extra={
        <div className="d-flex align-center">
          <span className="mr-16 ml-16">
            {toy?.images?.length > 0 && (
              <>
                <Image
                  preview={{
                    visible: false,
                  }}
                  width={20}
                  height={20}
                  src={toy?.images?.[0]?.url}
                  onClick={(e) => {
                    e?.stopPropagation();
                    setVisible(true);
                    setToyImages(toy?.images);
                  }}
                  className="pointer"
                />
              </>
            )}
            {toy?.images?.length > 1 ? ` + ${toy?.images?.length - 1}` : ''}
          </span>
        </div>
      }
      ref={infiniteScrollRef}
    >
      <div className="mb-8">
        <span className="mobile-card-label">Product Name: </span>
        <span>{toy?.name}</span>
      </div>
      <div className="mb-8">
        <span className="mobile-card-label">Categories: </span>
        <span>
          {renderedCategories?.map((category) => (
            <Tag key={category?.id}>{category?.name}</Tag>
          ))}
          {toy?.categories?.length > maxTagsToShow && !showAllCategories && (
            <Button
              type="link"
              size="small"
              onClick={() => setShowAllCategories(true)}
            >
              ...more
            </Button>
          )}
        </span>
      </div>
      <div className="mb-8">
        <span className="mobile-card-label">Old Quantity: </span>
        <span>{inventory?.oldQuantity}</span>
      </div>
      <div className="mb-8">
        <span className="mobile-card-label">Updated Quantity: </span>
        <span>{inventory?.updatedQuantity}</span>
      </div>
      <div className="mb-8">
        <span className="mobile-card-label">Reason: </span>
        <span>{inventory?.reason || 'No Reason'}</span>
      </div>
    </Card>
  );
};

export default BulkUpdateLogCard;
