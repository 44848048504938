import React from 'react';
import { Route, Switch } from 'react-router-dom';
import AccessControl from '../../../../AccessControl';
import {
  PERMISSIONS_KEY,
  PERMISSION_TYPE,
  ROUTES,
} from '../../../../common/constants';
import Error404 from '../../../../Error404';
import CreateDelivery from './pages/CreateDelivery';
import Delivery from './pages/Delivery';
import EditDelivery from './pages/EditDelivery';
import ViewDelivery from './pages/ViewDelivery';

const DeliveryWrapper = () => (
  <Switch>
    <Route
      exact
      path={`${ROUTES?.SYSTEM_USERS}${ROUTES?.DELIVERY_PARTNERS}`}
      render={(props) => (
        <AccessControl
          permissionKey={PERMISSIONS_KEY?.SYSTEM_USERS_DELIVERY_PARTNERS}
        >
          <Delivery {...props} />
        </AccessControl>
      )}
    />
    <Route
      exact
      path={`${ROUTES?.SYSTEM_USERS}${ROUTES?.DELIVERY_PARTNERS}/add`}
      render={(props) => (
        <AccessControl
          permissionType={PERMISSION_TYPE?.CREATE}
          permissionKey={PERMISSIONS_KEY?.SYSTEM_USERS_DELIVERY_PARTNERS}
        >
          <CreateDelivery {...props} />
        </AccessControl>
      )}
    />
    <Route
      exact
      path={`${ROUTES?.SYSTEM_USERS}${ROUTES?.DELIVERY_PARTNERS}/edit/:id`}
      render={(props) => (
        <AccessControl
          permissionType={PERMISSION_TYPE?.UPDATE}
          permissionKey={PERMISSIONS_KEY?.SYSTEM_USERS_DELIVERY_PARTNERS}
        >
          <EditDelivery {...props} />
        </AccessControl>
      )}
    />
    <Route
      exact
      path={`${ROUTES?.SYSTEM_USERS}${ROUTES?.DELIVERY_PARTNERS}/view/:id`}
      render={(props) => (
        <AccessControl
          permissionKey={PERMISSIONS_KEY?.SYSTEM_USERS_DELIVERY_PARTNERS}
        >
          <ViewDelivery {...props} />
        </AccessControl>
      )}
    />
    <Route path="*" exact component={Error404} />
  </Switch>
);

export default DeliveryWrapper;
