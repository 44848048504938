import { useLazyQuery } from '@apollo/client';
import { Divider } from 'antd';

import React, { useEffect, useState } from 'react';
import { LIMIT } from '../../../../../common/constants';
import CommonTable from '../../../../../components/CommonTable';
import ModalComponent from '../../../../../components/ModalComponent';
import SearchComponent from '../../../../../components/SearchComponent';
import useInfiniteScroll from '../../../../../hooks/useInfiniteScroll';
import { COUPON_REDEMPTIONS } from '../graphql/Queries';

const initialFilter = {
  limit: LIMIT,
  search: '',
  skip: 0,
};

const initialSort = {
  field: 'createdAt',
  order: 'DESC',
};

const UsedByModal = ({ isModalOpen, setIsModalOpen, couponId }) => {
  const [users, setUsers] = useState([]);
  const [isUsersLoading, setIsUsersLoading] = useState(true);
  const [usersSearchTerm, setUsersSearchTerm] = useState('');
  const [isEmptyUsersList, setIsEmptyUsersList] = useState(false);
  const [isFetchMoreUsersLoading, setIsFetchMoreUsersLoading] = useState(false);
  const [isActive, setIsActive] = useState(true);

  const [couponRedemptions] = useLazyQuery(COUPON_REDEMPTIONS, {
    onCompleted: (response) => {
      if (response?.couponRedemptions?.data?.length < LIMIT) {
        setIsActive(false);
      }
      if (response?.couponRedemptions?.count === 0) {
        setIsEmptyUsersList(true);
      }
      setUsers([...users, ...response?.couponRedemptions?.data]);
      setIsUsersLoading(false);
      setIsFetchMoreUsersLoading(false);
    },
    fetchPolicy: 'network-only',
    onError() {},
  });

  useEffect(() => {
    if (couponId && isModalOpen) {
      setIsUsersLoading(true);
      setIsActive(true);
      setUsers([]);
      setUsersSearchTerm('');
      couponRedemptions({
        variables: {
          where: {
            id: couponId,
          },
          filter: initialFilter,
          sort: initialSort,
        },
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [couponId, isModalOpen]);

  const handleSearch = (value) => {
    const trimValue = value?.trim();
    setUsersSearchTerm(trimValue);
    setIsUsersLoading(true);
    setUsers([]);
    setIsActive(true);
    couponRedemptions({
      variables: {
        where: {
          id: couponId,
        },
        filter: {
          ...initialFilter,
          search: trimValue,
        },
        sort: initialSort,
      },
    });
  };

  const handleMembersScroll = () => {
    if (!isFetchMoreUsersLoading && !isUsersLoading) {
      const currentLength = users?.length;
      setIsFetchMoreUsersLoading(true);
      couponRedemptions({
        variables: {
          where: {
            id: couponId,
          },
          filter: {
            ...initialFilter,
            skip: currentLength,
          },
          sort: initialSort,
        },
      });
    }
  };

  const columns = [
    {
      title: 'USER NAME',
      dataIndex: 'userName',
      key: 'userName',
      ellipsis: true,
      width: 300,
      align: 'left',
      className: 'max-width-column',
      render: (_, record) => {
        const userData = record?.user;
        return (
          <>
            {userData?.firstName || userData?.lastName ? (
              <div className="table-data-with-id">{`${
                userData?.firstName ? userData?.firstName : ''
              } ${userData?.lastName ? userData?.lastName : ''}`}</div>
            ) : (
              '-'
            )}
            {userData?.userNumber ? (
              <div className="font-size-12">({userData?.userNumber})</div>
            ) : (
              ''
            )}
          </>
        );
      },
    },
  ];

  const locale = {
    emptyText: isEmptyUsersList ? '' : <span />,
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const infiniteScrollRef = useInfiniteScroll(handleMembersScroll, isActive);

  return (
    <ModalComponent
      open={isModalOpen}
      onCancel={handleCancel}
      title="Used By"
      footer={null}
      forceRender
      width={600}
    >
      <Divider />
      <div className="width-percent-40">
        <SearchComponent
          query={usersSearchTerm}
          setQuery={setUsersSearchTerm}
          getData={handleSearch}
        />
      </div>
      <div className="mt-24">
        <CommonTable
          locale={locale}
          columns={columns}
          data={users}
          loading={isUsersLoading || isFetchMoreUsersLoading}
          scroll={{ y: 350 }}
          rowKey={(record) => record?.id}
          onRow={() => ({
            ref: infiniteScrollRef,
          })}
        />
      </div>
    </ModalComponent>
  );
};

export default UsedByModal;
