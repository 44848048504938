import React from 'react';
import { Route, Switch } from 'react-router-dom';
import AccessControl from '../../AccessControl';
import { PERMISSIONS_KEY, ROUTES } from '../../common/constants';
import UiLabels from './pages/UiLabels';

const UiLabelsWrapper = () => (
  <Switch>
    <Route
      exact
      path={ROUTES?.UI_LABELS}
      render={(props) => (
        <AccessControl permissionKey={PERMISSIONS_KEY?.UI_LABELS}>
          <UiLabels {...props} />
        </AccessControl>
      )}
    />
  </Switch>
);

export default UiLabelsWrapper;
