import { Card, Dropdown, Tag } from 'antd';
import React from 'react';
import { ReactComponent as MoreIcon } from '../../../assets/svg/ellipsis-vertical-solid.svg';
import {
  ACTIONS,
  DATE_FORMATS,
  PAYMENT_STATUS,
} from '../../../common/constants';
import { formatDate } from '../../../common/utils';

const items = [
  {
    label: ACTIONS?.DOWNLOAD?.label,
    key: ACTIONS?.DOWNLOAD?.value,
  },
];

const InvoiceCard = ({ invoice, handleDownload, infiniteScrollRef }) => {
  const handleMenuClick = () => {
    handleDownload(invoice?.attachment);
  };

  const menuProps = {
    items,
    onClick: handleMenuClick,
  };

  return (
    <Card
      className="ml-12 mr-12 mb-24 mt-12 mobile-card"
      title={
        <div className="overflow-scroll no-scroll-bar">
          {invoice?.invoiceNumber}
        </div>
      }
      extra={
        <div className="d-flex align-center">
          <Tag
            color={PAYMENT_STATUS?.[invoice?.status]?.color}
            className="mr-16 ml-16"
          >
            {PAYMENT_STATUS?.[invoice?.status]?.text}
          </Tag>
          <Dropdown
            getPopupContainer={(trigger) => trigger?.parentNode}
            menu={menuProps}
          >
            <MoreIcon className="pointer mobile-svg-icon" />
          </Dropdown>
        </div>
      }
      ref={infiniteScrollRef}
    >
      <div className="mb-8">
        <span className="mobile-card-label">Generated On: </span>
        <span>{formatDate(invoice?.createdAt, DATE_FORMATS?.REGULAR)}</span>
      </div>
      <div className="mb-8">
        <span className="mobile-card-label">Paid On: </span>
        <span>
          {formatDate(invoice?.paidOn, DATE_FORMATS?.REGULAR) || 'No Paid On'}
        </span>
      </div>
      <div className="mb-8">
        <span className="mobile-card-label">Amount: </span>
        <span>{invoice?.amount}</span>
      </div>
    </Card>
  );
};

export default InvoiceCard;
