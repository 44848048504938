import { gql } from '@apollo/client';

// eslint-disable-next-line import/prefer-default-export
export const FACILITATES_ADMIN = gql`
  query FacilitatesAdmin($filter: FacilitateFilter, $sort: FacilitateSort) {
    facilitatesAdmin(filter: $filter, sort: $sort) {
      count
      data {
        name
        image
        createdAt
        id
        isArchived
        imageUuid
      }
    }
  }
`;

export const GET_FACILITATES_IMAGE_SIGNED_PUT_URL = gql`
  query GetFacilitatesImageSignedPutUrl(
    $data: GetFacilitatesImageSignedPutUrlInput!
  ) {
    getFacilitatesImageSignedPutUrl(data: $data) {
      fileName
      signedUrl
    }
  }
`;
