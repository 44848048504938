import { gql } from '@apollo/client';

// eslint-disable-next-line import/prefer-default-export
export const UPDATE_LIBRARY_ADMIN = gql`
  mutation UpdateLibraryAdmin(
    $where: LibraryUniqueInput!
    $data: UpdateLibraryInput!
  ) {
    updateLibraryAdmin(where: $where, data: $data) {
      message
    }
  }
`;

export const CREATE_LIBRARY = gql`
  mutation CreateLibrary($data: CreateLibraryInput!) {
    createLibrary(data: $data) {
      message
    }
  }
`;

export const ASSIGN_BACKUP_LIBRARY = gql`
  mutation AssignBackUpLibrary(
    $where: LibraryUniqueInput!
    $data: AssignBackUpLibraryInput!
  ) {
    assignBackUpLibrary(where: $where, data: $data) {
      message
    }
  }
`;

export const ADD_TOY_TO_LIBRARY_INVENTORY = gql`
  mutation AddToysToLibraryInventory(
    $where: LibraryUniqueInput!
    $data: AddToysToLibraryInventoryInput!
  ) {
    addToysToLibraryInventory(where: $where, data: $data) {
      message
    }
  }
`;

export const UPDATE_LIBRARY_INVENTORY_ADMIN = gql`
  mutation UpdateLibraryInventoryAdmin(
    $where: LibraryInventoryUniqueInput!
    $data: UpdateLibraryInventoryInput!
  ) {
    updateLibraryInventoryAdmin(where: $where, data: $data) {
      message
    }
  }
`;

export const DELETE_TOY_FROM_INVENTORY = gql`
  mutation DeleteToyFromInventory($where: LibraryInventoryUniqueInput!) {
    deleteToyFromInventory(where: $where) {
      message
    }
  }
`;

export const REQUEST_LIBRARY_EXPORT = gql`
  mutation RequestLibraryExport(
    $data: RequestExportInput!
    $filter: RequestLibraryExportFilterInput
  ) {
    requestLibraryExport(data: $data, filter: $filter) {
      message
    }
  }
`;

export const REQUEST_LIBRARY_INVENTORY_EXPORT_ADMIN = gql`
  mutation RequestLibraryInventoryExportAdmin(
    $where: LibraryUniqueInput!
    $data: RequestExportInput!
    $filter: RequestLibraryInventoryExportFilterInput
  ) {
    requestLibraryInventoryExportAdmin(
      where: $where
      data: $data
      filter: $filter
    ) {
      message
    }
  }
`;

export const LIBRARY_INVENTORY_BULK_UPDATE_ADMIN = gql`
  mutation LibraryInventoryBulkUpdateAdmin(
    $data: LibraryInventoryBulkUpdateInput!
  ) {
    libraryInventoryBulkUpdateAdmin(data: $data) {
      message
    }
  }
`;

export const CREATE_BULK_LIBRARY_INVENTORY = gql`
  mutation CreateBulkLibraryInventory(
    $where: LibraryInventoryUniqueInput!
    $data: LibraryInventoryBulkUpdateInput!
  ) {
    createBulkLibraryInventory(where: $where, data: $data) {
      message
    }
  }
`;
