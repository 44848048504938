import { gql } from '@apollo/client';

// eslint-disable-next-line import/prefer-default-export
export const CREATE_PINCODE = gql`
  mutation CreatePincode($data: CreatePincodeInput!) {
    createPincode(data: $data) {
      message
    }
  }
`;

export const UPDATE_PINCODE = gql`
  mutation UpdatePincode(
    $where: PincodeUniqueInput!
    $data: UpdatePincodeInput
  ) {
    updatePincode(where: $where, data: $data) {
      message
    }
  }
`;
