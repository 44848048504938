import { Button, DatePicker, Divider, Form, Image } from 'antd';
import { map } from 'lodash';
import React, { useEffect, useState } from 'react';
import { DATE_FORMATS, SIZE, STATUS_FILTER } from '../../../common/constants';
import CommonPreview from '../../../components/CommonPreview';
import ModalComponent from '../../../components/ModalComponent';
import SelectComponent from '../../../components/SelectComponent';

const { Item } = Form;
const { RangePicker } = DatePicker;

const AllFilterBagReqModal = ({
  isModalOpen,
  setIsModalOpen,
  onFinish,
  finalWhereFilters,
  setFinalWhereFilters,
}) => {
  const [form] = Form.useForm();
  const [sizeImage, setSizeImage] = useState();
  const [visible, setVisible] = useState(false);

  const handleReset = () => {
    setIsModalOpen(false);
    form?.resetFields();
    setFinalWhereFilters();
    form?.submit();
  };

  useEffect(() => {
    form?.setFieldsValue({
      ...finalWhereFilters,
      createdAt: [
        finalWhereFilters?.createdAt?.from,
        finalWhereFilters?.createdAt?.to,
      ],
      size: finalWhereFilters?.size,
      status: finalWhereFilters?.status,
    });
  }, [isModalOpen]);

  return (
    <>
      <CommonPreview
        visible={visible}
        setVisible={setVisible}
        image={sizeImage}
        setImage={setSizeImage}
      />
      <ModalComponent
        open={isModalOpen}
        onCancel={() => setIsModalOpen(false)}
        title="Filter"
        forceRender
        destroyOnClose
        footer={null}
      >
        <Divider />
        <div className="mb-16">
          <Form
            form={form}
            onFinish={onFinish}
            autoComplete="off"
            layout="vertical"
          >
            <Item label="Request Date" name="createdAt">
              <RangePicker
                format={DATE_FORMATS?.REGULAR}
                className="full-width"
                popupClassName="picker-with-scroll"
                placeholder={['From Date', 'To Date']}
              />
            </Item>
            <Item label="Size" name="size">
              <SelectComponent
                placeholder="Select Size"
                allowClear
                options={map(SIZE, (size) => ({
                  label: (
                    <div>
                      <span className="mr-16">{size?.label}</span>
                      <Image
                        preview={{
                          visible: false,
                        }}
                        width={20}
                        height={20}
                        src={size?.url}
                        onClick={(e) => {
                          e?.stopPropagation();
                          setVisible(true);
                          setSizeImage(size?.url);
                        }}
                        className="pointer"
                      />
                    </div>
                  ),
                  value: size?.value,
                  labelForFilter: size?.label,
                }))}
                filterOption={(input, option) =>
                  (option?.labelForFilter ?? '')
                    ?.toLowerCase()
                    ?.includes(input?.toLowerCase())
                }
              />
            </Item>
            <Item label="Status" name="status">
              <SelectComponent
                showSearch={false}
                placeholder="Select Status"
                mode="multiple"
                options={map(STATUS_FILTER, (status) => ({
                  label: status?.label,
                  value: status?.key,
                }))}
              />
            </Item>
          </Form>
          <div className="d-flex justify-end">
            <Button htmlType="submit" onClick={handleReset} className="mr-8">
              Reset
            </Button>
            <Button key="submit" type="primary" onClick={form?.submit}>
              Apply
            </Button>
          </div>
        </div>
      </ModalComponent>
    </>
  );
};

export default AllFilterBagReqModal;
