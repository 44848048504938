import { Divider, Form, Input } from 'antd';
import React, { useEffect, useState } from 'react';
import {
  NUMBER_FIELD_TYPES,
  REFUND_REASON,
  USER_ROLES,
} from '../../../../../common/constants';
import { formValidatorRules } from '../../../../../common/utils';
import ModalComponent from '../../../../../components/ModalComponent';
import NumberComponent from '../../../../../components/NumberComponent';
import SelectComponent from '../../../../../components/SelectComponent';
import UserSelect from './UserSelect';

const { Item } = Form;
const { TextArea } = Input;
const { price, whitespaceNotAllowed } = formValidatorRules;

const RefundReqModal = ({
  isModalOpen,
  setIsModalOpen,
  onFinish,
  loadings,
  form,
  outflow,
  setOutflow,
  isEditMode,
}) => {
  const [isValidateTrigger, setIsValidateTrigger] = useState(false);

  const handleCancel = () => {
    setIsModalOpen(false);
    setOutflow({});
  };

  useEffect(() => {
    const initialValues = {
      ...outflow,
      userName: outflow?.user?.id,
    };
    form?.setFieldsValue(initialValues);
  }, [outflow]);

  useEffect(() => {
    if (!isEditMode) {
      form?.resetFields();
      setIsValidateTrigger(false);
      setOutflow();
    }
  }, [isEditMode, isModalOpen]);

  return (
    <>
      <ModalComponent
        open={isModalOpen}
        onCancel={handleCancel}
        onOk={form?.submit}
        title={isEditMode ? 'Edit Refund Request' : 'New Refund Request'}
        okText="Save"
        confirmLoading={loadings}
        destroyOnClose
        forceRender
      >
        <Divider />
        <Form
          form={form}
          onFinish={(values) => {
            onFinish(values);
            setIsValidateTrigger(true);
          }}
          autoComplete="off"
          layout="vertical"
          validateTrigger={isValidateTrigger ? 'onChange' : 'onSubmit'}
          onFinishFailed={() => setIsValidateTrigger(true)}
        >
          <Item
            label="User Name"
            name="userName"
            rules={[{ required: true, message: 'Please Select User Name!' }]}
          >
            <UserSelect
              className="full-width"
              userData={outflow?.user}
              disabled={isEditMode}
              roles={USER_ROLES?.USER?.key}
              isModalOpen={isModalOpen}
            />
          </Item>
          <Item
            label="Reason for Refund"
            name="reason"
            rules={[
              { required: true, message: 'Please Select Reason for Refund!' },
            ]}
          >
            <SelectComponent
              options={REFUND_REASON}
              placeholder="Select Reason"
              filterOption={(input, option) =>
                (option?.label ?? '')
                  ?.toLowerCase()
                  ?.includes(input?.toLowerCase())
              }
            />
          </Item>
          <Item
            name="comment"
            label="Additional Comment"
            rules={[whitespaceNotAllowed]}
          >
            <TextArea
              rows={4}
              allowClear
              placeholder="Enter Additional Comment"
              showCount
              maxLength={700}
            />
          </Item>
          <Item
            label="Amount"
            name="price"
            rules={[{ required: true, message: 'Please Enter Amount!' }, price]}
          >
            <NumberComponent
              allowClear
              placeholder="Enter Amount"
              type={NUMBER_FIELD_TYPES?.PRICE}
            />
          </Item>
        </Form>
      </ModalComponent>
    </>
  );
};

export default RefundReqModal;
