import { useMutation } from '@apollo/client';
import { Button, Card, Form } from 'antd';
import React, { useContext, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { AppContext } from '../../../../../AppContext';
import { ROUTES } from '../../../../../common/constants';
import GoBackButton from '../../../../../components/GoBackButton';
import Portal from '../../../../../components/Portal';
import CouponForm from '../components/CouponForm';
import { CREATE_COUPON } from '../graphql/Mutations';

const CreateCoupon = () => {
  const history = useHistory();
  const [form] = Form.useForm();
  const { dispatch } = useContext(AppContext);
  const [isDisabled, setIsDisabled] = useState(true);
  const [isSubmitLoading, setIsSubmitLoading] = useState(false);

  const [createCoupon] = useMutation(CREATE_COUPON, {
    onError: () => {},
  });

  const createCouponFun = async (details) => {
    setIsSubmitLoading(true);
    dispatch({
      type: 'SET_SHOW_PROMPT',
      data: false,
    });
    const response = await createCoupon({
      variables: {
        data: {
          code: details?.code?.trim(),
          name: details?.name?.trim(),
          type: details?.type,
          value: parseFloat(details?.price),
          commission: details?.commission?.trim(),
          note: details?.note?.trim(),
          description: details?.description?.trim(),
          maxRedemptions: parseInt(details?.redemptions, 10),
          validTo: details?.expiresOn?.endOf('day'),
          validFrom: details?.startsOn,
          libraryId: details?.libraryId,
          metaData: {
            minimumAmount: parseFloat(details?.orderValue),
            product: details?.plan,
            duration: details?.duration,
          },
        },
      },
    });
    if (response?.data) {
      history?.replace(`${ROUTES?.OFFERS}${ROUTES?.COUPON}`);
    }
    setIsSubmitLoading(false);
  };

  const handleShowPrompt = () => {
    setIsDisabled(false);
    dispatch({
      type: 'SET_SHOW_PROMPT',
      data: true,
    });
  };

  return (
    <>
      <Portal portalId="header-title-content">
        <GoBackButton customLink={`${ROUTES?.OFFERS}${ROUTES?.COUPON}`} />
        <div className="portal-header">Add New Coupon</div>
      </Portal>
      <Portal portalId="header-right-content">
        <div className="header-btn-wrapper">
          <Button
            type="primary"
            className="common-button mr-16"
            size="middle"
            onClick={() => history?.push(`${ROUTES?.OFFERS}${ROUTES?.COUPON}`)}
          >
            Cancel
          </Button>
          <Button
            className="common-button"
            size="middle"
            onClick={form.submit}
            loading={isSubmitLoading}
            disabled={isDisabled}
          >
            Save
          </Button>
        </div>
      </Portal>
      <Portal portalId="header-right-content-phones">
        <div className="header-btn-wrapper">
          <Button
            type="primary"
            className="common-button mr-16"
            size="middle"
            onClick={() => history?.push(`${ROUTES?.OFFERS}${ROUTES?.COUPON}`)}
          >
            Cancel
          </Button>
          <Button
            className="common-button"
            size="middle"
            onClick={form.submit}
            loading={isSubmitLoading}
            disabled={isDisabled}
          >
            Save
          </Button>
        </div>
      </Portal>
      <Card className="full-height-with-nav" title="Coupon Details">
        <div className="toy-form">
          <CouponForm
            form={form}
            onFinish={createCouponFun}
            handleShowPrompt={handleShowPrompt}
          />
        </div>
      </Card>
    </>
  );
};

export default CreateCoupon;
