import { Card } from 'antd';
import React from 'react';
import { MODULES } from '../../../common/constants';
import Portal from '../../../components/Portal';
import ExportTable from '../components/ExportTable';

const Exports = () => (
  <>
    <Portal portalId="header-title-content">
      <div className="portal-header">{MODULES?.EXPORTS}</div>
    </Portal>
    <Card className="mobile-with-search-container mobile-card-main">
      <ExportTable />
    </Card>
  </>
);

export default Exports;
