import { PlusOutlined } from '@ant-design/icons';
import { Col, Divider, Form, Input, Select, Upload } from 'antd';
import React, { useEffect, useState } from 'react';
import { GENRES_ORDER } from '../../../../common/constants';
import {
  formValidatorRules,
  getBase64,
  validateImagesGenres,
} from '../../../../common/utils';
import ModalComponent from '../../../../components/ModalComponent';

const { Item } = Form;
const { required } = formValidatorRules;
const { Option } = Select;


const GenresModal = ({
  isModalOpen,
  setIsModalOpen,
  onFinish,
  loadings,
  form,
  genres,
  setGenres,
  genresImages,
  setGenresImages,
  setVisible,
  setGenresPreviewImage,
}) => {
  const [isValidateTrigger, setIsValidateTrigger] = useState(false);

  const handleCancel = () => {
    setIsModalOpen(false);
    setGenres();
    form?.resetFields();
  };

  useEffect(() => {
    form?.setFieldsValue({ name: genres?.name, order: genres?.order });
  }, [genres]);
  const handleImageChange = (info) => {
    validateImagesGenres(info, form, setGenresImages, 'image');
  };

  const handlePreview = async (fileObj) => {
    let preview;
    if (!fileObj?.url && !fileObj?.preview) {
      preview = await getBase64(fileObj?.originFileObj);
    }
    setVisible(true);
    setGenresPreviewImage(fileObj?.url || preview);
  };

  return (
    <>
      <ModalComponent
        open={isModalOpen}
        onCancel={handleCancel}
        onOk={form?.submit}
        title={genres ? 'Edit Genre' : 'Add New Genre'}
        okText="Save"
        confirmLoading={loadings}
        destroyOnClose
        forceRender
      >
        <Divider />
        <Form
          form={form}
          onFinish={(values) => {
            onFinish(values);
            setIsValidateTrigger(true);
          }}
          autoComplete="off"
          layout="vertical"
          validateTrigger={isValidateTrigger ? 'onChange' : 'onSubmit'}
          onFinishFailed={() => setIsValidateTrigger(true)}
        >
          <Item
            label="Name"
            name="name"
            rules={[{ ...required, message: 'Please Enter Name!' }]}
          >
            <Input allowClear placeholder="Enter Name" maxLength={50} />
          </Item>
          <Item
            label="Order"
            name="order"
          >
            <Select placeholder="Select Order" allowClear >
              {
                GENRES_ORDER?.map((option) => (
                  <Option key={option} value={option} >
                    {option}{' '}
                  </Option>
                ))
              }
            </Select>
          </Item>
          <Item label="Image" name="image"
            rules={genresImages?.length !== 1
              ? [{ required: true, message: 'Image Upload is Required!' }]
              : []
            }
          >
            <Upload
              listType="picture-card"
              maxCount={1}
              fileList={genresImages}
              beforeUpload={() => false}
              onPreview={handlePreview}
              onChange={handleImageChange}
              showUploadList={{
                showDownloadIcon: false,
                showPreviewIcon: true,
                showRemoveIcon: true,
              }}
            >
              {genresImages?.length !== 1 && <PlusOutlined />}
            </Upload>
          </Item>
          <Col xs={24} className='notes'>
            <div>Image Notes </div>
            <ul >
              <li>
                Image upload supported formats are JPEG, JPG, PNG
              </li>
              <li>
                The minimum dimensions is 150 * 150 pixels
              </li>
              <li>
                Allow maximum size of 150 KB
              </li>
            </ul>
          </Col>
        </Form>
      </ModalComponent>
    </>
  );
};

export default GenresModal;
