import { gql } from '@apollo/client';

// eslint-disable-next-line import/prefer-default-export
export const PINCODES = gql`
  query Pincodes($filter: PincodesFilter, $sort: PincodesSort) {
    pincodes(filter: $filter, sort: $sort) {
      count
      data {
        id
        areaName
        isActive
        pincode
        createdAt
      }
    }
  }
`;
