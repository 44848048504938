import { FilterFilled, FilterOutlined } from '@ant-design/icons';
import { useLazyQuery } from '@apollo/client';
import { Badge, Button, Checkbox, DatePicker, Divider, Empty, Tag } from 'antd';
import { filter, forEach, isEmpty, map } from 'lodash';
import React, { useContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { AppContext } from '../../../../../AppContext';
import {
  BREAKPOINTS,
  DATE_FORMATS,
  INFLOW_DESCRIPTION,
  INFLOW_PAYMENT_STATUS,
  LIMIT,
  PLAN_NAMES,
  PLAN_NAMES_OPTIONS,
  PLAN_STATUS,
  PLAN_TYPES,
  PLAN_TYPES_OPTIONS,
  ROUTES,
  SORT,
  TRANSACTION_HISTORY_TYPES,
} from '../../../../../common/constants';
import { formatDate } from '../../../../../common/utils';
import CommonTable from '../../../../../components/CommonTable';
import LoaderComponent from '../../../../../components/LoaderComponent';
import SearchComponent from '../../../../../components/SearchComponent';
import { TRANSACTION_HISTORIES_INFLOW } from '../graphql/Queries';
import InvoiceModal from './InvoiceModal';

const { RangePicker } = DatePicker;

const initialPaginationValue = {
  total: 0,
  current: 1,
  pageSize: 10,
};

const initialInflowFilter = {
  limit: 10,
  search: '',
  skip: 0,
};

const initialInflowSort = {
  field: 'createdAt',
  order: 'DESC',
};

const transactionTypes = [
  TRANSACTION_HISTORY_TYPES?.SUBSCRIPTION_PURCHASED?.value,
  TRANSACTION_HISTORY_TYPES?.SUBSCRIPTION_RENEWED?.value,
  TRANSACTION_HISTORY_TYPES?.DELIVERY_CHARGED?.value,
];

const InflowTable = ({ exportFilter, userId, setExportFilter }) => {
  const {
    state: {
      commonPermissions: { isUserPermission },
    },
  } = useContext(AppContext);
  const history = useHistory();
  const [inflowList, setInflowList] = useState([]);
  const [inflowSearchTerm, setInflowSearchTerm] = useState('');
  const [isEmptyInflowList, setIsEmptyInflowList] = useState(false);
  const [inflow, setInflow] = useState({});
  const [query, setQuery] = useState('');
  const [sortedInfo, setSortedInfo] = useState({});
  const [paginationProp, setPaginationProp] = useState(initialPaginationValue);

  const [isInvoiceModalOpen, setIsInvoiceModalOpen] = useState(false);

  const [isInflowLoading, setIsInflowLoading] = useState(true);
  const [filterList, setFilterList] = useState([]);
  const [whereFilter, setWhereFilter] = useState({});
  const [filterLoading, setFilterLoading] = useState(false);
  const [filterTags, setFilterTags] = useState(null);
  const [filterVisible, setFilterVisible] = useState(false);
  const [filterIndex, setFilterIndex] = useState(null);
  const [finalWhereFilters, setFinalWhereFilters] = useState({});
  const [finalFilterTags, setFinalFilterTags] = useState(null);

  const [transactionHistories] = useLazyQuery(TRANSACTION_HISTORIES_INFLOW, {
    onCompleted: (response) => {
      setInflowList([...response?.transactionHistories?.data]);
      if (
        response?.transactionHistories?.count === 0 &&
        initialPaginationValue?.total === 0
      ) {
        setIsEmptyInflowList(true);
      } else {
        setIsEmptyInflowList(false);
      }
      const pagination = {
        ...paginationProp,
        defaultPageSize: LIMIT,
        total: response?.transactionHistories?.count,
      };
      setPaginationProp(pagination);
      setIsInflowLoading(false);
    },
    fetchPolicy: 'network-only',
    onError() {},
  });

  useEffect(() => {
    setPaginationProp({ ...paginationProp, current: 1, skip: 0 });
    setIsInflowLoading(true);
    transactionHistories({
      variables: {
        where: userId && {
          id: userId,
        },
        filter: {
          ...initialInflowFilter,
          limit: paginationProp?.pageSize || LIMIT,
          search: inflowSearchTerm,
          transactionTypes,
        },
        sort: initialInflowSort,
      },
    });
  }, []);

  const handleTableChange = (pagination, tableFilter, sorter) => {
    const { current } = pagination;
    const skip = (current - 1) * (pagination?.pageSize || 0);
    setPaginationProp({ ...paginationProp, ...pagination });
    setIsInflowLoading(true);
    setSortedInfo(sorter);
    transactionHistories({
      variables: {
        where: userId && {
          id: userId,
        },
        filter: {
          ...initialInflowFilter,
          skip,
          limit: pagination?.pageSize,
          search: inflowSearchTerm,
          dateRange: {
            from: finalWhereFilters?.createdAt?.from?.$d,
            to: finalWhereFilters?.createdAt?.to?.$d,
          },
          ...(finalWhereFilters?.subscriptionStatus?.length > 0 && {
            subscriptionStatus: finalWhereFilters?.subscriptionStatus,
          }),
          transactionTypes:
            finalWhereFilters?.description?.flat()?.length > 0
              ? finalWhereFilters?.description?.flat()
              : transactionTypes,
          types:
            finalWhereFilters?.billingCycle?.length > 0
              ? finalWhereFilters?.billingCycle
              : undefined,
          names:
            finalWhereFilters?.planSubscribed?.length > 0
              ? finalWhereFilters?.planSubscribed
              : undefined,
          status:
            finalWhereFilters?.status?.length > 0
              ? finalWhereFilters?.status
              : undefined,
        },
        sort: sorter?.column
          ? {
              field: sorter?.field,
              order: sorter?.order === 'ascend' ? SORT?.ASC : SORT?.DESC,
            }
          : initialInflowSort,
      },
    });
  };

  const handleSearch = (value) => {
    const trimValue = value?.trim();
    setInflowSearchTerm(trimValue);
    setPaginationProp({ ...paginationProp, current: 1, skip: 0 });
    setIsInflowLoading(true);
    transactionHistories({
      variables: {
        where: userId && {
          id: userId,
        },
        filter: {
          ...initialInflowFilter,
          limit: paginationProp?.pageSize || LIMIT,
          search: trimValue,
          ...(finalWhereFilters?.subscriptionStatus?.length > 0 && {
            subscriptionStatus: finalWhereFilters?.subscriptionStatus,
          }),
          dateRange: {
            from: finalWhereFilters?.createdAt?.from?.$d,
            to: finalWhereFilters?.createdAt?.to?.$d,
          },
          status:
            finalWhereFilters?.status?.length > 0
              ? finalWhereFilters?.status
              : undefined,
          transactionTypes:
            finalWhereFilters?.description?.flat()?.length > 0
              ? finalWhereFilters?.description?.flat()
              : transactionTypes,
          types:
            finalWhereFilters?.billingCycle?.length > 0
              ? finalWhereFilters?.billingCycle
              : undefined,
          names:
            finalWhereFilters?.planSubscribed?.length > 0
              ? finalWhereFilters?.planSubscribed
              : undefined,
        },
        sort: sortedInfo?.column
          ? {
              field: sortedInfo?.field,
              order: sortedInfo?.order === 'ascend' ? SORT?.ASC : SORT?.DESC,
            }
          : initialInflowSort,
      },
    });
    if (exportFilter) {
      setExportFilter({ ...exportFilter, search: trimValue });
    }
  };

  const getFilterData = (confirm) => {
    setIsInflowLoading(true);
    transactionHistories({
      variables: {
        where: userId && {
          id: userId,
        },
        filter: {
          ...initialInflowFilter,
          limit: paginationProp?.pageSize || LIMIT,
          search: inflowSearchTerm,
          ...(whereFilter?.subscriptionStatus?.length > 0 && {
            subscriptionStatus: whereFilter?.subscriptionStatus,
          }),
          dateRange: {
            from: whereFilter?.createdAt?.from?.$d,
            to: whereFilter?.createdAt?.to?.$d,
          },
          status:
            whereFilter?.status?.length > 0 ? whereFilter?.status : undefined,
          transactionTypes:
            whereFilter?.description?.flat()?.length > 0
              ? whereFilter?.description?.flat()
              : transactionTypes,
          types:
            whereFilter?.billingCycle?.length > 0
              ? whereFilter?.billingCycle
              : undefined,
          names:
            whereFilter?.planSubscribed?.length > 0
              ? whereFilter?.planSubscribed
              : undefined,
        },
        sort: sortedInfo?.column
          ? {
              field: sortedInfo?.field,
              order: sortedInfo?.order === 'ascend' ? SORT?.ASC : SORT?.DESC,
            }
          : initialInflowSort,
      },
    });
    setFinalWhereFilters({ ...finalWhereFilters, ...whereFilter });
    if (exportFilter) {
      setExportFilter({
        ...exportFilter,
        ...finalWhereFilters,
        ...whereFilter,
      });
    }
    setFinalFilterTags(filterTags);
    setPaginationProp({ ...paginationProp, skip: 0, current: 1 });
    if (confirm) {
      confirm();
    }
  };

  const handleReset = (clearFilters, dataIndex, confirm) => {
    const tagsCopy = {
      ...filterTags,
      [dataIndex]: [],
    };
    const whereFiltersCopy = {
      ...whereFilter,
      [dataIndex]: [],
    };
    setFilterVisible(false);
    setFinalFilterTags({ ...filterTags, ...tagsCopy });
    setFinalWhereFilters({ ...finalWhereFilters, ...whereFiltersCopy });
    if (exportFilter) {
      setExportFilter({
        ...exportFilter,
        ...finalWhereFilters,
        ...whereFiltersCopy,
      });
    }
    setIsInflowLoading(true);
    transactionHistories({
      variables: {
        where: userId && {
          id: userId,
        },
        filter: {
          ...initialInflowFilter,
          limit: paginationProp?.pageSize || LIMIT,
          search: inflowSearchTerm,
          ...(whereFiltersCopy?.subscriptionStatus?.length > 0 && {
            subscriptionStatus: whereFiltersCopy?.subscriptionStatus,
          }),
          dateRange: {
            from: whereFiltersCopy?.createdAt?.from?.$d,
            to: whereFiltersCopy?.createdAt?.to?.$d,
          },
          status:
            whereFiltersCopy?.status?.length > 0
              ? whereFiltersCopy?.status
              : undefined,
          transactionTypes:
            whereFiltersCopy?.description?.flat()?.length > 0
              ? whereFiltersCopy?.description?.flat()
              : transactionTypes,
          types:
            whereFiltersCopy?.billingCycle?.length > 0
              ? whereFiltersCopy?.billingCycle
              : undefined,
          names:
            whereFiltersCopy?.planSubscribed?.length > 0
              ? whereFiltersCopy?.planSubscribed
              : undefined,
        },
        sort: sortedInfo?.column
          ? {
              field: sortedInfo?.field,
              order: sortedInfo?.order === 'ascend' ? SORT?.ASC : SORT?.DESC,
            }
          : initialInflowSort,
      },
    });
    setPaginationProp({ ...paginationProp, skip: 0, current: 1 });
    clearFilters();
    setFilterVisible(false);
    if (confirm) {
      confirm();
    }
  };

  useEffect(() => {
    if (filterVisible) {
      setFilterList([]);
      setFilterLoading(true);
      setFilterTags({ ...filterTags, ...finalFilterTags });
      setWhereFilter({ ...whereFilter, ...finalWhereFilters });
      const optionsCopy = [];

      switch (filterIndex) {
        case 'status':
          forEach(INFLOW_PAYMENT_STATUS, (item) => {
            optionsCopy?.push({ label: item?.text, key: item?.value });
          });
          setFilterList(optionsCopy);
          setFilterLoading(false);
          break;

        case 'subscriptionStatus':
          forEach(PLAN_STATUS, (item) => {
            optionsCopy?.push({ label: item?.text, key: item?.value });
          });
          setFilterList(optionsCopy);
          setFilterLoading(false);
          break;

        case 'description':
          forEach(INFLOW_DESCRIPTION, (item) => {
            optionsCopy?.push({ label: item?.label, key: item?.value });
          });
          setFilterList(optionsCopy);
          setFilterLoading(false);
          break;

        case 'billingCycle':
          forEach(PLAN_TYPES_OPTIONS, (item) => {
            optionsCopy?.push({ label: item?.label, key: item?.value });
          });
          setFilterList(optionsCopy);
          setFilterLoading(false);
          break;

        case 'planSubscribed':
          forEach(PLAN_NAMES_OPTIONS, (item) => {
            optionsCopy?.push({ label: item?.label, key: item?.value });
          });
          setFilterList(optionsCopy);
          setFilterLoading(false);
          break;

        default:
          break;
      }
    }
    if (!filterVisible) {
      setFilterTags(finalFilterTags);
      setWhereFilter(finalWhereFilters);
    }
  }, [filterVisible]);

  const changeFilter = (newFilterObj, dataIndex) => {
    const {
      target: { value },
    } = newFilterObj;

    const filterTagsCopy = { ...filterTags };
    let newTagList;
    if (whereFilter?.[dataIndex]?.includes(value?.key)) {
      newTagList = {
        ...filterTagsCopy,
        [dataIndex]: filter(filterTagsCopy?.[dataIndex], (item) => {
          if (item?.label) {
            return item?.key !== value?.key;
          }
          return item !== value;
        }),
      };
    } else {
      newTagList = {
        ...filterTagsCopy,
        [dataIndex]: filterTagsCopy?.[dataIndex]
          ? [...filterTagsCopy?.[dataIndex], value]
          : [value],
      };
    }

    const updatedWhereFilter = {
      ...whereFilter,
      [dataIndex]: newTagList?.[dataIndex]?.map((item) => item?.key),
    };
    setWhereFilter(updatedWhereFilter);
    setFilterTags(newTagList);
  };

  const handleDeleteFilter = (value, dataIndex) => {
    let newTagList;

    if (whereFilter?.[dataIndex]?.includes(value?.key)) {
      newTagList = {
        ...filterTags,
        [dataIndex]: filter(filterTags?.[dataIndex], (item) => {
          if (item?.key) {
            return item?.key !== value?.key;
          }
          return item !== value;
        }),
      };
    }

    const updatedWhereFilter = {
      ...whereFilter,
      [dataIndex]: newTagList?.[dataIndex]?.map((item) => item?.key),
    };
    setWhereFilter(updatedWhereFilter);
    setFilterTags(newTagList);
  };

  const onRangePickerChange = (values, dataIndex) => {
    const rangeObj = {
      from: values[0]?.startOf('day'),
      to: values?.[1]?.endOf('day'),
    };

    const filtersCopy = {
      ...whereFilter,
      [dataIndex]: rangeObj,
    };
    if (!values?.length) {
      delete whereFilter?.[dataIndex];
    }
    setWhereFilter(filtersCopy);
  };

  const filterPopup = (dataIndex) => ({
    filterDropdown: ({ confirm, clearFilters }) => {
      if (dataIndex === 'createdAt') {
        return (
          <div className="custom-filter-dropdown">
            <RangePicker
              allowClear={false}
              format={DATE_FORMATS?.REGULAR}
              onChange={(values) => onRangePickerChange(values, dataIndex)}
              value={
                whereFilter?.[dataIndex]?.from
                  ? [
                      whereFilter?.[dataIndex]?.from,
                      whereFilter?.[dataIndex]?.to,
                    ]
                  : null
              }
            />
            <Divider className="divider-filter" />
            <div className="d-flex justify-center">
              <Button
                size="small"
                className="reset-button"
                id="roles-filter-reset"
                onClick={() => handleReset(clearFilters, dataIndex, confirm)}
              >
                Reset
              </Button>
              <Button
                size="small"
                className="common-button ok-button"
                id="roles-filter-ok"
                type="primary"
                onClick={() => getFilterData(confirm, dataIndex)}
              >
                Ok
              </Button>
            </div>
          </div>
        );
      }

      return (
        <div className="custom-filter-dropdown">
          <LoaderComponent spinning={filterLoading} setHeight={35}>
            {filterTags?.[dataIndex]?.length > 0 && (
              <div className="filter-tags-wrapper">
                {map(filterTags?.[dataIndex], (item) => (
                  <Tag
                    key={item?.key}
                    closable
                    onClose={() => handleDeleteFilter(item, dataIndex)}
                    className="filter-tag"
                  >
                    <span>{item?.label}</span>
                  </Tag>
                ))}
              </div>
            )}
            <div className="filter-checkboxes">
              {filterList?.length > 0 ? (
                map(filterList, (item) => (
                  <div className="single-checkbox-div" key={item?.key}>
                    <Checkbox
                      value={item}
                      checked={whereFilter?.[dataIndex]?.includes(item?.key)}
                      key={item?.key || item}
                      onChange={(e) => changeFilter(e, dataIndex)}
                      className="single-checkbox"
                    >
                      <span>{item?.label}</span>
                    </Checkbox>
                  </div>
                ))
              ) : (
                <Empty image={Empty?.PRESENTED_IMAGE_SIMPLE} />
              )}
            </div>
          </LoaderComponent>
          <Divider className="divider-filter" />
          <div className="d-flex justify-center">
            <Button
              size="small"
              className="reset-button"
              id="roles-filter-reset"
              onClick={() => handleReset(clearFilters, dataIndex, confirm)}
            >
              Reset
            </Button>
            <Button
              size="small"
              className="common-button ok-button"
              id="roles-filter-ok"
              type="primary"
              onClick={() => getFilterData(confirm, dataIndex)}
            >
              Ok
            </Button>
          </div>
        </div>
      );
    },
    filterIcon: () =>
      finalWhereFilters?.[dataIndex]?.length > 0 ||
      !isEmpty(finalWhereFilters?.[dataIndex]) ? (
        <FilterFilled className="filter-icon filter-filled" />
      ) : (
        <FilterOutlined className="filter-icon" />
      ),
    onFilterDropdownOpenChange: (visible) => {
      setFilterIndex(dataIndex);
      setFilterVisible(visible);
    },
  });

  const columns = [
    {
      title: 'TRANSACTION NUMBER',
      dataIndex: 'transactionNumber',
      key: 'transactionNumber',
      width: 150,
      ellipsis: true,
      align: 'left',
      className: 'max-width-column',
      render: (_, record) => record?.transactionNumber || '-',
      // eslint-disable-next-line no-undef
      fixed: window.innerWidth > BREAKPOINTS.desktop ? 'left' : false,
    },
    {
      title: 'USER NAME',
      dataIndex: 'name',
      key: 'name',
      ellipsis: true,
      width: 250,
      align: 'left',
      className: `max-width-column`,
      onCell: (record) => ({
        onClick: () =>
          isUserPermission &&
          record?.user?.id &&
          history?.push(
            `${ROUTES?.SYSTEM_USERS}${ROUTES?.END_USERS}${ROUTES?.BASIC_DETAILS}/view/${record?.user?.id}/${record?.user?.userNumber}`,
          ),
        className: `${
          isUserPermission && record?.user?.id && 'pointer table-cell'
        }`,
      }),
      render: (_, record) => {
        const userData = record?.user;
        return (
          <>
            {userData?.firstName || userData?.lastName ? (
              <div className="table-data-with-id">{`${
                userData?.firstName ? userData?.firstName : ''
              } ${userData?.lastName ? userData?.lastName : ''}`}</div>
            ) : (
              '-'
            )}
            {userData?.userNumber ? (
              <div className="font-size-12">({userData?.userNumber})</div>
            ) : (
              ''
            )}
          </>
        );
      },
    },
    {
      title: 'GENERATED ON',
      dataIndex: 'generatedOn',
      key: 'generatedOn',
      width: 150,
      ellipsis: true,
      align: 'left',
      className: 'max-width-column',
      render: (_, record) =>
        formatDate(record?.createdAt, DATE_FORMATS?.REGULAR),
      ...filterPopup('createdAt'),
    },
    {
      title: 'DESCRIPTION',
      dataIndex: 'description',
      key: 'description',
      ellipsis: true,
      width: 150,
      align: 'left',
      className: 'max-width-column',
      render: (_, record) => TRANSACTION_HISTORY_TYPES?.[record?.type]?.key,
      ...filterPopup('description'),
    },
    {
      title: 'BILLING CYCLE',
      dataIndex: 'billingCycle',
      key: 'billingCycle',
      ellipsis: true,
      width: 150,
      align: 'left',
      className: 'max-width-column',
      render: (_, record) => {
        const subscription = record?.subscription;
        const planTypeKey =
          PLAN_TYPES?.[subscription?.subscriptionPlan?.type]?.key;
        const planVersion = subscription?.subscriptionFeatures?.planVersion;
        if (!planTypeKey && !planVersion) {
          return '-';
        }
        return `${planTypeKey || ''} ${planVersion ? `(${planVersion})` : ''}`;
      },
      ...filterPopup('billingCycle'),
    },
    {
      title: 'PLAN SUBSCRIBED',
      dataIndex: 'planSubscribed',
      key: 'planSubscribed',
      ellipsis: true,
      width: 150,
      align: 'left',
      className: 'max-width-column',
      render: (_, record) =>
        PLAN_NAMES?.[record?.subscription?.subscriptionPlan?.name]?.key || '-',
      ...filterPopup('planSubscribed'),
    },
    {
      title: 'AMOUNT',
      dataIndex: 'amount',
      key: 'amount',
      width: 150,
      ellipsis: true,
      align: 'left',
      className: 'max-width-column',
      render: (_, record) => {
        const metaData = record?.subscription?.metaData;
        const gst = record?.metaData?.taxesApplied?.percentage
          ? record?.price * (record?.metaData?.taxesApplied?.percentage / 100)
          : 0;
        return `${record?.price + gst} ${
          metaData?.isTrialPeriodExpired === false ? '(Trial)' : ''
        }`;
      },
    },
    {
      title: 'CURRENT PLAN STATUS',
      dataIndex: 'planStatus',
      key: 'planStatus',
      ellipsis: true,
      width: 200,
      align: 'left',
      className: 'max-width-column',
      render: (_, record) =>
        PLAN_STATUS?.[record?.subscription?.status]?.text || '-',
      ...filterPopup('subscriptionStatus'),
    },
    {
      title: 'STATUS',
      dataIndex: 'status',
      key: 'status',
      ellipsis: true,
      width: 200,
      align: 'left',
      className: 'max-width-column',
      render: (_, record) =>
        record?.status ? (
          <Badge
            color={INFLOW_PAYMENT_STATUS?.[record?.status]?.color}
            text={INFLOW_PAYMENT_STATUS?.[record?.status]?.text}
          />
        ) : (
          '-'
        ),
      ...filterPopup('status'),
    },
    {
      title: 'ACTIONS',
      dataIndex: 'actions',
      key: 'actions',
      ellipsis: true,
      width: 150,
      // eslint-disable-next-line no-undef
      fixed: window.innerWidth > BREAKPOINTS?.desktop ? 'right' : false,
      render: (_, record) => (
        <>
          <div className="action-button">
            <Button
              type="link"
              className="common-btn p-0"
              onClick={() => {
                setInflow(record);
                setIsInvoiceModalOpen(true);
              }}
            >
              View Breakdown
            </Button>
          </div>
        </>
      ),
    },
  ];

  const locale = {
    emptyText: isEmptyInflowList ? '' : <span />,
  };

  return (
    <>
      <InvoiceModal
        isModalOpen={isInvoiceModalOpen}
        setIsModalOpen={setIsInvoiceModalOpen}
        inflow={inflow}
      />
      <div className="d-flex justify-end mb-16 flex-wrap">
        <SearchComponent
          className="search-box"
          query={query}
          setQuery={setQuery}
          getData={handleSearch}
        />
      </div>
      <CommonTable
        locale={locale}
        columns={columns}
        data={inflowList || []}
        loading={isInflowLoading}
        onChange={handleTableChange}
        paginationConfig={paginationProp}
        rowKey={(record) => record?.id}
      />
    </>
  );
};

export default InflowTable;
