import { gql } from '@apollo/client';

// eslint-disable-next-line import/prefer-default-export
export const SYNC_SUBSCRIPTION_PLANS = gql`
  mutation SyncSubscriptionPlans {
    syncSubscriptionPlans {
      message
    }
  }
`;

export const UPDATE_SUBSCRIPTION_PLAN = gql`
  mutation UpdateSubscriptionPlan(
    $where: SubscriptionPlanUniqueInput!
    $data: UpdateSubscriptionPlanInput!
  ) {
    updateSubscriptionPlan(where: $where, data: $data) {
      message
    }
  }
`;
