import { useLazyQuery } from '@apollo/client';
import { Empty, Select } from 'antd';
import { debounce, uniqBy } from 'lodash';
import React, { useEffect, useState } from 'react';
import { LIMIT, SORT } from '../../../../../common/constants';
import SelectComponent from '../../../../../components/SelectComponent';
import { AGE_GROUPS } from '../graphql/Queries';

const { Option } = Select;

const AgeGroupSelect = (props) => {
  const { toyDetail, ...rest } = props;
  const [ageGroupList, setAgeGroupList] = useState([]);
  const [ageGroupsSearchTerm, setAgeGroupsSearchTerm] = useState('');
  const [isAllAgeGroupsFetched, setIsAllAgeGroupsFetched] = useState(false);
  const [isAgeGroupsAllowClear, setIsAgeGroupsAllowClear] = useState(false);
  const [isAgeGroupsDropdownVisible, setIsAgeGroupsDropdownVisible] = useState(
    false,
  );
  const [
    isFetchMoreAgeGroupsLoading,
    setIsFetchMoreAgeGroupsLoading,
  ] = useState(false);
  const [isAgeGroupsSelected, setIsAgeGroupsSelected] = useState(false);

  const [ageGroups, { loading: isAgeGroupsLoading }] = useLazyQuery(
    AGE_GROUPS,
    {
      onCompleted: (response) => {
        if (response?.ageGroups?.data?.length < LIMIT) {
          setIsAllAgeGroupsFetched(true);
        }
        setIsFetchMoreAgeGroupsLoading(false);
        if (toyDetail) {
          setAgeGroupList(
            uniqBy(
              [
                ...toyDetail?.ageGroups,
                ...ageGroupList,
                ...response?.ageGroups?.data,
              ],
              'id',
            ),
          );
          return;
        }
        setAgeGroupList([...ageGroupList, ...response?.ageGroups?.data]);
      },
      fetchPolicy: 'network-only',
      onError() {},
    },
  );

  useEffect(() => {
    if (!isAgeGroupsDropdownVisible) {
      setAgeGroupsSearchTerm('');
      setAgeGroupList([]);
      ageGroups({
        variables: {
          filter: {
            limit: LIMIT,
            skip: 0,
            search: ageGroupsSearchTerm,
          },
          sort: {
            field: 'minAge',
            order: SORT?.ASC,
          },
        },
      });
    }
    setIsAllAgeGroupsFetched(false);
  }, [isAgeGroupsDropdownVisible]);

  useEffect(() => {
    if (
      isAgeGroupsSelected &&
      isAgeGroupsDropdownVisible &&
      ageGroupsSearchTerm
    ) {
      setAgeGroupsSearchTerm('');
      setAgeGroupList([]);
      ageGroups({
        variables: {
          filter: {
            limit: LIMIT,
            skip: 0,
            search: ageGroupsSearchTerm,
          },
          sort: {
            field: 'minAge',
            order: SORT?.ASC,
          },
        },
      });
    }
    setIsAllAgeGroupsFetched(false);
  }, [isAgeGroupsSelected, isAgeGroupsDropdownVisible, ageGroupsSearchTerm]);

  const handleAgeGroupsScroll = (event) => {
    if (!isAllAgeGroupsFetched && !isAgeGroupsLoading) {
      const target = event?.target;
      const currentLength = ageGroupList?.length;
      if (
        target?.scrollTop + target?.offsetHeight >=
        target?.scrollHeight - 5
      ) {
        setIsFetchMoreAgeGroupsLoading(true);
        ageGroups({
          variables: {
            filter: {
              limit: LIMIT,
              skip: currentLength,
              search: ageGroupsSearchTerm,
            },
            sort: {
              field: 'minAge',
              order: SORT?.ASC,
            },
          },
        });
      }
    }
  };

  const handleAgeGroupsSearch = (searchTerm) => {
    const trimSearch = searchTerm?.trim();
    setIsAllAgeGroupsFetched(false);
    setAgeGroupList([]);
    setAgeGroupsSearchTerm(trimSearch);
    ageGroups({
      variables: {
        filter: {
          limit: LIMIT,
          skip: 0,
          search: trimSearch,
        },
        sort: {
          field: 'minAge',
          order: SORT?.ASC,
        },
      },
    });
  };

  const handleCategoriesScrollDebounce = debounce(handleAgeGroupsScroll, 500);
  const handleSearchCategoriesDebounce = debounce(handleAgeGroupsSearch, 500);

  useEffect(() => {
    handleCategoriesScrollDebounce?.cancel();
  }, [handleCategoriesScrollDebounce]);

  useEffect(() => {
    handleSearchCategoriesDebounce?.cancel();
  }, [handleSearchCategoriesDebounce]);

  useEffect(() => {
    if (isAgeGroupsLoading) {
      setIsAgeGroupsAllowClear(false);
    } else {
      setIsAgeGroupsAllowClear(true);
    }
  }, [isAgeGroupsLoading]);

  return (
    <SelectComponent
      mode="multiple"
      showSearch={false}
      loading={
        (isAgeGroupsLoading && isAgeGroupsDropdownVisible) ||
        isFetchMoreAgeGroupsLoading
      }
      placeholder="Select Age Groups"
      optionFilterProp="children"
      allowClear={isAgeGroupsAllowClear}
      onPopupScroll={handleCategoriesScrollDebounce}
      onSearch={handleSearchCategoriesDebounce}
      onDropdownVisibleChange={(visible) =>
        setIsAgeGroupsDropdownVisible(visible)
      }
      filterOption={false}
      onChange={() => setIsAgeGroupsAllowClear(true)}
      onSelect={() => setIsAgeGroupsSelected(true)}
      maxTagCount={false}
      notFoundContent={
        !isFetchMoreAgeGroupsLoading && isAgeGroupsLoading ? (
          <span>Loading...</span>
        ) : (
          <Empty image={Empty?.PRESENTED_IMAGE_SIMPLE} />
        )
      }
      {...rest}
    >
      {ageGroupList
        ?.filter?.(
          (ageGroup) => ageGroup?.minAge !== 0 || ageGroup?.maxAge !== 0,
        )
        ?.map((ageGroup) => (
          <Option key={ageGroup?.id} value={ageGroup?.id}>
            {ageGroup?.minAge > 18
              ? `${Math.floor(ageGroup?.minAge / 12)} - ${Math.floor(
                  ageGroup?.maxAge / 12,
                )} Years`
              : `${ageGroup?.minAge} - ${ageGroup?.maxAge} Months`}
          </Option>
        ))}
    </SelectComponent>
  );
};

export default AgeGroupSelect;
