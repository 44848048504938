import { gql } from '@apollo/client';

// eslint-disable-next-line import/prefer-default-export
export const VIDEOS_ADMIN = gql`
  query VideosAdmin($filter: VideosFilter, $sort: VideosSort) {
    videosAdmin(filter: $filter, sort: $sort) {
      count
      data {
        createdAt
        description
        id
        key
        metaData {
          durationInSeconds
          extension
          height
          sizeInMb
          width
        }
        status
        thumbnailKey
        thumbnailUrl
        title
        type
        url
      }
    }
  }
`;

export const GET_VIDEO_THUMBNAIL_SIGNED_PUT_URL = gql`
  query GetVideoThumbnailSignedPutUrl(
    $data: GetVideoThumbnailSignedPutUrlInput!
  ) {
    getVideoThumbnailSignedPutUrl(data: $data) {
      fileName
      signedUrl
    }
  }
`;

export const GET_VIDEO_SIGNED_PUT_URL = gql`
  query GetVideoSignedPutUrl($data: GetVideoSignedPutUrlInput!) {
    getVideoSignedPutUrl(data: $data) {
      fileName
      signedUrl
    }
  }
`;
