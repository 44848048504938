import React, { useContext } from 'react';
import { Route, Switch } from 'react-router-dom';
import { AppContext } from '../../AppContext';
import { ROUTES, USER_ROLES } from '../../common/constants';
import LibrarianProfile from './pages/LibrarianProfile';
import Profile from './pages/Profile';
import './profile.less';

const ProfileWrapper = () => {
  const { getCurrentUser } = useContext(AppContext);
  const { roles } = getCurrentUser() || {};

  return (
    <Switch>
      <Route
        path={ROUTES?.PROFILE}
        exact
        component={
          roles?.includes(USER_ROLES?.LIBRARIAN?.key)
            ? LibrarianProfile
            : Profile
        }
      />
    </Switch>
  );
};

export default ProfileWrapper;
