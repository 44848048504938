import React, { useContext } from 'react';
import { Route, Switch } from 'react-router-dom';
import AccessControl from '../../../../AccessControl';
import { MENU_ITEMS } from '../../../../app/components/sidebar/Sidebar';
import { AppContext } from '../../../../AppContext';
import { MODULES, PERMISSIONS_KEY, ROUTES } from '../../../../common/constants';
import { getRedirectSubmenuItem } from '../../../../common/utils';
import RedirectControl from '../../../../RedirectControl';
import './library.less';
import Library from './pages/Library';
import Referral from './pages/Referral';

const tabs = MENU_ITEMS?.find(
  (menuItem) => menuItem?.key === MODULES?.PAYMENTS,
)?.subMenu?.find((menu) => menu?.key === MODULES?.LIBRARY)?.tabKeys;

const PaymentLibraryWrapper = () => {
  const {
    state: { permissions },
  } = useContext(AppContext);

  const redirectArray = getRedirectSubmenuItem(permissions, tabs);

  return (
    <Switch>
      <Route
        exact
        path={`${ROUTES?.PAYMENTS}${ROUTES?.LIBRARY}`}
        render={() => (
          <RedirectControl
            redirectArray={redirectArray}
            route={`${ROUTES?.PAYMENTS}${ROUTES?.LIBRARY}`}
          />
        )}
      />
      <Route
        exact
        path={`${ROUTES?.PAYMENTS}${ROUTES?.LIBRARY}${ROUTES?.INVOICES}`}
        render={(props) => (
          <AccessControl permissionKey={PERMISSIONS_KEY?.PAYMENTS_LIBRARY}>
            <Library {...props} />
          </AccessControl>
        )}
      />
      <Route
        exact
        path={`${ROUTES?.PAYMENTS}${ROUTES?.LIBRARY}${ROUTES?.REFERRAL}`}
        render={(props) => (
          <AccessControl
            permissionKey={PERMISSIONS_KEY?.PAYMENTS_LIBRARY_REFERRAL}
          >
            <Referral {...props} />
          </AccessControl>
        )}
      />
    </Switch>
  );
};

export default PaymentLibraryWrapper;
