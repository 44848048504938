import { PlusOutlined } from '@ant-design/icons';
import { Button, Card } from 'antd';
import React, { useContext, useState } from 'react';
import { AppContext } from '../../../AppContext';
import {
  MODULES,
  PERMISSION_TYPE,
  PERMISSIONS_KEY,
} from '../../../common/constants';
import { hasPermission } from '../../../common/utils';
import Portal from '../../../components/Portal';
import HomeTable from '../components/HomeTable';

const HomeVideo = () => {
  const {
    state: { permissions },
  } = useContext(AppContext);
  const [isHomeVideoModalOpen, setIsHomeVideoModalOpen] = useState(false);

  return (
    <>
      <Portal portalId="header-title-content">
        <div className="portal-header">{MODULES?.HOME_VIDEO}</div>
      </Portal>
      <Portal portalId="header-right-content">
        {hasPermission(
          PERMISSIONS_KEY?.VIDEOS,
          PERMISSION_TYPE?.CREATE,
          permissions,
        ) && (
          <Button
            className="common-button"
            size="middle"
            icon={<PlusOutlined />}
            onClick={() => setIsHomeVideoModalOpen(true)}
          >
            Add New Video
          </Button>
        )}
      </Portal>
      <Portal portalId="header-right-content-phones">
        {hasPermission(
          PERMISSIONS_KEY?.VIDEOS,
          PERMISSION_TYPE?.CREATE,
          permissions,
        ) && (
          <Button
            className="common-button"
            size="middle"
            icon={<PlusOutlined />}
            onClick={() => setIsHomeVideoModalOpen(true)}
          >
            Add New Video
          </Button>
        )}
      </Portal>
      <Card className="full-height-without-nav">
        <HomeTable
          isHomeVideoModalOpen={isHomeVideoModalOpen}
          setIsHomeVideoModalOpen={setIsHomeVideoModalOpen}
        />
      </Card>
    </>
  );
};

export default HomeVideo;
