import { FilterFilled, FilterOutlined } from '@ant-design/icons';
import { useLazyQuery, useMutation } from '@apollo/client';
import {
  Badge,
  Button,
  Checkbox,
  DatePicker,
  Divider,
  Empty,
  Modal,
  Switch,
  Tag,
  Tooltip,
} from 'antd';
import { filter, forEach, isEmpty, map } from 'lodash';
import React, { useContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { AppContext } from '../../../../../AppContext';
import { ReactComponent as ArchiveIcon } from '../../../../../assets/svg/box-archive-solid.svg';
import { ReactComponent as ApproveIcon } from '../../../../../assets/svg/circle-check-solid.svg';
import { ReactComponent as RejectIcon } from '../../../../../assets/svg/circle-xmark-solid.svg';
import { ReactComponent as UnarchiveIcon } from '../../../../../assets/svg/right-from-bracket-solid.svg';
import {
  ARCHIVING_STATE,
  BREAKPOINTS,
  DATE_FORMATS,
  LIMIT,
  PERMISSIONS_KEY,
  PERMISSION_TYPE,
  REQUEST_STATUS,
  REQUEST_TYPES,
  ROUTES,
  SORT,
  STATUS_FILTER,
} from '../../../../../common/constants';
import { formatDate, hasPermission } from '../../../../../common/utils';
import CommonTable from '../../../../../components/CommonTable';
import LoaderComponent from '../../../../../components/LoaderComponent';
import SearchComponent from '../../../../../components/SearchComponent';
import { UPDATE_REQUEST_ADMIN } from '../../../graphql/Mutations';
import { CHANGE_ADDRESS_REQUESTS_ADMIN } from '../graphql/Queries';

const { RangePicker } = DatePicker;

const initialPaginationValue = {
  total: 0,
  current: 1,
  pageSize: 10,
};

const initialAddressFilter = {
  limit: 10,
  search: '',
  skip: 0,
};

const initialAddressSort = {
  field: 'createdAt',
  order: 'DESC',
};

const AddressTable = ({ exportFilter, setExportFilter }) => {
  const {
    state: {
      permissions,
      commonPermissions: { isUserPermission },
    },
  } = useContext(AppContext);
  const history = useHistory();
  const [addressList, setAddressList] = useState([]);
  const [addressSearchTerm, setAddressSearchTerm] = useState('');
  const [isEmptyAddressList, setIsEmptyAddressList] = useState(false);
  const [address, setAddress] = useState({});
  const [query, setQuery] = useState('');
  const [sortedInfo, setSortedInfo] = useState({});
  const [paginationProp, setPaginationProp] = useState(initialPaginationValue);

  const [isArchiveLoading, setIsArchiveLoading] = useState(false);
  const [isArchivePrompts, setIsArchivePrompts] = useState(false);
  const [isApproveLoading, setIsApproveLoading] = useState(false);
  const [isApprovePrompts, setIsApprovePrompts] = useState(false);
  const [isRejectLoading, setIsRejectLoading] = useState(false);
  const [isRejectPrompts, setIsRejectPrompts] = useState(false);

  const [isAddressLoading, setIsAddressLoading] = useState(true);
  const [isArchiveFilter, setIsArchiveFilter] = useState(false);
  const [filterList, setFilterList] = useState([]);
  const [whereFilter, setWhereFilter] = useState({});
  const [filterLoading, setFilterLoading] = useState(false);
  const [filterTags, setFilterTags] = useState(null);
  const [filterVisible, setFilterVisible] = useState(false);
  const [filterIndex, setFilterIndex] = useState(null);
  const [finalWhereFilters, setFinalWhereFilters] = useState({});
  const [finalFilterTags, setFinalFilterTags] = useState(null);

  const [requestsAdmin] = useLazyQuery(CHANGE_ADDRESS_REQUESTS_ADMIN, {
    onCompleted: (response) => {
      setAddressList([...response?.requestsAdmin?.data]);
      if (
        response?.requestsAdmin?.count === 0 &&
        initialPaginationValue?.total === 0
      ) {
        setIsEmptyAddressList(true);
      } else {
        setIsEmptyAddressList(false);
      }
      const pagination = {
        ...paginationProp,
        defaultPageSize: LIMIT,
        total: response?.requestsAdmin?.count,
      };
      setPaginationProp(pagination);
      setIsAddressLoading(false);
    },
    fetchPolicy: 'network-only',
    onError() {},
  });

  const [updateRequestAdmin] = useMutation(UPDATE_REQUEST_ADMIN, {
    onError: () => {},
  });

  const hasUpdatePermission = hasPermission(
    PERMISSIONS_KEY?.REQUESTS_END_USER_CHANGE_ADDRESS,
    PERMISSION_TYPE?.UPDATE,
    permissions,
  );

  useEffect(() => {
    setPaginationProp({ ...paginationProp, current: 1, skip: 0 });
    setIsAddressLoading(true);
    requestsAdmin({
      variables: {
        filter: {
          ...initialAddressFilter,
          limit: paginationProp?.pageSize || LIMIT,
          search: addressSearchTerm,
          isArchived: isArchiveFilter,
          type: REQUEST_TYPES?.CHANGE_ADDRESS,
          dateRange: {
            from: finalWhereFilters?.createdAt?.from?.$d,
            to: finalWhereFilters?.createdAt?.to?.$d,
          },
          status:
            finalWhereFilters?.status?.length > 0
              ? finalWhereFilters?.status
              : undefined,
        },
        sort: sortedInfo?.column
          ? {
              field: sortedInfo?.field,
              order: sortedInfo?.order === 'ascend' ? SORT?.ASC : SORT?.DESC,
            }
          : initialAddressSort,
      },
    });
    setExportFilter({ ...exportFilter, isArchived: isArchiveFilter });
  }, [isArchiveFilter]);

  const handleTableChange = (pagination, tableFilter, sorter) => {
    const { current } = pagination;
    const skip = (current - 1) * (pagination?.pageSize || 0);
    setPaginationProp({ ...paginationProp, ...pagination });
    setIsAddressLoading(true);
    setSortedInfo(sorter);
    requestsAdmin({
      variables: {
        filter: {
          ...initialAddressFilter,
          skip,
          limit: pagination?.pageSize,
          search: addressSearchTerm,
          isArchived: isArchiveFilter,
          type: REQUEST_TYPES?.CHANGE_ADDRESS,
          dateRange: {
            from: finalWhereFilters?.createdAt?.from?.$d,
            to: finalWhereFilters?.createdAt?.to?.$d,
          },
          status:
            finalWhereFilters?.status?.length > 0
              ? finalWhereFilters?.status
              : undefined,
        },
        sort: sorter?.column
          ? {
              field: sorter?.field,
              order: sorter?.order === 'ascend' ? SORT?.ASC : SORT?.DESC,
            }
          : initialAddressSort,
      },
    });
  };

  const handleSearch = (value) => {
    const trimValue = value?.trim();
    setAddressSearchTerm(trimValue);
    setPaginationProp({ ...paginationProp, current: 1, skip: 0 });
    setIsAddressLoading(true);
    requestsAdmin({
      variables: {
        filter: {
          ...initialAddressFilter,
          limit: paginationProp?.pageSize || LIMIT,
          search: trimValue,
          isArchived: isArchiveFilter,
          type: REQUEST_TYPES?.CHANGE_ADDRESS,
          dateRange: {
            from: finalWhereFilters?.createdAt?.from?.$d,
            to: finalWhereFilters?.createdAt?.to?.$d,
          },
          status:
            finalWhereFilters?.status?.length > 0
              ? finalWhereFilters?.status
              : undefined,
        },
        sort: sortedInfo?.column
          ? {
              field: sortedInfo?.field,
              order: sortedInfo?.order === 'ascend' ? SORT?.ASC : SORT?.DESC,
            }
          : initialAddressSort,
      },
    });
    setExportFilter({ ...exportFilter, search: trimValue });
  };

  const handleArchive = async (id, isArchived) => {
    setIsArchiveLoading(true);
    const response = await updateRequestAdmin({
      variables: {
        where: {
          id,
        },
        data: {
          isArchived: !isArchived,
        },
      },
    });
    if (response?.data) {
      setIsArchiveLoading(false);
      setIsAddressLoading(true);
      setIsArchivePrompts(false);
      requestsAdmin({
        variables: {
          filter: {
            ...initialAddressFilter,
            skip:
              (paginationProp?.current - 1) * (paginationProp?.pageSize || 0),
            limit: paginationProp?.pageSize || LIMIT,
            search: addressSearchTerm,
            isArchived: isArchiveFilter,
            type: REQUEST_TYPES?.CHANGE_ADDRESS,
            dateRange: {
              from: finalWhereFilters?.createdAt?.from?.$d,
              to: finalWhereFilters?.createdAt?.to?.$d,
            },
            status:
              finalWhereFilters?.status?.length > 0
                ? finalWhereFilters?.status
                : undefined,
          },
          sort: sortedInfo?.column
            ? {
                field: sortedInfo?.field,
                order: sortedInfo?.order === 'ascend' ? SORT?.ASC : SORT?.DESC,
              }
            : initialAddressSort,
        },
      });
    }
    setIsArchiveLoading(false);
  };

  const handleApprove = async (id) => {
    setIsApproveLoading(true);
    const response = await updateRequestAdmin({
      variables: {
        where: {
          id,
        },
        data: {
          status: REQUEST_STATUS?.APPROVED?.value,
        },
      },
    });
    if (response?.data) {
      setIsAddressLoading(true);
      setIsApprovePrompts(false);
      requestsAdmin({
        variables: {
          filter: {
            ...initialAddressFilter,
            skip:
              (paginationProp?.current - 1) * (paginationProp?.pageSize || 0),
            limit: paginationProp?.pageSize || LIMIT,
            search: addressSearchTerm,
            isArchived: isArchiveFilter,
            type: REQUEST_TYPES?.CHANGE_ADDRESS,
            dateRange: {
              from: finalWhereFilters?.createdAt?.from?.$d,
              to: finalWhereFilters?.createdAt?.to?.$d,
            },
            status:
              finalWhereFilters?.status?.length > 0
                ? finalWhereFilters?.status
                : undefined,
          },
          sort: sortedInfo?.column
            ? {
                field: sortedInfo?.field,
                order: sortedInfo?.order === 'ascend' ? SORT?.ASC : SORT?.DESC,
              }
            : initialAddressSort,
        },
      });
    }
    setIsApproveLoading(false);
  };

  const handleReject = async (id) => {
    setIsRejectLoading(true);
    const response = await updateRequestAdmin({
      variables: {
        where: {
          id,
        },
        data: {
          status: REQUEST_STATUS?.REJECTED?.value,
        },
      },
    });
    if (response?.data) {
      setIsAddressLoading(true);
      setIsRejectPrompts(false);
      requestsAdmin({
        variables: {
          filter: {
            ...initialAddressFilter,
            skip:
              (paginationProp?.current - 1) * (paginationProp?.pageSize || 0),
            limit: paginationProp?.pageSize || LIMIT,
            search: addressSearchTerm,
            isArchived: isArchiveFilter,
            type: REQUEST_TYPES?.CHANGE_ADDRESS,
            dateRange: {
              from: finalWhereFilters?.createdAt?.from?.$d,
              to: finalWhereFilters?.createdAt?.to?.$d,
            },
            status:
              finalWhereFilters?.status?.length > 0
                ? finalWhereFilters?.status
                : undefined,
          },
          sort: sortedInfo?.column
            ? {
                field: sortedInfo?.field,
                order: sortedInfo?.order === 'ascend' ? SORT?.ASC : SORT?.DESC,
              }
            : initialAddressSort,
        },
      });
    }
    setIsRejectLoading(false);
  };

  const getFilterData = (confirm) => {
    setIsAddressLoading(true);
    requestsAdmin({
      variables: {
        filter: {
          ...initialAddressFilter,
          limit: paginationProp?.pageSize || LIMIT,
          search: addressSearchTerm,
          isArchived: isArchiveFilter,
          type: REQUEST_TYPES?.CHANGE_ADDRESS,
          dateRange: {
            from: whereFilter?.createdAt?.from?.$d,
            to: whereFilter?.createdAt?.to?.$d,
          },
          status:
            whereFilter?.status?.length > 0 ? whereFilter?.status : undefined,
        },
        sort: sortedInfo?.column
          ? {
              field: sortedInfo?.field,
              order: sortedInfo?.order === 'ascend' ? SORT?.ASC : SORT?.DESC,
            }
          : initialAddressSort,
      },
    });
    setFinalWhereFilters({ ...finalWhereFilters, ...whereFilter });
    setExportFilter({ ...exportFilter, ...finalWhereFilters, ...whereFilter });
    setFinalFilterTags(filterTags);
    setPaginationProp({ ...paginationProp, skip: 0, current: 1 });
    if (confirm) {
      confirm();
    }
  };

  const handleReset = (clearFilters, dataIndex, confirm) => {
    const tagsCopy = {
      ...filterTags,
      [dataIndex]: [],
    };
    const whereFiltersCopy = {
      ...whereFilter,
      [dataIndex]: [],
    };
    setFilterVisible(false);
    setFinalFilterTags({ ...filterTags, ...tagsCopy });
    setFinalWhereFilters({ ...finalWhereFilters, ...whereFiltersCopy });
    setExportFilter({
      ...exportFilter,
      ...finalWhereFilters,
      ...whereFiltersCopy,
    });
    setIsAddressLoading(true);
    requestsAdmin({
      variables: {
        filter: {
          ...initialAddressFilter,
          limit: paginationProp?.pageSize || LIMIT,
          search: addressSearchTerm,
          isArchived: isArchiveFilter,
          type: REQUEST_TYPES?.CHANGE_ADDRESS,
          dateRange: {
            from: whereFiltersCopy?.createdAt?.from?.$d,
            to: whereFiltersCopy?.createdAt?.to?.$d,
          },
          status:
            whereFiltersCopy?.status?.length > 0
              ? whereFiltersCopy?.status
              : undefined,
        },
        sort: sortedInfo?.column
          ? {
              field: sortedInfo?.field,
              order: sortedInfo?.order === 'ascend' ? SORT?.ASC : SORT?.DESC,
            }
          : initialAddressSort,
      },
    });
    setPaginationProp({ ...paginationProp, skip: 0, current: 1 });
    clearFilters();
    setFilterVisible(false);
    if (confirm) {
      confirm();
    }
  };

  useEffect(() => {
    if (filterVisible) {
      setFilterList([]);
      setFilterLoading(true);
      setFilterTags({ ...filterTags, ...finalFilterTags });
      setWhereFilter({ ...whereFilter, ...finalWhereFilters });
      const optionsCopy = [];

      switch (filterIndex) {
        case 'status':
          forEach(STATUS_FILTER, (item) => {
            optionsCopy?.push({ label: item?.label, key: item?.key });
          });
          setFilterList(optionsCopy);
          setFilterLoading(false);
          break;

        default:
          break;
      }
    }
    if (!filterVisible) {
      setFilterTags(finalFilterTags);
      setWhereFilter(finalWhereFilters);
    }
  }, [filterVisible]);

  const changeFilter = (newFilterObj, dataIndex) => {
    const {
      target: { value },
    } = newFilterObj;

    const filterTagsCopy = { ...filterTags };
    let newTagList;
    if (whereFilter?.[dataIndex]?.includes(value?.key)) {
      newTagList = {
        ...filterTagsCopy,
        [dataIndex]: filter(filterTagsCopy?.[dataIndex], (item) => {
          if (item?.label) {
            return item?.key !== value?.key;
          }
          return item !== value;
        }),
      };
    } else {
      newTagList = {
        ...filterTagsCopy,
        [dataIndex]: filterTagsCopy?.[dataIndex]
          ? [...filterTagsCopy?.[dataIndex], value]
          : [value],
      };
    }

    const updatedWhereFilter = {
      ...whereFilter,
      [dataIndex]: newTagList?.[dataIndex]?.map((item) => item?.key),
    };
    setWhereFilter(updatedWhereFilter);
    setFilterTags(newTagList);
  };

  const handleDeleteFilter = (value, dataIndex) => {
    let newTagList;

    if (whereFilter?.[dataIndex]?.includes(value?.key)) {
      newTagList = {
        ...filterTags,
        [dataIndex]: filter(filterTags?.[dataIndex], (item) => {
          if (item?.key) {
            return item?.key !== value?.key;
          }
          return item !== value;
        }),
      };
    }

    const updatedWhereFilter = {
      ...whereFilter,
      [dataIndex]: newTagList?.[dataIndex]?.map((item) => item?.key),
    };
    setWhereFilter(updatedWhereFilter);
    setFilterTags(newTagList);
  };

  const onRangePickerChange = (values, dataIndex) => {
    const rangeObj = {
      from: values[0]?.startOf('day'),
      to: values?.[1]?.endOf('day'),
    };

    const filtersCopy = {
      ...whereFilter,
      [dataIndex]: rangeObj,
    };
    if (!values?.length) {
      delete whereFilter?.[dataIndex];
    }
    setWhereFilter(filtersCopy);
  };

  const filterPopup = (dataIndex) => ({
    filterDropdown: ({ confirm, clearFilters }) => {
      if (dataIndex === 'createdAt') {
        return (
          <div className="custom-filter-dropdown">
            <RangePicker
              allowClear={false}
              format={DATE_FORMATS?.REGULAR}
              onChange={(values) => onRangePickerChange(values, dataIndex)}
              value={
                whereFilter?.[dataIndex]?.from
                  ? [
                      whereFilter?.[dataIndex]?.from,
                      whereFilter?.[dataIndex]?.to,
                    ]
                  : null
              }
            />
            <Divider className="divider-filter" />
            <div className="d-flex justify-center">
              <Button
                size="small"
                className="reset-button"
                id="roles-filter-reset"
                onClick={() => handleReset(clearFilters, dataIndex, confirm)}
              >
                Reset
              </Button>
              <Button
                size="small"
                className="common-button ok-button"
                id="roles-filter-ok"
                type="primary"
                onClick={() => getFilterData(confirm, dataIndex)}
              >
                Ok
              </Button>
            </div>
          </div>
        );
      }

      return (
        <div className="custom-filter-dropdown">
          <LoaderComponent spinning={filterLoading} setHeight={35}>
            {filterTags?.[dataIndex]?.length > 0 && (
              <div className="filter-tags-wrapper">
                {map(filterTags?.[dataIndex], (item) => (
                  <Tag
                    key={item?.key}
                    closable
                    onClose={() => handleDeleteFilter(item, dataIndex)}
                    className="filter-tag"
                  >
                    <span>{item?.label}</span>
                  </Tag>
                ))}
              </div>
            )}
            <div className="filter-checkboxes">
              {filterList?.length > 0 ? (
                map(filterList, (item) => (
                  <div className="single-checkbox-div" key={item?.key}>
                    <Checkbox
                      value={item}
                      checked={whereFilter?.[dataIndex]?.includes(item?.key)}
                      key={item?.key || item}
                      onChange={(e) => changeFilter(e, dataIndex)}
                      className="single-checkbox"
                    >
                      <span>{item?.label}</span>
                    </Checkbox>
                  </div>
                ))
              ) : (
                <Empty image={Empty?.PRESENTED_IMAGE_SIMPLE} />
              )}
            </div>
          </LoaderComponent>
          <Divider className="divider-filter" />
          <div className="d-flex justify-center">
            <Button
              size="small"
              className="reset-button"
              id="roles-filter-reset"
              onClick={() => handleReset(clearFilters, dataIndex, confirm)}
            >
              Reset
            </Button>
            <Button
              size="small"
              className="common-button ok-button"
              id="roles-filter-ok"
              type="primary"
              onClick={() => getFilterData(confirm, dataIndex)}
            >
              Ok
            </Button>
          </div>
        </div>
      );
    },
    filterIcon: () =>
      finalWhereFilters?.[dataIndex]?.length > 0 ||
      !isEmpty(finalWhereFilters?.[dataIndex]) ? (
        <FilterFilled className="filter-icon filter-filled" />
      ) : (
        <FilterOutlined className="filter-icon" />
      ),
    onFilterDropdownOpenChange: (visible) => {
      setFilterIndex(dataIndex);
      setFilterVisible(visible);
    },
  });

  const columns = [
    {
      title: 'USER NAME',
      dataIndex: 'name',
      key: 'name',
      ellipsis: true,
      sorter: true,
      width: 250,
      align: 'left',
      className: `max-width-column`,
      // eslint-disable-next-line no-undef
      fixed: window.innerWidth > BREAKPOINTS.desktop ? 'left' : false,
      sortOrder: sortedInfo?.columnKey === 'name' && sortedInfo?.order,
      onCell: (record) => ({
        onClick: () =>
          isUserPermission &&
          record?.createdBy?.id &&
          history?.push(
            `${ROUTES?.SYSTEM_USERS}${ROUTES?.END_USERS}${ROUTES?.BASIC_DETAILS}/view/${record?.createdBy?.id}/${record?.createdBy?.userNumber}`,
          ),
        className: `${
          isUserPermission && record?.createdBy?.id && 'pointer table-cell'
        }`,
      }),
      render: (_, record) => {
        const userData = record?.createdBy;
        return (
          <>
            {userData?.firstName || userData?.lastName ? (
              <div className="table-data-with-id">{`${
                userData?.firstName ? userData?.firstName : ''
              } ${userData?.lastName ? userData?.lastName : ''}`}</div>
            ) : (
              '-'
            )}
            {userData?.userNumber ? (
              <div className="font-size-12">({userData?.userNumber})</div>
            ) : (
              ''
            )}
          </>
        );
      },
    },
    {
      title: 'REQUEST ID',
      dataIndex: 'id',
      key: 'id',
      ellipsis: true,
      width: 170,
      align: 'left',
      className: 'max-width-column',
      render: (_, record) => record?.requestNumber,
    },
    {
      title: 'PREVIOUS ADDRESS',
      dataIndex: 'prevAddress',
      key: 'prevAddress',
      ellipsis: true,
      width: 250,
      align: 'left',
      className: 'max-width-column table-tooltip-cell',
      render: (_, record) => {
        const createdBy = record?.createdBy;
        const previousAddress = record?.metaData?.previousAddress;
        const isCreatedByAddress = createdBy?.address
          ? `${createdBy?.address}, ${createdBy?.landmark}, ${createdBy?.city}, ${createdBy?.state} - ${createdBy?.pincode?.pincode}`
          : '-';
        const fullPreviousAddress = previousAddress?.address
          ? `${previousAddress?.address}, ${previousAddress?.landmark}, ${previousAddress?.city}, ${previousAddress?.state} - ${previousAddress?.pincode}`
          : isCreatedByAddress;
        return (
          <Tooltip
            title={fullPreviousAddress}
            placement="topLeft"
            trigger="click"
            className="pointer"
          >
            {fullPreviousAddress}
          </Tooltip>
        );
      },
    },
    {
      title: 'NEW ADDRESS',
      dataIndex: 'newAddress',
      key: 'newAddress',
      ellipsis: true,
      width: 250,
      align: 'left',
      className: 'max-width-column table-tooltip-cell',
      render: (_, record) => {
        const metaData = record?.metaData;
        const fullNewAddress = metaData?.address
          ? `${metaData?.address}, ${metaData?.landmark}, ${metaData?.city}, ${metaData?.state} - ${metaData?.pincode}`
          : '-';
        return (
          <Tooltip
            title={fullNewAddress}
            placement="topLeft"
            trigger="click"
            className="pointer"
          >
            {fullNewAddress}
          </Tooltip>
        );
      },
    },
    {
      title: 'REQUEST DATE',
      dataIndex: 'requestDate',
      key: 'requestDate',
      ellipsis: true,
      width: 150,
      align: 'left',
      className: 'max-width-column',
      render: (_, record) =>
        formatDate(record?.createdAt, DATE_FORMATS?.REGULAR) || '-',
      ...filterPopup('createdAt'),
    },
    {
      title: 'STATUS',
      dataIndex: 'status',
      key: 'status',
      ellipsis: true,
      width: 150,
      align: 'left',
      className: 'max-width-column',
      render: (_, record) => (
        <Badge
          color={REQUEST_STATUS?.[record?.status]?.color}
          text={REQUEST_STATUS?.[record?.status]?.text}
        />
      ),
      ...filterPopup('status'),
    },
    {
      title: 'ACTIONS',
      dataIndex: 'actions',
      key: 'actions',
      ellipsis: true,
      width: 150,
      // eslint-disable-next-line no-undef
      fixed: window.innerWidth > BREAKPOINTS.desktop ? 'right' : false,
      render: (_, record) => (
        <>
          <div className="action-button">
            {isArchiveFilter ? (
              <Tooltip title="Unarchive" placement="top" zIndex={4}>
                <UnarchiveIcon
                  onClick={() => {
                    setIsArchivePrompts(true);
                    setAddress(record);
                  }}
                  className="mr-16 pointer svg-icon"
                />
              </Tooltip>
            ) : (
              <>
                <div className="mr-16 d-flex">
                  <Tooltip
                    title="Approve"
                    placement="top"
                    className="table-action-button"
                    zIndex={4}
                  >
                    <Button
                      disabled={
                        record?.status !== REQUEST_STATUS?.PENDING?.value
                      }
                      type="link"
                      onClick={() => {
                        setIsApprovePrompts(true);
                        setAddress(record);
                      }}
                    >
                      <ApproveIcon
                        className={
                          record?.status !== REQUEST_STATUS?.PENDING?.value
                            ? 'svg-icon-grey'
                            : 'svg-icon'
                        }
                      />
                    </Button>
                  </Tooltip>
                </div>
                <div className="mr-16 d-flex">
                  <Tooltip
                    title="Reject"
                    placement="top"
                    className="table-action-button"
                    zIndex={4}
                  >
                    <Button
                      disabled={
                        record?.status !== REQUEST_STATUS?.PENDING?.value
                      }
                      type="link"
                      onClick={() => {
                        setIsRejectPrompts(true);
                        setAddress(record);
                      }}
                    >
                      <RejectIcon
                        className={
                          record?.status !== REQUEST_STATUS?.PENDING?.value
                            ? 'svg-icon-grey'
                            : 'svg-icon'
                        }
                      />
                    </Button>
                  </Tooltip>
                </div>
                <Tooltip title="Archive" placement="top" zIndex={4}>
                  <ArchiveIcon
                    onClick={() => {
                      setIsArchivePrompts(true);
                      setAddress(record);
                    }}
                    className="mr-16 pointer svg-icon"
                  />
                </Tooltip>
              </>
            )}
          </div>
        </>
      ),
    },
  ]?.filter((item) => {
    if (item?.dataIndex === 'actions' && !hasUpdatePermission) {
      return item?.hidden;
    }
    return !item?.hidden;
  });

  const locale = {
    emptyText: isEmptyAddressList ? '' : <span />,
  };

  return (
    <>
      <Modal
        title="Caution"
        okText="Yes"
        cancelText="No"
        open={isArchivePrompts}
        onOk={() => handleArchive(address?.id, address?.isArchived)}
        onCancel={() => setIsArchivePrompts(false)}
        okButtonProps={{ loading: isArchiveLoading }}
      >
        Are you sure you want to
        <strong>
          {' '}
          {!address?.isArchived
            ? ARCHIVING_STATE?.ARCHIVED
            : ARCHIVING_STATE?.UNARCHIVED}{' '}
        </strong>
        this request?
      </Modal>
      <Modal
        title="Caution"
        okText="Yes"
        cancelText="No"
        open={isApprovePrompts}
        onOk={() => handleApprove(address?.id)}
        onCancel={() => setIsApprovePrompts(false)}
        okButtonProps={{ loading: isApproveLoading }}
      >
        Are you sure you want to
        <strong> Approve </strong>
        this request?
      </Modal>
      <Modal
        title="Caution"
        okText="Yes"
        cancelText="No"
        open={isRejectPrompts}
        onOk={() => handleReject(address?.id)}
        onCancel={() => setIsRejectPrompts(false)}
        okButtonProps={{ loading: isRejectLoading }}
      >
        Are you sure you want to
        <strong> Reject </strong>
        this request?
      </Modal>
      <div className="d-flex justify-end mb-16 flex-wrap">
        {hasUpdatePermission && (
          <div className="mr-16 d-flex align-center">
            <h4 className="m-0 mr-8">Only Archived</h4>{' '}
            <Switch
              size="small"
              onChange={(checked) => setIsArchiveFilter(checked)}
            />
          </div>
        )}
        <SearchComponent
          className="search-box"
          query={query}
          setQuery={setQuery}
          getData={handleSearch}
        />
      </div>
      <CommonTable
        locale={locale}
        columns={columns}
        data={addressList || []}
        loading={isAddressLoading}
        onChange={handleTableChange}
        paginationConfig={paginationProp}
        rowKey={(record) => record?.id}
      />
    </>
  );
};

export default AddressTable;
