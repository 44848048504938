import { Image } from 'antd';
import React from 'react';

const CommonPreviewTable = ({ visible, setVisible, images, setImages }) => (
  <div className="d-none">
    <Image.PreviewGroup
      preview={{
        visible,
        onVisibleChange: (vis) => {
          setVisible(vis);
          setImages([]);
        },
      }}
    >
      {images?.map((image) => (
        <Image width={20} src={image.url} key={image?.key} />
      ))}
    </Image.PreviewGroup>
  </div>
);

export default CommonPreviewTable;
