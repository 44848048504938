import { gql } from '@apollo/client';

// eslint-disable-next-line import/prefer-default-export
export const CHANGE_ADDRESS_REQUESTS_ADMIN = gql`
  query RequestsAdmin($filter: RequestsAdminFilterInput, $sort: RequestsSort) {
    requestsAdmin(filter: $filter, sort: $sort) {
      count
      data {
        id
        isArchived
        metaData {
          ... on ChangeAddress {
            address
            city
            landmark
            pincode
            state
            previousAddress {
              address
              city
              landmark
              pincode
              state
            }
          }
        }
        createdBy {
          id
          address
          city
          state
          landmark
          pincode {
            pincode
          }
          firstName
          lastName
          userNumber
        }
        status
        createdAt
        isArchived
        requestNumber
      }
    }
  }
`;

export const SUGGESTED_TOY_REQUESTS_ADMIN = gql`
  query RequestsAdmin($filter: RequestsAdminFilterInput, $sort: RequestsSort) {
    requestsAdmin(filter: $filter, sort: $sort) {
      count
      data {
        createdBy {
          id
          firstName
          lastName
          userNumber
        }
        id
        createdAt
        description
        requestNumber
        metaData {
          ... on SuggestToy {
            notes
          }
        }
      }
    }
  }
`;

export const USER_DAMAGE_REQUESTS_ADMIN = gql`
  query RequestsAdmin($filter: RequestsAdminFilterInput, $sort: RequestsSort) {
    requestsAdmin(filter: $filter, sort: $sort) {
      count
      data {
        videoUrl
        createdBy {
          firstName
          lastName
          id
          userNumber
        }
        images {
          key
          url
        }
        orderItemDetails {
          toy {
            id
            toyNumber
            name
          }
          order {
            deliveryPartner {
              firstName
              lastName
              id
              userNumber
            }
          }
        }
        createdAt
        id
        description
        requestNumber
        type
        status
        metaData {
          ... on UserDamage {
            comment
            orderItemId
          }
        }
      }
    }
  }
`;
