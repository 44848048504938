import { gql } from '@apollo/client';

// eslint-disable-next-line import/prefer-default-export
export const GENRES_ADMIN = gql`
  query GenresAdmin($filter: GenreFilter, $sort: GenreSort) {
    genresAdmin(filter: $filter, sort: $sort) {
      count
      data {
        id
        image
        imageUuid
        publish
        order
        createdAt
        isArchived
        name
      }
    }
  }
`;

export const GET_GENRE_IMAGE_SIGNED_PUT_URL = gql`
  query GetGenreImageSignedPutUrl($data: GetGenresImageSignedPutUrlInput!) {
    getGenreImageSignedPutUrl(data: $data) {
      fileName
      signedUrl
    }
  }
`;

export const TOYS_FOR_GENRES = gql`
 query ToysForGenres($where: GenreUniqueInput!, $filter:ToysFilter, $sort: ToysSort) {
  toysForGenres(where: $where,filter: $filter, sort:$sort) {
    data {
      id
      name
    }
  }
}
`;
export const TOY_REMOVE = gql`
   query Toys($filter: ToysFilter, $sort: ToysSort) {
    toys(filter: $filter, sort: $sort) {
     data {
    name
    id
}
    }
}
`
export const TOY = gql`
  query toy($where: ToyUniqueInput!) {
    toy(where: $where) {
      name
      imageUuid
      createdAt
      isArchived
      price
      url
      toyNumber
      material {
        name
        id
      }
      images {
        url
        size
        order
        key
        contentType
      }
      id
      description
      shortDescription
      eanNumber
      categories {
        id
        name
      }
      tags {
        id
        name
      }
      facilitates {
        id
        name
      }
      ageGroups {
        maxAge
        minAge
        id
      }
      brand {
        name
        id
      }
      videoUrl
      includedContents {
        id
        quantity
        value
        key
      }
      excludedContents {
        id
        quantity
        key
        value
      }
    }
  }
`;