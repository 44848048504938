import { Button, Divider, Form } from 'antd';
import React, { useEffect } from 'react';
import ModalComponent from '../../../components/ModalComponent';
import CategorySelect from '../../../modules/lists/modules/toy/components/CategorySelect';

const { Item } = Form;

const AllFilterBulkUpdateLogModal = ({
  isModalOpen,
  setIsModalOpen,
  onFinish,
  finalWhereFilters,
  setFinalWhereFilters,
}) => {
  const [form] = Form.useForm();

  const handleReset = () => {
    setIsModalOpen(false);
    form?.resetFields();
    setFinalWhereFilters();
    form?.submit();
  };

  useEffect(() => {
    form?.setFieldsValue({
      ...finalWhereFilters,
    });
  }, [isModalOpen]);

  return (
    <>
      <ModalComponent
        open={isModalOpen}
        onCancel={() => setIsModalOpen(false)}
        title="Filter"
        forceRender
        destroyOnClose
        footer={null}
      >
        <Divider />
        <div className="mb-16">
          <Form
            form={form}
            onFinish={onFinish}
            autoComplete="off"
            layout="vertical"
          >
            <Item label="Categories" name="category">
              <CategorySelect />
            </Item>
          </Form>
          <div className="d-flex justify-end">
            <Button htmlType="submit" onClick={handleReset} className="mr-8">
              Reset
            </Button>
            <Button key="submit" type="primary" onClick={form?.submit}>
              Apply
            </Button>
          </div>
        </div>
      </ModalComponent>
    </>
  );
};

export default AllFilterBulkUpdateLogModal;
