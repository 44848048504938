import React from 'react';
import { Route, Switch } from 'react-router-dom';
import AccessControl from '../../../../AccessControl';
import Error404 from '../../../../Error404';
import { PERMISSIONS_KEY, ROUTES } from '../../../../common/constants';
import RackMaster from './pages/RackMaster';

const RackMasterWrapper = () => (
  <Switch>
    <Route
      exact
      path={`${ROUTES?.MASTER_LISTS}${ROUTES?.RACK_MASTER}`}
      render={(props) => (
        <AccessControl permissionKey={PERMISSIONS_KEY?.MASTER_LIST_RACK}>
          <RackMaster {...props} />
        </AccessControl>
      )}
    />
    <Route path="*" exact component={Error404} />
  </Switch>
);

export default RackMasterWrapper;
