import { gql } from '@apollo/client';

// eslint-disable-next-line import/prefer-default-export
export const CREATE_BANNER = gql`
  mutation CreateBanner($data: CreateBannerInput!) {
    createBanner(data: $data) {
      message
    }
  }
`;

export const UPDATE_BANNER = gql`
  mutation UpdateBanner($where: BannerUniqueInput!, $data: UpdateBannerInput) {
    updateBanner(where: $where, data: $data) {
      message
    }
  }
`;
