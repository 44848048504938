import { gql } from '@apollo/client';

// eslint-disable-next-line import/prefer-default-export
export const GET_SIGNED_URL = gql`
  query GetUserProfileImageSignedPutUrl(
    $data: GetUserProfileImageSignedPutUrlInput!
  ) {
    getUserProfileImageSignedPutUrl(data: $data) {
      fileName
      signedUrl
    }
  }
`;

export const LIBRARY = gql`
  query Library {
    library {
      referralUrl
      isActive
      referralCode
      isArchived
      createdAt
      address
      city
      id
      libraryNumber
      librarian {
        contactNo
        lastName
        firstName
        email
        pincode {
          pincode
        }
        id
      }
      name
      pincodes {
        pincode
        id
      }
      preferredTimeFrom
      preferredTimeTo
      state
      backUpLibrary {
        id
        name
        preferredTimeFrom
        preferredTimeTo
        librarian {
          firstName
          lastName
          email
          contactNo
        }
      }
    }
  }
`;
