import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import AccessControl from '../../AccessControl';
import { PERMISSIONS_KEY, ROUTES } from '../../common/constants';

const SubscriptionWrapper = () => (
  <Switch>
    <Route
      exact
      path={ROUTES?.SUBSCRIPTION_PLANS}
      render={() => (
        <AccessControl permissionKey={PERMISSIONS_KEY?.SUBSCRIPTION_PLANS}>
          <Redirect to={`${ROUTES?.SUBSCRIPTION_PLANS}${ROUTES?.GARDEN}`} />
        </AccessControl>
      )}
    />
  </Switch>
);

export default SubscriptionWrapper;
