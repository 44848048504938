import { gql } from '@apollo/client';

// eslint-disable-next-line import/prefer-default-export
export const USERS = gql`
  query Users($filter: UsersFilter, $sort: UsersSort) {
    users(filter: $filter, sort: $sort) {
      count
      data {
        id
        contactNo
        email
        roles
        createdAt
        isActive
        isArchived
        firstName
        lastName
        userNumber
        availableBalance
        referralCode
        referredByType
        referrer {
          firstName
          lastName
          id
          userNumber
        }
        referrerLibrary {
          libraryNumber
          name
          id
        }
        appliedReferralCode
      }
    }
  }
`;
