import { ExportOutlined, PlusOutlined, SyncOutlined } from '@ant-design/icons';
import { useMutation } from '@apollo/client';
import { Button, Card, Form } from 'antd';
import React, { useContext, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { AppContext } from '../../../../../AppContext';
import {
  EXPORT_TYPES,
  MODULES,
  PERMISSION_TYPE,
  PERMISSIONS_KEY,
  ROUTES,
} from '../../../../../common/constants';
import { hasPermission } from '../../../../../common/utils';
import ExportDateRangeModal from '../../../../../components/ExportDateRangeModal';
import Portal from '../../../../../components/Portal';
import CouponTable from '../components/CouponTable';
import { REQUEST_COUPON_EXPORT, SYNC_COUPONS } from '../graphql/Mutations';

const Coupon = () => {
  const {
    state: { permissions },
  } = useContext(AppContext);
  const history = useHistory();
  const [form] = Form.useForm();
  const [isSyncCouponsLoading, setIsSyncCouponsLoading] = useState(false);
  const [isSync, setIsSync] = useState(false);
  const [exportFilter, setExportFilter] = useState({});
  const [isExportLoading, setIsExportLoading] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const [syncCoupons] = useMutation(SYNC_COUPONS, {
    onError: () => {},
  });

  const [requestCouponExport] = useMutation(REQUEST_COUPON_EXPORT, {
    onError: () => {},
  });

  const handleSync = async () => {
    setIsSyncCouponsLoading(true);
    const res = await syncCoupons();
    if (res?.data) {
      setIsSync(!isSync);
    }
    setIsSyncCouponsLoading(false);
  };

  const handleExport = async (values) => {
    setIsExportLoading(true);
    const res = await requestCouponExport({
      variables: {
        data: {
          type: EXPORT_TYPES?.COUPON,
        },
        filter: {
          search: exportFilter?.search,
          isArchived: exportFilter?.isArchived,
          dateRange: {
            from: values?.date?.[0]?.startOf('day'),
            to: values?.date?.[1]?.endOf('day'),
          },
          type: exportFilter?.type,
          libraryIds: exportFilter?.name,
          isActive: exportFilter?.status,
        },
      },
    });
    if (res?.data) {
      setIsModalOpen(false);
      form?.resetFields();
    }
    setIsExportLoading(false);
  };

  return (
    <>
      <Portal portalId="header-title-content">
        <div className="portal-header">{MODULES?.COUPON}</div>
      </Portal>
      <Portal portalId="header-right-content">
        <div className="header-btn-wrapper">
          {hasPermission(
            PERMISSIONS_KEY?.OFFERS_COUPONS,
            PERMISSION_TYPE?.CREATE,
            permissions,
          ) && (
            <Button
              className="common-button"
              size="middle"
              icon={<PlusOutlined />}
              onClick={() =>
                history?.push(`${ROUTES?.OFFERS}${ROUTES?.COUPON}/add`)
              }
            >
              Add New Coupon
            </Button>
          )}
          <Button
            loading={isSyncCouponsLoading}
            className="common-button ml-16"
            size="middle"
            onClick={handleSync}
            icon={<SyncOutlined />}
          >
            Sync Coupons
          </Button>
          <Button
            className="common-button ml-16"
            size="middle"
            icon={<ExportOutlined />}
            onClick={() => setIsModalOpen(true)}
            loading={isExportLoading}
          >
            Export
          </Button>
        </div>
      </Portal>
      <Portal portalId="header-right-content-phones">
        <div className="header-btn-wrapper">
          {hasPermission(
            PERMISSIONS_KEY?.OFFERS_COUPONS,
            PERMISSION_TYPE?.CREATE,
            permissions,
          ) && (
            <Button
              className="common-button"
              size="middle"
              icon={<PlusOutlined />}
              onClick={() =>
                history?.push(`${ROUTES?.OFFERS}${ROUTES?.COUPON}/add`)
              }
            >
              Add New Coupon
            </Button>
          )}
          <Button
            loading={isSyncCouponsLoading}
            className="common-button"
            size="middle"
            onClick={handleSync}
            icon={<SyncOutlined />}
          >
            Sync Coupons
          </Button>
          <Button
            className="common-button ml-16"
            size="middle"
            icon={<ExportOutlined />}
            onClick={() => setIsModalOpen(true)}
            loading={isExportLoading}
          >
            Export
          </Button>
        </div>
      </Portal>
      <ExportDateRangeModal
        form={form}
        onFinish={handleExport}
        loadings={isExportLoading}
        isModalOpen={isModalOpen}
        setIsModalOpen={setIsModalOpen}
      />
      <Card className="full-height-without-nav">
        <CouponTable
          isSync={isSync}
          exportFilter={exportFilter}
          setExportFilter={setExportFilter}
        />
      </Card>
    </>
  );
};

export default Coupon;
