import { Card, Dropdown, Tag } from 'antd';
import React from 'react';
import { ReactComponent as MoreIcon } from '../../../assets/svg/ellipsis-vertical-solid.svg';
import {
  ACTIONS,
  DATE_FORMATS,
  EXPORT,
  EXPORT_JOBS_STATUS,
  EXPORT_TYPES,
} from '../../../common/constants';
import { formatDate } from '../../../common/utils';

const ExportCard = ({ exportItem, handleDownload, infiniteScrollRef }) => {
  const items = [
    {
      label: ACTIONS?.DOWNLOAD?.label,
      key: ACTIONS?.DOWNLOAD?.value,
    },
  ];

  const menuProps = {
    items,
    onClick: () =>
      [EXPORT_TYPES?.ORDER_CHALLANS, EXPORT_TYPES?.ORDER_STICKERS]?.includes(
        exportItem?.type,
      )
        ? exportItem?.fileUrls?.map((url) => handleDownload(url))
        : handleDownload(exportItem?.fileUrl),
  };

  return (
    <Card
      className="ml-12 mr-12 mb-24 mt-12 mobile-card"
      title={EXPORT?.[exportItem?.type]}
      extra={
        <div className="d-flex align-center">
          <Tag
            color={EXPORT_JOBS_STATUS?.[exportItem?.status]?.color}
            className="mr-16"
          >
            {EXPORT_JOBS_STATUS?.[exportItem?.status]?.text}
          </Tag>
          <Dropdown
            getPopupContainer={(trigger) => trigger?.parentNode}
            menu={menuProps}
          >
            <MoreIcon className="pointer mobile-svg-icon" />
          </Dropdown>
        </div>
      }
      ref={infiniteScrollRef}
    >
      <div className="mb-8">
        <span className="mobile-card-label">Date: </span>
        <span>
          {formatDate(exportItem?.createdAt, DATE_FORMATS?.REGULAR) ||
            'No Date'}
        </span>
      </div>
    </Card>
  );
};

export default ExportCard;
