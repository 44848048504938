import { gql } from '@apollo/client';

// eslint-disable-next-line import/prefer-default-export
export const LIBRARIES_UNFULFILLED_ORDERS_COUNT = gql`
  query LibrariesUnfulfilledOrdersCount(
    $filter: LibrariesUnfulfilledOrdersFilter
    $sort: LibrariesUnfulfilledOrdersSort
  ) {
    librariesUnfulfilledOrdersCount(filter: $filter, sort: $sort) {
      count
      data {
        id
        libraryName
        libraryNumber
        orderCount
        orderItemCount
      }
    }
  }
`;

export const LIBRARY_UNFULFILLED_ORDERS_COUNT = gql`
  query LibraryUnfulfilledOrdersCount($where: LibraryUniqueInput) {
    libraryUnfulfilledOrdersCount(where: $where) {
      count
      data {
        orderItemCount
        orderNumber
        orderItemId
      }
    }
  }
`;
