import React from 'react';
import { SUBSCRIPTION_CANCELLED_TYPES } from '../../../../../common/constants';
import ModalComponent from '../../../../../components/ModalComponent';

const SubscriptionNoteModal = ({ note, isModalOpen, setIsModalOpen }) => (
  <ModalComponent
    open={isModalOpen}
    title="Cancellation Note"
    onCancel={() => {
      setIsModalOpen(false);
    }}
    footer={null}
  >
    {note?.cancellationData && (
      <>
        {SUBSCRIPTION_CANCELLED_TYPES?.[note?.cancelledType] && (
          <div className="font-size-16">
            Reason: {note?.cancellationData?.reason || '-'}
            <br />
            Description: {note?.cancellationData?.description || '-'}
          </div>
        )}
      </>
    )}
  </ModalComponent>
);

export default SubscriptionNoteModal;
