import { useLazyQuery } from '@apollo/client';
import { Button, Form } from 'antd';
import React, { useContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { AppContext } from '../../../../../AppContext';
import {
  PERMISSIONS_KEY,
  PERMISSION_TYPE,
  ROUTES,
} from '../../../../../common/constants';
import { hasPermission } from '../../../../../common/utils';
import GoBackButton from '../../../../../components/GoBackButton';
import LoaderComponent from '../../../../../components/LoaderComponent';
import Portal from '../../../../../components/Portal';
import { USER } from '../../../graphql/Queries';
import EndUserForm from '../components/EndUserForm';
import EndUsersTabs from '../components/EndUsersTabs';

const ViewEndUser = ({ match: { params: { id, userNumber } = {} } = {} }) => {
  const {
    state: { permissions },
  } = useContext(AppContext);
  const [form] = Form.useForm();
  const history = useHistory();
  const [endUserDetail, setEndUserDetail] = useState({});
  const [isEndUserLoading, setIsEndUserLoading] = useState(true);

  const [users] = useLazyQuery(USER, {
    onCompleted: (response) => {
      setEndUserDetail(response?.user);
      setIsEndUserLoading(false);
    },
    fetchPolicy: 'network-only',
    onError() {},
  });

  useEffect(() => {
    setIsEndUserLoading(true);
    setEndUserDetail([]);
    users({
      variables: {
        where: {
          id,
        },
      },
    });
  }, []);

  const initialValues = {
    ...endUserDetail,
    contact: endUserDetail?.contactNo,
    status: endUserDetail?.isActive,
    state: endUserDetail?.state,
    city: endUserDetail?.city,
    pincode: endUserDetail?.pincode?.pincode,
    id: endUserDetail?.userNumber,
    mappedLibrary: endUserDetail?.pincode?.library?.name,
    lat: endUserDetail?.latLong?.coordinates?.[0],
    lng: endUserDetail?.latLong?.coordinates?.[1],
  };

  return (
    <>
      <Portal portalId="header-title-content">
        <GoBackButton
          customLink={`${ROUTES?.SYSTEM_USERS}${ROUTES?.END_USERS}`}
        />
        <div className="portal-header">View End User</div>
      </Portal>
      <Portal portalId="header-right-content">
        {hasPermission(
          PERMISSIONS_KEY?.SYSTEM_USERS_END_USERS,
          PERMISSION_TYPE?.UPDATE,
          permissions,
        ) && (
          <div className="header-btn-wrapper">
            <Button
              className="common-button mr-16"
              size="middle"
              onClick={() =>
                history?.push(
                  `${ROUTES?.SYSTEM_USERS}${ROUTES?.END_USERS}${ROUTES?.BASIC_DETAILS}/edit/${id}/${userNumber}`,
                )
              }
            >
              Edit End User
            </Button>
          </div>
        )}
      </Portal>
      <Portal portalId="header-right-content-phones">
        {hasPermission(
          PERMISSIONS_KEY?.SYSTEM_USERS_END_USERS,
          PERMISSION_TYPE?.UPDATE,
          permissions,
        ) && (
          <div className="header-btn-wrapper">
            <Button
              className="common-button mr-16"
              size="middle"
              onClick={() =>
                history?.push(
                  `${ROUTES?.SYSTEM_USERS}${ROUTES?.END_USERS}${ROUTES?.BASIC_DETAILS}/edit/${id}/${userNumber}`,
                )
              }
            >
              Edit End User
            </Button>
          </div>
        )}
      </Portal>
      <EndUsersTabs
        endUserId={id}
        endUserNumber={userNumber}
        onboardedOn={endUserDetail?.createdAt}
        isView
      >
        {isEndUserLoading ? (
          <LoaderComponent
            size="large"
            setHeight="60"
            spinning={isEndUserLoading}
          />
        ) : (
          <div className="endUser-form">
            <EndUserForm
              form={form}
              initialValues={initialValues}
              isView
              endUserDetail={endUserDetail}
            />
          </div>
        )}
      </EndUsersTabs>
    </>
  );
};

export default ViewEndUser;
