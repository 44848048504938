import { gql } from '@apollo/client';

// eslint-disable-next-line import/prefer-default-export
export const REQUEST_LIBRARY_REQUESTS_EXPORT = gql`
  mutation RequestLibraryRequestsExport(
    $data: RequestExportInput!
    $filter: RequestLibraryExportFilterInput
  ) {
    requestLibraryRequestsExport(data: $data, filter: $filter) {
      message
    }
  }
`;

// eslint-disable-next-line import/prefer-default-export
export const CREATE_REQUEST_LIBRARY_ADMIN = gql`
  mutation CreateRequestLibraryAdmin($data: CreateRequestLibraryInput) {
    createRequestLibraryAdmin(data: $data) {
      message
    }
  }
`;
