import { gql } from '@apollo/client';

// eslint-disable-next-line import/prefer-default-export
export const ADMIN_NOTIFICATIONS = gql`
  query AdminNotifications(
    $filter: NotificationsFilter
    $sort: NotificationsSort
  ) {
    adminNotifications(filter: $filter, sort: $sort) {
      count
      data {
        title
        description
        createdAt
        id
        type
        readBy
        hasRead
      }
    }
  }
`;
