import { FilterFilled, FilterOutlined } from '@ant-design/icons';
import { useLazyQuery, useMutation } from '@apollo/client';
import {
  Button,
  Checkbox,
  DatePicker,
  Divider,
  Empty,
  Modal,
  Radio,
  Tag,
} from 'antd';
import { debounce, filter, forEach, isEmpty, map } from 'lodash';
import React, { useContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { AppContext } from '../../../../../AppContext';
import {
  DATE_FORMATS,
  DELIVERY_TYPE_OPTIONS,
  LIMIT,
  PERMISSIONS_KEY,
  PERMISSION_TYPE,
  ROUTES,
  SORT,
  USER_ROLES,
} from '../../../../../common/constants';
import { hasPermission } from '../../../../../common/utils';
import CommonTable from '../../../../../components/CommonTable';
import LoaderComponent from '../../../../../components/LoaderComponent';
import SearchComponent from '../../../../../components/SearchComponent';
import UserSelect from '../../../../payments/modules/endUser/components/UserSelect';
import { ASSIGN_DELIVERY_PARTNER } from '../graphql/Mutations';
import {
  LIBRARIES,
  NEW_ORDERS_ADMIN,
  PINCODE_WISE_NEW_ORDER_COUNT,
} from '../graphql/Queries';

const { RangePicker } = DatePicker;
const { Group } = Radio;

const initialPaginationValue = {
  total: 0,
  current: 1,
  pageSize: 10,
};

const initialOrderFilter = {
  limit: LIMIT,
  search: '',
  skip: 0,
};

const initialOrderSort = {
  field: 'orderCount',
  order: 'DESC',
};

const initialTableFilter = {
  skip: 0,
  limit: LIMIT,
  search: '',
};

const initialTableSort = {
  field: 'createdAt',
  order: 'DESC',
};

let filterScrollDebounceJob = null;

const AllDeliveryTable = () => {
  const {
    state: {
      permissions,
      commonPermissions: { isLibraryPermission },
    },
  } = useContext(AppContext);
  const history = useHistory();
  const [orderList, setOrderList] = useState([]);
  const [orderSearchTerm, setOrderSearchTerm] = useState('');
  const [isEmptyOrderList, setIsEmptyOrderList] = useState(false);
  const [order, setOrder] = useState({});
  const [orderItem, setOrderItem] = useState({});
  const [query, setQuery] = useState('');
  const [sortedInfo, setSortedInfo] = useState({});
  const [paginationProp, setPaginationProp] = useState(initialPaginationValue);

  const [isOrderLoading, setIsOrderLoading] = useState(true);
  const [isDeliveryPartnerLoading, setIsDeliveryPartnerLoading] = useState(
    false,
  );
  const [isDeliveryPartnerPrompts, setIsDeliveryPartnerPrompts] = useState(
    false,
  );
  const [deliveryPartnerData, setDeliveryPartnerData] = useState('');

  const [expandedRowKeys, setExpandedRowKeys] = useState([]);
  const [nestedData, setNestedData] = useState({});
  const [isLoading, setIsLoading] = useState({});
  const [isDisabled, setIsDisabled] = useState(false);

  const [filterSearch, setFilterSearch] = useState('');
  const [filterList, setFilterList] = useState([]);
  const [whereFilter, setWhereFilter] = useState();
  const [filterLoading, setFilterLoading] = useState(false);
  const [filterTags, setFilterTags] = useState(null);
  const [filterVisible, setFilterVisible] = useState(false);
  const [filterIndex, setFilterIndex] = useState(null);
  const [finalWhereFilters, setFinalWhereFilters] = useState();
  const [finalFilterTags, setFinalFilterTags] = useState(null);
  const [filterIsEnd, setFilterIsEnd] = useState(false);
  const [isEmptyFilterList, setIsEmptyFilterList] = useState(false);

  const [newOrdersAdmin] = useLazyQuery(NEW_ORDERS_ADMIN, {
    onCompleted: (response) => {
      setOrderList([...response?.newOrdersAdmin?.data]);
      if (
        response?.newOrdersAdmin?.count === 0 &&
        initialPaginationValue?.total === 0
      ) {
        setIsEmptyOrderList(true);
      } else {
        setIsEmptyOrderList(false);
      }
      const pagination = {
        ...paginationProp,
        defaultPageSize: LIMIT,
        total: response?.newOrdersAdmin?.count,
      };
      setPaginationProp(pagination);
      setIsOrderLoading(false);
    },
    fetchPolicy: 'network-only',
    onError() {},
  });

  const [pincodeWiseNewOrdersCount] = useLazyQuery(
    PINCODE_WISE_NEW_ORDER_COUNT,
    {
      fetchPolicy: 'network-only',
      onError() {},
    },
  );

  const [libraries, { loading: isLibrariesLoading }] = useLazyQuery(LIBRARIES, {
    onCompleted: (response) => {
      if (response?.libraries?.data?.length < LIMIT) {
        setFilterIsEnd(true);
      }
      if (response?.libraries?.count === 0) {
        setIsEmptyFilterList(true);
      } else {
        setIsEmptyFilterList(false);
      }
      const optionsCopy = [];
      forEach(response?.libraries?.data, (item) =>
        optionsCopy?.push({ label: item?.name, key: item?.id }),
      );
      setFilterList([...filterList, ...optionsCopy]);
      setFilterLoading(false);
    },
    fetchPolicy: 'network-only',
    onError() {
      setFilterLoading(false);
    },
  });

  const [assignDeliveryPartner] = useMutation(ASSIGN_DELIVERY_PARTNER, {
    onError: () => {},
  });

  useEffect(() => {
    setPaginationProp({ ...paginationProp, current: 1, skip: 0 });
    setIsOrderLoading(true);
    newOrdersAdmin({
      variables: {
        filter: {
          ...initialOrderFilter,
          search: orderSearchTerm,
          limit: paginationProp?.pageSize || LIMIT,
        },
        sort: initialOrderSort,
      },
    });
  }, []);

  const handleTableChange = (pagination, tableFilter, sorter) => {
    const { current } = pagination;
    const skip = (current - 1) * (pagination?.pageSize || 0);
    setPaginationProp({ ...paginationProp, ...pagination });
    setIsOrderLoading(true);
    setSortedInfo(sorter);
    setNestedData({});
    setExpandedRowKeys([]);
    newOrdersAdmin({
      variables: {
        filter: {
          ...initialOrderFilter,
          skip,
          limit: pagination?.pageSize,
          search: orderSearchTerm,
          libraryIds: finalWhereFilters?.name,
        },
        sort: sorter?.column
          ? {
              field: sorter?.field,
              order: sorter?.order === 'ascend' ? SORT?.ASC : SORT?.DESC,
            }
          : initialOrderSort,
      },
    });
  };

  const handleSearch = (value) => {
    const trimValue = value?.trim();
    setOrderSearchTerm(trimValue);
    setPaginationProp({ ...paginationProp, current: 1, skip: 0 });
    setIsOrderLoading(true);
    setNestedData({});
    setExpandedRowKeys([]);
    newOrdersAdmin({
      variables: {
        filter: {
          ...initialOrderFilter,
          limit: paginationProp?.pageSize || LIMIT,
          search: trimValue,
          libraryIds: finalWhereFilters?.name,
        },
        sort: sortedInfo?.column
          ? {
              field: sortedInfo?.field,
              order: sortedInfo?.order === 'ascend' ? SORT?.ASC : SORT?.DESC,
            }
          : initialOrderSort,
      },
    });
  };

  const handleAssignDeliveryPartner = async () => {
    setIsDeliveryPartnerLoading(true);
    const response = await assignDeliveryPartner({
      variables: {
        where: {
          id: orderItem?.pincodeId,
        },
        data: {
          deliveryPartnerId: deliveryPartnerData?.value,
          libraryId: order?.libraryId,
        },
      },
    });
    if (response?.data) {
      setIsOrderLoading(true);
      setIsDeliveryPartnerPrompts(false);
      setNestedData({});
      setExpandedRowKeys([]);
      newOrdersAdmin({
        variables: {
          filter: {
            ...initialOrderFilter,
            skip:
              (paginationProp?.current - 1) * (paginationProp?.pageSize || 0),
            limit: paginationProp?.pageSize || LIMIT,
            search: orderSearchTerm,
            libraryIds: finalWhereFilters?.name,
          },
          sort: sortedInfo?.column
            ? {
                field: sortedInfo?.field,
                order: sortedInfo?.order === 'ascend' ? SORT?.ASC : SORT?.DESC,
              }
            : initialOrderSort,
        },
      });
    }
    setIsDeliveryPartnerLoading(false);
  };

  const getFilterData = (confirm) => {
    setIsOrderLoading(true);
    setNestedData({});
    setExpandedRowKeys([]);
    newOrdersAdmin({
      variables: {
        filter: {
          ...initialOrderFilter,
          search: orderSearchTerm,
          libraryIds: whereFilter?.name,
        },
        sort: sortedInfo?.column
          ? {
              field: sortedInfo?.field,
              order: sortedInfo?.order === 'ascend' ? SORT?.ASC : SORT?.DESC,
            }
          : initialOrderSort,
      },
    });
    setFinalWhereFilters({ ...finalWhereFilters, ...whereFilter });
    setFinalFilterTags(filterTags);
    setPaginationProp({ ...paginationProp, skip: 0, current: 1 });
    if (confirm) {
      confirm();
    }
  };

  const handleReset = (clearFilters, dataIndex, confirm) => {
    const tagsCopy = {
      ...filterTags,
      [dataIndex]: [],
    };
    const whereFiltersCopy = {
      ...whereFilter,
      [dataIndex]: [],
    };
    setFilterVisible(false);
    setFinalFilterTags({ ...filterTags, ...tagsCopy });
    setFinalWhereFilters({ ...finalWhereFilters, ...whereFiltersCopy });
    setIsOrderLoading(true);
    setNestedData({});
    setExpandedRowKeys([]);
    newOrdersAdmin({
      variables: {
        filter: {
          ...initialOrderFilter,
          search: orderSearchTerm,
          libraryIds: whereFiltersCopy?.name,
        },
        sort: sortedInfo?.column
          ? {
              field: sortedInfo?.field,
              order: sortedInfo?.order === 'ascend' ? SORT?.ASC : SORT?.DESC,
            }
          : initialOrderSort,
      },
    });
    setPaginationProp({ ...paginationProp, skip: 0, current: 1 });
    clearFilters();
    setFilterVisible(false);
    if (confirm) {
      confirm();
    }
  };

  useEffect(() => {
    if (filterVisible) {
      setFilterList([]);
      setFilterLoading(true);
      setIsEmptyFilterList(false);
      setFilterTags({ ...filterTags, ...finalFilterTags });
      setWhereFilter({ ...whereFilter, ...finalWhereFilters });
      setFilterIsEnd(false);
      switch (filterIndex) {
        case 'name':
          libraries({
            variables: {
              filter: initialTableFilter,
              sort: initialTableSort,
            },
          });
          break;

        default:
          break;
      }
    }
    if (!filterVisible) {
      setFilterTags(finalFilterTags);
      setWhereFilter(finalWhereFilters);
      setFilterSearch('');
    }
  }, [filterVisible]);

  const onFilterScroll = (event, dataIndex) => {
    if (filterScrollDebounceJob) {
      filterScrollDebounceJob?.cancel();
      filterScrollDebounceJob = null;
    }
    if (!isLibrariesLoading && !filterIsEnd) {
      const target = event?.target;
      const currentLength = filterList?.length;
      if (
        target?.scrollTop + target?.offsetHeight >=
        target?.scrollHeight - 5
      ) {
        filterScrollDebounceJob = debounce(() => {
          setFilterLoading(true);
          switch (dataIndex) {
            case 'name':
              libraries({
                variables: {
                  filter: {
                    limit: LIMIT,
                    skip: currentLength,
                    search: filterSearch,
                  },
                  sort: initialTableSort,
                },
              });
              break;

            default:
              break;
          }
        }, 500);
        filterScrollDebounceJob();
      }
    }
  };

  const handleFilterSearch = (value, dataIndex) => {
    setFilterSearch(value);
    setFilterIsEnd(false);
    setFilterLoading(true);
    setFilterList([]);
    switch (dataIndex) {
      case 'name':
        libraries({
          variables: {
            filter: {
              ...initialTableFilter,
              limit: LIMIT,
              search: value,
            },
            sort: initialTableSort,
          },
        });
        break;

      default:
        break;
    }
  };

  const changeFilter = (newFilterObj, dataIndex) => {
    const {
      target: { value },
    } = newFilterObj;

    const filterTagsCopy = { ...filterTags };
    let newTagList;
    if (whereFilter?.[dataIndex]?.includes(value?.key)) {
      newTagList = {
        ...filterTagsCopy,
        [dataIndex]: filter(filterTagsCopy?.[dataIndex], (item) => {
          if (item?.label) {
            return item?.key !== value?.key;
          }
          return item !== value;
        }),
      };
    } else {
      newTagList = {
        ...filterTagsCopy,
        [dataIndex]: filterTagsCopy?.[dataIndex]
          ? [...filterTagsCopy?.[dataIndex], value]
          : [value],
      };
    }

    const updatedWhereFilter = {
      ...whereFilter,
      [dataIndex]: newTagList?.[dataIndex]?.map((item) => item?.key),
    };
    setWhereFilter(updatedWhereFilter);
    setFilterTags(newTagList);
  };

  const handleDeleteFilter = (value, dataIndex) => {
    let newTagList;

    if (whereFilter?.[dataIndex]?.includes(value?.key)) {
      newTagList = {
        ...filterTags,
        [dataIndex]: filter(filterTags?.[dataIndex], (item) => {
          if (item?.key) {
            return item?.key !== value?.key;
          }
          return item !== value;
        }),
      };
    }

    const updatedWhereFilter = {
      ...whereFilter,
      [dataIndex]: newTagList?.[dataIndex]?.map((item) => item?.key),
    };
    setWhereFilter(updatedWhereFilter);
    setFilterTags(newTagList);
  };

  const onRangePickerChange = (values, dataIndex) => {
    const rangeObj = {
      from: values[0]?.startOf('day'),
      to: values?.[1]?.endOf('day'),
    };

    const filtersCopy = {
      ...whereFilter,
      [dataIndex]: rangeObj,
    };
    if (!values?.length) {
      delete whereFilter?.[dataIndex];
    }
    setWhereFilter(filtersCopy);
  };

  const onRadioChange = (value, dataIndex) => {
    const filtersCopy = {
      ...whereFilter,
      [dataIndex]: value,
    };
    if (!value?.length) {
      delete whereFilter?.[dataIndex];
    }
    setWhereFilter(filtersCopy);
  };

  const filterPopup = (dataIndex, showSearch = true) => ({
    filterDropdown: ({ confirm, clearFilters }) => {
      if (dataIndex === 'createdAt') {
        return (
          <div className="custom-filter-dropdown">
            <RangePicker
              allowClear={false}
              format={DATE_FORMATS?.REGULAR}
              onChange={(values) => onRangePickerChange(values, dataIndex)}
              value={
                whereFilter?.[dataIndex]?.from
                  ? [
                      whereFilter?.[dataIndex]?.from,
                      whereFilter?.[dataIndex]?.to,
                    ]
                  : null
              }
            />
            <Divider className="divider-filter" />
            <div className="d-flex justify-center">
              <Button
                size="small"
                className="reset-button"
                id="roles-filter-reset"
                onClick={() => handleReset(clearFilters, dataIndex, confirm)}
              >
                Reset
              </Button>
              <Button
                size="small"
                className="common-button ok-button"
                id="roles-filter-ok"
                type="primary"
                onClick={() => getFilterData(confirm, dataIndex)}
              >
                Ok
              </Button>
            </div>
          </div>
        );
      }

      if (dataIndex === 'deliveryType') {
        return (
          <div className="custom-filter-dropdown">
            <Group
              options={DELIVERY_TYPE_OPTIONS}
              value={whereFilter?.[dataIndex]}
              onChange={(e) => onRadioChange(e?.target?.value, dataIndex)}
            />
            <Divider className="divider-filter" />
            <div className="d-flex justify-center">
              <Button
                size="small"
                className="reset-button"
                id="roles-filter-reset"
                onClick={() => handleReset(clearFilters, dataIndex, confirm)}
              >
                Reset
              </Button>
              <Button
                size="small"
                className="common-button ok-button"
                id="roles-filter-ok"
                type="primary"
                onClick={() => getFilterData(confirm, dataIndex)}
              >
                Ok
              </Button>
            </div>
          </div>
        );
      }

      return (
        <div className="custom-filter-dropdown">
          <LoaderComponent
            spinning={filterLoading}
            setHeight={35}
            size="medium"
          >
            {showSearch && (
              <SearchComponent
                className="filter-search"
                id="search-container-id-roles"
                placeholder="Search..."
                name={dataIndex}
                query={filterSearch}
                setQuery={setFilterSearch}
                getData={(value) => handleFilterSearch(value, dataIndex)}
              />
            )}
            {filterTags?.[dataIndex]?.length > 0 && (
              <div className="filter-tags-wrapper">
                {map(filterTags?.[dataIndex], (item) => (
                  <Tag
                    key={item?.key}
                    closable
                    onClose={() => handleDeleteFilter(item, dataIndex)}
                    className="filter-tag"
                  >
                    <span>{item?.label}</span>
                  </Tag>
                ))}
              </div>
            )}
            <div
              className="filter-checkboxes"
              onScroll={(e) => onFilterScroll(e, dataIndex)}
            >
              {filterList?.length > 0
                ? map(filterList, (item) => (
                    <div className="single-checkbox-div" key={item?.key}>
                      <Checkbox
                        value={item}
                        checked={whereFilter?.[dataIndex]?.includes(item?.key)}
                        key={item?.key || item}
                        onChange={(e) => changeFilter(e, dataIndex)}
                        className="single-checkbox"
                      >
                        <span>{item?.label}</span>
                      </Checkbox>
                    </div>
                  ))
                : isEmptyFilterList && (
                    <Empty image={Empty?.PRESENTED_IMAGE_SIMPLE} />
                  )}
            </div>
          </LoaderComponent>
          <Divider className="divider-filter" />
          <div className="d-flex justify-center">
            <Button
              size="small"
              className="reset-button"
              id="roles-filter-reset"
              onClick={() => handleReset(clearFilters, dataIndex, confirm)}
            >
              Reset
            </Button>
            <Button
              size="small"
              className="common-button ok-button"
              id="roles-filter-ok"
              type="primary"
              onClick={() => getFilterData(confirm, dataIndex)}
            >
              Ok
            </Button>
          </div>
        </div>
      );
    },
    filterIcon: () =>
      finalWhereFilters?.[dataIndex]?.length > 0 ||
      !isEmpty(finalWhereFilters?.[dataIndex]) ? (
        <FilterFilled className="filter-icon filter-filled" />
      ) : (
        <FilterOutlined className="filter-icon" />
      ),
    onFilterDropdownOpenChange: (isVisible) => {
      setFilterIndex(dataIndex);
      setFilterVisible(isVisible);
    },
  });

  const handleExpand = async (expanded, record) => {
    setIsLoading({
      [record?.libraryId]: true,
    });
    setIsDisabled(true);
    if (!nestedData?.[record?.libraryId]) {
      try {
        const res = await pincodeWiseNewOrdersCount({
          variables: {
            where: {
              id: record?.libraryId,
            },
          },
        });
        if (res?.data) {
          setNestedData((state) => ({
            ...state,
            [record?.libraryId]: res?.data,
          }));
        }
      } catch (error) {
        setIsDisabled(false);
        return error;
      }
    }
    if (expanded) {
      setExpandedRowKeys([...expandedRowKeys, record?.libraryId]);
    } else {
      setExpandedRowKeys(
        expandedRowKeys?.filter((key) => key !== record?.libraryId),
      );
    }
    setIsLoading({
      [record?.libraryId]: false,
    });
    setIsDisabled(false);
  };

  const handleOrderItemChange = (value, record, orderDetails) => {
    setOrderItem(record);
    setOrder(orderDetails);
    setDeliveryPartnerData(value);
    setIsDeliveryPartnerPrompts(true);
  };

  const columns = [
    {
      title: 'LIBRARY NAME',
      dataIndex: 'name',
      key: 'name',
      align: 'left',
      ellipsis: true,
      className: `max-width-column`,
      width: 300,
      onCell: (record) => ({
        onClick: () =>
          isLibraryPermission &&
          record?.libraryId &&
          history?.push(
            `${ROUTES?.MASTER_LISTS}${ROUTES?.LIBRARY}${ROUTES?.BASIC_DETAILS}/view/${record?.libraryId}/${record?.libraryNumber}`,
          ),
        className: `${
          isLibraryPermission && record?.libraryId && 'pointer table-cell'
        }`,
      }),
      render: (_, record) => (
        <>
          <div className="table-data-with-id">{record?.name}</div>
          <div className="font-size-12">({record?.libraryNumber})</div>
        </>
      ),
      ...filterPopup('name'),
    },
    {
      title: 'TOTAL ORDERS OF LIBRARY',
      dataIndex: 'totalOrdersLibrary',
      key: 'totalOrdersLibrary',
      ellipsis: true,
      align: 'left',
      width: 300,
      className: 'max-width-column',
      render: (_, record) => record?.orderCount,
    },
  ];

  const expandedRowRender = (data) => {
    const orderItemList =
      nestedData?.[data?.libraryId]?.pincodeWiseNewOrdersCount?.data;
    const nestedColumns = [
      {
        title: 'PINCODE',
        dataIndex: 'pincode',
        key: 'pincode',
        ellipsis: true,
        align: 'left',
        width: 150,
        className: 'max-width-column',
        render: (_, record) => record?.pincode,
      },
      {
        title: 'TOTAL ORDERS OF PINCODE',
        dataIndex: 'totalOrdersPincode',
        key: 'totalOrdersPincode',
        ellipsis: true,
        align: 'left',
        width: 200,
        className: 'max-width-column',
        render: (_, record) => record?.orderCount,
      },
      {
        title: 'ACTIONS',
        dataIndex: 'actions',
        key: 'actions',
        width: 100,
        className: `max-width-column`,
        render: (_, record) => {
          const assignedDeliveryPartner = {
            firstName: record?.firstName,
            lastName: record?.lastName,
            id: record?.userId,
            userNumber: record?.userNumber,
          };
          return (
            <UserSelect
              size="small"
              className="user-select"
              placeholder="Select Action"
              showAction={['click']}
              value={record?.userId || null}
              userData={record?.userId ? assignedDeliveryPartner : null}
              roles={USER_ROLES?.DELIVERY?.key}
              onChange={(value, userData) =>
                handleOrderItemChange(userData, record, data)
              }
              allowClear={false}
            />
          );
        },
      },
    ]?.filter((item) => {
      if (
        item?.dataIndex === 'actions' &&
        !hasPermission(
          PERMISSIONS_KEY?.ORDERS_NEW_DELIVERY,
          PERMISSION_TYPE?.UPDATE,
          permissions,
        )
      ) {
        return item?.hidden;
      }
      return !item?.hidden;
    });

    return (
      <CommonTable
        loading={isLoading[data?.libraryId]}
        columns={nestedColumns}
        dataSource={orderItemList || []}
        pagination={false}
        rowKey={(record) =>
          `${record?.pincodeId}${
            Math?.random()?.toString(36)?.substring(2) +
            new Date()?.getTime()?.toString(36)
          }`
        }
        className="nested-table"
      />
    );
  };

  const locale = {
    emptyText: isEmptyOrderList ? '' : <span />,
  };

  const expandIcon = ({ expanded, onExpand, record }) =>
    expanded ? (
      <button
        type="button"
        className="ant-table-row-expand-icon ant-table-row-expand-icon-expanded disabled"
        aria-label="Expand row"
        aria-expanded="false"
        onClick={(e) => onExpand(record, e)}
        disabled={isDisabled}
      />
    ) : (
      <button
        type="button"
        className="ant-table-row-expand-icon ant-table-row-expand-icon-collapsed"
        aria-label="Collapse row"
        aria-expanded="true"
        onClick={(e) => onExpand(record, e)}
        disabled={isDisabled}
      />
    );

  return (
    <>
      <Modal
        title="Caution"
        okText="Yes"
        cancelText="No"
        open={isDeliveryPartnerPrompts}
        onOk={handleAssignDeliveryPartner}
        onCancel={() => setIsDeliveryPartnerPrompts(false)}
        okButtonProps={{ loading: isDeliveryPartnerLoading }}
      >
        Are you sure you want to assign
        <strong> {deliveryPartnerData?.children} </strong>?
      </Modal>
      <div className="d-flex justify-end mb-16 flex-wrap">
        <SearchComponent
          className="search-box"
          query={query}
          setQuery={setQuery}
          getData={handleSearch}
        />
      </div>
      <CommonTable
        locale={locale}
        columns={columns}
        data={orderList || []}
        loading={isOrderLoading}
        onChange={handleTableChange}
        paginationConfig={paginationProp}
        rowKey={(record) => record?.libraryId}
        expandedRowKeys={expandedRowKeys}
        onExpand={(_, record) => setOrder(record)}
        expandable={{
          onExpand: (expanded, record) => handleExpand(expanded, record),
          expandedRowRender: (record) => expandedRowRender(record),
          expandIcon,
        }}
      />
    </>
  );
};

export default AllDeliveryTable;
