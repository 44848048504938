import { DatePicker, Divider, Form } from 'antd';
import React, { useContext, useState } from 'react';
import { AppContext } from '../AppContext';
import { DATE_FORMATS } from '../common/constants';
import ModalComponent from './ModalComponent';

const { Item } = Form;
const { RangePicker } = DatePicker;

const ExportDateRangeModal = ({
  title = 'Export',
  isModalOpen,
  setIsModalOpen,
  loadings,
  onFinish,
  form,
}) => {
  const {
    state: { isDesktop },
  } = useContext(AppContext);
  const [validationTriggered, setValidationTriggered] = useState(false);

  const disabledDate = (current) => {
    const today = new Date();
    const threeMonthsAgo = new Date();
    threeMonthsAgo.setMonth(today.getMonth() - 3);
    // return current && (current > today || current < threeMonthsAgo);
    return current && (current > today);
  };

  return (
    <ModalComponent
      title={title}
      okText="Download"
      cancelText="Cancel"
      open={isModalOpen}
      onOk={form?.submit}
      onCancel={() => {
        setIsModalOpen(false);
        form?.resetFields();
      }}
      confirmLoading={loadings}
    >
      <Divider />
      <Form
        form={form}
        layout="vertical"
        onFinish={onFinish}
        onFinishFailed={() => setValidationTriggered(true)}
        validateTrigger={validationTriggered ? 'onChange' : 'onSubmit'}
      >
        <Item
          label="Date"
          name="date"
          rules={[
            {
              required: true,
              message: 'Please Select Date!',
            },
          ]}
        >
          <RangePicker
            format={DATE_FORMATS?.REGULAR}
            className="full-width"
            popupClassName={isDesktop ? '' : 'picker-with-scroll'}
            placeholder={['From Date', 'To Date']}
            disabledDate={disabledDate}
          />
        </Item>
      </Form>
    </ModalComponent>
  );
};

export default ExportDateRangeModal;
