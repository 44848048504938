import { Card, Tag } from 'antd';
import React from 'react';
import { DATE_FORMATS, REQUEST_STATUS } from '../../../common/constants';
import { formatDate } from '../../../common/utils';

const ClosureRequestCard = ({ closureReq, infiniteScrollRef }) => (
  <Card
    className="ml-12 mr-12 mb-24 mt-12 mobile-card"
    title={
      <div className="overflow-scroll no-scroll-bar">
        {closureReq?.requestNumber}
      </div>
    }
    extra={
      <Tag
        color={REQUEST_STATUS?.[closureReq?.status]?.color}
        className="ml-16"
      >
        {REQUEST_STATUS?.[closureReq?.status]?.text}
      </Tag>
    }
    ref={infiniteScrollRef}
  >
    <div className="mb-8">
      <span className="mobile-card-label">Request Date: </span>
      <span>
        {formatDate(closureReq?.createdAt, DATE_FORMATS?.REGULAR) ||
          'No Request Date'}
      </span>
    </div>
    <div className="mb-8">
      <span className="mobile-card-label">From Date: </span>
      <span>
        {formatDate(closureReq?.metaData?.fromDate, DATE_FORMATS?.REGULAR) ||
          'No From Date'}
      </span>
    </div>
    <div className="mb-8">
      <span className="mobile-card-label">To Date: </span>
      <span>
        {formatDate(closureReq?.metaData?.toDate, DATE_FORMATS?.REGULAR) ||
          'No To Date'}
      </span>
    </div>
    <div className="mb-8">
      <span className="mobile-card-label">Reason: </span>
      <span>{closureReq?.description || '-'}</span>
    </div>
    <div className="mb-8">
      <span className="mobile-card-label">Action Taken On: </span>
      <span>
        {formatDate(closureReq?.updatedAt, DATE_FORMATS?.REGULAR) ||
          'No Action Taken On'}
      </span>
    </div>
  </Card>
);

export default ClosureRequestCard;
