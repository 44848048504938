import { Divider, Form } from "antd";
import React, { useState } from "react";
import ModalComponent from "../../../../components/ModalComponent";
import ToyRemove from "./toyRemove";

const GenresRemoveToyTable = ({
  setGenreToyRemoveModalOpen,
  genreToyRemoveModalOpen,
  genreId,
  onFinish,
  loadings,
  setToySelectValue,
}) => {
  const [form] = Form.useForm();
  const [isValidateTrigger, setIsValidateTrigger] = useState(false);
  const { Item } = Form;
  const handleCancel = () => {
    setGenreToyRemoveModalOpen(false);
    form?.resetFields();
  };
  return (
    <>
      <ModalComponent
        open={genreToyRemoveModalOpen}
        onCancel={handleCancel}
        onOk={form?.submit}
        title='Remove Toys'
        okText="Save"
        confirmLoading={loadings}
        destroyOnClose
        forceRender
      >
        <Divider />
        <Form
          form={form}
          onFinish={(values) => {
            onFinish(values);
            setIsValidateTrigger(true);
            handleCancel(values);
          }}
          autoComplete="off"
          layout="vertical"
          validateTrigger={isValidateTrigger ? 'onChange' : 'onSubmit'}
          onFinishFailed={() => setIsValidateTrigger(true)}
        >
          <Item label="Toys" name="toyIds">
            <ToyRemove
              genreId={genreId}
              onChange={(values) => setToySelectValue(values)}
            />
          </Item>
        </Form>
      </ModalComponent>
    </>
  )
}

export default GenresRemoveToyTable