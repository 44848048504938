import { gql } from '@apollo/client';

// eslint-disable-next-line import/prefer-default-export
export const CREATE_CATEGORY = gql`
  mutation CreateCategory($data: CreateCategoryInput!) {
    createCategory(data: $data) {
      message
    }
  }
`;

export const UPDATE_CATEGORY = gql`
  mutation UpdateCategory(
    $where: CategoryUniqueInput!
    $data: UpdateCategoryInput
  ) {
    updateCategory(where: $where, data: $data) {
      message
    }
  }
`;
