import { useLazyQuery } from '@apollo/client';
import { Empty, Select } from 'antd';
import { debounce, uniqBy } from 'lodash';
import React, { useEffect, useState } from 'react';
import { LIMIT, SORT } from '../../../../../common/constants';
import SelectComponent from '../../../../../components/SelectComponent';
import { TAGS_ADMIN } from '../graphql/Queries';

const { Option } = Select;

const TagsSelect = (props) => {
  const { toyDetail, ...rest } = props;
  const [tagList, setTagList] = useState([]);
  const [tagSearchTerm, setTagSearchTerm] = useState('');
  const [isAllTagFetched, setIsAllTagFetched] = useState(false);
  const [isTagAllowClear, setIsTagAllowClear] = useState(false);
  const [isTagDropdownVisible, setIsTagDropdownVisible] = useState(false);
  const [isFetchMoreTagLoading, setIsFetchMoreTagLoading] = useState(false);
  const [isTagSelected, setIsTagSelected] = useState(false);

  const [tagsAdmin, { loading: isTagLoading }] = useLazyQuery(TAGS_ADMIN, {
    onCompleted: (response) => {
      if (response?.tagsAdmin?.data?.length < LIMIT) {
        setIsAllTagFetched(true);
      }
      setIsTagSelected(false);
      setIsFetchMoreTagLoading(false);
      if (toyDetail) {
        setTagList(
          uniqBy(
            [
              ...(toyDetail?.tags || []),
              ...tagList,
              ...response?.tagsAdmin?.data,
            ],
            'id',
          ),
        );
        return;
      }
      setTagList([...tagList, ...response?.tagsAdmin?.data]);
    },
    fetchPolicy: 'network-only',
    onError() {},
  });

  useEffect(() => {
    if (!isTagDropdownVisible) {
      setTagSearchTerm('');
      setTagList([]);
      tagsAdmin({
        variables: {
          filter: {
            limit: LIMIT,
            skip: 0,
            search: tagSearchTerm,
          },
          sort: {
            field: 'name',
            order: SORT?.ASC,
          },
        },
      });
    }
    setIsAllTagFetched(false);
  }, [isTagDropdownVisible]);

  useEffect(() => {
    if (isTagSelected && isTagDropdownVisible && tagSearchTerm) {
      setTagSearchTerm('');
      setTagList([]);
      tagsAdmin({
        variables: {
          filter: {
            limit: LIMIT,
            skip: 0,
          },
          sort: {
            field: 'name',
            order: SORT?.ASC,
          },
        },
      });
    }
    setIsAllTagFetched(false);
  }, [isTagSelected, isTagDropdownVisible, tagSearchTerm]);

  const handleTagScroll = (event) => {
    if (!isAllTagFetched && !isTagLoading) {
      const target = event?.target;
      const currentLength = tagList?.length;
      if (
        target?.scrollTop + target?.offsetHeight >=
        target?.scrollHeight - 5
      ) {
        setIsFetchMoreTagLoading(true);
        tagsAdmin({
          variables: {
            filter: {
              limit: LIMIT,
              skip: currentLength,
              search: tagSearchTerm,
            },
            sort: {
              field: 'name',
              order: SORT?.ASC,
            },
          },
        });
      }
    }
  };

  const handleTagSearch = (searchTerm) => {
    const trimSearch = searchTerm?.trim();
    setIsAllTagFetched(false);
    setTagList([]);
    setTagSearchTerm(trimSearch);
    tagsAdmin({
      variables: {
        filter: {
          limit: LIMIT,
          skip: 0,
          search: trimSearch,
        },
        sort: {
          field: 'name',
          order: SORT?.ASC,
        },
      },
    });
  };

  const handleTagScrollDebounce = debounce(handleTagScroll, 500);
  const handleSearchTagDebounce = debounce(handleTagSearch, 500);

  useEffect(() => {
    handleTagScrollDebounce?.cancel();
  }, [handleTagScrollDebounce]);

  useEffect(() => {
    handleSearchTagDebounce?.cancel();
  }, [handleSearchTagDebounce]);

  useEffect(() => {
    if (isTagLoading) {
      setIsTagAllowClear(false);
    } else {
      setIsTagAllowClear(true);
    }
  }, [isTagLoading]);

  return (
    <SelectComponent
      mode="multiple"
      showSearch
      loading={(isTagLoading && isTagDropdownVisible) || isFetchMoreTagLoading}
      placeholder="Select Tags"
      optionFilterProp="children"
      allowClear={isTagAllowClear}
      onPopupScroll={handleTagScrollDebounce}
      onSearch={handleSearchTagDebounce}
      onDropdownVisibleChange={(visible) => setIsTagDropdownVisible(visible)}
      filterOption={false}
      onChange={() => setIsTagAllowClear(true)}
      onSelect={() => setIsTagSelected(true)}
      maxTagCount={false}
      notFoundContent={
        !isFetchMoreTagLoading && isTagLoading ? (
          <span>Loading...</span>
        ) : (
          <Empty image={Empty?.PRESENTED_IMAGE_SIMPLE} />
        )
      }
      {...rest}
    >
      {tagList?.map((tag) => (
        <Option key={tag?.id} value={tag?.id}>
          {tag?.name?.replace(/\s/g, '\u00a0')}
        </Option>
      ))}
    </SelectComponent>
  );
};

export default TagsSelect;
