import { Empty } from 'antd';
import React from 'react';
import LoaderComponent from '../../../../../components/LoaderComponent';
import CouponCard from './CouponCard';

const CouponList = ({
  couponList,
  infiniteScrollRef,
  isFetchMoreCouponLoading,
  isEmptyCouponList,
  isCouponLoading,
  setIsShareModalOpen,
  handleShare,
}) => (
  <div>
    {!isEmptyCouponList &&
      couponList?.map((coupon) => (
        <CouponCard
          key={coupon?.id}
          coupon={coupon}
          infiniteScrollRef={infiniteScrollRef}
          setIsShareModalOpen={setIsShareModalOpen}
          handleShare={handleShare}
        />
      ))}
    {(isFetchMoreCouponLoading || isCouponLoading) && (
      <LoaderComponent size="small" setHeight={10} />
    )}
    {isEmptyCouponList && <Empty image={Empty?.PRESENTED_IMAGE_SIMPLE} />}
  </div>
);

export default CouponList;
