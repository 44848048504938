import { gql } from '@apollo/client';

// eslint-disable-next-line import/prefer-default-export
export const USER_SUBSCRIPTION_ADMIN = gql`
  query UserSubscriptionAdmin($where: UserUniqueInput!) {
    userSubscriptionAdmin(where: $where) {
      data {
        id
        transactionId
        expireAt
        startedAt
        type
        method
        subscriptionFeatures {
          bonusDeliveries
          deliveryCharges
          discount
          effectiveCostPerMonth
          memberShipFees
          memberShipFeesPaid
          planPriceDiscountAdmin
          planPriceDiscountAdminmemberShipFeesPaid
          planSavings
          pricePaid
          refundableDeposit
          refundableDepositDiscountAdmin
          refundableDepositPaid
          totalDeliveryPerPlan
          totalFreeDeliveryPerMonth
          totalKeepCountPerMonth
          toyProgram
          membershipDiscountAdmin
          isUnlimitedFreeDelivery
          planVersion
        }
        metaData {
          amount
          note
          deviceType
          paymentProvider
          isTrialPeriodExpired
          membershipData {
            amount
          }
        }
        subscriptionId
        subscriptionPlan {
          id
          type
          name
          price
          subscriptionFeatures {
            totalFreeDeliveryPerMonth
            bonusDeliveries
          }
        }
        userSubscriptionMonthlyRestrictions {
          usedFreeDeliveriesCount
          availableFreeDeliveriesCount
          subscriptionFeatures {
            bonusDeliveries
            totalFreeDeliveryPerMonth
            refundableDeposit
            memberShipFees
          }
        }
        userSubscriptionRenewals {
          toyHoldingCount
          usedBonusDeliveriesCount
        }
        cancelledAt
        cancellationData {
          description
          reason
        }
        cancelledType
        paymentTokenStatusHistory {
          createdAt
          status
        }
      }
    }
  }
`;

export const USER_ORDER_DETAILS_ADMIN = gql`
  query UserOrderDetailsAdmin($where: UserUniqueInput) {
    userOrderDetailsAdmin(where: $where) {
      data {
        returnedOrdersCount
        placedOrdersCount
      }
    }
  }
`;

export const USER_SUBSCRIPTION_HISTORIES = gql`
  query UserSubscriptionHistories(
    $where: UserUniqueInput
    $filter: UserSubscriptionHistoriesFilter
    $sort: UserSubscriptionHistoriesSort
  ) {
    userSubscriptionHistories(where: $where, filter: $filter, sort: $sort) {
      count
      data {
        user {
          firstName
          id
          lastName
          userNumber
        }
        createdAt
        startedAt
        expireAt
        cancelledAt
        cancelledType
        cancellationData {
          reason
          description
        }
        id
        price
        status
        metaData {
          paymentProvider
        }
        subscriptionFeatures {
          planVersion
        }
        subscriptionPlan {
          id
          name
          type
        }
      }
    }
  }
`;
