import { Card, Tag, Tooltip } from 'antd';
import React from 'react';
import { DATE_FORMATS, REQUEST_STATUS } from '../../../common/constants';
import { formatDate } from '../../../common/utils';

const NewToyRequestCard = ({ toyReq, infiniteScrollRef }) => (
  <Card
    className="ml-12 mr-12 mb-24 mt-12 mobile-card"
    title={
      <div className="overflow-scroll no-scroll-bar">
        {toyReq?.requestNumber}
      </div>
    }
    extra={
      <Tag color={REQUEST_STATUS?.[toyReq?.status]?.color} className="ml-16">
        {REQUEST_STATUS?.[toyReq?.status]?.text}
      </Tag>
    }
    ref={infiniteScrollRef}
  >
    <div className="mb-8">
      <span className="mobile-card-label">Product Name: </span>
      <span>{toyReq?.metaData?.productName || 'No Product Name'}</span>
    </div>
    <div className="mb-8">
      <span className="mobile-card-label">Brand: </span>
      <span>{toyReq?.metaData?.brand || 'No Brand'}</span>
    </div>
    <div className="mb-8">
      <span className="mobile-card-label">Url: </span>
      <span>
        <Tooltip
          title={toyReq?.metaData?.url ? toyReq?.metaData?.url : 'No URL'}
          placement="topLeft"
          trigger="click"
          className="pointer"
        >
          {toyReq?.metaData?.url || 'No URL'}
        </Tooltip>
      </span>
    </div>
    <div className="mb-8">
      <span className="mobile-card-label">Request Date: </span>
      <span>
        {formatDate(toyReq?.createdAt, DATE_FORMATS?.REGULAR) ||
          'No Request Date'}
      </span>
    </div>
  </Card>
);

export default NewToyRequestCard;
