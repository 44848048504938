import { Divider } from 'antd';
import React from 'react';
import ModalComponent from '../../../../../components/ModalComponent';

const NoteModal = ({ note, isModalOpen, setIsModalOpen }) => (
  <ModalComponent
    title="Comments by library"
    open={isModalOpen}
    onCancel={() => {
      setIsModalOpen(false);
    }}
    footer={null}
  >
    <Divider />
    <div className="font-size-16">Date: 21/11/2023</div>
    <div className="font-size-16">
      Reason for not fulfilling the order: {note?.reason}
    </div>
    <div className="font-size-16">
      Additional comment: {note?.additionalComment}
    </div>
  </ModalComponent>
);

export default NoteModal;
