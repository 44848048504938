import { useLazyQuery, useMutation } from '@apollo/client';
import { Button, Card, Form } from 'antd';
import React, { useContext, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';
import { AppContext } from '../../../../../AppContext';
import { ROUTES } from '../../../../../common/constants';
import { fileUpload, openNotification } from '../../../../../common/utils';
import GoBackButton from '../../../../../components/GoBackButton';
import Portal from '../../../../../components/Portal';
import ToyForm from '../components/ToyForm';
import { CREATE_TOY } from '../graphql/Mutations';
import {
  GET_TOYS_SIGNED_URL,
  GET_TOY_VIDEO_SIGNED_PUT_URL,
} from '../graphql/Queries';

const CreateToy = () => {
  const history = useHistory();
  const [form] = Form.useForm();
  const { dispatch } = useContext(AppContext);
  const [isDisabled, setIsDisabled] = useState(true);
  const [isSubmitLoading, setIsSubmitLoading] = useState(false);
  const [toyImages, setToyImages] = useState([]);
  const [toyVideo, setToyVideo] = useState([]);
  const [featuredImage, setFeaturedImage] = useState([]);

  const [getToysSignUrl] = useLazyQuery(GET_TOYS_SIGNED_URL, {
    fetchPolicy: 'network-only',
    onError() {
      setIsSubmitLoading(false);
    },
  });

  const [getToyVideoSignedPutUrl] = useLazyQuery(GET_TOY_VIDEO_SIGNED_PUT_URL, {
    fetchPolicy: 'network-only',
    onError() {
      setIsSubmitLoading(false);
    },
  });

  const [createToy] = useMutation(CREATE_TOY, {
    onError: () => {
      setIsSubmitLoading(false);
    },
  });

  const createToyFun = async (values) => {
    setIsSubmitLoading(true);
    const uuid = uuidv4();
    dispatch({
      type: 'SET_SHOW_PROMPT',
      data: false,
    });
    const toyObj = {
      name: values?.name?.trim(),
      description: values?.description?.trim(),
      ageGroupIds: values?.ageGroup,
      categoryIds: values?.category,
      brandId: values?.brand,
      materialId: values?.material,
      imageUuid: uuid,
      url: values?.url,
      shortDescription: values?.shortDescription?.trim(),
      eanNumber: values?.eanNumber?.trim(),
      price: parseFloat(values?.price),
      carbonEmission: parseFloat(values?.carbonEmission),
      toyNumber: values?.id?.trim(),
      facilitateIds: values?.facilitates,
      tagIds: values?.tags,
      genreIds: values?.genres,
      includedContentMasters: values?.includedContentMasters,
      excludedContentMasters: values?.excludedContentMasters,
      dimensions: values?.dimensions,
    };
    let images = [];
    let video = '';
    if (values?.video) {
      const { name } = values?.video?.[0];
      const ext = name?.substring(name?.lastIndexOf('.') + 1);
      const timestamp = Date?.now();
      const filename = name?.split('.')?.slice(0, -1)?.join('.');
      const newFilename = `${timestamp}_${filename}.${ext}`;
      const fileKey = `toy/${uuid}/${newFilename}`;

      const res = await getToyVideoSignedPutUrl({
        variables: {
          data: {
            fileName: fileKey,
            imageUuid: uuid,
          },
        },
      });
      if (res?.data) {
        try {
          try {
            await fileUpload(
              res?.data?.getToyVideoSignedPutUrl?.signedUrl,
              toyVideo?.[0]?.originFileObj,
            );
            video = res?.data?.getToyVideoSignedPutUrl?.fileName;
          } catch (error) {
            throw new Error(
              `${toyVideo?.name} upload failed. Please try again.`,
            );
          }
        } catch (error) {
          setIsSubmitLoading(false);
          openNotification('error', error.message);
        }
      }
    }
    if (values?.images) {
      const fileKeyArray = values?.images?.map((item) => {
        const { name } = item;
        const ext = name?.substring(name?.lastIndexOf('.') + 1);
        const timestamp = Date?.now();
        const filename = name?.split('.')?.slice(0, -1)?.join('.');
        const newFilename = `${timestamp}_${filename}.${ext}`;
        const fileKey = `toy/${uuid}/${newFilename}`;

        return fileKey;
      });

      const featuredImageArray = values?.featuredImages?.map((item) => {
        const { name } = item;
        const ext = name?.substring(name?.lastIndexOf('.') + 1);
        const timestamp = Date?.now();
        const filename = name?.split('.')?.slice(0, -1)?.join('.');
        const newFilename = `${timestamp}_${filename}.${ext}`;
        const fileKey = `toy/${uuid}/${newFilename}`;

        return fileKey;
      });

      const newArray = featuredImageArray?.concat(fileKeyArray);

      const res = await getToysSignUrl({
        variables: {
          data: {
            fileNames: newArray,
            imageUuid: uuid,
          },
        },
      });

      const toyAllImages = values?.featuredImages?.concat(values?.images);

      if (res?.data) {
        try {
          const response = await Promise.all(
            res?.data?.getToyImagesSignedPutUrl?.map(
              async (signedData, index) => {
                try {
                  await fileUpload(
                    signedData?.signedUrl,
                    toyAllImages?.[index]?.originFileObj,
                  );
                  return {
                    contentType: toyAllImages?.[index]?.type,
                    key: signedData?.fileName,
                    order: index + 1,
                    size: `${toyAllImages?.[index]?.size}`,
                  };
                } catch (error) {
                  throw new Error(
                    `${toyAllImages?.[index]?.name} upload failed. Please try again.`,
                  );
                }
              },
            ),
          );

          images = response;
        } catch (error) {
          setIsSubmitLoading(false);
          openNotification('error', error.message);
        }
      }
    }
    try {
      const response = await createToy({
        variables: { data: { ...toyObj, images, videoKey: video } },
      });
      if (response?.data) {
        history?.replace(`${ROUTES?.MASTER_LISTS}${ROUTES?.TOY}`);
      }
    } catch (error) {
      setIsSubmitLoading(false);
      return error;
    }
    setIsSubmitLoading(false);
  };

  const handleShowPrompt = () => {
    setIsDisabled(false);
    dispatch({
      type: 'SET_SHOW_PROMPT',
      data: true,
    });
  };

  return (
    <>
      <Portal portalId="header-title-content">
        <GoBackButton customLink={`${ROUTES?.MASTER_LISTS}${ROUTES?.TOY}`} />
        <div className="portal-header">Add New Toy</div>
      </Portal>
      <Portal portalId="header-right-content">
        <div className="header-btn-wrapper">
          <Button
            type="primary"
            className="common-button mr-16"
            size="middle"
            onClick={() =>
              history?.push(`${ROUTES?.MASTER_LISTS}${ROUTES?.TOY}`)
            }
          >
            Cancel
          </Button>
          <Button
            className="common-button"
            size="middle"
            onClick={form.submit}
            loading={isSubmitLoading}
            disabled={isDisabled}
          >
            Save
          </Button>
        </div>
      </Portal>
      <Portal portalId="header-right-content-phones">
        <div className="header-btn-wrapper">
          <Button
            type="primary"
            className="common-button mr-16"
            size="middle"
            onClick={() =>
              history?.push(`${ROUTES?.MASTER_LISTS}${ROUTES?.TOY}`)
            }
          >
            Cancel
          </Button>
          <Button
            className="common-button"
            size="middle"
            onClick={form.submit}
            loading={isSubmitLoading}
            disabled={isDisabled}
          >
            Save
          </Button>
        </div>
      </Portal>
      <Card className="full-height-with-nav" title="Toy Details">
        <div className="toy-form">
          <ToyForm
            form={form}
            onFinish={createToyFun}
            handleShowPrompt={handleShowPrompt}
            toyImages={toyImages}
            setToyImages={setToyImages}
            toyVideo={toyVideo}
            setToyVideo={setToyVideo}
            featuredImage={featuredImage}
            setFeaturedImage={setFeaturedImage}
          />
        </div>
      </Card>
    </>
  );
};

export default CreateToy;
