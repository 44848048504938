import { Divider, Form } from 'antd';
import React, { useState } from 'react';
import { USER_ROLES } from '../../../../../common/constants';
import { formValidatorRules } from '../../../../../common/utils';
import ModalComponent from '../../../../../components/ModalComponent';
import UserSelect from '../../../../payments/modules/endUser/components/UserSelect';

const { Item } = Form;
const { required } = formValidatorRules;

const AssignDeliveryPartnerModal = ({
  isModalOpen,
  setIsModalOpen,
  onFinish,
  loadings,
  form,
  title,
}) => {
  const [isValidateTrigger, setIsValidateTrigger] = useState(false);

  const handleCancel = () => {
    setIsModalOpen(false);
    form?.resetFields();
  };

  return (
    <>
      <ModalComponent
        open={isModalOpen}
        onCancel={handleCancel}
        onOk={form?.submit}
        title={title}
        okText="Save"
        confirmLoading={loadings}
        destroyOnClose
      >
        <Divider />
        <Form
          form={form}
          onFinish={onFinish}
          autoComplete="off"
          layout="vertical"
          validateTrigger={isValidateTrigger ? 'onChange' : 'onSubmit'}
          onFinishFailed={() => setIsValidateTrigger(true)}
        >
          <Item
            label="Delivery Partner"
            name="name"
            rules={[
              { ...required, message: 'Please Select Delivery Partner!' },
            ]}
          >
            <UserSelect
              placeholder="Select Delivery Partner"
              roles={USER_ROLES?.DELIVERY?.key}
            />
          </Item>
        </Form>
      </ModalComponent>
    </>
  );
};

export default AssignDeliveryPartnerModal;
