import { Tabs } from 'antd';
import React, { useContext, useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { AppContext } from '../../../../../AppContext';
import {
  DATE_FORMATS,
  PERMISSIONS_KEY,
  PERMISSION_TYPE,
  ROUTES,
  TABS,
} from '../../../../../common/constants';
import {
  filterTabsByPermissions,
  formatDate,
  hasPermission,
} from '../../../../../common/utils';

const LibraryTabs = (props) => {
  const history = useHistory();
  const { libraryId, libraryNumber, isView, onboardedOn, children } = props;
  const location = useLocation();
  const {
    state: { permissions },
  } = useContext(AppContext);
  const [activeTabKey, setActiveTabKey] = useState(TABS?.BASIC_DETAILS?.key);

  const hasUpdatePermission = hasPermission(
    PERMISSIONS_KEY?.MASTER_LIST_LIBRARY_BASIC_DETAILS,
    PERMISSION_TYPE?.UPDATE,
    permissions,
  );

  const tabList = [
    {
      key: TABS?.BASIC_DETAILS?.key,
      label: TABS?.BASIC_DETAILS?.label,
      children,
      permissionKey: PERMISSIONS_KEY?.MASTER_LIST_LIBRARY_BASIC_DETAILS,
    },
    libraryId && {
      key: TABS?.INVENTORY_DETAILS?.key,
      label: TABS?.INVENTORY_DETAILS?.label,
      children,
      permissionKey: PERMISSIONS_KEY?.MASTER_LIST_LIBRARY_INVENTORY_DETAILS,
    },
  ];

  const filteredTabs = filterTabsByPermissions(tabList, permissions);

  useEffect(() => {
    if (location?.pathname?.includes(ROUTES?.BASIC_DETAILS)) {
      setActiveTabKey(TABS?.BASIC_DETAILS?.key);
    }
    if (location?.pathname?.includes(ROUTES?.INVENTORY_DETAILS)) {
      setActiveTabKey(TABS?.INVENTORY_DETAILS?.key);
    }
  }, [location]);

  const onTabChange = (key) => {
    setActiveTabKey(key);
    switch (key) {
      case TABS?.BASIC_DETAILS?.key:
        if (!libraryId) {
          history?.push(
            `${ROUTES?.MASTER_LISTS}${ROUTES?.LIBRARY}${ROUTES?.BASIC_DETAILS}/add`,
          );
        } else if (!isView && hasUpdatePermission) {
          history?.push(
            `${ROUTES?.MASTER_LISTS}${ROUTES?.LIBRARY}${ROUTES?.BASIC_DETAILS}/edit/${libraryId}/${libraryNumber}`,
          );
        } else {
          history?.push(
            `${ROUTES?.MASTER_LISTS}${ROUTES?.LIBRARY}${ROUTES?.BASIC_DETAILS}/view/${libraryId}/${libraryNumber}`,
          );
        }
        break;
      case TABS?.INVENTORY_DETAILS?.key:
        if (!isView) {
          history?.push(
            `${ROUTES?.MASTER_LISTS}${ROUTES?.LIBRARY}${ROUTES?.INVENTORY_DETAILS}/edit/${libraryId}/${libraryNumber}`,
          );
        } else {
          history?.push(
            `${ROUTES?.MASTER_LISTS}${ROUTES?.LIBRARY}${ROUTES?.INVENTORY_DETAILS}/view/${libraryId}/${libraryNumber}`,
          );
        }
        break;
      default:
        break;
    }
  };

  return (
    <>
      <div className="card-container">
        <Tabs
          destroyInactiveTabPane
          activeKey={activeTabKey}
          type="card"
          className="full-width"
          onChange={(key) => onTabChange(key)}
          items={filteredTabs}
          tabBarExtraContent={
            activeTabKey === TABS?.BASIC_DETAILS?.key && libraryId ? (
              <strong>
                Onboarded On :{' '}
                {formatDate(onboardedOn, DATE_FORMATS?.ONBOARD) || '-'}
              </strong>
            ) : (
              false
            )
          }
        />
      </div>
    </>
  );
};

export default LibraryTabs;
