import { PlusOutlined } from '@ant-design/icons';
import { Col, Form, Input, Row, Upload } from 'antd';
import React, { useContext, useEffect, useState } from 'react';
import { AppContext } from '../../../AppContext';
import { GENDERS } from '../../../common/constants';
import {
  formValidatorRules,
  getBase64,
  validateImage,
} from '../../../common/utils';
import CitySelect from '../../../components/CitySelect';
import CommonPreview from '../../../components/CommonPreview';
import CountrySelect from '../../../components/CountrySelect';
import PincodeSelectName from '../../../components/PincodeSelectName';
import SelectComponent from '../../../components/SelectComponent';
import StateSelect from '../../../components/StateSelect';

const { required, name, email, phone } = formValidatorRules;

const ProfileForm = ({
  form,
  setIsDisabled,
  onFinish,
  initialValues,
  userProfilePic,
  userData,
  setUserProfilePic,
}) => {
  const { dispatch } = useContext(AppContext);
  const [countryId, setCountryId] = useState('');
  const [stateId, setStateId] = useState(initialValues?.state);
  const [isStateIdChanged, setIsStateIdChanged] = useState(false);

  const [visible, setVisible] = useState(false);
  const [profileImage, setProfileImage] = useState();

  useEffect(() => {
    const initialValueFun = () => {
      form?.setFieldsValue({
        ...initialValues,
        country: countryId,
      });
    };
    setTimeout(initialValueFun, 300);
  }, [countryId]);

  const handleShowPrompt = () => {
    setIsDisabled(false);
    dispatch({
      type: 'SET_SHOW_PROMPT',
      data: true,
    });
  };

  const handleChange = (info) => {
    validateImage(info, form, setUserProfilePic, 'profileImage', true);
  };

  const handlePreview = async (fileObj) => {
    let preview;
    if (!fileObj?.url && !fileObj?.preview) {
      preview = await getBase64(fileObj?.originFileObj);
    }
    setVisible(true);
    setProfileImage(fileObj?.url || preview);
  };

  return (
    <>
      <CommonPreview
        visible={visible}
        setVisible={setVisible}
        image={profileImage}
        setImage={setProfileImage}
      />
      <Form
        form={form}
        className="sticky-action-form"
        onFieldsChange={handleShowPrompt}
        layout="vertical"
        onFinish={onFinish}
      >
        <div className="card-body-wrapper">
          <Row gutter={[16, 0]}>
            <Col xs={24} lg={24} xl={24}>
              <Form.Item name="profileImage" label="Profile Photo">
                <Upload
                  name="avatar"
                  listType="picture-circle"
                  maxCount={1}
                  fileList={userProfilePic}
                  beforeUpload={() => false}
                  onPreview={handlePreview}
                  onChange={handleChange}
                  className="avatar-uploader"
                  showUploadList={{
                    showDownloadIcon: false,
                    showPreviewIcon: true,
                    showRemoveIcon: true,
                  }}
                >
                  {userProfilePic?.length === 0 && <PlusOutlined />}
                </Upload>
              </Form.Item>
            </Col>
            <Col xs={24} sm={12} md={12} lg={8} xl={8} xxl={8}>
              <Form.Item
                name="firstName"
                label="First Name"
                rules={[
                  { ...required, message: 'Please Enter Your First Name' },
                  name,
                ]}
              >
                <Input
                  allowClear
                  placeholder="Enter First Name"
                  maxLength={50}
                />
              </Form.Item>
            </Col>
            <Col xs={24} sm={12} md={12} lg={8} xl={8} xxl={8}>
              <Form.Item
                name="lastName"
                label="Last Name"
                rules={[
                  { ...required, message: 'Please Enter Your Last Name' },
                  name,
                ]}
              >
                <Input
                  allowClear
                  placeholder="Enter Last Name"
                  maxLength={50}
                />
              </Form.Item>
            </Col>
            <Col xs={24} sm={12} md={12} lg={8} xl={8} xxl={8}>
              <Form.Item
                name="email"
                label="Email Id"
                rules={[
                  { ...required, message: 'Please Enter Your Email' },
                  email,
                ]}
              >
                <Input disabled placeholder="Enter Email Id" maxLength={255} />
              </Form.Item>
            </Col>
            <Col xs={24} sm={12} md={12} lg={8} xl={8} xxl={8}>
              <Form.Item
                label="Contact Details"
                name="contactNo"
                rules={[
                  {
                    ...required,
                    message: 'Please Enter Your Contact Details!',
                  },
                  phone,
                ]}
              >
                <Input
                  allowClear
                  placeholder="Enter Contact Details"
                  maxLength={12}
                />
              </Form.Item>
            </Col>
            <Col xs={24} sm={12} md={12} lg={8} xl={8} xxl={8}>
              <Form.Item
                label="Gender"
                name="gender"
                rules={[
                  {
                    required: true,
                    message: 'Please Enter Your Gender!',
                  },
                ]}
              >
                <SelectComponent
                  placeholder="Select Gender"
                  options={GENDERS}
                />
              </Form.Item>
            </Col>
            <Col xs={24} sm={12} md={12} lg={8} xl={8} xxl={8}>
              <Form.Item
                label="Address"
                name="address"
                rules={[
                  {
                    ...required,
                    message: 'Please Enter Your Address!',
                  },
                ]}
              >
                <Input allowClear placeholder="Enter Address" maxLength={250} />
              </Form.Item>
            </Col>
            <Col xs={24} sm={12} md={12} lg={12} xl={6} xxl={6}>
              <Form.Item
                label="Country"
                name="country"
                rules={[
                  { required: true, message: 'Please Select Your Country!' },
                ]}
              >
                <CountrySelect setCountryId={setCountryId} />
              </Form.Item>
            </Col>
            <Col xs={24} sm={12} md={12} lg={12} xl={6} xxl={6}>
              <Form.Item
                label="State"
                name="state"
                rules={[
                  { required: true, message: 'Please Select Your State!' },
                ]}
              >
                <StateSelect
                  countryId={countryId}
                  setStateId={setStateId}
                  userData={userData}
                  onChange={(value) => {
                    form?.setFieldsValue({
                      city: null,
                    });
                    setStateId(value);
                    setIsStateIdChanged(true);
                  }}
                />
              </Form.Item>
            </Col>
            <Col xs={24} sm={12} md={12} lg={12} xl={6} xxl={6}>
              <Form.Item
                label="City"
                name="city"
                rules={[
                  { required: true, message: 'Please Select Your City!' },
                ]}
              >
                <CitySelect
                  stateId={stateId}
                  userData={userData}
                  isStateIdChanged={isStateIdChanged}
                />
              </Form.Item>
            </Col>
            <Col xs={24} sm={12} md={12} lg={12} xl={6} xxl={6}>
              <Form.Item
                label="Pincode"
                name="userPincode"
                rules={[
                  { required: true, message: 'Please Select Your Pincode!' },
                ]}
              >
                <PincodeSelectName userData={userData} />
              </Form.Item>
            </Col>
          </Row>
        </div>
      </Form>
    </>
  );
};

export default ProfileForm;
