import { Button, Card, Dropdown, Image, Tag } from 'antd';
import React, { useState } from 'react';
import { ReactComponent as MoreIcon } from '../../../assets/svg/ellipsis-vertical-solid.svg';
import { ACTIONS } from '../../../common/constants';

const items = [
  {
    label: ACTIONS?.EDIT?.label,
    key: ACTIONS?.EDIT?.value,
  },
];

const InventoryCard = ({
  inventory,
  setVisible,
  setToyImages,
  infiniteScrollRef,
  setInventory,
  setIsEditInventoryModalOpen,
}) => {
  const maxTagsToShow = 5;
  const [showAllFacilitates, setShowAllFacilitates] = useState(false);
  const [showAllCategories, setShowAllCategories] = useState(false);
  const [showAllAgeGroups, setShowAllAgeGroups] = useState(false);
  const [showAllTags, setShowAllTags] = useState(false);
  const toy = inventory?.toy;

  const renderedCategories = showAllCategories
    ? toy?.categories
    : toy?.categories?.slice(0, maxTagsToShow);

  const renderedFacilitates = showAllFacilitates
    ? toy?.facilitates
    : toy?.facilitates?.slice(0, maxTagsToShow);

  const renderedAgeGroups = showAllAgeGroups
    ? toy?.ageGroups
    : toy?.ageGroups?.slice(0, maxTagsToShow);

  const renderedTags = showAllTags
    ? toy?.tags
    : toy?.tags?.slice(0, maxTagsToShow);

  const handleMenuClick = () => {
    setInventory(inventory);
    setIsEditInventoryModalOpen(true);
  };

  const menuProps = {
    items,
    onClick: handleMenuClick,
  };

  return (
    <Card
      className="ml-12 mr-12 mb-24 mt-12 mobile-card"
      title={
        <div className="overflow-scroll no-scroll-bar">{toy?.toyNumber}</div>
      }
      extra={
        <div className="d-flex align-center">
          <span className="mr-16 ml-16">
            {toy?.images?.length > 0 && (
              <>
                <Image
                  preview={{
                    visible: false,
                  }}
                  width={20}
                  height={20}
                  src={toy?.images?.[0]?.url}
                  onClick={(e) => {
                    e?.stopPropagation();
                    setVisible(true);
                    setToyImages(toy?.images);
                  }}
                  className="pointer"
                />
              </>
            )}
            {toy?.images?.length > 1 ? ` + ${toy?.images?.length - 1}` : ''}
          </span>
          <Dropdown
            getPopupContainer={(trigger) => trigger?.parentNode}
            menu={menuProps}
          >
            <MoreIcon className="pointer mobile-svg-icon" />
          </Dropdown>
        </div>
      }
      ref={infiniteScrollRef}
    >
      <div className="mb-8">
        <span className="mobile-card-label">Product Name: </span>
        <span>{toy?.name}</span>
      </div>
      <div className="mb-8">
        <span className="mobile-card-label">Brand: </span>
        <span>{toy?.brand?.name}</span>
      </div>
      <div className="mb-8">
        <span className="mobile-card-label">Material: </span>
        <span>{toy?.material?.name}</span>
      </div>
      <div className="mb-8">
        <span className="mobile-card-label">Categories: </span>
        <span>
          {renderedCategories?.map((category) => (
            <Tag key={category?.id}>{category?.name}</Tag>
          ))}
          {toy?.categories?.length > maxTagsToShow && !showAllCategories && (
            <Button
              type="link"
              size="small"
              onClick={() => setShowAllCategories(true)}
            >
              ...more
            </Button>
          )}
        </span>
      </div>
      <div className="mb-8">
        <span className="mobile-card-label">Age Groups: </span>
        <span>
          {renderedAgeGroups?.map((ageGroup) =>
            ageGroup?.minAge > 18 ? (
              <Tag key={ageGroup?.id}>
                {Math.floor(ageGroup?.minAge / 12)} -{' '}
                {Math.floor(ageGroup?.maxAge / 12)} Years
              </Tag>
            ) : (
              <Tag key={ageGroup?.id}>
                {ageGroup?.minAge} - {ageGroup?.maxAge} Months
              </Tag>
            ),
          )}
          {toy?.ageGroups?.length > maxTagsToShow && !showAllAgeGroups && (
            <Button
              type="link"
              size="small"
              onClick={() => setShowAllAgeGroups(true)}
            >
              ...more
            </Button>
          )}
        </span>
      </div>
      <div className="mb-8">
        <span className="mobile-card-label">Facilitates: </span>
        <span>
          {renderedFacilitates?.length > 0
            ? renderedFacilitates?.map((facilitate) => (
                <Tag key={facilitate?.id}>{facilitate?.name}</Tag>
              ))
            : 'No Facilitates'}
          {toy?.facilitates?.length > maxTagsToShow && !showAllFacilitates && (
            <Button
              type="link"
              size="small"
              onClick={() => setShowAllFacilitates(true)}
            >
              ...more
            </Button>
          )}
        </span>
      </div>
      <div className="mb-8">
        <span className="mobile-card-label">Tags: </span>
        <span>
          {renderedTags?.length > 0
            ? renderedTags?.map((tag) => <Tag key={tag?.id}>{tag?.name}</Tag>)
            : 'No Tags'}
          {toy?.tags?.length > maxTagsToShow && !showAllTags && (
            <Button
              type="link"
              size="small"
              onClick={() => setShowAllTags(true)}
            >
              ...more
            </Button>
          )}
        </span>
      </div>
      <div className="mb-8">
        <span className="mobile-card-label">Total Stock: </span>
        <span>{inventory?.availableStock + inventory?.rentedOut}</span>
      </div>
      <div className="mb-8">
        <span className="mobile-card-label">Rented Out: </span>
        <span>{inventory?.rentedOut}</span>
      </div>
      <div className="mb-8">
        <span className="mobile-card-label">Currently Available: </span>
        <span>{inventory?.availableStock}</span>
      </div>
    </Card>
  );
};

export default InventoryCard;
