import { gql } from '@apollo/client';

// eslint-disable-next-line import/prefer-default-export
export const CREATE_BAG = gql`
  mutation CreateBag($data: CreateBagInput!) {
    createBag(data: $data) {
      message
    }
  }
`;

export const UPDATE_BAG = gql`
  mutation UpdateBag($where: BagUniqueInput!, $data: UpdateBagInput) {
    updateBag(where: $where, data: $data) {
      message
    }
  }
`;
