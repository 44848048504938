import { gql } from '@apollo/client';

export const LOGIN = gql`
  mutation signIn($data: SignInInput!) {
    signIn(data: $data) {
      data {
        contactNo
        address
        city
        createdAt
        permissions
        email
        firstName
        gender
        id
        isActive
        isArchived
        lastName
        refreshToken
        pincode {
          id
          pincode
        }
        roles
        state
        type
        updatedAt
      }
      token
      message
    }
  }
`;

export const SIGNUP = gql`
  mutation emailPasswordSignUp($data: EmailPasswordSignUpData!) {
    emailPasswordSignUp(data: $data) {
      message
    }
  }
`;

export const FORGOT_PASSWORD = gql`
  mutation forgotPassword($data: ForgotPasswordInput!) {
    forgotPassword(data: $data) {
      message
    }
  }
`;

export const RESET_PASSWORD = gql`
  mutation resetPassword($data: ResetPasswordInput!) {
    resetPassword(data: $data) {
      message
    }
  }
`;

export const CHANGE_PASSWORD = gql`
  mutation changePassword($data: ChangePasswordInput!) {
    changePassword(data: $data) {
      message
    }
  }
`;

export const VERIFY_TOKEN = gql`
  mutation emailPasswordVerifyResetToken($token: String!) {
    emailPasswordVerifyResetToken(data: { token: $token }) {
      message
      data {
        isValidToken
      }
    }
  }
`;

export const UPDATE_CURRENT_USER = gql`
  mutation updateCurrentUser($data: UpdateCurrentUserInput!) {
    updateCurrentUser(data: $data) {
      message
    }
  }
`;

export const UPDATE_USER = gql`
  mutation updateUser($where: UserUniqueInput!, $data: UserUpdateInput!) {
    updateUser(where: $where, data: $data) {
      message
    }
  }
`;

export const REFRESH_TOKEN = gql`
  mutation RefreshToken($data: RefreshTokenInput!) {
    refreshToken(data: $data) {
      data {
        contactNo
        address
        city
        createdAt
        permissions
        email
        firstName
        gender
        id
        isActive
        isArchived
        lastName
        refreshToken
        pincode {
          id
          pincode
        }
        roles
        state
        type
        updatedAt
      }
      token
      message
    }
  }
`;
