import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import AccessControl from '../../../../AccessControl';
import Error404 from '../../../../Error404';
import { PERMISSIONS_KEY, ROUTES } from '../../../../common/constants';
import EditTrial from './pages/EditTrial';
import WeeklyTrial from './pages/Trial';
import './trial.less';

const TrialWrapper = () => (
  <Switch>
    <Route
      exact
      path={`${ROUTES?.SUBSCRIPTION_PLANS}${ROUTES?.TRIAL}`}
      render={() => (
        <AccessControl permissionKey={PERMISSIONS_KEY?.SUBSCRIPTION_PLANS}>
          <Redirect
            to={`${ROUTES?.SUBSCRIPTION_PLANS}${ROUTES?.TRIAL}${ROUTES?.MONTHLY}`}
          />
        </AccessControl>
      )}
    />
    <Route
      exact
      path={`${ROUTES?.SUBSCRIPTION_PLANS}${ROUTES?.TRIAL}${ROUTES?.MONTHLY}`}
      render={(props) => (
        <AccessControl permissionKey={PERMISSIONS_KEY?.SUBSCRIPTION_PLANS}>
          <WeeklyTrial {...props} />
        </AccessControl>
      )}
    />
    <Route
      exact
      path={`${ROUTES?.SUBSCRIPTION_PLANS}${ROUTES?.TRIAL}${ROUTES?.MONTHLY}/edit`}
      render={(props) => (
        <AccessControl permissionKey={PERMISSIONS_KEY?.SUBSCRIPTION_PLANS}>
          <EditTrial {...props} />
        </AccessControl>
      )}
    />
    <Route path="*" exact component={Error404} />
  </Switch>
);

export default TrialWrapper;
