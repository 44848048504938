import React from 'react';
import { Route, Switch } from 'react-router-dom';
import Error404 from '../Error404';
import { ROUTES } from '../common/constants';
import InventoryWrapper from '../libraryModules/inventory';
import NotificationsWrapper from '../libraryModules/notifications';
import OffersWrapper from '../libraryModules/offers';
import CouponWrapper from '../libraryModules/offers/modules/coupon';
import ReferralWrapper from '../libraryModules/offers/modules/referral';
import OrdersWrapper from '../libraryModules/orders';
import AllOrdersLibraryWrapper from '../libraryModules/orders/modules/all';
import NewWrapper from '../libraryModules/orders/modules/new';
import PaymentsWrapper from '../libraryModules/payments';
import RackMasterWrapper from '../libraryModules/rackMaster';
import RaiseRequestWrapper from '../libraryModules/raiseRequest';
import SupportWrapper from '../libraryModules/support';
import DashboardWrapper from '../modules/dashboard';
import ExportsWrapper from '../modules/exports';
import ProfileWrapper from '../modules/profile';

const LibraryContentRoutes = () => (
  <Switch>
    <Route path={ROUTES?.MAIN} exact component={DashboardWrapper} />
    <Route
      path={`${ROUTES?.ORDERS}${ROUTES?.ALL}`}
      component={AllOrdersLibraryWrapper}
    />
    <Route path={`${ROUTES?.ORDERS}${ROUTES?.NEW}`} component={NewWrapper} />
    <Route
      path={`${ROUTES?.OFFERS}${ROUTES?.COUPON}`}
      component={CouponWrapper}
    />
    <Route
      path={`${ROUTES?.OFFERS}${ROUTES?.REFERRAL}`}
      component={ReferralWrapper}
    />
    <Route path={ROUTES?.RACK_MASTER} component={RackMasterWrapper} />
    <Route path={ROUTES?.NOTIFICATIONS} component={NotificationsWrapper} />
    <Route path={ROUTES?.ORDERS} exact component={OrdersWrapper} />
    <Route path={ROUTES?.PAYMENTS} exact component={PaymentsWrapper} />
    <Route path={ROUTES?.PROFILE} exact component={ProfileWrapper} />
    <Route path={ROUTES?.INVENTORY} component={InventoryWrapper} />
    <Route path={ROUTES?.RAISE_REQUEST} component={RaiseRequestWrapper} />
    <Route path={ROUTES?.OFFERS} exact component={OffersWrapper} />
    <Route path={ROUTES?.SUPPORT} exact component={SupportWrapper} />
    <Route path={ROUTES?.EXPORTS} exact component={ExportsWrapper} />
    <Route path="*" exact component={Error404} />
  </Switch>
);

export default LibraryContentRoutes;
