import { gql } from '@apollo/client';

// eslint-disable-next-line import/prefer-default-export
export const CREATE_GENRE = gql`
  mutation CreateGenre($data: CreateGenreInput!) {
    createGenre(data: $data) {
      message
    }
  }
`;

export const UPDATE_GENRE = gql`
  mutation UpdateGenre($where: GenreUniqueInput!, $data: UpdateGenreInput) {
    updateGenre(where: $where, data: $data) {
      message
    }
  }
`;

export const MAP_GENRE_TOYS = gql`
mutation MapToysGenre($where: GenreUniqueInput!, $data: ToyGenreMappingInput) {
  mapToysGenre(where: $where, data: $data) {
    message
  }
}
`;

export const REMOVE_GENRE_TOYS = gql`
mutation RemoveToysGenre($where: GenreUniqueInput!, $data: ToyGenreMappingInput) {
  removeToysGenre(where: $where, data: $data) {
    message
  }
}`