import { useMutation } from '@apollo/client';
import { Button, Card, Form } from 'antd';
import moment from 'moment-timezone';
import React, { useContext, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { AppContext } from '../../../../../AppContext';
import { ROUTES, USER_ROLES } from '../../../../../common/constants';
import GoBackButton from '../../../../../components/GoBackButton';
import Portal from '../../../../../components/Portal';
import { CREATE_USER } from '../../../graphql/Mutations';
import DeliveryForm from '../components/DeliveryForm';

const roleFilter = [USER_ROLES?.DELIVERY?.key];

const CreateDelivery = () => {
  const history = useHistory();
  const [form] = Form.useForm();
  const { dispatch } = useContext(AppContext);
  const [isDisabled, setIsDisabled] = useState(true);
  const [isSubmitLoading, setIsSubmitLoading] = useState(false);

  const [createUser] = useMutation(CREATE_USER, {
    onError: () => {},
  });

  const createDeliveryFun = async (deliveryDetails) => {
    setIsSubmitLoading(true);
    dispatch({
      type: 'SET_SHOW_PROMPT',
      data: false,
    });
    const response = await createUser({
      variables: {
        data: {
          firstName: deliveryDetails?.firstName?.trim(),
          lastName: deliveryDetails?.lastName?.trim(),
          gender: deliveryDetails?.gender,
          city: deliveryDetails?.city,
          state: deliveryDetails?.state,
          roles: roleFilter,
          contactNo: deliveryDetails?.contact?.trim(),
          address: deliveryDetails?.address?.trim(),
          email: deliveryDetails?.email?.trim(),
          pincode: deliveryDetails?.pincode,
          agencyName: deliveryDetails?.agencyName?.trim(),
          vehicleNumber: deliveryDetails?.vehicleNumber?.trim(),
          timezone: moment?.tz?.guess(),
        },
      },
    });
    if (response?.data) {
      history?.replace(`${ROUTES?.SYSTEM_USERS}${ROUTES?.DELIVERY_PARTNERS}`);
    }
    setIsSubmitLoading(false);
  };

  const handleShowPrompt = () => {
    setIsDisabled(false);
    dispatch({
      type: 'SET_SHOW_PROMPT',
      data: true,
    });
  };

  return (
    <>
      <Portal portalId="header-title-content">
        <GoBackButton
          customLink={`${ROUTES?.SYSTEM_USERS}${ROUTES?.DELIVERY_PARTNERS}`}
        />
        <div className="portal-header">Add New Delivery Partner</div>
      </Portal>
      <Portal portalId="header-right-content">
        <div className="header-btn-wrapper">
          <Button
            type="primary"
            className="common-button mr-16"
            size="middle"
            onClick={() =>
              history?.push(
                `${ROUTES?.SYSTEM_USERS}${ROUTES?.DELIVERY_PARTNERS}`,
              )
            }
          >
            Cancel
          </Button>
          <Button
            className="common-button"
            size="middle"
            onClick={form.submit}
            loading={isSubmitLoading}
            disabled={isDisabled}
          >
            Save
          </Button>
        </div>
      </Portal>
      <Portal portalId="header-right-content-phones">
        <div className="header-btn-wrapper">
          <Button
            type="primary"
            className="common-button mr-16"
            size="middle"
            onClick={() =>
              history?.push(
                `${ROUTES?.SYSTEM_USERS}${ROUTES?.DELIVERY_PARTNERS}`,
              )
            }
          >
            Cancel
          </Button>
          <Button
            className="common-button"
            size="middle"
            onClick={form.submit}
            loading={isSubmitLoading}
            disabled={isDisabled}
          >
            Save
          </Button>
        </div>
      </Portal>
      <Card className="full-height-with-nav" title="Delivery Partner Details">
        <div className="delivery-form">
          <DeliveryForm
            form={form}
            onFinish={createDeliveryFun}
            handleShowPrompt={handleShowPrompt}
          />
        </div>
      </Card>
    </>
  );
};

export default CreateDelivery;
