import { gql } from '@apollo/client';

// eslint-disable-next-line import/prefer-default-export
export const UI_LABELS_ADMIN = gql`
  query UiLabelsAdmin($filter: UILabelFilter, $sort: UILabelSort) {
    uiLabelsAdmin(filter: $filter, sort: $sort) {
      count
      data {
        id
        group
        description
        createdAt
        isArchived
        key
        type
        value
      }
    }
  }
`;
