import { gql } from '@apollo/client';

// eslint-disable-next-line import/prefer-default-export
export const LIBRARY_INVENTORY_BULK_UPDATE_LOGS_ADMIN = gql`
  query libraryInventoryBulkUpdateLogsAdmin(
    $filter: LibraryInventoryBulkUpdateLogsFilterInput
    $sort: LibraryBulkUpdatesSort
    $where: LibraryUniqueInput!
  ) {
    libraryInventoryBulkUpdateLogsAdmin(
      filter: $filter
      sort: $sort
      where: $where
    ) {
      data {
        id
        updatedAt
        oldQuantity
        updatedQuantity
        reason
        libraryInventory {
          id
          totalStock
          toy {
            id
            name
            toyNumber
            categories {
              id
              name
            }
            images {
              url
              key
            }
          }
          library {
            id
            name
            libraryNumber
          }
        }
      }
      count
    }
  }
`;

export const LIBRARY_BULK_UPDATES = gql`
  query LibraryBulkUpdates(
    $filter: LibraryBulkUpdatesFilterInput
    $sort: LibraryBulkUpdatesSort
  ) {
    libraryBulkUpdates(filter: $filter, sort: $sort) {
      count
      data {
        id
        name
        bulkUpdateCount
        updatedOn
        libraryNumber
      }
    }
  }
`;

export const LIBRARIES = gql`
  query Libraries($filter: LibraryFilter, $sort: LibrarySort) {
    libraries(filter: $filter, sort: $sort) {
      count
      data {
        id
        name
      }
    }
  }
`;
