import React from 'react';
import { Route, Switch } from 'react-router-dom';
import AccessControl from '../../../../AccessControl';
import {
  PERMISSIONS_KEY,
  PERMISSION_TYPE,
  ROUTES,
} from '../../../../common/constants';
import Error404 from '../../../../Error404';
import './admins.less';
import Admins from './pages/Admins';
import CreateAdmin from './pages/CreateAdmin';
import EditAdmin from './pages/EditAdmin';
import ViewAdmin from './pages/ViewAdmin';

const AdminsWrapper = () => (
  <Switch>
    <Route
      exact
      path={`${ROUTES?.SYSTEM_USERS}${ROUTES?.ADMINS}`}
      render={(props) => (
        <AccessControl permissionKey={PERMISSIONS_KEY?.SYSTEM_USERS_ADMINS}>
          <Admins {...props} />
        </AccessControl>
      )}
    />
    <Route
      exact
      path={`${ROUTES?.SYSTEM_USERS}${ROUTES?.ADMINS}/add`}
      component={CreateAdmin}
      render={(props) => (
        <AccessControl
          permissionType={PERMISSION_TYPE?.CREATE}
          permissionKey={PERMISSIONS_KEY?.SYSTEM_USERS_ADMINS}
        >
          <CreateAdmin {...props} />
        </AccessControl>
      )}
    />
    <Route
      exact
      path={`${ROUTES?.SYSTEM_USERS}${ROUTES?.ADMINS}/edit/:id`}
      render={(props) => (
        <AccessControl
          permissionType={PERMISSION_TYPE?.UPDATE}
          permissionKey={PERMISSIONS_KEY?.SYSTEM_USERS_ADMINS}
        >
          <EditAdmin {...props} />
        </AccessControl>
      )}
    />
    <Route
      exact
      path={`${ROUTES?.SYSTEM_USERS}${ROUTES?.ADMINS}/view/:id`}
      render={(props) => (
        <AccessControl permissionKey={PERMISSIONS_KEY?.SYSTEM_USERS_ADMINS}>
          <ViewAdmin {...props} />
        </AccessControl>
      )}
    />
    <Route path="*" exact component={Error404} />
  </Switch>
);

export default AdminsWrapper;
