import React from 'react';
import { Route, Switch } from 'react-router-dom';
import { ROUTES } from '../../common/constants';
import Support from './pages/Support';
import './support.less';

const SupportWrapper = () => (
  <Switch>
    <Route exact path={ROUTES?.SUPPORT} component={Support} />
  </Switch>
);

export default SupportWrapper;
