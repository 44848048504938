import { Image } from 'antd';
import React from 'react';

const CommonPreview = ({ visible, setVisible, image, setImage }) => (
  <div className="d-none">
    <Image.PreviewGroup
      preview={{
        visible,
        onVisibleChange: (vis) => {
          setVisible(vis);
          setImage();
        },
      }}
    >
      <Image width={20} src={image} />
    </Image.PreviewGroup>
  </div>
);

export default CommonPreview;
