import { FilterFilled, FilterOutlined } from '@ant-design/icons';
import { useLazyQuery } from '@apollo/client';
import {
  Badge,
  Button,
  Checkbox,
  DatePicker,
  Divider,
  Empty,
  Radio,
  Tag,
} from 'antd';
import { filter, forEach, isEmpty, map } from 'lodash';
import React, { useContext, useEffect, useState } from 'react';
import { AppContext } from '../../../AppContext';
import { ReactComponent as FilterIcon } from '../../../assets/svg/sliders-solid.svg';
import {
  BREAKPOINTS,
  DATE_FORMATS,
  LIMIT,
  PAYMENT_STATUS,
  PAYMENT_STATUS_OPTIONS,
  SORT,
} from '../../../common/constants';
import {
  formatDate,
  handleDownload,
  hasNonEmptyValueObj,
} from '../../../common/utils';
import CommonTable from '../../../components/CommonTable';
import LoaderComponent from '../../../components/LoaderComponent';
import SearchComponent from '../../../components/SearchComponent';
import useInfiniteScroll from '../../../hooks/useInfiniteScroll';
import { INVOICES } from '../graphql/Queries';
import AllFilterModal from './AllFilterModal';
import InvoiceList from './InvoiceList';

const { RangePicker } = DatePicker;
const { Group } = Radio;

const initialPaginationValue = {
  total: 0,
  current: 1,
  pageSize: 10,
};

const initialInvoiceFilter = {
  limit: LIMIT,
  search: '',
  skip: 0,
};

const initialInvoiceSort = {
  field: 'createdAt',
  order: 'DESC',
};

const PaymentTable = () => {
  const {
    state: { isDesktop },
  } = useContext(AppContext);
  const [invoiceList, setInvoiceList] = useState([]);
  const [invoiceSearchTerm, setInvoiceSearchTerm] = useState('');
  const [isEmptyInvoiceList, setIsEmptyInvoiceList] = useState(false);
  const [query, setQuery] = useState('');
  const [sortedInfo, setSortedInfo] = useState({});
  const [paginationProp, setPaginationProp] = useState(initialPaginationValue);

  const [isInvoiceLoading, setIsInvoiceLoading] = useState(true);

  const [filterList, setFilterList] = useState([]);
  const [whereFilter, setWhereFilter] = useState();
  const [filterLoading, setFilterLoading] = useState(false);
  const [filterTags, setFilterTags] = useState(null);
  const [filterVisible, setFilterVisible] = useState(false);
  const [filterIndex, setFilterIndex] = useState(null);
  const [finalWhereFilters, setFinalWhereFilters] = useState();
  const [finalFilterTags, setFinalFilterTags] = useState(null);
  const [isEmptyFilterList, setIsEmptyFilterList] = useState(false);

  const [isAllFilterModalOpen, setIsAllFilterModalOpen] = useState(false);
  const [isFetchMoreInvoicesLoading, setIsFetchMoreInvoicesLoading] = useState(
    false,
  );
  const [isActive, setIsActive] = useState(true);

  const [invoices] = useLazyQuery(INVOICES, {
    onCompleted: (response) => {
      if (isDesktop) {
        setInvoiceList([...response?.invoices?.data]);
      } else {
        setInvoiceList([...invoiceList, ...response?.invoices?.data]);
      }
      if (
        response?.invoices?.count === 0 &&
        initialPaginationValue?.total === 0
      ) {
        setIsEmptyInvoiceList(true);
      } else {
        setIsEmptyInvoiceList(false);
      }
      if (response?.invoices?.data?.length < LIMIT) {
        setIsActive(false);
      }
      const pagination = {
        ...paginationProp,
        defaultPageSize: LIMIT,
        total: response?.invoices?.count,
      };
      setPaginationProp(pagination);
      setIsInvoiceLoading(false);
      setIsFetchMoreInvoicesLoading(false);
    },
    fetchPolicy: 'network-only',
    onError() {},
  });

  useEffect(() => {
    setPaginationProp({ ...paginationProp, current: 1, skip: 0 });
    setIsInvoiceLoading(true);
    invoices({
      variables: {
        filter: {
          ...initialInvoiceFilter,
          search: invoiceSearchTerm,
          limit: LIMIT,
          libraryIds: finalWhereFilters?.name,
          createdAt: {
            from: finalWhereFilters?.createdAt?.from?.$d,
            to: finalWhereFilters?.createdAt?.to?.$d,
          },
          paidOn: {
            from: finalWhereFilters?.paidOn?.from?.$d,
            to: finalWhereFilters?.paidOn?.to?.$d,
          },
          status: finalWhereFilters?.status,
        },
        sort: sortedInfo?.column
          ? {
              field: sortedInfo?.field,
              order: sortedInfo?.order === 'ascend' ? SORT?.ASC : SORT?.DESC,
            }
          : initialInvoiceSort,
      },
    });
  }, [isDesktop]);

  const handleTableChange = (pagination, tableFilter, sorter) => {
    const { current } = pagination;
    const skip = (current - 1) * (pagination?.pageSize || 0);
    setPaginationProp({ ...paginationProp, ...pagination });
    setIsInvoiceLoading(true);
    setSortedInfo(sorter);
    invoices({
      variables: {
        filter: {
          ...initialInvoiceFilter,
          skip,
          limit: pagination?.pageSize,
          search: invoiceSearchTerm,
          libraryIds: finalWhereFilters?.name,
          createdAt: {
            from: finalWhereFilters?.createdAt?.from?.$d,
            to: finalWhereFilters?.createdAt?.to?.$d,
          },
          paidOn: {
            from: finalWhereFilters?.paidOn?.from?.$d,
            to: finalWhereFilters?.paidOn?.to?.$d,
          },
          status: finalWhereFilters?.status,
        },
        sort: sorter?.column
          ? {
              field: sorter?.field,
              order: sorter?.order === 'ascend' ? SORT?.ASC : SORT?.DESC,
            }
          : initialInvoiceSort,
      },
    });
  };

  const handleSearch = (value) => {
    const trimValue = value?.trim();
    setInvoiceSearchTerm(trimValue);
    setInvoiceList([]);
    setPaginationProp({ ...paginationProp, current: 1, skip: 0 });
    setIsInvoiceLoading(true);
    invoices({
      variables: {
        filter: {
          ...initialInvoiceFilter,
          limit: paginationProp?.pageSize || LIMIT,
          search: trimValue,
          libraryIds: finalWhereFilters?.name,
          createdAt: {
            from: finalWhereFilters?.createdAt?.from?.$d,
            to: finalWhereFilters?.createdAt?.to?.$d,
          },
          paidOn: {
            from: finalWhereFilters?.paidOn?.from?.$d,
            to: finalWhereFilters?.paidOn?.to?.$d,
          },
          status: finalWhereFilters?.status,
        },
        sort: sortedInfo?.column
          ? {
              field: sortedInfo?.field,
              order: sortedInfo?.order === 'ascend' ? SORT?.ASC : SORT?.DESC,
            }
          : initialInvoiceSort,
      },
    });
  };

  const handleFilter = (values) => {
    setPaginationProp({ ...paginationProp, current: 1, skip: 0 });
    setIsInvoiceLoading(true);
    setIsAllFilterModalOpen(false);
    setInvoiceList([]);
    setIsActive(true);
    const filters = {
      status: values?.status,
      createdAt: values?.createdAt?.[1]
        ? { from: values?.createdAt?.[0], to: values?.createdAt?.[1] }
        : null,
      paidOn: values?.paidOn?.[1]
        ? { from: values?.paidOn?.[0], to: values?.paidOn?.[1] }
        : null,
    };
    setWhereFilter(filters);
    setFinalWhereFilters(filters);
    invoices({
      variables: {
        filter: {
          ...initialInvoiceFilter,
          limit: paginationProp?.pageSize || LIMIT,
          search: invoiceSearchTerm,
          createdAt: {
            from: values?.createdAt?.[0],
            to: values?.createdAt?.[1],
          },
          paidOn: {
            from: values?.paidOn?.[0],
            to: values?.paidOn?.[1],
          },
          status: values?.status,
        },
        sort: sortedInfo?.column
          ? {
              field: sortedInfo?.field,
              order: sortedInfo?.order === 'ascend' ? SORT?.ASC : SORT?.DESC,
            }
          : initialInvoiceSort,
      },
    });
  };

  const handleInvoicesScroll = () => {
    if (!isFetchMoreInvoicesLoading && !isInvoiceLoading) {
      const currentLength = invoiceList?.length;
      setIsFetchMoreInvoicesLoading(true);
      invoices({
        variables: {
          filter: {
            ...initialInvoiceFilter,
            skip: currentLength,
            limit: paginationProp?.pageSize || LIMIT,
            search: invoiceSearchTerm,
            libraryIds: finalWhereFilters?.name,
            createdAt: {
              from: finalWhereFilters?.createdAt?.from?.$d,
              to: finalWhereFilters?.createdAt?.to?.$d,
            },
            paidOn: {
              from: finalWhereFilters?.paidOn?.from?.$d,
              to: finalWhereFilters?.paidOn?.to?.$d,
            },
            status: finalWhereFilters?.status,
          },
          sort: sortedInfo?.column
            ? {
                field: sortedInfo?.field,
                order: sortedInfo?.order === 'ascend' ? SORT?.ASC : SORT?.DESC,
              }
            : initialInvoiceSort,
        },
      });
    }
  };

  // eslint-disable-next-line react-hooks/rules-of-hooks, no-use-before-define
  const infiniteScrollRef = useInfiniteScroll(handleInvoicesScroll, isActive);

  const getFilterData = (confirm) => {
    setIsInvoiceLoading(true);
    invoices({
      variables: {
        filter: {
          ...initialInvoiceFilter,
          limit: paginationProp?.pageSize || LIMIT,
          search: invoiceSearchTerm,
          libraryIds: whereFilter?.name,
          createdAt: {
            from: whereFilter?.createdAt?.from?.$d,
            to: whereFilter?.createdAt?.to?.$d,
          },
          paidOn: {
            from: whereFilter?.paidOn?.from?.$d,
            to: whereFilter?.paidOn?.to?.$d,
          },
          status:
            whereFilter?.status === PAYMENT_STATUS?.PAID?.value ||
            whereFilter?.status === PAYMENT_STATUS?.UNPAID?.value
              ? whereFilter?.status
              : undefined,
        },
        sort: sortedInfo?.column
          ? {
              field: sortedInfo?.field,
              order: sortedInfo?.order === 'ascend' ? SORT?.ASC : SORT?.DESC,
            }
          : initialInvoiceSort,
      },
    });
    setFinalWhereFilters({ ...finalWhereFilters, ...whereFilter });
    setFinalFilterTags(filterTags);
    setPaginationProp({ ...paginationProp, skip: 0, current: 1 });
    if (confirm) {
      confirm();
    }
  };

  const handleReset = (clearFilters, dataIndex, confirm) => {
    const tagsCopy = {
      ...filterTags,
      [dataIndex]: [],
    };
    let whereFiltersCopy;
    if (dataIndex === 'status') {
      whereFiltersCopy = {
        ...whereFilter,
        [dataIndex]: null,
      };
    } else {
      whereFiltersCopy = {
        ...whereFilter,
        [dataIndex]: [],
      };
    }
    setFilterVisible(false);
    setFinalFilterTags({ ...filterTags, ...tagsCopy });
    setFinalWhereFilters({ ...finalWhereFilters, ...whereFiltersCopy });
    setIsInvoiceLoading(true);
    invoices({
      variables: {
        filter: {
          ...initialInvoiceFilter,
          limit: paginationProp?.pageSize || LIMIT,
          search: invoiceSearchTerm,
          libraryIds: whereFiltersCopy?.name,
          createdAt: {
            from: whereFiltersCopy?.createdAt?.from?.$d,
            to: whereFiltersCopy?.createdAt?.to?.$d,
          },
          paidOn: {
            from: whereFiltersCopy?.paidOn?.from?.$d,
            to: whereFiltersCopy?.paidOn?.to?.$d,
          },
          status:
            whereFiltersCopy?.status === PAYMENT_STATUS?.PAID?.value ||
            whereFiltersCopy?.status === PAYMENT_STATUS?.UNPAID?.value
              ? whereFiltersCopy?.status
              : undefined,
        },
        sort: sortedInfo?.column
          ? {
              field: sortedInfo?.field,
              order: sortedInfo?.order === 'ascend' ? SORT?.ASC : SORT?.DESC,
            }
          : initialInvoiceSort,
      },
    });
    setPaginationProp({ ...paginationProp, skip: 0, current: 1 });
    clearFilters();
    setFilterVisible(false);
    if (confirm) {
      confirm();
    }
  };

  useEffect(() => {
    if (filterVisible) {
      setFilterList([]);
      setFilterLoading(true);
      setIsEmptyFilterList(false);
      setFilterTags({ ...filterTags, ...finalFilterTags });
      setWhereFilter({ ...whereFilter, ...finalWhereFilters });
      const optionsCopy = [];

      switch (filterIndex) {
        case 'status':
          forEach(PAYMENT_STATUS_OPTIONS, (item) => {
            optionsCopy?.push({ label: item?.label, key: item?.value });
          });
          setFilterList(optionsCopy);
          setFilterLoading(false);
          break;

        default:
          break;
      }
    }
    if (!filterVisible) {
      setFilterTags(finalFilterTags);
      setWhereFilter(finalWhereFilters);
    }
  }, [filterVisible]);

  const changeFilter = (newFilterObj, dataIndex) => {
    const {
      target: { value },
    } = newFilterObj;

    const filterTagsCopy = { ...filterTags };
    let newTagList;
    if (whereFilter?.[dataIndex]?.includes(value?.key)) {
      newTagList = {
        ...filterTagsCopy,
        [dataIndex]: filter(filterTagsCopy?.[dataIndex], (item) => {
          if (item?.label) {
            return item?.key !== value?.key;
          }
          return item !== value;
        }),
      };
    } else {
      newTagList = {
        ...filterTagsCopy,
        [dataIndex]: filterTagsCopy?.[dataIndex]
          ? [...filterTagsCopy?.[dataIndex], value]
          : [value],
      };
    }

    const updatedWhereFilter = {
      ...whereFilter,
      [dataIndex]: newTagList?.[dataIndex]?.map((item) => item?.key),
    };
    setWhereFilter(updatedWhereFilter);
    setFilterTags(newTagList);
  };

  const handleDeleteFilter = (value, dataIndex) => {
    let newTagList;

    if (whereFilter?.[dataIndex]?.includes(value?.key)) {
      newTagList = {
        ...filterTags,
        [dataIndex]: filter(filterTags?.[dataIndex], (item) => {
          if (item?.key) {
            return item?.key !== value?.key;
          }
          return item !== value;
        }),
      };
    }

    const updatedWhereFilter = {
      ...whereFilter,
      [dataIndex]: newTagList?.[dataIndex]?.map((item) => item?.key),
    };
    setWhereFilter(updatedWhereFilter);
    setFilterTags(newTagList);
  };

  const onRangePickerChange = (values, dataIndex) => {
    const rangeObj = {
      from: values[0]?.startOf('day'),
      to: values?.[1]?.endOf('day'),
    };

    const filtersCopy = {
      ...whereFilter,
      [dataIndex]: rangeObj,
    };
    if (!values?.length) {
      delete whereFilter?.[dataIndex];
    }
    setWhereFilter(filtersCopy);
  };

  const onRadioChange = (value, dataIndex) => {
    const filtersCopy = {
      ...whereFilter,
      [dataIndex]: value,
    };
    if (!value?.length) {
      delete whereFilter?.[dataIndex];
    }
    setWhereFilter(filtersCopy);
  };

  const filterPopup = (dataIndex) => ({
    filterDropdown: ({ confirm, clearFilters }) => {
      if (dataIndex === 'createdAt' || dataIndex === 'paidOn') {
        return (
          <div className="custom-filter-dropdown">
            <RangePicker
              allowClear={false}
              format={DATE_FORMATS?.REGULAR}
              onChange={(values) => onRangePickerChange(values, dataIndex)}
              value={
                whereFilter?.[dataIndex]?.from
                  ? [
                      whereFilter?.[dataIndex]?.from,
                      whereFilter?.[dataIndex]?.to,
                    ]
                  : null
              }
            />
            <Divider className="divider-filter" />
            <div className="d-flex justify-center">
              <Button
                size="small"
                className="reset-button"
                id="roles-filter-reset"
                onClick={() => handleReset(clearFilters, dataIndex, confirm)}
              >
                Reset
              </Button>
              <Button
                size="small"
                className="common-button ok-button"
                id="roles-filter-ok"
                type="primary"
                onClick={() => getFilterData(confirm, dataIndex)}
              >
                Ok
              </Button>
            </div>
          </div>
        );
      }

      if (dataIndex === 'status') {
        return (
          <div className="custom-filter-dropdown">
            <Group
              options={PAYMENT_STATUS_OPTIONS}
              value={whereFilter?.[dataIndex]}
              onChange={(e) => onRadioChange(e?.target?.value, dataIndex)}
            />
            <Divider className="divider-filter" />
            <div className="d-flex justify-center">
              <Button
                size="small"
                className="reset-button"
                id="roles-filter-reset"
                onClick={() => handleReset(clearFilters, dataIndex, confirm)}
              >
                Reset
              </Button>
              <Button
                size="small"
                className="common-button ok-button"
                id="roles-filter-ok"
                type="primary"
                onClick={() => getFilterData(confirm, dataIndex)}
              >
                Ok
              </Button>
            </div>
          </div>
        );
      }

      return (
        <div className="custom-filter-dropdown">
          <LoaderComponent
            spinning={filterLoading}
            setHeight={35}
            size="medium"
          >
            {filterTags?.[dataIndex]?.length > 0 && (
              <div className="filter-tags-wrapper">
                {map(filterTags?.[dataIndex], (item) => (
                  <Tag
                    key={item?.key}
                    closable
                    onClose={() => handleDeleteFilter(item, dataIndex)}
                    className="filter-tag"
                  >
                    <span>{item?.label}</span>
                  </Tag>
                ))}
              </div>
            )}
            <div className="filter-checkboxes">
              {filterList?.length > 0
                ? map(filterList, (item) => (
                    <div className="single-checkbox-div" key={item?.key}>
                      <Checkbox
                        value={item}
                        checked={whereFilter?.[dataIndex]?.includes(item?.key)}
                        key={item?.key || item}
                        onChange={(e) => changeFilter(e, dataIndex)}
                        className="single-checkbox"
                      >
                        <span>{item?.label}</span>
                      </Checkbox>
                    </div>
                  ))
                : isEmptyFilterList && (
                    <Empty image={Empty?.PRESENTED_IMAGE_SIMPLE} />
                  )}
            </div>
          </LoaderComponent>
          <Divider className="divider-filter" />
          <div className="d-flex justify-center">
            <Button
              size="small"
              className="reset-button"
              id="roles-filter-reset"
              onClick={() => handleReset(clearFilters, dataIndex, confirm)}
            >
              Reset
            </Button>
            <Button
              size="small"
              className="common-button ok-button"
              id="roles-filter-ok"
              type="primary"
              onClick={() => getFilterData(confirm, dataIndex)}
            >
              Ok
            </Button>
          </div>
        </div>
      );
    },
    filterIcon: () =>
      finalWhereFilters?.[dataIndex]?.length > 0 ||
      !isEmpty(finalWhereFilters?.[dataIndex]) ||
      finalWhereFilters?.[dataIndex] === PAYMENT_STATUS?.PAID?.value ||
      finalWhereFilters?.[dataIndex] === PAYMENT_STATUS?.UNPAID?.value ? (
        <FilterFilled className="filter-icon filter-filled" />
      ) : (
        <FilterOutlined className="filter-icon" />
      ),
    onFilterDropdownOpenChange: (visible) => {
      setFilterIndex(dataIndex);
      setFilterVisible(visible);
    },
  });

  const columns = [
    {
      title: 'INVOICE NUMBER',
      dataIndex: 'invoiceNumber',
      key: 'invoiceNumber',
      width: 150,
      ellipsis: true,
      align: 'left',
      className: 'max-width-column',
      render: (_, record) => record?.invoiceNumber,
    },
    {
      title: 'GENERATED ON',
      dataIndex: 'generatedOn',
      key: 'generatedOn',
      width: 150,
      ellipsis: true,
      align: 'left',
      className: 'max-width-column',
      render: (_, record) =>
        formatDate(record?.createdAt, DATE_FORMATS?.REGULAR),
      ...filterPopup('createdAt'),
    },
    {
      title: 'PAID ON',
      dataIndex: 'paidOn',
      key: 'paidOn',
      width: 150,
      ellipsis: true,
      align: 'left',
      className: 'max-width-column',
      render: (_, record) =>
        record?.paidOn
          ? formatDate(record?.paidOn, DATE_FORMATS?.REGULAR)
          : '-',
      ...filterPopup('paidOn'),
    },
    {
      title: 'AMOUNT',
      dataIndex: 'amount',
      key: 'amount',
      width: 150,
      ellipsis: true,
      align: 'left',
      className: 'max-width-column',
      render: (_, record) => record?.amount,
    },
    {
      title: 'STATUS',
      dataIndex: 'status',
      key: 'status',
      ellipsis: true,
      width: 150,
      align: 'left',
      className: 'max-width-column',
      render: (_, record) => (
        <Badge
          color={PAYMENT_STATUS?.[record?.status]?.color}
          text={PAYMENT_STATUS?.[record?.status]?.text}
        />
      ),
      ...filterPopup('status'),
    },
    {
      title: 'ACTIONS',
      dataIndex: 'actions',
      key: 'actions',
      ellipsis: true,
      width: 150,
      // eslint-disable-next-line no-undef
      fixed: window.innerWidth > BREAKPOINTS.desktop ? 'right' : false,
      render: (_, record) => (
        <>
          <div className="action-button">
            <Button
              type="primary"
              size="small"
              className="table-action-primary-btn"
              onClick={() => handleDownload(record?.attachment)}
            >
              Download
            </Button>
          </div>
        </>
      ),
    },
  ];

  const locale = {
    emptyText: isEmptyInvoiceList ? '' : <span />,
  };

  return (
    <>
      <div className="d-flex justify-end mb-16 align-center">
        <SearchComponent
          className={isDesktop ? 'search-box' : ''}
          query={query}
          setQuery={setQuery}
          getData={handleSearch}
        />
        {!isDesktop && (
          <Badge dot={hasNonEmptyValueObj(finalWhereFilters)} size="small">
            <FilterIcon
              className="mobile-svg-icon ml-16"
              onClick={() => setIsAllFilterModalOpen(true)}
            />
          </Badge>
        )}
      </div>
      {isDesktop ? (
        <CommonTable
          locale={locale}
          columns={columns}
          data={invoiceList || []}
          loading={isInvoiceLoading}
          onChange={handleTableChange}
          paginationConfig={paginationProp}
          rowKey={(record) => record?.id}
        />
      ) : (
        <div className="mobile-container no-scroll-bar">
          <AllFilterModal
            onFinish={handleFilter}
            isModalOpen={isAllFilterModalOpen}
            setIsModalOpen={setIsAllFilterModalOpen}
            finalWhereFilters={finalWhereFilters}
            setFinalWhereFilters={setFinalWhereFilters}
          />
          <InvoiceList
            invoiceList={invoiceList}
            handleDownload={handleDownload}
            infiniteScrollRef={infiniteScrollRef}
            isFetchMoreInvoicesLoading={isFetchMoreInvoicesLoading}
            isEmptyInvoiceList={isEmptyInvoiceList}
            isInvoiceLoading={isInvoiceLoading}
          />
        </div>
      )}
    </>
  );
};

export default PaymentTable;
