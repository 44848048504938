import { gql } from '@apollo/client';

// eslint-disable-next-line import/prefer-default-export
export const MARK_NOTIFICATION_AS_READ_ADMIN = gql`
  mutation MarkNotificationAsReadAdmin(
    $where: MarkNotificationsAsReadWhereInput!
    $data: MarkNotificationsAsReadData
  ) {
    markNotificationAsReadAdmin(where: $where, data: $data) {
      message
    }
  }
`;
