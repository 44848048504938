import { useLazyQuery } from '@apollo/client';
import { Empty, Select } from 'antd';
import { debounce, uniqBy } from 'lodash';
import React, { useEffect, useState } from 'react';
import { LIMIT, SORT } from '../../../common/constants';
import SelectComponent from '../../../components/SelectComponent';
import { FACILITATES } from '../graphql/Queries';

const { Option } = Select;

const FacilitatesSelect = (props) => {
  const { toyDetail, ...rest } = props;
  const [facilitateList, setFacilitateList] = useState([]);
  const [facilitateSearchTerm, setFacilitateSearchTerm] = useState('');
  const [isAllFacilitateFetched, setIsAllFacilitateFetched] = useState(false);
  const [isFacilitateAllowClear, setIsFacilitateAllowClear] = useState(false);
  const [
    isFacilitateDropdownVisible,
    setIsFacilitateDropdownVisible,
  ] = useState(false);
  const [
    isFetchMoreFacilitateLoading,
    setIsFetchMoreFacilitateLoading,
  ] = useState(false);
  const [isFacilitateSelected, setIsFacilitateSelected] = useState(false);

  const [facilitates, { loading: isFacilitateLoading }] = useLazyQuery(
    FACILITATES,
    {
      onCompleted: (response) => {
        if (response?.facilitates?.data?.length < LIMIT) {
          setIsAllFacilitateFetched(true);
        }
        setIsFacilitateSelected(false);
        setIsFetchMoreFacilitateLoading(false);
        if (toyDetail) {
          setFacilitateList(
            uniqBy(
              [
                ...(toyDetail?.facilitates || []),
                ...facilitateList,
                ...response?.facilitates?.data,
              ],
              'id',
            ),
          );
          return;
        }
        setFacilitateList([...facilitateList, ...response?.facilitates?.data]);
      },
      fetchPolicy: 'network-only',
      onError() {},
    },
  );

  useEffect(() => {
    if (!isFacilitateDropdownVisible) {
      setFacilitateSearchTerm('');
      setFacilitateList([]);
      facilitates({
        variables: {
          filter: {
            limit: LIMIT,
            skip: 0,
            search: facilitateSearchTerm,
          },
          sort: {
            field: 'name',
            order: SORT?.ASC,
          },
        },
      });
    }
    setIsAllFacilitateFetched(false);
  }, [isFacilitateDropdownVisible]);

  useEffect(() => {
    if (
      isFacilitateSelected &&
      isFacilitateDropdownVisible &&
      facilitateSearchTerm
    ) {
      setFacilitateSearchTerm('');
      setFacilitateList([]);
      facilitates({
        variables: {
          filter: {
            limit: LIMIT,
            skip: 0,
          },
          sort: {
            field: 'name',
            order: SORT?.ASC,
          },
        },
      });
    }
    setIsAllFacilitateFetched(false);
  }, [isFacilitateSelected, isFacilitateDropdownVisible, facilitateSearchTerm]);

  const handleFacilitateScroll = (event) => {
    if (!isAllFacilitateFetched && !isFacilitateLoading) {
      const target = event?.target;
      const currentLength = facilitateList?.length;
      if (
        target?.scrollTop + target?.offsetHeight >=
        target?.scrollHeight - 5
      ) {
        setIsFetchMoreFacilitateLoading(true);
        facilitates({
          variables: {
            filter: {
              limit: LIMIT,
              skip: currentLength,
              search: facilitateSearchTerm,
            },
            sort: {
              field: 'name',
              order: SORT?.ASC,
            },
          },
        });
      }
    }
  };

  const handleFacilitateSearch = (searchTerm) => {
    const trimSearch = searchTerm?.trim();
    setIsAllFacilitateFetched(false);
    setFacilitateList([]);
    setFacilitateSearchTerm(trimSearch);
    facilitates({
      variables: {
        filter: {
          limit: LIMIT,
          skip: 0,
          search: trimSearch,
        },
        sort: {
          field: 'name',
          order: SORT?.ASC,
        },
      },
    });
  };

  const handleFacilitateScrollDebounce = debounce(handleFacilitateScroll, 500);
  const handleSearchFacilitateDebounce = debounce(handleFacilitateSearch, 500);

  useEffect(() => {
    handleFacilitateScrollDebounce?.cancel();
  }, [handleFacilitateScrollDebounce]);

  useEffect(() => {
    handleSearchFacilitateDebounce?.cancel();
  }, [handleSearchFacilitateDebounce]);

  useEffect(() => {
    if (isFacilitateLoading) {
      setIsFacilitateAllowClear(false);
    } else {
      setIsFacilitateAllowClear(true);
    }
  }, [isFacilitateLoading]);

  return (
    <SelectComponent
      mode="multiple"
      showSearch
      loading={
        (isFacilitateLoading && isFacilitateDropdownVisible) ||
        isFetchMoreFacilitateLoading
      }
      placeholder="Select Facilitates"
      optionFilterProp="children"
      allowClear={isFacilitateAllowClear}
      onPopupScroll={handleFacilitateScrollDebounce}
      onSearch={handleSearchFacilitateDebounce}
      onDropdownVisibleChange={(visible) =>
        setIsFacilitateDropdownVisible(visible)
      }
      filterOption={false}
      onChange={() => setIsFacilitateAllowClear(true)}
      onSelect={() => setIsFacilitateSelected(true)}
      maxTagCount={false}
      notFoundContent={
        !isFetchMoreFacilitateLoading && isFacilitateLoading ? (
          <span>Loading...</span>
        ) : (
          <Empty image={Empty?.PRESENTED_IMAGE_SIMPLE} />
        )
      }
      {...rest}
    >
      {facilitateList?.map((facilitate) => (
        <Option key={facilitate?.id} value={facilitate?.id}>
          {facilitate?.name}
        </Option>
      ))}
    </SelectComponent>
  );
};

export default FacilitatesSelect;
