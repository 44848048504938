import React from 'react';
import { Route, Switch } from 'react-router-dom';
import { ROUTES } from '../../common/constants';
import Notifications from './pages/Notifications';

const NotificationsWrapper = () => (
  <Switch>
    <Route
      exact
      path={`${ROUTES?.NOTIFICATIONS}`}
      render={(props) => <Notifications {...props} />}
    />
  </Switch>
);

export default NotificationsWrapper;
