import { gql } from '@apollo/client';

// eslint-disable-next-line import/prefer-default-export
export const RACKS = gql`
  query Racks($filter: RackFilter, $sort: RackSort) {
    racks(filter: $filter, sort: $sort) {
      count
      data {
        id
        isArchived
        createdAt
        name
      }
    }
  }
`;

export const TOYS_FOR_LIBRARY_INVENTORY = gql`
  query ToysForLibraryInventory(
    $where: LibraryUniqueInput!
    $filter: ToysFilterForLibraryInventory
    $sort: ToysSort
  ) {
    toysForLibraryInventory(where: $where, filter: $filter, sort: $sort) {
      count
      data {
        id
        name
        toyNumber
      }
    }
  }
`;
