import { useMutation } from '@apollo/client';
import React, { useContext, useEffect } from 'react';
import { AppContext } from '../../AppContext';
import LoaderComponent from '../../components/LoaderComponent';
import { LOGOUT_USER } from './graphql/Queries';

const Logout = () => {
  const { dispatch } = useContext(AppContext);
  const [logout] = useMutation(LOGOUT_USER);

  useEffect(() => {
    const performLogout = async () => {
      try {
        await logout();
        dispatch({ type: 'LOGOUT' });
        // eslint-disable-next-line no-undef
        window.location = '/login';
      } catch (error) {
        const newErrors = error?.graphQLErrors?.[0];
        // eslint-disable-next-line no-console
        console.log('newErrors => ', newErrors);
        dispatch({ type: 'LOGOUT' });
        // eslint-disable-next-line no-undef
        window.location = '/login';
      }
    };
    performLogout();
  }, [dispatch, logout]);

  return <LoaderComponent />;
};

export default Logout;
