import { Divider, Form } from 'antd';
import React, { useEffect, useState } from 'react';
import { formValidatorRules } from '../../../../../common/utils';
import ModalComponent from '../../../../../components/ModalComponent';
import LibrarySelect from './LibrarySelect';

const { Item } = Form;
const { required } = formValidatorRules;

const BackupLibModal = ({
  isModalOpen,
  setIsModalOpen,
  setLibrary,
  onFinish,
  loadings,
  library,
  form,
  title,
}) => {
  const [isValidateTrigger, setIsValidateTrigger] = useState(false);

  const handleCancel = () => {
    setIsModalOpen(false);
    setLibrary(null);
    form?.resetFields();
  };

  useEffect(() => {
    const initialValues = {
      name: library?.backUpLibrary?.id,
    };
    form?.setFieldsValue(initialValues);
  }, [library]);

  return (
    <>
      <ModalComponent
        open={isModalOpen}
        onCancel={handleCancel}
        onOk={form?.submit}
        title={title}
        okText="Save"
        confirmLoading={loadings}
        destroyOnClose
      >
        <Divider />
        <Form
          form={form}
          onFinish={onFinish}
          autoComplete="off"
          layout="vertical"
          validateTrigger={isValidateTrigger ? 'onChange' : 'onSubmit'}
          onFinishFailed={() => setIsValidateTrigger(true)}
        >
          <Item
            label="Backup Library Name"
            name="name"
            rules={[
              { ...required, message: 'Please Select Backup Library Name!' },
            ]}
          >
            <LibrarySelect
              libraryData={library?.backUpLibrary}
              library={library}
              isActiveLibOnly
            />
          </Item>
        </Form>
        <div className="d-flex justify-between">
          <div className="lib-modal-text">Librarian Name</div>
          <div className="lib-modal-text-value">
            {library?.librarian?.firstName}
          </div>
        </div>
        <div className="d-flex justify-between">
          <div className="lib-modal-text">Contact Details</div>
          <div className="lib-modal-text-value">
            {library?.librarian?.contactNo}
          </div>
        </div>
        <div className="d-flex justify-between">
          <div className="lib-modal-text">Email ID</div>
          <div className="lib-modal-text-value">
            {library?.librarian?.email}
          </div>
        </div>
        <div className="d-flex justify-between">
          <div className="lib-modal-text">Total Toys</div>
          <div className="lib-modal-text-value">{library?.totalToys}</div>
        </div>
      </ModalComponent>
    </>
  );
};

export default BackupLibModal;
