import { gql } from '@apollo/client';

// eslint-disable-next-line import/prefer-default-export
export const CREATE_UI_LABEL = gql`
  mutation CreateUILabel($data: CreateUILabelInput) {
    createUILabel(data: $data) {
      message
    }
  }
`;

export const UPDATE_UI_LABEL = gql`
  mutation UpdateUILabel(
    $where: UILabelUniqueInput!
    $data: UpdateUILabelInput
  ) {
    updateUILabel(where: $where, data: $data) {
      message
    }
  }
`;

export const CREATE_CLOUD_FRONT_CACHE_INVALIDATION = gql`
  mutation CreateCloudFrontCacheInvalidation {
    createCloudFrontCacheInvalidation {
      message
    }
  }
`;
