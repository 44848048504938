import { useLazyQuery } from '@apollo/client';
import { Empty, Select } from 'antd';
import { debounce, uniqBy } from 'lodash';
import React, { useEffect, useState } from 'react';
import { LIMIT, SORT } from '../common/constants';
import { PINCODES } from './graphql/Queries';
import SelectComponent from './SelectComponent';

const { Option } = Select;

const PincodeSelectName = (props) => {
  const { userData, ...rest } = props;
  const [pincodeList, setPincodeList] = useState([]);
  const [pincodesSearchTerm, setPincodesSearchTerm] = useState('');
  const [isAllPincodesFetched, setIsAllPincodesFetched] = useState(false);
  const [isPincodesAllowClear, setIsPincodesAllowClear] = useState(false);
  const [isPincodesDropdownVisible, setIsPincodesDropdownVisible] = useState(
    false,
  );
  const [isFetchMorePincodesLoading, setIsFetchMorePincodesLoading] = useState(
    false,
  );

  const [pincodes, { loading: isPincodesLoading }] = useLazyQuery(PINCODES, {
    onCompleted: (response) => {
      if (response?.pincodes?.data?.length < LIMIT) {
        setIsAllPincodesFetched(true);
      }
      setIsFetchMorePincodesLoading(false);
      if (userData?.pincode) {
        setPincodeList(
          uniqBy(
            [userData?.pincode, ...pincodeList, ...response?.pincodes?.data],
            'id',
          ),
        );
        return;
      }
      setPincodeList([...pincodeList, ...response?.pincodes?.data]);
    },
    fetchPolicy: 'network-only',
    onError() {},
  });

  useEffect(() => {
    setPincodesSearchTerm('');
    setPincodeList([]);
    pincodes({
      variables: {
        filter: {
          limit: LIMIT,
          skip: 0,
          search: pincodesSearchTerm,
        },
        sort: {
          field: 'pincode',
          order: SORT?.ASC,
        },
      },
    });
    setIsAllPincodesFetched(false);
  }, [isPincodesDropdownVisible]);

  const handleCategoriesScroll = (event) => {
    if (!isAllPincodesFetched && !isPincodesLoading) {
      const target = event?.target;
      const currentLength = pincodeList?.length;
      if (
        target?.scrollTop + target?.offsetHeight >=
        target?.scrollHeight - 5
      ) {
        setIsFetchMorePincodesLoading(true);
        pincodes({
          variables: {
            filter: {
              limit: LIMIT,
              skip: currentLength,
              search: pincodesSearchTerm,
            },
            sort: {
              field: 'pincode',
              order: SORT?.ASC,
            },
          },
        });
      }
    }
  };

  const handleCategoriesSearch = (searchTerm) => {
    const trimSearch = searchTerm?.trim();
    setIsAllPincodesFetched(false);
    setPincodeList([]);
    setPincodesSearchTerm(trimSearch);
    pincodes({
      variables: {
        filter: {
          limit: LIMIT,
          skip: 0,
          search: trimSearch,
        },
        sort: {
          field: 'pincode',
          order: SORT?.ASC,
        },
      },
    });
  };

  const handleCategoriesScrollDebounce = debounce(handleCategoriesScroll, 500);
  const handleSearchCategoriesDebounce = debounce(handleCategoriesSearch, 500);

  useEffect(() => {
    handleCategoriesScrollDebounce?.cancel();
  }, [handleCategoriesScrollDebounce]);

  useEffect(() => {
    handleSearchCategoriesDebounce?.cancel();
  }, [handleSearchCategoriesDebounce]);

  useEffect(() => {
    if (isPincodesLoading) {
      setIsPincodesAllowClear(false);
    } else {
      setIsPincodesAllowClear(true);
    }
  }, [isPincodesLoading]);

  return (
    <SelectComponent
      showSearch
      loading={
        (isPincodesLoading && isPincodesDropdownVisible) ||
        isFetchMorePincodesLoading
      }
      placeholder="Select Category"
      optionFilterProp="children"
      allowClear={isPincodesAllowClear}
      onPopupScroll={handleCategoriesScrollDebounce}
      onSearch={handleSearchCategoriesDebounce}
      onDropdownVisibleChange={(visible) =>
        setIsPincodesDropdownVisible(visible)
      }
      filterOption={false}
      notFoundContent={
        !isFetchMorePincodesLoading && isPincodesLoading ? (
          <span>Loading...</span>
        ) : (
          <Empty image={Empty?.PRESENTED_IMAGE_SIMPLE} />
        )
      }
      {...rest}
    >
      {pincodeList?.map((pincode) => (
        <Option key={pincode?.id} value={pincode?.pincode}>
          {pincode?.pincode}
        </Option>
      ))}
    </SelectComponent>
  );
};

export default PincodeSelectName;
