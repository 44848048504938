import React from 'react';
import { Route, Switch } from 'react-router-dom';
import AccessControl from '../../AccessControl';
import { PERMISSIONS_KEY, ROUTES } from '../../common/constants';
import Configs from './pages/Configs';

const ConfigsWrapper = () => (
  <Switch>
    <Route
      exact
      path={ROUTES?.CONFIGS}
      render={(props) => (
        <AccessControl permissionKey={PERMISSIONS_KEY?.CONFIGS}>
          <Configs {...props} />
        </AccessControl>
      )}
    />
  </Switch>
);

export default ConfigsWrapper;
