import { gql } from '@apollo/client';

// eslint-disable-next-line import/prefer-default-export
export const CREATE_TAG = gql`
  mutation CreateTag($data: CreateTagInput!) {
    createTag(data: $data) {
      message
    }
  }
`;

export const UPDATE_TAG = gql`
  mutation UpdateTag($where: TagUniqueInput!, $data: UpdateTagInput) {
    updateTag(where: $where, data: $data) {
      message
    }
  }
`;
