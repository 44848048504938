import { Divider, Form, Select } from "antd";
import React, { useState } from "react";
import ModalComponent from "../../../../components/ModalComponent";
import SelectComponent from "../../../../components/SelectComponent";
import ToySelect from "./toySelect";

const { Option } = Select;
const generateValuesArray = (dataCount, length) =>
  Array.from({ length }, (_, index) => {
    if (dataCount === -Infinity) {
      return 1;
    }
    return Number.isNaN(dataCount) ? index : dataCount + 1 + index;
  });

const CollectionsAddToyTable = ({
  setCollectionToySelectModalOpen,
  collectionToySelectModalOpen,
  collectionId,
  maxOrder,
  onFinish,
  loadings,
  toySelectValue,
  setToySelectValue,
}) => {
  const toySelectLength = toySelectValue.length
  const optionsArray = generateValuesArray(maxOrder, toySelectLength);
  // eslint-disable-next-line no-console
  console.log(maxOrder, toySelectLength);
  const [form] = Form.useForm();
  const [isValidateTrigger, setIsValidateTrigger] = useState(false);
  const { Item } = Form;
  const handleCancel = () => {
    setCollectionToySelectModalOpen(false);
    form?.resetFields();
  };
  const validateOrderIds = (rule, value) => {
    if (value === undefined) {
      return Promise.resolve();
    }

    if (value?.length === 0 || value === undefined) {
      return Promise.resolve();
    }

    if (value?.length !== toySelectValue?.length) {
      return Promise.reject(new Error('The length of orderIds must be the same as toyIds.'));
    }

    // If all checks pass, return a resolved promise
    return Promise.resolve();
  };
  return (
    <>
      <ModalComponent
        open={collectionToySelectModalOpen}
        onCancel={handleCancel}
        onOk={form?.submit}
        title='Add New Toys'
        okText="Save"
        confirmLoading={loadings}
        destroyOnClose
        forceRender
      >
        <Divider />
        <Form
          form={form}
          onFinish={(values) => {
            onFinish(values);
            setIsValidateTrigger(true);
            handleCancel(values);
            setCollectionToySelectModalOpen(false);
          }}
          autoComplete="off"
          layout="vertical"
          validateTrigger={isValidateTrigger ? 'onChange' : 'onSubmit'}
          onFinishFailed={() => setIsValidateTrigger(true)}
        >
          <Item label="Toys" name="toyIds">
            <ToySelect
              collectionId={collectionId}
              onChange={(values) => setToySelectValue(values)}
            />
          </Item>
          <Item label="Orders" name="orderIds" rules={[{ validator: validateOrderIds }]}>
            <SelectComponent label="Orders" name="orderIds" mode="multiple">
              {
                optionsArray.map((arr) => <Option key={arr} value={arr}>{arr}</Option>)
              };
            </SelectComponent>
          </Item>
        </Form>
      </ModalComponent>
    </>
  )
}

export default CollectionsAddToyTable