import { gql } from '@apollo/client';

// eslint-disable-next-line import/prefer-default-export
export const RACKS_ADMIN = gql`
  query RacksAdmin($filter: RackFilter, $sort: RackSort) {
    racksAdmin(filter: $filter, sort: $sort) {
      count
      data {
        id
        isArchived
        createdAt
        name
      }
    }
  }
`;
