import { Divider, Form, Input } from 'antd';
import React, { useState } from 'react';
import { formValidatorRules } from '../../../../../common/utils';
import ModalComponent from '../../../../../components/ModalComponent';

const { Item } = Form;
const { TextArea } = Input;
const { required } = formValidatorRules;

const NoteModal = ({
  note,
  isModalOpen,
  setIsModalOpen,
  isBtnLoading,
  onFinish,
  form,
}) => {
  const [validationTriggered, setValidationTriggered] = useState(false);

  return (
    <ModalComponent
      title={note ? 'Note' : 'Add Note'}
      okText="Save"
      cancelText="Cancel"
      open={isModalOpen}
      onOk={form?.submit}
      onCancel={() => {
        setIsModalOpen(false);
        form?.resetFields();
      }}
      confirmLoading={isBtnLoading}
      footer={note ? null : undefined}
    >
      <Divider />
      {note || (
        <Form
          form={form}
          layout="vertical"
          onFinish={onFinish}
          onFinishFailed={() => setValidationTriggered(true)}
          validateTrigger={validationTriggered ? 'onChange' : 'onSubmit'}
        >
          <Item
            name="note"
            rules={[
              {
                ...required,
                message: 'Please Enter Note!',
              },
            ]}
          >
            <TextArea
              rows={4}
              allowClear
              placeholder="Enter Note"
              showCount
              maxLength={700}
            />
          </Item>
        </Form>
      )}
    </ModalComponent>
  );
};

export default NoteModal;
