import { Card } from 'antd';
import React from 'react';
import { MODULES } from '../../../common/constants';
import Portal from '../../../components/Portal';

const Support = () => (
  <>
    <Portal portalId="header-title-content">
      <div className="portal-header">{MODULES?.SUPPORT}</div>
    </Portal>
    <Card className="support-library">
      <h2 className="mt-0 mb-22">Get in Touch</h2>
      {/* <h3 className="mb-0 mt-0">Phone</h3>
      <h3 className="mb-0 mt-0 text-grey">+91 123456789</h3>
      <h3 className="mt-0 text-grey">0261 1234567</h3> */}
      <h3 className="mb-0">Email</h3>
      <h3 className="mb-0 mt-0 text-grey">hello@theelefant.com</h3>
      {/* <h3 className="mb-0">Official Address</h3>
      <h3 className="mb-0 mt-0 text-grey">
        Akshya Nagar 1st Block 1st Cross, Rammurthy nagar, Bangalore-560016
      </h3> */}
    </Card>
  </>
);

export default Support;
