import { gql } from '@apollo/client';

// eslint-disable-next-line import/prefer-default-export
export const CONFIGS = gql`
  query Configs($filter: ConfigFilter, $sort: ConfigSort) {
    configs(filter: $filter, sort: $sort) {
      count
      data {
        id
        key
        type
        value
        description
        dataType
        createdAt
        isArchived
      }
    }
  }
`;
