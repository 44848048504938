import { gql } from '@apollo/client';

// eslint-disable-next-line import/prefer-default-export
export const HOLDING_PERIOD = gql`
  query HoldingPeriod($filter: HoldingPeriodFilter, $sort: HoldingPeriodSort) {
    holdingPeriod(filter: $filter, sort: $sort) {
      count
      data {
        id
        firstName
        lastName
        userId
        toyName
        toyNumber
        userNumber
        toyId
        orderNumber
        libraryId
        libraryNumber
        dueSince
        orderId
        deliveredOn
        returnDate
        status
        libraryName
        comment
        commentId
        isResolved
      }
    }
  }
`;

export const ORDERS_SELECT = gql`
  query HoldingPeriod($filter: HoldingPeriodFilter, $sort: HoldingPeriodSort) {
    holdingPeriod(filter: $filter, sort: $sort) {
      count
      data {
        orderNumber
      }
    }
  }
`;
