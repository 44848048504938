import { useLazyQuery } from '@apollo/client';
import { Empty, Select } from 'antd';
import { debounce, uniqBy } from 'lodash';
import React, { useEffect, useState } from 'react';
import { LIMIT, SORT } from '../common/constants';
import { STATES } from './graphql/Queries';
import SelectComponent from './SelectComponent';

const { Option } = Select;

const StateSelect = (props) => {
  const { isView, countryId, setStateId, userData, ...rest } = props;
  const [stateList, setStateList] = useState([]);
  const [statesSearchTerm, setStatesSearchTerm] = useState('');
  const [isAllStatesFetched, setIsAllStatesFetched] = useState(false);
  const [isStatesAllowClear, setIsStatesAllowClear] = useState(false);
  const [isStatesDropdownVisible, setIsStatesDropdownVisible] = useState(false);
  const [isFetchMoreStatesLoading, setIsFetchMoreStatesLoading] = useState(
    false,
  );

  const [states, { loading: isStatesLoading }] = useLazyQuery(STATES, {
    onCompleted: (response) => {
      if (response?.states?.data?.length < LIMIT) {
        setIsAllStatesFetched(true);
      }
      setIsFetchMoreStatesLoading(false);
      if (userData?.state) {
        setStateList(
          uniqBy(
            [
              { name: userData?.state },
              ...stateList,
              ...response?.states?.data,
            ],
            'name',
          ),
        );
        return;
      }
      setStateList([...stateList, ...response?.states?.data]);
    },
    fetchPolicy: 'network-only',
    onError() {},
  });

  useEffect(() => {
    if (countryId) {
      setStatesSearchTerm('');
      setStateList([]);
      states({
        variables: {
          where: {
            countryId,
          },
          filter: {
            limit: LIMIT,
            skip: 0,
            search: statesSearchTerm,
          },
          sort: {
            field: 'name',
            order: SORT?.ASC,
          },
        },
      });
      setIsAllStatesFetched(false);
    }
  }, [isStatesDropdownVisible, countryId]);

  const handleCategoriesScroll = (event) => {
    if (!isAllStatesFetched && !isStatesLoading) {
      const target = event?.target;
      const currentLength = stateList?.length;
      if (
        target?.scrollTop + target?.offsetHeight >=
        target?.scrollHeight - 5
      ) {
        setIsFetchMoreStatesLoading(true);
        states({
          variables: {
            where: {
              countryId,
            },
            filter: {
              limit: LIMIT,
              skip: currentLength,
              search: statesSearchTerm,
            },
            sort: {
              field: 'name',
              order: SORT?.ASC,
            },
          },
        });
      }
    }
  };

  const handleCategoriesSearch = (searchTerm) => {
    const trimSearch = searchTerm?.trim();
    setIsAllStatesFetched(false);
    setStateList([]);
    setStatesSearchTerm(trimSearch);
    states({
      variables: {
        where: {
          countryId,
        },
        filter: {
          limit: LIMIT,
          skip: 0,
          search: trimSearch,
        },
        sort: {
          field: 'name',
          order: SORT?.ASC,
        },
      },
    });
  };

  const handleCategoriesScrollDebounce = debounce(handleCategoriesScroll, 500);
  const handleSearchCategoriesDebounce = debounce(handleCategoriesSearch, 500);

  useEffect(() => {
    handleCategoriesScrollDebounce?.cancel();
  }, [handleCategoriesScrollDebounce]);

  useEffect(() => {
    handleSearchCategoriesDebounce?.cancel();
  }, [handleSearchCategoriesDebounce]);

  useEffect(() => {
    if (isStatesLoading) {
      setIsStatesAllowClear(false);
    } else {
      setIsStatesAllowClear(true);
    }
  }, [isStatesLoading]);

  return (
    <SelectComponent
      showSearch
      loading={
        (isStatesLoading && isStatesDropdownVisible) || isFetchMoreStatesLoading
      }
      placeholder="Select State"
      optionFilterProp="children"
      allowClear={isStatesAllowClear}
      onPopupScroll={handleCategoriesScrollDebounce}
      onSearch={handleSearchCategoriesDebounce}
      onDropdownVisibleChange={(visible) => setIsStatesDropdownVisible(visible)}
      filterOption={false}
      notFoundContent={
        !isFetchMoreStatesLoading && isStatesLoading ? (
          <span>Loading...</span>
        ) : (
          <Empty image={Empty?.PRESENTED_IMAGE_SIMPLE} />
        )
      }
      disabled={isView || !countryId || userData?.isArchived}
      {...rest}
    >
      {stateList?.map((state) => (
        <Option key={state?.name} value={state?.name}>
          {state?.name}
        </Option>
      ))}
    </SelectComponent>
  );
};

export default StateSelect;
