import { gql } from '@apollo/client';

// eslint-disable-next-line import/prefer-default-export
export const REQUEST_TOY_USAGE_REPORT_EXPORT = gql`
  mutation RequestToyUsageReportExport($data: RequestExportInput!) {
    requestToyUsageReportExport(data: $data) {
      message
    }
  }
`;

export const REQUEST_USER_REVENUE_REPORT_EXPORT = gql`
  mutation RequestUserRevenueReportExport(
    $data: RequestExportInput!
    $filter: RequestUserExportFilterInput
  ) {
    requestUserRevenueReportExport(data: $data, filter: $filter) {
      message
    }
  }
`;
