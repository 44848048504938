import { Divider, Form, Input } from 'antd';
import React, { useState } from 'react';
import { formValidatorRules } from '../../../common/utils';
import ModalComponent from '../../../components/ModalComponent';

const { Item } = Form;
const { required, url, whitespaceNotAllowed } = formValidatorRules;

const RaiseNewToyRequestModal = ({
  isModalOpen,
  setIsModalOpen,
  onFinish,
  loadings,
  form,
}) => {
  const [isValidateTrigger, setIsValidateTrigger] = useState(false);

  const handleCancel = () => {
    setIsModalOpen(false);
    form?.resetFields();
  };

  return (
    <>
      <ModalComponent
        open={isModalOpen}
        onCancel={handleCancel}
        onOk={form?.submit}
        title="New Toy Request"
        okText="Save"
        confirmLoading={loadings}
        destroyOnClose
      >
        <Divider />
        <div className="mb-16">
          <Form
            form={form}
            onFinish={onFinish}
            autoComplete="off"
            layout="vertical"
            validateTrigger={isValidateTrigger ? 'onChange' : 'onSubmit'}
            onFinishFailed={() => setIsValidateTrigger(true)}
          >
            <Item
              label="Product Name"
              name="name"
              rules={[{ ...required, message: 'Please Enter Product Name!' }]}
            >
              <Input allowClear placeholder="Enter Product Name" />
            </Item>
            <Item label="Brand" name="brand" rules={[whitespaceNotAllowed]}>
              <Input allowClear placeholder="Enter Brand" />
            </Item>
            <Item label="URL" name="url" rules={[url]}>
              <Input allowClear placeholder="Enter Product URL" />
            </Item>
          </Form>
        </div>
      </ModalComponent>
    </>
  );
};

export default RaiseNewToyRequestModal;
