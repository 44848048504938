import React from 'react';
import { REQUEST_STATUS } from '../../../common/constants';
import ModalComponent from '../../../components/ModalComponent';

const ViewCommentsModal = ({
  isModalOpen,
  setIsModalOpen,
  adminComment,
  libraryComments,
  status,
}) => (
  <ModalComponent
    open={isModalOpen}
    onCancel={() => {
      setIsModalOpen(false);
    }}
    footer={null}
  >
    <div className="mb-16">
      <b className="font-size-16">Resolving comment by admin</b>
    </div>
    <div className="font-size-16">{adminComment || '-'}</div>
    {status === REQUEST_STATUS?.APPROVED?.value && (
      <div>
        <div className="mb-12 mt-24">
          <b className="font-size-16">Accept comment by library</b>
        </div>
        {libraryComments?.length > 0
          ? libraryComments?.map((commentData) => (
              <div
                className="font-size-16"
                key={
                  Math?.random()?.toString(36)?.substring(2) +
                  new Date()?.getTime()?.toString(36)
                }
              >
                {commentData?.comment || '-'}
              </div>
            ))
          : '-'}
      </div>
    )}
  </ModalComponent>
);

export default ViewCommentsModal;
