import { Card } from 'antd';
import React from 'react';
import { MODULES } from '../../../common/constants';
import Portal from '../../../components/Portal';
import RackMasterTable from '../components/RackMasterTable';

const RackMaster = () => (
  <>
    <Portal portalId="header-title-content">
      <div className="portal-header">{MODULES?.RACK_MASTER}</div>
    </Portal>
    <Card className="full-height-without-nav">
      <RackMasterTable />
    </Card>
  </>
);

export default RackMaster;
