import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import { ROUTES } from '../../common/constants';

const OffersWrapper = () => (
  <Switch>
    <Route
      exact
      path={ROUTES?.OFFERS}
      render={() => <Redirect to={`${ROUTES?.OFFERS}${ROUTES?.COUPON}`} />}
    />
  </Switch>
);

export default OffersWrapper;
