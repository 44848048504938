import { gql } from '@apollo/client';

// eslint-disable-next-line import/prefer-default-export
export const CONTENT_MASTERS_ADMIN = gql`
  query ContentMastersAdmin(
    $filter: ContentMasterFilter
    $sort: ContentMasterSort
  ) {
    contentMastersAdmin(filter: $filter, sort: $sort) {
      count
      data {
        description
        createdAt
        id
        image
        isArchived
        key
        value
      }
    }
  }
`;

export const GET_CONTENT_MASTER_IMAGE_SIGNED_PUT_URL = gql`
  query GetContentMasterImageSignedPutUrl(
    $data: GetContentMasterImageSignedPutUrlInput!
  ) {
    getContentMasterImageSignedPutUrl(data: $data) {
      fileName
      signedUrl
    }
  }
`;
