import { gql } from '@apollo/client';

// eslint-disable-next-line import/prefer-default-export
export const COUPONS = gql`
  query Coupons($filter: CouponFilter, $sort: CouponsSort) {
    coupons(filter: $filter, sort: $sort) {
      count
      data {
        value
        validTo
        validFrom
        type
        note
        name
        id
        description
        code
        createdAt
        isActive
        commission
        usedCouponCount
        maxRedemptions
      }
    }
  }
`;
