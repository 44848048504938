import { useLazyQuery } from '@apollo/client';
import { Empty, Select } from 'antd';
import { debounce, uniqBy } from 'lodash';
import React, { useEffect, useState } from 'react';
import { LIMIT, SORT } from '../../../../../common/constants';
import SelectComponent from '../../../../../components/SelectComponent';
import { PINCODES } from '../graphql/Queries';

const { Option } = Select;

const LibraryPincodeSelect = (props) => {
  const { initialPincodes, isIdAsValue = false, ...rest } = props;
  const [pincodeList, setPincodeList] = useState([]);
  const [pincodesSearchTerm, setPincodesSearchTerm] = useState('');
  const [isAllPincodesFetched, setIsAllPincodesFetched] = useState(false);
  const [isPincodesAllowClear, setIsPincodesAllowClear] = useState(false);
  const [isPincodesDropdownVisible, setIsPincodesDropdownVisible] = useState(
    false,
  );
  const [isFetchMorePincodesLoading, setIsFetchMorePincodesLoading] = useState(
    false,
  );
  const [isLibrarySelected, setIsLibrarySelected] = useState(false);

  const [pincodes, { loading: isPincodesLoading }] = useLazyQuery(PINCODES, {
    onCompleted: (response) => {
      if (response?.pincodes?.data?.length < LIMIT) {
        setIsAllPincodesFetched(true);
      }
      setIsFetchMorePincodesLoading(false);
      setIsLibrarySelected(false);
      if (initialPincodes?.length > 0) {
        setPincodeList(
          uniqBy(
            [...initialPincodes, ...pincodeList, ...response?.pincodes?.data],
            'id',
          ),
        );
        return;
      }
      setPincodeList([...pincodeList, ...response?.pincodes?.data]);
    },
    fetchPolicy: 'network-only',
    onError() {},
  });

  useEffect(() => {
    if (!isPincodesDropdownVisible) {
      setPincodesSearchTerm('');
      setPincodeList([]);
      pincodes({
        variables: {
          filter: {
            limit: LIMIT,
            skip: 0,
            search: pincodesSearchTerm,
          },
          sort: {
            field: 'pincode',
            order: SORT?.ASC,
          },
        },
      });
    }
    setIsAllPincodesFetched(false);
  }, [isPincodesDropdownVisible]);

  useEffect(() => {
    if (isLibrarySelected && isPincodesDropdownVisible && pincodesSearchTerm) {
      setPincodesSearchTerm('');
      setPincodeList([]);
      pincodes({
        variables: {
          filter: {
            limit: LIMIT,
            skip: 0,
          },
          sort: {
            field: 'pincode',
            order: SORT?.ASC,
          },
        },
      });
    }
    setIsAllPincodesFetched(false);
  }, [isLibrarySelected, isPincodesDropdownVisible, pincodesSearchTerm]);

  const handlePincodesScroll = (event) => {
    if (!isAllPincodesFetched && !isPincodesLoading) {
      const target = event?.target;
      const currentLength = pincodeList?.length;
      if (
        target?.scrollTop + target?.offsetHeight >=
        target?.scrollHeight - 5
      ) {
        setIsFetchMorePincodesLoading(true);
        pincodes({
          variables: {
            filter: {
              limit: LIMIT,
              skip: currentLength,
              search: pincodesSearchTerm,
            },
            sort: {
              field: 'pincode',
              order: SORT?.ASC,
            },
          },
        });
      }
    }
  };

  const handlePincodesSearch = (searchTerm) => {
    const trimSearch = searchTerm?.trim();
    setIsAllPincodesFetched(false);
    setPincodeList([]);
    setPincodesSearchTerm(trimSearch);
    pincodes({
      variables: {
        filter: {
          limit: LIMIT,
          skip: 0,
          search: trimSearch,
        },
        sort: {
          field: 'pincode',
          order: SORT?.ASC,
        },
      },
    });
  };

  const handlePincodesScrollDebounce = debounce(handlePincodesScroll, 500);
  const handleSearchPincodesDebounce = debounce(handlePincodesSearch, 500);

  useEffect(() => {
    handlePincodesScrollDebounce?.cancel();
  }, [handlePincodesScrollDebounce]);

  useEffect(() => {
    handleSearchPincodesDebounce?.cancel();
  }, [handleSearchPincodesDebounce]);

  useEffect(() => {
    if (isPincodesLoading) {
      setIsPincodesAllowClear(false);
    } else {
      setIsPincodesAllowClear(true);
    }
  }, [isPincodesLoading]);

  return (
    <SelectComponent
      mode="multiple"
      placeholder="Select Pincodes"
      onDropdownVisibleChange={(e) => setIsPincodesDropdownVisible(e)}
      onPopupScroll={handlePincodesScrollDebounce}
      onSearch={handleSearchPincodesDebounce}
      onChange={() => setIsPincodesAllowClear(true)}
      loading={isFetchMorePincodesLoading}
      allowClear={isPincodesAllowClear}
      filterOption={false}
      notFoundContent={
        !isFetchMorePincodesLoading && isPincodesLoading ? (
          <span>Loading...</span>
        ) : (
          <Empty image={Empty?.PRESENTED_IMAGE_SIMPLE} />
        )
      }
      onSelect={() => setIsLibrarySelected(true)}
      maxTagCount={false}
      {...rest}
    >
      {pincodeList?.map((pincode) => (
        <Option
          key={pincode?.id}
          value={isIdAsValue ? pincode?.id : pincode?.pincode}
        >
          {pincode?.pincode}
        </Option>
      ))}
    </SelectComponent>
  );
};

export default LibraryPincodeSelect;
