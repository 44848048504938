import { MenuOutlined } from '@ant-design/icons';
import { Button, Layout } from 'antd';
import React from 'react';

const { Header } = Layout;

const AppHeader = ({ isDesktop, handleOverlay }) => (
  <Header className="site-layout-sub-header-background app-header">
    <div className="header-portal-wrapper d-flex justify-between align-center full-width header-wrapper">
      <div className="header-left-content">
        {!isDesktop && (
          <Button
            className="trigger d-flex justify-center align-center mr-8 menu-btn"
            type="text"
            onClick={handleOverlay}
            icon={<MenuOutlined />}
            size="middle"
          />
        )}
        <div id="header-title-content" />
      </div>
      <div id="header-right-content" />
    </div>
  </Header>
);
export default AppHeader;
