import { Button } from 'antd';
import React, { useState } from 'react';
import { MODULES } from '../../../common/constants';
import Portal from '../../../components/Portal';
import ClosureRequestsTable from '../components/ClosureRequestsTable';
import RaiseRequestTabs from '../components/RaiseRequestTabs';

const ClosureRequests = () => {
  const [isRaiseRequestModalOpen, setIsRaiseRequestModalOpen] = useState(false);

  return (
    <>
      <Portal portalId="header-title-content">
        <div className="portal-header">{MODULES?.RAISE_REQUEST}</div>
      </Portal>
      <Portal portalId="header-right-content">
        <Button
          className="common-button"
          size="middle"
          onClick={() => setIsRaiseRequestModalOpen(true)}
        >
          Raise Request
        </Button>
      </Portal>
      <Portal portalId="header-right-content-phones">
        <Button
          className="common-button"
          size="middle"
          onClick={() => setIsRaiseRequestModalOpen(true)}
        >
          Raise Request
        </Button>
      </Portal>
      <RaiseRequestTabs>
        <div className="tab-table">
          <ClosureRequestsTable
            isRaiseRequestModalOpen={isRaiseRequestModalOpen}
            setIsRaiseRequestModalOpen={setIsRaiseRequestModalOpen}
          />
        </div>
      </RaiseRequestTabs>
    </>
  );
};

export default ClosureRequests;
