import React from 'react';
import { Route, Switch } from 'react-router-dom';
import AccessControl from '../../../../AccessControl';
import { PERMISSIONS_KEY, ROUTES } from '../../../../common/constants';
import Error404 from '../../../../Error404';
import AgeGroup from './pages/AgeGroup';

const AgeGroupWrapper = () => (
  <Switch>
    <Route
      exact
      path={`${ROUTES?.MASTER_LISTS}${ROUTES?.AGE_GROUP}`}
      render={(props) => (
        <AccessControl permissionKey={PERMISSIONS_KEY?.MASTER_LIST_AGE_GROUP}>
          <AgeGroup {...props} />
        </AccessControl>
      )}
    />
    <Route path="*" exact component={Error404} />
  </Switch>
);

export default AgeGroupWrapper;
