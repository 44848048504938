import React from 'react';
import {
  MODULES,
  REFERRAL_TYPE,
  TRANSACTION_HISTORY_TYPES,
} from '../../../../../common/constants';
import Portal from '../../../../../components/Portal';
import ReferralTable from '../../endUser/components/ReferralTable';
import LibraryTabs from '../components/LibraryTabs';

const transactionTypes = [
  TRANSACTION_HISTORY_TYPES?.REFERRAL?.value,
  TRANSACTION_HISTORY_TYPES?.ENCASHMENT?.value,
];

const Referral = () => (
  <>
    <Portal portalId="header-title-content">
      <div className="portal-header">{MODULES?.LIBRARY}</div>
    </Portal>
    <LibraryTabs>
      <div className="tab-table">
        <ReferralTable
          transactionTypes={transactionTypes}
          referredByType={REFERRAL_TYPE?.LIBRARY}
        />
      </div>
    </LibraryTabs>
  </>
);

export default Referral;
