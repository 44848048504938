import { Divider } from 'antd';
import React from 'react';
import ModalComponent from '../../../../../components/ModalComponent';

const ViewCommentModal = ({
  comment,
  isModalOpen,
  setIsModalOpen,
  title = 'Resolving Comment',
}) => (
  <ModalComponent
    title={title}
    open={isModalOpen}
    onCancel={() => {
      setIsModalOpen(false);
    }}
    footer={null}
  >
    <Divider />
    {comment || '-'}
  </ModalComponent>
);

export default ViewCommentModal;
