import { Empty } from 'antd';
import React from 'react';
import LoaderComponent from '../../../../../components/LoaderComponent';
import AllReturnOrderCard from './AllReturnOrderCard';

const AllReturnOrderList = ({
  orderList,
  infiniteScrollRef,
  isFetchMoreOrderLoading,
  isEmptyOrderList,
  isOrderLoading,
  handleChange,
  setOrder,
  setIsContentMasterModalOpen,
  setIsAllReturnOrderItemModalOpen,
  setVisible,
  setToyImages,
}) => (
  <div>
    {!isEmptyOrderList &&
      orderList?.map((order) => (
        <AllReturnOrderCard
          key={order?.id}
          order={order}
          infiniteScrollRef={infiniteScrollRef}
          handleChange={handleChange}
          setOrder={setOrder}
          setIsContentMasterModalOpen={setIsContentMasterModalOpen}
          setIsAllReturnOrderItemModalOpen={setIsAllReturnOrderItemModalOpen}
          setVisible={setVisible}
          setToyImages={setToyImages}
        />
      ))}
    {(isFetchMoreOrderLoading || isOrderLoading) && (
      <LoaderComponent size="small" setHeight={10} />
    )}
    {isEmptyOrderList && <Empty image={Empty?.PRESENTED_IMAGE_SIMPLE} />}
  </div>
);

export default AllReturnOrderList;
