import React, { useContext } from 'react';
import { Route, Switch } from 'react-router-dom';
import AccessControl from '../../../../AccessControl';
import { MENU_ITEMS } from '../../../../app/components/sidebar/Sidebar';
import { AppContext } from '../../../../AppContext';
import { MODULES, PERMISSIONS_KEY, ROUTES } from '../../../../common/constants';
import { getRedirectSubmenuItem } from '../../../../common/utils';
import Error404 from '../../../../Error404';
import RedirectControl from '../../../../RedirectControl';
import './new.less';
import NewAssignDelPartner from './pages/NewAssignDelPartner';
import NewConfirmOrder from './pages/NewConfirmOrder';
import NewDelivery from './pages/NewDelivery';
import NewReturn from './pages/NewReturn';

const tabs = MENU_ITEMS?.find(
  (menuItem) => menuItem?.key === MODULES?.ORDERS,
)?.subMenu?.find((menu) => menu?.key === MODULES?.NEW)?.tabKeys;

const NewOrdersWrapper = () => {
  const {
    state: { permissions },
  } = useContext(AppContext);

  const redirectArray = getRedirectSubmenuItem(permissions, tabs);

  return (
    <Switch>
      <Route
        exact
        path={`${ROUTES?.ORDERS}${ROUTES?.NEW}`}
        render={() => (
          <RedirectControl
            redirectArray={redirectArray}
            route={`${ROUTES?.ORDERS}${ROUTES?.NEW}`}
          />
        )}
      />
      <Route
        exact
        path={`${ROUTES?.ORDERS}${ROUTES?.NEW}${ROUTES?.CONFIRM_ORDER}`}
        render={(props) => (
          <AccessControl permissionKey={PERMISSIONS_KEY?.ORDERS_NEW_PLACED}>
            <NewConfirmOrder {...props} />
          </AccessControl>
        )}
      />
      <Route
        exact
        path={`${ROUTES?.ORDERS}${ROUTES?.NEW}${ROUTES?.ASSIGN_DELIVERY_PARTNER}`}
        render={(props) => (
          <AccessControl permissionKey={PERMISSIONS_KEY?.ORDERS_NEW_CONFIRMED}>
            <NewAssignDelPartner {...props} />
          </AccessControl>
        )}
      />
      <Route
        exact
        path={`${ROUTES?.ORDERS}${ROUTES?.NEW}${ROUTES?.DELIVERY}`}
        render={(props) => (
          <AccessControl permissionKey={PERMISSIONS_KEY?.ORDERS_NEW_DELIVERY}>
            <NewDelivery {...props} />
          </AccessControl>
        )}
      />
      <Route
        exact
        path={`${ROUTES?.ORDERS}${ROUTES?.NEW}${ROUTES?.RETURN}`}
        render={(props) => (
          <AccessControl permissionKey={PERMISSIONS_KEY?.ORDERS_NEW_RETURN}>
            <NewReturn {...props} />
          </AccessControl>
        )}
      />
      <Route path="*" exact component={Error404} />
    </Switch>
  );
};

export default NewOrdersWrapper;
