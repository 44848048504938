import { Card } from 'antd';
import React, { useState } from 'react';
import {
  MODULES,
} from '../../../../common/constants';
import Portal from '../../../../components/Portal';
import StoryTable from '../components/StoryTable';

const Story = () => {
  const [isStoryModalOpen, setIsStoryModalOpen] = useState(false);

  return (
    <>
      <Portal portalId="header-title-content">
        <div className="portal-header">{MODULES?.STORY}</div>
      </Portal>
      <Card className="full-height-without-nav">
        <StoryTable
          isStoryModalOpen={isStoryModalOpen}
          setIsStoryModalOpen={setIsStoryModalOpen}
        />
      </Card>
    </>
  );
};

export default Story;
