import { Tabs } from 'antd';
import React, { useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { ROUTES, TABS } from '../../../../../common/constants';

const TrialTabs = ({ children }) => {
  const history = useHistory();
  const location = useLocation();
  const [activeTabKey, setActiveTabKey] = useState(TABS?.MONTHLY?.key);

  const tabList = [
    {
      key: TABS?.MONTHLY?.key,
      label: TABS?.MONTHLY?.label,
      children,
    },
  ];

  useEffect(() => {
    if (location?.pathname?.includes(ROUTES?.MONTHLY)) {
      setActiveTabKey(TABS?.MONTHLY?.key);
    }
  }, [location]);

  const onTabChange = (key) => {
    setActiveTabKey(key);
    switch (key) {
      case TABS?.MONTHLY?.key:
        if (location?.pathname?.includes('/edit')) {
          history?.push(
            `${ROUTES?.SUBSCRIPTION_PLANS}${ROUTES?.TRIAL}${ROUTES?.MONTHLY}/edit`,
          );
        } else {
          history?.push(
            `${ROUTES?.SUBSCRIPTION_PLANS}${ROUTES?.TRIAL}${ROUTES?.MONTHLY}`,
          );
        }
        break;
      default:
        break;
    }
  };

  return (
    <div className="card-container">
      <Tabs
        activeKey={activeTabKey}
        type="card"
        className="full-width"
        onChange={(key) => onTabChange(key)}
        items={tabList}
        destroyInactiveTabPane
      />
    </div>
  );
};

export default TrialTabs;
