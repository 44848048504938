import { DatePicker, Divider, Form, Input } from 'antd';
import moment from 'moment';
import React, { useState } from 'react';
import { DATE_FORMATS } from '../../../common/constants';
import { formValidatorRules } from '../../../common/utils';
import ModalComponent from '../../../components/ModalComponent';

const { Item } = Form;
const { RangePicker } = DatePicker;
const { TextArea } = Input;
const { required, whitespaceNotAllowed } = formValidatorRules;

const RaiseClosureRequestModal = ({
  isModalOpen,
  setIsModalOpen,
  onFinish,
  loadings,
  form,
}) => {
  const [isValidateTrigger, setIsValidateTrigger] = useState(false);

  const handleCancel = () => {
    setIsModalOpen(false);
    form?.resetFields();
  };

  return (
    <>
      <ModalComponent
        open={isModalOpen}
        onCancel={handleCancel}
        onOk={form?.submit}
        title="Temporary Closure Request"
        okText="Save"
        confirmLoading={loadings}
        destroyOnClose
      >
        <Divider />
        <div className="mb-16">
          <Form
            form={form}
            onFinish={onFinish}
            autoComplete="off"
            layout="vertical"
            validateTrigger={isValidateTrigger ? 'onChange' : 'onSubmit'}
            onFinishFailed={() => setIsValidateTrigger(true)}
          >
            <Item
              label="Temporary Closure Date"
              name="closureDate"
              rules={[
                {
                  required: true,
                  message: 'Please Select Library Closure Date!',
                },
              ]}
            >
              <RangePicker
                format={DATE_FORMATS?.REGULAR}
                className="full-width"
                placeholder={['From Date', 'To Date']}
                disabledDate={(current) =>
                  current &&
                  current < moment()?.subtract(1, 'days')?.endOf('day')
                }
              />
            </Item>
            <Item
              label="Reason for Temporary Closure"
              name="reason"
              rules={[
                { ...required, message: 'Please Enter For Temporary Closure!' },
                whitespaceNotAllowed,
              ]}
            >
              <TextArea
                rows={4}
                allowClear
                placeholder="Enter Reason For Temporary Closure"
                showCount
                maxLength={700}
              />
            </Item>
          </Form>
        </div>
      </ModalComponent>
    </>
  );
};

export default RaiseClosureRequestModal;
