import { Divider, Form } from 'antd';
import React, { useEffect, useState } from 'react';
import { NUMBER_FIELD_TYPES } from '../../../../../common/constants';
import { formValidatorRules } from '../../../../../common/utils';
import ModalComponent from '../../../../../components/ModalComponent';
import NumberComponent from '../../../../../components/NumberComponent';

const { Item } = Form;
const { positiveNumber } = formValidatorRules;

const DeliveryModal = ({
  isModalOpen,
  setIsModalOpen,
  onFinish,
  loadings,
  endUserDetail,
}) => {
  const [form] = Form.useForm();
  const [isValidateTrigger, setIsValidateTrigger] = useState(false);

  useEffect(() => {
    form?.setFieldsValue({
      freeDeliveries:
        endUserDetail?.userSubscriptionMonthlyRestrictions?.[0]
          ?.subscriptionFeatures?.totalFreeDeliveryPerMonth,
    });
  }, [endUserDetail, isModalOpen]);

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  return (
    <>
      <ModalComponent
        open={isModalOpen}
        onCancel={handleCancel}
        onOk={form?.submit}
        title="Edit Free Deliveries"
        okText="Save"
        confirmLoading={loadings}
        destroyOnClose
        forceRender
      >
        <Divider />
        <div className="mb-16">
          <Form
            form={form}
            onFinish={onFinish}
            autoComplete="off"
            layout="vertical"
            validateTrigger={isValidateTrigger ? 'onChange' : 'onSubmit'}
            onFinishFailed={() => setIsValidateTrigger(true)}
          >
            <Item
              label="Free Deliveries"
              name="freeDeliveries"
              rules={[
                { required: true, message: 'Please Enter Free Deliveries!' },
                positiveNumber(false),
              ]}
            >
              <NumberComponent
                allowClear
                isFractional={false}
                placeholder="Enter Free Deliveries"
                type={NUMBER_FIELD_TYPES?.DELIVERY}
              />
            </Item>
          </Form>
        </div>
      </ModalComponent>
    </>
  );
};

export default DeliveryModal;
