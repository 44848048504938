import { useLazyQuery, useMutation } from '@apollo/client';
import { Button, Card, Form } from 'antd';
import { capitalize } from 'lodash';
import moment from 'moment';
import React, { useContext, useEffect, useState } from 'react';
import { AppContext } from '../../../AppContext';
import { MODULES } from '../../../common/constants';
import LoaderComponent from '../../../components/LoaderComponent';
import Portal from '../../../components/Portal';
import { GET_CURRENT_USER } from '../../auth/graphql/Queries';
import ChangePasswordModal from '../components/ChangePasswordModal';
import LibrarianProfileForm from '../components/LibrarianProfileForm';
import { UPDATE_LIBRARY } from '../graphql/Mutations';
import { LIBRARY } from '../graphql/Queries';

const LibrarianProfile = () => {
  const [form] = Form.useForm();
  const { dispatch, getCurrentUser } = useContext(AppContext);
  const [libraryDetail, setLibraryDetail] = useState({});
  const [isDisabled, setIsDisabled] = useState(true);
  const [isSubmitLoading, setIsSubmitLoading] = useState(false);
  const [isLibraryLoading, setIsLibraryLoading] = useState(true);
  const [isPasswordVisible, setIsPasswordVisible] = useState(false);
  const { firstName = '', lastName = '' } = getCurrentUser() || {};

  const [updateLibrary] = useMutation(UPDATE_LIBRARY, {
    onError: () => {},
  });

  const [library] = useLazyQuery(LIBRARY, {
    onCompleted: (response) => {
      setLibraryDetail(response?.library);
      setIsLibraryLoading(false);
    },
    fetchPolicy: 'network-only',
    onError() {},
  });

  const [fetchCurrentUser] = useLazyQuery(GET_CURRENT_USER, {
    fetchPolicy: 'network-only',
    onError() {},
  });

  useEffect(() => {
    setIsLibraryLoading(true);
    setLibraryDetail([]);
    library();
  }, []);

  const updateLibraryFun = async (values) => {
    setIsSubmitLoading(true);
    dispatch({
      type: 'SET_SHOW_PROMPT',
      data: false,
    });
    const response = await updateLibrary({
      variables: {
        data: {
          city: values?.city,
          state: values?.state,
          contactNo: values?.contact,
          address: values?.address?.trim(),
          email: values?.email?.trim(),
          firstName: values?.firstName?.trim(),
          lastName: values?.lastName?.trim(),
          name: values?.name?.trim(),
          pincodes: values?.libraryPincode,
          preferredTimeFrom: values?.from,
          preferredTimeTo: values?.to,
        },
      },
    });
    if (response?.data) {
      setIsLibraryLoading(true);
      fetchCurrentUser();
    }
    setIsSubmitLoading(false);
  };

  const handleDiscard = () => {
    setIsLibraryLoading(true);
    library();
    setIsDisabled(true);
    dispatch({
      type: 'SET_SHOW_PROMPT',
      data: false,
    });
  };

  const handleShowPrompt = () => {
    setIsDisabled(false);
    dispatch({
      type: 'SET_SHOW_PROMPT',
      data: true,
    });
  };

  const initialValues = {
    ...libraryDetail,
    contact: libraryDetail?.librarian?.contactNo,
    email: libraryDetail?.librarian?.email,
    firstName: libraryDetail?.librarian?.firstName,
    lastName: libraryDetail?.librarian?.lastName,
    pincode: libraryDetail?.librarian?.pincode?.id,
    state: libraryDetail?.state,
    city: libraryDetail?.city,
    from: moment(libraryDetail?.preferredTimeFrom),
    to: moment(libraryDetail?.preferredTimeTo),
    libraryPincode: libraryDetail?.pincodes?.map((pincode) => pincode?.pincode),
    id: libraryDetail?.libraryNumber,
  };

  return (
    <>
      <Portal portalId="header-title-content">
        <div className="portal-header">{MODULES?.PROFILE}</div>
      </Portal>
      <Portal portalId="header-right-content">
        <div className="header-btn-wrapper">
          <Button
            onClick={handleDiscard}
            disabled={isSubmitLoading || isDisabled}
            type="primary"
            className="common-button mr-16"
          >
            Discard Changes
          </Button>
          <Button
            className="common-button"
            size="middle"
            onClick={form.submit}
            loading={isSubmitLoading}
            disabled={isDisabled}
          >
            Save
          </Button>
        </div>
      </Portal>
      <Portal portalId="header-right-content-phones">
        <div className="header-btn-wrapper">
          <Button
            onClick={handleDiscard}
            disabled={isSubmitLoading || isDisabled}
            type="primary"
            className="common-button mr-16"
          >
            Discard Changes
          </Button>
          <Button
            className="common-button"
            size="middle"
            onClick={form.submit}
            loading={isSubmitLoading}
            disabled={isDisabled}
          >
            Save
          </Button>
        </div>
      </Portal>
      <ChangePasswordModal
        visible={isPasswordVisible}
        setVisible={setIsPasswordVisible}
      />
      <Card
        className="full-height-with-nav"
        title={`${capitalize(firstName)} ${capitalize(lastName)} Details`}
        extra={
          <Button
            type="primary"
            onClick={() => setIsPasswordVisible(true)}
            size="small"
            className="change-password-btn"
          >
            Change Password
          </Button>
        }
      >
        {isLibraryLoading ? (
          <LoaderComponent
            size="large"
            setHeight="60"
            spinning={isLibraryLoading}
          />
        ) : (
          <div className="library-form">
            <LibrarianProfileForm
              form={form}
              onFinish={updateLibraryFun}
              handleShowPrompt={handleShowPrompt}
              initialValues={initialValues}
              libraryDetail={libraryDetail}
            />
          </div>
        )}
      </Card>
    </>
  );
};

export default LibrarianProfile;
