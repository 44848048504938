import React, { useContext } from 'react';
import { Route, Switch } from 'react-router-dom';
import AccessControl from '../../AccessControl';
import { AppContext } from '../../AppContext';
import { PERMISSIONS_KEY, ROUTES, USER_ROLES } from '../../common/constants';
import './dashboard.less';
import Dashboard from './pages/Dashboard';
import LibrarianDashboard from './pages/LibrarianDashboard';

const DashboardWrapper = () => {
  const { getCurrentUser } = useContext(AppContext);
  const { roles } = getCurrentUser() || {};

  return (
    <Switch>
      <Route
        exact
        path={ROUTES?.MAIN}
        render={(props) => (
          <AccessControl permissionKey={PERMISSIONS_KEY?.OVERVIEW}>
            {roles?.includes(USER_ROLES?.LIBRARIAN?.key) ? (
              <LibrarianDashboard {...props} />
            ) : (
              <Dashboard {...props} />
            )}
          </AccessControl>
        )}
      />
    </Switch>
  );
};

export default DashboardWrapper;
