import { useLazyQuery } from '@apollo/client';
import { Empty, Select } from 'antd';
import { debounce, uniqBy } from 'lodash';
import React, { useEffect, useState } from 'react';
import { LIMIT, SORT } from '../../../../../common/constants';
import SelectComponent from '../../../../../components/SelectComponent';
import { LIBRARIES } from '../graphql/Queries';

const { Option } = Select;

const LibrarySelect = (props) => {
  const {
    library,
    libraryData,
    isModalOpen = true,
    isActiveLibOnly = false,
    ...rest
  } = props;
  const [libraryList, setLibraryList] = useState([]);
  const [librarySearchTerm, setLibrarySearchTerm] = useState('');
  const [isAllLibrariesFetched, setIsAllLibrariesFetched] = useState(false);
  const [isLibrariesAllowClear, setIsLibrariesAllowClear] = useState(false);
  const [isLibrariesDropdownVisible, setIsLibrariesDropdownVisible] = useState(
    false,
  );
  const [
    isFetchMoreLibrariesLoading,
    setIsFetchMoreLibrariesLoading,
  ] = useState(false);

  const [libraries, { loading: isLibraryLoading }] = useLazyQuery(LIBRARIES, {
    onCompleted: (response) => {
      if (response?.libraries?.data?.length < LIMIT) {
        setIsAllLibrariesFetched(true);
      }
      setIsFetchMoreLibrariesLoading(false);
      if (libraryData) {
        setLibraryList(
          uniqBy(
            [
              {
                name: libraryData?.name,
                id: libraryData?.id,
                libraryNumber: libraryData?.libraryNumber,
              },
              ...libraryList,
              ...response?.libraries?.data,
            ],
            'id',
          ),
        );
        return;
      }
      setLibraryList([...libraryList, ...response?.libraries?.data]);
    },
    fetchPolicy: 'network-only',
    onError() {},
  });

  useEffect(() => {
    setLibrarySearchTerm('');
    setLibraryList([]);
    if (isModalOpen) {
      libraries({
        variables: {
          filter: {
            limit: LIMIT,
            skip: 0,
            search: librarySearchTerm,
            ...(isActiveLibOnly && { isActive: true }),
          },
          sort: {
            field: 'createdAt',
            order: SORT?.DESC,
          },
        },
      });
    }
    setIsAllLibrariesFetched(false);
  }, [isLibrariesDropdownVisible, isModalOpen]);

  const handleLibrariesScroll = (event) => {
    if (!isAllLibrariesFetched && !isLibraryLoading) {
      const target = event?.target;
      const currentLength = libraryList?.length;
      if (
        target?.scrollTop + target?.offsetHeight >=
        target?.scrollHeight - 5
      ) {
        setIsFetchMoreLibrariesLoading(true);
        libraries({
          variables: {
            filter: {
              limit: LIMIT,
              skip: currentLength,
              search: librarySearchTerm,
              ...(isActiveLibOnly && { isActive: true }),
            },
            sort: {
              field: 'createdAt',
              order: SORT?.DESC,
            },
          },
        });
      }
    }
  };

  const handleLibrariesSearch = (searchTerm) => {
    const trimSearch = searchTerm?.trim();
    setIsAllLibrariesFetched(false);
    setLibraryList([]);
    setLibrarySearchTerm(trimSearch);
    libraries({
      variables: {
        filter: {
          limit: LIMIT,
          skip: 0,
          search: trimSearch,
          ...(isActiveLibOnly && { isActive: true }),
        },
        sort: {
          field: 'createdAt',
          order: SORT?.DESC,
        },
      },
    });
  };

  const handleLibrariesScrollDebounce = debounce(handleLibrariesScroll, 500);
  const handleSearchLibrariesDebounce = debounce(handleLibrariesSearch, 500);

  useEffect(() => {
    handleLibrariesScrollDebounce?.cancel();
  }, [handleLibrariesScrollDebounce]);

  useEffect(() => {
    handleSearchLibrariesDebounce?.cancel();
  }, [handleSearchLibrariesDebounce]);

  useEffect(() => {
    if (isLibraryLoading) {
      setIsLibrariesAllowClear(false);
    } else {
      setIsLibrariesAllowClear(true);
    }
  }, [isLibraryLoading]);

  return (
    <SelectComponent
      showSearch
      loading={
        (isLibraryLoading && isLibrariesDropdownVisible) ||
        isFetchMoreLibrariesLoading
      }
      placeholder="Select Library"
      optionFilterProp="children"
      allowClear={isLibrariesAllowClear}
      onPopupScroll={handleLibrariesScrollDebounce}
      onSearch={handleSearchLibrariesDebounce}
      onDropdownVisibleChange={(visible) =>
        setIsLibrariesDropdownVisible(visible)
      }
      filterOption={false}
      notFoundContent={
        !isFetchMoreLibrariesLoading && isLibraryLoading ? (
          <span>Loading...</span>
        ) : (
          <Empty image={Empty?.PRESENTED_IMAGE_SIMPLE} />
        )
      }
      {...rest}
    >
      {libraryList
        ?.filter((lib) => lib?.id !== library?.id)
        ?.map((lib) => (
          <Option key={lib?.id} value={lib?.id}>
            {lib?.name} ({lib?.libraryNumber})
          </Option>
        ))}
    </SelectComponent>
  );
};

export default LibrarySelect;
