import { useLazyQuery } from '@apollo/client';
import { Empty, Select } from 'antd';
import { debounce, uniqBy } from 'lodash';
import React, { useEffect, useState } from 'react';
import { LIMIT, SORT } from '../common/constants';
import { CITIES } from './graphql/Queries';
import SelectComponent from './SelectComponent';

const { Option } = Select;

const CitySelect = (props) => {
  const { isView, stateId, userData, isStateIdChanged, ...rest } = props;
  const [cityList, setCityList] = useState([]);
  const [citiesSearchTerm, setCitiesSearchTerm] = useState('');
  const [isAllCitiesFetched, setIsAllCitiesFetched] = useState(false);
  const [isCitiesAllowClear, setIsCitiesAllowClear] = useState(false);
  const [isCitiesDropdownVisible, setIsCitiesDropdownVisible] = useState(false);
  const [isFetchMoreCitiesLoading, setIsFetchMoreCitiesLoading] = useState(
    false,
  );

  const [cities, { loading: isCitiesLoading }] = useLazyQuery(CITIES, {
    onCompleted: (response) => {
      if (response?.cities?.data?.length < LIMIT) {
        setIsAllCitiesFetched(true);
      }
      setIsFetchMoreCitiesLoading(false);
      if (userData?.city && !isStateIdChanged) {
        setCityList(
          uniqBy(
            [{ name: userData?.city }, ...cityList, ...response?.cities?.data],
            'name',
          ),
        );
        return;
      }
      setCityList([...cityList, ...response?.cities?.data]);
    },
    fetchPolicy: 'network-only',
    onError() {},
  });

  useEffect(() => {
    if (stateId) {
      setCitiesSearchTerm('');
      setCityList([]);
      cities({
        variables: {
          filter: {
            limit: LIMIT,
            skip: 0,
            search: citiesSearchTerm,
            stateName: stateId,
          },
          sort: {
            field: 'name',
            order: SORT?.ASC,
          },
        },
      });
      setIsAllCitiesFetched(false);
    }
  }, [isCitiesDropdownVisible, stateId]);

  const handleCategoriesScroll = (event) => {
    if (!isAllCitiesFetched && !isCitiesLoading) {
      const target = event?.target;
      const currentLength = cityList?.length;
      if (
        target?.scrollTop + target?.offsetHeight >=
        target?.scrollHeight - 5
      ) {
        setIsFetchMoreCitiesLoading(true);
        cities({
          variables: {
            filter: {
              limit: LIMIT,
              skip: currentLength,
              search: citiesSearchTerm,
              stateName: stateId,
            },
            sort: {
              field: 'name',
              order: SORT?.ASC,
            },
          },
        });
      }
    }
  };

  const handleCategoriesSearch = (searchTerm) => {
    const trimSearch = searchTerm?.trim();
    setIsAllCitiesFetched(false);
    setCityList([]);
    setCitiesSearchTerm(trimSearch);
    cities({
      variables: {
        filter: {
          limit: LIMIT,
          skip: 0,
          search: trimSearch,
          stateName: stateId,
        },
        sort: {
          field: 'name',
          order: SORT?.ASC,
        },
      },
    });
  };

  const handleCategoriesScrollDebounce = debounce(handleCategoriesScroll, 500);
  const handleSearchCategoriesDebounce = debounce(handleCategoriesSearch, 500);

  useEffect(() => {
    handleCategoriesScrollDebounce?.cancel();
  }, [handleCategoriesScrollDebounce]);

  useEffect(() => {
    handleSearchCategoriesDebounce?.cancel();
  }, [handleSearchCategoriesDebounce]);

  useEffect(() => {
    if (isCitiesLoading) {
      setIsCitiesAllowClear(false);
    } else {
      setIsCitiesAllowClear(true);
    }
  }, [isCitiesLoading]);

  return (
    <SelectComponent
      showSearch
      loading={
        (isCitiesLoading && isCitiesDropdownVisible) || isFetchMoreCitiesLoading
      }
      placeholder="Select City"
      optionFilterProp="children"
      allowClear={isCitiesAllowClear}
      onPopupScroll={handleCategoriesScrollDebounce}
      onSearch={handleSearchCategoriesDebounce}
      onDropdownVisibleChange={(visible) => {
        setIsCitiesDropdownVisible(visible);
      }}
      filterOption={false}
      notFoundContent={
        !isFetchMoreCitiesLoading && isCitiesLoading ? (
          <span>Loading...</span>
        ) : (
          <Empty image={Empty?.PRESENTED_IMAGE_SIMPLE} />
        )
      }
      disabled={isView || !stateId || userData?.isArchived}
      {...rest}
    >
      {cityList?.map((city) => (
        <Option key={city?.name} value={city?.name}>
          {city?.name}
        </Option>
      ))}
    </SelectComponent>
  );
};

export default CitySelect;
