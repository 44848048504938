import { useLazyQuery, useMutation } from '@apollo/client';
import React, { useContext, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { AppContext } from '../../AppContext';
import { ROUTES } from '../../common/constants';
import LoaderComponent from '../../components/LoaderComponent';
import { REFRESH_TOKEN } from './graphql/Mutations';
import { GET_CURRENT_USER } from './graphql/Queries';

const RefreshToken = () => {
  const { initializeAuth, getRefreshToken } = useContext(AppContext);
  const history = useHistory();
  const refreshToken = getRefreshToken();
  function successCallback(
    accessToken,
    userData,
    permissions,
    fetchedRefreshToken,
  ) {
    initializeAuth(accessToken, userData, permissions, fetchedRefreshToken);
  }

  const [getCurrentUser, { loading: currentUserLoading }] = useLazyQuery(
    GET_CURRENT_USER,
    {
      fetchPolicy: 'network-only',
      onError() {},
    },
  );

  const [refresh, { loading }] = useMutation(REFRESH_TOKEN, {
    fetchPolicy: 'network-only',
    async onCompleted(response) {
      const accessToken = response?.refreshToken?.token;
      const res = await getCurrentUser({
        context: {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        },
      });
      if (res?.data && successCallback) {
        const userData = res?.data?.currentUserProfile;
        successCallback(
          accessToken,
          userData,
          userData?.permissions,
          userData?.refreshToken,
        );
        history?.push(ROUTES?.MAIN);
      }
    },
    onError() {
      history?.replace(ROUTES?.LOGOUT);
    },
  });

  useEffect(() => {
    if (refreshToken)
      refresh({
        variables: {
          data: {
            refreshToken,
          },
        },
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (loading || currentUserLoading) return <LoaderComponent />;

  return null;
};

export default RefreshToken;
