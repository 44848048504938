import { useLazyQuery, useMutation } from '@apollo/client';
import { Button, Form } from 'antd';
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import {
  MODULES,
  PLAN_NAMES,
  PLAN_TYPES,
  ROUTES,
} from '../../../../../common/constants';
import LoaderComponent from '../../../../../components/LoaderComponent';
import Portal from '../../../../../components/Portal';
import PopularPlanSelect from '../../../components/PopularPlanSelect';
import SubscriptionForm from '../../../components/SubscriptionForm';
import { SYNC_SUBSCRIPTION_PLANS } from '../../../graphql/Mutations';
import { SUBSCRIPTION_PLANS_ADMIN } from '../../../graphql/Queries';
import ForestTabs from '../components/ForestTabs';

const SemiAnnualForest = () => {
  const history = useHistory();
  const [form] = Form.useForm();
  const [isSyncPlanLoading, setIsSyncPlanLoading] = useState(false);
  const [isSync, setIsSync] = useState(false);
  const [planDetails, setPlanDetails] = useState({});
  const [isPlanLoading, setIsPlanLoading] = useState(true);

  const [subscriptionPlansAdmin] = useLazyQuery(SUBSCRIPTION_PLANS_ADMIN, {
    onCompleted: (response) => {
      setPlanDetails(response?.subscriptionPlansAdmin?.data?.[0]);
      setIsPlanLoading(false);
    },
    fetchPolicy: 'network-only',
    onError() {},
  });

  const [syncSubscriptionPlans] = useMutation(SYNC_SUBSCRIPTION_PLANS, {
    onError: () => {},
  });

  const handleSync = async () => {
    setIsSyncPlanLoading(true);
    const res = await syncSubscriptionPlans();
    if (res?.data) {
      setIsSync(!isSync);
    }
    setIsSyncPlanLoading(false);
  };

  useEffect(() => {
    setIsPlanLoading(true);
    subscriptionPlansAdmin({
      variables: {
        filter: {
          name: PLAN_NAMES?.FOREST?.value,
          type: PLAN_TYPES?.SEMI_ANNUAL?.value,
        },
      },
    });
  }, [isSync]);

  return (
    <>
      <Portal portalId="header-title-content">
        <div className="portal-header">{MODULES?.FOREST}</div>
      </Portal>
      <Portal portalId="header-right-content">
        <div className="header-btn-wrapper">
          <PopularPlanSelect />
          <Button
            loading={isSyncPlanLoading}
            className="common-button mr-16"
            size="middle"
            onClick={handleSync}
          >
            Sync Plan
          </Button>
          <Button
            className="common-button mr-16"
            size="middle"
            onClick={() =>
              history?.push(
                `${ROUTES?.SUBSCRIPTION_PLANS}${ROUTES?.FOREST}${ROUTES?.SEMI_ANNUAL}/edit`,
              )
            }
          >
            Edit Plan
          </Button>
        </div>
      </Portal>
      <Portal portalId="header-right-content-phones">
        <div className="header-btn-wrapper">
          <PopularPlanSelect />
          <Button
            loading={isSyncPlanLoading}
            className="common-button mr-16"
            size="middle"
            onClick={handleSync}
          >
            Sync Plan
          </Button>
          <Button
            className="common-button mr-16"
            size="middle"
            onClick={() =>
              history?.push(
                `${ROUTES?.SUBSCRIPTION_PLANS}${ROUTES?.FOREST}${ROUTES?.SEMI_ANNUAL}/edit`,
              )
            }
          >
            Edit Plan
          </Button>
        </div>
      </Portal>
      <ForestTabs>
        {isPlanLoading ? (
          <LoaderComponent
            size="large"
            setHeight="60"
            spinning={isPlanLoading}
          />
        ) : (
          <SubscriptionForm
            form={form}
            planName={PLAN_NAMES?.FOREST?.value}
            planType={PLAN_TYPES?.SEMI_ANNUAL?.value}
            isView
            planDetails={planDetails}
          />
        )}
      </ForestTabs>
    </>
  );
};

export default SemiAnnualForest;
