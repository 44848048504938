import { DownOutlined } from '@ant-design/icons';
import { Card, Col, List, Row, Tree } from 'antd';
import React from 'react';
import { MODULES, ROUTES, TABS } from '../../../../../common/constants';

const readData = [
  {
    title: MODULES?.DASHBOARD,
    key: ROUTES?.MAIN,
  },
  {
    title: MODULES?.REQUESTS,
    key: ROUTES?.REQUESTS,
    children: [
      {
        title: MODULES?.LIBRARY,
        key: `${ROUTES?.REQUESTS}${ROUTES?.LIBRARY}`,
        children: [
          {
            title: TABS?.BAGS?.label,
            key: `${ROUTES?.REQUESTS}${ROUTES?.LIBRARY}${ROUTES?.BAGS}`,
          },
          {
            title: TABS?.NEW_TOYS?.label,
            key: `${ROUTES?.REQUESTS}${ROUTES?.LIBRARY}${ROUTES?.NEW_TOYS}`,
          },
        ],
      },
    ],
  },
  {
    title: MODULES?.ORDERS,
    key: ROUTES?.ORDERS,
    children: [
      {
        title: MODULES?.NEW,
        key: `${ROUTES?.ORDERS}${ROUTES?.NEW}`,
        children: [
          {
            title: TABS?.DELIVERY?.label,
            key: `${ROUTES?.ORDERS}${ROUTES?.NEW}${ROUTES?.DELIVERY}`,
          },
          {
            title: TABS?.RETURN?.label,
            key: `${ROUTES?.ORDERS}${ROUTES?.NEW}${ROUTES?.RETURN}`,
          },
        ],
      },
      {
        title: MODULES?.ALL,
        key: `${ROUTES?.ORDERS}${ROUTES?.ALL}`,
        children: [
          {
            title: TABS?.DELIVERY?.label,
            key: `${ROUTES?.ORDERS}${ROUTES?.ALL}${ROUTES?.DELIVERY}`,
          },
          {
            title: TABS?.RETURN?.label,
            key: `${ROUTES?.ORDERS}${ROUTES?.ALL}${ROUTES?.RETURN}`,
          },
        ],
      },
    ],
  },
  {
    title: MODULES?.REPORTS,
    key: ROUTES?.REPORTS,
    children: [
      {
        title: MODULES?.BULK_UPDATE,
        key: `${ROUTES?.REPORTS}${ROUTES?.BULK_UPDATE}`,
      },
      {
        title: MODULES?.UNFULFILLED_ORDERS,
        key: `${ROUTES?.REPORTS}${ROUTES?.UNFULFILLED_ORDERS}`,
      },
    ],
  },
  {
    title: MODULES?.MASTER_LISTS,
    key: ROUTES?.MASTER_LISTS,
    children: [
      {
        title: MODULES?.LIBRARY,
        key: `${ROUTES?.MASTER_LISTS}${ROUTES?.LIBRARY}`,
        children: [
          {
            title: TABS?.BASIC_DETAILS?.label,
            key: `${ROUTES?.MASTER_LISTS}${ROUTES?.LIBRARY}${ROUTES?.BASIC_DETAILS}`,
          },
          {
            title: TABS?.INVENTORY_DETAILS?.label,
            key: `${ROUTES?.MASTER_LISTS}${ROUTES?.LIBRARY}${ROUTES?.INVENTORY_DETAILS}`,
          },
        ],
      },
      {
        title: MODULES?.TOY,
        key: `${ROUTES?.MASTER_LISTS}${ROUTES?.TOY}`,
      },
    ],
  },
  {
    title: MODULES?.SYSTEM_USERS,
    key: ROUTES?.SYSTEM_USERS,
    children: [
      {
        title: MODULES?.DELIVERY_PARTNERS,
        key: `${ROUTES?.SYSTEM_USERS}${ROUTES?.DELIVERY_PARTNERS}`,
      },
    ],
  },
  {
    title: MODULES?.EXPORTS,
    key: ROUTES?.EXPORTS,
  },
  {
    title: MODULES?.NOTIFICATIONS,
    key: ROUTES?.NOTIFICATIONS,
  },
];

const createData = [
  {
    title: MODULES?.ORDERS,
    key: ROUTES?.ORDERS,
    children: [
      {
        title: MODULES?.NEW,
        key: `${ROUTES?.ORDERS}${ROUTES?.NEW}`,
        children: [
          {
            title: TABS?.DELIVERY?.label,
            key: `${ROUTES?.ORDERS}${ROUTES?.NEW}${ROUTES?.DELIVERY}`,
          },
          {
            title: TABS?.RETURN?.label,
            key: `${ROUTES?.ORDERS}${ROUTES?.NEW}${ROUTES?.RETURN}`,
          },
        ],
      },
      {
        title: MODULES?.ALL,
        key: `${ROUTES?.ORDERS}${ROUTES?.ALL}`,
        children: [
          {
            title: TABS?.DELIVERY?.label,
            key: `${ROUTES?.ORDERS}${ROUTES?.ALL}${ROUTES?.DELIVERY}`,
          },
          {
            title: TABS?.RETURN?.label,
            key: `${ROUTES?.ORDERS}${ROUTES?.ALL}${ROUTES?.RETURN}`,
          },
        ],
      },
    ],
  },
  {
    title: MODULES?.MASTER_LISTS,
    key: ROUTES?.MASTER_LISTS,
    children: [
      {
        title: MODULES?.LIBRARY,
        key: `${ROUTES?.MASTER_LISTS}${ROUTES?.LIBRARY}`,
        children: [
          {
            title: TABS?.INVENTORY_DETAILS?.label,
            key: `${ROUTES?.MASTER_LISTS}${ROUTES?.LIBRARY}${ROUTES?.INVENTORY_DETAILS}`,
          },
        ],
      },
      {
        title: MODULES?.TOY,
        key: `${ROUTES?.MASTER_LISTS}${ROUTES?.TOY}`,
      },
    ],
  },
];

const updateData = [
  {
    title: MODULES?.REQUESTS,
    key: ROUTES?.REQUESTS,
    children: [
      {
        title: MODULES?.LIBRARY,
        key: `${ROUTES?.REQUESTS}${ROUTES?.LIBRARY}`,
        children: [
          {
            title: TABS?.BAGS?.label,
            key: `${ROUTES?.REQUESTS}${ROUTES?.LIBRARY}${ROUTES?.BAGS}`,
          },
          {
            title: TABS?.NEW_TOYS?.label,
            key: `${ROUTES?.REQUESTS}${ROUTES?.LIBRARY}${ROUTES?.NEW_TOYS}`,
          },
        ],
      },
    ],
  },
  {
    title: MODULES?.ORDERS,
    key: ROUTES?.ORDERS,
    children: [
      {
        title: MODULES?.NEW,
        key: `${ROUTES?.ORDERS}${ROUTES?.NEW}`,
        children: [
          {
            title: TABS?.DELIVERY?.label,
            key: `${ROUTES?.ORDERS}${ROUTES?.NEW}${ROUTES?.DELIVERY}`,
          },
          {
            title: TABS?.RETURN?.label,
            key: `${ROUTES?.ORDERS}${ROUTES?.NEW}${ROUTES?.RETURN}`,
          },
        ],
      },
      {
        title: MODULES?.ALL,
        key: `${ROUTES?.ORDERS}${ROUTES?.ALL}`,
        children: [
          {
            title: TABS?.DELIVERY?.label,
            key: `${ROUTES?.ORDERS}${ROUTES?.ALL}${ROUTES?.DELIVERY}`,
          },
          {
            title: TABS?.RETURN?.label,
            key: `${ROUTES?.ORDERS}${ROUTES?.ALL}${ROUTES?.RETURN}`,
          },
        ],
      },
    ],
  },
  {
    title: MODULES?.MASTER_LISTS,
    key: ROUTES?.MASTER_LISTS,
    children: [
      {
        title: MODULES?.LIBRARY,
        key: `${ROUTES?.MASTER_LISTS}${ROUTES?.LIBRARY}`,
        children: [
          {
            title: TABS?.INVENTORY_DETAILS?.label,
            key: `${ROUTES?.MASTER_LISTS}${ROUTES?.LIBRARY}${ROUTES?.INVENTORY_DETAILS}`,
          },
        ],
      },
      {
        title: MODULES?.TOY,
        key: `${ROUTES?.MASTER_LISTS}${ROUTES?.TOY}`,
      },
    ],
  },
  {
    title: MODULES?.NOTIFICATIONS,
    key: ROUTES?.NOTIFICATIONS,
  },
];

const OrderInventoryPermission = () => (
  <Row gutter={[8, 16]}>
    <Col xs={24} sm={24} md={12} lg={12} xl={6} xxl={6}>
      <List.Item>
        <Card title="Read Only">
          <Tree showLine switcherIcon={<DownOutlined />} treeData={readData} />
        </Card>
      </List.Item>
    </Col>
    <Col xs={24} sm={24} md={12} lg={12} xl={6} xxl={6}>
      <List.Item>
        <Card title="Create Only">
          <Tree
            showLine
            switcherIcon={<DownOutlined />}
            treeData={createData}
          />
        </Card>
      </List.Item>
    </Col>
    <Col xs={24} sm={24} md={12} lg={12} xl={6} xxl={6}>
      <List.Item>
        <Card title="Update (edit & other actions)">
          <Tree
            showLine
            switcherIcon={<DownOutlined />}
            treeData={updateData}
          />
        </Card>
      </List.Item>
    </Col>
    <Col xs={24} sm={24} md={12} lg={12} xl={6} xxl={6}>
      <List.Item>
        <Card title="Delete Only">No Delete Permissions</Card>
      </List.Item>
    </Col>
  </Row>
);

export default OrderInventoryPermission;
