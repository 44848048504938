import { Divider, Form } from 'antd';
import React, { useEffect, useState } from 'react';
import { formValidatorRules } from '../../../../../common/utils';
import ModalComponent from '../../../../../components/ModalComponent';
import NumberComponent from '../../../../../components/NumberComponent';

const { Item } = Form;
const { positiveNumber } = formValidatorRules;

const HoldingModal = ({
  isModalOpen,
  setIsModalOpen,
  onFinish,
  loadings,
  endUserDetail,
}) => {
  const [form] = Form.useForm();
  const [isValidateTrigger, setIsValidateTrigger] = useState(false);

  useEffect(() => {
    form?.setFieldsValue({
      totalKeepCountPerMonth:
        endUserDetail?.subscriptionFeatures?.totalKeepCountPerMonth,
    });
  }, [endUserDetail, isModalOpen]);

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  return (
    <>
      <ModalComponent
        open={isModalOpen}
        onCancel={handleCancel}
        onOk={form?.submit}
        title="Edit Toy Holdings Count"
        okText="Save"
        confirmLoading={loadings}
        destroyOnClose
        forceRender
      >
        <Divider />
        <div className="mb-16">
          <Form
            form={form}
            onFinish={onFinish}
            autoComplete="off"
            layout="vertical"
            validateTrigger={isValidateTrigger ? 'onChange' : 'onSubmit'}
            onFinishFailed={() => setIsValidateTrigger(true)}
          >
            <Item
              label="Toy Holdings Count"
              name="totalKeepCountPerMonth"
              rules={[
                { required: true, message: 'Please Enter Toy Holding Count!' },
                () => ({
                  validator(_, value) {
                    const positiveNumberValidator = positiveNumber(false);
                    return positiveNumberValidator
                      .validator(_, value)
                      .catch((error) => Promise.reject(new Error(error)))
                      .then(() => {
                        if (
                          value &&
                          Number(value) !== 0 &&
                          value <
                            endUserDetail?.userSubscriptionRenewals?.[0]
                              ?.toyHoldingCount
                        ) {
                          return Promise.reject(
                            new Error(
                              `Count should not be less than user's holding`,
                            ),
                          );
                        }
                        return Promise.resolve();
                      });
                  },
                }),
              ]}
            >
              <NumberComponent
                allowClear
                isFractional={false}
                placeholder="Enter Toy Holding Count"
              />
            </Item>
          </Form>
        </div>
      </ModalComponent>
    </>
  );
};

export default HoldingModal;
