import { gql } from '@apollo/client';

// eslint-disable-next-line import/prefer-default-export
export const CREATE_FACILITATE = gql`
  mutation CreateFacilitate($data: CreateFacilitateInput!) {
    createFacilitate(data: $data) {
      message
    }
  }
`;

export const UPDATE_FACILITATES = gql`
  mutation UpdateFacilitate(
    $where: FacilitateUniqueInput!
    $data: UpdateFacilitateInput
  ) {
    updateFacilitate(where: $where, data: $data) {
      message
    }
  }
`;
