import { useLazyQuery } from '@apollo/client';
import { Card, Form } from 'antd';
import React, { useEffect, useState } from 'react';
import { ROUTES } from '../../../common/constants';
import GoBackButton from '../../../components/GoBackButton';
import LoaderComponent from '../../../components/LoaderComponent';
import Portal from '../../../components/Portal';
import ToyDetails from '../components/ToyDetails';
import { TOY } from '../graphql/Queries';

const ViewToy = (props) => {
  const { match: { params: { id } = {} } = {} } = props;
  const [form] = Form.useForm();
  const [toyDetail, setToyDetail] = useState({});
  const [isToyLoading, setIsToyLoading] = useState(true);
  const [toyImages, setToyImages] = useState([]);
  const [featuredImage, setFeaturedImage] = useState([]);
  const [toyVideo, setToyVideo] = useState([]);

  const [toy] = useLazyQuery(TOY, {
    onCompleted: (response) => {
      const toyImagesArray = response?.toy?.images;
      const featuredImageObj = toyImagesArray?.shift();
      setToyImages(toyImagesArray);
      setFeaturedImage(featuredImageObj ? [featuredImageObj] : []);
      setToyVideo(
        response?.toy?.videoUrl ? { url: response?.toy?.videoUrl } : [],
      );
      setToyDetail({
        ...response?.toy,
        featuredImages: featuredImageObj ? [featuredImageObj] : [],
      });
      setIsToyLoading(false);
    },
    fetchPolicy: 'network-only',
    onError() {},
  });

  useEffect(() => {
    setIsToyLoading(true);
    setToyDetail([]);
    toy({
      variables: {
        where: {
          id,
        },
      },
    });
  }, []);

  return (
    <>
      <Portal portalId="header-title-content">
        <GoBackButton customLink={ROUTES?.INVENTORY} />
        <div className="portal-header">View Toy</div>
      </Portal>
      <Card className="full-height-with-nav" title="Toy Details">
        {isToyLoading ? (
          <LoaderComponent
            size="large"
            setHeight="60"
            spinning={isToyLoading}
          />
        ) : (
          <div className="toy-form">
            <ToyDetails
              form={form}
              isView
              toyImages={toyImages}
              toyDetail={toyDetail}
              featuredImage={featuredImage}
              toyVideo={toyVideo}
            />
          </div>
        )}
      </Card>
    </>
  );
};

export default ViewToy;
