import { useLazyQuery } from '@apollo/client';
import { Empty, Select } from 'antd';
import { debounce, uniqBy } from 'lodash';
import React, { useEffect, useState } from 'react';
import { LIMIT, SORT } from '../../../../../common/constants';
import SelectComponent from '../../../../../components/SelectComponent';
import { USERS } from '../graphql/Queries';

const { Option } = Select;

const UserSelect = (props) => {
  const { userData, roles, isModalOpen = true, ...rest } = props;
  const [userList, setUserList] = useState([]);
  const [userSearchTerm, setUserSearchTerm] = useState('');
  const [isAllUsersFetched, setIsAllUsersFetched] = useState(false);
  const [isUsersAllowClear, setIsUsersAllowClear] = useState(false);
  const [isUsersDropdownVisible, setIsUsersDropdownVisible] = useState(false);
  const [isFetchMoreUsersLoading, setIsFetchMoreUsersLoading] = useState(false);

  const [users, { loading: isUserLoading }] = useLazyQuery(USERS, {
    onCompleted: (response) => {
      if (response?.users?.data?.length < LIMIT) {
        setIsAllUsersFetched(true);
      }
      setIsFetchMoreUsersLoading(false);
      if (userData) {
        setUserList(
          uniqBy(
            [
              {
                firstName: userData?.firstName,
                lastName: userData?.lastName,
                id: userData?.id,
                userNumber: userData?.userNumber,
              },
              ...userList,
              ...response?.users?.data,
            ],
            'id',
          ),
        );
        return;
      }
      setUserList([...userList, ...response?.users?.data]);
    },
    fetchPolicy: 'network-only',
    onError() {},
  });

  useEffect(() => {
    setUserSearchTerm('');
    setUserList([]);
    if (isModalOpen) {
      users({
        variables: {
          filter: {
            limit: LIMIT,
            skip: 0,
            search: userSearchTerm,
            roles,
          },
          sort: {
            field: 'createdAt',
            order: SORT?.DESC,
          },
        },
      });
    }
    setIsAllUsersFetched(false);
  }, [isUsersDropdownVisible, isModalOpen]);

  const handleUsersScroll = (event) => {
    if (!isAllUsersFetched && !isUserLoading) {
      const target = event?.target;
      const currentLength = userList?.length;
      if (
        target?.scrollTop + target?.offsetHeight >=
        target?.scrollHeight - 5
      ) {
        setIsFetchMoreUsersLoading(true);
        users({
          variables: {
            filter: {
              limit: LIMIT,
              skip: currentLength,
              search: userSearchTerm,
              roles,
            },
            sort: {
              field: 'createdAt',
              order: SORT?.DESC,
            },
          },
        });
      }
    }
  };

  const handleUsersSearch = (searchTerm) => {
    const trimSearch = searchTerm?.trim();
    setIsAllUsersFetched(false);
    setUserList([]);
    setUserSearchTerm(trimSearch);
    users({
      variables: {
        filter: {
          limit: LIMIT,
          skip: 0,
          search: trimSearch,
          roles,
        },
        sort: {
          field: 'createdAt',
          order: SORT?.DESC,
        },
      },
    });
  };

  const handleUsersScrollDebounce = debounce(handleUsersScroll, 500);
  const handleSearchUsersDebounce = debounce(handleUsersSearch, 500);

  useEffect(() => {
    handleUsersScrollDebounce?.cancel();
  }, [handleUsersScrollDebounce]);

  useEffect(() => {
    handleSearchUsersDebounce?.cancel();
  }, [handleSearchUsersDebounce]);

  useEffect(() => {
    if (isUserLoading) {
      setIsUsersAllowClear(false);
    } else {
      setIsUsersAllowClear(true);
    }
  }, [isUserLoading]);

  return (
    <SelectComponent
      showSearch
      loading={
        (isUserLoading && isUsersDropdownVisible) || isFetchMoreUsersLoading
      }
      placeholder="Select User"
      optionFilterProp="children"
      allowClear={isUsersAllowClear}
      onPopupScroll={handleUsersScrollDebounce}
      onSearch={handleSearchUsersDebounce}
      onDropdownVisibleChange={(visible) => setIsUsersDropdownVisible(visible)}
      filterOption={false}
      notFoundContent={
        !isFetchMoreUsersLoading && isUserLoading ? (
          <span>Loading...</span>
        ) : (
          <Empty image={Empty?.PRESENTED_IMAGE_SIMPLE} />
        )
      }
      {...rest}
    >
      {userList?.map((user) => (
        <Option key={user?.id} value={user?.id}>
          {user?.firstName || user?.lastName || user?.userNumber
            ? `${user?.firstName || ''} ${user?.lastName || ''} (${
                user?.userNumber
              })`
            : '-'}
        </Option>
      ))}
    </SelectComponent>
  );
};

export default UserSelect;
