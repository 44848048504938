import { gql } from '@apollo/client';

// eslint-disable-next-line import/prefer-default-export
export const TAGS_ADMIN = gql`
  query TagsAdmin($filter: TagFilter, $sort: TagSort) {
    tagsAdmin(filter: $filter, sort: $sort) {
      count
      data {
        id
        image
        imageUuid
        createdAt
        isArchived
        name
      }
    }
  }
`;

export const GET_TAG_IMAGE_SIGNED_PUT_URL = gql`
  query GetTagImageSignedPutUrl($data: GetTagsImageSignedPutUrlInput!) {
    getTagImageSignedPutUrl(data: $data) {
      fileName
      signedUrl
    }
  }
`;
