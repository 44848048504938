import { useLazyQuery } from '@apollo/client';
import { Card } from 'antd';
import React, { useEffect, useState } from 'react';
import { MODULES } from '../../../common/constants';
import LoaderComponent from '../../../components/LoaderComponent';
import Portal from '../../../components/Portal';
import StatisticsCards from '../components/StatisticsCards';
import { DASHBOARD_ADMIN } from '../graphql/Queries';

const Dashboard = () => {
  const [statistics, setStatistics] = useState({});
  const [isStatisticsLoading, setIsStatisticsLoading] = useState(false);

  const [dashboardAdmin] = useLazyQuery(DASHBOARD_ADMIN, {
    onCompleted: (response) => {
      setStatistics(response?.dashboardAdmin);
      setIsStatisticsLoading(false);
    },
    fetchPolicy: 'network-only',
    onError() {},
  });

  useEffect(() => {
    setIsStatisticsLoading(true);
    dashboardAdmin();
  }, []);

  return (
    <>
      <Portal portalId="header-title-content">
        <div className="portal-header">{MODULES?.DASHBOARD}</div>
      </Portal>
      <Card className="site-statistic-demo-card">
        {isStatisticsLoading ? (
          <LoaderComponent />
        ) : (
          <StatisticsCards statistics={statistics} />
        )}
      </Card>
    </>
  );
};

export default Dashboard;
