import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import Error404 from '../../Error404';
import { ROUTES } from '../../common/constants';
import Bags from './pages/Bags';
import ClosureRequests from './pages/ClosureRequests';
import Damages from './pages/Damages';
import NewToys from './pages/NewToys';
import './raiseRequest.less';

const RaiseRequestWrapper = () => (
  <Switch>
    <Route
      exact
      path={`${ROUTES?.RAISE_REQUEST}`}
      render={() => (
        <Redirect to={`${ROUTES?.RAISE_REQUEST}${ROUTES?.DAMAGES}`} />
      )}
    />
    <Route
      exact
      path={`${ROUTES?.RAISE_REQUEST}${ROUTES?.DAMAGES}`}
      render={(props) => <Damages {...props} />}
    />
    <Route
      exact
      path={`${ROUTES?.RAISE_REQUEST}${ROUTES?.CLOSURE_REQUESTS}`}
      render={(props) => <ClosureRequests {...props} />}
    />
    <Route
      exact
      path={`${ROUTES?.RAISE_REQUEST}${ROUTES?.BAGS}`}
      render={(props) => <Bags {...props} />}
    />
    <Route
      exact
      path={`${ROUTES?.RAISE_REQUEST}${ROUTES?.NEW_TOYS}`}
      render={(props) => <NewToys {...props} />}
    />
    <Route path="*" exact component={Error404} />
  </Switch>
);

export default RaiseRequestWrapper;
