import { PlusOutlined } from '@ant-design/icons';
import { Divider, Form, Input, Upload } from 'antd';
import React, { useEffect, useState } from 'react';
import {
  formValidatorRules,
  getBase64,
  validateImage,
} from '../../../../../common/utils';
import ModalComponent from '../../../../../components/ModalComponent';

const { Item } = Form;
const { required } = formValidatorRules;

const MaterialModal = ({
  isModalOpen,
  setIsModalOpen,
  onFinish,
  loadings,
  form,
  material,
  setMaterial,
  materialsImages,
  setMaterialsImages,
  setVisible,
  setMaterialPreviewImage,
}) => {
  const [isValidateTrigger, setIsValidateTrigger] = useState(false);

  const handleCancel = () => {
    setIsModalOpen(false);
    setMaterial();
    form?.resetFields();
  };

  useEffect(() => {
    form?.setFieldsValue({
      name: material?.name,
      size: material?.size,
      type: material?.type,
    });
  }, [material]);

  const handleImageChange = (info) => {
    validateImage(info, form, setMaterialsImages, 'image');
  };

  const handlePreview = async (fileObj) => {
    let preview;
    if (!fileObj?.url && !fileObj?.preview) {
      preview = await getBase64(fileObj?.originFileObj);
    }
    setVisible(true);
    setMaterialPreviewImage(fileObj?.url || preview);
  };

  return (
    <>
      <ModalComponent
        open={isModalOpen}
        onCancel={handleCancel}
        onOk={form?.submit}
        title={
          material ? 'Edit Packaging Material' : 'Add New Packaging Material'
        }
        okText="Save"
        confirmLoading={loadings}
        destroyOnClose
        forceRender
      >
        <Divider />
        <Form
          form={form}
          onFinish={(values) => {
            onFinish(values);
            setIsValidateTrigger(true);
          }}
          autoComplete="off"
          layout="vertical"
          validateTrigger={isValidateTrigger ? 'onChange' : 'onSubmit'}
          onFinishFailed={() => setIsValidateTrigger(true)}
        >
          <Item
            label="Name"
            name="name"
            rules={[{ ...required, message: 'Please Enter Name!' }]}
          >
            <Input allowClear placeholder="Enter Name" maxLength={50} />
          </Item>
          <Item
            label="Size"
            name="size"
            rules={[{ ...required, message: 'Please Enter Size!' }]}
          >
            <Input allowClear placeholder="Enter Size" maxLength={50} />
          </Item>
          <Item label="Type" name="type">
            <Input allowClear placeholder="Enter Type" maxLength={50} />
          </Item>
          <Item label="Image" name="image">
            <Upload
              listType="picture-card"
              maxCount={1}
              fileList={materialsImages}
              beforeUpload={() => false}
              onPreview={handlePreview}
              onChange={handleImageChange}
              showUploadList={{
                showDownloadIcon: false,
                showPreviewIcon: true,
                showRemoveIcon: true,
              }}
            >
              {materialsImages?.length !== 1 && <PlusOutlined />}
            </Upload>
          </Item>
        </Form>
      </ModalComponent>
    </>
  );
};

export default MaterialModal;
