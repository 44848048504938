import { gql } from '@apollo/client';

// eslint-disable-next-line import/prefer-default-export
export const INVOICES = gql`
  query Invoices($filter: InvoiceFilter, $sort: InvoicesSort) {
    invoices(filter: $filter, sort: $sort) {
      count
      data {
        id
        invoiceNumber
        library {
          id
          name
          libraryNumber
        }
        createdAt
        amount
        status
        attachment
        reason
        updatedAt
        paidOn
      }
    }
  }
`;
