import React, { useContext } from 'react';
import { Route, Switch } from 'react-router-dom';
import AccessControl from '../../../../AccessControl';
import { MENU_ITEMS } from '../../../../app/components/sidebar/Sidebar';
import { AppContext } from '../../../../AppContext';
import { MODULES, PERMISSIONS_KEY, ROUTES } from '../../../../common/constants';
import { getRedirectSubmenuItem } from '../../../../common/utils';
import Error404 from '../../../../Error404';
import RedirectControl from '../../../../RedirectControl';
import Bags from './pages/Bags';
import ClosureRequests from './pages/ClosureRequests';
import Damages from './pages/Damages';
import NewToys from './pages/NewToys';

const tabs = MENU_ITEMS?.find(
  (menuItem) => menuItem?.key === MODULES?.REQUESTS,
)?.subMenu?.find((menu) => menu?.key === MODULES?.LIBRARY)?.tabKeys;

const LibraryWrapper = () => {
  const {
    state: { permissions },
  } = useContext(AppContext);

  const redirectArray = getRedirectSubmenuItem(permissions, tabs);

  return (
    <Switch>
      <Route
        exact
        path={`${ROUTES?.REQUESTS}${ROUTES?.LIBRARY}`}
        render={() => (
          <RedirectControl
            redirectArray={redirectArray}
            route={`${ROUTES?.REQUESTS}${ROUTES?.LIBRARY}`}
          />
        )}
      />
      <Route
        exact
        path={`${ROUTES?.REQUESTS}${ROUTES?.LIBRARY}${ROUTES?.DAMAGES}`}
        render={(props) => (
          <AccessControl
            permissionKey={PERMISSIONS_KEY?.REQUESTS_LIBRARY_DAMAGES}
          >
            <Damages {...props} />
          </AccessControl>
        )}
      />
      <Route
        exact
        path={`${ROUTES?.REQUESTS}${ROUTES?.LIBRARY}${ROUTES?.CLOSURE_REQUESTS}`}
        render={(props) => (
          <AccessControl
            permissionKey={PERMISSIONS_KEY?.REQUESTS_LIBRARY_CLOSURE_REQUESTS}
          >
            <ClosureRequests {...props} />{' '}
          </AccessControl>
        )}
      />
      <Route
        exact
        path={`${ROUTES?.REQUESTS}${ROUTES?.LIBRARY}${ROUTES?.BAGS}`}
        render={(props) => (
          <AccessControl permissionKey={PERMISSIONS_KEY?.REQUESTS_LIBRARY_BAGS}>
            <Bags {...props} />{' '}
          </AccessControl>
        )}
      />
      <Route
        exact
        path={`${ROUTES?.REQUESTS}${ROUTES?.LIBRARY}${ROUTES?.NEW_TOYS}`}
        render={(props) => (
          <AccessControl
            permissionKey={PERMISSIONS_KEY?.REQUESTS_LIBRARY_NEW_TOYS}
          >
            <NewToys {...props} />
          </AccessControl>
        )}
      />
      <Route path="*" exact component={Error404} />
    </Switch>
  );
};

export default LibraryWrapper;
