import React from 'react';
import { Route, Switch } from 'react-router-dom';
import AccessControl from '../../../../AccessControl';
import { PERMISSIONS_KEY, ROUTES } from '../../../../common/constants';
import Unfulfilled from './pages/Unfulfilled';

const UnfulfilledOrderWrapper = () => (
  <Switch>
    <Route
      exact
      path={`${ROUTES?.REPORTS}${ROUTES?.UNFULFILLED_ORDERS}`}
      render={(props) => (
        <AccessControl
          permissionKey={PERMISSIONS_KEY?.REPORTS_UNFULFILLED_ORDERS}
        >
          <Unfulfilled {...props} />
        </AccessControl>
      )}
    />
  </Switch>
);

export default UnfulfilledOrderWrapper;
