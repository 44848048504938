import { gql } from '@apollo/client';

// eslint-disable-next-line import/prefer-default-export
export const EXPORT_JOBS = gql`
  query ExportJobs($sort: ExportJobSort, $filter: ExportJobFilter) {
    exportJobs(sort: $sort, filter: $filter) {
      count
      data {
        fileUrl
        id
        status
        createdAt
        type
        fileUrls
      }
    }
  }
`;
