import { gql } from '@apollo/client';

// eslint-disable-next-line import/prefer-default-export
export const CREATE_STORY = gql`
  mutation createStory($data: CreateStoryInput!) {
    createCollection(data: $data) {
      message
    }
  }
`;

export const UPDATE_STORY = gql`
  mutation updateStory($where: StoryUniqueInput!, $data: UpdateStoryInput) {
    updateStory(where: $where, data: $data) {
      message
    }
  }
`;

export const CREATE_VIDEO_STORY = gql`
mutation CreateStoryVideo($where: StoryUniqueInput!, $data: CreateStoryVideoInput) {
  createStoryVideo(where: $where, data: $data) {
    message
  }
}
`;

export const UPDATE_VIDEO_STORY = gql`
mutation UpdateStoryVideo($where: StoryUniqueInput!, $data: UpdateStoryVideoInput) {
  updateStoryVideo(where: $where, data: $data) {
    message
  }
}
`;

export const REMOVE_VIDEO_STORY = gql`
mutation RemoveStory($where: StoryUniqueInput!) {
  removeStory(where: $where) {
    message
  }
}`
