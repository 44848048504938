import { gql } from '@apollo/client';

// eslint-disable-next-line import/prefer-default-export
export const CREATE_CONTENT_MASTER = gql`
  mutation CreateContentMaster($data: CreateContentMasterInput) {
    createContentMaster(data: $data) {
      message
    }
  }
`;

export const UPDATE_CONTENT_MASTER = gql`
  mutation UpdateContentMaster(
    $where: ContentMasterUniqueInput!
    $data: UpdateContentMasterInput
  ) {
    updateContentMaster(where: $where, data: $data) {
      message
    }
  }
`;
