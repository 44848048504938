import React from 'react';
import {
  FacebookIcon,
  FacebookShareButton,
  LinkedinIcon,
  LinkedinShareButton,
  TelegramIcon,
  TelegramShareButton,
  TwitterShareButton,
  WhatsappIcon,
  WhatsappShareButton,
  XIcon,
} from 'react-share';

import { Button, Divider, Input, Space, Tooltip } from 'antd';
import { handleCopy } from '../../../../../common/utils';
import LoaderComponent from '../../../../../components/LoaderComponent';
import ModalComponent from '../../../../../components/ModalComponent';

const ShareModal = ({
  isModalOpen,
  setIsModalOpen,
  title,
  shareTitle,
  shareUrl,
  isShareLoading,
  setIsShareLoading,
}) => (
  <ModalComponent
    open={isModalOpen}
    onCancel={() => {
      setIsModalOpen(false);
      setIsShareLoading(false);
    }}
    footer={null}
    title={title}
  >
    <Divider />
    {isShareLoading ? (
      <LoaderComponent setHeight={15} />
    ) : (
      <>
        <div className="d-flex justify-around flex-wrap mb-16">
          <WhatsappShareButton
            url={shareUrl}
            title={shareTitle}
            className="m-8"
          >
            <WhatsappIcon size={40} round />
            <div>Whatsapp</div>
          </WhatsappShareButton>

          <FacebookShareButton
            url={shareUrl}
            quote={shareTitle}
            className="m-8"
          >
            <FacebookIcon size={40} round />
            <div>Facebook</div>
          </FacebookShareButton>

          <TelegramShareButton
            url={shareUrl}
            title={shareTitle}
            className="m-8"
          >
            <TelegramIcon size={40} round />
            <div>Telegram</div>
          </TelegramShareButton>

          <LinkedinShareButton
            url={shareUrl}
            title={shareTitle}
            className="m-8"
          >
            <LinkedinIcon size={40} round />
            <div>LinkedIn</div>
          </LinkedinShareButton>

          <TwitterShareButton url={shareUrl} title={shareTitle} className="m-8">
            <XIcon size={40} round />
            <div>Twitter</div>
          </TwitterShareButton>
        </div>
        <Space.Compact className="copy-to-clipboard mb-16">
          <Input defaultValue={shareUrl} disabled />
          <Tooltip
            title="Copied!"
            trigger="focus"
            onClick={(e) => e?.stopPropagation()}
          >
            <Button type="primary" onClick={() => handleCopy(shareUrl)}>
              Copy
            </Button>
          </Tooltip>
        </Space.Compact>
      </>
    )}
  </ModalComponent>
);

export default ShareModal;
