import { Button, Col, Row } from 'antd';
import React from 'react';
import { ROUTES } from '../../../../../common/constants';

const OrderInfo = ({ endUserDetail, userNumber }) => (
  <>
    <Row className="mb-16" gutter={32}>
      <Col xs={12} sm={12} md={12} lg={8} xl={6} xxl={6}>
        <span className="subscription-tab-text">Total orders placed</span>
      </Col>
      <Col xs={12} sm={12} md={12} lg={8} xl={6} xxl={6} className="text-right">
        <span className="subscription-tab-text-value">
          {endUserDetail?.placedOrdersCount}
        </span>
      </Col>
    </Row>
    <Row className="mb-16" gutter={32}>
      <Col xs={12} sm={12} md={12} lg={8} xl={6} xxl={6}>
        <span className="subscription-tab-text">Total orders returned</span>
      </Col>
      <Col xs={12} sm={12} md={12} lg={8} xl={6} xxl={6} className="text-right">
        <span className="subscription-tab-text-value">
          {endUserDetail?.returnedOrdersCount}
        </span>
      </Col>
    </Row>
    <Row span={24}>
      <Button
        type="link"
        className="p-0 link-button"
        onClick={() =>
          // eslint-disable-next-line no-undef
          window?.open(
            `${ROUTES?.ORDERS}${ROUTES?.ALL}${ROUTES?.DELIVERY}?user=${userNumber}`,
            '_blank',
          )
        }
      >
        Click here to view orders
      </Button>
    </Row>
  </>
);

export default OrderInfo;
