import { Empty } from 'antd';
import { isEmpty } from 'lodash';
import React, { useEffect } from 'react';
import LoaderComponent from '../../../../../components/LoaderComponent';
import ModalComponent from '../../../../../components/ModalComponent';
import OrderItemCard from './OrderItemCard';

const AllOrderItemModal = ({
  order,
  setOrder,
  isNewPath,
  isModalOpen,
  setIsModalOpen,
  handleExpand,
  nestedData,
  isLoading,
  setIsNotePrompts,
  setOrderItem,
  setIsOrderAction,
  handleOrderItemChange,
  handleStickerDownload,
  isStickerLoading,
  handleStickerReset,
  isResetStickerLoading,
  setIsContentMasterModalOpen,
  setVisible,
  setToyImages,
}) => {
  useEffect(() => {
    if (!isEmpty(order)) {
      handleExpand(true, order);
    }
  }, [order]);

  const isEmptyOrderItemList = nestedData?.[order?.id]?.orderItems?.count === 0;

  return (
    <ModalComponent
      open={isModalOpen}
      onCancel={() => {
        setOrder({});
        setIsModalOpen(false);
      }}
      title={`${order?.orderNumber} - Order Items`}
      footer={null}
      wrapClassName="full-screen-modal"
    >
      <div className="mobile-full-screen-container no-scroll-bar">
        {!isEmptyOrderItemList &&
          nestedData?.[order?.id]?.orderItems?.data?.map((orderItem) => (
            <OrderItemCard
              key={orderItem?.id}
              order={order}
              isNewPath={isNewPath}
              orderItem={orderItem}
              setIsNotePrompts={setIsNotePrompts}
              setOrderItem={setOrderItem}
              setIsOrderAction={setIsOrderAction}
              handleOrderItemChange={handleOrderItemChange}
              handleStickerDownload={handleStickerDownload}
              isStickerLoading={isStickerLoading}
              handleStickerReset={handleStickerReset}
              isResetStickerLoading={isResetStickerLoading}
              setIsContentMasterModalOpen={setIsContentMasterModalOpen}
              setVisible={setVisible}
              setToyImages={setToyImages}
            />
          ))}
        {isLoading?.[order?.id] && (
          <LoaderComponent size="small" setHeight={10} />
        )}
        {isEmptyOrderItemList && (
          <Empty image={Empty?.PRESENTED_IMAGE_SIMPLE} />
        )}
      </div>
    </ModalComponent>
  );
};

export default AllOrderItemModal;
