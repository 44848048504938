import { CopyOutlined, FilterFilled, FilterOutlined } from '@ant-design/icons';
import { useLazyQuery, useMutation } from '@apollo/client';
import {
  Badge,
  Button,
  Checkbox,
  DatePicker,
  Divider,
  Empty,
  Modal,
  Switch,
  Tag,
  Tooltip,
} from 'antd';
import { filter, forEach, isEmpty, map } from 'lodash';
import React, { useContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { AppContext } from '../../../../../AppContext';
import { ReactComponent as ArchiveIcon } from '../../../../../assets/svg/box-archive-solid.svg';
import { ReactComponent as EditIcon } from '../../../../../assets/svg/pen-to-square-solid.svg';
import { ReactComponent as UnarchiveIcon } from '../../../../../assets/svg/right-from-bracket-solid.svg';
import {
  ARCHIVING_STATE,
  BREAKPOINTS,
  DATE_FORMATS,
  GIFT_CARD_STATUS,
  LIMIT,
  PERMISSIONS_KEY,
  PERMISSION_TYPE,
  PLAN_NAMES,
  PLAN_NAMES_OPTIONS,
  PLAN_TYPES,
  PLAN_TYPES_OPTIONS,
  ROUTES,
  SORT,
} from '../../../../../common/constants';
import {
  formatDate,
  handleCopy,
  hasPermission,
} from '../../../../../common/utils';
import CommonTable from '../../../../../components/CommonTable';
import LoaderComponent from '../../../../../components/LoaderComponent';
import SearchComponent from '../../../../../components/SearchComponent';
import { UPDATE_GIFT_CARD } from '../graphql/Mutations';
import { GIFT_CARDS } from '../graphql/Queries';

const { RangePicker } = DatePicker;

const initialPaginationValue = {
  total: 0,
  current: 1,
  pageSize: 10,
};

const initialFilter = {
  limit: LIMIT,
  search: '',
  skip: 0,
};

const initialSort = {
  field: 'createdAt',
  order: 'DESC',
};

const GiftCardTable = ({ exportFilter, setExportFilter }) => {
  const {
    state: {
      permissions,
      commonPermissions: { isUserPermission },
    },
  } = useContext(AppContext);
  const history = useHistory();
  const [giftCardList, setGiftCardList] = useState([]);
  const [giftCardSearchTerm, setGiftCardSearchTerm] = useState('');
  const [isEmptyGiftCardList, setIsEmptyGiftCardList] = useState(false);
  const [giftCard, setGiftCard] = useState({});
  const [query, setQuery] = useState('');
  const [sortedInfo, setSortedInfo] = useState({});
  const [paginationProp, setPaginationProp] = useState(initialPaginationValue);
  const [isArchivePrompts, setIsArchivePrompts] = useState(false);

  const [isGiftCardLoading, setIsGiftCardLoading] = useState(true);
  const [isArchiveLoading, setIsArchiveLoading] = useState(false);

  const [isArchiveFilter, setIsArchiveFilter] = useState(false);
  const [filterList, setFilterList] = useState([]);
  const [whereFilter, setWhereFilter] = useState();
  const [filterLoading, setFilterLoading] = useState(false);
  const [filterTags, setFilterTags] = useState(null);
  const [filterVisible, setFilterVisible] = useState(false);
  const [filterIndex, setFilterIndex] = useState(null);
  const [finalWhereFilters, setFinalWhereFilters] = useState();
  const [finalFilterTags, setFinalFilterTags] = useState(null);

  const [updateGiftCard] = useMutation(UPDATE_GIFT_CARD, {
    onError: () => {},
  });

  const [giftCards] = useLazyQuery(GIFT_CARDS, {
    onCompleted: (response) => {
      setGiftCardList([...response?.giftCards?.data]);
      if (
        response?.giftCards?.count === 0 &&
        initialPaginationValue?.total === 0
      ) {
        setIsEmptyGiftCardList(true);
      } else {
        setIsEmptyGiftCardList(false);
      }
      const pagination = {
        ...paginationProp,
        defaultPageSize: LIMIT,
        total: response?.giftCards?.count,
      };
      setPaginationProp(pagination);
      setIsGiftCardLoading(false);
    },
    fetchPolicy: 'network-only',
    onError() {},
  });

  const hasUpdatePermission = hasPermission(
    PERMISSIONS_KEY?.OFFERS_GIFT_CARD,
    PERMISSION_TYPE?.UPDATE,
    permissions,
  );

  useEffect(() => {
    setPaginationProp({ ...paginationProp, current: 1, skip: 0 });
    setIsGiftCardLoading(true);
    giftCards({
      variables: {
        filter: {
          ...initialFilter,
          limit: paginationProp?.pageSize || LIMIT,
          search: giftCardSearchTerm,
          isArchived: isArchiveFilter,
          dateRange: {
            from: finalWhereFilters?.createdAt?.from?.$d,
            to: finalWhereFilters?.createdAt?.to?.$d,
          },
          expiredAtDate: {
            from: finalWhereFilters?.expiredAt?.from?.$d,
            to: finalWhereFilters?.expiredAt?.to?.$d,
          },
          startedAtDate: {
            from: finalWhereFilters?.startedAt?.from?.$d,
            to: finalWhereFilters?.startedAt?.to?.$d,
          },
          planTypes:
            finalWhereFilters?.billingCycle?.length > 0
              ? finalWhereFilters?.billingCycle
              : undefined,
          planNames:
            finalWhereFilters?.planName?.length > 0
              ? finalWhereFilters?.planName
              : undefined,
          status:
            finalWhereFilters?.status?.length > 0
              ? finalWhereFilters?.status
              : undefined,
        },
        sort: sortedInfo?.column
          ? {
              field: sortedInfo?.field,
              order: sortedInfo?.order === 'ascend' ? SORT?.ASC : SORT?.DESC,
            }
          : initialSort,
      },
    });
    setExportFilter({ ...exportFilter, isArchived: isArchiveFilter });
  }, [isArchiveFilter]);

  const handleTableChange = (pagination, tableFilter, sorter) => {
    const { current } = pagination;
    const skip = (current - 1) * (pagination?.pageSize || 0);
    setPaginationProp({ ...paginationProp, ...pagination });
    setIsGiftCardLoading(true);
    setSortedInfo(sorter);
    giftCards({
      variables: {
        filter: {
          ...initialFilter,
          skip,
          limit: pagination?.pageSize,
          search: giftCardSearchTerm,
          isArchived: isArchiveFilter,
          dateRange: {
            from: finalWhereFilters?.createdAt?.from?.$d,
            to: finalWhereFilters?.createdAt?.to?.$d,
          },
          expiredAtDate: {
            from: finalWhereFilters?.expiredAt?.from?.$d,
            to: finalWhereFilters?.expiredAt?.to?.$d,
          },
          startedAtDate: {
            from: finalWhereFilters?.startedAt?.from?.$d,
            to: finalWhereFilters?.startedAt?.to?.$d,
          },
          planTypes:
            finalWhereFilters?.billingCycle?.length > 0
              ? finalWhereFilters?.billingCycle
              : undefined,
          planNames:
            finalWhereFilters?.planName?.length > 0
              ? finalWhereFilters?.planName
              : undefined,
          status:
            finalWhereFilters?.status?.length > 0
              ? finalWhereFilters?.status
              : undefined,
        },
        sort: sorter?.column
          ? {
              field: sorter?.field,
              order: sorter?.order === 'ascend' ? SORT?.ASC : SORT?.DESC,
            }
          : initialSort,
      },
    });
  };

  const handleSearch = (value) => {
    const trimValue = value?.trim();
    setGiftCardSearchTerm(trimValue);
    setPaginationProp({ ...paginationProp, current: 1, skip: 0 });
    setIsGiftCardLoading(true);
    giftCards({
      variables: {
        filter: {
          ...initialFilter,
          limit: paginationProp?.pageSize || LIMIT,
          search: trimValue,
          isArchived: isArchiveFilter,
          dateRange: {
            from: finalWhereFilters?.createdAt?.from?.$d,
            to: finalWhereFilters?.createdAt?.to?.$d,
          },
          expiredAtDate: {
            from: finalWhereFilters?.expiredAt?.from?.$d,
            to: finalWhereFilters?.expiredAt?.to?.$d,
          },
          startedAtDate: {
            from: finalWhereFilters?.startedAt?.from?.$d,
            to: finalWhereFilters?.startedAt?.to?.$d,
          },
          planTypes:
            finalWhereFilters?.billingCycle?.length > 0
              ? finalWhereFilters?.billingCycle
              : undefined,
          planNames:
            finalWhereFilters?.planName?.length > 0
              ? finalWhereFilters?.planName
              : undefined,
          status:
            finalWhereFilters?.status?.length > 0
              ? finalWhereFilters?.status
              : undefined,
        },
        sort: sortedInfo?.column
          ? {
              field: sortedInfo?.field,
              order: sortedInfo?.order === 'ascend' ? SORT?.ASC : SORT?.DESC,
            }
          : initialSort,
      },
    });
    setExportFilter({ ...exportFilter, search: trimValue });
  };

  const handleArchive = async (id, isArchived) => {
    setIsArchiveLoading(true);
    const response = await updateGiftCard({
      variables: {
        where: {
          id,
        },
        data: {
          isArchived: !isArchived,
        },
      },
    });
    if (response?.data) {
      setIsArchiveLoading(false);
      setIsGiftCardLoading(true);
      setIsArchivePrompts(false);
      giftCards({
        variables: {
          filter: {
            ...initialFilter,
            skip:
              (paginationProp?.current - 1) * (paginationProp?.pageSize || 0),
            limit: paginationProp?.pageSize || LIMIT,
            search: giftCardSearchTerm,
            isArchived: isArchiveFilter,
            dateRange: {
              from: finalWhereFilters?.createdAt?.from?.$d,
              to: finalWhereFilters?.createdAt?.to?.$d,
            },
            expiredAtDate: {
              from: finalWhereFilters?.expiredAt?.from?.$d,
              to: finalWhereFilters?.expiredAt?.to?.$d,
            },
            startedAtDate: {
              from: finalWhereFilters?.startedAt?.from?.$d,
              to: finalWhereFilters?.startedAt?.to?.$d,
            },
            planTypes:
              finalWhereFilters?.billingCycle?.length > 0
                ? finalWhereFilters?.billingCycle
                : undefined,
            planNames:
              finalWhereFilters?.planName?.length > 0
                ? finalWhereFilters?.planName
                : undefined,
            status:
              finalWhereFilters?.status?.length > 0
                ? finalWhereFilters?.status
                : undefined,
          },
          sort: {
            ...initialSort,
            field: sortedInfo?.field || 'createdAt',
            order: sortedInfo?.order === 'ascend' ? SORT?.ASC : SORT?.DESC,
          },
        },
      });
    }
    setIsArchiveLoading(false);
  };

  const getFilterData = (confirm) => {
    setIsGiftCardLoading(true);
    giftCards({
      variables: {
        filter: {
          ...initialFilter,
          limit: paginationProp?.pageSize || LIMIT,
          search: giftCardSearchTerm,
          isArchived: isArchiveFilter,
          dateRange: {
            from: whereFilter?.createdAt?.from?.$d,
            to: whereFilter?.createdAt?.to?.$d,
          },
          expiredAtDate: {
            from: whereFilter?.expiredAt?.from?.$d,
            to: whereFilter?.expiredAt?.to?.$d,
          },
          startedAtDate: {
            from: whereFilter?.startedAt?.from?.$d,
            to: whereFilter?.startedAt?.to?.$d,
          },
          planTypes:
            whereFilter?.billingCycle?.length > 0
              ? whereFilter?.billingCycle
              : undefined,
          planNames:
            whereFilter?.planName?.length > 0
              ? whereFilter?.planName
              : undefined,
          status:
            whereFilter?.status?.length > 0 ? whereFilter?.status : undefined,
        },
        sort: sortedInfo?.column
          ? {
              field: sortedInfo?.field,
              order: sortedInfo?.order === 'ascend' ? SORT?.ASC : SORT?.DESC,
            }
          : initialSort,
      },
    });
    setFinalWhereFilters({ ...finalWhereFilters, ...whereFilter });
    setFinalFilterTags(filterTags);
    setExportFilter({ ...exportFilter, ...finalWhereFilters, ...whereFilter });
    setPaginationProp({ ...paginationProp, skip: 0, current: 1 });
    if (confirm) {
      confirm();
    }
  };

  const handleReset = (clearFilters, dataIndex, confirm) => {
    const tagsCopy = {
      ...filterTags,
      [dataIndex]: [],
    };
    const whereFiltersCopy = {
      ...whereFilter,
      [dataIndex]: [],
    };
    setFilterVisible(false);
    setFinalFilterTags({ ...filterTags, ...tagsCopy });
    setFinalWhereFilters({ ...finalWhereFilters, ...whereFiltersCopy });
    setIsGiftCardLoading(true);
    setExportFilter({
      ...exportFilter,
      ...finalWhereFilters,
      ...whereFiltersCopy,
    });
    giftCards({
      variables: {
        filter: {
          ...initialFilter,
          limit: paginationProp?.pageSize || LIMIT,
          search: giftCardSearchTerm,
          isArchived: isArchiveFilter,
          dateRange: {
            from: whereFiltersCopy?.createdAt?.from?.$d,
            to: whereFiltersCopy?.createdAt?.to?.$d,
          },
          expiredAtDate: {
            from: whereFiltersCopy?.expiredAt?.from?.$d,
            to: whereFiltersCopy?.expiredAt?.to?.$d,
          },
          startedAtDate: {
            from: whereFiltersCopy?.startedAt?.from?.$d,
            to: whereFiltersCopy?.startedAt?.to?.$d,
          },
          planTypes:
            whereFiltersCopy?.billingCycle?.length > 0
              ? whereFiltersCopy?.billingCycle
              : undefined,
          planNames:
            whereFiltersCopy?.planName?.length > 0
              ? whereFiltersCopy?.planName
              : undefined,
          status:
            whereFiltersCopy?.status?.length > 0
              ? whereFiltersCopy?.status
              : undefined,
        },
        sort: sortedInfo?.column
          ? {
              field: sortedInfo?.field,
              order: sortedInfo?.order === 'ascend' ? SORT?.ASC : SORT?.DESC,
            }
          : initialSort,
      },
    });
    setPaginationProp({ ...paginationProp, skip: 0, current: 1 });
    clearFilters();
    setFilterVisible(false);
    if (confirm) {
      confirm();
    }
  };

  useEffect(() => {
    if (filterVisible) {
      setFilterList([]);
      setFilterLoading(true);
      setFilterTags({ ...filterTags, ...finalFilterTags });
      setWhereFilter({ ...whereFilter, ...finalWhereFilters });
      const optionsCopy = [];

      switch (filterIndex) {
        case 'status':
          forEach(GIFT_CARD_STATUS, (item) => {
            optionsCopy?.push({ label: item?.label, key: item?.value });
          });
          setFilterList(optionsCopy);
          setFilterLoading(false);
          break;

        case 'billingCycle':
          forEach(PLAN_TYPES_OPTIONS, (item) => {
            optionsCopy?.push({ label: item?.label, key: item?.value });
          });
          setFilterList(optionsCopy);
          setFilterLoading(false);
          break;

        case 'planName':
          forEach(PLAN_NAMES_OPTIONS, (item) => {
            optionsCopy?.push({ label: item?.label, key: item?.value });
          });
          setFilterList(optionsCopy);
          setFilterLoading(false);
          break;

        default:
          break;
      }
    }
    if (!filterVisible) {
      setFilterTags(finalFilterTags);
      setWhereFilter(finalWhereFilters);
    }
  }, [filterVisible]);

  const changeFilter = (newFilterObj, dataIndex) => {
    const {
      target: { value },
    } = newFilterObj;

    const filterTagsCopy = { ...filterTags };
    let newTagList;
    if (whereFilter?.[dataIndex]?.includes(value?.key)) {
      newTagList = {
        ...filterTagsCopy,
        [dataIndex]: filter(filterTagsCopy?.[dataIndex], (item) => {
          if (item?.label) {
            return item?.key !== value?.key;
          }
          return item !== value;
        }),
      };
    } else {
      newTagList = {
        ...filterTagsCopy,
        [dataIndex]: filterTagsCopy?.[dataIndex]
          ? [...filterTagsCopy?.[dataIndex], value]
          : [value],
      };
    }

    const updatedWhereFilter = {
      ...whereFilter,
      [dataIndex]: newTagList?.[dataIndex]?.map((item) => item?.key),
    };
    setWhereFilter(updatedWhereFilter);
    setFilterTags(newTagList);
  };

  const handleDeleteFilter = (value, dataIndex) => {
    let newTagList;

    if (whereFilter?.[dataIndex]?.includes(value?.key)) {
      newTagList = {
        ...filterTags,
        [dataIndex]: filter(filterTags?.[dataIndex], (item) => {
          if (item?.key) {
            return item?.key !== value?.key;
          }
          return item !== value;
        }),
      };
    }

    const updatedWhereFilter = {
      ...whereFilter,
      [dataIndex]: newTagList?.[dataIndex]?.map((item) => item?.key),
    };
    setWhereFilter(updatedWhereFilter);
    setFilterTags(newTagList);
  };

  const onRangePickerChange = (values, dataIndex) => {
    const rangeObj = {
      from: values[0]?.startOf('day'),
      to: values?.[1]?.endOf('day'),
    };

    const filtersCopy = {
      ...whereFilter,
      [dataIndex]: rangeObj,
    };
    if (!values?.length) {
      delete whereFilter?.[dataIndex];
    }
    setWhereFilter(filtersCopy);
  };

  const filterPopup = (dataIndex) => ({
    filterDropdown: ({ confirm, clearFilters }) => {
      if (
        dataIndex === 'createdAt' ||
        dataIndex === 'expiredAt' ||
        dataIndex === 'startedAt'
      ) {
        return (
          <div className="custom-filter-dropdown">
            <RangePicker
              allowClear={false}
              format={DATE_FORMATS?.REGULAR}
              onChange={(values) => onRangePickerChange(values, dataIndex)}
              value={
                whereFilter?.[dataIndex]?.from
                  ? [
                      whereFilter?.[dataIndex]?.from,
                      whereFilter?.[dataIndex]?.to,
                    ]
                  : null
              }
            />
            <Divider className="divider-filter" />
            <div className="d-flex justify-center">
              <Button
                size="small"
                className="reset-button"
                id="roles-filter-reset"
                onClick={() => handleReset(clearFilters, dataIndex, confirm)}
              >
                Reset
              </Button>
              <Button
                size="small"
                className="common-button ok-button"
                id="roles-filter-ok"
                type="primary"
                onClick={() => getFilterData(confirm, dataIndex)}
              >
                Ok
              </Button>
            </div>
          </div>
        );
      }

      return (
        <div className="custom-filter-dropdown">
          <LoaderComponent spinning={filterLoading} setHeight={35}>
            {filterTags?.[dataIndex]?.length > 0 && (
              <div className="filter-tags-wrapper">
                {map(filterTags?.[dataIndex], (item) => (
                  <Tag
                    key={item?.key}
                    closable
                    onClose={() => handleDeleteFilter(item, dataIndex)}
                    className="filter-tag"
                  >
                    <span>{item?.label}</span>
                  </Tag>
                ))}
              </div>
            )}
            <div className="filter-checkboxes">
              {filterList?.length > 0 ? (
                map(filterList, (item) => (
                  <div className="single-checkbox-div" key={item?.key}>
                    <Checkbox
                      value={item}
                      checked={whereFilter?.[dataIndex]?.includes(item?.key)}
                      key={item?.key || item}
                      onChange={(e) => changeFilter(e, dataIndex)}
                      className="single-checkbox"
                    >
                      <span>{item?.label}</span>
                    </Checkbox>
                  </div>
                ))
              ) : (
                <Empty image={Empty?.PRESENTED_IMAGE_SIMPLE} />
              )}
            </div>
          </LoaderComponent>
          <Divider className="divider-filter" />
          <div className="d-flex justify-center">
            <Button
              size="small"
              className="reset-button"
              id="roles-filter-reset"
              onClick={() => handleReset(clearFilters, dataIndex, confirm)}
            >
              Reset
            </Button>
            <Button
              size="small"
              className="common-button ok-button"
              id="roles-filter-ok"
              type="primary"
              onClick={() => getFilterData(confirm, dataIndex)}
            >
              Ok
            </Button>
          </div>
        </div>
      );
    },
    filterIcon: () =>
      finalWhereFilters?.[dataIndex]?.length > 0 ||
      !isEmpty(finalWhereFilters?.[dataIndex]) ? (
        <FilterFilled className="filter-icon filter-filled" />
      ) : (
        <FilterOutlined className="filter-icon" />
      ),
    onFilterDropdownOpenChange: (visible) => {
      setFilterIndex(dataIndex);
      setFilterVisible(visible);
    },
  });

  const columns = [
    {
      title: 'CODE',
      dataIndex: 'code',
      key: 'code',
      ellipsis: true,
      width: 250,
      align: 'left',
      // eslint-disable-next-line no-undef
      fixed: window.innerWidth > BREAKPOINTS.desktop ? 'left' : false,
      className: 'max-width-column code-cell',
      onCell: () => ({
        onClick: (e) => e?.stopPropagation(),
      }),
      render: (_, record) => (
        <div>
          <span className="pr-8">{record?.code}</span>
          <CopyOutlined
            className="copy-icon"
            onClick={(e) => {
              e?.stopPropagation();
              handleCopy(record?.code);
            }}
          />
        </div>
      ),
    },
    {
      title: 'START ON',
      dataIndex: 'startedAt',
      key: 'startedAt',
      width: 150,
      ellipsis: true,
      align: 'left',
      className: 'max-width-column',
      render: (_, record) =>
        formatDate(record?.startedAt, DATE_FORMATS?.REGULAR) || '-',
      ...filterPopup('startedAt'),
    },
    {
      title: 'EXPIRE ON',
      dataIndex: 'expiredAt',
      key: 'expiredAt',
      width: 150,
      ellipsis: true,
      align: 'left',
      className: 'max-width-column',
      render: (_, record) =>
        formatDate(record?.expiredAt, DATE_FORMATS?.REGULAR) || '-',
      ...filterPopup('expiredAt'),
    },
    {
      title: 'PRICE',
      dataIndex: 'price',
      key: 'price',
      ellipsis: true,
      width: 150,
      align: 'left',
      className: 'max-width-column',
      render: (_, record) => record?.subscription?.price || '-',
    },
    {
      title: 'SENDER NAME',
      dataIndex: 'senderName',
      key: 'senderName',
      ellipsis: true,
      width: 250,
      align: 'left',
      className: 'max-width-column',
      render: (_, record) => record?.sender?.name || '-',
    },
    {
      title: 'SENDER EMAIL',
      dataIndex: 'senderEmail',
      key: 'senderEmail',
      ellipsis: true,
      width: 250,
      align: 'left',
      className: 'max-width-column',
      render: (_, record) => record?.sender?.email || '-',
    },
    {
      title: 'SENDER CONTACT',
      dataIndex: 'senderContact',
      key: 'senderContact',
      ellipsis: true,
      width: 250,
      align: 'left',
      className: 'max-width-column',
      render: (_, record) => record?.sender?.contactNo || '-',
    },
    {
      title: 'BILLING ADDRESS',
      dataIndex: 'billingAddress',
      key: 'billingAddress',
      ellipsis: true,
      width: 250,
      align: 'left',
      className: 'max-width-column table-tooltip-cell',
      onCell: () => ({
        onClick: (e) => e?.stopPropagation(),
      }),
      render: (_, record) => {
        const line1 = record?.billingAddress?.line1;
        const line2 = record?.billingAddress?.line2;
        const city = record?.billingAddress?.city;
        const state = record?.billingAddress?.state;
        const postalCode = record?.billingAddress?.postalCode;

        return (
          <Tooltip
            title={
              line1 || line2 || city || state || postalCode
                ? `${line1 || ''} ${line2 || ''}, ${city || ''}, ${
                    state || ''
                  }, ${postalCode || ''}`
                : 'No Billing Address'
            }
            placement="topLeft"
            trigger="click"
            className="pointer"
          >
            {line1 || line2 || city || state || postalCode
              ? `${line1 || ''} ${line2 || ''}, ${city || ''}, ${
                  state || ''
                }, ${postalCode || ''}`
              : '-'}
          </Tooltip>
        );
      },
    },
    {
      title: 'RECEIVER NAME',
      dataIndex: 'receiverName',
      key: 'receiverName',
      ellipsis: true,
      width: 250,
      align: 'left',
      className: 'max-width-column',
      render: (_, record) => record?.shippingAddress?.name || '-',
    },
    {
      title: 'RECEIVER CONTACT',
      dataIndex: 'receiverContact',
      key: 'receiverContact',
      ellipsis: true,
      width: 250,
      align: 'left',
      className: 'max-width-column',
      render: (_, record) => record?.shippingAddress?.phone || '-',
    },
    {
      title: 'SHIPPING ADDRESS',
      dataIndex: 'shippingAddress',
      key: 'shippingAddress',
      ellipsis: true,
      width: 250,
      align: 'left',
      className: 'max-width-column table-tooltip-cell',
      onCell: () => ({
        onClick: (e) => e?.stopPropagation(),
      }),
      render: (_, record) => {
        const line1 = record?.shippingAddress?.line1;
        const line2 = record?.shippingAddress?.line2;
        const city = record?.shippingAddress?.city;
        const state = record?.shippingAddress?.state;
        const postalCode = record?.shippingAddress?.postalCode;

        return (
          <Tooltip
            title={
              line1 || line2 || city || state || postalCode
                ? `${line1 || ''} ${line2 || ''}, ${city || ''}, ${
                    state || ''
                  }, ${postalCode || ''}`
                : 'No Shipping Address'
            }
            placement="topLeft"
            trigger="click"
            className="pointer"
          >
            {line1 || line2 || city || state || postalCode
              ? `${line1 || ''} ${line2 || ''}, ${city || ''}, ${
                  state || ''
                }, ${postalCode || ''}`
              : '-'}
          </Tooltip>
        );
      },
    },
    {
      title: 'PLAN NAME',
      dataIndex: 'planName',
      key: 'planName',
      ellipsis: true,
      width: 150,
      align: 'left',
      className: 'max-width-column',
      render: (_, record) => PLAN_NAMES?.[record?.subscription?.name]?.key,
      ...filterPopup('planName'),
    },
    {
      title: 'BILLING CYCLE',
      dataIndex: 'billingCycle',
      key: 'billingCycle',
      ellipsis: true,
      width: 150,
      align: 'left',
      className: 'max-width-column',
      render: (_, record) => PLAN_TYPES?.[record?.subscription?.type]?.key,
      ...filterPopup('billingCycle'),
    },
    {
      title: 'USED BY',
      dataIndex: 'name',
      key: 'name',
      ellipsis: true,
      width: 250,
      align: 'left',
      className: `max-width-column`,
      onCell: (record) => ({
        onClick: (e) => {
          e?.stopPropagation();
          if (isUserPermission && record?.user?.id) {
            history?.push(
              `${ROUTES?.SYSTEM_USERS}${ROUTES?.END_USERS}${ROUTES?.BASIC_DETAILS}/view/${record?.user?.id}/${record?.user?.userNumber}`,
            );
          }
        },
        className: `${
          isUserPermission && record?.user?.id && 'pointer table-cell'
        }`,
      }),
      render: (_, record) => {
        const userData = record?.user;
        return (
          <>
            {userData?.firstName || userData?.lastName ? (
              <div className="table-data-with-id">{`${
                userData?.firstName ? userData?.firstName : ''
              } ${userData?.lastName ? userData?.lastName : ''}`}</div>
            ) : (
              '-'
            )}
            {userData?.userNumber ? (
              <div className="font-size-12">({userData?.userNumber})</div>
            ) : (
              ''
            )}
          </>
        );
      },
    },
    {
      title: 'CREATED AT',
      dataIndex: 'createdAt',
      key: 'createdAt',
      width: 150,
      ellipsis: true,
      align: 'left',
      className: 'max-width-column',
      render: (_, record) =>
        formatDate(record?.createdAt, DATE_FORMATS?.REGULAR) || '-',
      ...filterPopup('createdAt'),
    },
    {
      title: 'STATUS',
      dataIndex: 'status',
      key: 'status',
      ellipsis: true,
      width: 150,
      align: 'left',
      className: 'max-width-column',
      render: (_, record) => (
        <Badge
          color={GIFT_CARD_STATUS?.[record?.status]?.color}
          text={GIFT_CARD_STATUS?.[record?.status]?.label}
        />
      ),
      ...filterPopup('status'),
    },
    {
      title: 'ACTIONS',
      dataIndex: 'actions',
      key: 'actions',
      ellipsis: true,
      width: 150,
      className: 'cursor-auto',
      onCell: () => ({
        onClick: (e) => e?.stopPropagation(),
      }),
      // eslint-disable-next-line no-undef
      fixed: window.innerWidth > BREAKPOINTS.desktop ? 'right' : false,
      render: (_, record) => (
        <>
          <div className="action-button">
            {isArchiveFilter ? (
              <Tooltip title="Unarchive" placement="top" zIndex={4}>
                <UnarchiveIcon
                  onClick={(e) => {
                    e?.stopPropagation();
                    setIsArchivePrompts(true);
                    setGiftCard(record);
                  }}
                  className="mr-16 pointer svg-icon"
                />
              </Tooltip>
            ) : (
              <>
                <div className="mr-16 d-flex">
                  <Tooltip
                    title="Edit"
                    placement="top"
                    zIndex={4}
                    className="table-action-button"
                  >
                    <Button
                      disabled={
                        record?.status === GIFT_CARD_STATUS?.EXPIRED?.value
                      }
                      type="link"
                      onClick={(e) => {
                        e?.stopPropagation();
                        history?.push(
                          `${ROUTES?.OFFERS}${ROUTES?.GIFT_CARD}/edit/${record?.id}`,
                        );
                      }}
                    >
                      <EditIcon
                        className={
                          record?.status === GIFT_CARD_STATUS?.EXPIRED?.value
                            ? 'svg-icon-grey'
                            : 'svg-icon'
                        }
                      />
                    </Button>
                  </Tooltip>
                </div>
                <Tooltip title="Archive" placement="top" zIndex={4}>
                  <ArchiveIcon
                    onClick={(e) => {
                      e?.stopPropagation();
                      setIsArchivePrompts(true);
                      setGiftCard(record);
                    }}
                    className="mr-16 pointer svg-icon"
                  />
                </Tooltip>
              </>
            )}
          </div>
        </>
      ),
    },
  ]?.filter((item) => {
    if (item?.dataIndex === 'actions' && !hasUpdatePermission) {
      return item?.hidden;
    }
    return !item?.hidden;
  });

  const locale = {
    emptyText: isEmptyGiftCardList ? '' : <span />,
  };

  return (
    <>
      <Modal
        title="Caution"
        okText="Yes"
        cancelText="No"
        open={isArchivePrompts}
        onOk={() => handleArchive(giftCard?.id, giftCard?.isArchived)}
        onCancel={() => setIsArchivePrompts(false)}
        okButtonProps={{ loading: isArchiveLoading }}
      >
        Are you sure you want to
        <strong>
          {' '}
          {!giftCard?.isArchived
            ? ARCHIVING_STATE?.ARCHIVED
            : ARCHIVING_STATE?.UNARCHIVED}{' '}
        </strong>
        this gift card?
      </Modal>
      <div className="d-flex justify-end mb-16 flex-wrap">
        {hasUpdatePermission && (
          <div className="mr-16 d-flex align-center">
            <h4 className="m-0 mr-8">Only Archived</h4>{' '}
            <Switch
              size="small"
              onChange={(checked) => setIsArchiveFilter(checked)}
            />
          </div>
        )}
        <SearchComponent
          className="search-box"
          query={query}
          setQuery={setQuery}
          getData={handleSearch}
        />
      </div>
      <CommonTable
        locale={locale}
        columns={columns}
        data={giftCardList || []}
        loading={isGiftCardLoading}
        onChange={handleTableChange}
        paginationConfig={paginationProp}
        rowKey={(record) => record?.id}
        rowClassName="pointer table-cell"
        onRow={(record) => ({
          onClick: () => {
            history?.push(
              `${ROUTES?.OFFERS}${ROUTES?.GIFT_CARD}/view/${record?.id}`,
            );
          },
        })}
      />
    </>
  );
};

export default GiftCardTable;
