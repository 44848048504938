import { Empty } from 'antd';
import React from 'react';
import LoaderComponent from '../../../components/LoaderComponent';
import NotificationCard from './NotificationCard';

const NotificationList = ({
  notificationList,
  infiniteScrollRef,
  isFetchMoreNotificationLoading,
  setIsMarkAsReadPrompts,
  setNotification,
  isEmptyNotificationList,
  isNotificationLoading,
  isAlreadyReadFilter,
}) => (
  <div>
    {!isEmptyNotificationList &&
      notificationList?.map((notification) => (
        <NotificationCard
          key={notification?.id}
          notification={notification}
          infiniteScrollRef={infiniteScrollRef}
          setIsMarkAsReadPrompts={setIsMarkAsReadPrompts}
          setNotification={setNotification}
          isAlreadyReadFilter={isAlreadyReadFilter}
        />
      ))}
    {(isFetchMoreNotificationLoading || isNotificationLoading) && (
      <LoaderComponent size="small" setHeight={10} />
    )}
    {isEmptyNotificationList && <Empty image={Empty?.PRESENTED_IMAGE_SIMPLE} />}
  </div>
);

export default NotificationList;
