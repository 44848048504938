import { gql } from '@apollo/client';

// eslint-disable-next-line import/prefer-default-export
export const CREATE_USER = gql`
  mutation CreateUser($data: CreateUserInput!) {
    createUser(data: $data) {
      message
    }
  }
`;

// eslint-disable-next-line import/prefer-default-export
export const UPDATE_USER = gql`
  mutation UpdateUser($where: UserUniqueInput!, $data: UserUpdateInput!) {
    updateUser(where: $where, data: $data) {
      message
    }
  }
`;

// eslint-disable-next-line import/prefer-default-export
export const REQUEST_USER_EXPORT = gql`
  mutation RequestUserExport(
    $data: RequestExportInput!
    $filter: RequestUserExportFilterInput
  ) {
    requestUserExport(data: $data, filter: $filter) {
      message
    }
  }
`;
