import { useLazyQuery, useMutation } from '@apollo/client';
import { Form, Tooltip } from 'antd';
import React, { useContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { AppContext } from '../../../../AppContext';
import { ReactComponent as EditIcon } from '../../../../assets/svg/pen-to-square-solid.svg';
import {
  BREAKPOINTS,
  LIMIT,
  PERMISSIONS_KEY,
  PERMISSION_TYPE,
  ROUTES,
  SORT,
} from '../../../../common/constants';
import {
  hasPermission,
} from '../../../../common/utils';
import CommonTable from '../../../../components/CommonTable';
import SearchComponent from '../../../../components/SearchComponent';
import { CREATE_STORY, UPDATE_STORY } from '../graphql/Mutations';
import { STORY_ADMIN } from '../graphql/Queries';
import CollectionsModal from './StoryModal';

const initialPaginationValue = {
  total: 0,
  current: 1,
  pageSize: 10,
};

const initialFilter = {
  limit: LIMIT,
  search: '',
  skip: 0,
};

const initialSort = {
  field: 'createdAt',
  order: 'ASC',
};

const StoryTable = ({
  isStoryModalOpen,
  setIsStoryModalOpen,
}) => {
  const {
    state: { permissions },
  } = useContext(AppContext);
  const history = useHistory();
  const [form] = Form.useForm();
  const [collectionsList, setCollectionsList] = useState([]);
  const [collectionsSearchTerm, setCollectionsSearchTerm] = useState('');
  const [isEmptyCollectionsList, setIsEmptyCollectionsList] = useState(false);
  const [collections, setCollections] = useState();
  const [query, setQuery] = useState('');
  const [sortedInfo, setSortedInfo] = useState({});
  const [paginationProp, setPaginationProp] = useState(initialPaginationValue);
  const [isCollectionsLoading, setIsCollectionsLoading] = useState(true);
  const [isBtnLoading, setIsBtnLoading] = useState(false);
  const [toySelectValue, setToySelectValue] = useState([]);
  const [createStory] = useMutation(CREATE_STORY, {
    onError: () => { },
  });

  const [updateStory] = useMutation(UPDATE_STORY, {
    onError: () => { },
  });



  const [storyAdmin] = useLazyQuery(STORY_ADMIN, {
    onCompleted: (response) => {
      setCollectionsList(
        [...response?.storyAdmin?.data]?.filter(
          (item) => item?.minAge !== 0 || item?.maxAge !== 0,
        ),
      );
      if (
        response?.storyAdmin?.count === 0 &&
        initialPaginationValue?.total === 0
      ) {
        setIsEmptyCollectionsList(true);
      } else {
        setIsEmptyCollectionsList(false);
      }
      const pagination = {
        ...paginationProp,
        defaultPageSize: LIMIT,
        total: response?.storyAdmin?.count,
      };
      setPaginationProp(pagination);
      setIsCollectionsLoading(false);
    },
    fetchPolicy: 'network-only',
    onError() { },
  });

  const hasUpdatePermission = hasPermission(
    PERMISSIONS_KEY?.APP_CONTENT_STORY,
    PERMISSION_TYPE?.UPDATE,
    permissions,
  );

  useEffect(() => {
    setPaginationProp({ ...paginationProp, current: 1, skip: 0 });
    setIsCollectionsLoading(true);
    storyAdmin({
      variables: {
        filter: {
          ...initialFilter,
          limit: paginationProp?.pageSize || LIMIT,
          search: collectionsSearchTerm,
        },
        sort: sortedInfo?.column
          ? {
            field: sortedInfo?.field,
            order: sortedInfo?.order === 'ascend' ? SORT?.ASC : SORT?.DESC,
          }
          : initialSort,
      },
    });
  }, []);

  const handleTableChange = (pagination, tableFilter, sorter) => {
    const { current } = pagination;
    const skip = (current - 1) * (pagination?.pageSize || 0);
    setPaginationProp({ ...paginationProp, ...pagination });
    setIsCollectionsLoading(true);
    setSortedInfo(sorter);
    storyAdmin({
      variables: {
        filter: {
          ...initialFilter,
          skip,
          limit: pagination?.pageSize,
          search: collectionsSearchTerm,
        },
        sort: sorter?.column
          ? {
            field: sorter?.field,
            order: sorter?.order === 'ascend' ? SORT?.ASC : SORT?.DESC,
          }
          : initialSort,
      },
    });
  };

  const handleSearch = (value) => {
    const trimValue = value?.trim();
    setCollectionsSearchTerm(trimValue);
    setPaginationProp({ ...paginationProp, current: 1, skip: 0 });
    setIsCollectionsLoading(true);
    storyAdmin({
      variables: {
        filter: {
          ...initialFilter,
          limit: paginationProp?.pageSize || LIMIT,
          search: trimValue,
        },
        sort: sortedInfo?.column
          ? {
            field: sortedInfo?.field,
            order: sortedInfo?.order === 'ascend' ? SORT?.ASC : SORT?.DESC,
          }
          : initialSort,
      },
    });
  };

  const handleCollection = async (values) => {
    setIsBtnLoading(true);
    let response;
    try {
      if (!collections) {
        response = await createStory({
          variables: {
            data: {
              name: values?.name,
              description: values?.description,
            },
          },
        });
      } else {
        response = await updateStory({
          variables: {
            where: {
              id: collections?.id,
            },
            data: {
              name: values?.name,
              description: values?.description,
            },
          },
        });
      }
    } catch (error) {
      setIsBtnLoading(false);
      return error;
    }
    if (response?.data) {
      setIsStoryModalOpen(false);
      setIsBtnLoading(true);
      setCollections();
      form?.resetFields();
      storyAdmin({
        variables: {
          filter: {
            ...initialFilter,
            skip:
              (paginationProp?.current - 1) * (paginationProp?.pageSize || 0),
            limit: paginationProp?.pageSize || LIMIT,
            search: collectionsSearchTerm,
          },
          sort: sortedInfo?.column
            ? {
              field: sortedInfo?.field,
              order: sortedInfo?.order === 'ascend' ? SORT?.ASC : SORT?.DESC,
            }
            : initialSort,
        },
      });
    }
    setIsBtnLoading(false);
  };

  const columns = [
    {
      title: 'NAME',
      dataIndex: 'name',
      key: 'name',
      ellipsis: true,
      width: 250,
      align: 'left',
      // eslint-disable-next-line no-undef
      fixed: window.innerWidth > BREAKPOINTS.desktop ? 'left' : false,
      className: 'max-width-column',
      render: (_, record) => record?.name?.replace(/\s/g, '\u00a0'),
    },
    {
      title: 'DESCRIPTION',
      dataIndex: 'description',
      key: 'description',
      ellipsis: true,
      width: 250,
      align: 'left',
      className: 'max-width-column',
      render: (_, record) => record?.description?.replace(/\s/g, '\u00a0'),
    },
    {
      title: 'TYPE',
      dataIndex: 'type',
      key: 'type',
      ellipsis: true,
      width: 250,
      align: 'left',
      className: 'max-width-column',
      render: (_, record) => record?.type?.replace(/\s/g, '\u00a0'),
    },
    {
      title: 'ACTIONS',
      dataIndex: 'actions',
      key: 'actions',
      ellipsis: true,
      width: 150,
      // eslint-disable-next-line no-undef
      fixed: window.innerWidth > BREAKPOINTS.desktop ? 'right' : false,
      className: 'cursor-auto',
      onCell: () => ({
        onClick: (e) => e?.stopPropagation(),
      }),
      render: (_, record) => (
        <>
          <Tooltip title="Edit" placement="top" zIndex={4}>
            <EditIcon
              onClick={() => {
                setIsStoryModalOpen(true);
                setCollections(record);

              }}
              className="mr-16 pointer svg-icon"
            />
          </Tooltip>
        </>
      ),
    },
  ]?.filter((item) => {
    if (item?.dataIndex === 'actions' && !hasUpdatePermission) {
      return item?.hidden;
    }
    return !item?.hidden;
  });

  const locale = {
    emptyText: isEmptyCollectionsList ? '' : <span />,
  };

  return (
    <>
      <CollectionsModal
        form={form}
        onFinish={handleCollection}
        loadings={isBtnLoading}
        isModalOpen={isStoryModalOpen}
        setIsModalOpen={setIsStoryModalOpen}
        collections={collections}
        setCollections={setCollections}
        toySelectValue={toySelectValue}
        setToySelectValue={setToySelectValue}
      />
      <div className="d-flex justify-end mb-16 flex-wrap">
        <SearchComponent
          className="search-box"
          query={query}
          setQuery={setQuery}
          getData={handleSearch}
        />
      </div>
      <CommonTable
        locale={locale}
        columns={columns}
        data={collectionsList || []}
        loading={isCollectionsLoading}
        onChange={handleTableChange}
        paginationConfig={paginationProp}
        rowKey={(record) => record?.id}
        onRow={(record) => ({
          onClick: () => {
            history?.push(
              `${ROUTES?.APP_CONTENT}${ROUTES?.STORY}/view/${record?.id}`,
            );
          },
        })}
      />
    </>
  );
};

export default StoryTable;
