import React from 'react';
import { Route, Switch } from 'react-router-dom';
import AccessControl from '../../../../AccessControl';
import {
  PERMISSIONS_KEY,
  PERMISSION_TYPE,
  ROUTES,
} from '../../../../common/constants';
import Error404 from '../../../../Error404';
import './endUsers.less';
import EditEndUser from './pages/EditEndUser';
import EndUsers from './pages/EndUsers';
import OrderDetails from './pages/OrderDetails';
import SubscriptionDetails from './pages/SubscriptionDetails';
import SubscriptionHistoryDetails from './pages/SubscriptionHistoryDetails';
import TransactionDetails from './pages/TransactionDetails';
import ViewEndUser from './pages/ViewEndUser';
import ViewOrderDetails from './pages/ViewOrderDetails';
import ViewSubscriptionDetails from './pages/ViewSubscriptionDetails';
import ViewSubscriptionHistoryDetails from './pages/ViewSubscriptionHistoryDetails';
import ViewTransactionDetails from './pages/ViewTransactionDetails';

const EndUsersWrapper = () => (
  <Switch>
    <Route
      exact
      path={`${ROUTES?.SYSTEM_USERS}${ROUTES?.END_USERS}`}
      render={(props) => (
        <AccessControl permissionKey={PERMISSIONS_KEY?.SYSTEM_USERS_END_USERS}>
          <EndUsers {...props} />
        </AccessControl>
      )}
    />
    <Route
      exact
      path={`${ROUTES?.SYSTEM_USERS}${ROUTES?.END_USERS}${ROUTES?.BASIC_DETAILS}/edit/:id/:userNumber`}
      render={(props) => (
        <AccessControl
          permissionType={PERMISSION_TYPE?.UPDATE}
          permissionKey={PERMISSIONS_KEY?.SYSTEM_USERS_END_USERS}
        >
          <EditEndUser {...props} />
        </AccessControl>
      )}
    />
    <Route
      exact
      path={`${ROUTES?.SYSTEM_USERS}${ROUTES?.END_USERS}${ROUTES?.BASIC_DETAILS}/view/:id/:userNumber`}
      render={(props) => (
        <AccessControl permissionKey={PERMISSIONS_KEY?.SYSTEM_USERS_END_USERS}>
          <ViewEndUser {...props} />
        </AccessControl>
      )}
    />
    <Route
      exact
      path={`${ROUTES?.SYSTEM_USERS}${ROUTES?.END_USERS}${ROUTES?.SUBSCRIPTION_PLAN_DETAILS}/edit/:id/:userNumber`}
      render={(props) => (
        <AccessControl permissionKey={PERMISSIONS_KEY?.SYSTEM_USERS_END_USERS}>
          <SubscriptionDetails {...props} />
        </AccessControl>
      )}
    />
    <Route
      exact
      path={`${ROUTES?.SYSTEM_USERS}${ROUTES?.END_USERS}${ROUTES?.SUBSCRIPTION_PLAN_DETAILS}/view/:id/:userNumber`}
      render={(props) => (
        <AccessControl permissionKey={PERMISSIONS_KEY?.SYSTEM_USERS_END_USERS}>
          <ViewSubscriptionDetails {...props} />
        </AccessControl>
      )}
    />
    <Route
      exact
      path={`${ROUTES?.SYSTEM_USERS}${ROUTES?.END_USERS}${ROUTES?.ORDER_DETAILS}/edit/:id/:userNumber`}
      render={(props) => (
        <AccessControl permissionKey={PERMISSIONS_KEY?.SYSTEM_USERS_END_USERS}>
          <OrderDetails {...props} />
        </AccessControl>
      )}
    />
    <Route
      exact
      path={`${ROUTES?.SYSTEM_USERS}${ROUTES?.END_USERS}${ROUTES?.ORDER_DETAILS}/view/:id/:userNumber`}
      render={(props) => (
        <AccessControl permissionKey={PERMISSIONS_KEY?.SYSTEM_USERS_END_USERS}>
          <ViewOrderDetails {...props} />
        </AccessControl>
      )}
    />
    <Route
      exact
      path={`${ROUTES?.SYSTEM_USERS}${ROUTES?.END_USERS}${ROUTES?.TRANSACTION_DETAILS}/edit/:id/:userNumber`}
      render={(props) => (
        <AccessControl permissionKey={PERMISSIONS_KEY?.SYSTEM_USERS_END_USERS}>
          <TransactionDetails {...props} />
        </AccessControl>
      )}
    />
    <Route
      exact
      path={`${ROUTES?.SYSTEM_USERS}${ROUTES?.END_USERS}${ROUTES?.TRANSACTION_DETAILS}/view/:id/:userNumber`}
      render={(props) => (
        <AccessControl permissionKey={PERMISSIONS_KEY?.SYSTEM_USERS_END_USERS}>
          <ViewTransactionDetails {...props} />
        </AccessControl>
      )}
    />
    <Route
      exact
      path={`${ROUTES?.SYSTEM_USERS}${ROUTES?.END_USERS}${ROUTES?.SUBSCRIPTION_HISTORY}/edit/:id/:userNumber`}
      render={(props) => (
        <AccessControl permissionKey={PERMISSIONS_KEY?.SYSTEM_USERS_END_USERS}>
          <SubscriptionHistoryDetails {...props} />
        </AccessControl>
      )}
    />
    <Route
      exact
      path={`${ROUTES?.SYSTEM_USERS}${ROUTES?.END_USERS}${ROUTES?.SUBSCRIPTION_HISTORY}/view/:id/:userNumber`}
      render={(props) => (
        <AccessControl permissionKey={PERMISSIONS_KEY?.SYSTEM_USERS_END_USERS}>
          <ViewSubscriptionHistoryDetails {...props} />
        </AccessControl>
      )}
    />
    <Route path="*" exact component={Error404} />
  </Switch>
);

export default EndUsersWrapper;
