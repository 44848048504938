import { Card, Dropdown, Tag } from 'antd';
import React from 'react';
import { ReactComponent as MoreIcon } from '../../../assets/svg/ellipsis-vertical-solid.svg';
import {
  ACTIONS,
  DATE_FORMATS,
  NOTIFICATION_STATUS,
} from '../../../common/constants';
import { formatDate } from '../../../common/utils';

const NotificationCard = ({
  notification,
  infiniteScrollRef,
  setIsMarkAsReadPrompts,
  setNotification,
  isAlreadyReadFilter,
}) => {
  const items = [
    {
      label: ACTIONS?.MARK_AS_READ?.label,
      key: ACTIONS?.MARK_AS_READ?.value,
      disabled:
        Array?.isArray(notification?.readBy) &&
        notification?.readBy?.length > 0,
    },
  ];

  const handleMenuClick = () => {
    setIsMarkAsReadPrompts(true);
    setNotification(notification);
  };

  const menuProps = {
    items,
    onClick: handleMenuClick,
  };

  return (
    <Card
      className="ml-12 mr-12 mb-24 mt-12 mobile-card"
      title={notification?.title}
      extra={
        <div className="d-flex align-center">
          <Tag>
            {notification?.readBy?.length > 0
              ? NOTIFICATION_STATUS?.READ
              : NOTIFICATION_STATUS?.UNREAD}
          </Tag>
          {!isAlreadyReadFilter && (
            <Dropdown
              getPopupContainer={(trigger) => trigger?.parentNode}
              menu={menuProps}
            >
              <MoreIcon className="pointer mobile-svg-icon ml-16" />
            </Dropdown>
          )}
        </div>
      }
      ref={infiniteScrollRef}
    >
      <div className="mb-8">
        <span className="mobile-card-label">Description: </span>
        <span>{notification?.description || 'No Description'}</span>
      </div>
      <div className="mb-8">
        <span className="mobile-card-label">Received On: </span>
        <span>
          {formatDate(notification?.createdAt, DATE_FORMATS?.REGULAR) ||
            'No Received On'}
        </span>
      </div>
    </Card>
  );
};

export default NotificationCard;
