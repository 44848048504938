import React from 'react';
import { Route, Switch } from 'react-router-dom';
import { ROUTES } from '../../../../common/constants';
import Referral from './pages/Referral';
import './referral.less';

const ReferralWrapper = () => (
  <Switch>
    <Route
      exact
      path={`${ROUTES?.OFFERS}${ROUTES?.REFERRAL}`}
      render={Referral}
    />
  </Switch>
);

export default ReferralWrapper;
