import { useLazyQuery, useMutation } from '@apollo/client';
import { Button, Card, Form } from 'antd';
import React, { useContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { AppContext } from '../../../../../AppContext';
import { DATE_FORMATS, ROUTES } from '../../../../../common/constants';
import { formatDate } from '../../../../../common/utils';
import GoBackButton from '../../../../../components/GoBackButton';
import LoaderComponent from '../../../../../components/LoaderComponent';
import Portal from '../../../../../components/Portal';
import { UPDATE_USER } from '../../../graphql/Mutations';
import { USER } from '../../../graphql/Queries';
import DeliveryForm from '../components/DeliveryForm';

const EditDelivery = (props) => {
  const { match: { params: { id } = {} } = {} } = props;

  const history = useHistory();
  const [form] = Form.useForm();
  const { dispatch } = useContext(AppContext);
  const [deliveryDetail, setDeliveryDetail] = useState({});
  const [isDisabled, setIsDisabled] = useState(true);
  const [isSubmitLoading, setIsSubmitLoading] = useState(false);
  const [isDeliveryLoading, setIsDeliveryLoading] = useState(true);

  const [updateUser] = useMutation(UPDATE_USER, {
    onError: () => {},
  });

  const [users] = useLazyQuery(USER, {
    onCompleted: (response) => {
      setDeliveryDetail(response?.user);
      setIsDeliveryLoading(false);
    },
    fetchPolicy: 'network-only',
    onError() {},
  });

  useEffect(() => {
    setIsDeliveryLoading(true);
    setDeliveryDetail([]);
    users({
      variables: {
        where: {
          id,
        },
      },
    });
  }, []);

  const updateDeliveryFun = async (deliveryDetails) => {
    setIsSubmitLoading(true);
    dispatch({
      type: 'SET_SHOW_PROMPT',
      data: false,
    });
    const response = await updateUser({
      variables: {
        where: {
          id,
        },
        data: {
          firstName: deliveryDetails?.firstName?.trim(),
          lastName: deliveryDetails?.lastName?.trim(),
          gender: deliveryDetails?.gender,
          city: deliveryDetails?.city,
          state: deliveryDetails?.state,
          contactNo: deliveryDetails?.contact?.trim(),
          address: deliveryDetails?.address?.trim(),
          email: deliveryDetails?.email?.trim(),
          pincode: deliveryDetails?.pincode,
          agencyName: deliveryDetails?.agencyName?.trim(),
          vehicleNumber: deliveryDetails?.vehicleNumber?.trim(),
        },
      },
    });
    if (response?.data) {
      history?.replace(`${ROUTES?.SYSTEM_USERS}${ROUTES?.DELIVERY_PARTNERS}`);
    }
    setIsSubmitLoading(false);
  };

  const handleShowPrompt = () => {
    setIsDisabled(false);
    dispatch({
      type: 'SET_SHOW_PROMPT',
      data: true,
    });
  };

  const initialValues = {
    ...deliveryDetail,
    contact: deliveryDetail?.contactNo,
    status: deliveryDetail?.isActive,
    state: deliveryDetail?.state,
    city: deliveryDetail?.city,
    pincode: deliveryDetail?.pincode?.pincode,
  };

  return (
    <>
      <Portal portalId="header-title-content">
        <GoBackButton
          customLink={`${ROUTES?.SYSTEM_USERS}${ROUTES?.DELIVERY_PARTNERS}`}
        />
        <div className="portal-header">Edit Delivery Partner</div>
      </Portal>
      <Portal portalId="header-right-content">
        <div className="header-btn-wrapper">
          <Button
            type="primary"
            className="common-button mr-16"
            size="middle"
            onClick={() =>
              history?.push(
                `${ROUTES?.SYSTEM_USERS}${ROUTES?.DELIVERY_PARTNERS}`,
              )
            }
          >
            Discard Changes
          </Button>
          <Button
            className="common-button"
            size="middle"
            onClick={form.submit}
            loading={isSubmitLoading}
            disabled={isDisabled}
          >
            Save
          </Button>
        </div>
      </Portal>
      <Portal portalId="header-right-content-phones">
        <div className="header-btn-wrapper">
          <Button
            type="primary"
            className="common-button mr-16"
            size="middle"
            onClick={() =>
              history?.push(
                `${ROUTES?.SYSTEM_USERS}${ROUTES?.DELIVERY_PARTNERS}`,
              )
            }
          >
            Discard Changes
          </Button>
          <Button
            className="common-button"
            size="middle"
            onClick={form.submit}
            loading={isSubmitLoading}
            disabled={isDisabled}
          >
            Save
          </Button>
        </div>
      </Portal>
      <Card
        className="full-height-with-nav"
        title="Delivery Partner Details"
        extra={
          <strong>
            Onboarded On :{' '}
            {formatDate(deliveryDetail?.createdAt, DATE_FORMATS?.ONBOARD)}
          </strong>
        }
      >
        {isDeliveryLoading ? (
          <LoaderComponent
            size="large"
            setHeight="60"
            spinning={isDeliveryLoading}
          />
        ) : (
          <div className="delivery-form">
            <DeliveryForm
              form={form}
              onFinish={updateDeliveryFun}
              handleShowPrompt={handleShowPrompt}
              initialValues={initialValues}
              deliveryDetail={deliveryDetail}
            />
          </div>
        )}
      </Card>
    </>
  );
};

export default EditDelivery;
