import { Card } from 'antd';
import React from 'react';
import { useLocation } from 'react-router-dom';
import { MODULES, ROUTES } from '../../../../../common/constants';
import GoBackButton from '../../../../../components/GoBackButton';
import Portal from '../../../../../components/Portal';
import ViewBulkTable from '../components/ViewBulkTable';

const ViewBulk = ({ match: { params: { id } = {} } = {} }) => {
  const location = useLocation();
  const queryParams = new URLSearchParams(location?.search);
  const library = queryParams?.get('library') || '';

  return (
    <>
      <Portal portalId="header-title-content">
        <GoBackButton
          customLink={`${ROUTES?.REPORTS}${ROUTES?.BULK_UPDATE}?library=${library}`}
        />
        <div className="portal-header">View {MODULES?.BULK_UPDATE}</div>
      </Portal>
      <Card className="full-height-without-nav">
        <ViewBulkTable libraryId={id} />
      </Card>
    </>
  );
};

export default ViewBulk;
