import { useLazyQuery } from '@apollo/client';
import { Empty, Select } from 'antd';
import { debounce, uniqBy } from 'lodash';
import React, { useEffect, useState } from 'react';
import { LIMIT, SORT } from '../../../../../common/constants';
import SelectComponent from '../../../../../components/SelectComponent';
import { CATEGORIES } from '../graphql/Queries';

const { Option } = Select;

const CategorySelect = (props) => {
  const { toyDetail, ...rest } = props;
  const [categoryList, setCategoryList] = useState([]);
  const [categoriesSearchTerm, setCategoriesSearchTerm] = useState('');
  const [isAllCategoriesFetched, setIsAllCategoriesFetched] = useState(false);
  const [isCategoriesAllowClear, setIsCategoriesAllowClear] = useState(false);
  const [
    isCategoriesDropdownVisible,
    setIsCategoriesDropdownVisible,
  ] = useState(false);
  const [
    isFetchMoreCategoriesLoading,
    setIsFetchMoreCategoriesLoading,
  ] = useState(false);
  const [isCategorySelected, setIsCategorySelected] = useState(false);

  const [categories, { loading: isCategoriesLoading }] = useLazyQuery(
    CATEGORIES,
    {
      onCompleted: (response) => {
        if (response?.categories?.data?.length < LIMIT) {
          setIsAllCategoriesFetched(true);
        }
        setIsCategorySelected(false);
        setIsFetchMoreCategoriesLoading(false);
        if (toyDetail) {
          setCategoryList(
            uniqBy(
              [
                ...toyDetail?.categories,
                ...categoryList,
                ...response?.categories?.data,
              ],
              'id',
            ),
          );
          return;
        }
        setCategoryList([...categoryList, ...response?.categories?.data]);
      },
      fetchPolicy: 'network-only',
      onError() {},
    },
  );

  useEffect(() => {
    if (!isCategoriesDropdownVisible) {
      setCategoriesSearchTerm('');
      setCategoryList([]);
      categories({
        variables: {
          filter: {
            limit: LIMIT,
            skip: 0,
            search: categoriesSearchTerm,
          },
          sort: {
            field: 'name',
            order: SORT?.ASC,
          },
        },
      });
    }
    setIsAllCategoriesFetched(false);
  }, [isCategoriesDropdownVisible]);

  useEffect(() => {
    if (
      isCategorySelected &&
      isCategoriesDropdownVisible &&
      categoriesSearchTerm
    ) {
      setCategoriesSearchTerm('');
      setCategoryList([]);
      categories({
        variables: {
          filter: {
            limit: LIMIT,
            skip: 0,
          },
          sort: {
            field: 'name',
            order: SORT?.ASC,
          },
        },
      });
    }
    setIsAllCategoriesFetched(false);
  }, [isCategorySelected, isCategoriesDropdownVisible, categoriesSearchTerm]);

  const handleCategoriesScroll = (event) => {
    if (!isAllCategoriesFetched && !isCategoriesLoading) {
      const target = event?.target;
      const currentLength = categoryList?.length;
      if (
        target?.scrollTop + target?.offsetHeight >=
        target?.scrollHeight - 5
      ) {
        setIsFetchMoreCategoriesLoading(true);
        categories({
          variables: {
            filter: {
              limit: LIMIT,
              skip: currentLength,
              search: categoriesSearchTerm,
            },
            sort: {
              field: 'name',
              order: SORT?.ASC,
            },
          },
        });
      }
    }
  };

  const handleCategoriesSearch = (searchTerm) => {
    const trimSearch = searchTerm?.trim();
    setIsAllCategoriesFetched(false);
    setCategoryList([]);
    setCategoriesSearchTerm(trimSearch);
    categories({
      variables: {
        filter: {
          limit: LIMIT,
          skip: 0,
          search: trimSearch,
        },
        sort: {
          field: 'name',
          order: SORT?.ASC,
        },
      },
    });
  };

  const handleCategoriesScrollDebounce = debounce(handleCategoriesScroll, 500);
  const handleSearchCategoriesDebounce = debounce(handleCategoriesSearch, 500);

  useEffect(() => {
    handleCategoriesScrollDebounce?.cancel();
  }, [handleCategoriesScrollDebounce]);

  useEffect(() => {
    handleSearchCategoriesDebounce?.cancel();
  }, [handleSearchCategoriesDebounce]);

  useEffect(() => {
    if (isCategoriesLoading) {
      setIsCategoriesAllowClear(false);
    } else {
      setIsCategoriesAllowClear(true);
    }
  }, [isCategoriesLoading]);

  return (
    <SelectComponent
      mode="multiple"
      showSearch
      loading={
        (isCategoriesLoading && isCategoriesDropdownVisible) ||
        isFetchMoreCategoriesLoading
      }
      placeholder="Select Categories"
      optionFilterProp="children"
      allowClear={isCategoriesAllowClear}
      onPopupScroll={handleCategoriesScrollDebounce}
      onSearch={handleSearchCategoriesDebounce}
      onDropdownVisibleChange={(visible) =>
        setIsCategoriesDropdownVisible(visible)
      }
      filterOption={false}
      onChange={() => setIsCategoriesAllowClear(true)}
      onSelect={() => setIsCategorySelected(true)}
      maxTagCount={false}
      notFoundContent={
        !isFetchMoreCategoriesLoading && isCategoriesLoading ? (
          <span>Loading...</span>
        ) : (
          <Empty image={Empty?.PRESENTED_IMAGE_SIMPLE} />
        )
      }
      {...rest}
    >
      {categoryList?.map((category) => (
        <Option key={category?.id} value={category?.id}>
          {category?.name}
        </Option>
      ))}
    </SelectComponent>
  );
};

export default CategorySelect;
