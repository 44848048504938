import { Divider, Form } from 'antd';
import React, { useState } from 'react';
import { NUMBER_FIELD_TYPES } from '../../../common/constants';
import { formValidatorRules } from '../../../common/utils';
import CommonPreview from '../../../components/CommonPreview';
import ModalComponent from '../../../components/ModalComponent';
import NumberComponent from '../../../components/NumberComponent';
import PackagingMaterialSelect from './PackagingMaterialSelect';

const { Item } = Form;
const { positiveNumber } = formValidatorRules;

const RaiseBagRequestModal = ({
  isModalOpen,
  setIsModalOpen,
  onFinish,
  loadings,
  form,
}) => {
  const [isValidateTrigger, setIsValidateTrigger] = useState(false);
  const [sizeImage, setSizeImage] = useState();
  const [visible, setVisible] = useState(false);

  const handleCancel = () => {
    setIsModalOpen(false);
    form?.resetFields();
  };

  return (
    <>
      <CommonPreview
        visible={visible}
        setVisible={setVisible}
        image={sizeImage}
        setImage={setSizeImage}
      />
      <ModalComponent
        open={isModalOpen}
        onCancel={handleCancel}
        onOk={form?.submit}
        title="Packaging"
        okText="Save"
        confirmLoading={loadings}
        destroyOnClose
      >
        <Divider />
        <div className="mb-16">
          <Form
            form={form}
            onFinish={onFinish}
            autoComplete="off"
            layout="vertical"
            validateTrigger={isValidateTrigger ? 'onChange' : 'onSubmit'}
            onFinishFailed={() => setIsValidateTrigger(true)}
          >
            <Item
              label="Quantity"
              name="quantity"
              rules={[
                { required: true, message: 'Please Enter Quantity!' },
                positiveNumber(false),
              ]}
            >
              <NumberComponent
                allowClear
                isFractional={false}
                placeholder="Enter Quantity"
                type={NUMBER_FIELD_TYPES?.STOCK}
              />
            </Item>
            <Item
              label="Packaging Material"
              name="size"
              rules={[
                {
                  required: true,
                  message: 'Please Select Packaging Material!',
                },
              ]}
            >
              <PackagingMaterialSelect />
            </Item>
          </Form>
        </div>
      </ModalComponent>
    </>
  );
};

export default RaiseBagRequestModal;
