import { gql } from '@apollo/client';

// eslint-disable-next-line import/prefer-default-export
export const CREATE_REFUND_REQUEST = gql`
  mutation CreateRefundRequest(
    $where: UserUniqueInput!
    $data: CreateRefundRequestInput!
  ) {
    createRefundRequest(where: $where, data: $data) {
      message
    }
  }
`;

export const UPDATE_REFUND_REQUEST = gql`
  mutation UpdateRefundRequest(
    $where: TransactionHistoryUniqueInput!
    $data: UpdateRefundRequestInput!
  ) {
    updateRefundRequest(where: $where, data: $data) {
      message
    }
  }
`;

export const REQUEST_TRANSACTION_EXPORT = gql`
  mutation RequestTransactionExport(
    $data: RequestExportInput!
    $filter: requestTransactionExportFilter
  ) {
    requestTransactionExport(data: $data, filter: $filter) {
      message
    }
  }
`;
