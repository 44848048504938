import React, { useContext } from 'react';
import { Route, Switch } from 'react-router-dom';
import { MENU_ITEMS } from '../../app/components/sidebar/Sidebar';
import { AppContext } from '../../AppContext';
import { MODULES, ROUTES } from '../../common/constants';
import { getRedirectItem } from '../../common/utils';
import RedirectControl from '../../RedirectControl';

const submenuItems = MENU_ITEMS?.find(
  (menuItem) => menuItem?.key === MODULES?.REPORTS,
)?.subMenu;

const ReportWrapper = () => {
  const {
    state: { permissions },
  } = useContext(AppContext);

  const redirectArray = getRedirectItem(permissions, submenuItems);

  return (
    <Switch>
      <Route
        exact
        path={ROUTES?.REPORTS}
        render={() => <RedirectControl redirectArray={redirectArray} />}
      />
    </Switch>
  );
};

export default ReportWrapper;
