import { gql } from '@apollo/client';

// eslint-disable-next-line import/prefer-default-export
export const UPDATE_REQUEST_ADMIN = gql`
  mutation UpdateRequestAdmin(
    $where: RequestUniqueInput!
    $data: UpdateRequestInput
  ) {
    updateRequestAdmin(where: $where, data: $data) {
      message
    }
  }
`;
