import { Empty } from 'antd';
import React from 'react';
import LoaderComponent from '../../../components/LoaderComponent';
import BagRequestCard from './BagRequestCard';

const BagRequestList = ({
  bagReqList,
  infiniteScrollRef,
  isFetchMoreBagReqLoading,
  isEmptyBagReqList,
  isBagReqLoading,
}) => (
  <div>
    {!isEmptyBagReqList &&
      bagReqList?.map((bagReq) => (
        <BagRequestCard
          key={bagReq?.id}
          bagReq={bagReq}
          infiniteScrollRef={infiniteScrollRef}
        />
      ))}
    {(isFetchMoreBagReqLoading || isBagReqLoading) && (
      <LoaderComponent size="small" setHeight={10} />
    )}
    {isEmptyBagReqList && <Empty image={Empty?.PRESENTED_IMAGE_SIMPLE} />}
  </div>
);

export default BagRequestList;
