import { Divider, Form, Input } from 'antd';
import React, { useEffect, useState } from 'react';
import { formValidatorRules } from '../../../../../common/utils';
import ModalComponent from '../../../../../components/ModalComponent';

const { Item } = Form;
const { required, whitespaceNotAllowed } = formValidatorRules;

const CategoryModal = ({
  isModalOpen,
  setIsModalOpen,
  onFinish,
  loadings,
  form,
  category,
  setCategory,
}) => {
  const [isValidateTrigger, setIsValidateTrigger] = useState(false);

  const handleCancel = () => {
    setIsModalOpen(false);
    setCategory();
    form?.resetFields();
  };

  useEffect(() => {
    form?.setFieldsValue({ name: category?.name });
  }, [category]);

  return (
    <>
      <ModalComponent
        open={isModalOpen}
        onCancel={handleCancel}
        onOk={form?.submit}
        title={category ? 'Edit Category' : 'Add New Category'}
        okText="Save"
        confirmLoading={loadings}
        destroyOnClose
        forceRender
      >
        <Divider />
        <Form
          form={form}
          onFinish={(values) => {
            onFinish(values);
            setIsValidateTrigger(true);
          }}
          autoComplete="off"
          layout="vertical"
          validateTrigger={isValidateTrigger ? 'onChange' : 'onSubmit'}
          onFinishFailed={() => setIsValidateTrigger(true)}
        >
          <Item
            name="name"
            label="Category Name"
            rules={[
              { ...required, message: 'Please Enter Category Name!' },
              whitespaceNotAllowed,
            ]}
          >
            <Input
              allowClear
              placeholder="Enter Category Name"
              maxLength={100}
            />
          </Item>
        </Form>
      </ModalComponent>
    </>
  );
};

export default CategoryModal;
