import { gql } from '@apollo/client';

// eslint-disable-next-line import/prefer-default-export
export const COUNTRIES = gql`
  query Countries($filter: CitiesStatesFilter, $sort: CitiesStatesSort) {
    countries(filter: $filter, sort: $sort) {
      count
      data {
        name
        id
      }
    }
  }
`;

export const STATES = gql`
  query States(
    $where: CountryUniqueInput!
    $filter: CitiesStatesFilter
    $sort: CitiesStatesSort
  ) {
    states(where: $where, filter: $filter, sort: $sort) {
      data {
        name
        id
      }
    }
  }
`;

export const CITIES = gql`
  query Cities(
    $where: StateUniqueInput
    $filter: CitiesFilter
    $sort: CitiesStatesSort
  ) {
    cities(where: $where, filter: $filter, sort: $sort) {
      data {
        id
        name
      }
    }
  }
`;

export const PINCODES = gql`
  query Pincodes($filter: PincodesFilter, $sort: PincodesSort) {
    pincodes(filter: $filter, sort: $sort) {
      data {
        id
        pincode
      }
    }
  }
`;
