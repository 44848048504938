import { DatePicker, Divider, Form, Input } from 'antd';
import dayjs from 'dayjs';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { DATE_FORMATS } from '../../../../../common/constants';
import { formValidatorRules } from '../../../../../common/utils';
import ModalComponent from '../../../../../components/ModalComponent';
import LibrarySelect from '../../../../lists/modules/library/components/LibrarySelect';

const { Item } = Form;
const { RangePicker } = DatePicker;
const { TextArea } = Input;
const { required, whitespaceNotAllowed } = formValidatorRules;

const RaiseClosureRequestModal = ({
  isModalOpen,
  setIsModalOpen,
  onFinish,
  loadings,
  form,
  closureReq,
  setClosureReq,
  title,
}) => {
  const [isValidateTrigger, setIsValidateTrigger] = useState(false);

  const handleCancel = () => {
    setIsModalOpen(false);
    setClosureReq(null);
    form?.resetFields();
  };

  useEffect(() => {
    const fromDate = closureReq?.metaData?.fromDate;
    const toDate = closureReq?.metaData?.toDate;
    const initialValues = {
      libraryId: closureReq?.library?.id,
      reason: closureReq?.description,
      closureDate: fromDate && toDate ? [dayjs(fromDate), dayjs(toDate)] : null,
    };
    form?.setFieldsValue(initialValues);
  }, [closureReq]);

  return (
    <>
      <ModalComponent
        open={isModalOpen}
        onCancel={handleCancel}
        onOk={form?.submit}
        title={title}
        okText="Save"
        confirmLoading={loadings}
        destroyOnClose
      >
        <Divider />
        <div className="mb-16">
          <Form
            form={form}
            onFinish={onFinish}
            autoComplete="off"
            layout="vertical"
            validateTrigger={isValidateTrigger ? 'onChange' : 'onSubmit'}
            onFinishFailed={() => setIsValidateTrigger(true)}
          >
            <Item
              label="Library Name"
              name="libraryId"
              rules={[
                {
                  required: true,
                  message: 'Please Select Library Name!',
                },
                whitespaceNotAllowed,
              ]}
            >
              <LibrarySelect
                libraryData={closureReq?.library}
                disabled={!!closureReq}
                isActiveLibOnly
              />
            </Item>
            <Item
              label="Temporary Closure Date"
              name="closureDate"
              rules={[
                {
                  required: true,
                  message: 'Please Select Library Closure Date!',
                },
              ]}
            >
              <RangePicker
                format={DATE_FORMATS?.REGULAR}
                className="full-width"
                placeholder={['From Date', 'To Date']}
                disabledDate={(current) =>
                  current &&
                  current < moment()?.subtract(1, 'days')?.endOf('day')
                }
              />
            </Item>
            <Item
              label="Reason For Temporary Closure"
              name="reason"
              rules={[
                {
                  ...required,
                  message: 'Please Enter Reason For Temporary Closure!',
                },
                whitespaceNotAllowed,
              ]}
            >
              <TextArea
                rows={4}
                allowClear
                placeholder="Enter Reason For Temporary Closure"
                showCount
                maxLength={700}
              />
            </Item>
          </Form>
        </div>
      </ModalComponent>
    </>
  );
};

export default RaiseClosureRequestModal;
