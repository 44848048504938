import { gql } from '@apollo/client';

// eslint-disable-next-line import/prefer-default-export
export const CREATE_COLLECTION = gql`
  mutation CreateCollection($data: CreateCollectionInput!) {
    createCollection(data: $data) {
      message
    }
  }
`;

export const UPDATE_COLLECTION = gql`
  mutation UpdateCollection($where: CollectionUniqueInput!, $data: UpdateCollectionInput) {
    updateCollection(where: $where, data: $data) {
      message
    }
  }
`;

export const MAP_COLLECTION_TOYS = gql`
mutation MapToysCollection($where: CollectionUniqueInput!, $data: ToyCollectionMappingInput) {
  mapToysCollection(where: $where, data: $data) {
    message
  }
}
`;

export const REMOVE_COLLECTION_TOYS = gql`
mutation RemoveToysCollection($where: CollectionUniqueInput!, $data: ToyCollectionMappingInput) {
  removeToysCollection(where: $where, data: $data) {
    message
  }
}
`;