import React from 'react';
import { Route, Switch } from 'react-router-dom';
import AccessControl from '../../../AccessControl';
import { PERMISSIONS_KEY, ROUTES } from '../../../common/constants';
import Error404 from '../../../Error404';
import Genres from './pages/Genres';
import ViewGenreToys from './pages/ViewGenreToys';

const GenresWrapper = () => (
  <Switch>
    <Route
      exact
      path={`${ROUTES?.APP_CONTENT}${ROUTES?.GENRES}`}
      render={(props) => (
        <AccessControl permissionKey={PERMISSIONS_KEY?.APP_CONTENT_GENRES}>
          <Genres {...props} />
        </AccessControl>
      )}
    />
    <Route
      exact
      path={`${ROUTES?.APP_CONTENT}${ROUTES?.GENRES}/view/:id`}
      render={(props) => (
        <AccessControl permissionKey={PERMISSIONS_KEY?.APP_CONTENT_GENRES}>
          <ViewGenreToys {...props} />
        </AccessControl>
      )}
    />
    <Route path="*" exact component={Error404} />
  </Switch>
);

export default GenresWrapper;
