import { PlusOutlined } from '@ant-design/icons';
import { Col, Divider, Form, Input, Radio, Select, Upload } from 'antd';
import React, { useEffect, useState } from 'react';
import { BRAND_STATUS } from '../../../../../common/constants';
import {
  formValidatorRules,
  getBase64,
  validateImagesBrands,
} from '../../../../../common/utils';
import ModalComponent from '../../../../../components/ModalComponent';

const { Item } = Form;
const { required, whitespaceNotAllowed } = formValidatorRules;
const { Option } = Select;
const BrandModal = ({
  isModalOpen,
  setIsModalOpen,
  onFinish,
  loadings,
  form,
  brand,
  setBrand,
  setBrandImages,
  brandImages,
  setVisible,
  setBrandsPreviewImage,
}) => {
  const [isValidateTrigger, setIsValidateTrigger] = useState(false);
  const Options = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10];
  const handleCancel = () => {
    setIsModalOpen(false);
    setBrand();
    form?.resetFields();
  };
  const handleImageChange = (info) => {
    validateImagesBrands(info, form, setBrandImages, 'image');
  };
  const handlePreview = async (fileObj) => {
    let preview;
    if (!fileObj?.url && !fileObj?.preview) {
      preview = await getBase64(fileObj?.originFileObj);
    }
    setVisible(true);
    setBrandsPreviewImage(fileObj?.url || preview);
  };

  useEffect(() => {
    form?.setFieldsValue({
      name: brand?.name,
      order: brand?.order,
      status: brand?.status,
    });
  }, [brand]);

  return (
    <>
      <ModalComponent
        open={isModalOpen}
        onCancel={handleCancel}
        onOk={form?.submit}
        title={brand ? 'Edit Brand' : 'Add New Brand'}
        okText="Save"
        confirmLoading={loadings}
        destroyOnClose
        forceRender
      >
        <Divider />
        <Form
          form={form}
          onFinish={(values) => {
            onFinish(values);
            setIsValidateTrigger(true);
          }}
          autoComplete="off"
          layout="vertical"
          validateTrigger={isValidateTrigger ? 'onChange' : 'onSubmit'}
          onFinishFailed={() => setIsValidateTrigger(true)}
        >
          <Item
            name="name"
            label="Brand Name"
            rules={[
              { ...required, message: 'Please Enter Brand Name!' },
              whitespaceNotAllowed,
            ]}
          >
            <Input allowClear placeholder="Enter Brand Name" maxLength={100} />
          </Item>
          <Item label="Order" name="order">
            <Select placeholder="Select Order" allowClear>
              {Options?.map((option) => (
                <Option key={option} value={option}>
                  {option}{' '}
                </Option>
              ))}
            </Select>
          </Item>
          {brand && (
            <Item label="Status" name="status">
              <Radio.Group placeholder="Select Status" allowClear>
                {Object?.values(BRAND_STATUS)
                  ?.filter((option) => option.value !== 'DRAFT')
                  ?.map((option) => (
                    <Radio key={option?.value} value={option?.value}>
                      {option?.label}
                    </Radio>
                  ))}
              </Radio.Group>
            </Item>
          )}
          <Item label="Image" name="image">
            <Upload
              listType="picture-card"
              maxCount={1}
              fileList={brandImages}
              beforeUpload={() => false}
              onPreview={handlePreview}
              onChange={handleImageChange}
              showUploadList={{
                showDownloadIcon: false,
                showPreviewIcon: true,
                showRemoveIcon: true,
              }}
            >
              {brandImages?.length !== 1 && <PlusOutlined />}
            </Upload>
          </Item>
          <Col xs={24} className="notes">
            <div>Image Notes </div>
            <ul>
              <li>Image upload supported formats are JPEG, JPG, PNG</li>
              <li>The minimum dimensions is 750x400 pixels</li>
              <li>Allow maximum size of 3 MB</li>
            </ul>
          </Col>
        </Form>
      </ModalComponent>
    </>
  );
};

export default BrandModal;
