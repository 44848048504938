import { Col, DatePicker, Form, Input, Row } from 'antd';
import { map } from 'lodash';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import {
  DATE_FORMATS,
  GIFT_CARD_STATUS,
  NUMBER_FIELD_TYPES,
  SUBSCRIPTION_TYPE,
} from '../../../../../common/constants';
import { formValidatorRules } from '../../../../../common/utils';
import NumberComponent from '../../../../../components/NumberComponent';
import SelectComponent from '../../../../../components/SelectComponent';
import '../giftCard.less';
import SubscriptionPlanSelect from './SubscriptionPlanSelect';

const { Item } = Form;
const { TextArea } = Input;
const { price, whitespaceNotAllowed } = formValidatorRules;

const GiftCardForm = ({
  form,
  onFinish,
  handleShowPrompt,
  initialValues,
  isView,
  giftCardDetail,
}) => {
  const [cardPrice, setCardPrice] = useState(initialValues?.price || '');
  const [validationTriggered, setValidationTriggered] = useState(false);
  const [startDate, setStartDate] = useState(initialValues?.startsOn || null);
  const [expireDate, setExpireDate] = useState(
    initialValues?.expiresOn || null,
  );

  useEffect(() => {
    const initialValueFun = () => {
      form?.setFieldsValue(initialValues);
    };
    setTimeout(initialValueFun, 300);
  }, []);

  useEffect(() => {
    const initialValueFun = () => {
      form?.setFieldsValue({
        price: cardPrice,
        status: initialValues
          ? initialValues?.status
          : GIFT_CARD_STATUS?.ACTIVE?.value,
      });
    };
    setTimeout(initialValueFun, 300);
  }, [cardPrice]);

  const onFinishFailed = () => {
    setValidationTriggered(true);
  };

  return (
    <>
      <Form
        layout="vertical"
        form={form}
        onFinish={onFinish}
        onFieldsChange={handleShowPrompt}
        disabled={
          !!isView ||
          giftCardDetail?.isArchived ||
          giftCardDetail?.status === GIFT_CARD_STATUS?.EXPIRED?.value
        }
        onFinishFailed={onFinishFailed}
        className={`gift-card-form ${isView ? 'common-view-form' : 'form'}`}
        validateTrigger={validationTriggered ? 'onChange' : 'onSubmit'}
      >
        <Row gutter={[16, 0]}>
          {initialValues && (
            <Col xs={24} sm={12} md={12} lg={12} xl={6} xxl={6}>
              <Item
                label="Code"
                name="code"
                rules={[{ required: true, message: 'Please Select Plan!' }]}
              >
                <Input allowClear placeholder="Enter Code" disabled />
              </Item>
            </Col>
          )}
          <Col
            xs={24}
            sm={12}
            md={12}
            lg={12}
            xl={initialValues ? 6 : 8}
            xxl={initialValues ? 6 : 8}
          >
            <Item
              label="Plan"
              name="plan"
              rules={[{ required: true, message: 'Please Select Plan!' }]}
            >
              <SubscriptionPlanSelect
                planData={giftCardDetail?.subscription}
                onChange={(_, option) =>
                  setCardPrice(option?.price?.toString())
                }
                disabled={initialValues}
                type={SUBSCRIPTION_TYPE?.GIFT_CARD?.value}
                isTrialPlanDisplay
              />
            </Item>
          </Col>
          <Col
            xs={24}
            sm={12}
            md={12}
            lg={12}
            xl={initialValues ? 6 : 8}
            xxl={initialValues ? 6 : 8}
          >
            <Item label="Price" name="price" rules={[price]}>
              <NumberComponent
                allowClear
                placeholder="Plan not selected"
                type={NUMBER_FIELD_TYPES?.PRICE}
                disabled
              />
            </Item>
          </Col>
          <Col
            xs={24}
            sm={12}
            md={12}
            lg={12}
            xl={initialValues ? 6 : 8}
            xxl={initialValues ? 6 : 8}
          >
            <Item label="Status" name="status">
              <SelectComponent
                placeholder="Select Status"
                options={map(GIFT_CARD_STATUS, (status) => ({
                  label: status?.label,
                  value: status?.value,
                }))}
                disabled
              />
            </Item>
          </Col>
          <Col xs={24} sm={12} md={12} lg={12} xl={12} xxl={12}>
            <Item
              label="Starts On"
              name="startsOn"
              rules={[
                {
                  required: true,
                  message: 'Please Select Starts On!',
                },
              ]}
            >
              <DatePicker
                format={DATE_FORMATS?.REGULAR}
                onChange={(date) => setStartDate(date)}
                disabledDate={(current) =>
                  (current &&
                    current < moment()?.subtract(1, 'days')?.endOf('day')) ||
                  (expireDate && current > expireDate.endOf('day'))
                }
              />
            </Item>
          </Col>
          <Col xs={24} sm={12} md={12} lg={12} xl={12} xxl={12}>
            <Item
              label="Expires On"
              name="expiresOn"
              rules={[
                {
                  required: true,
                  message: 'Please Select Expires On!',
                },
              ]}
            >
              <DatePicker
                format={DATE_FORMATS?.REGULAR}
                onChange={(date) => setExpireDate(date)}
                disabledDate={(current) =>
                  (current &&
                    current < moment()?.subtract(1, 'days')?.endOf('day')) ||
                  (startDate && current < startDate.startOf('day'))
                }
              />
            </Item>
          </Col>
          <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24}>
            <Item label="Notes" name="notes" rules={[whitespaceNotAllowed]}>
              <TextArea
                rows={3}
                allowClear
                placeholder="Enter Notes"
                showCount
                maxLength={700}
              />
            </Item>
          </Col>
        </Row>
      </Form>
    </>
  );
};

export default GiftCardForm;
