import { PlusOutlined } from '@ant-design/icons';
import { Button, Card } from 'antd';
import React, { useContext, useState } from 'react';
import { AppContext } from '../../../../AppContext';
import {
  PERMISSION_TYPE,
  PERMISSIONS_KEY,
  ROUTES,
} from '../../../../common/constants';
import { hasPermission } from '../../../../common/utils';
import GoBackButton from '../../../../components/GoBackButton';
import Portal from '../../../../components/Portal';
import ViewStoryTable from '../components/ViewStoryTable';

const ViewStory = (props) => {
  const { match: { params: { id } = {} } = {} } = props;
  const {
    state: { permissions },
  } = useContext(AppContext);
  const [isStoryVideoModalOpen, setIsStoryVideoModalOpen] = useState(false);

  return (
    <>
      <Portal portalId="header-title-content">
        <GoBackButton customLink={ROUTES?.STORY} />
        <div className="portal-header">View Story </div>
      </Portal>
      <Portal portalId="header-right-content">
        {hasPermission(
          PERMISSIONS_KEY?.APP_CONTENT_STORY,
          PERMISSION_TYPE?.CREATE,
          permissions,
        ) && (
            <Button
              className="common-button"
              size="middle"
              icon={<PlusOutlined />}
              onClick={() => setIsStoryVideoModalOpen(true)}
            >
              Add New Video
            </Button>
          )}
      </Portal>
      <Portal portalId="header-right-content-phones">
        {hasPermission(
          PERMISSIONS_KEY?.APP_CONTENT_STORY,
          PERMISSION_TYPE?.CREATE,
          permissions,
        ) && (
            <Button
              className="common-button"
              size="middle"
              icon={<PlusOutlined />}
              onClick={() => setIsStoryVideoModalOpen(true)}
            >
              Add New Video
            </Button>
          )}
      </Portal>
      <Card className="full-height-without-nav">
        <ViewStoryTable
          isStoryVideoModalOpen={isStoryVideoModalOpen}
          setIsStoryVideoModalOpen={setIsStoryVideoModalOpen}
          storyId={id}
        />
      </Card>
    </>
  );
};

export default ViewStory;
