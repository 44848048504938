import { useLazyQuery, useMutation } from '@apollo/client';
import { Form, Modal, Switch, Tooltip } from 'antd';
import React, { useContext, useEffect, useState } from 'react';
import { AppContext } from '../../../../../AppContext';
import { ReactComponent as ArchiveIcon } from '../../../../../assets/svg/box-archive-solid.svg';
import { ReactComponent as EditIcon } from '../../../../../assets/svg/pen-to-square-solid.svg';
import { ReactComponent as UnarchiveIcon } from '../../../../../assets/svg/right-from-bracket-solid.svg';
import {
  ARCHIVING_STATE,
  BREAKPOINTS,
  DATE_FORMATS,
  LIMIT,
  PERMISSIONS_KEY,
  PERMISSION_TYPE,
  SORT,
} from '../../../../../common/constants';
import { formatDate, hasPermission } from '../../../../../common/utils';
import CommonTable from '../../../../../components/CommonTable';
import SearchComponent from '../../../../../components/SearchComponent';
import { CREATE_MATERIAL, UPDATE_MATERIAL } from '../graphql/Mutations';
import { MATERIALS_ADMIN } from '../graphql/Queries';
import MaterialModal from './MaterialModal';

const initialPaginationValue = {
  total: 0,
  current: 1,
  pageSize: 10,
};

const initialFilter = {
  limit: LIMIT,
  search: '',
  skip: 0,
};

const initialSort = {
  field: 'name',
  order: 'ASC',
};

const MaterialTable = ({ isMaterialModalOpen, setIsMaterialModalOpen }) => {
  const {
    state: { permissions },
  } = useContext(AppContext);
  const [form] = Form.useForm();
  const [materialList, setMaterialList] = useState([]);
  const [materialSearchTerm, setBrandSearchTerm] = useState('');
  const [isEmptyMaterialList, setIsEmptyMaterialList] = useState(false);
  const [material, setMaterial] = useState();
  const [query, setQuery] = useState('');
  const [sortedInfo, setSortedInfo] = useState({});
  const [paginationProp, setPaginationProp] = useState(initialPaginationValue);
  const [isArchivePrompts, setIsArchivePrompts] = useState(false);
  const [isMaterialLoading, setIsMaterialLoading] = useState(true);
  const [isArchiveLoading, setIsArchiveLoading] = useState(false);
  const [isArchiveFilter, setIsArchiveFilter] = useState(false);
  const [isBtnLoading, setIsBtnLoading] = useState(false);

  const [createMaterial] = useMutation(CREATE_MATERIAL, {
    onError: () => {},
  });

  const [updateMaterial] = useMutation(UPDATE_MATERIAL, {
    onError: () => {},
  });

  const [materialsAdmin] = useLazyQuery(MATERIALS_ADMIN, {
    onCompleted: (response) => {
      setMaterialList([...response?.materialsAdmin?.data]);
      if (
        response?.materialsAdmin?.count === 0 &&
        initialPaginationValue?.total === 0
      ) {
        setIsEmptyMaterialList(true);
      } else {
        setIsEmptyMaterialList(false);
      }
      const pagination = {
        ...paginationProp,
        defaultPageSize: LIMIT,
        total: response?.materialsAdmin?.count,
      };
      setPaginationProp(pagination);
      setIsMaterialLoading(false);
    },
    fetchPolicy: 'network-only',
    onError() {},
  });

  const hasUpdatePermission = hasPermission(
    PERMISSIONS_KEY?.MASTER_LIST_MATERIAL,
    PERMISSION_TYPE?.UPDATE,
    permissions,
  );

  useEffect(() => {
    setPaginationProp({ ...paginationProp, current: 1, skip: 0 });
    setIsMaterialLoading(true);
    materialsAdmin({
      variables: {
        filter: {
          ...initialFilter,
          limit: paginationProp?.pageSize || LIMIT,
          search: materialSearchTerm,
          isArchived: isArchiveFilter,
        },
        sort: sortedInfo?.column
          ? {
              field: sortedInfo?.field,
              order: sortedInfo?.order === 'ascend' ? SORT?.ASC : SORT?.DESC,
            }
          : initialSort,
      },
    });
  }, [isArchiveFilter]);

  const handleTableChange = (pagination, tableFilter, sorter) => {
    const { current } = pagination;
    const skip = (current - 1) * (pagination?.pageSize || 0);
    setPaginationProp({ ...paginationProp, ...pagination });
    setIsMaterialLoading(true);
    setSortedInfo(sorter);
    materialsAdmin({
      variables: {
        filter: {
          ...initialFilter,
          skip,
          limit: pagination?.pageSize,
          search: materialSearchTerm,
          isArchived: isArchiveFilter,
        },
        sort: sorter?.column
          ? {
              field: sorter?.field,
              order: sorter?.order === 'ascend' ? SORT?.ASC : SORT?.DESC,
            }
          : initialSort,
      },
    });
  };

  const handleSearch = (value) => {
    const trimValue = value?.trim();
    setBrandSearchTerm(trimValue);
    setPaginationProp({ ...paginationProp, current: 1, skip: 0 });
    setIsMaterialLoading(true);
    materialsAdmin({
      variables: {
        filter: {
          ...initialFilter,
          limit: paginationProp?.pageSize || LIMIT,
          search: trimValue,
          isArchived: isArchiveFilter,
        },
        sort: sortedInfo?.column
          ? {
              field: sortedInfo?.field,
              order: sortedInfo?.order === 'ascend' ? SORT?.ASC : SORT?.DESC,
            }
          : initialSort,
      },
    });
  };

  const handleArchive = async (id, isArchived) => {
    setIsArchiveLoading(true);
    const response = await updateMaterial({
      variables: {
        where: {
          id,
        },
        data: {
          name: material?.name,
          isArchived: !isArchived,
        },
      },
    });
    if (response?.data) {
      setIsArchiveLoading(false);
      setIsMaterialLoading(true);
      setIsArchivePrompts(false);
      setMaterial();
      materialsAdmin({
        variables: {
          filter: {
            ...initialFilter,
            skip:
              (paginationProp?.current - 1) * (paginationProp?.pageSize || 0),
            limit: paginationProp?.pageSize || LIMIT,
            search: materialSearchTerm,
            isArchived: isArchiveFilter,
          },
          sort: sortedInfo?.column
            ? {
                field: sortedInfo?.field,
                order: sortedInfo?.order === 'ascend' ? SORT?.ASC : SORT?.DESC,
              }
            : initialSort,
        },
      });
    }
    setIsArchiveLoading(false);
  };

  const handleMaterial = async (values) => {
    setIsBtnLoading(true);
    let response;
    if (!material) {
      response = await createMaterial({
        variables: {
          data: {
            name: values?.name?.trim(),
          },
        },
      });
    } else {
      response = await updateMaterial({
        variables: {
          where: {
            id: material?.id,
          },
          data: {
            name: values?.name?.trim(),
          },
        },
      });
    }
    if (response?.data) {
      setIsMaterialModalOpen(false);
      setIsBtnLoading(true);
      setMaterial();
      form?.resetFields();
      materialsAdmin({
        variables: {
          filter: {
            ...initialFilter,
            skip:
              (paginationProp?.current - 1) * (paginationProp?.pageSize || 0),
            limit: paginationProp?.pageSize || LIMIT,
            search: materialSearchTerm,
            isArchived: isArchiveFilter,
          },
          sort: sortedInfo?.column
            ? {
                field: sortedInfo?.field,
                order: sortedInfo?.order === 'ascend' ? SORT?.ASC : SORT?.DESC,
              }
            : initialSort,
        },
      });
    }
    setIsBtnLoading(false);
  };

  const columns = [
    {
      title: 'MATERIAL NAME',
      dataIndex: 'name',
      key: 'name',
      ellipsis: true,
      sorter: true,
      width: 250,
      align: 'left',
      // eslint-disable-next-line no-undef
      fixed: window.innerWidth > BREAKPOINTS.desktop ? 'left' : false,
      className: 'max-width-column',
      sortOrder: sortedInfo?.columnKey === 'name' && sortedInfo?.order,
      render: (_, record) => record?.name || '-',
    },
    {
      title: 'CREATED AT',
      dataIndex: 'createdAt',
      key: 'createdAt',
      width: 150,
      ellipsis: true,
      align: 'left',
      className: 'max-width-column',
      render: (_, record) =>
        formatDate(record?.createdAt, DATE_FORMATS?.REGULAR) || '-',
    },
    {
      title: 'ACTIONS',
      dataIndex: 'actions',
      key: 'actions',
      ellipsis: true,
      width: 150,
      // eslint-disable-next-line no-undef
      fixed: window.innerWidth > BREAKPOINTS.desktop ? 'right' : false,
      render: (_, record) => (
        <>
          <div className="action-button">
            {isArchiveFilter ? (
              <Tooltip title="Unarchive" placement="top" zIndex={4}>
                <UnarchiveIcon
                  onClick={() => {
                    setIsArchivePrompts(true);
                    setMaterial(record);
                  }}
                  className="mr-16 pointer svg-icon"
                />
              </Tooltip>
            ) : (
              <>
                <Tooltip title="Edit" placement="top" zIndex={4}>
                  <EditIcon
                    onClick={() => {
                      setIsMaterialModalOpen(true);
                      setMaterial(record);
                    }}
                    className="mr-16 pointer svg-icon"
                  />
                </Tooltip>
                <Tooltip title="Archive" placement="top" zIndex={4}>
                  <ArchiveIcon
                    onClick={() => {
                      setIsArchivePrompts(true);
                      setMaterial(record);
                    }}
                    className="mr-16 pointer svg-icon"
                  />
                </Tooltip>
              </>
            )}
          </div>
        </>
      ),
    },
  ]?.filter((item) => {
    if (item?.dataIndex === 'actions' && !hasUpdatePermission) {
      return item?.hidden;
    }
    return !item?.hidden;
  });

  const locale = {
    emptyText: isEmptyMaterialList ? '' : <span />,
  };

  return (
    <>
      <Modal
        title="Caution"
        okText="Yes"
        cancelText="No"
        open={isArchivePrompts}
        onOk={() => handleArchive(material?.id, material?.isArchived)}
        onCancel={() => {
          setIsArchivePrompts(false);
          setMaterial();
        }}
        okButtonProps={{ loading: isArchiveLoading }}
      >
        Are you sure you want to
        <strong>
          {' '}
          {!material?.isArchived
            ? ARCHIVING_STATE?.ARCHIVED
            : ARCHIVING_STATE?.UNARCHIVED}{' '}
        </strong>
        this material?
      </Modal>
      <MaterialModal
        form={form}
        onFinish={handleMaterial}
        loadings={isBtnLoading}
        isModalOpen={isMaterialModalOpen}
        setIsModalOpen={setIsMaterialModalOpen}
        material={material}
        setMaterial={setMaterial}
      />
      <div className="d-flex justify-end mb-16 flex-wrap">
        {hasUpdatePermission && (
          <div className="mr-16 d-flex align-center">
            <h4 className="m-0 mr-8">Only Archived</h4>{' '}
            <Switch
              size="small"
              onChange={(checked) => setIsArchiveFilter(checked)}
            />
          </div>
        )}
        <SearchComponent
          className="search-box"
          query={query}
          setQuery={setQuery}
          getData={handleSearch}
        />
      </div>
      <CommonTable
        locale={locale}
        columns={columns}
        data={materialList || []}
        loading={isMaterialLoading}
        onChange={handleTableChange}
        paginationConfig={paginationProp}
        rowKey={(record) => record?.id}
      />
    </>
  );
};

export default MaterialTable;
