import React from 'react';
import { Route, Switch } from 'react-router-dom';
import AccessControl from '../../../../AccessControl';
import {
  PERMISSION_TYPE,
  PERMISSIONS_KEY,
  ROUTES,
} from '../../../../common/constants';
import './coupon.less';
import Coupon from './pages/Coupon';
import CreateCoupon from './pages/CreateCoupon';
import ViewCoupon from './pages/ViewCoupon';

const CouponWrapper = () => (
  <Switch>
    <Route
      exact
      path={`${ROUTES?.OFFERS}${ROUTES?.COUPON}`}
      render={(props) => (
        <AccessControl permissionKey={PERMISSIONS_KEY?.OFFERS_COUPONS}>
          <Coupon {...props} />
        </AccessControl>
      )}
    />
    <Route
      exact
      path={`${ROUTES?.OFFERS}${ROUTES?.COUPON}/add`}
      render={(props) => (
        <AccessControl
          permissionType={PERMISSION_TYPE?.CREATE}
          permissionKey={PERMISSIONS_KEY?.OFFERS_COUPONS}
        >
          <CreateCoupon {...props} />
        </AccessControl>
      )}
    />
    <Route
      exact
      path={`${ROUTES?.OFFERS}${ROUTES?.COUPON}/view/:id`}
      render={(props) => (
        <AccessControl
          permissionType={PERMISSION_TYPE?.UPDATE}
          permissionKey={PERMISSIONS_KEY?.OFFERS_COUPONS}
        >
          <ViewCoupon {...props} />
        </AccessControl>
      )}
    />
  </Switch>
);

export default CouponWrapper;
