import React from 'react';
import { Route, Switch } from 'react-router-dom';
import AccessControl from '../../../../AccessControl';
import {
  PERMISSIONS_KEY,
  PERMISSION_TYPE,
  ROUTES,
} from '../../../../common/constants';
import './giftCard.less';
import CreateGiftCard from './pages/CreateGiftCard';
import EditGiftCard from './pages/EditGiftCard';
import GiftCard from './pages/GiftCard';
import ViewGiftCard from './pages/ViewGiftCard';

const GiftCardWrapper = () => (
  <Switch>
    <Route
      exact
      path={`${ROUTES?.OFFERS}${ROUTES?.GIFT_CARD}`}
      render={(props) => (
        <AccessControl permissionKey={PERMISSIONS_KEY?.OFFERS_GIFT_CARD}>
          <GiftCard {...props} />
        </AccessControl>
      )}
    />
    <Route
      exact
      path={`${ROUTES?.OFFERS}${ROUTES?.GIFT_CARD}/add`}
      render={(props) => (
        <AccessControl
          permissionType={PERMISSION_TYPE?.CREATE}
          permissionKey={PERMISSIONS_KEY?.OFFERS_GIFT_CARD}
        >
          <CreateGiftCard {...props} />
        </AccessControl>
      )}
    />
    <Route
      exact
      path={`${ROUTES?.OFFERS}${ROUTES?.GIFT_CARD}/edit/:id`}
      render={(props) => (
        <AccessControl
          permissionType={PERMISSION_TYPE?.UPDATE}
          permissionKey={PERMISSIONS_KEY?.OFFERS_GIFT_CARD}
        >
          <EditGiftCard {...props} />
        </AccessControl>
      )}
    />
    <Route
      exact
      path={`${ROUTES?.OFFERS}${ROUTES?.GIFT_CARD}/view/:id`}
      render={(props) => (
        <AccessControl
          permissionType={PERMISSION_TYPE?.UPDATE}
          permissionKey={PERMISSIONS_KEY?.OFFERS_GIFT_CARD}
        >
          <ViewGiftCard {...props} />
        </AccessControl>
      )}
    />
  </Switch>
);

export default GiftCardWrapper;
