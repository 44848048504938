import { InfoCircleOutlined } from '@ant-design/icons';
import { Card, Col, Row, Statistic, Tooltip } from 'antd';
import React, { useContext } from 'react';
import { AppContext } from '../../../AppContext';
import {
  BREAKPOINTS,
  GUTTER_VARIATIONS,
  ORDER_STATUS,
  RETURN_ORDER_ITEM_STATUS,
  ROUTES,
  USER_ROLES,
} from '../../../common/constants';

const StatisticsCards = ({ statistics = {} }) => {
  const { getCurrentUser } = useContext(AppContext);
  const { roles } = getCurrentUser() || {};
  const isLibrarian = roles?.includes(USER_ROLES?.LIBRARIAN?.key);
  const {
    delayedPlacedOrdersCount,
    orderConfirmationThreshold,
    delayedReturnedOrdersCount,
    returnConfirmationThreshold,
    onboardedReferralsCount,
  } = statistics || {};

  return (
    <Row gutter={[GUTTER_VARIATIONS, GUTTER_VARIATIONS]}>
      {!isLibrarian && (
        <Col xs={{ span: 24 }} md={{ span: 8 }} lg={{ span: 8 }}>
          <Card>
            <div className="info-card-container">
              <Statistic
                title="Total Users"
                value={statistics?.totalUsers}
                className="dashboard-statistic"
              />
              <div className="info-icon-container">
                <Tooltip
                  title="The Total Number Of Users Till Date"
                  trigger="click"
                  className="pointer"
                  placement={
                    // eslint-disable-next-line no-undef
                    window.innerWidth > BREAKPOINTS.tablet ? 'top' : 'left'
                  }
                >
                  <InfoCircleOutlined className="info-icon" />
                </Tooltip>
              </div>
            </div>
          </Card>
        </Col>
      )}
      {!isLibrarian && (
        <Col xs={{ span: 24 }} md={{ span: 8 }} lg={{ span: 8 }}>
          <Card>
            <div className="info-card-container">
              <Statistic
                title="Total Active Users"
                value={statistics?.totalActivateUsers}
                className="dashboard-statistic"
              />
              <Tooltip
                title="The Total Number Of Active Users Till Date"
                trigger="click"
                className="pointer"
                placement={
                  // eslint-disable-next-line no-undef
                  window.innerWidth > BREAKPOINTS.tablet ? 'top' : 'left'
                }
              >
                <div className="info-icon-container">
                  <InfoCircleOutlined className="info-icon" />
                </div>
              </Tooltip>
            </div>
          </Card>
        </Col>
      )}
      {!isLibrarian && (
        <Col xs={{ span: 24 }} md={{ span: 8 }} lg={{ span: 8 }}>
          <Card>
            <div className="info-card-container">
              <Statistic
                title="Total Subscribed Users"
                value={statistics?.subscribedUsers}
                className="dashboard-statistic"
              />
              <div className="info-icon-container">
                <Tooltip
                  title="The Total Number Of Subscribed Users Till Date"
                  trigger="click"
                  className="pointer"
                  placement="left"
                >
                  <InfoCircleOutlined className="info-icon" />
                </Tooltip>
              </div>
            </div>
          </Card>
        </Col>
      )}
      <Col xs={{ span: 24 }} md={{ span: 12 }} lg={{ span: 12 }}>
        <Card>
          <div className="info-card-container">
            <Statistic
              title="Total Orders"
              value={statistics?.totalOrders}
              className="dashboard-statistic"
            />
            <div className="info-icon-container">
              <Tooltip
                title="The Total Number Of Orders Till Date"
                trigger="click"
                className="pointer"
                placement={
                  // eslint-disable-next-line no-undef
                  window.innerWidth > BREAKPOINTS.tablet ? 'top' : 'left'
                }
              >
                <InfoCircleOutlined className="info-icon" />
              </Tooltip>
            </div>
          </div>
        </Card>
      </Col>
      <Col xs={{ span: 24 }} md={{ span: 12 }} lg={{ span: 12 }}>
        <Card>
          <div className="info-card-container">
            <Statistic
              title="Total Orders Today"
              value={statistics?.totalOrdersToday}
              className="dashboard-statistic"
            />
            <div className="info-icon-container">
              <Tooltip
                title="The Total Number Of Orders Today Only"
                trigger="click"
                className="pointer"
                placement="left"
              >
                <InfoCircleOutlined className="info-icon" />
              </Tooltip>
            </div>
          </div>
        </Card>
      </Col>
      <Col xs={{ span: 24 }} md={{ span: 12 }} lg={{ span: 12 }}>
        <Card>
          <div className="info-card-container">
            <Statistic
              title="Total Cancelled Orders"
              value={statistics?.totalCancelledOrders}
              className="dashboard-statistic"
            />
            <div className="info-icon-container">
              <Tooltip
                title="The Total Number Of Cancelled Orders Till Date"
                trigger="click"
                className="pointer"
                placement={
                  // eslint-disable-next-line no-undef
                  window.innerWidth > BREAKPOINTS.tablet ? 'top' : 'left'
                }
              >
                <InfoCircleOutlined className="info-icon" />
              </Tooltip>
            </div>
          </div>
        </Card>
      </Col>
      <Col xs={{ span: 24 }} md={{ span: 12 }} lg={{ span: 12 }}>
        <Card>
          <div className="info-card-container">
            <Statistic
              title="Total Cancelled Orders Today"
              value={statistics?.totalCancelledOrdersToday}
              className="dashboard-statistic"
            />
            <div className="info-icon-container">
              <Tooltip
                title="The Total Number Of Cancelled Orders Today Only"
                trigger="click"
                className="pointer"
                placement="left"
              >
                <InfoCircleOutlined className="info-icon" />
              </Tooltip>
            </div>
          </div>
        </Card>
      </Col>
      {isLibrarian && (
        <>
          <Col xs={{ span: 24 }} md={{ span: 12 }} lg={{ span: 12 }}>
            <Card>
              <div className="info-card-container">
                <Statistic
                  title={`Total Orders Pending Confirmation For Over ${orderConfirmationThreshold} Hours`}
                  className="dashboard-statistic"
                  valueRender={() => (
                    <span
                      className={`${
                        delayedPlacedOrdersCount > 0
                          ? 'pointer statistic-custom-title'
                          : ''
                      }`}
                      onClick={() => {
                        if (delayedPlacedOrdersCount > 0) {
                          // eslint-disable-next-line no-undef
                          window.open(
                            `${ROUTES.ORDERS}${ROUTES.ALL}${ROUTES.DELIVERY}?status=${ORDER_STATUS.PLACED.value}&hours=${orderConfirmationThreshold}`,
                            '_blank',
                          );
                        }
                      }}
                    >
                      {delayedPlacedOrdersCount || 0}
                    </span>
                  )}
                />
                <div className="info-icon-container">
                  <Tooltip
                    title={`The Total Number Of Orders Pending Confirmation For Over ${orderConfirmationThreshold} Hours`}
                    trigger="click"
                    className="pointer"
                    placement="left"
                  >
                    <InfoCircleOutlined className="info-icon" />
                  </Tooltip>
                </div>
              </div>
            </Card>
          </Col>
          <Col xs={{ span: 24 }} md={{ span: 12 }} lg={{ span: 12 }}>
            <Card>
              <div className="info-card-container">
                <Statistic
                  title={`Total Returns Pending Confirmation For Over ${returnConfirmationThreshold} Hours`}
                  className="dashboard-statistic"
                  valueRender={() => (
                    <span
                      className={`${
                        delayedReturnedOrdersCount > 0
                          ? 'pointer statistic-custom-title'
                          : ''
                      }`}
                      onClick={() => {
                        if (delayedReturnedOrdersCount > 0) {
                          // eslint-disable-next-line no-undef
                          window.open(
                            `${ROUTES.ORDERS}${ROUTES.ALL}${ROUTES.RETURN}?status=${RETURN_ORDER_ITEM_STATUS.RETURNED.value}`,
                            '_blank',
                          );
                        }
                      }}
                    >
                      {delayedReturnedOrdersCount || 0}
                    </span>
                  )}
                />
                <div className="info-icon-container">
                  <Tooltip
                    title={`The Total Number Of Returns Pending Confirmation For Over ${returnConfirmationThreshold} Hours`}
                    trigger="click"
                    className="pointer"
                    placement="left"
                  >
                    <InfoCircleOutlined className="info-icon" />
                  </Tooltip>
                </div>
              </div>
            </Card>
          </Col>
          <Col xs={{ span: 24 }} md={{ span: 12 }} lg={{ span: 12 }}>
            <Card>
              <div className="info-card-container">
                <Statistic
                  title="Total Onboarded Referrals"
                  value={onboardedReferralsCount}
                  className="dashboard-statistic"
                />
                <div className="info-icon-container">
                  <Tooltip
                    title="The Total Number Of Onboarded Referrals"
                    trigger="click"
                    className="pointer"
                    placement="left"
                  >
                    <InfoCircleOutlined className="info-icon" />
                  </Tooltip>
                </div>
              </div>
            </Card>
          </Col>
        </>
      )}
    </Row>
  );
};

export default StatisticsCards;
