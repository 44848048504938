import { PlusOutlined } from '@ant-design/icons';
import { Button, Card } from 'antd';
import React, { useContext, useState } from 'react';
import { AppContext } from '../../../../../AppContext';
import {
  MODULES,
  PERMISSIONS_KEY,
  PERMISSION_TYPE,
} from '../../../../../common/constants';
import { hasPermission } from '../../../../../common/utils';
import Portal from '../../../../../components/Portal';
import TagsTable from '../components/TagsTable';

const Tags = () => {
  const {
    state: { permissions },
  } = useContext(AppContext);
  const [isTagsModalOpen, setIsTagsModalOpen] = useState(false);
  const [tagsImages, setTagsImages] = useState([]);

  return (
    <>
      <Portal portalId="header-title-content">
        <div className="portal-header">{MODULES?.TAGS}</div>
      </Portal>
      <Portal portalId="header-right-content">
        {hasPermission(
          PERMISSIONS_KEY?.MASTER_LIST_TAG,
          PERMISSION_TYPE?.CREATE,
          permissions,
        ) && (
          <Button
            className="common-button"
            size="middle"
            icon={<PlusOutlined />}
            onClick={() => {
              setIsTagsModalOpen(true);
              setTagsImages([]);
            }}
          >
            Add New Tag
          </Button>
        )}
      </Portal>
      <Portal portalId="header-right-content-phones">
        {hasPermission(
          PERMISSIONS_KEY?.MASTER_LIST_TAG,
          PERMISSION_TYPE?.CREATE,
          permissions,
        ) && (
          <Button
            className="common-button"
            size="middle"
            icon={<PlusOutlined />}
            onClick={() => {
              setIsTagsModalOpen(true);
              setTagsImages([]);
            }}
          >
            Add New Tag
          </Button>
        )}
      </Portal>
      <Card className="full-height-without-nav">
        <TagsTable
          isTagsModalOpen={isTagsModalOpen}
          setIsTagsModalOpen={setIsTagsModalOpen}
          tagsImages={tagsImages}
          setTagsImages={setTagsImages}
        />
      </Card>
    </>
  );
};

export default Tags;
