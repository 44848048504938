import React from 'react';
import { ROUTES } from '../../../../../common/constants';
import GoBackButton from '../../../../../components/GoBackButton';
import Portal from '../../../../../components/Portal';
import EndUsersTabs from '../components/EndUsersTabs';
import SubscriptionHistoryTable from '../components/SubscriptionHistoryTable';

const ViewSubscriptionHistoryDetails = ({
  match: { params: { id, userNumber } = {} } = {},
}) => (
  <>
    <Portal portalId="header-title-content">
      <GoBackButton
        customLink={`${ROUTES?.SYSTEM_USERS}${ROUTES?.END_USERS}`}
      />
      <div className="portal-header">View End User</div>
    </Portal>
    <EndUsersTabs endUserId={id} endUserNumber={userNumber} isView>
      <div className="tab-table">
        <SubscriptionHistoryTable userId={id} />
      </div>
    </EndUsersTabs>
  </>
);

export default ViewSubscriptionHistoryDetails;
