import React from 'react';
import { Route, Switch } from 'react-router-dom';
import AccessControl from '../../../../AccessControl';
import { PERMISSIONS_KEY, ROUTES } from '../../../../common/constants';
import Error404 from '../../../../Error404';
import Facilitates from './pages/Facilitates';

const FacilitatesWrapper = () => (
  <Switch>
    <Route
      exact
      path={`${ROUTES?.MASTER_LISTS}${ROUTES?.FACILITATES}`}
      render={(props) => (
        <AccessControl permissionKey={PERMISSIONS_KEY?.MASTER_LIST_FACILITATE}>
          <Facilitates {...props} />
        </AccessControl>
      )}
    />
    <Route path="*" exact component={Error404} />
  </Switch>
);

export default FacilitatesWrapper;
