import { Empty } from 'antd';
import React from 'react';
import LoaderComponent from '../../../components/LoaderComponent';
import ExportCard from './ExportCard';

const ExportList = ({
  exportList,
  handleDownload,
  infiniteScrollRef,
  isFetchMoreExportLoading,
  isEmptyDeliveryList,
  isExportsLoading,
}) => (
  <div>
    {!isEmptyDeliveryList &&
      exportList?.map((exportItem) => (
        <ExportCard
          key={exportItem?.id}
          exportItem={exportItem}
          handleDownload={handleDownload}
          infiniteScrollRef={infiniteScrollRef}
        />
      ))}
    {(isFetchMoreExportLoading || isExportsLoading) && (
      <LoaderComponent size="small" setHeight={10} />
    )}
    {isEmptyDeliveryList && <Empty image={Empty?.PRESENTED_IMAGE_SIMPLE} />}
  </div>
);

export default ExportList;
