import { FilterFilled, FilterOutlined } from '@ant-design/icons';
import { useLazyQuery, useMutation } from '@apollo/client';
import {
  Badge,
  Button,
  DatePicker,
  Divider,
  Modal,
  Switch,
  Tooltip,
} from 'antd';
import { isEmpty, map } from 'lodash';
import React, { useContext, useEffect, useState } from 'react';
import { AppContext } from '../../../AppContext';
import { ReactComponent as FilterIcon } from '../../../assets/svg/sliders-solid.svg';
import {
  BREAKPOINTS,
  DATE_FORMATS,
  LIMIT,
  NOTIFICATION_STATUS,
  SORT,
  SORTING,
} from '../../../common/constants';
import { formatDate, hasNonEmptyValueObj } from '../../../common/utils';
import CommonTable from '../../../components/CommonTable';
import SearchComponent from '../../../components/SearchComponent';
import SortDropdown from '../../../components/SortDropdown';
import useInfiniteScroll from '../../../hooks/useInfiniteScroll';
import { MARK_NOTIFICATION_AS_READ } from '../graphql/Mutations';
import { LIBRARY_NOTIFICATIONS } from '../graphql/Queries';
import AllFilterModal from './AllFilterModal';
import NotificationList from './NotificationList';

const { RangePicker } = DatePicker;

const initialPaginationValue = {
  total: 0,
  current: 1,
  pageSize: 10,
};

const initialFilter = {
  limit: 10,
  search: '',
  skip: 0,
};

const initialSort = {
  field: 'createdAt',
  order: 'DESC',
};

const sort = [
  {
    label: SORTING?.TITLE_A_Z?.label,
    value: SORTING?.TITLE_A_Z?.value,
    field: SORTING?.TITLE_A_Z?.field,
    sort: SORTING?.TITLE_A_Z?.sort,
  },
  {
    label: SORTING?.TITLE_Z_A?.label,
    value: SORTING?.TITLE_Z_A?.value,
    field: SORTING?.TITLE_Z_A?.field,
    sort: SORTING?.TITLE_Z_A?.sort,
  },
];

const NotificationTable = () => {
  const {
    state: { isDesktop },
    dispatch,
  } = useContext(AppContext);
  const [notificationList, setNotificationList] = useState([]);
  const [notificationSearchTerm, setNotificationSearchTerm] = useState('');
  const [isEmptyNotificationList, setIsEmptyNotificationList] = useState(false);
  const [query, setQuery] = useState('');
  const [sortedInfo, setSortedInfo] = useState({});
  const [paginationProp, setPaginationProp] = useState(initialPaginationValue);

  const [notification, setNotification] = useState({});
  const [isMarkAsReadPrompts, setIsMarkAsReadPrompts] = useState(false);
  const [isMarkAsReadLoading, setIsMarkAsReadLoading] = useState(false);
  const [isAllMarkAsRead, setIsAllMarkAsRead] = useState(false);
  const [isAlreadyReadFilter, setIsAlreadyReadFilter] = useState(false);

  const [isNotificationLoading, setIsNotificationLoading] = useState(true);
  const [whereFilter, setWhereFilter] = useState({});
  const [filterTags, setFilterTags] = useState(null);
  const [filterVisible, setFilterVisible] = useState(false);
  const [finalWhereFilters, setFinalWhereFilters] = useState({});
  const [finalFilterTags, setFinalFilterTags] = useState(null);

  const [isAllFilterModalOpen, setIsAllFilterModalOpen] = useState(false);
  const [
    isFetchMoreNotificationLoading,
    setIsFetchMoreNotificationLoading,
  ] = useState(false);
  const [isActive, setIsActive] = useState(true);
  const [isAllSortPopoverOpen, setIsAllSortPopoverOpen] = useState(false);

  const [libraryNotifications] = useLazyQuery(LIBRARY_NOTIFICATIONS, {
    onCompleted: (response) => {
      if (isDesktop) {
        setNotificationList([...response?.libraryNotifications?.data]);
      } else {
        setNotificationList([
          ...notificationList,
          ...response?.libraryNotifications?.data,
        ]);
      }
      if (
        response?.libraryNotifications?.count === 0 &&
        initialPaginationValue?.total === 0
      ) {
        setIsEmptyNotificationList(true);
      } else {
        setIsEmptyNotificationList(false);
      }
      if (response?.libraryNotifications?.data?.length < LIMIT) {
        setIsActive(false);
      } else {
        setIsActive(true);
      }
      const pagination = {
        ...paginationProp,
        defaultPageSize: LIMIT,
        total: response?.libraryNotifications?.count,
      };
      if (!isAlreadyReadFilter) {
        dispatch({
          type: 'SET_NOTIFICATION_COUNT',
          data: response?.libraryNotifications?.count,
        });
      }
      setPaginationProp(pagination);
      setIsNotificationLoading(false);
      setIsFetchMoreNotificationLoading(false);
    },
    fetchPolicy: 'network-only',
    onError() {},
  });

  const [markNotificationAsRead] = useMutation(MARK_NOTIFICATION_AS_READ, {
    onError: () => {},
  });

  useEffect(() => {
    setPaginationProp({ ...paginationProp, current: 1, skip: 0 });
    setIsNotificationLoading(true);
    setNotificationList([]);
    libraryNotifications({
      variables: {
        filter: {
          ...initialFilter,
          limit: paginationProp?.pageSize || LIMIT,
          search: notificationSearchTerm,
          dateRange: {
            from: finalWhereFilters?.createdAt?.from?.$d,
            to: finalWhereFilters?.createdAt?.to?.$d,
          },
          hasRead: isAlreadyReadFilter,
        },
        sort: sortedInfo?.column
          ? {
              field: sortedInfo?.field,
              order: sortedInfo?.order === 'ascend' ? SORT?.ASC : SORT?.DESC,
            }
          : initialSort,
      },
    });
  }, [isAlreadyReadFilter, isDesktop]);

  const handleTableChange = (pagination, tableFilter, sorter) => {
    const { current } = pagination;
    const skip = (current - 1) * (pagination?.pageSize || 0);
    setPaginationProp({ ...paginationProp, ...pagination });
    setIsNotificationLoading(true);
    setSortedInfo(sorter);
    libraryNotifications({
      variables: {
        filter: {
          ...initialFilter,
          skip,
          limit: pagination?.pageSize,
          search: notificationSearchTerm,
          dateRange: {
            from: finalWhereFilters?.createdAt?.from?.$d,
            to: finalWhereFilters?.createdAt?.to?.$d,
          },
          hasRead: isAlreadyReadFilter,
        },
        sort: sorter?.column
          ? {
              field: sorter?.field,
              order: sorter?.order === 'ascend' ? SORT?.ASC : SORT?.DESC,
            }
          : initialSort,
      },
    });
  };

  const handleSearch = (value) => {
    const trimValue = value?.trim();
    setNotificationSearchTerm(trimValue);
    setPaginationProp({ ...paginationProp, current: 1, skip: 0 });
    setNotificationList([]);
    setIsNotificationLoading(true);
    libraryNotifications({
      variables: {
        filter: {
          ...initialFilter,
          limit: paginationProp?.pageSize || LIMIT,
          search: trimValue,
          dateRange: {
            from: finalWhereFilters?.createdAt?.from?.$d,
            to: finalWhereFilters?.createdAt?.to?.$d,
          },
          hasRead: isAlreadyReadFilter,
        },
        sort: sortedInfo?.column
          ? {
              field: sortedInfo?.field,
              order: sortedInfo?.order === 'ascend' ? SORT?.ASC : SORT?.DESC,
            }
          : initialSort,
      },
    });
  };

  const handleMarkAsRead = async (id) => {
    setIsMarkAsReadLoading(true);
    const notificationIds = notificationList?.map(
      (notificationData) => notificationData?.id,
    );
    const response = await markNotificationAsRead({
      variables: {
        where: {
          id: isAllMarkAsRead ? notificationIds : [id],
        },
        data: {
          hasRead: true,
        },
      },
    });
    if (response?.data) {
      setIsMarkAsReadLoading(false);
      setIsNotificationLoading(true);
      setIsMarkAsReadPrompts(false);
      setNotificationList([]);
      libraryNotifications({
        variables: {
          filter: {
            ...initialFilter,
            skip:
              (paginationProp?.current - 1) * (paginationProp?.pageSize || 0),
            limit: paginationProp?.pageSize || LIMIT,
            search: notificationSearchTerm,
            dateRange: {
              from: finalWhereFilters?.createdAt?.from?.$d,
              to: finalWhereFilters?.createdAt?.to?.$d,
            },
            hasRead: isAlreadyReadFilter,
          },
          sort: sortedInfo?.column
            ? {
                field: sortedInfo?.field,
                order: sortedInfo?.order === 'ascend' ? SORT?.ASC : SORT?.DESC,
              }
            : initialSort,
        },
      });
    }
    setIsMarkAsReadLoading(false);
  };

  const handleFilter = (values) => {
    setPaginationProp({ ...paginationProp, current: 1, skip: 0 });
    setIsNotificationLoading(true);
    setIsAllFilterModalOpen(false);
    setNotificationList([]);
    setIsActive(true);
    const createdAt = {
      from: values?.createdAt?.[0]?.startOf('day'),
      to: values?.createdAt?.[1]?.endOf('day'),
    };
    const filters = {
      createdAt: values?.createdAt?.[1] ? createdAt : null,
    };
    setWhereFilter(filters);
    setFinalWhereFilters(filters);
    libraryNotifications({
      variables: {
        filter: {
          ...initialFilter,
          limit: paginationProp?.pageSize || LIMIT,
          search: notificationSearchTerm,
          dateRange: createdAt,
          hasRead: isAlreadyReadFilter,
        },
        sort: sortedInfo?.column
          ? {
              field: sortedInfo?.field,
              order: sortedInfo?.order === 'ascend' ? SORT?.ASC : SORT?.DESC,
            }
          : initialSort,
      },
    });
  };

  const handleInvoicesScroll = () => {
    if (!isFetchMoreNotificationLoading && !isNotificationLoading) {
      const currentLength = notificationList?.length;
      setIsFetchMoreNotificationLoading(true);
      libraryNotifications({
        variables: {
          filter: {
            ...initialFilter,
            skip: currentLength,
            limit: paginationProp?.pageSize || LIMIT,
            search: notificationSearchTerm,
            dateRange: {
              from: finalWhereFilters?.createdAt?.from?.$d,
              to: finalWhereFilters?.createdAt?.to?.$d,
            },
            hasRead: isAlreadyReadFilter,
          },
          sort: sortedInfo?.column
            ? {
                field: sortedInfo?.field,
                order: sortedInfo?.order === 'ascend' ? SORT?.ASC : SORT?.DESC,
              }
            : initialSort,
        },
      });
    }
  };

  const handleSort = (values) => {
    setPaginationProp({ ...paginationProp, current: 1, skip: 0 });
    setIsNotificationLoading(true);
    setIsAllSortPopoverOpen(false);
    setIsActive(true);
    setNotificationList([]);
    const filteredSort = sort?.filter((item) => item?.value === values?.sort);
    if (filteredSort?.[0]?.field) {
      setSortedInfo({
        ...sortedInfo,
        field: filteredSort?.[0]?.field,
        columnKey: filteredSort?.[0]?.field,
        order: filteredSort?.[0]?.sort === SORT?.ASC ? 'ascend' : 'descend',
        column: filteredSort?.[0]?.field,
      });
    }
    libraryNotifications({
      variables: {
        filter: {
          ...initialFilter,
          limit: paginationProp?.pageSize || LIMIT,
          search: notificationSearchTerm,
          dateRange: {
            from: finalWhereFilters?.createdAt?.from?.$d,
            to: finalWhereFilters?.createdAt?.to?.$d,
          },
          hasRead: isAlreadyReadFilter,
        },
        sort: filteredSort?.[0]
          ? {
              field: filteredSort?.[0]?.field,
              order: filteredSort?.[0]?.sort,
            }
          : initialSort,
      },
    });
  };

  // eslint-disable-next-line react-hooks/rules-of-hooks, no-use-before-define
  const infiniteScrollRef = useInfiniteScroll(handleInvoicesScroll, isActive);

  const getFilterData = (confirm) => {
    setIsNotificationLoading(true);
    libraryNotifications({
      variables: {
        filter: {
          ...initialFilter,
          limit: paginationProp?.pageSize || LIMIT,
          search: notificationSearchTerm,
          dateRange: {
            from: whereFilter?.createdAt?.from?.$d,
            to: whereFilter?.createdAt?.to?.$d,
          },
          hasRead: isAlreadyReadFilter,
        },
        sort: sortedInfo?.column
          ? {
              field: sortedInfo?.field,
              order: sortedInfo?.order === 'ascend' ? SORT?.ASC : SORT?.DESC,
            }
          : initialSort,
      },
    });
    setFinalWhereFilters({ ...finalWhereFilters, ...whereFilter });
    setFinalFilterTags(filterTags);
    setPaginationProp({ ...paginationProp, skip: 0, current: 1 });
    if (confirm) {
      confirm();
    }
  };

  const handleReset = (clearFilters, dataIndex, confirm) => {
    const tagsCopy = {
      ...filterTags,
      [dataIndex]: [],
    };
    const whereFiltersCopy = {
      ...whereFilter,
      [dataIndex]: [],
    };
    setFilterVisible(false);
    setFinalFilterTags({ ...filterTags, ...tagsCopy });
    setFinalWhereFilters({ ...finalWhereFilters, ...whereFiltersCopy });
    setIsNotificationLoading(true);
    libraryNotifications({
      variables: {
        filter: {
          ...initialFilter,
          limit: paginationProp?.pageSize || LIMIT,
          search: notificationSearchTerm,
          dateRange: {
            from: whereFiltersCopy?.createdAt?.from?.$d,
            to: whereFiltersCopy?.createdAt?.to?.$d,
          },
          hasRead: isAlreadyReadFilter,
        },
        sort: sortedInfo?.column
          ? {
              field: sortedInfo?.field,
              order: sortedInfo?.order === 'ascend' ? SORT?.ASC : SORT?.DESC,
            }
          : initialSort,
      },
    });
    setPaginationProp({ ...paginationProp, skip: 0, current: 1 });
    clearFilters();
    setFilterVisible(false);
    if (confirm) {
      confirm();
    }
  };

  useEffect(() => {
    if (filterVisible) {
      setFilterTags({ ...filterTags, ...finalFilterTags });
      setWhereFilter({ ...whereFilter, ...finalWhereFilters });
      if (!filterVisible) {
        setFilterTags(finalFilterTags);
        setWhereFilter(finalWhereFilters);
      }
    }
  }, [filterVisible]);

  const onRangePickerChange = (values, dataIndex) => {
    const rangeObj = {
      from: values[0]?.startOf('day'),
      to: values?.[1]?.endOf('day'),
    };

    const filtersCopy = {
      ...whereFilter,
      [dataIndex]: rangeObj,
    };
    if (!values?.length) {
      delete whereFilter?.[dataIndex];
    }
    setWhereFilter(filtersCopy);
  };

  const filterPopup = (dataIndex) => ({
    filterDropdown: ({ confirm, clearFilters }) => {
      if (dataIndex === 'createdAt') {
        return (
          <div className="custom-filter-dropdown">
            <RangePicker
              format={DATE_FORMATS?.REGULAR}
              allowClear={false}
              onChange={(values) => onRangePickerChange(values, dataIndex)}
              value={
                whereFilter?.[dataIndex]?.from
                  ? [
                      whereFilter?.[dataIndex]?.from,
                      whereFilter?.[dataIndex]?.to,
                    ]
                  : null
              }
            />
            <Divider className="divider-filter" />
            <div className="d-flex justify-center">
              <Button
                size="small"
                className="reset-button"
                id="roles-filter-reset"
                onClick={() => handleReset(clearFilters, dataIndex, confirm)}
              >
                Reset
              </Button>
              <Button
                size="small"
                className="common-button ok-button"
                id="roles-filter-ok"
                type="primary"
                onClick={() => getFilterData(confirm, dataIndex)}
              >
                Ok
              </Button>
            </div>
          </div>
        );
      }
    },
    filterIcon: () =>
      finalWhereFilters?.[dataIndex]?.length > 0 ||
      !isEmpty(finalWhereFilters?.[dataIndex]) ? (
        <FilterFilled className="filter-icon filter-filled" />
      ) : (
        <FilterOutlined className="filter-icon" />
      ),
    onFilterDropdownOpenChange: (visible) => {
      setFilterVisible(visible);
    },
  });

  const columns = [
    {
      title: 'TITLE',
      dataIndex: 'title',
      key: 'title',
      ellipsis: true,
      sorter: true,
      width: 150,
      align: 'left',
      className: 'max-width-column pointer',
      // eslint-disable-next-line no-undef
      fixed: window.innerWidth > BREAKPOINTS.desktop ? 'left' : false,
      sortOrder: sortedInfo?.columnKey === 'title' && sortedInfo?.order,
      render: (_, record) => record?.title || '-',
    },
    {
      title: 'DESCRIPTION',
      dataIndex: 'description',
      key: 'description',
      ellipsis: true,
      width: 150,
      align: 'left',
      className: 'max-width-column table-tooltip-cell',
      render: (_, record) => (
        <Tooltip
          title={record?.description ? record?.description : 'No Description'}
          placement="topLeft"
          trigger="click"
          className="pointer"
        >
          {record?.description || '-'}
        </Tooltip>
      ),
    },

    {
      title: 'RECEIVED ON',
      dataIndex: 'createdAt',
      key: 'createdAt',
      ellipsis: true,
      width: 150,
      align: 'left',
      className: 'max-width-column',
      render: (_, record) =>
        formatDate(record?.createdAt, DATE_FORMATS?.REGULAR) || '-',
      ...filterPopup('createdAt'),
    },
    {
      title: 'STATUS',
      dataIndex: 'status',
      key: 'status',
      ellipsis: true,
      width: 150,
      align: 'left',
      className: 'max-width-column',
      render: (_, record) =>
        record?.readBy?.length > 0
          ? NOTIFICATION_STATUS?.READ
          : NOTIFICATION_STATUS?.UNREAD,
    },
    {
      title: 'ACTIONS',
      dataIndex: 'actions',
      key: 'actions',
      ellipsis: true,
      width: 100,
      // eslint-disable-next-line no-undef
      fixed: window.innerWidth > BREAKPOINTS.desktop ? 'right' : false,
      render: (_, record) => (
        <>
          <div className="action-button">
            <Button
              type="primary"
              size="small"
              className="table-action-primary-btn"
              onClick={() => {
                setIsMarkAsReadPrompts(true);
                setNotification(record);
              }}
              disabled={
                Array?.isArray(record?.readBy) && record?.readBy?.length > 0
              }
            >
              Mark as read
            </Button>
          </div>
        </>
      ),
    },
  ]?.filter((item) => {
    if (item?.dataIndex === 'actions' && isAlreadyReadFilter) {
      return item?.hidden;
    }
    return !item?.hidden;
  });

  const locale = {
    emptyText: isEmptyNotificationList ? '' : <span />,
  };

  return (
    <>
      <Modal
        title="Caution"
        okText="Yes"
        cancelText="No"
        open={isMarkAsReadPrompts}
        onOk={() => handleMarkAsRead(notification?.id)}
        onCancel={() => {
          setIsMarkAsReadPrompts(false);
          setIsAllMarkAsRead(false);
        }}
        okButtonProps={{ loading: isMarkAsReadLoading }}
      >
        Are you sure you want to
        <strong> mark </strong>
        {isAllMarkAsRead ? 'all' : 'this'} notification as read?
      </Modal>
      <div className="d-flex justify-end mb-16 flex-wrap">
        <div className={`d-flex ${!isDesktop ? 'mb-16' : ''}`}>
          <div className="mr-16 d-flex align-center">
            <h4 className="m-0 mr-8">Already Read</h4>{' '}
            <Switch
              size="small"
              onChange={(checked) => setIsAlreadyReadFilter(checked)}
            />
          </div>
          <div className="d-flex align-center">
            <Button
              type="primary"
              className={`add-toy-btn ${isDesktop ? 'mr-16' : ''}`}
              onClick={() => {
                setIsAllMarkAsRead(true);
                setIsMarkAsReadPrompts(true);
              }}
              disabled={notificationList?.length === 0 || isAlreadyReadFilter}
            >
              Mark all as read
            </Button>
          </div>
        </div>
        <div
          className={`d-flex align-center ${!isDesktop ? 'full-width' : ''}`}
        >
          <SearchComponent
            query={query}
            setQuery={setQuery}
            getData={handleSearch}
          />
          {!isDesktop && (
            <>
              <Badge dot={hasNonEmptyValueObj(finalWhereFilters)} size="small">
                <FilterIcon
                  className="mobile-svg-icon ml-16"
                  onClick={() => setIsAllFilterModalOpen(true)}
                />
              </Badge>
              <div className="ml-16 d-flex align-center">
                <SortDropdown
                  isOpen={isAllSortPopoverOpen}
                  setIsOpen={setIsAllSortPopoverOpen}
                  options={map(sort, (item) => item)}
                  sortedInfo={sortedInfo}
                  setSortedInfo={setSortedInfo}
                  onFinish={handleSort}
                />
              </div>
            </>
          )}
        </div>
      </div>
      {isDesktop ? (
        <CommonTable
          locale={locale}
          columns={columns}
          data={notificationList || []}
          loading={isNotificationLoading}
          onChange={handleTableChange}
          paginationConfig={paginationProp}
          rowKey={(record) => record?.id}
        />
      ) : (
        <div className="mobile-container-action no-scroll-bar">
          <AllFilterModal
            onFinish={handleFilter}
            isModalOpen={isAllFilterModalOpen}
            setIsModalOpen={setIsAllFilterModalOpen}
            finalWhereFilters={finalWhereFilters}
            setFinalWhereFilters={setFinalWhereFilters}
          />
          <NotificationList
            notificationList={notificationList}
            infiniteScrollRef={infiniteScrollRef}
            isFetchMoreNotificationLoading={isFetchMoreNotificationLoading}
            setIsMarkAsReadPrompts={setIsMarkAsReadPrompts}
            setNotification={setNotification}
            isEmptyNotificationList={isEmptyNotificationList}
            isNotificationLoading={isNotificationLoading}
            isAlreadyReadFilter={isAlreadyReadFilter}
          />
        </div>
      )}
    </>
  );
};

export default NotificationTable;
