import { gql } from '@apollo/client';

// eslint-disable-next-line import/prefer-default-export
export const CREATE_INVOICE = gql`
  mutation CreateInvoice($data: CreateInvoiceInput!) {
    createInvoice(data: $data) {
      message
    }
  }
`;

export const UPDATE_INVOICE = gql`
  mutation UpdateInvoice(
    $where: InvoiceUniqueInput!
    $data: UpdateInvoiceInput!
  ) {
    updateInvoice(where: $where, data: $data) {
      message
    }
  }
`;
