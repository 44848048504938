import { Col, Form, Input, Row } from 'antd';
import React, { useEffect, useState } from 'react';
import { GENDERS } from '../../../../../common/constants';
import { formValidatorRules } from '../../../../../common/utils';
import CitySelect from '../../../../../components/CitySelect';
import CountrySelect from '../../../../../components/CountrySelect';
import PincodeSelectName from '../../../../../components/PincodeSelectName';
import SelectComponent from '../../../../../components/SelectComponent';
import StateSelect from '../../../../../components/StateSelect';

const { Item } = Form;
const {
  required,
  email,
  name,
  phone,
  whitespaceNotAllowed,
} = formValidatorRules;

const DeliveryForm = ({
  form,
  onFinish,
  handleShowPrompt,
  initialValues,
  isView,
  deliveryDetail,
}) => {
  const [validationTriggered, setValidationTriggered] = useState(false);
  const [countryId, setCountryId] = useState('');
  const [stateId, setStateId] = useState(initialValues?.state);
  const [isStateIdChanged, setIsStateIdChanged] = useState(false);

  useEffect(() => {
    const initialValueFun = () => {
      form?.setFieldsValue({
        ...initialValues,
        country: countryId,
      });
    };
    setTimeout(initialValueFun, 300);
  }, [countryId]);

  const onFinishFailed = () => {
    setValidationTriggered(true);
  };

  return (
    <Form
      layout="vertical"
      form={form}
      onFinish={onFinish}
      onFieldsChange={handleShowPrompt}
      disabled={!!isView || deliveryDetail?.isArchived}
      onFinishFailed={onFinishFailed}
      className={isView ? 'common-view-form' : 'form'}
      validateTrigger={validationTriggered ? 'onChange' : 'onSubmit'}
    >
      <Row gutter={[16, 0]}>
        <Col xs={24} sm={12} md={12} lg={8} xl={8} xxl={8}>
          <Item
            label="First Name"
            name="firstName"
            rules={[{ ...required, message: 'Please Enter First Name!' }, name]}
          >
            <Input allowClear placeholder="Enter First Name" maxLength={50} />
          </Item>
        </Col>
        <Col xs={24} sm={12} md={12} lg={8} xl={8} xxl={8}>
          <Item
            label="Last Name"
            name="lastName"
            rules={[{ ...required, message: 'Please Enter Last Name!' }, name]}
          >
            <Input allowClear placeholder="Enter Last Name" maxLength={50} />
          </Item>
        </Col>
        <Col xs={24} sm={12} md={12} lg={8} xl={8} xxl={8}>
          <Item
            label="Email ID"
            name="email"
            rules={[
              {
                ...required,
                message: 'Please Enter Email!',
              },
              email,
            ]}
          >
            <Input allowClear placeholder="Enter Email" maxLength={255} />
          </Item>
        </Col>
        <Col xs={24} sm={12} md={12} lg={8} xl={8} xxl={8}>
          <Item
            label="Gender"
            name="gender"
            rules={[
              {
                required: true,
                message: 'Please Select Gender!',
              },
            ]}
          >
            <SelectComponent placeholder="Select Gender" options={GENDERS} />
          </Item>
        </Col>
        <Col xs={24} sm={12} md={12} lg={8} xl={8} xxl={8}>
          <Item
            label="Contact Details"
            name="contact"
            rules={[
              {
                ...required,
                message: 'Please Enter Contact Details!',
              },
              phone,
            ]}
          >
            <Input
              allowClear
              placeholder="Enter Contact Details"
              maxLength={12}
            />
          </Item>
        </Col>
        <Col xs={24} sm={12} md={12} lg={8} xl={8} xxl={8}>
          <Item
            label="Address"
            name="address"
            rules={[
              {
                ...required,
                message: 'Please Enter Address!',
              },
            ]}
          >
            <Input allowClear placeholder="Enter Address!" maxLength={250} />
          </Item>
        </Col>
        <Col xs={24} sm={12} md={12} lg={6} xl={6} xxl={6}>
          <Form.Item
            label="Country"
            name="country"
            rules={[{ required: true, message: 'Please Select Country!' }]}
          >
            <CountrySelect setCountryId={setCountryId} />
          </Form.Item>
        </Col>
        <Col xs={24} sm={12} md={12} lg={6} xl={6} xxl={6}>
          <Form.Item
            label="State"
            name="state"
            rules={[{ required: true, message: 'Please Select State!' }]}
          >
            <StateSelect
              countryId={countryId}
              setStateId={setStateId}
              userData={deliveryDetail}
              onChange={(value) => {
                form?.setFieldsValue({
                  city: null,
                });
                setStateId(value);
                setIsStateIdChanged(true);
              }}
              isView={isView}
            />
          </Form.Item>
        </Col>
        <Col xs={24} sm={12} md={12} lg={6} xl={6} xxl={6}>
          <Form.Item
            label="City"
            name="city"
            rules={[{ required: true, message: 'Please Select City!' }]}
          >
            <CitySelect
              stateId={stateId}
              userData={deliveryDetail}
              isView={isView}
              isStateIdChanged={isStateIdChanged}
            />
          </Form.Item>
        </Col>
        <Col xs={24} sm={12} md={12} lg={6} xl={6} xxl={6}>
          <Form.Item
            label="Pincode"
            name="pincode"
            rules={[{ required: true, message: 'Please Select Pincode!' }]}
          >
            <PincodeSelectName userData={deliveryDetail} />
          </Form.Item>
        </Col>
        <Col xs={24} sm={12} md={12} lg={12} xl={12} xxl={12}>
          <Item
            label="Agency Name"
            name="agencyName"
            rules={[whitespaceNotAllowed]}
          >
            <Input allowClear placeholder="Enter Agency Name" maxLength={100} />
          </Item>
        </Col>
        <Col xs={24} sm={12} md={12} lg={12} xl={12} xxl={12}>
          <Item
            label="Vehicle Number"
            name="vehicleNumber"
            rules={[whitespaceNotAllowed]}
          >
            <Input
              allowClear
              placeholder="Enter Vehicle Number"
              maxLength={100}
            />
          </Item>
        </Col>
      </Row>
    </Form>
  );
};

export default DeliveryForm;
