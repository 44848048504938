import { FilterFilled, FilterOutlined } from '@ant-design/icons';
import { useLazyQuery, useMutation } from '@apollo/client';
import {
  Badge,
  Button,
  Checkbox,
  DatePicker,
  Divider,
  Empty,
  Form,
  Modal,
  Switch,
  Tag,
  Tooltip,
} from 'antd';
import { debounce, filter, forEach, isEmpty, map } from 'lodash';
import { React, useContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { AppContext } from '../../../../../AppContext';
import { ReactComponent as RejectIcon } from '../../../../../assets/svg/circle-xmark-solid.svg';
import { ReactComponent as CommentIcon } from '../../../../../assets/svg/comment-dots-solid.svg';
import {
  BREAKPOINTS,
  DATE_FORMATS,
  LIMIT,
  PAYMENT_TYPE,
  PERMISSIONS_KEY,
  PERMISSION_TYPE,
  REQUEST_ENCASHMENT_STATUS,
  REQUEST_STATUS,
  REQUEST_TYPES,
  ROUTES,
  SORT,
  STATUS_REQUEST_ENCASHMENT_FILTER,
  USER_ROLES,
} from '../../../../../common/constants';
import { formatDate, hasPermission } from '../../../../../common/utils';
import CommonPreviewTable from '../../../../../components/CommonPreviewTable';
import CommonTable from '../../../../../components/CommonTable';
import LoaderComponent from '../../../../../components/LoaderComponent';
import SearchComponent from '../../../../../components/SearchComponent';
import { UPDATE_REQUEST_ADMIN } from '../../../graphql/Mutations';
import { USERS } from '../../../graphql/Queries';
import ResolveCommentModal from '../../library/components/ResolveCommentModal';
import {
  LIBRARIES,
  LIBRARY_ENCASHMENT_REQUESTS_ADMIN,
  TOYS,
} from '../../library/graphql/Queries';

const { RangePicker } = DatePicker;

const initialPaginationValue = {
  total: 0,
  current: 1,
  pageSize: 10,
};

const initialDamageFilter = {
  limit: 10,
  search: '',
  skip: 0,
};

const initialDamageSort = {
  field: 'createdAt',
  order: 'DESC',
};

const initialTableFilter = {
  skip: 0,
  limit: LIMIT,
  search: '',
};

const initialTableSort = {
  field: 'createdAt',
  order: 'DESC',
};

const roleFilter = [USER_ROLES?.DELIVERY?.key];
let filterScrollDebounceJob = null;

const EncashmentTable = () => {
  const {
    state: {
      permissions,
      commonPermissions: { isUserPermission },
    },
  } = useContext(AppContext);
  const history = useHistory();
  const [form] = Form.useForm();
  const [encashmentList, setEncashmentList] = useState([]);
  const [encashmentSearchTerm, setEncashmentSearchTerm] = useState('');
  const [isEmptyEncashmentList, setIsEmptyEncashmentList] = useState(false);
  const [encashment, setEncashment] = useState({});
  const [query, setQuery] = useState('');
  const [sortedInfo, setSortedInfo] = useState({});
  const [paginationProp, setPaginationProp] = useState(initialPaginationValue);

  const [visible, setVisible] = useState(false);
  const [toyImages, setToyImages] = useState([]);
  const [isCommentLoading, setIsCommentLoading] = useState(false);
  const [isCommentPrompts, setIsCommentPrompts] = useState(false);
  const [isRejectLoading, setIsRejectLoading] = useState(false);
  const [isRejectPrompts, setIsRejectPrompts] = useState(false);

  const [isEncashmentLoading, setIsEncashmentLoading] = useState(true);
  const [isArchiveFilter, setIsArchiveFilter] = useState(false);
  const [filterSearch, setFilterSearch] = useState('');
  const [filterList, setFilterList] = useState([]);
  const [whereFilter, setWhereFilter] = useState();
  const [filterLoading, setFilterLoading] = useState(false);
  const [filterTags, setFilterTags] = useState(null);
  const [filterVisible, setFilterVisible] = useState(false);
  const [filterIndex, setFilterIndex] = useState(null);
  const [finalWhereFilters, setFinalWhereFilters] = useState();
  const [finalFilterTags, setFinalFilterTags] = useState(null);
  const [filterIsEnd, setFilterIsEnd] = useState(false);
  const [isEmptyFilterList, setIsEmptyFilterList] = useState(false);

  const [requestsAdmin] = useLazyQuery(LIBRARY_ENCASHMENT_REQUESTS_ADMIN, {
    onCompleted: (response) => {
      setEncashmentList([...response?.requestsAdmin?.data]);
      if (
        response?.requestsAdmin?.count === 0 &&
        initialPaginationValue?.total === 0
      ) {
        setIsEmptyEncashmentList(true);
      } else {
        setIsEmptyEncashmentList(false);
      }
      const pagination = {
        ...paginationProp,
        defaultPageSize: LIMIT,
        total: response?.requestsAdmin?.count,
      };
      setPaginationProp(pagination);
      setIsEncashmentLoading(false);
    },
    fetchPolicy: 'network-only',
    onError() {},
  });

  const [libraries, { loading: isLibrariesLoading }] = useLazyQuery(LIBRARIES, {
    onCompleted: (response) => {
      if (response?.libraries?.data?.length < LIMIT) {
        setFilterIsEnd(true);
      }
      if (response?.libraries?.count === 0) {
        setIsEmptyFilterList(true);
      } else {
        setIsEmptyFilterList(false);
      }
      const optionsCopy = [];
      forEach(response?.libraries?.data, (item) =>
        optionsCopy?.push({ label: item?.name, key: item?.id }),
      );
      setFilterList([...filterList, ...optionsCopy]);
      setFilterLoading(false);
    },
    fetchPolicy: 'network-only',
    onError() {
      setFilterLoading(false);
    },
  });

  const [users, { loading: isUsersLoading }] = useLazyQuery(USERS, {
    onCompleted: (response) => {
      if (response?.users?.data?.length < LIMIT) {
        setFilterIsEnd(true);
      }
      if (response?.users?.count === 0) {
        setIsEmptyFilterList(true);
      } else {
        setIsEmptyFilterList(false);
      }
      const optionsCopy = [];
      forEach(response?.users?.data, (item) =>
        optionsCopy?.push({
          label: `${item?.firstName} ${item?.lastName}`,
          key: item?.id,
        }),
      );
      setFilterList([...filterList, ...optionsCopy]);
      setFilterLoading(false);
    },
    fetchPolicy: 'network-only',
    onError() {
      setFilterLoading(false);
    },
  });

  const [toys, { loading: isToysLoading }] = useLazyQuery(TOYS, {
    onCompleted: (response) => {
      if (response?.toys?.data?.length < LIMIT) {
        setFilterIsEnd(true);
      }
      if (response?.toys?.count === 0) {
        setIsEmptyFilterList(true);
      } else {
        setIsEmptyFilterList(false);
      }
      const optionsCopy = [];
      forEach(response?.toys?.data, (item) =>
        optionsCopy?.push({ label: item?.name, key: item?.id }),
      );
      setFilterList([...filterList, ...optionsCopy]);
      setFilterLoading(false);
    },
    fetchPolicy: 'network-only',
    onError() {
      setFilterLoading(false);
    },
  });

  const [updateRequestAdmin] = useMutation(UPDATE_REQUEST_ADMIN, {
    onError: () => {},
  });

  const hasUpdatePermission = hasPermission(
    PERMISSIONS_KEY?.REQUESTS_END_USER_ENCASHMENT,
    PERMISSION_TYPE?.UPDATE,
    permissions,
  );

  useEffect(() => {
    setPaginationProp({ ...paginationProp, current: 1, skip: 0 });
    setIsEncashmentLoading(true);
    requestsAdmin({
      variables: {
        filter: {
          ...initialDamageFilter,
          limit: paginationProp?.pageSize || LIMIT,
          search: encashmentSearchTerm,
          isArchived: isArchiveFilter,
          type: REQUEST_TYPES?.ENCASHMENT,
          dateRange: {
            from: finalWhereFilters?.createdAt?.from?.$d,
            to: finalWhereFilters?.createdAt?.to?.$d,
          },
          status:
            finalWhereFilters?.status?.length > 0
              ? finalWhereFilters?.status
              : undefined,
          libraryIds: finalWhereFilters?.name,
          deliveryPartnerIds: finalWhereFilters?.deliveryPartnerName,
          productIds: finalWhereFilters?.productName,
        },
        sort: sortedInfo?.column
          ? {
              field: sortedInfo?.field,
              order: sortedInfo?.order === 'ascend' ? SORT?.ASC : SORT?.DESC,
            }
          : initialDamageSort,
      },
    });
  }, [isArchiveFilter]);

  const handleTableChange = (pagination, tableFilter, sorter) => {
    const { current } = pagination;
    const skip = (current - 1) * (pagination?.pageSize || 0);
    setPaginationProp({ ...paginationProp, ...pagination });
    setIsEncashmentLoading(true);
    setSortedInfo(sorter);
    requestsAdmin({
      variables: {
        filter: {
          ...initialDamageFilter,
          skip,
          limit: pagination?.pageSize,
          search: encashmentSearchTerm,
          isArchived: isArchiveFilter,
          type: REQUEST_TYPES?.ENCASHMENT,
          dateRange: {
            from: finalWhereFilters?.createdAt?.from?.$d,
            to: finalWhereFilters?.createdAt?.to?.$d,
          },
          status:
            finalWhereFilters?.status?.length > 0
              ? finalWhereFilters?.status
              : undefined,
          libraryIds: finalWhereFilters?.name,
          deliveryPartnerIds: finalWhereFilters?.deliveryPartnerName,
          productIds: finalWhereFilters?.productName,
        },
        sort: sorter?.column
          ? {
              field: sorter?.field,
              order: sorter?.order === 'ascend' ? SORT?.ASC : SORT?.DESC,
            }
          : initialDamageSort,
      },
    });
  };

  const handleSearch = (value) => {
    const trimValue = value?.trim();
    setEncashmentSearchTerm(trimValue);
    setPaginationProp({ ...paginationProp, current: 1, skip: 0 });
    setIsEncashmentLoading(true);
    requestsAdmin({
      variables: {
        filter: {
          ...initialDamageFilter,
          limit: paginationProp?.pageSize || LIMIT,
          search: trimValue,
          isArchived: isArchiveFilter,
          type: REQUEST_TYPES?.ENCASHMENT,
          dateRange: {
            from: finalWhereFilters?.createdAt?.from?.$d,
            to: finalWhereFilters?.createdAt?.to?.$d,
          },
          status:
            finalWhereFilters?.status?.length > 0
              ? finalWhereFilters?.status
              : undefined,
          libraryIds: finalWhereFilters?.name,
          deliveryPartnerIds: finalWhereFilters?.deliveryPartnerName,
          productIds: finalWhereFilters?.productName,
        },
        sort: sortedInfo?.column
          ? {
              field: sortedInfo?.field,
              order: sortedInfo?.order === 'ascend' ? SORT?.ASC : SORT?.DESC,
            }
          : initialDamageSort,
      },
    });
  };

  const handleAddComment = async (values) => {
    setIsCommentLoading(true);
    const response = await updateRequestAdmin({
      variables: {
        where: {
          id: encashment?.id,
        },
        data: {
          encashmentRequest: {
            comment: values?.comment?.trim() || '',
          },
          status: REQUEST_STATUS?.APPROVED?.value,
        },
      },
    });
    if (response?.data) {
      setIsEncashmentLoading(true);
      setIsCommentPrompts(false);
      form?.resetFields();
      requestsAdmin({
        variables: {
          filter: {
            ...initialDamageFilter,
            skip:
              (paginationProp?.current - 1) * (paginationProp?.pageSize || 0),
            limit: paginationProp?.pageSize || LIMIT,
            search: encashmentSearchTerm,
            type: REQUEST_TYPES?.ENCASHMENT,
            dateRange: {
              from: finalWhereFilters?.createdAt?.from?.$d,
              to: finalWhereFilters?.createdAt?.to?.$d,
            },
            status:
              finalWhereFilters?.status?.length > 0
                ? finalWhereFilters?.status
                : undefined,
            libraryIds: finalWhereFilters?.name,
            deliveryPartnerIds: finalWhereFilters?.deliveryPartnerName,
            productIds: finalWhereFilters?.productName,
          },
          sort: sortedInfo?.column
            ? {
                field: sortedInfo?.field,
                order: sortedInfo?.order === 'ascend' ? SORT?.ASC : SORT?.DESC,
              }
            : initialDamageSort,
        },
      });
    }
    setIsCommentLoading(false);
  };

  const getFilterData = (confirm) => {
    setIsEncashmentLoading(true);
    requestsAdmin({
      variables: {
        filter: {
          ...initialDamageFilter,
          limit: paginationProp?.pageSize || LIMIT,
          search: encashmentSearchTerm,
          isArchived: isArchiveFilter,
          type: REQUEST_TYPES?.ENCASHMENT,
          dateRange: {
            from: whereFilter?.createdAt?.from?.$d,
            to: whereFilter?.createdAt?.to?.$d,
          },
          status:
            whereFilter?.status?.length > 0 ? whereFilter?.status : undefined,
          libraryIds: whereFilter?.name,
          deliveryPartnerIds: whereFilter?.deliveryPartnerName,
          productIds: whereFilter?.productName,
        },
        sort: sortedInfo?.column
          ? {
              field: sortedInfo?.field,
              order: sortedInfo?.order === 'ascend' ? SORT?.ASC : SORT?.DESC,
            }
          : initialDamageSort,
      },
    });
    setFinalWhereFilters({ ...finalWhereFilters, ...whereFilter });
    setFinalFilterTags(filterTags);
    setPaginationProp({ ...paginationProp, skip: 0, current: 1 });
    if (confirm) {
      confirm();
    }
  };

  const handleReset = (clearFilters, dataIndex, confirm) => {
    const tagsCopy = {
      ...filterTags,
      [dataIndex]: [],
    };
    const whereFiltersCopy = {
      ...whereFilter,
      [dataIndex]: [],
    };
    setFilterVisible(false);
    setFinalFilterTags({ ...filterTags, ...tagsCopy });
    setFinalWhereFilters({ ...finalWhereFilters, ...whereFiltersCopy });
    setIsEncashmentLoading(true);
    requestsAdmin({
      variables: {
        filter: {
          ...initialDamageFilter,
          limit: paginationProp?.pageSize || LIMIT,
          search: encashmentSearchTerm,
          isArchived: isArchiveFilter,
          type: REQUEST_TYPES?.ENCASHMENT,
          dateRange: {
            from: whereFiltersCopy?.createdAt?.from?.$d,
            to: whereFiltersCopy?.createdAt?.to?.$d,
          },
          status:
            whereFiltersCopy?.status?.length > 0
              ? whereFiltersCopy?.status
              : undefined,
          libraryIds: whereFiltersCopy?.name,
          deliveryPartnerIds: whereFiltersCopy?.deliveryPartnerName,
          productIds: whereFiltersCopy?.productName,
        },
        sort: sortedInfo?.column
          ? {
              field: sortedInfo?.field,
              order: sortedInfo?.order === 'ascend' ? SORT?.ASC : SORT?.DESC,
            }
          : initialDamageSort,
      },
    });
    setPaginationProp({ ...paginationProp, skip: 0, current: 1 });
    clearFilters();
    setFilterVisible(false);
    if (confirm) {
      confirm();
    }
  };

  const handleReject = async (id) => {
    setIsRejectLoading(true);
    const response = await updateRequestAdmin({
      variables: {
        where: {
          id,
        },
        data: {
          status: REQUEST_STATUS?.REJECTED?.value,
        },
      },
    });
    if (response?.data) {
      setIsEncashmentLoading(true);
      setIsRejectPrompts(false);
      requestsAdmin({
        variables: {
          filter: {
            ...initialDamageFilter,
            skip:
              (paginationProp?.current - 1) * (paginationProp?.pageSize || 0),
            limit: paginationProp?.pageSize || LIMIT,
            search: encashmentSearchTerm,
            type: REQUEST_TYPES?.ENCASHMENT,
            dateRange: {
              from: finalWhereFilters?.createdAt?.from?.$d,
              to: finalWhereFilters?.createdAt?.to?.$d,
            },
            status:
              finalWhereFilters?.status?.length > 0
                ? finalWhereFilters?.status
                : undefined,
            libraryIds: finalWhereFilters?.name,
            deliveryPartnerIds: finalWhereFilters?.deliveryPartnerName,
            productIds: finalWhereFilters?.productName,
          },
          sort: sortedInfo?.column
            ? {
                field: sortedInfo?.field,
                order: sortedInfo?.order === 'ascend' ? SORT?.ASC : SORT?.DESC,
              }
            : initialDamageSort,
        },
      });
    }
    setIsRejectLoading(false);
  };

  useEffect(() => {
    if (filterVisible) {
      setFilterList([]);
      setFilterLoading(true);
      setIsEmptyFilterList(false);
      setFilterTags({ ...filterTags, ...finalFilterTags });
      setWhereFilter({ ...whereFilter, ...finalWhereFilters });
      setFilterIsEnd(false);
      const optionsCopy = [];

      switch (filterIndex) {
        case 'status':
          forEach(STATUS_REQUEST_ENCASHMENT_FILTER, (item) => {
            optionsCopy?.push({ label: item?.label, key: item?.key });
          });
          setFilterList(optionsCopy);
          setFilterLoading(false);
          break;

        case 'name':
          libraries({
            variables: {
              filter: initialTableFilter,
              sort: initialTableSort,
            },
          });
          break;

        case 'productName':
          toys({
            variables: {
              filter: initialTableFilter,
              sort: initialTableSort,
            },
          });
          break;

        case 'deliveryPartnerName':
          users({
            variables: {
              filter: {
                ...initialTableFilter,
                roles: roleFilter,
              },
              sort: initialTableSort,
            },
          });
          break;

        default:
          break;
      }
    }
    if (!filterVisible) {
      setFilterTags(finalFilterTags);
      setWhereFilter(finalWhereFilters);
      setFilterSearch('');
    }
  }, [filterVisible]);

  const onFilterScroll = (event, dataIndex) => {
    if (filterScrollDebounceJob) {
      filterScrollDebounceJob?.cancel();
      filterScrollDebounceJob = null;
    }
    if (
      !isLibrariesLoading &&
      !isUsersLoading &&
      !isToysLoading &&
      !filterIsEnd
    ) {
      const target = event?.target;
      const currentLength = filterList?.length;
      if (
        target?.scrollTop + target?.offsetHeight >=
        target?.scrollHeight - 5
      ) {
        filterScrollDebounceJob = debounce(() => {
          setFilterLoading(true);
          switch (dataIndex) {
            case 'name':
              libraries({
                variables: {
                  filter: {
                    limit: LIMIT,
                    skip: currentLength,
                    search: filterSearch,
                  },
                  sort: initialTableSort,
                },
              });
              break;

            case 'productName':
              toys({
                variables: {
                  filter: {
                    limit: LIMIT,
                    skip: currentLength,
                    search: filterSearch,
                  },
                  sort: initialTableSort,
                },
              });
              break;

            case 'deliveryPartnerName':
              users({
                variables: {
                  filter: {
                    limit: LIMIT,
                    skip: currentLength,
                    search: filterSearch,
                    roles: roleFilter,
                  },
                  sort: initialTableSort,
                },
              });
              break;

            default:
              break;
          }
        }, 500);
        filterScrollDebounceJob();
      }
    }
  };

  const handleFilterSearch = (value, dataIndex) => {
    setFilterSearch(value);
    setFilterIsEnd(false);
    setFilterLoading(true);
    setFilterList([]);
    switch (dataIndex) {
      case 'name':
        libraries({
          variables: {
            filter: {
              ...initialTableFilter,
              limit: LIMIT,
              search: value,
            },
            sort: initialTableSort,
          },
        });
        break;

      case 'productName':
        toys({
          variables: {
            filter: {
              ...initialTableFilter,
              limit: LIMIT,
              search: value,
            },
            sort: initialTableSort,
          },
        });
        break;

      case 'deliveryPartnerName':
        users({
          variables: {
            filter: {
              ...initialTableFilter,
              limit: LIMIT,
              search: value,
              roles: roleFilter,
            },
            sort: initialTableSort,
          },
        });
        break;

      default:
        break;
    }
  };

  const changeFilter = (newFilterObj, dataIndex) => {
    const {
      target: { value },
    } = newFilterObj;

    const filterTagsCopy = { ...filterTags };
    let newTagList;
    if (whereFilter?.[dataIndex]?.includes(value?.key)) {
      newTagList = {
        ...filterTagsCopy,
        [dataIndex]: filter(filterTagsCopy?.[dataIndex], (item) => {
          if (item?.label) {
            return item?.key !== value?.key;
          }
          return item !== value;
        }),
      };
    } else {
      newTagList = {
        ...filterTagsCopy,
        [dataIndex]: filterTagsCopy?.[dataIndex]
          ? [...filterTagsCopy?.[dataIndex], value]
          : [value],
      };
    }

    const updatedWhereFilter = {
      ...whereFilter,
      [dataIndex]: newTagList?.[dataIndex]?.map((item) => item?.key),
    };
    setWhereFilter(updatedWhereFilter);
    setFilterTags(newTagList);
  };

  const handleDeleteFilter = (value, dataIndex) => {
    let newTagList;

    if (whereFilter?.[dataIndex]?.includes(value?.key)) {
      newTagList = {
        ...filterTags,
        [dataIndex]: filter(filterTags?.[dataIndex], (item) => {
          if (item?.key) {
            return item?.key !== value?.key;
          }
          return item !== value;
        }),
      };
    }

    const updatedWhereFilter = {
      ...whereFilter,
      [dataIndex]: newTagList?.[dataIndex]?.map((item) => item?.key),
    };
    setWhereFilter(updatedWhereFilter);
    setFilterTags(newTagList);
  };

  const onRangePickerChange = (values, dataIndex) => {
    const rangeObj = {
      from: values[0]?.startOf('day'),
      to: values?.[1]?.endOf('day'),
    };

    const filtersCopy = {
      ...whereFilter,
      [dataIndex]: rangeObj,
    };
    if (!values?.length) {
      delete whereFilter?.[dataIndex];
    }
    setWhereFilter(filtersCopy);
  };

  const filterPopup = (dataIndex, showSearch = true) => ({
    filterDropdown: ({ confirm, clearFilters }) => {
      if (dataIndex === 'createdAt') {
        return (
          <div className="custom-filter-dropdown">
            <RangePicker
              allowClear={false}
              format={DATE_FORMATS?.REGULAR}
              onChange={(values) => onRangePickerChange(values, dataIndex)}
              value={
                whereFilter?.[dataIndex]?.from
                  ? [
                      whereFilter?.[dataIndex]?.from,
                      whereFilter?.[dataIndex]?.to,
                    ]
                  : null
              }
            />
            <Divider className="divider-filter" />
            <div className="d-flex justify-center">
              <Button
                size="small"
                className="reset-button"
                id="roles-filter-reset"
                onClick={() => handleReset(clearFilters, dataIndex, confirm)}
              >
                Reset
              </Button>
              <Button
                size="small"
                className="common-button ok-button"
                id="roles-filter-ok"
                type="primary"
                onClick={() => getFilterData(confirm, dataIndex)}
              >
                Ok
              </Button>
            </div>
          </div>
        );
      }

      return (
        <div className="custom-filter-dropdown">
          <LoaderComponent
            spinning={filterLoading}
            setHeight={35}
            size="medium"
          >
            {showSearch && (
              <SearchComponent
                className="filter-search"
                id="search-container-id-roles"
                placeholder="Search..."
                name={dataIndex}
                query={filterSearch}
                setQuery={setFilterSearch}
                getData={(value) => handleFilterSearch(value, dataIndex)}
              />
            )}
            {filterTags?.[dataIndex]?.length > 0 && (
              <div className="filter-tags-wrapper">
                {map(filterTags?.[dataIndex], (item) => (
                  <Tag
                    key={item?.key}
                    closable
                    onClose={() => handleDeleteFilter(item, dataIndex)}
                    className="filter-tag"
                  >
                    <span>{item?.label}</span>
                  </Tag>
                ))}
              </div>
            )}
            <div
              className="filter-checkboxes"
              onScroll={(e) => onFilterScroll(e, dataIndex)}
            >
              {filterList?.length > 0
                ? map(filterList, (item) => (
                    <div className="single-checkbox-div" key={item?.key}>
                      <Checkbox
                        value={item}
                        checked={whereFilter?.[dataIndex]?.includes(item?.key)}
                        key={item?.key || item}
                        onChange={(e) => changeFilter(e, dataIndex)}
                        className="single-checkbox"
                      >
                        <span>{item?.label}</span>
                      </Checkbox>
                    </div>
                  ))
                : isEmptyFilterList && (
                    <Empty image={Empty?.PRESENTED_IMAGE_SIMPLE} />
                  )}
            </div>
          </LoaderComponent>
          <Divider className="divider-filter" />
          <div className="d-flex justify-center">
            <Button
              size="small"
              className="reset-button"
              id="roles-filter-reset"
              onClick={() => handleReset(clearFilters, dataIndex, confirm)}
            >
              Reset
            </Button>
            <Button
              size="small"
              className="common-button ok-button"
              id="roles-filter-ok"
              type="primary"
              onClick={() => getFilterData(confirm, dataIndex)}
            >
              Ok
            </Button>
          </div>
        </div>
      );
    },
    filterIcon: () =>
      finalWhereFilters?.[dataIndex]?.length > 0 ||
      !isEmpty(finalWhereFilters?.[dataIndex]) ? (
        <FilterFilled className="filter-icon filter-filled" />
      ) : (
        <FilterOutlined className="filter-icon" />
      ),
    onFilterDropdownOpenChange: (isVisible) => {
      setFilterIndex(dataIndex);
      setFilterVisible(isVisible);
    },
  });

  const columns = [
    {
      title: 'USER NAME',
      dataIndex: 'userName',
      key: 'userName',
      ellipsis: true,
      width: 250,
      align: 'left',
      className: `max-width-column`,
      onCell: (record) => ({
        onClick: () =>
          isUserPermission &&
          record?.createdBy?.id &&
          !record?.createdBy?.deletedAt &&
          history?.push(
            `${ROUTES?.SYSTEM_USERS}${ROUTES?.END_USERS}${ROUTES?.BASIC_DETAILS}/view/${record?.createdBy?.id}/${record?.createdBy?.userNumber}`,
          ),
        className: `${
          isUserPermission &&
          record?.createdBy?.id &&
          !record?.createdBy?.deletedAt &&
          'pointer table-cell'
        }`,
      }),
      render: (_, record) => {
        const userData = record?.createdBy;
        return (
          <>
            {userData?.deletedAt ? (
              <div className="font-size-14">(Deleted User)</div>
            ) : (
              <>
                {userData?.firstName || userData?.lastName ? (
                  <div className="table-data-with-id">{`${
                    userData?.firstName ? userData?.firstName : ''
                  } ${userData?.lastName ? userData?.lastName : ''}`}</div>
                ) : (
                  '-'
                )}
                {userData?.userNumber ? (
                  <div className="font-size-12">({userData?.userNumber})</div>
                ) : (
                  ''
                )}
              </>
            )}
          </>
        );
      },
    },
    {
      title: 'REQUEST ID',
      dataIndex: 'id',
      key: 'id',
      ellipsis: true,
      width: 170,
      align: 'left',
      className: 'max-width-column',
      render: (_, record) => record?.requestNumber,
    },
    {
      title: 'REQUEST DATE',
      dataIndex: 'requestDate',
      key: 'requestDate',
      ellipsis: true,
      width: 150,
      align: 'left',
      className: 'max-width-column',
      render: (_, record) =>
        formatDate(record?.createdAt, DATE_FORMATS?.REGULAR) || '-',
      ...filterPopup('createdAt'),
    },
    {
      title: 'RESOLVING COMMENT',
      dataIndex: 'resolvingComment',
      key: 'resolvingComment',
      ellipsis: true,
      width: 150,
      render: (_, record) =>
        record?.status === REQUEST_STATUS?.APPROVED?.value ? (
          <>
            <div className="action-button">
              <CommentIcon
                className="mr-16 pointer svg-icon"
                onClick={() => {
                  setIsCommentPrompts(true);
                  setEncashment(record);
                }}
              />
            </div>
          </>
        ) : (
          '-'
        ),
    },
    {
      title: 'AMOUNT',
      dataIndex: 'amount',
      key: 'amount',
      ellipsis: true,
      width: 150,
      align: 'left',
      className: 'max-width-column',
      render: (_, record) => record?.metaData?.amount || '-',
    },
    {
      title: 'ACCOUNT DETAILS',
      dataIndex: 'accountDetails',
      key: 'accountDetails',
      ellipsis: true,
      width: 250,
      align: 'left',
      className: 'max-width-column table-tooltip-cell',
      render: (_, record) => {
        const bankAccounts = record?.createdBy?.bankAccounts?.filter(
          (account) => account?.isDefault,
        );
        const allBankAccounts = bankAccounts?.map((bankAccount) => {
          if (bankAccount?.type === PAYMENT_TYPE?.ACCOUNT) {
            return `(Name. ${bankAccount?.holderName}) (No. ${bankAccount?.accountNumber}) (IFSC: ${bankAccount?.ifsc})`;
          }
          return ` (UPI ID: ${bankAccount.upiId}) `;
        });
        return (
          <>
            {bankAccounts?.length > 0 ? (
              <Tooltip
                title={allBankAccounts}
                placement="topLeft"
                trigger="click"
                className="pointer"
              >
                {allBankAccounts}
              </Tooltip>
            ) : (
              '-'
            )}
          </>
        );
      },
    },
    {
      title: 'STATUS',
      dataIndex: 'status',
      key: 'status',
      ellipsis: true,
      width: 150,
      align: 'left',
      className: 'max-width-column',
      render: (_, record) => (
        <Badge
          color={REQUEST_ENCASHMENT_STATUS?.[record?.status]?.color}
          text={REQUEST_ENCASHMENT_STATUS?.[record?.status]?.text}
        />
      ),
      ...filterPopup('status', false),
    },
    {
      title: 'ACTIONS',
      dataIndex: 'actions',
      key: 'actions',
      ellipsis: true,
      width: 150,
      // eslint-disable-next-line no-undef
      fixed: window.innerWidth > BREAKPOINTS.desktop ? 'right' : false,
      render: (_, record) => (
        <div className="action-button">
          <>
            <Button
              type="primary"
              size="small"
              className="table-action-primary-btn"
              onClick={() => {
                setIsCommentPrompts(true);
                setEncashment(record);
              }}
              disabled={record?.status === REQUEST_STATUS?.APPROVED?.value}
            >
              Resolve
            </Button>
            <div className="ml-16 d-flex">
              <Tooltip
                title="Reject"
                placement="top"
                className="table-action-button"
                zIndex={4}
              >
                <Button
                  disabled={record?.status !== REQUEST_STATUS?.PENDING?.value}
                  type="link"
                  onClick={() => {
                    setIsRejectPrompts(true);
                    setEncashment(record);
                  }}
                >
                  <RejectIcon
                    className={
                      record?.status !== REQUEST_STATUS?.PENDING?.value
                        ? 'svg-icon-grey'
                        : 'svg-icon'
                    }
                  />
                </Button>
              </Tooltip>
            </div>
          </>
        </div>
      ),
    },
  ]?.filter((item) => {
    if (item?.dataIndex === 'actions' && !hasUpdatePermission) {
      return item?.hidden;
    }
    return !item?.hidden;
  });

  const locale = {
    emptyText: isEmptyEncashmentList ? '' : <span />,
  };

  return (
    <>
      <Modal
        title="Caution"
        okText="Yes"
        cancelText="No"
        open={isRejectPrompts}
        onOk={() => handleReject(encashment?.id)}
        onCancel={() => setIsRejectPrompts(false)}
        okButtonProps={{ loading: isRejectLoading }}
      >
        Are you sure you want to
        <strong> Reject </strong>
        this request?
      </Modal>
      <CommonPreviewTable
        visible={visible}
        setVisible={setVisible}
        images={toyImages}
        setImages={setToyImages}
      />
      <ResolveCommentModal
        form={form}
        comment={encashment?.metaData?.comment}
        isModalOpen={isCommentPrompts}
        setIsModalOpen={setIsCommentPrompts}
        isBtnLoading={isCommentLoading}
        onFinish={handleAddComment}
        isResolved={encashment?.status === REQUEST_STATUS?.APPROVED?.value}
      />
      <div className="d-flex justify-end mb-16 flex-wrap">
        {hasUpdatePermission && (
          <div className="mr-16 d-flex align-center">
            <h4 className="m-0 mr-8">Only Archived</h4>{' '}
            <Switch
              size="small"
              onChange={(checked) => setIsArchiveFilter(checked)}
            />
          </div>
        )}
        <SearchComponent
          className="search-box"
          query={query}
          setQuery={setQuery}
          getData={handleSearch}
        />
      </div>
      <CommonTable
        locale={locale}
        columns={columns}
        data={encashmentList || []}
        loading={isEncashmentLoading}
        onChange={handleTableChange}
        paginationConfig={paginationProp}
        rowKey={(record) => record?.id}
      />
    </>
  );
};

export default EncashmentTable;
