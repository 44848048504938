import { useLazyQuery, useMutation } from '@apollo/client';
import { Button, Form } from 'antd';
import React, { useContext, useEffect, useState } from 'react';
import { AppContext } from '../../../../../AppContext';
import {
  MODULES,
  PLAN_NAMES,
  PLAN_TYPES,
  ROUTES,
} from '../../../../../common/constants';
import GoBackButton from '../../../../../components/GoBackButton';
import LoaderComponent from '../../../../../components/LoaderComponent';
import Portal from '../../../../../components/Portal';
import SubscriptionForm from '../../../components/SubscriptionForm';
import { UPDATE_SUBSCRIPTION_PLAN } from '../../../graphql/Mutations';
import { SUBSCRIPTION_PLANS_ADMIN } from '../../../graphql/Queries';
import GardenTabs from '../components/GardenTabs';

const EditQuarterlyGarden = () => {
  const [form] = Form.useForm();
  const { dispatch } = useContext(AppContext);
  const [isDisabled, setIsDisabled] = useState(true);
  const [btnLoading, setBtnLoading] = useState(false);
  const [planDetails, setPlanDetails] = useState({});
  const [isPlanLoading, setIsPlanLoading] = useState(true);

  const [subscriptionPlansAdmin] = useLazyQuery(SUBSCRIPTION_PLANS_ADMIN, {
    onCompleted: (response) => {
      setPlanDetails(response?.subscriptionPlansAdmin?.data?.[0]);
      setIsPlanLoading(false);
    },
    fetchPolicy: 'network-only',
    onError() {},
  });

  const [updateSubscriptionPlan] = useMutation(UPDATE_SUBSCRIPTION_PLAN, {
    onError: () => {},
  });

  useEffect(() => {
    setIsPlanLoading(true);
    subscriptionPlansAdmin({
      variables: {
        filter: {
          name: PLAN_NAMES?.GARDEN?.value,
          type: PLAN_TYPES?.QUARTERLY?.value,
        },
      },
    });
  }, []);

  const onFinish = async (values) => {
    setBtnLoading(true);
    const response = await updateSubscriptionPlan({
      variables: {
        where: {
          id: planDetails?.id,
        },
        data: {
          bonusDeliveries: parseInt(values?.bonusDeliveries, 10),
          totalKeepCountPerMonth: parseInt(values?.holdingToys, 10),
          totalFreeDeliveryPerMonth: parseInt(values?.freeDeliveries, 10),
          trialApplied: values?.trialApplied,
          membershipFeesApplied: values?.membershipFeesApplied,
          securityDepositApplied: values?.securityDepositApplied,
          trialDays: parseInt(values?.trialDays, 10),
          trialAmount: parseInt(values?.trialAmount, 10),
          actualChargesForSecurityDeposit: parseInt(
            values?.actualChargesForSecurityDeposit,
            10,
          ),
          damageWaiverFeesApplied: values?.damageWaiverFeesApplied,
          damageWaiverFees: parseInt(values?.damageWaiverFees, 10),
          actualChargesForDamageWaiverFees: parseInt(
            values?.actualChargesForDamageWaiverFees,
            10,
          ),
          actualChargesForMemberShipFees: parseInt(
            values?.actualChargesForMemberShipFees,
            10,
          ),
        },
      },
    });
    if (response?.data) {
      setIsPlanLoading(true);
      setBtnLoading(false);
      setIsDisabled(true);
      dispatch({
        type: 'SET_SHOW_PROMPT',
        data: false,
      });
      subscriptionPlansAdmin({
        variables: {
          filter: {
            name: PLAN_NAMES?.GARDEN?.value,
            type: PLAN_TYPES?.QUARTERLY?.value,
          },
        },
      });
    }
  };

  const handleDiscard = () => {
    setIsPlanLoading(true);
    subscriptionPlansAdmin({
      variables: {
        filter: {
          name: PLAN_NAMES?.GARDEN?.value,
          type: PLAN_TYPES?.QUARTERLY?.value,
        },
      },
    });
    setIsDisabled(true);
    dispatch({
      type: 'SET_SHOW_PROMPT',
      data: false,
    });
  };

  return (
    <>
      <Portal portalId="header-title-content">
        <GoBackButton customLink={ROUTES?.SUBSCRIPTION_PLANS} />
        <div className="portal-header">Edit {MODULES?.GARDEN}</div>
      </Portal>
      <Portal portalId="header-right-content">
        <div className="header-btn-wrapper">
          <Button
            type="primary"
            className="common-button mr-16"
            size="middle"
            disabled={btnLoading || isDisabled}
            onClick={handleDiscard}
          >
            Discard Changes
          </Button>
          <Button
            className="common-button"
            size="middle"
            onClick={form.submit}
            disabled={isDisabled}
          >
            Save
          </Button>
        </div>
      </Portal>
      <Portal portalId="header-right-content-phones">
        <div className="header-btn-wrapper">
          <Button
            type="primary"
            className="common-button mr-16"
            size="middle"
            disabled={btnLoading || isDisabled}
            onClick={handleDiscard}
          >
            Discard Changes
          </Button>
          <Button
            className="common-button"
            size="middle"
            onClick={form.submit}
            disabled={isDisabled}
          >
            Save
          </Button>
        </div>
      </Portal>
      <GardenTabs>
        {isPlanLoading ? (
          <LoaderComponent
            size="large"
            setHeight="60"
            spinning={isPlanLoading}
          />
        ) : (
          <SubscriptionForm
            form={form}
            planName={PLAN_NAMES?.GARDEN?.value}
            planType={PLAN_TYPES?.QUARTERLY?.value}
            planDetails={planDetails}
            onFinish={onFinish}
            setIsDisabled={setIsDisabled}
          />
        )}
      </GardenTabs>
    </>
  );
};

export default EditQuarterlyGarden;
