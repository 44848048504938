import React from 'react';
import { Route, Switch } from 'react-router-dom';
import AccessControl from '../../../../AccessControl';
import Error404 from '../../../../Error404';
import { PERMISSIONS_KEY, ROUTES } from '../../../../common/constants';
import NewPlansTabs from './components/NewPlansTabs';

const NewPlansWrapper = () => (
  <Switch>
    <Route
      exact
      path={`${ROUTES?.SUBSCRIPTION_PLANS}${ROUTES?.NEW_PLANS}`}
      render={() => (
        <AccessControl permissionKey={PERMISSIONS_KEY?.SUBSCRIPTION_PLANS}>
          <NewPlansTabs />
        </AccessControl>
      )}
    />
    <Route
      exact
      path={`${ROUTES?.SUBSCRIPTION_PLANS}${ROUTES?.NEW_PLANS}/*`}
      render={() => (
        <AccessControl permissionKey={PERMISSIONS_KEY?.SUBSCRIPTION_PLANS}>
          <NewPlansTabs />
        </AccessControl>
      )}
    />
    <Route path="*" exact component={Error404} />
  </Switch>
);

export default NewPlansWrapper;
