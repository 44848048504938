import { useLazyQuery } from '@apollo/client';
import { Empty, Select } from 'antd';
import { debounce } from 'lodash';
import React, { useEffect, useState } from 'react';
import { LIMIT, SORT } from '../common/constants';
import { COUNTRIES } from './graphql/Queries';
import SelectComponent from './SelectComponent';

const { Option } = Select;

const CountrySelect = (props) => {
  const { setCountryId, ...rest } = props;
  const [countryList, setCountryList] = useState([]);
  const [countriesSearchTerm, setCountriesSearchTerm] = useState('');
  const [isAllCountriesFetched, setIsAllCountriesFetched] = useState(false);
  const [isCountriesAllowClear, setIsCountriesAllowClear] = useState(false);
  const [isCountriesDropdownVisible, setIsCountriesDropdownVisible] = useState(
    false,
  );
  const [
    isFetchMoreCountriesLoading,
    setIsFetchMoreCountriesLoading,
  ] = useState(false);

  const [countries, { loading: isCountriesLoading }] = useLazyQuery(COUNTRIES, {
    onCompleted: (response) => {
      if (response?.countries?.data?.length < LIMIT) {
        setIsAllCountriesFetched(true);
      }
      setIsFetchMoreCountriesLoading(false);
      setCountryList([...countryList, ...response?.countries?.data]);
      setCountryId(response?.countries?.data?.[0]?.id);
    },
    fetchPolicy: 'network-only',
    onError() {},
  });

  useEffect(() => {
    setCountriesSearchTerm('');
    setCountryList([]);
    countries({
      variables: {
        filter: {
          limit: LIMIT,
          skip: 0,
          search: countriesSearchTerm,
        },
        sort: {
          field: 'name',
          order: SORT?.ASC,
        },
      },
    });
    setIsAllCountriesFetched(false);
  }, [isCountriesDropdownVisible]);

  const handleCategoriesScroll = (event) => {
    if (!isAllCountriesFetched && !isCountriesLoading) {
      const target = event?.target;
      const currentLength = countryList?.length;
      if (
        target?.scrollTop + target?.offsetHeight >=
        target?.scrollHeight - 5
      ) {
        setIsFetchMoreCountriesLoading(true);
        countries({
          variables: {
            filter: {
              limit: LIMIT,
              skip: currentLength,
              search: countriesSearchTerm,
            },
            sort: {
              field: 'name',
              order: SORT?.ASC,
            },
          },
        });
      }
    }
  };

  const handleCategoriesSearch = (searchTerm) => {
    const trimSearch = searchTerm?.trim();
    setIsAllCountriesFetched(false);
    setCountryList([]);
    setCountriesSearchTerm(trimSearch);
    countries({
      variables: {
        filter: {
          limit: LIMIT,
          skip: 0,
          search: trimSearch,
        },
        sort: {
          field: 'name',
          order: SORT?.ASC,
        },
      },
    });
  };

  const handleCategoriesScrollDebounce = debounce(handleCategoriesScroll, 500);
  const handleSearchCategoriesDebounce = debounce(handleCategoriesSearch, 500);

  useEffect(() => {
    handleCategoriesScrollDebounce?.cancel();
  }, [handleCategoriesScrollDebounce]);

  useEffect(() => {
    handleSearchCategoriesDebounce?.cancel();
  }, [handleSearchCategoriesDebounce]);

  useEffect(() => {
    if (isCountriesLoading) {
      setIsCountriesAllowClear(false);
    } else {
      setIsCountriesAllowClear(true);
    }
  }, [isCountriesLoading]);

  return (
    <SelectComponent
      showSearch
      loading={
        (isCountriesLoading && isCountriesDropdownVisible) ||
        isFetchMoreCountriesLoading
      }
      placeholder="Select Country"
      optionFilterProp="children"
      allowClear={isCountriesAllowClear}
      onPopupScroll={handleCategoriesScrollDebounce}
      onSearch={handleSearchCategoriesDebounce}
      onDropdownVisibleChange={(visible) =>
        setIsCountriesDropdownVisible(visible)
      }
      filterOption={false}
      notFoundContent={
        !isFetchMoreCountriesLoading && isCountriesLoading ? (
          <span>Loading...</span>
        ) : (
          <Empty image={Empty?.PRESENTED_IMAGE_SIMPLE} />
        )
      }
      disabled
      className="text-capitalize"
      {...rest}
    >
      {countryList?.length > 0 && (
        <>
          {countryList?.map((country) => (
            <Option key={country?.id} value={country?.id}>
              {country?.name}
            </Option>
          ))}
        </>
      )}
    </SelectComponent>
  );
};

export default CountrySelect;
