import { Empty } from 'antd';
import React from 'react';
import LoaderComponent from '../../../components/LoaderComponent';
import BulkUpdateLogCard from './BulkUpdateLogCard';

const BulkUpdateLogList = ({
  inventoryList,
  setVisible,
  setToyImages,
  infiniteScrollRef,
  isFetchMoreToysLoading,
  isEmptyInventoryList,
  isInventoryLoading,
}) => (
  <div>
    {inventoryList?.map((inventory) => (
      <BulkUpdateLogCard
        key={inventory?.id}
        inventory={inventory}
        setVisible={setVisible}
        setToyImages={setToyImages}
        infiniteScrollRef={infiniteScrollRef}
      />
    ))}
    {(isFetchMoreToysLoading || isInventoryLoading) && (
      <LoaderComponent size="small" setHeight={10} />
    )}
    {isEmptyInventoryList && <Empty image={Empty?.PRESENTED_IMAGE_SIMPLE} />}
  </div>
);

export default BulkUpdateLogList;
