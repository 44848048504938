import { useLazyQuery } from '@apollo/client';
import { Empty, Select } from 'antd';
import { debounce, uniqBy } from 'lodash';
import React, { useEffect, useState } from 'react';
import { LIMIT, SORT } from '../../../../../common/constants';
import SelectComponent from '../../../../../components/SelectComponent';
import { CONTENT_MASTERS_ADMIN } from '../graphql/Queries';

const { Option } = Select;

const ContentMasterSelect = (props) => {
  const {
    form,
    toyDetail,
    index,
    inCludedContentValues = [],
    exCludedContentValues = [],
    contentMasters,
    includedContentsSelect = false,
    excludedContentsSelect = false,
    ...rest
  } = props;
  const includedContentMasters = form.getFieldValue('includedContentMasters');
  const excludedContentMasters = form.getFieldValue('excludedContentMasters');
  const [contentMasterList, setContentMasterList] = useState([]);
  const [contentMasterSearchTerm, setContentMasterSearchTerm] = useState('');
  const [isAllContentMasterFetched, setIsAllContentMasterFetched] = useState(
    false,
  );
  const [isContentMasterAllowClear, setIsContentMasterAllowClear] = useState(
    false,
  );
  const [
    isContentMasterDropdownVisible,
    setIsContentMasterDropdownVisible,
  ] = useState(false);
  const [
    isFetchMoreContentMasterLoading,
    setIsFetchMoreContentMasterLoading,
  ] = useState(false);

  const [
    contentMastersAdmin,
    { loading: isContentMasterLoading },
  ] = useLazyQuery(CONTENT_MASTERS_ADMIN, {
    onCompleted: (response) => {
      if (response?.contentMastersAdmin?.data?.length < LIMIT) {
        setIsAllContentMasterFetched(true);
      }
      setIsFetchMoreContentMasterLoading(false);
      if (contentMasters) {
        setContentMasterList(
          uniqBy(
            [
              ...contentMasters,
              ...contentMasterList,
              ...response?.contentMastersAdmin?.data,
            ],
            'id',
          ),
        );
        return;
      }
      setContentMasterList([
        ...contentMasterList,
        ...response?.contentMastersAdmin?.data,
      ]);
    },
    fetchPolicy: 'network-only',
    onError() {},
  });

  useEffect(() => {
    setContentMasterSearchTerm('');
    setContentMasterList([]);
    contentMastersAdmin({
      variables: {
        filter: {
          limit: LIMIT,
          skip: 0,
          search: contentMasterSearchTerm,
          isArchived: false,
        },
        sort: {
          field: 'key',
          order: SORT?.ASC,
        },
      },
    });
    setIsAllContentMasterFetched(false);
  }, [isContentMasterDropdownVisible]);

  const handleContentMasterScroll = (event) => {
    if (!isAllContentMasterFetched && !isContentMasterLoading) {
      const target = event?.target;
      const currentLength = contentMasterList?.length;
      if (
        target?.scrollTop + target?.offsetHeight >=
        target?.scrollHeight - 5
      ) {
        setIsFetchMoreContentMasterLoading(true);
        contentMastersAdmin({
          variables: {
            filter: {
              limit: LIMIT,
              skip: currentLength,
              search: contentMasterSearchTerm,
              isArchived: false,
            },
            sort: {
              field: 'key',
              order: SORT?.ASC,
            },
          },
        });
      }
    }
  };

  const handleContentMasterSearch = (searchTerm) => {
    const trimSearch = searchTerm?.trim();
    setIsAllContentMasterFetched(false);
    setContentMasterList([]);
    setContentMasterSearchTerm(trimSearch);
    contentMastersAdmin({
      variables: {
        filter: {
          limit: LIMIT,
          skip: 0,
          search: trimSearch,
          isArchived: false,
        },
        sort: {
          field: 'key',
          order: SORT?.ASC,
        },
      },
    });
  };

  const handleContentMasterScrollDebounce = debounce(
    handleContentMasterScroll,
    500,
  );
  const handleSearchContentMasterDebounce = debounce(
    handleContentMasterSearch,
    500,
  );

  useEffect(() => {
    handleContentMasterScrollDebounce?.cancel();
  }, [handleContentMasterScrollDebounce]);

  useEffect(() => {
    handleSearchContentMasterDebounce?.cancel();
  }, [handleSearchContentMasterDebounce]);

  useEffect(() => {
    if (isContentMasterLoading) {
      setIsContentMasterAllowClear(false);
    } else {
      setIsContentMasterAllowClear(true);
    }
  }, [isContentMasterLoading]);

  const includedContentMasterIds = includedContentMasters
    ?.filter((item) => item !== undefined)
    ?.map((item) => item?.id);

  const excludedContentMasterIds = excludedContentMasters
    ?.filter((item) => item !== undefined)
    ?.map((item) => item?.id);

  return (
    <SelectComponent
      showSearch
      loading={
        (isContentMasterLoading && isContentMasterDropdownVisible) ||
        isFetchMoreContentMasterLoading
      }
      placeholder="Select Content Master"
      optionFilterProp="children"
      allowClear={isContentMasterAllowClear}
      onPopupScroll={handleContentMasterScrollDebounce}
      onSearch={handleSearchContentMasterDebounce}
      onDropdownVisibleChange={(visible) =>
        setIsContentMasterDropdownVisible(visible)
      }
      filterOption={false}
      notFoundContent={
        !isFetchMoreContentMasterLoading && isContentMasterLoading ? (
          <span>Loading...</span>
        ) : (
          <Empty image={Empty?.PRESENTED_IMAGE_SIMPLE} />
        )
      }
      {...rest}
    >
      {contentMasterList
        ?.filter((content) =>
          includedContentsSelect
            ? (content?.id === inCludedContentValues?.[index] ||
                !inCludedContentValues?.includes(content?.id)) &&
              !excludedContentMasterIds?.includes(content?.id)
            : content,
        )
        ?.filter((content) =>
          excludedContentsSelect
            ? (content?.id === exCludedContentValues?.[index] ||
                !exCludedContentValues?.includes(content?.id)) &&
              !includedContentMasterIds?.includes(content?.id)
            : content,
        )
        ?.map((contentMaster) => (
          <Option key={contentMaster?.id} value={contentMaster?.id}>
            {contentMaster?.value}
          </Option>
        ))}
    </SelectComponent>
  );
};

export default ContentMasterSelect;
