import { Button } from 'antd';
import React, { useContext } from 'react';
import { useHistory } from 'react-router-dom';
import { AppContext } from '../../../../../AppContext';
import {
  PERMISSIONS_KEY,
  PERMISSION_TYPE,
  ROUTES,
} from '../../../../../common/constants';
import { hasPermission } from '../../../../../common/utils';
import GoBackButton from '../../../../../components/GoBackButton';
import Portal from '../../../../../components/Portal';
import EndUsersTabs from '../components/EndUsersTabs';
import SubscriptionInfo from '../components/SubscriptionInfo';

const ViewSubscriptionDetails = ({
  match: { params: { id, userNumber } = {} } = {},
}) => {
  const {
    state: { permissions },
  } = useContext(AppContext);
  const history = useHistory();

  return (
    <>
      <Portal portalId="header-title-content">
        <GoBackButton
          customLink={`${ROUTES?.SYSTEM_USERS}${ROUTES?.END_USERS}`}
        />
        <div className="portal-header">View End User</div>
      </Portal>
      <Portal portalId="header-right-content">
        {hasPermission(
          PERMISSIONS_KEY?.SYSTEM_USERS_END_USERS,
          PERMISSION_TYPE?.UPDATE,
          permissions,
        ) && (
          <div className="header-btn-wrapper">
            <Button
              className="common-button mr-16"
              size="middle"
              onClick={() =>
                history?.push(
                  `${ROUTES?.SYSTEM_USERS}${ROUTES?.END_USERS}${ROUTES?.SUBSCRIPTION_PLAN_DETAILS}/edit/${id}/${userNumber}`,
                )
              }
            >
              Edit Subscription
            </Button>
          </div>
        )}
      </Portal>
      <Portal portalId="header-right-content-phones">
        {hasPermission(
          PERMISSIONS_KEY?.SYSTEM_USERS_END_USERS,
          PERMISSION_TYPE?.UPDATE,
          permissions,
        ) && (
          <div className="header-btn-wrapper">
            <Button
              className="common-button mr-16"
              size="middle"
              onClick={() =>
                history?.push(
                  `${ROUTES?.SYSTEM_USERS}${ROUTES?.END_USERS}${ROUTES?.SUBSCRIPTION_PLAN_DETAILS}/edit/${id}/${userNumber}`,
                )
              }
            >
              Edit Subscription
            </Button>
          </div>
        )}
      </Portal>
      <EndUsersTabs endUserId={id} endUserNumber={userNumber} isView>
        <div className="full-height-without-details">
          <SubscriptionInfo userId={id} isView />
        </div>
      </EndUsersTabs>
    </>
  );
};

export default ViewSubscriptionDetails;
