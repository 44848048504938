import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import { ROUTES } from '../../common/constants';

const OrdersWrapper = () => (
  <Switch>
    <Route
      exact
      path={ROUTES?.ORDERS}
      render={() => <Redirect to={`${ROUTES?.ORDERS}${ROUTES?.NEW}`} />}
    />
  </Switch>
);

export default OrdersWrapper;
