import { FilterFilled, FilterOutlined } from '@ant-design/icons';
import { useLazyQuery, useMutation } from '@apollo/client';
import { Badge, Button, Divider, Form, Modal, Radio, Tooltip } from 'antd';
import { forEach, isEmpty } from 'lodash';
import React, { useContext, useEffect, useState } from 'react';
import { AppContext } from '../../../../../AppContext';
import { ReactComponent as ActiveIcon } from '../../../../../assets/svg/location-dot-solid.svg';
import { ReactComponent as EditIcon } from '../../../../../assets/svg/pen-to-square-solid.svg';
import {
  BREAKPOINTS,
  LIMIT,
  PERMISSIONS_KEY,
  PERMISSION_TYPE,
  SORT,
  STATUS,
  USER_FORM_STATUS,
} from '../../../../../common/constants';
import { hasPermission } from '../../../../../common/utils';
import CommonTable from '../../../../../components/CommonTable';
import SearchComponent from '../../../../../components/SearchComponent';
import { CREATE_PINCODE, UPDATE_PINCODE } from '../graphql/Mutations';
import { PINCODES } from '../graphql/Queries';
import PincodeModal from './PincodeModal';

const { Group } = Radio;

const initialPaginationValue = {
  total: 0,
  current: 1,
  pageSize: 10,
};

const initialFilter = {
  limit: LIMIT,
  search: '',
  skip: 0,
};

const initialSort = {
  field: 'pincode',
  order: 'ASC',
};

const PincodeTable = ({ isPincodeModalOpen, setIsPincodeModalOpen }) => {
  const {
    state: { permissions },
  } = useContext(AppContext);
  const [form] = Form.useForm();
  const [pincodeList, setPincodeList] = useState([]);
  const [pincodeSearchTerm, setPincodeSearchTerm] = useState('');
  const [isEmptyPincodeList, setIsEmptyPincodeList] = useState(false);
  const [pincode, setPincode] = useState();
  const [query, setQuery] = useState('');
  const [sortedInfo, setSortedInfo] = useState({});
  const [paginationProp, setPaginationProp] = useState(initialPaginationValue);
  const [isPincodeLoading, setIsPincodeLoading] = useState(true);
  const [isBtnLoading, setIsBtnLoading] = useState(false);
  const [isActivePrompts, setIsActivePrompts] = useState(false);
  const [isActiveLoading, setIsActiveLoading] = useState(false);

  const [whereFilter, setWhereFilter] = useState();
  const [filterTags, setFilterTags] = useState(null);
  const [filterVisible, setFilterVisible] = useState(false);
  const [filterIndex, setFilterIndex] = useState(null);
  const [finalWhereFilters, setFinalWhereFilters] = useState();
  const [finalFilterTags, setFinalFilterTags] = useState(null);

  const [createPincode] = useMutation(CREATE_PINCODE, {
    onError: () => {},
  });

  const [updatePincode] = useMutation(UPDATE_PINCODE, {
    onError: () => {},
  });

  const [pincodes] = useLazyQuery(PINCODES, {
    onCompleted: (response) => {
      setPincodeList([...response?.pincodes?.data]);
      if (
        response?.pincodes?.count === 0 &&
        initialPaginationValue?.total === 0
      ) {
        setIsEmptyPincodeList(true);
      } else {
        setIsEmptyPincodeList(false);
      }
      const pagination = {
        ...paginationProp,
        defaultPageSize: LIMIT,
        total: response?.pincodes?.count,
      };
      setPaginationProp(pagination);
      setIsPincodeLoading(false);
    },
    fetchPolicy: 'network-only',
    onError() {},
  });

  const hasUpdatePermission = hasPermission(
    PERMISSIONS_KEY?.MASTER_LIST_PINCODE,
    PERMISSION_TYPE?.UPDATE,
    permissions,
  );

  useEffect(() => {
    setPaginationProp({ ...paginationProp, current: 1, skip: 0 });
    setIsPincodeLoading(true);
    pincodes({
      variables: {
        filter: {
          ...initialFilter,
          limit: paginationProp?.pageSize || LIMIT,
          search: pincodeSearchTerm,
        },
        sort: sortedInfo?.column
          ? {
              field: sortedInfo?.field,
              order: sortedInfo?.order === 'ascend' ? SORT?.ASC : SORT?.DESC,
            }
          : initialSort,
      },
    });
  }, []);

  const handleTableChange = (pagination, tableFilter, sorter) => {
    const { current } = pagination;
    const skip = (current - 1) * (pagination?.pageSize || 0);
    setPaginationProp({ ...paginationProp, ...pagination });
    setIsPincodeLoading(true);
    setSortedInfo(sorter);
    pincodes({
      variables: {
        filter: {
          ...initialFilter,
          skip,
          limit: pagination?.pageSize,
          search: pincodeSearchTerm,
          isActive: finalWhereFilters?.status,
        },
        sort: sorter?.column
          ? {
              field: sorter?.field,
              order: sorter?.order === 'ascend' ? SORT?.ASC : SORT?.DESC,
            }
          : initialSort,
      },
    });
  };

  const handleSearch = (value) => {
    const trimValue = value?.trim();
    setPincodeSearchTerm(trimValue);
    setPaginationProp({ ...paginationProp, current: 1, skip: 0 });
    setIsPincodeLoading(true);
    pincodes({
      variables: {
        filter: {
          ...initialFilter,
          limit: paginationProp?.pageSize || LIMIT,
          search: trimValue,
          isActive: finalWhereFilters?.status,
        },
        sort: sortedInfo?.column
          ? {
              field: sortedInfo?.field,
              order: sortedInfo?.order === 'ascend' ? SORT?.ASC : SORT?.DESC,
            }
          : initialSort,
      },
    });
  };

  const handlePincode = async (values) => {
    setIsBtnLoading(true);
    let response;
    if (!pincode) {
      response = await createPincode({
        variables: {
          data: {
            pincode: values?.pincode,
            areaName: values?.areaName,
          },
        },
      });
    } else {
      response = await updatePincode({
        variables: {
          where: {
            id: pincode?.id,
          },
          data: {
            pincode: values?.pincode,
            areaName: values?.areaName,
          },
        },
      });
    }
    if (response?.data) {
      setIsPincodeModalOpen(false);
      setIsBtnLoading(true);
      setPincode();
      form?.resetFields();
      pincodes({
        variables: {
          filter: {
            ...initialFilter,
            skip:
              (paginationProp?.current - 1) * (paginationProp?.pageSize || 0),
            limit: paginationProp?.pageSize || LIMIT,
            search: pincodeSearchTerm,
            isActive: finalWhereFilters?.status,
          },
          sort: sortedInfo?.column
            ? {
                field: sortedInfo?.field,
                order: sortedInfo?.order === 'ascend' ? SORT?.ASC : SORT?.DESC,
              }
            : initialSort,
        },
      });
    }
    setIsBtnLoading(false);
  };

  const handleActive = async (id, isActive) => {
    setIsActiveLoading(true);
    const response = await updatePincode({
      variables: {
        where: {
          id,
        },
        data: {
          isActive: !isActive,
        },
      },
    });
    if (response?.data) {
      setIsActiveLoading(false);
      setIsPincodeLoading(true);
      setIsActivePrompts(false);
      setPincode();
      pincodes({
        variables: {
          filter: {
            ...initialFilter,
            skip:
              (paginationProp?.current - 1) * (paginationProp?.pageSize || 0),
            limit: paginationProp?.pageSize || LIMIT,
            search: pincodeSearchTerm,
            isActive: finalWhereFilters?.status,
          },
          sort: sortedInfo?.column
            ? {
                field: sortedInfo?.field,
                order: sortedInfo?.order === 'ascend' ? SORT?.ASC : SORT?.DESC,
              }
            : initialSort,
        },
      });
    }
    setIsActiveLoading(false);
  };

  const getFilterData = (confirm) => {
    setIsPincodeLoading(true);
    pincodes({
      variables: {
        filter: {
          ...initialFilter,
          skip: (paginationProp?.current - 1) * (paginationProp?.pageSize || 0),
          limit: paginationProp?.pageSize || LIMIT,
          search: pincodeSearchTerm,
          isActive:
            whereFilter?.status === true || whereFilter?.status === false
              ? whereFilter?.status
              : undefined,
        },
        sort: sortedInfo?.column
          ? {
              field: sortedInfo?.field,
              order: sortedInfo?.order === 'ascend' ? SORT?.ASC : SORT?.DESC,
            }
          : initialSort,
      },
    });
    setFinalWhereFilters({ ...finalWhereFilters, ...whereFilter });
    setFinalFilterTags(filterTags);
    setPaginationProp({ ...paginationProp, skip: 0, current: 1 });
    if (confirm) {
      confirm();
    }
  };

  const handleReset = (clearFilters, dataIndex, confirm) => {
    const tagsCopy = {
      ...filterTags,
      [dataIndex]: [],
    };
    const whereFiltersCopy = {
      ...whereFilter,
      [dataIndex]: null,
    };
    setFilterVisible(false);
    setFinalFilterTags({ ...filterTags, ...tagsCopy });
    setFinalWhereFilters({ ...finalWhereFilters, ...whereFiltersCopy });
    setIsPincodeLoading(true);
    pincodes({
      variables: {
        filter: {
          ...initialFilter,
          skip: (paginationProp?.current - 1) * (paginationProp?.pageSize || 0),
          limit: paginationProp?.pageSize || LIMIT,
          search: pincodeSearchTerm,
          isActive:
            whereFiltersCopy?.status === true ||
            whereFiltersCopy?.status === false
              ? whereFiltersCopy?.status
              : undefined,
        },
        sort: sortedInfo?.column
          ? {
              field: sortedInfo?.field,
              order: sortedInfo?.order === 'ascend' ? SORT?.ASC : SORT?.DESC,
            }
          : initialSort,
      },
    });
    setPaginationProp({ ...paginationProp, skip: 0, current: 1 });
    clearFilters();
    setFilterVisible(false);
    if (confirm) {
      confirm();
    }
  };

  useEffect(() => {
    if (filterVisible) {
      setFilterTags({ ...filterTags, ...finalFilterTags });
      setWhereFilter({ ...whereFilter, ...finalWhereFilters });
      const optionsCopy = [];

      switch (filterIndex) {
        case 'status':
          forEach(STATUS, (item) => {
            optionsCopy?.push({ label: item?.label, key: item?.key });
          });
          break;

        default:
          break;
      }
    }
    if (!filterVisible) {
      setFilterTags(finalFilterTags);
      setWhereFilter(finalWhereFilters);
    }
  }, [filterVisible]);

  const onRadioChange = (value, dataIndex) => {
    const filtersCopy = {
      ...whereFilter,
      [dataIndex]: value,
    };
    if (!value?.length) {
      delete whereFilter?.[dataIndex];
    }
    setWhereFilter(filtersCopy);
  };

  const filterPopup = (dataIndex) => ({
    filterDropdown: ({ confirm, clearFilters }) => (
      <div className="custom-filter-dropdown">
        <Group
          options={USER_FORM_STATUS}
          value={whereFilter?.[dataIndex]}
          onChange={(e) => onRadioChange(e?.target?.value, dataIndex)}
        />
        <Divider className="divider-filter" />
        <div className="d-flex justify-center">
          <Button
            size="small"
            className="reset-button"
            id="roles-filter-reset"
            onClick={() => handleReset(clearFilters, dataIndex, confirm)}
          >
            Reset
          </Button>
          <Button
            size="small"
            className="common-button ok-button"
            id="roles-filter-ok"
            type="primary"
            onClick={() => getFilterData(confirm, dataIndex)}
          >
            Ok
          </Button>
        </div>
      </div>
    ),
    filterIcon: () =>
      finalWhereFilters?.[dataIndex]?.length > 0 ||
      !isEmpty(finalWhereFilters?.[dataIndex]) ||
      finalWhereFilters?.[dataIndex] === true ||
      finalWhereFilters?.[dataIndex] === false ? (
        <FilterFilled className="filter-icon filter-filled" />
      ) : (
        <FilterOutlined className="filter-icon" />
      ),
    onFilterDropdownOpenChange: (visible) => {
      setFilterIndex(dataIndex);
      setFilterVisible(visible);
    },
  });

  const columns = [
    {
      title: 'PINCODE',
      dataIndex: 'pincode',
      key: 'pincode',
      ellipsis: true,
      sorter: true,
      width: 150,
      align: 'left',
      className: 'max-width-column',
      render: (_, record) => record?.pincode,
      // eslint-disable-next-line no-undef
      fixed: window.innerWidth > BREAKPOINTS.desktop ? 'left' : false,
    },
    {
      title: 'AREA NAME',
      dataIndex: 'areaName',
      key: 'areaName',
      ellipsis: true,
      sorter: true,
      width: 200,
      align: 'left',
      className: 'max-width-column',
      render: (_, record) => record?.areaName || '-',
    },
    {
      title: 'STATUS',
      dataIndex: 'status',
      key: 'status',
      ellipsis: true,
      width: 150,
      align: 'left',
      className: 'max-width-column',
      render: (_, record) => (
        <Badge
          color={
            record?.isActive ? STATUS?.ACTIVE?.color : STATUS?.INACTIVE?.color
          }
          text={
            record?.isActive ? STATUS?.ACTIVE?.label : STATUS?.INACTIVE?.label
          }
        />
      ),
      ...filterPopup('status'),
    },
    {
      title: 'ACTIONS',
      dataIndex: 'actions',
      key: 'actions',
      ellipsis: true,
      width: 150,
      // eslint-disable-next-line no-undef
      fixed: window.innerWidth > BREAKPOINTS.desktop ? 'right' : false,
      render: (_, record) => (
        <>
          <div className="action-button">
            <Tooltip
              title={record?.isActive ? 'Active' : 'Inactive'}
              placement="top"
              zIndex={4}
            >
              <ActiveIcon
                onClick={(e) => {
                  e?.stopPropagation();
                  setIsActivePrompts(true);
                  setPincode(record);
                }}
                className={
                  record?.isActive
                    ? 'mr-16 pointer svg-icon'
                    : 'mr-16 pointer svg-icon-grey'
                }
              />
            </Tooltip>
            <Tooltip title="Edit" placement="top" zIndex={4}>
              <EditIcon
                onClick={() => {
                  setIsPincodeModalOpen(true);
                  setPincode(record);
                }}
                className="mr-16 pointer svg-icon"
              />
            </Tooltip>
          </div>
        </>
      ),
    },
  ]?.filter((item) => {
    if (item?.dataIndex === 'actions' && !hasUpdatePermission) {
      return item?.hidden;
    }
    return !item?.hidden;
  });

  const locale = {
    emptyText: isEmptyPincodeList ? '' : <span />,
  };

  return (
    <>
      <Modal
        title="Caution"
        okText="Yes"
        cancelText="No"
        open={isActivePrompts}
        onOk={() => handleActive(pincode?.id, pincode?.isActive)}
        onCancel={() => {
          setIsActivePrompts(false);
          setPincode();
        }}
        okButtonProps={{ loading: isActiveLoading }}
      >
        Are you sure you want to
        <strong>
          {' '}
          {!pincode?.isActive
            ? STATUS?.ACTIVE?.label
            : STATUS?.INACTIVE?.label}{' '}
        </strong>
        this pincode?
      </Modal>
      <PincodeModal
        form={form}
        onFinish={handlePincode}
        loadings={isBtnLoading}
        isModalOpen={isPincodeModalOpen}
        setIsModalOpen={setIsPincodeModalOpen}
        pincode={pincode}
        setPincode={setPincode}
      />
      <div className="d-flex justify-end mb-16 flex-wrap">
        <SearchComponent
          className="search-box"
          query={query}
          setQuery={setQuery}
          getData={handleSearch}
        />
      </div>
      <CommonTable
        locale={locale}
        columns={columns}
        data={pincodeList || []}
        loading={isPincodeLoading}
        onChange={handleTableChange}
        paginationConfig={paginationProp}
        rowKey={(record) => record?.id}
      />
    </>
  );
};

export default PincodeTable;
