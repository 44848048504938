import { useLazyQuery, useMutation } from '@apollo/client';
import { Form, Image, Modal, Switch, Tooltip } from 'antd';
import React, { useContext, useEffect, useState } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { AppContext } from '../../../../../AppContext';
import { ReactComponent as ArchiveIcon } from '../../../../../assets/svg/box-archive-solid.svg';
import { ReactComponent as EditIcon } from '../../../../../assets/svg/pen-to-square-solid.svg';
import { ReactComponent as UnarchiveIcon } from '../../../../../assets/svg/right-from-bracket-solid.svg';
import {
  ARCHIVING_STATE,
  BREAKPOINTS,
  DATE_FORMATS,
  LIMIT,
  PERMISSIONS_KEY,
  PERMISSION_TYPE,
  SORT,
} from '../../../../../common/constants';
import {
  fileUpload,
  formatDate,
  hasPermission,
  openNotification,
} from '../../../../../common/utils';
import CommonPreview from '../../../../../components/CommonPreview';
import CommonTable from '../../../../../components/CommonTable';
import SearchComponent from '../../../../../components/SearchComponent';
import {
  CREATE_CONTENT_MASTER,
  UPDATE_CONTENT_MASTER,
} from '../graphql/Mutations';
import {
  CONTENT_MASTERS_ADMIN,
  GET_CONTENT_MASTER_IMAGE_SIGNED_PUT_URL,
} from '../graphql/Queries';
import ContentMasterModal from './ContentMasterModal';

const initialPaginationValue = {
  total: 0,
  current: 1,
  pageSize: 10,
};

const initialFilter = {
  limit: LIMIT,
  search: '',
  skip: 0,
};

const initialSort = {
  field: 'createdAt',
  order: 'DESC',
};

const ContentMasterTable = ({
  isContentMasterModalOpen,
  setIsContentMasterModalOpen,
  contentMasterImages,
  setContentMasterImages,
}) => {
  const {
    state: { permissions },
  } = useContext(AppContext);
  const [form] = Form.useForm();
  const [contentMasterList, setContentMasterList] = useState([]);
  const [contentMasterSearchTerm, setContentMasterSearchTerm] = useState('');
  const [isEmptyContentMasterList, setIsEmptyContentMasterList] = useState(
    false,
  );
  const [contentMaster, setContentMaster] = useState();
  const [query, setQuery] = useState('');
  const [sortedInfo, setSortedInfo] = useState({});
  const [paginationProp, setPaginationProp] = useState(initialPaginationValue);
  const [isArchivePrompts, setIsArchivePrompts] = useState(false);
  const [isContentMasterLoading, setIsContentMasterLoading] = useState(true);
  const [isArchiveLoading, setIsArchiveLoading] = useState(false);
  const [isArchiveFilter, setIsArchiveFilter] = useState(false);
  const [isBtnLoading, setIsBtnLoading] = useState(false);
  const [visible, setVisible] = useState(false);
  const [contentMasterPreviewImage, setContentMasterPreviewImage] = useState();

  const [createContentMaster] = useMutation(CREATE_CONTENT_MASTER, {
    onError: () => {},
  });

  const [updateContentMaster] = useMutation(UPDATE_CONTENT_MASTER, {
    onError: () => {},
  });

  const [getContentMasterImageSignedPutUrl] = useLazyQuery(
    GET_CONTENT_MASTER_IMAGE_SIGNED_PUT_URL,
    {
      fetchPolicy: 'network-only',
      onError() {
        setIsBtnLoading(false);
      },
    },
  );

  const [contentMastersAdmin] = useLazyQuery(CONTENT_MASTERS_ADMIN, {
    onCompleted: (response) => {
      setContentMasterList([...response?.contentMastersAdmin?.data]);
      if (
        response?.contentMastersAdmin?.count === 0 &&
        initialPaginationValue?.total === 0
      ) {
        setIsEmptyContentMasterList(true);
      } else {
        setIsEmptyContentMasterList(false);
      }
      const pagination = {
        ...paginationProp,
        defaultPageSize: LIMIT,
        total: response?.contentMastersAdmin?.count,
      };
      setPaginationProp(pagination);
      setIsContentMasterLoading(false);
    },
    fetchPolicy: 'network-only',
    onError() {},
  });

  const hasUpdatePermission = hasPermission(
    PERMISSIONS_KEY?.MASTER_LIST_CONTENT,
    PERMISSION_TYPE?.UPDATE,
    permissions,
  );

  useEffect(() => {
    setPaginationProp({ ...paginationProp, current: 1, skip: 0 });
    setIsContentMasterLoading(true);
    contentMastersAdmin({
      variables: {
        filter: {
          ...initialFilter,
          limit: paginationProp?.pageSize || LIMIT,
          search: contentMasterSearchTerm,
          isArchived: isArchiveFilter,
        },
        sort: sortedInfo?.column
          ? {
              field: sortedInfo?.field,
              order: sortedInfo?.order === 'ascend' ? SORT?.ASC : SORT?.DESC,
            }
          : initialSort,
      },
    });
  }, [isArchiveFilter]);

  const handleTableChange = (pagination, tableFilter, sorter) => {
    const { current } = pagination;
    const skip = (current - 1) * (pagination?.pageSize || 0);
    setPaginationProp({ ...paginationProp, ...pagination });
    setIsContentMasterLoading(true);
    setSortedInfo(sorter);
    contentMastersAdmin({
      variables: {
        filter: {
          ...initialFilter,
          skip,
          limit: pagination?.pageSize,
          search: contentMasterSearchTerm,
          isArchived: isArchiveFilter,
        },
        sort: sorter?.column
          ? {
              field: sorter?.field,
              order: sorter?.order === 'ascend' ? SORT?.ASC : SORT?.DESC,
            }
          : initialSort,
      },
    });
  };

  const handleSearch = (value) => {
    const trimValue = value?.trim();
    setContentMasterSearchTerm(trimValue);
    setPaginationProp({ ...paginationProp, current: 1, skip: 0 });
    setIsContentMasterLoading(true);
    contentMastersAdmin({
      variables: {
        filter: {
          ...initialFilter,
          limit: paginationProp?.pageSize || LIMIT,
          search: trimValue,
          isArchived: isArchiveFilter,
        },
        sort: sortedInfo?.column
          ? {
              field: sortedInfo?.field,
              order: sortedInfo?.order === 'ascend' ? SORT?.ASC : SORT?.DESC,
            }
          : initialSort,
      },
    });
  };

  const handleArchive = async (id, isArchived) => {
    setIsArchiveLoading(true);
    const response = await updateContentMaster({
      variables: {
        where: {
          id,
        },
        data: {
          isArchived: !isArchived,
        },
      },
    });
    if (response?.data) {
      setIsArchiveLoading(false);
      setIsContentMasterLoading(true);
      setIsArchivePrompts(false);
      setContentMaster();
      contentMastersAdmin({
        variables: {
          filter: {
            ...initialFilter,
            skip:
              (paginationProp?.current - 1) * (paginationProp?.pageSize || 0),
            limit: paginationProp?.pageSize || LIMIT,
            search: contentMasterSearchTerm,
            isArchived: isArchiveFilter,
          },
          sort: sortedInfo?.column
            ? {
                field: sortedInfo?.field,
                order: sortedInfo?.order === 'ascend' ? SORT?.ASC : SORT?.DESC,
              }
            : initialSort,
        },
      });
    }
    setIsArchiveLoading(false);
  };

  const handleContentMaster = async (values) => {
    setIsBtnLoading(true);
    const uuid = uuidv4();
    let response;
    let image = '';

    if (values?.image?.length > 0 && !contentMasterImages?.[0]?.url) {
      const { name } = values?.image?.[0];
      const ext = name?.substring(name?.lastIndexOf('.') + 1);
      const timestamp = Date?.now();
      const filename = name?.split('.')?.slice(0, -1)?.join('.');
      const newFilename = `${timestamp}_${filename}.${ext}`;
      const fileKey = `content-master/${uuid}/${newFilename}`;

      const res = await getContentMasterImageSignedPutUrl({
        variables: {
          data: {
            fileName: fileKey,
            imageUuid: uuid,
          },
        },
      });
      if (res?.data) {
        try {
          try {
            await fileUpload(
              res?.data?.getContentMasterImageSignedPutUrl?.signedUrl,
              contentMasterImages?.[0]?.originFileObj,
            );
            image = res?.data?.getContentMasterImageSignedPutUrl?.fileName;
          } catch (error) {
            throw new Error(
              `${contentMasterImages?.name} upload failed. Please try again.`,
            );
          }
        } catch (error) {
          setIsBtnLoading(false);
          openNotification('error', error.message);
        }
      }
    }
    try {
      if (!contentMaster) {
        response = await createContentMaster({
          variables: {
            data: {
              key: values?.key?.trim(),
              value: values?.value?.trim(),
              description: values?.description?.trim(),
              imageUuid: uuid,
              image: contentMasterImages?.[0]?.url ? undefined : image,
            },
          },
        });
      } else {
        response = await updateContentMaster({
          variables: {
            where: {
              id: contentMaster?.id,
            },
            data: {
              key: values?.key?.trim(),
              value: values?.value?.trim(),
              description: values?.description?.trim(),
              imageUuid: contentMaster?.imageUuid,
              image: contentMasterImages?.[0]?.url ? undefined : image,
            },
          },
        });
      }
    } catch (error) {
      setIsBtnLoading(false);
      return error;
    }
    if (response?.data) {
      setIsContentMasterModalOpen(false);
      setIsBtnLoading(true);
      setContentMaster();
      form?.resetFields();
      contentMastersAdmin({
        variables: {
          filter: {
            ...initialFilter,
            skip:
              (paginationProp?.current - 1) * (paginationProp?.pageSize || 0),
            limit: paginationProp?.pageSize || LIMIT,
            search: contentMasterSearchTerm,
            isArchived: isArchiveFilter,
          },
          sort: sortedInfo?.column
            ? {
                field: sortedInfo?.field,
                order: sortedInfo?.order === 'ascend' ? SORT?.ASC : SORT?.DESC,
              }
            : initialSort,
        },
      });
    }
    setIsBtnLoading(false);
  };

  const columns = [
    {
      title: 'KEY',
      dataIndex: 'key',
      key: 'key',
      ellipsis: true,
      width: 150,
      align: 'left',
      // eslint-disable-next-line no-undef
      fixed: window.innerWidth > BREAKPOINTS.desktop ? 'left' : false,
      className: 'max-width-column',
      render: (_, record) => record?.key?.replace(/\s/g, '\u00a0'),
    },
    {
      title: 'LABEL',
      dataIndex: 'value',
      key: 'value',
      ellipsis: true,
      width: 150,
      align: 'left',
      className: 'max-width-column',
      render: (_, record) => record?.value?.replace(/\s/g, '\u00a0'),
    },
    {
      title: 'DESCRIPTION',
      dataIndex: 'description',
      key: 'description',
      ellipsis: true,
      width: 150,
      align: 'left',
      className: 'max-width-column table-tooltip-cell',
      render: (_, record) => (
        <Tooltip
          title={record?.description ? record?.description : 'No Description'}
          placement="topLeft"
          trigger="click"
          className="pointer"
        >
          {record?.description || '-'}
        </Tooltip>
      ),
    },
    {
      title: 'IMAGE',
      dataIndex: 'image',
      key: 'image',
      ellipsis: true,
      width: 100,
      align: 'left',
      className: 'max-width-column',
      render: (_, record) => (
        <span>
          {record?.image ? (
            <>
              <Image
                preview={{
                  visible: false,
                }}
                width={20}
                height={20}
                src={record?.image}
                onClick={(e) => {
                  e?.stopPropagation();
                  setVisible(true);
                  setContentMasterPreviewImage(record?.image);
                }}
                className="pointer"
              />
            </>
          ) : (
            <span>No Image</span>
          )}
        </span>
      ),
    },
    {
      title: 'CREATED AT',
      dataIndex: 'createdAt',
      key: 'createdAt',
      width: 150,
      ellipsis: true,
      align: 'left',
      className: 'max-width-column',
      render: (_, record) =>
        formatDate(record?.createdAt, DATE_FORMATS?.REGULAR) || '-',
    },
    {
      title: 'ACTIONS',
      dataIndex: 'actions',
      key: 'actions',
      ellipsis: true,
      width: 150,
      // eslint-disable-next-line no-undef
      fixed: window.innerWidth > BREAKPOINTS.desktop ? 'right' : false,
      render: (_, record) => (
        <>
          <div className="action-button">
            {isArchiveFilter ? (
              <Tooltip title="Unarchive" placement="top" zIndex={4}>
                <UnarchiveIcon
                  onClick={() => {
                    setIsArchivePrompts(true);
                    setContentMaster(record);
                  }}
                  className="mr-16 pointer svg-icon"
                />
              </Tooltip>
            ) : (
              <>
                <Tooltip title="Edit" placement="top" zIndex={4}>
                  <EditIcon
                    onClick={() => {
                      setIsContentMasterModalOpen(true);
                      setContentMaster(record);
                      setContentMasterImages(
                        record?.image ? [{ url: record?.image }] : [],
                      );
                    }}
                    className="mr-16 pointer svg-icon"
                  />
                </Tooltip>
                <Tooltip title="Archive" placement="top" zIndex={4}>
                  <ArchiveIcon
                    onClick={() => {
                      setIsArchivePrompts(true);
                      setContentMaster(record);
                    }}
                    className="mr-16 pointer svg-icon"
                  />
                </Tooltip>
              </>
            )}
          </div>
        </>
      ),
    },
  ]?.filter((item) => {
    if (item?.dataIndex === 'actions' && !hasUpdatePermission) {
      return item?.hidden;
    }
    return !item?.hidden;
  });

  const locale = {
    emptyText: isEmptyContentMasterList ? '' : <span />,
  };

  return (
    <>
      <Modal
        title="Caution"
        okText="Yes"
        cancelText="No"
        open={isArchivePrompts}
        onOk={() => handleArchive(contentMaster?.id, contentMaster?.isArchived)}
        onCancel={() => {
          setIsArchivePrompts(false);
          setContentMaster();
        }}
        okButtonProps={{ loading: isArchiveLoading }}
      >
        Are you sure you want to
        <strong>
          {' '}
          {!contentMaster?.isArchived
            ? ARCHIVING_STATE?.ARCHIVED
            : ARCHIVING_STATE?.UNARCHIVED}{' '}
        </strong>
        this content master?
      </Modal>
      <CommonPreview
        visible={visible}
        setVisible={setVisible}
        image={contentMasterPreviewImage}
        setImage={setContentMasterPreviewImage}
      />
      <ContentMasterModal
        form={form}
        onFinish={handleContentMaster}
        loadings={isBtnLoading}
        isModalOpen={isContentMasterModalOpen}
        setIsModalOpen={setIsContentMasterModalOpen}
        contentMaster={contentMaster}
        setContentMaster={setContentMaster}
        contentMasterImages={contentMasterImages}
        setContentMasterImages={setContentMasterImages}
        setVisible={setVisible}
        setContentMasterPreviewImage={setContentMasterPreviewImage}
        title={contentMaster ? 'Edit Content Master' : 'Add New Content Master'}
      />
      <div className="d-flex justify-end mb-16 flex-wrap">
        {hasUpdatePermission && (
          <div className="mr-16 d-flex align-center">
            <h4 className="m-0 mr-8">Only Archived</h4>{' '}
            <Switch
              size="small"
              onChange={(checked) => setIsArchiveFilter(checked)}
            />
          </div>
        )}
        <SearchComponent
          className="search-box"
          query={query}
          setQuery={setQuery}
          getData={handleSearch}
        />
      </div>
      <CommonTable
        locale={locale}
        columns={columns}
        data={contentMasterList || []}
        loading={isContentMasterLoading}
        onChange={handleTableChange}
        paginationConfig={paginationProp}
        rowKey={(record) => record?.id}
      />
    </>
  );
};

export default ContentMasterTable;
