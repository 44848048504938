import { gql } from '@apollo/client';

// eslint-disable-next-line import/prefer-default-export
export const BAGS_REQUESTS_LIBRARY = gql`
  query RequestsLibrary(
    $filter: RequestsLibraryFilterInput
    $sort: RequestsLibrarySort
  ) {
    requestsLibrary(filter: $filter, sort: $sort) {
      count
      data {
        id
        createdAt
        metaData {
          ... on BagRequest {
            quantity
            size
            name
            image
            type
          }
        }
        library {
          name
        }
        status
        isArchived
        requestNumber
      }
    }
  }
`;

export const NEW_TOYS_REQUESTS_LIBRARY = gql`
  query RequestsLibrary(
    $filter: RequestsLibraryFilterInput
    $sort: RequestsLibrarySort
  ) {
    requestsLibrary(filter: $filter, sort: $sort) {
      count
      data {
        library {
          name
        }
        id
        createdAt
        metaData {
          ... on NewToy {
            brand
            productName
            url
          }
        }
        isArchived
        status
        requestNumber
      }
    }
  }
`;

export const CLOSURE_REQUESTS_LIBRARY = gql`
  query RequestsLibrary(
    $sort: RequestsLibrarySort
    $filter: RequestsLibraryFilterInput
  ) {
    requestsLibrary(sort: $sort, filter: $filter) {
      count
      data {
        id
        requestNumber
        status
        createdAt
        library {
          name
          backUpLibrary {
            name
          }
        }
        metaData {
          ... on ClosureRequest {
            fromDate
            toDate
          }
        }
        description
        updatedAt
        isArchived
      }
    }
  }
`;

export const LIBRARY_DAMAGE_REQUESTS_LIBRARY = gql`
  query RequestsLibrary(
    $sort: RequestsLibrarySort
    $filter: RequestsLibraryFilterInput
  ) {
    requestsLibrary(sort: $sort, filter: $filter) {
      count
      data {
        id
        videoUrl
        images {
          key
          url
        }
        damager {
          userNumber
        }
        library {
          name
        }
        createdBy {
          userNumber
        }
        requestNumber
        returnOrderItemDetails {
          contentHistory {
            id
            newContent {
              id
              quantity
              value
            }
            oldContent {
              id
              quantity
              value
            }
            status
            createdAt
          }
          returnOrder {
            deliveryPartner {
              firstName
              lastName
            }
          }
          libraryInventory {
            toy {
              name
            }
          }
        }
        createdAt
        description
        status
        metaData {
          ... on LibraryDamage {
            actionsByLibrary {
              comment
            }
            comment
            returnOrderItemId
          }
        }
      }
    }
  }
`;

export const LIBRARIES = gql`
  query Libraries($filter: LibraryFilter, $sort: LibrarySort) {
    libraries(filter: $filter, sort: $sort) {
      count
      data {
        id
        name
      }
    }
  }
`;

export const USERS = gql`
  query Users($filter: UsersFilter, $sort: UsersSort) {
    users(filter: $filter, sort: $sort) {
      count
      data {
        firstName
        lastName
        id
      }
    }
  }
`;

export const BAGS = gql`
  query Bags($filter: BagFilter, $sort: BagSort) {
    bags(filter: $filter, sort: $sort) {
      count
      data {
        id
        isArchived
        name
        size
        image
      }
    }
  }
`;
