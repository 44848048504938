import { gql } from '@apollo/client';

// eslint-disable-next-line import/prefer-default-export
export const REQUEST_UNFULFILLED_ORDER_EXPORT = gql`
  mutation RequestUnfulfilledOrderExport(
    $data: RequestExportInput!
    $filter: UnfulfilledOrderExportFilter
  ) {
    requestUnfulfilledOrderExport(data: $data, filter: $filter) {
      message
    }
  }
`;
