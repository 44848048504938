import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import AccessControl from '../../../../AccessControl';
import Error404 from '../../../../Error404';
import { PERMISSIONS_KEY, ROUTES } from '../../../../common/constants';
import './forest.less';
import AnnuallyForest from './pages/AnnuallyForest';
import EditAnnuallyForest from './pages/EditAnnuallyForest';
import EditForest from './pages/EditForest';
import EditQuarterlyForest from './pages/EditQuarterlyForest';
import EditSemiAnnualForest from './pages/EditSemiAnnualForest';
import MonthlyForest from './pages/MonthlyForest';
import QuarterlyForest from './pages/QuarterlyForest';
import SemiAnnualForest from './pages/SemiAnnualForest';

const ForestWrapper = () => (
  <Switch>
    <Route
      exact
      path={`${ROUTES?.SUBSCRIPTION_PLANS}${ROUTES?.FOREST}`}
      render={() => (
        <AccessControl permissionKey={PERMISSIONS_KEY?.SUBSCRIPTION_PLANS}>
          <Redirect
            to={`${ROUTES?.SUBSCRIPTION_PLANS}${ROUTES?.FOREST}${ROUTES?.QUARTERLY}`}
          />
        </AccessControl>
      )}
    />
    <Route
      exact
      path={`${ROUTES?.SUBSCRIPTION_PLANS}${ROUTES?.FOREST}${ROUTES?.MONTHLY}`}
      render={(props) => (
        <AccessControl permissionKey={PERMISSIONS_KEY?.SUBSCRIPTION_PLANS}>
          <MonthlyForest {...props} />
        </AccessControl>
      )}
    />
    <Route
      exact
      path={`${ROUTES?.SUBSCRIPTION_PLANS}${ROUTES?.FOREST}${ROUTES?.MONTHLY}/edit`}
      render={(props) => (
        <AccessControl permissionKey={PERMISSIONS_KEY?.SUBSCRIPTION_PLANS}>
          <EditForest {...props} />
        </AccessControl>
      )}
    />
    <Route
      exact
      path={`${ROUTES?.SUBSCRIPTION_PLANS}${ROUTES?.FOREST}${ROUTES?.QUARTERLY}`}
      render={(props) => (
        <AccessControl permissionKey={PERMISSIONS_KEY?.SUBSCRIPTION_PLANS}>
          <QuarterlyForest {...props} />
        </AccessControl>
      )}
    />
    <Route
      exact
      path={`${ROUTES?.SUBSCRIPTION_PLANS}${ROUTES?.FOREST}${ROUTES?.QUARTERLY}/edit`}
      render={(props) => (
        <AccessControl permissionKey={PERMISSIONS_KEY?.SUBSCRIPTION_PLANS}>
          <EditQuarterlyForest {...props} />
        </AccessControl>
      )}
    />
    <Route
      exact
      path={`${ROUTES?.SUBSCRIPTION_PLANS}${ROUTES?.FOREST}${ROUTES?.SEMI_ANNUAL}`}
      render={(props) => (
        <AccessControl permissionKey={PERMISSIONS_KEY?.SUBSCRIPTION_PLANS}>
          <SemiAnnualForest {...props} />
        </AccessControl>
      )}
    />
    <Route
      exact
      path={`${ROUTES?.SUBSCRIPTION_PLANS}${ROUTES?.FOREST}${ROUTES?.SEMI_ANNUAL}/edit`}
      render={(props) => (
        <AccessControl permissionKey={PERMISSIONS_KEY?.SUBSCRIPTION_PLANS}>
          <EditSemiAnnualForest {...props} />
        </AccessControl>
      )}
    />
    <Route
      exact
      path={`${ROUTES?.SUBSCRIPTION_PLANS}${ROUTES?.FOREST}${ROUTES?.ANNUALLY}`}
      render={(props) => (
        <AccessControl permissionKey={PERMISSIONS_KEY?.SUBSCRIPTION_PLANS}>
          <AnnuallyForest {...props} />
        </AccessControl>
      )}
    />
    <Route
      exact
      path={`${ROUTES?.SUBSCRIPTION_PLANS}${ROUTES?.FOREST}${ROUTES?.ANNUALLY}/edit`}
      render={(props) => (
        <AccessControl permissionKey={PERMISSIONS_KEY?.SUBSCRIPTION_PLANS}>
          <EditAnnuallyForest {...props} />
        </AccessControl>
      )}
    />
    <Route path="*" exact component={Error404} />
  </Switch>
);

export default ForestWrapper;
