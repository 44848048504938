import { Button, Divider, Form } from 'antd';
import React, { useEffect } from 'react';
import ModalComponent from '../../../components/ModalComponent';
import AgeGroupSelect from '../../../modules/lists/modules/toy/components/AgeGroupSelect';
import CategorySelect from '../../../modules/lists/modules/toy/components/CategorySelect';
import BrandSelect from './BrandSelect';
import FacilitatesSelect from './FacilitatesSelect';
import MaterialSelect from './MaterialSelect';
import TagsSelect from './TagsSelect';

const { Item } = Form;

const AllFilterModal = ({
  isModalOpen,
  setIsModalOpen,
  onFinish,
  finalWhereFilters,
  setFinalWhereFilters,
}) => {
  const [form] = Form.useForm();

  const handleReset = () => {
    setIsModalOpen(false);
    form?.resetFields();
    setFinalWhereFilters();
    form?.submit();
  };

  useEffect(() => {
    form?.setFieldsValue({
      ...finalWhereFilters,
    });
  }, [isModalOpen]);

  return (
    <>
      <ModalComponent
        open={isModalOpen}
        onCancel={() => setIsModalOpen(false)}
        title="Filter"
        forceRender
        destroyOnClose
        footer={null}
      >
        <Divider />
        <div className="mb-16">
          <Form
            form={form}
            onFinish={onFinish}
            autoComplete="off"
            layout="vertical"
          >
            <Item label="Brand" name="brand">
              <BrandSelect />
            </Item>
            <Item label="Age Groups" name="ageGroup">
              <AgeGroupSelect />
            </Item>
            <Item label="Categories" name="category">
              <CategorySelect />
            </Item>
            <Item label="Material" name="material">
              <MaterialSelect />
            </Item>
            <Item label="Facilitates" name="facilitates">
              <FacilitatesSelect />
            </Item>
            <Item label="Tags" name="tags">
              <TagsSelect />
            </Item>
          </Form>
          <div className="d-flex justify-end">
            <Button htmlType="submit" onClick={handleReset} className="mr-8">
              Reset
            </Button>
            <Button key="submit" type="primary" onClick={form?.submit}>
              Apply
            </Button>
          </div>
        </div>
      </ModalComponent>
    </>
  );
};

export default AllFilterModal;
