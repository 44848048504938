import { useMutation } from '@apollo/client';
import { Button, Form } from 'antd';
import React, { useContext, useState } from 'react';
import { AppContext } from '../../../../../AppContext';
import {
  CUSTOM_REPORTS_DATA,
  CUSTOM_REPORTS_KEYS,
  EXPORT_TYPES,
} from '../../../../../common/constants';
import CommonTable from '../../../../../components/CommonTable';
import ExportDateRangeModal from '../../../../../components/ExportDateRangeModal';
import {
  REQUEST_TOY_USAGE_REPORT_EXPORT,
  REQUEST_USER_REVENUE_REPORT_EXPORT,
} from '../graphql/Mutations';

const CustomReportsTable = () => {
  const {
    state: { isDesktop },
  } = useContext(AppContext);
  const [form] = Form.useForm();
  const [isLoading, setIsLoading] = useState({});
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [exportData, setExportData] = useState({});

  const [requestToyUsageReportExport] = useMutation(
    REQUEST_TOY_USAGE_REPORT_EXPORT,
    {
      onError: () => {},
    },
  );

  const [requestUserRevenueReportExport] = useMutation(
    REQUEST_USER_REVENUE_REPORT_EXPORT,
    {
      onError: () => {},
    },
  );

  const handleExport = async (values) => {
    setIsLoading((prevLoadingState) => ({
      ...prevLoadingState,
      [exportData.id]: true,
    }));
    const response = await requestUserRevenueReportExport({
      variables: {
        data: {
          type: EXPORT_TYPES?.REVENUE_REPORTS,
        },
        filter: {
          dateRange: {
            from: values?.date?.[0]?.startOf('day'),
            to: values?.date?.[1]?.endOf('day'),
          },
        },
      },
    });
    if (response?.data) {
      setIsModalOpen(false);
      form?.resetFields();
    }
    setIsLoading((prevLoadingState) => ({
      ...prevLoadingState,
      [exportData.id]: false,
    }));
  };

  const handleExportToy = async (id) => {
    setIsLoading((prevLoadingState) => ({
      ...prevLoadingState,
      [id]: true,
    }));
    await requestToyUsageReportExport({
      variables: {
        data: {
          type: EXPORT_TYPES?.TOY_REPORTS,
        },
      },
    });
    setIsLoading((prevLoadingState) => ({
      ...prevLoadingState,
      [id]: false,
    }));
  };

  const columns = [
    {
      title: 'REPORTS',
      dataIndex: 'reports',
      key: 'reports',
      width: 150,
      ellipsis: true,
      align: 'left',
      className: 'max-width-column',
      render: (_, record) => record?.name,
    },
    {
      title: 'ACTIONS',
      dataIndex: 'actions',
      key: 'actions',
      ellipsis: true,
      width: 150,
      render: (_, record) => (
        <div className="action-button">
          <Button
            className="table-action-primary-btn"
            type="primary"
            onClick={() => {
              setExportData(record);
              if (record?.key === CUSTOM_REPORTS_KEYS?.TOY_REPORT) {
                handleExportToy(record?.id);
              } else {
                setIsModalOpen(true);
              }
            }}
            loading={isLoading?.[record?.id]}
          >
            Download
          </Button>
        </div>
      ),
    },
  ];

  return (
    <>
      <ExportDateRangeModal
        form={form}
        title={
          exportData?.key === CUSTOM_REPORTS_KEYS?.TOY_REPORT
            ? 'Toy Report Export'
            : 'Revenue Export'
        }
        onFinish={handleExport}
        loadings={isLoading?.[exportData?.id]}
        isModalOpen={isModalOpen}
        setIsModalOpen={setIsModalOpen}
        isDesktop={isDesktop}
      />
      <CommonTable columns={columns} dataSource={CUSTOM_REPORTS_DATA} />
    </>
  );
};

export default CustomReportsTable;
