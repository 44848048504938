import { Button, DatePicker, Divider, Form, Radio } from 'antd';
import { map } from 'lodash';
import React, { useEffect } from 'react';
import {
  COUPON_TYPE,
  DATE_FORMATS,
  USER_FORM_STATUS,
} from '../../../../../common/constants';
import ModalComponent from '../../../../../components/ModalComponent';

const { Item } = Form;
const { RangePicker } = DatePicker;
const { Group } = Radio;

const AllFilterCouponModal = ({
  isModalOpen,
  setIsModalOpen,
  onFinish,
  finalWhereFilters,
  setFinalWhereFilters,
}) => {
  const [form] = Form.useForm();

  const handleReset = () => {
    setIsModalOpen(false);
    form?.resetFields();
    setFinalWhereFilters();
    form?.submit();
  };

  useEffect(() => {
    form?.setFieldsValue({
      ...finalWhereFilters,
      createdAt: [
        finalWhereFilters?.createdAt?.from,
        finalWhereFilters?.createdAt?.to,
      ],
      type: finalWhereFilters?.type,
      status: finalWhereFilters?.status,
    });
  }, [isModalOpen]);

  return (
    <>
      <ModalComponent
        open={isModalOpen}
        onCancel={() => setIsModalOpen(false)}
        title="Filter"
        forceRender
        destroyOnClose
        footer={null}
      >
        <Divider />
        <div className="mb-16">
          <Form
            form={form}
            onFinish={onFinish}
            autoComplete="off"
            layout="vertical"
          >
            <Item label="Request Date" name="createdAt">
              <RangePicker
                format={DATE_FORMATS?.REGULAR}
                className="full-width"
                popupClassName="picker-with-scroll"
                placeholder={['From Date', 'To Date']}
              />
            </Item>
            <Item label="Coupon Type" name="type">
              <Group
                options={map(COUPON_TYPE, (item) => ({
                  label: item?.label,
                  value: item?.value,
                }))}
              />
            </Item>
            <Item label="Status" name="status">
              <Group options={USER_FORM_STATUS} />
            </Item>
          </Form>
          <div className="d-flex justify-end">
            <Button htmlType="submit" onClick={handleReset} className="mr-8">
              Reset
            </Button>
            <Button key="submit" type="primary" onClick={form?.submit}>
              Apply
            </Button>
          </div>
        </div>
      </ModalComponent>
    </>
  );
};

export default AllFilterCouponModal;
