import { useLazyQuery, useMutation } from '@apollo/client';
import { Modal } from 'antd';
import React, { useContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { AppContext } from '../../../../../AppContext';
import {
  BREAKPOINTS,
  LIMIT,
  PERMISSIONS_KEY,
  PERMISSION_TYPE,
  ROUTES,
  SORT,
} from '../../../../../common/constants';
import { hasPermission } from '../../../../../common/utils';
import CommonTable from '../../../../../components/CommonTable';
import SearchComponent from '../../../../../components/SearchComponent';
import LibrarySelect from '../../library/components/LibrarySelect';
import { ASSIGN_LIBRARY_TO_PINCODE } from '../graphql/Mutations';
import { PINCODES } from '../graphql/Queries';

const initialPaginationValue = {
  total: 0,
  current: 1,
  pageSize: 10,
};

const initialPincodeFilter = {
  limit: LIMIT,
  search: '',
  skip: 0,
};

const initialPincodeSort = {
  field: 'pincode',
  order: 'ASC',
};

const PincodeTable = ({ exportFilter, setExportFilter }) => {
  const {
    state: {
      permissions,
      commonPermissions: { isLibraryPermission },
    },
  } = useContext(AppContext);
  const history = useHistory();
  const [pincodeList, setPincodeList] = useState([]);
  const [pincodeSearchTerm, setPincodeSearchTerm] = useState('');
  const [isEmptyPincodeList, setIsEmptyPincodeList] = useState(false);
  const [query, setQuery] = useState('');
  const [sortedInfo, setSortedInfo] = useState({});
  const [paginationProp, setPaginationProp] = useState(initialPaginationValue);
  const [isAssignLibraryLoading, setIsAssignLibraryLoading] = useState(false);
  const [isAssignLibraryPrompts, setIsAssignLibraryPrompts] = useState(false);
  const [libraryData, setLibraryData] = useState();
  const [pincode, setPincode] = useState();
  const [isPincodeLoading, setIsPincodeLoading] = useState(true);

  const [pincodes] = useLazyQuery(PINCODES, {
    onCompleted: (response) => {
      setPincodeList([...response?.pincodes?.data]);
      if (
        response?.pincodes?.count === 0 &&
        initialPaginationValue?.total === 0
      ) {
        setIsEmptyPincodeList(true);
      } else {
        setIsEmptyPincodeList(false);
      }
      const pagination = {
        ...paginationProp,
        defaultPageSize: LIMIT,
        total: response?.pincodes?.count,
      };
      setPaginationProp(pagination);
      setIsPincodeLoading(false);
    },
    fetchPolicy: 'network-only',
    onError() {},
  });

  const [assignLibraryToPincode] = useMutation(ASSIGN_LIBRARY_TO_PINCODE, {
    onError: () => {},
  });

  useEffect(() => {
    setIsPincodeLoading(true);
    pincodes({
      variables: {
        filter: initialPincodeFilter,
        sort: initialPincodeSort,
      },
    });
  }, []);

  const handleTableChange = (pagination, tableFilter, sorter) => {
    const { current } = pagination;
    const skip = (current - 1) * (pagination?.pageSize || 0);
    setPaginationProp({ ...paginationProp, ...pagination });
    setIsPincodeLoading(true);
    setSortedInfo(sorter);
    pincodes({
      variables: {
        filter: {
          ...initialPincodeFilter,
          skip,
          limit: pagination?.pageSize,
          search: pincodeSearchTerm,
        },
        sort: sorter?.column
          ? {
              field: sorter?.field,
              order: sorter?.order === 'ascend' ? SORT?.ASC : SORT?.DESC,
            }
          : initialPincodeSort,
      },
    });
  };

  const handleSearch = (value) => {
    const trimValue = value?.trim();
    setPincodeSearchTerm(trimValue);
    setPaginationProp({ ...paginationProp, current: 1, skip: 0 });
    setIsPincodeLoading(true);
    pincodes({
      variables: {
        filter: {
          ...initialPincodeFilter,
          limit: paginationProp?.pageSize || LIMIT,
          search: trimValue,
        },
        sort: sortedInfo?.column
          ? {
              field: sortedInfo?.field,
              order: sortedInfo?.order === 'ascend' ? SORT?.ASC : SORT?.DESC,
            }
          : initialPincodeSort,
      },
    });
    setExportFilter({ ...exportFilter, search: trimValue });
  };

  const handleAssignLibrary = async () => {
    setIsAssignLibraryLoading(true);
    const response = await assignLibraryToPincode({
      variables: {
        where: {
          id: pincode?.id,
        },
        data: {
          libraryId: libraryData?.value,
        },
      },
    });
    if (response?.data) {
      setIsAssignLibraryPrompts(false);
      setIsPincodeLoading(true);
      setPincodeList([]);
      pincodes({
        variables: {
          filter: {
            ...initialPincodeFilter,
            skip:
              (paginationProp?.current - 1) * (paginationProp?.pageSize || 0),
            limit: paginationProp?.pageSize || LIMIT,
            search: pincodeSearchTerm,
          },
          sort: sortedInfo?.column
            ? {
                field: sortedInfo?.field,
                order: sortedInfo?.order === 'ascend' ? SORT?.ASC : SORT?.DESC,
              }
            : initialPincodeSort,
        },
      });
    }
    setIsAssignLibraryLoading(false);
  };

  const columns = [
    {
      title: 'PINCODE',
      dataIndex: 'pincode',
      key: 'pincode',
      ellipsis: true,
      sorter: true,
      width: 150,
      align: 'left',
      className: 'max-width-column',
      render: (_, record) => record?.pincode,
      // eslint-disable-next-line no-undef
      fixed: window.innerWidth > BREAKPOINTS.desktop ? 'left' : false,
    },
    {
      title: 'AREA NAME',
      dataIndex: 'areaName',
      key: 'areaName',
      ellipsis: true,
      sorter: true,
      width: 200,
      align: 'left',
      className: 'max-width-column',
      render: (_, record) => record?.areaName || '-',
    },
    {
      title: 'ASSIGN LIBRARY',
      dataIndex: 'assignLibrary',
      key: 'assignLibrary',
      width: 150,
      align: 'left',
      className: 'max-width-column',
      render: (_, record) => (
        <LibrarySelect
          size="small"
          className="library-select"
          showAction={['click']}
          onChange={(value, data) => {
            setLibraryData(data);
            setPincode(record);
            setIsAssignLibraryPrompts(true);
          }}
          value={record?.library?.id || null}
          libraryData={{
            id: record?.library?.id,
            name: record?.library?.name,
            libraryNumber: record?.library?.libraryNumber,
          }}
          allowClear={false}
          disabled={
            !hasPermission(
              PERMISSIONS_KEY?.MASTER_LIST_PINCODE,
              PERMISSION_TYPE?.UPDATE,
              permissions,
            )
          }
        />
      ),
    },
    {
      title: 'BACKUP LIBRARY',
      dataIndex: 'backupLibrary',
      key: 'backupLibrary',
      ellipsis: true,
      width: 200,
      align: 'left',
      className: `max-width-column`,
      onCell: (record) => ({
        onClick: () =>
          isLibraryPermission &&
          record?.library?.backUpLibrary?.id &&
          history?.push(
            `${ROUTES?.MASTER_LISTS}${ROUTES?.LIBRARY}${ROUTES?.BASIC_DETAILS}/view/${record?.library?.backUpLibrary?.id}/${record?.library?.backUpLibrary?.libraryNumber}`,
          ),
        className: `${
          isLibraryPermission &&
          record?.library?.backUpLibrary?.id &&
          'pointer table-cell'
        }`,
      }),
      render: (_, record) =>
        record?.library?.backUpLibrary?.name ||
        record?.library?.backUpLibrary?.libraryNumber ? (
          <>
            <div className="table-data-with-id">
              {record?.library?.backUpLibrary?.name}
            </div>
            <div className="font-size-12">
              ({record?.library?.backUpLibrary?.libraryNumber})
            </div>
          </>
        ) : (
          '-'
        ),
    },
    {
      title: 'TOTAL USERS UNDER PINCODE',
      dataIndex: 'totalUsers',
      key: 'totalUsers',
      ellipsis: true,
      width: 100,
      align: 'left',
      className: 'max-width-column',
      render: (_, record) => record?.totalUsers || '-',
    },
  ];

  const locale = {
    emptyText: isEmptyPincodeList ? '' : <span />,
  };

  return (
    <>
      <Modal
        title="Caution"
        okText="Yes"
        cancelText="No"
        open={isAssignLibraryPrompts}
        onOk={handleAssignLibrary}
        onCancel={() => setIsAssignLibraryPrompts(false)}
        okButtonProps={{ loading: isAssignLibraryLoading }}
      >
        Are you sure you want to assign
        <strong> {libraryData?.children} </strong>?
      </Modal>
      <div className="pincode-table">
        <div className="d-flex justify-end mb-16 flex-wrap">
          <SearchComponent
            className="search-box"
            query={query}
            setQuery={setQuery}
            getData={handleSearch}
          />
        </div>
        <CommonTable
          locale={locale}
          columns={columns}
          data={pincodeList || []}
          loading={isPincodeLoading}
          onChange={handleTableChange}
          paginationConfig={paginationProp}
          rowKey={(record) => record?.id}
        />
      </div>
    </>
  );
};

export default PincodeTable;
