import { gql } from '@apollo/client';

// eslint-disable-next-line import/prefer-default-export
export const BANNERS_ADMIN = gql`
  query BannersAdmin($filter: BannerFilter, $sort: BannerSort) {
    bannersAdmin(filter: $filter, sort: $sort) {
      count
      data {
        id
        image
        imageUuid
        createdAt
        isArchived
        name
      }
    }
  }
`;

export const GET_BANNER_IMAGE_SIGNED_PUT_URL = gql`
  query GetBannerImageSignedPutUrl($data: GetBannersImageSignedPutUrlInput!) {
    getBannerImageSignedPutUrl(data: $data) {
      fileName
      signedUrl
    }
  }
`;
