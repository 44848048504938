import { gql } from '@apollo/client';

// eslint-disable-next-line import/prefer-default-export
export const USERS = gql`
  query Users($filter: UsersFilter, $sort: UsersSort) {
    users(filter: $filter, sort: $sort) {
      count
      data {
        id
        contactNo
        email
        roles
        createdAt
        isActive
        isArchived
        firstName
        lastName
        agencyName
        vehicleNumber
        userNumber
        latLong {
          coordinates
          type
        }
        subscription {
          subscriptionPlan {
            type
            name
          }
        }
        pincode {
          library {
            id
            name
            libraryNumber
          }
        }
        bankAccounts {
          accountNumber
          holderName
          ifsc
          isDefault
          id
          upiId
          type
        }
      }
    }
  }
`;

export const USER = gql`
  query User($where: UserUniqueInput!) {
    user(where: $where) {
      id
      email
      address
      city
      contactNo
      createdAt
      firstName
      gender
      landmark
      isActive
      isArchived
      lastName
      latLong {
        coordinates
        type
      }
      pincode {
        id
        pincode
        library {
          id
          name
          libraryNumber
        }
      }
      roles
      state
      updatedAt
      agencyName
      vehicleNumber
      userNumber
    }
  }
`;
