import { Empty } from 'antd';
import React from 'react';
import LoaderComponent from '../../../components/LoaderComponent';
import ClosureRequestCard from './ClosureRequestCard';

const ClosureRequestList = ({
  closureReqList,
  infiniteScrollRef,
  isFetchMoreClosureReqLoading,
  isEmptyClosureReqList,
  isClosureReqLoading,
}) => (
  <div>
    {!isEmptyClosureReqList &&
      closureReqList?.map((closureReq) => (
        <ClosureRequestCard
          key={closureReq?.id}
          closureReq={closureReq}
          infiniteScrollRef={infiniteScrollRef}
        />
      ))}
    {(isFetchMoreClosureReqLoading || isClosureReqLoading) && (
      <LoaderComponent size="small" setHeight={10} />
    )}
    {isEmptyClosureReqList && <Empty image={Empty?.PRESENTED_IMAGE_SIMPLE} />}
  </div>
);

export default ClosureRequestList;
