import { Divider, Form, Input } from 'antd';
import React, { useEffect, useState } from 'react';
import { NUMBER_FIELD_TYPES } from '../../../../../common/constants';
import { formValidatorRules } from '../../../../../common/utils';
import ModalComponent from '../../../../../components/ModalComponent';
import NumberComponent from '../../../../../components/NumberComponent';

const { Item } = Form;
const { required, whitespaceNotAllowed, pincodeRule } = formValidatorRules;

const PincodeModal = ({
  isModalOpen,
  setIsModalOpen,
  onFinish,
  loadings,
  form,
  pincode,
  setPincode,
}) => {
  const [isValidateTrigger, setIsValidateTrigger] = useState(false);

  const handleCancel = () => {
    setIsModalOpen(false);
    setPincode();
    form?.resetFields();
  };

  useEffect(() => {
    form?.setFieldsValue({
      pincode: pincode?.pincode,
      areaName: pincode?.areaName,
    });
  }, [pincode]);

  return (
    <>
      <ModalComponent
        open={isModalOpen}
        onCancel={handleCancel}
        onOk={form?.submit}
        title={pincode ? 'Edit Pincode' : 'Add New Pincode'}
        okText="Save"
        confirmLoading={loadings}
        destroyOnClose
        forceRender
      >
        <Divider />
        <Form
          form={form}
          onFinish={(values) => {
            onFinish(values);
            setIsValidateTrigger(true);
          }}
          autoComplete="off"
          layout="vertical"
          validateTrigger={isValidateTrigger ? 'onChange' : 'onSubmit'}
          onFinishFailed={() => setIsValidateTrigger(true)}
        >
          <Item
            name="pincode"
            label="Pincode"
            rules={[
              { required: true, message: 'Please Enter Pincode!' },
              pincodeRule,
            ]}
          >
            <NumberComponent
              allowClear
              placeholder="Enter Pincode"
              isFractional={false}
              type={NUMBER_FIELD_TYPES?.PINCODE}
            />
          </Item>
          <Item
            name="areaName"
            label="Area Name"
            rules={[
              { ...required, message: 'Please Enter Area Name!' },
              whitespaceNotAllowed,
            ]}
          >
            <Input allowClear placeholder="Enter Area Name" maxLength={100} />
          </Item>
        </Form>
      </ModalComponent>
    </>
  );
};

export default PincodeModal;
