import { gql } from '@apollo/client';

// eslint-disable-next-line import/prefer-default-export
export const ASSIGN_DELIVERY_PARTNER = gql`
  mutation AssignDeliveryPartner(
    $where: PincodeUniqueInput!
    $data: AssignDeliveryPartnerInput
  ) {
    assignDeliveryPartner(where: $where, data: $data) {
      message
    }
  }
`;

export const ASSIGN_DELIVERY_PARTNER_TO_RETURN_ORDER = gql`
  mutation AssignDeliveryPartnerToReturnOrder(
    $data: AssignDeliveryPartnerToReturnOrderInput
    $where: AssignDeliveryPartnerToReturnOrderWhereInput!
  ) {
    assignDeliveryPartnerToReturnOrder(data: $data, where: $where) {
      message
    }
  }
`;

export const REQUEST_NEW_ORDER_EXPORT_ADMIN = gql`
  mutation RequestNewOrderExportAdmin(
    $data: RequestExportInput!
    $filter: OrderExportFilter
  ) {
    requestNewOrderExportAdmin(data: $data, filter: $filter) {
      message
    }
  }
`;

export const REQUEST_NEW_RETURN_ORDER_EXPORT_ADMIN = gql`
  mutation RequestNewReturnOrderExportAdmin(
    $data: RequestExportInput!
    $filter: ReturnOrderExportFilter
  ) {
    requestNewReturnOrderExportAdmin(data: $data, filter: $filter) {
      message
    }
  }
`;

export const UPDATE_ORDER_STATUS_ADMIN_V2 = gql`
  mutation UpdateOrderStatusAdminV2(
    $where: UpdateOrderStatusUniqueInput!
    $data: UpdateOrderStatusAdminV2Input!
  ) {
    updateOrderStatusAdminV2(where: $where, data: $data) {
      message
    }
  }
`;

export const UPDATE_ORDER_STATUS_ADMIN = gql`
  mutation UpdateOrderStatusAdmin(
    $where: OrderUniqueInput!
    $data: UpdateOrderStatusLibraryInput!
  ) {
    updateOrderStatusAdmin(where: $where, data: $data) {
      message
    }
  }
`;

export const ASSIGN_DELIVERY_PARTNER_V2 = gql`
  mutation AssignDeliveryPartnerV2(
    $where: UpdateOrderStatusUniqueInput!
    $data: AssignDeliveryPartnerV2
  ) {
    assignDeliveryPartnerV2(where: $where, data: $data) {
      message
    }
  }
`;
