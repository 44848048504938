import { gql } from '@apollo/client';

// eslint-disable-next-line import/prefer-default-export
export const UPDATE_ORDER_STATUS_LIBRARY = gql`
  mutation UpdateOrderStatusLibrary(
    $where: OrderUniqueInput!
    $data: UpdateOrderStatusLibraryInput
  ) {
    updateOrderStatusLibrary(where: $where, data: $data) {
      message
    }
  }
`;

export const CANCEL_ORDER_LIBRARY = gql`
  mutation CancelOrderLibrary(
    $where: OrderUniqueInput!
    $data: CancelledOrderByLibraryInput
  ) {
    cancelOrderLibrary(where: $where, data: $data) {
      message
    }
  }
`;

export const REQUEST_NEW_ORDER_EXPORT = gql`
  mutation RequestNewOrderExport($data: RequestExportInput!) {
    requestNewOrderExport(data: $data) {
      message
    }
  }
`;
