import { gql } from '@apollo/client';

// eslint-disable-next-line import/prefer-default-export
export const AGE_GROUPS_ADMIN = gql`
  query AgeGroupsAdmin($filter: AgeGroupFilter, $sort: AgeGroupSort) {
    ageGroupsAdmin(filter: $filter, sort: $sort) {
      count
      data {
        id
        minAge
        maxAge
        image
        isArchived
        createdAt
      }
    }
  }
`;

export const GET_AGE_GROUP_IMAGE_SIGNED_PUT_URL = gql`
  query GetAgeGroupImageSignedPutUrl(
    $data: GetAgeGroupImageSignedPutUrlInput!
  ) {
    getAgeGroupImageSignedPutUrl(data: $data) {
      signedUrl
      fileName
    }
  }
`;
