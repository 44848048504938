import { gql } from '@apollo/client';

// eslint-disable-next-line import/prefer-default-export
export const USERS = gql`
  query Users($filter: UsersFilter, $sort: UsersSort) {
    users(filter: $filter, sort: $sort) {
      count
      data {
        firstName
        lastName
        id
      }
    }
  }
`;
