import { DownOutlined } from '@ant-design/icons';
import { Card, Col, List, Row, Tree } from 'antd';
import React from 'react';
import { MODULES, ROUTES, TABS } from '../../../../../common/constants';

const readData = [
  {
    title: MODULES?.DASHBOARD,
    key: ROUTES?.MAIN,
  },
  {
    title: MODULES?.PAYMENTS,
    key: ROUTES?.PAYMENTS,
    children: [
      {
        title: MODULES?.END_USER,
        key: `${ROUTES?.PAYMENTS}${ROUTES?.END_USER}`,
        children: [
          {
            title: TABS?.INFLOW?.label,
            key: `${ROUTES?.PAYMENTS}${ROUTES?.END_USER}${ROUTES?.INFLOW}`,
          },
          {
            title: TABS?.OUTFLOW?.label,
            key: `${ROUTES?.PAYMENTS}${ROUTES?.END_USER}${ROUTES?.OUTFLOW}`,
          },
        ],
      },
      {
        title: MODULES?.LIBRARY,
        key: `${ROUTES?.PAYMENTS}${ROUTES?.LIBRARY}`,
      },
    ],
  },
  {
    title: MODULES?.REQUESTS,
    key: ROUTES?.REQUESTS,
    children: [
      {
        title: MODULES?.END_USER,
        key: `${ROUTES?.REQUESTS}${ROUTES?.END_USER}`,
        children: [
          {
            title: TABS?.SUGGESTED_TOY?.label,
            key: `${ROUTES?.REQUESTS}${ROUTES?.END_USER}${ROUTES?.SUGGESTED_TOY}`,
          },
          {
            title: TABS?.CHANGE_ADDRESS?.label,
            key: `${ROUTES?.REQUESTS}${ROUTES?.END_USER}${ROUTES?.CHANGE_ADDRESS}`,
          },
          {
            title: TABS?.REPORT_DAMAGE?.label,
            key: `${ROUTES?.REQUESTS}${ROUTES?.END_USER}${ROUTES?.REPORT_DAMAGE}`,
          },
        ],
      },
      {
        title: MODULES?.LIBRARY,
        key: `${ROUTES?.REQUESTS}${ROUTES?.LIBRARY}`,
        children: [
          {
            title: TABS?.DAMAGES?.label,
            key: `${ROUTES?.REQUESTS}${ROUTES?.LIBRARY}${ROUTES?.DAMAGES}`,
          },
          {
            title: TABS?.CLOSURE_REQUESTS?.label,
            key: `${ROUTES?.REQUESTS}${ROUTES?.LIBRARY}${ROUTES?.CLOSURE_REQUESTS}`,
          },
          {
            title: TABS?.BAGS?.label,
            key: `${ROUTES?.REQUESTS}${ROUTES?.LIBRARY}${ROUTES?.BAGS}`,
          },
          {
            title: TABS?.NEW_TOYS?.label,
            key: `${ROUTES?.REQUESTS}${ROUTES?.LIBRARY}${ROUTES?.NEW_TOYS}`,
          },
        ],
      },
    ],
  },
  {
    title: MODULES?.ORDERS,
    key: ROUTES?.ORDERS,
    children: [
      {
        title: MODULES?.NEW,
        key: `${ROUTES?.ORDERS}${ROUTES?.NEW}`,
        children: [
          {
            title: TABS?.DELIVERY?.label,
            key: `${ROUTES?.ORDERS}${ROUTES?.NEW}${ROUTES?.DELIVERY}`,
          },
          {
            title: TABS?.RETURN?.label,
            key: `${ROUTES?.ORDERS}${ROUTES?.NEW}${ROUTES?.RETURN}`,
          },
        ],
      },
      {
        title: MODULES?.ALL,
        key: `${ROUTES?.ORDERS}${ROUTES?.ALL}`,
        children: [
          {
            title: TABS?.DELIVERY?.label,
            key: `${ROUTES?.ORDERS}${ROUTES?.ALL}${ROUTES?.DELIVERY}`,
          },
          {
            title: TABS?.RETURN?.label,
            key: `${ROUTES?.ORDERS}${ROUTES?.ALL}${ROUTES?.RETURN}`,
          },
        ],
      },
    ],
  },
  {
    title: MODULES?.APP_CONTENT,
    key: `${ROUTES?.APP_CONTENT}${ROUTES.GENRES}`,
    children: [
      {
        title: MODULES?.GENRES,
        key: `${ROUTES?.APP_CONTENT}${ROUTES?.GENRES}`,
      },
      {
        title: MODULES?.COLLECTION,
        key: `${ROUTES?.APP_CONTENT}${ROUTES?.COLLECTION}`,
      },
    ],
  },
  {
    title: MODULES?.REPORTS,
    key: ROUTES?.REPORTS,
    children: [
      {
        title: MODULES?.BULK_UPDATE,
        key: `${ROUTES?.REPORTS}${ROUTES?.BULK_UPDATE}`,
      },
      {
        title: MODULES?.HOLDING_PERIOD,
        key: `${ROUTES?.REPORTS}${ROUTES?.HOLDING_PERIOD}`,
      },
      {
        title: MODULES?.UNFULFILLED_ORDERS,
        key: `${ROUTES?.REPORTS}${ROUTES?.UNFULFILLED_ORDERS}`,
      },
    ],
  },
  {
    title: MODULES?.MASTER_LISTS,
    key: ROUTES?.MASTER_LISTS,
    children: [
      {
        title: MODULES?.LIBRARY,
        key: `${ROUTES?.MASTER_LISTS}${ROUTES?.LIBRARY}`,
        children: [
          {
            title: TABS?.BASIC_DETAILS?.label,
            key: `${ROUTES?.MASTER_LISTS}${ROUTES?.LIBRARY}${ROUTES?.BASIC_DETAILS}`,
          },
          {
            title: TABS?.INVENTORY_DETAILS?.label,
            key: `${ROUTES?.MASTER_LISTS}${ROUTES?.LIBRARY}${ROUTES?.INVENTORY_DETAILS}`,
          },
        ],
      },
      {
        title: MODULES?.TOY,
        key: `${ROUTES?.MASTER_LISTS}${ROUTES?.TOY}`,
      },
      {
        title: MODULES?.PINCODE,
        key: `${ROUTES?.MASTER_LISTS}${ROUTES?.PINCODE}`,
      },
    ],
  },
  {
    title: MODULES?.SYSTEM_USERS,
    key: ROUTES?.SYSTEM_USERS,
    children: [
      {
        title: MODULES?.ADMINS,
        key: `${ROUTES?.SYSTEM_USERS}${ROUTES?.ADMINS}`,
      },
      {
        title: MODULES?.END_USERS,
        key: `${ROUTES?.SYSTEM_USERS}${ROUTES?.END_USERS}`,
      },
      {
        title: MODULES?.DELIVERY_PARTNERS,
        key: `${ROUTES?.SYSTEM_USERS}${ROUTES?.DELIVERY_PARTNERS}`,
      },
    ],
  },
  {
    title: MODULES?.SUBSCRIPTION_PLANS,
    key: ROUTES?.SUBSCRIPTION_PLANS,
    children: [
      {
        title: MODULES?.GARDEN,
        key: `${ROUTES?.SUBSCRIPTION_PLANS}${ROUTES?.GARDEN}`,
        children: [
          {
            title: TABS?.MONTHLY?.label,
            key: `${ROUTES?.SUBSCRIPTION_PLANS}${ROUTES?.GARDEN}${ROUTES?.MONTHLY}`,
          },
          {
            title: TABS?.QUARTERLY?.label,
            key: `${ROUTES?.SUBSCRIPTION_PLANS}${ROUTES?.GARDEN}${ROUTES?.QUARTERLY}`,
          },
          {
            title: TABS?.ANNUALLY?.label,
            key: `${ROUTES?.SUBSCRIPTION_PLANS}${ROUTES?.GARDEN}${ROUTES?.ANNUALLY}`,
          },
        ],
      },
      {
        title: MODULES?.ESTATE,
        key: `${ROUTES?.SUBSCRIPTION_PLANS}${ROUTES?.ESTATE}`,
        children: [
          {
            title: TABS?.MONTHLY?.label,
            key: `${ROUTES?.SUBSCRIPTION_PLANS}${ROUTES?.ESTATE}${ROUTES?.MONTHLY}`,
          },
          {
            title: TABS?.QUARTERLY?.label,
            key: `${ROUTES?.SUBSCRIPTION_PLANS}${ROUTES?.ESTATE}${ROUTES?.QUARTERLY}`,
          },
          {
            title: TABS?.ANNUALLY?.label,
            key: `${ROUTES?.SUBSCRIPTION_PLANS}${ROUTES?.ESTATE}${ROUTES?.ANNUALLY}`,
          },
        ],
      },
      {
        title: MODULES?.FOREST,
        key: `${ROUTES?.SUBSCRIPTION_PLANS}${ROUTES?.FOREST}`,
        children: [
          {
            title: TABS?.MONTHLY?.label,
            key: `${ROUTES?.SUBSCRIPTION_PLANS}${ROUTES?.FOREST}${ROUTES?.MONTHLY}`,
          },
          {
            title: TABS?.QUARTERLY?.label,
            key: `${ROUTES?.SUBSCRIPTION_PLANS}${ROUTES?.FOREST}${ROUTES?.QUARTERLY}`,
          },
          {
            title: TABS?.ANNUALLY?.label,
            key: `${ROUTES?.SUBSCRIPTION_PLANS}${ROUTES?.FOREST}${ROUTES?.ANNUALLY}`,
          },
        ],
      },
    ],
  },
  {
    title: MODULES?.EXPORTS,
    key: ROUTES?.EXPORTS,
  },
  {
    title: MODULES?.NOTIFICATIONS,
    key: ROUTES?.NOTIFICATIONS,
  },
];

const createData = [
  {
    title: MODULES?.PAYMENTS,
    key: ROUTES?.PAYMENTS,
    children: [
      {
        title: MODULES?.END_USER,
        key: `${ROUTES?.PAYMENTS}${ROUTES?.END_USER}`,
        children: [
          {
            title: TABS?.OUTFLOW?.label,
            key: `${ROUTES?.PAYMENTS}${ROUTES?.END_USER}${ROUTES?.OUTFLOW}`,
          },
        ],
      },
      {
        title: MODULES?.LIBRARY,
        key: `${ROUTES?.PAYMENTS}${ROUTES?.LIBRARY}`,
      },
    ],
  },
  {
    title: MODULES?.MASTER_LISTS,
    key: ROUTES?.MASTER_LISTS,
    children: [
      {
        title: MODULES?.TOY,
        key: `${ROUTES?.MASTER_LISTS}${ROUTES?.TOY}`,
      },
    ],
  },
  {
    title: MODULES?.SYSTEM_USERS,
    key: ROUTES?.SYSTEM_USERS,
    children: [
      {
        title: MODULES?.ADMINS,
        key: `${ROUTES?.SYSTEM_USERS}${ROUTES?.ADMINS}`,
      },
      {
        title: MODULES?.DELIVERY_PARTNERS,
        key: `${ROUTES?.SYSTEM_USERS}${ROUTES?.DELIVERY_PARTNERS}`,
      },
    ],
  },
];

const updateData = [
  {
    title: MODULES?.PAYMENTS,
    key: ROUTES?.PAYMENTS,
    children: [
      {
        title: MODULES?.END_USER,
        key: `${ROUTES?.PAYMENTS}${ROUTES?.END_USER}`,
        children: [
          {
            title: TABS?.OUTFLOW?.label,
            key: `${ROUTES?.PAYMENTS}${ROUTES?.END_USER}${ROUTES?.OUTFLOW}`,
          },
        ],
      },
      {
        title: MODULES?.LIBRARY,
        key: `${ROUTES?.PAYMENTS}${ROUTES?.LIBRARY}`,
      },
    ],
  },
  {
    title: MODULES?.REQUESTS,
    key: ROUTES?.REQUESTS,
    children: [
      {
        title: MODULES?.END_USER,
        key: `${ROUTES?.REQUESTS}${ROUTES?.END_USER}`,
        children: [
          {
            title: TABS?.SUGGESTED_TOY?.label,
            key: `${ROUTES?.REQUESTS}${ROUTES?.END_USER}${ROUTES?.SUGGESTED_TOY}`,
          },
          {
            title: TABS?.CHANGE_ADDRESS?.label,
            key: `${ROUTES?.REQUESTS}${ROUTES?.END_USER}${ROUTES?.CHANGE_ADDRESS}`,
          },
          {
            title: TABS?.REPORT_DAMAGE?.label,
            key: `${ROUTES?.REQUESTS}${ROUTES?.END_USER}${ROUTES?.REPORT_DAMAGE}`,
          },
        ],
      },
      {
        title: MODULES?.LIBRARY,
        key: `${ROUTES?.REQUESTS}${ROUTES?.LIBRARY}`,
        children: [
          {
            title: TABS?.DAMAGES?.label,
            key: `${ROUTES?.REQUESTS}${ROUTES?.LIBRARY}${ROUTES?.DAMAGES}`,
          },
          {
            title: TABS?.CLOSURE_REQUESTS?.label,
            key: `${ROUTES?.REQUESTS}${ROUTES?.LIBRARY}${ROUTES?.CLOSURE_REQUESTS}`,
          },
        ],
      },
    ],
  },
  {
    title: MODULES?.ORDERS,
    key: ROUTES?.ORDERS,
    children: [
      {
        title: MODULES?.NEW,
        key: `${ROUTES?.ORDERS}${ROUTES?.NEW}`,
        children: [
          {
            title: TABS?.DELIVERY?.label,
            key: `${ROUTES?.ORDERS}${ROUTES?.NEW}${ROUTES?.DELIVERY}`,
          },
          {
            title: TABS?.RETURN?.label,
            key: `${ROUTES?.ORDERS}${ROUTES?.NEW}${ROUTES?.RETURN}`,
          },
        ],
      },
      {
        title: MODULES?.ALL,
        key: `${ROUTES?.ORDERS}${ROUTES?.ALL}`,
        children: [
          {
            title: TABS?.DELIVERY?.label,
            key: `${ROUTES?.ORDERS}${ROUTES?.ALL}${ROUTES?.DELIVERY}`,
          },
          {
            title: TABS?.RETURN?.label,
            key: `${ROUTES?.ORDERS}${ROUTES?.ALL}${ROUTES?.RETURN}`,
          },
        ],
      },
    ],
  },
  {
    title: MODULES?.REPORTS,
    key: ROUTES?.REPORTS,
    children: [
      {
        title: MODULES?.HOLDING_PERIOD,
        key: `${ROUTES?.REPORTS}${ROUTES?.HOLDING_PERIOD}`,
      },
    ],
  },
  {
    title: MODULES?.MASTER_LISTS,
    key: ROUTES?.MASTER_LISTS,
    children: [
      {
        title: MODULES?.LIBRARY,
        key: `${ROUTES?.MASTER_LISTS}${ROUTES?.LIBRARY}`,
        children: [
          {
            title: TABS?.BASIC_DETAILS?.label,
            key: `${ROUTES?.MASTER_LISTS}${ROUTES?.LIBRARY}${ROUTES?.BASIC_DETAILS}`,
          },
          {
            title: TABS?.INVENTORY_DETAILS?.label,
            key: `${ROUTES?.MASTER_LISTS}${ROUTES?.LIBRARY}${ROUTES?.INVENTORY_DETAILS}`,
          },
        ],
      },
      {
        title: MODULES?.TOY,
        key: `${ROUTES?.MASTER_LISTS}${ROUTES?.TOY}`,
      },
      {
        title: MODULES?.PINCODE,
        key: `${ROUTES?.MASTER_LISTS}${ROUTES?.PINCODE}`,
      },
    ],
  },
  {
    title: MODULES?.SYSTEM_USERS,
    key: ROUTES?.SYSTEM_USERS,
    children: [
      {
        title: MODULES?.ADMINS,
        key: `${ROUTES?.SYSTEM_USERS}${ROUTES?.ADMINS}`,
      },
      {
        title: MODULES?.DELIVERY_PARTNERS,
        key: `${ROUTES?.SYSTEM_USERS}${ROUTES?.DELIVERY_PARTNERS}`,
      },
    ],
  },
  {
    title: MODULES?.NOTIFICATIONS,
    key: ROUTES?.NOTIFICATIONS,
  },
];

const deleteData = [
  {
    title: MODULES?.MASTER_LISTS,
    key: ROUTES?.MASTER_LISTS,
    children: [
      {
        title: MODULES?.LIBRARY,
        key: `${ROUTES?.MASTER_LISTS}${ROUTES?.LIBRARY}`,
        children: [
          {
            title: TABS?.INVENTORY_DETAILS?.label,
            key: `${ROUTES?.MASTER_LISTS}${ROUTES?.LIBRARY}${ROUTES?.INVENTORY_DETAILS}`,
          },
        ],
      },
    ],
  },
];

const PowerAdminPermission = () => (
  <Row gutter={[8, 16]}>
    <Col xs={24} sm={24} md={12} lg={12} xl={6} xxl={6}>
      <List.Item>
        <Card title="Read Only">
          <Tree showLine switcherIcon={<DownOutlined />} treeData={readData} />
        </Card>
      </List.Item>
    </Col>
    <Col xs={24} sm={24} md={12} lg={12} xl={6} xxl={6}>
      <List.Item>
        <Card title="Create Only">
          <Tree
            showLine
            switcherIcon={<DownOutlined />}
            treeData={createData}
          />
        </Card>
      </List.Item>
    </Col>
    <Col xs={24} sm={24} md={12} lg={12} xl={6} xxl={6}>
      <List.Item>
        <Card title="Update (edit & other actions)">
          <Tree
            showLine
            switcherIcon={<DownOutlined />}
            treeData={updateData}
          />
        </Card>
      </List.Item>
    </Col>
    <Col xs={24} sm={24} md={12} lg={12} xl={6} xxl={6}>
      <List.Item>
        <Card title="Delete Only">
          <Tree
            showLine
            switcherIcon={<DownOutlined />}
            treeData={deleteData}
          />
        </Card>
      </List.Item>
    </Col>
  </Row>
);

export default PowerAdminPermission;
