import { PlusOutlined } from '@ant-design/icons';
import { Button, Card } from 'antd';
import React, { useContext, useState } from 'react';
import { AppContext } from '../../../../../AppContext';
import {
  MODULES,
  PERMISSIONS_KEY,
  PERMISSION_TYPE,
} from '../../../../../common/constants';
import { hasPermission } from '../../../../../common/utils';
import Portal from '../../../../../components/Portal';
import AgeGroupTable from '../components/AgeGroupTable';

const AgeGroup = () => {
  const {
    state: { permissions },
  } = useContext(AppContext);
  const [isAgeGroupModalOpen, setIsAgeGroupModalOpen] = useState(false);
  const [ageGroupImages, setAgeGroupImages] = useState([]);

  return (
    <>
      <Portal portalId="header-title-content">
        <div className="portal-header">{MODULES?.AGE_GROUP}</div>
      </Portal>
      <Portal portalId="header-right-content">
        {hasPermission(
          PERMISSIONS_KEY?.MASTER_LIST_AGE_GROUP,
          PERMISSION_TYPE?.CREATE,
          permissions,
        ) && (
          <Button
            className="common-button"
            size="middle"
            icon={<PlusOutlined />}
            onClick={() => {
              setIsAgeGroupModalOpen(true);
              setAgeGroupImages([]);
            }}
          >
            Add New Age Group
          </Button>
        )}
      </Portal>
      <Portal portalId="header-right-content-phones">
        {hasPermission(
          PERMISSIONS_KEY?.MASTER_LIST_AGE_GROUP,
          PERMISSION_TYPE?.CREATE,
          permissions,
        ) && (
          <Button
            className="common-button"
            size="middle"
            icon={<PlusOutlined />}
            onClick={() => {
              setIsAgeGroupModalOpen(true);
              setAgeGroupImages([]);
            }}
          >
            Add New Age Group
          </Button>
        )}
      </Portal>
      <Card className="full-height-without-nav">
        <AgeGroupTable
          isAgeGroupModalOpen={isAgeGroupModalOpen}
          setIsAgeGroupModalOpen={setIsAgeGroupModalOpen}
          ageGroupImages={ageGroupImages}
          setAgeGroupImages={setAgeGroupImages}
        />
      </Card>
    </>
  );
};

export default AgeGroup;
