import { Divider, Form, Input } from 'antd';
import React, { useEffect, useState } from 'react';
import { formValidatorRules } from '../../../../../common/utils';
import ModalComponent from '../../../../../components/ModalComponent';
import LibrarySelect from '../../../../lists/modules/library/components/LibrarySelect';

const { Item } = Form;
const { TextArea } = Input;
const { whitespaceNotAllowed } = formValidatorRules;

const CouponModal = ({
  isModalOpen,
  setIsModalOpen,
  onFinish,
  loadings,
  form,
  coupon,
  setCoupon,
  isDisabled,
  setIsDisabled,
}) => {
  const [isValidateTrigger, setIsValidateTrigger] = useState(false);

  const handleCancel = () => {
    setIsModalOpen(false);
    setIsDisabled(true);
    setCoupon({});
  };

  useEffect(() => {
    const initialValues = {
      ...coupon,
      libraryName: coupon?.library?.id,
    };
    form?.setFieldsValue(initialValues);
  }, [coupon]);

  return (
    <>
      <ModalComponent
        open={isModalOpen}
        onCancel={handleCancel}
        onOk={form?.submit}
        title="Edit Coupon"
        okText="Save"
        confirmLoading={loadings}
        destroyOnClose
        forceRender
        okButtonProps={{ disabled: isDisabled }}
      >
        <Divider />
        <Form
          form={form}
          onFinish={(values) => {
            onFinish(values);
            setIsValidateTrigger(true);
          }}
          onFieldsChange={() => setIsDisabled(false)}
          autoComplete="off"
          layout="vertical"
          validateTrigger={isValidateTrigger ? 'onChange' : 'onSubmit'}
          onFinishFailed={() => setIsValidateTrigger(true)}
        >
          <Item label="Library Name" name="libraryName">
            <LibrarySelect
              libraryData={coupon?.library}
              disabled={coupon?.library?.id}
              isModalOpen={isModalOpen}
            />
          </Item>
          <Item
            label="Library Commission"
            name="commission"
            rules={[whitespaceNotAllowed]}
          >
            <Input
              allowClear
              placeholder="Enter Library Commission"
              maxLength={15}
            />
          </Item>
          <Item
            name="description"
            label="Description"
            rules={[whitespaceNotAllowed]}
          >
            <TextArea
              rows={4}
              allowClear
              placeholder="Enter Description"
              showCount
              maxLength={800}
            />
          </Item>
          <Item name="note" label="Note" rules={[whitespaceNotAllowed]}>
            <TextArea
              rows={3}
              allowClear
              placeholder="Enter Note"
              showCount
              maxLength={700}
            />
          </Item>
        </Form>
      </ModalComponent>
    </>
  );
};

export default CouponModal;
