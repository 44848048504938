import React from 'react';
import { Route, Switch } from 'react-router-dom';
import AccessControl from '../../../../AccessControl';
import {
  PERMISSIONS_KEY,
  PERMISSION_TYPE,
  ROUTES,
} from '../../../../common/constants';
import Error404 from '../../../../Error404';
import CreateToy from './pages/CreateToy';
import EditToy from './pages/EditToy';
import Toy from './pages/Toy';
import ViewToy from './pages/ViewToy';
import './toy.less';

const ToyWrapper = () => (
  <Switch>
    <Route
      exact
      path={`${ROUTES?.MASTER_LISTS}${ROUTES?.TOY}`}
      render={(props) => (
        <AccessControl permissionKey={PERMISSIONS_KEY?.MASTER_LIST_TOY}>
          <Toy {...props} />
        </AccessControl>
      )}
    />
    <Route
      exact
      path={`${ROUTES?.MASTER_LISTS}${ROUTES?.TOY}/add`}
      render={(props) => (
        <AccessControl
          permissionType={PERMISSION_TYPE?.CREATE}
          permissionKey={PERMISSIONS_KEY?.MASTER_LIST_TOY}
        >
          <CreateToy {...props} />
        </AccessControl>
      )}
    />
    <Route
      exact
      path={`${ROUTES?.MASTER_LISTS}${ROUTES?.TOY}/edit/:id`}
      render={(props) => (
        <AccessControl
          permissionType={PERMISSION_TYPE?.UPDATE}
          permissionKey={PERMISSIONS_KEY?.MASTER_LIST_TOY}
        >
          <EditToy {...props} />
        </AccessControl>
      )}
    />
    <Route
      exact
      path={`${ROUTES?.MASTER_LISTS}${ROUTES?.TOY}/view/:id`}
      render={(props) => (
        <AccessControl permissionKey={PERMISSIONS_KEY?.MASTER_LIST_TOY}>
          <ViewToy {...props} />
        </AccessControl>
      )}
    />
    <Route path="*" exact component={Error404} />
  </Switch>
);

export default ToyWrapper;
