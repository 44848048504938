import { FilterFilled, FilterOutlined } from '@ant-design/icons';
import { useLazyQuery, useMutation } from '@apollo/client';
import { Button, Divider, Form, Modal, Radio, Switch, Tooltip } from 'antd';
import { forEach, isEmpty, map } from 'lodash';
import React, { useContext, useEffect, useState } from 'react';
import { AppContext } from '../../../AppContext';
import { ReactComponent as ArchiveIcon } from '../../../assets/svg/box-archive-solid.svg';
import { ReactComponent as EditIcon } from '../../../assets/svg/pen-to-square-solid.svg';
import { ReactComponent as UnarchiveIcon } from '../../../assets/svg/right-from-bracket-solid.svg';
import {
  ARCHIVING_STATE,
  BREAKPOINTS,
  DATE_FORMATS,
  LIMIT,
  PERMISSIONS_KEY,
  PERMISSION_TYPE,
  SORT,
  UI_LABEL_GROUP,
  UI_LABEL_TYPE,
} from '../../../common/constants';
import { formatDate, hasPermission } from '../../../common/utils';
import CommonTable from '../../../components/CommonTable';
import SearchComponent from '../../../components/SearchComponent';
import { CREATE_UI_LABEL, UPDATE_UI_LABEL } from '../graphql/Mutations';
import { UI_LABELS_ADMIN } from '../graphql/Queries';
import UiLabelModal from './UiLabelModal';

const { Group } = Radio;

const initialPaginationValue = {
  total: 0,
  current: 1,
  pageSize: 10,
};

const initialFilter = {
  limit: LIMIT,
  search: '',
  skip: 0,
};

const initialSort = {
  field: 'createdAt',
  order: 'DESC',
};

const UiLabelTable = ({ isUiLabelModalOpen, setIsUiLabelModalOpen }) => {
  const {
    state: { permissions },
  } = useContext(AppContext);
  const [form] = Form.useForm();
  const [uiLabelList, setUiLabelList] = useState([]);
  const [uiLabelSearchTerm, setUiLabelSearchTerm] = useState('');
  const [isEmptyUiLabelList, setIsEmptyUiLabelList] = useState(false);
  const [uiLabelData, setUiLabelData] = useState(null);
  const [query, setQuery] = useState('');
  const [sortedInfo, setSortedInfo] = useState({});
  const [paginationProp, setPaginationProp] = useState(initialPaginationValue);
  const [isUiLabelLoading, setIsUiLabelLoading] = useState(true);
  const [isUiLabelBtnLoading, setIsUiLabelBtnLoading] = useState(false);
  const [whereFilter, setWhereFilter] = useState();
  const [filterTags, setFilterTags] = useState(null);
  const [filterVisible, setFilterVisible] = useState(false);
  const [filterIndex, setFilterIndex] = useState(null);
  const [finalWhereFilters, setFinalWhereFilters] = useState();
  const [finalFilterTags, setFinalFilterTags] = useState(null);
  const [type, setType] = useState(UI_LABEL_TYPE.STRING.value);
  const [isArchivePrompts, setIsArchivePrompts] = useState(false);
  const [isArchiveLoading, setIsArchiveLoading] = useState(false);
  const [isArchiveFilter, setIsArchiveFilter] = useState(false);

  const [uiLabelsAdmin] = useLazyQuery(UI_LABELS_ADMIN, {
    onCompleted: (response) => {
      setUiLabelList([...response?.uiLabelsAdmin?.data]);
      if (
        response?.uiLabelsAdmin?.count === 0 &&
        initialPaginationValue?.total === 0
      ) {
        setIsEmptyUiLabelList(true);
      } else {
        setIsEmptyUiLabelList(false);
      }
      const pagination = {
        ...paginationProp,
        defaultPageSize: LIMIT,
        total: response?.uiLabelsAdmin?.count,
      };
      setPaginationProp(pagination);
      setIsUiLabelLoading(false);
    },
    fetchPolicy: 'network-only',
    onError() {},
  });

  const [createUILabel] = useMutation(CREATE_UI_LABEL, {
    onError: () => {},
  });

  const [updateUILabel] = useMutation(UPDATE_UI_LABEL, {
    onError: () => {},
  });

  const hasUpdatePermission = hasPermission(
    PERMISSIONS_KEY?.UI_LABELS,
    PERMISSION_TYPE?.UPDATE,
    permissions,
  );

  useEffect(() => {
    setPaginationProp({ ...paginationProp, current: 1, skip: 0 });
    setIsUiLabelLoading(true);
    uiLabelsAdmin({
      variables: {
        filter: {
          ...initialFilter,
          limit: paginationProp?.pageSize || LIMIT,
          search: uiLabelSearchTerm,
          isArchived: isArchiveFilter,
        },
        sort: sortedInfo?.column
          ? {
              field: sortedInfo?.field,
              order: sortedInfo?.order === 'ascend' ? SORT?.ASC : SORT?.DESC,
            }
          : initialSort,
      },
    });
  }, [isArchiveFilter]);

  const handleTableChange = (pagination, tableFilter, sorter) => {
    const { current } = pagination;
    const skip = (current - 1) * (pagination?.pageSize || 0);
    setPaginationProp({ ...paginationProp, ...pagination });
    setIsUiLabelLoading(true);
    setSortedInfo(sorter);
    uiLabelsAdmin({
      variables: {
        filter: {
          ...initialFilter,
          skip,
          limit: pagination?.pageSize,
          search: uiLabelSearchTerm,
          type: finalWhereFilters?.type,
          isArchived: isArchiveFilter,
        },
        sort: sorter?.column
          ? {
              field: sorter?.field,
              order: sorter?.order === 'ascend' ? SORT?.ASC : SORT?.DESC,
            }
          : initialSort,
      },
    });
  };

  const handleSearch = (value) => {
    const trimValue = value?.trim();
    setUiLabelSearchTerm(trimValue);
    setPaginationProp({ ...paginationProp, current: 1, skip: 0 });
    setIsUiLabelLoading(true);
    uiLabelsAdmin({
      variables: {
        filter: {
          ...initialFilter,
          limit: paginationProp?.pageSize || LIMIT,
          search: trimValue,
          type: finalWhereFilters?.type,
          isArchived: isArchiveFilter,
        },
        sort: sortedInfo?.column
          ? {
              field: sortedInfo?.field,
              order: sortedInfo?.order === 'ascend' ? SORT?.ASC : SORT?.DESC,
            }
          : initialSort,
      },
    });
  };

  const handleUiLabel = async (values) => {
    setIsUiLabelBtnLoading(true);
    let response;
    const data = {
      key: values?.key,
      description: values?.description,
      group: values?.group,
      type: values?.type,
      value: values?.value,
    };
    if (uiLabelData) {
      response = await updateUILabel({
        variables: {
          where: {
            id: uiLabelData?.id,
          },
          data,
        },
      });
    } else {
      response = await createUILabel({
        variables: {
          data,
        },
      });
    }
    if (response?.data) {
      setIsUiLabelLoading(true);
      setIsUiLabelBtnLoading(false);
      setIsUiLabelModalOpen(false);
      form?.resetFields();
      setUiLabelList([]);
      setUiLabelData(null);
      setType(UI_LABEL_TYPE.STRING.value);
      uiLabelsAdmin({
        variables: {
          filter: {
            ...initialFilter,
            limit: paginationProp?.pageSize || LIMIT,
            search: uiLabelSearchTerm,
            type: finalWhereFilters?.type,
            isArchived: isArchiveFilter,
          },
          sort: sortedInfo?.column
            ? {
                field: sortedInfo?.field,
                order: sortedInfo?.order === 'ascend' ? SORT?.ASC : SORT?.DESC,
              }
            : initialSort,
        },
      });
    }
    setIsUiLabelBtnLoading(false);
  };

  const handleArchive = async (id, isArchived) => {
    setIsArchiveLoading(true);
    const response = await updateUILabel({
      variables: {
        where: {
          id,
        },
        data: {
          isArchived: !isArchived,
        },
      },
    });
    if (response?.data) {
      setIsArchiveLoading(false);
      setIsUiLabelLoading(true);
      setIsArchivePrompts(false);
      uiLabelsAdmin({
        variables: {
          filter: {
            ...initialFilter,
            limit: paginationProp?.pageSize || LIMIT,
            search: uiLabelSearchTerm,
            type: finalWhereFilters?.type,
            isArchived: isArchiveFilter,
          },
          sort: sortedInfo?.column
            ? {
                field: sortedInfo?.field,
                order: sortedInfo?.order === 'ascend' ? SORT?.ASC : SORT?.DESC,
              }
            : initialSort,
        },
      });
    }
    setIsArchiveLoading(false);
  };

  const getFilterData = (confirm) => {
    setIsUiLabelBtnLoading(true);
    uiLabelsAdmin({
      variables: {
        filter: {
          ...initialFilter,
          skip: (paginationProp?.current - 1) * (paginationProp?.pageSize || 0),
          limit: paginationProp?.pageSize || LIMIT,
          search: uiLabelSearchTerm,
          type: whereFilter?.type,
          isArchived: isArchiveFilter,
        },
        sort: sortedInfo?.column
          ? {
              field: sortedInfo?.field,
              order: sortedInfo?.order === 'ascend' ? SORT?.ASC : SORT?.DESC,
            }
          : initialSort,
      },
    });
    setFinalWhereFilters({ ...finalWhereFilters, ...whereFilter });
    setFinalFilterTags(filterTags);
    setPaginationProp({ ...paginationProp, skip: 0, current: 1 });
    if (confirm) {
      confirm();
    }
  };

  const handleReset = (clearFilters, dataIndex, confirm) => {
    const tagsCopy = {
      ...filterTags,
      [dataIndex]: [],
    };
    const whereFiltersCopy = {
      ...whereFilter,
      [dataIndex]: null,
    };
    setFilterVisible(false);
    setFinalFilterTags({ ...filterTags, ...tagsCopy });
    setFinalWhereFilters({ ...finalWhereFilters, ...whereFiltersCopy });
    setIsUiLabelBtnLoading(true);
    uiLabelsAdmin({
      variables: {
        filter: {
          ...initialFilter,
          skip: (paginationProp?.current - 1) * (paginationProp?.pageSize || 0),
          limit: paginationProp?.pageSize || LIMIT,
          search: uiLabelSearchTerm,
          type: whereFiltersCopy?.type,
          isArchived: isArchiveFilter,
        },
        sort: sortedInfo?.column
          ? {
              field: sortedInfo?.field,
              order: sortedInfo?.order === 'ascend' ? SORT?.ASC : SORT?.DESC,
            }
          : initialSort,
      },
    });
    setPaginationProp({ ...paginationProp, skip: 0, current: 1 });
    clearFilters();
    setFilterVisible(false);
    if (confirm) {
      confirm();
    }
  };

  useEffect(() => {
    if (filterVisible) {
      setFilterTags({ ...filterTags, ...finalFilterTags });
      setWhereFilter({ ...whereFilter, ...finalWhereFilters });
      const optionsCopy = [];

      switch (filterIndex) {
        case 'type':
          forEach(UI_LABEL_TYPE, (item) => {
            optionsCopy?.push({ label: item?.label, key: item?.value });
          });
          break;

        default:
          break;
      }
    }
    if (!filterVisible) {
      setFilterTags(finalFilterTags);
      setWhereFilter(finalWhereFilters);
    }
  }, [filterVisible]);

  const onRadioChange = (value, dataIndex) => {
    const filtersCopy = {
      ...whereFilter,
      [dataIndex]: value,
    };
    if (!value?.length) {
      delete whereFilter?.[dataIndex];
    }
    setWhereFilter(filtersCopy);
  };

  const filterPopup = (dataIndex) => ({
    filterDropdown: ({ confirm, clearFilters }) => (
      <div className="custom-filter-dropdown">
        <Group
          options={map(UI_LABEL_TYPE, (option) => option)}
          value={whereFilter?.[dataIndex]}
          onChange={(e) => onRadioChange(e?.target?.value, dataIndex)}
        />
        <Divider className="divider-filter" />
        <div className="d-flex justify-center">
          <Button
            size="small"
            className="reset-button"
            id="roles-filter-reset"
            onClick={() => handleReset(clearFilters, dataIndex, confirm)}
          >
            Reset
          </Button>
          <Button
            size="small"
            className="common-button ok-button"
            id="roles-filter-ok"
            type="primary"
            onClick={() => getFilterData(confirm, dataIndex)}
          >
            Ok
          </Button>
        </div>
      </div>
    ),
    filterIcon: () =>
      finalWhereFilters?.[dataIndex]?.length > 0 ||
      !isEmpty(finalWhereFilters?.[dataIndex]) ? (
        <FilterFilled className="filter-icon filter-filled" />
      ) : (
        <FilterOutlined className="filter-icon" />
      ),
    onFilterDropdownOpenChange: (isVisible) => {
      setFilterIndex(dataIndex);
      setFilterVisible(isVisible);
    },
  });

  const columns = [
    {
      title: 'KEY',
      dataIndex: 'key',
      key: 'key',
      ellipsis: true,
      width: 200,
      align: 'left',
      className: 'max-width-column',
      // eslint-disable-next-line no-undef
      fixed: window?.innerWidth > BREAKPOINTS?.desktop ? 'left' : false,
      render: (_, record) => record?.key || '-',
    },
    {
      title: 'VALUE',
      dataIndex: 'value',
      key: 'value',
      ellipsis: true,
      width: 250,
      align: 'left',
      className: 'max-width-column table-tooltip-cell',
      render: (_, record) => (
        <Tooltip
          title={record?.value ? record?.value : 'No Value'}
          placement="topLeft"
          trigger="click"
          className="pointer"
        >
          {record?.value || '-'}
        </Tooltip>
      ),
    },
    {
      title: 'DESCRIPTION',
      dataIndex: 'description',
      key: 'description',
      ellipsis: true,
      width: 250,
      align: 'left',
      className: 'max-width-column table-tooltip-cell',
      render: (_, record) => (
        <Tooltip
          title={record?.description ? record?.description : 'No Description'}
          placement="topLeft"
          trigger="click"
          className="pointer"
        >
          {record?.description || '-'}
        </Tooltip>
      ),
    },
    {
      title: 'GROUP',
      dataIndex: 'group',
      key: 'group',
      ellipsis: true,
      width: 250,
      align: 'left',
      className: `max-width-column`,
      render: (_, record) => UI_LABEL_GROUP?.[record?.group]?.label || '-',
    },
    {
      title: 'TYPE',
      dataIndex: 'type',
      key: 'type',
      ellipsis: true,
      width: 250,
      align: 'left',
      className: `max-width-column`,
      render: (_, record) => UI_LABEL_TYPE?.[record?.type]?.label || '-',
      ...filterPopup('type'),
    },
    {
      title: 'CREATED AT',
      dataIndex: 'createdAt',
      key: 'createdAt',
      width: 150,
      ellipsis: true,
      align: 'left',
      className: 'max-width-column',
      render: (_, record) =>
        formatDate(record?.createdAt, DATE_FORMATS?.REGULAR) || '-',
    },
    {
      title: 'ACTIONS',
      dataIndex: 'actions',
      key: 'actions',
      ellipsis: true,
      width: 150,
      // eslint-disable-next-line no-undef
      fixed: window.innerWidth > BREAKPOINTS.desktop ? 'right' : false,
      render: (_, record) => (
        <>
          <div className="action-button">
            {isArchiveFilter ? (
              <Tooltip title="Unarchive" placement="top" zIndex={4}>
                <UnarchiveIcon
                  onClick={() => {
                    setIsArchivePrompts(true);
                    setUiLabelData(record);
                  }}
                  className="mr-16 pointer svg-icon"
                />
              </Tooltip>
            ) : (
              <>
                <Tooltip title="Edit" placement="top" zIndex={4}>
                  <EditIcon
                    onClick={() => {
                      setUiLabelData(record);
                      setIsUiLabelModalOpen(true);
                    }}
                    className="mr-16 pointer svg-icon"
                  />
                </Tooltip>
                <Tooltip title="Archive" placement="top" zIndex={4}>
                  <ArchiveIcon
                    onClick={() => {
                      setIsArchivePrompts(true);
                      setUiLabelData(record);
                    }}
                    className="mr-16 pointer svg-icon"
                  />
                </Tooltip>
              </>
            )}
          </div>
        </>
      ),
    },
  ]?.filter((item) => {
    if (item?.dataIndex === 'actions' && !hasUpdatePermission) {
      return item?.hidden;
    }
    return !item?.hidden;
  });

  const locale = {
    emptyText: isEmptyUiLabelList ? '' : <span />,
  };

  return (
    <>
      <Modal
        title="Caution"
        okText="Yes"
        cancelText="No"
        open={isArchivePrompts}
        onOk={() => handleArchive(uiLabelData?.id, uiLabelData?.isArchived)}
        onCancel={() => setIsArchivePrompts(false)}
        okButtonProps={{ loading: isArchiveLoading }}
      >
        Are you sure you want to
        <strong>
          {' '}
          {!uiLabelData?.isArchived
            ? ARCHIVING_STATE?.ARCHIVED
            : ARCHIVING_STATE?.UNARCHIVED}{' '}
        </strong>
        this Ui Label?
      </Modal>
      <UiLabelModal
        title={uiLabelData ? 'Edit Ui Label' : 'Add New Ui Label'}
        uiLabelData={uiLabelData}
        setUiLabelData={setUiLabelData}
        isModalOpen={isUiLabelModalOpen}
        setIsModalOpen={setIsUiLabelModalOpen}
        isBtnLoading={isUiLabelBtnLoading}
        onFinish={handleUiLabel}
        form={form}
        type={type}
        setType={setType}
      />
      <div className="d-flex justify-end mb-16 flex-wrap">
        {hasUpdatePermission && (
          <div className="mr-16 d-flex align-center">
            <h4 className="m-0 mr-8">Only Archived</h4>{' '}
            <Switch
              size="small"
              onChange={(checked) => setIsArchiveFilter(checked)}
            />
          </div>
        )}
        <SearchComponent
          className="search-box"
          query={query}
          setQuery={setQuery}
          getData={handleSearch}
        />
      </div>
      <CommonTable
        locale={locale}
        columns={columns}
        data={uiLabelList || []}
        loading={isUiLabelLoading}
        onChange={handleTableChange}
        paginationConfig={paginationProp}
        rowKey={(record) => record?.id}
      />
    </>
  );
};

export default UiLabelTable;
