import { useLazyQuery } from '@apollo/client';
import { Empty, Select } from 'antd';
import { debounce, uniqBy } from 'lodash';
import React, { useEffect, useState } from 'react';
import { LIMIT, SORT } from '../../../common/constants';
import SelectComponent from '../../../components/SelectComponent';
import { RACKS } from '../graphql/Queries';

const { Option } = Select;

const RackSelect = (props) => {
  const {
    rackData,
    isModalOpen = true,
    isActiveLibOnly = false,
    ...rest
  } = props;
  const [rackList, setRackList] = useState([]);
  const [rackSearchTerm, setRackSearchTerm] = useState('');
  const [isAllRackFetched, setIsAllRackFetched] = useState(false);
  const [isRackAllowClear, setIsRackAllowClear] = useState(false);
  const [isRackDropdownVisible, setIsRackDropdownVisible] = useState(false);
  const [isFetchMoreRackLoading, setIsFetchMoreRackLoading] = useState(false);

  const [racks, { loading: isRackLoading }] = useLazyQuery(RACKS, {
    onCompleted: (response) => {
      if (response?.racks?.data?.length < LIMIT) {
        setIsAllRackFetched(true);
      }
      setIsFetchMoreRackLoading(false);
      if (rackData?.id) {
        setRackList(
          uniqBy(
            [
              {
                name: rackData?.name,
                id: rackData?.id,
              },
              ...rackList,
              ...response?.racks?.data,
            ],
            'id',
          ),
        );
        return;
      }
      setRackList([...rackList, ...response?.racks?.data]);
    },
    fetchPolicy: 'network-only',
    onError() {},
  });

  useEffect(() => {
    setRackSearchTerm('');
    setRackList([]);
    if (isModalOpen) {
      racks({
        variables: {
          filter: {
            limit: LIMIT,
            skip: 0,
            search: rackSearchTerm,
            ...(isActiveLibOnly && { isActive: true }),
          },
          sort: {
            field: 'createdAt',
            order: SORT?.DESC,
          },
        },
      });
    }
    setIsAllRackFetched(false);
  }, [isRackDropdownVisible, isModalOpen]);

  const handleRackScroll = (event) => {
    if (!isAllRackFetched && !isRackLoading) {
      const target = event?.target;
      const currentLength = rackList?.length;
      if (
        target?.scrollTop + target?.offsetHeight >=
        target?.scrollHeight - 5
      ) {
        setIsFetchMoreRackLoading(true);
        racks({
          variables: {
            filter: {
              limit: LIMIT,
              skip: currentLength,
              search: rackSearchTerm,
              ...(isActiveLibOnly && { isActive: true }),
            },
            sort: {
              field: 'createdAt',
              order: SORT?.DESC,
            },
          },
        });
      }
    }
  };

  const handleRackSearch = (searchTerm) => {
    const trimSearch = searchTerm?.trim();
    setIsAllRackFetched(false);
    setRackList([]);
    setRackSearchTerm(trimSearch);
    racks({
      variables: {
        filter: {
          limit: LIMIT,
          skip: 0,
          search: trimSearch,
          ...(isActiveLibOnly && { isActive: true }),
        },
        sort: {
          field: 'createdAt',
          order: SORT?.DESC,
        },
      },
    });
  };

  const handleRackScrollDebounce = debounce(handleRackScroll, 500);
  const handleSearchRackDebounce = debounce(handleRackSearch, 500);

  useEffect(() => {
    handleRackScrollDebounce?.cancel();
  }, [handleRackScrollDebounce]);

  useEffect(() => {
    handleSearchRackDebounce?.cancel();
  }, [handleSearchRackDebounce]);

  useEffect(() => {
    if (isRackLoading) {
      setIsRackAllowClear(false);
    } else {
      setIsRackAllowClear(true);
    }
  }, [isRackLoading]);

  return (
    <SelectComponent
      showSearch
      loading={
        (isRackLoading && isRackDropdownVisible) || isFetchMoreRackLoading
      }
      placeholder="Select Rack"
      optionFilterProp="children"
      allowClear={isRackAllowClear}
      onPopupScroll={handleRackScrollDebounce}
      onSearch={handleSearchRackDebounce}
      onDropdownVisibleChange={(visible) => setIsRackDropdownVisible(visible)}
      filterOption={false}
      notFoundContent={
        !isFetchMoreRackLoading && isRackLoading ? (
          <span>Loading...</span>
        ) : (
          <Empty image={Empty?.PRESENTED_IMAGE_SIMPLE} />
        )
      }
      {...rest}
    >
      {rackList?.map((rack) => (
        <Option key={rack?.id} value={rack?.id}>
          {rack?.name}
        </Option>
      ))}
    </SelectComponent>
  );
};

export default RackSelect;
