import { Button, DatePicker, Divider, Form, Radio } from 'antd';
import { map } from 'lodash';
import React, { useEffect } from 'react';
import {
  DATE_FORMATS,
  DELIVERY_TYPE_OPTIONS,
  RETURN_ORDER_ITEM_STATUS,
} from '../../../../../common/constants';
import ModalComponent from '../../../../../components/ModalComponent';
import SelectComponent from '../../../../../components/SelectComponent';
import LibraryPincodeSelect from '../../../../../modules/lists/modules/library/components/LibraryPincodeSelect';

const { Item } = Form;
const { RangePicker } = DatePicker;
const { Group } = Radio;

const AllFilterReturnOrderModal = ({
  isModalOpen,
  setIsModalOpen,
  onFinish,
  finalWhereFilters,
  setFinalWhereFilters,
}) => {
  const [form] = Form.useForm();

  const handleReset = () => {
    setIsModalOpen(false);
    form?.resetFields();
    setFinalWhereFilters();
    form?.submit();
  };

  useEffect(() => {
    form?.setFieldsValue({
      ...finalWhereFilters,
      createdAt: [
        finalWhereFilters?.createdAt?.from,
        finalWhereFilters?.createdAt?.to,
      ],
      returnedOn: [
        finalWhereFilters?.returnedOn?.from,
        finalWhereFilters?.returnedOn?.to,
      ],
      status: finalWhereFilters?.status,
      deliveryType: finalWhereFilters?.deliveryType,
      pincodes: finalWhereFilters?.pincodes,
    });
  }, [isModalOpen]);

  return (
    <>
      <ModalComponent
        open={isModalOpen}
        onCancel={() => setIsModalOpen(false)}
        title="Filter"
        forceRender
        destroyOnClose
        footer={null}
      >
        <Divider />
        <div className="mb-16">
          <Form
            form={form}
            onFinish={onFinish}
            autoComplete="off"
            layout="vertical"
          >
            <Item label="Delivery Type" name="deliveryType">
              <Group options={DELIVERY_TYPE_OPTIONS} />
            </Item>
            <Item label="Pincode" name="pincodes">
              <LibraryPincodeSelect isIdAsValue />
            </Item>
            <Item label="Placed On" name="createdAt">
              <RangePicker
                format={DATE_FORMATS?.REGULAR}
                className="full-width"
                popupClassName="picker-with-scroll"
                placeholder={['From Date', 'To Date']}
              />
            </Item>
            <Item label="Returned On" name="returnedOn">
              <RangePicker
                format={DATE_FORMATS?.REGULAR}
                className="full-width"
                popupClassName="picker-with-scroll"
                placeholder={['From Date', 'To Date']}
              />
            </Item>
            <Item label="Status" name="status">
              <SelectComponent
                showSearch={false}
                placeholder="Select Status"
                mode="multiple"
                options={map(RETURN_ORDER_ITEM_STATUS, (status) => ({
                  label: status?.label,
                  value: status?.value,
                }))}
                maxTagCount={false}
              />
            </Item>
          </Form>
          <div className="d-flex justify-end">
            <Button htmlType="submit" onClick={handleReset} className="mr-8">
              Reset
            </Button>
            <Button key="submit" type="primary" onClick={form?.submit}>
              Apply
            </Button>
          </div>
        </div>
      </ModalComponent>
    </>
  );
};

export default AllFilterReturnOrderModal;
