import { gql } from '@apollo/client';

// eslint-disable-next-line import/prefer-default-export
export const BAGS_ADMIN = gql`
  query BagsAdmin($filter: BagFilter, $sort: BagSort) {
    bagsAdmin(filter: $filter, sort: $sort) {
      count
      data {
        createdAt
        id
        image
        isArchived
        name
        size
        type
      }
    }
  }
`;

export const GET_BAG_IMAGE_SIGNED_PUT_URL = gql`
  query GetBagImageSignedPutUrl($data: GetBagsImageSignedPutUrlInput!) {
    getBagImageSignedPutUrl(data: $data) {
      fileName
      signedUrl
    }
  }
`;
