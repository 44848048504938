import { Divider, Form, Input } from 'antd';
import React, { useEffect, useState } from 'react';
import {
  formValidatorRules,
} from '../../../../common/utils';
import ModalComponent from '../../../../components/ModalComponent';

const { Item } = Form;
const { TextArea } = Input;
const { required, whitespaceNotAllowed } = formValidatorRules;

const StoryModal = ({
  isModalOpen,
  setIsModalOpen,
  onFinish,
  loadings,
  form,
  collections,
  setCollections,
}) => {
  const [isValidateTrigger, setIsValidateTrigger] = useState(false);

  const handleCancel = () => {
    setIsModalOpen(false);
    setCollections();
    form?.resetFields();
  };

  useEffect(() => {
    form?.setFieldsValue({ name: collections?.name, description: collections?.description });
  }, [collections]);

  return (
    <>
      <ModalComponent
        open={isModalOpen}
        onCancel={handleCancel}
        onOk={form?.submit}
        title={collections ? 'Edit Story' : 'Add New Story'}
        okText="Save"
        confirmLoading={loadings}
        destroyOnClose
        forceRender
      >
        <Divider />
        <Form
          form={form}
          onFinish={(values) => {
            onFinish(values);
            setIsValidateTrigger(true);
          }}
          autoComplete="off"
          layout="vertical"
          validateTrigger={isValidateTrigger ? 'onChange' : 'onSubmit'}
          onFinishFailed={() => setIsValidateTrigger(true)}
        >
          <Item
            label="Name"
            name="name"
            rules={[{ ...required, message: 'Please Enter Name!' }]}
          >
            <Input allowClear placeholder="Enter Name" maxLength={50} />
          </Item>
          <Item
            name="description"
            label="Description"
            rules={[whitespaceNotAllowed]}
          >
            <TextArea
              className='text-area-padding'
              rows={4}
              allowClear
              placeholder="Enter Description"
              showCount
              maxLength={700}
            />
          </Item>
        </Form>
      </ModalComponent>
    </>
  );
};

export default StoryModal;
