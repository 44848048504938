import React from 'react';
import { Route, Switch } from 'react-router-dom';
import AccessControl from '../../../../AccessControl';
import { PERMISSIONS_KEY, ROUTES } from '../../../../common/constants';
import Error404 from '../../../../Error404';
import Referral from './pages/Referral';

const ReferralWrapper = () => (
  <Switch>
    <Route
      exact
      path={`${ROUTES?.OFFERS}${ROUTES?.REFERRAL}`}
      render={(props) => (
        <AccessControl permissionKey={PERMISSIONS_KEY?.OFFERS_REFERRAL_USERS}>
          <Referral {...props} />
        </AccessControl>
      )}
    />
    <Route path="*" exact component={Error404} />
  </Switch>
);

export default ReferralWrapper;
