import { Col, Divider, Form, Input, Row, TimePicker } from 'antd';
import React, { useEffect, useState } from 'react';
import {
  NUMBER_FIELD_TYPES,
  TIME_FORMATS,
} from '../../../../../common/constants';
import { formValidatorRules } from '../../../../../common/utils';
import CitySelect from '../../../../../components/CitySelect';
import CountrySelect from '../../../../../components/CountrySelect';
import NumberComponent from '../../../../../components/NumberComponent';
import StateSelect from '../../../../../components/StateSelect';
import LibraryPincodeSelect from './LibraryPincodeSelect';

const { Item } = Form;
const { email, name, phone, required, latRule, lngRule } = formValidatorRules;

const LibraryForm = ({
  form,
  onFinish,
  handleShowPrompt,
  initialValues,
  isView,
  libraryDetail,
}) => {
  const [validationTriggered, setValidationTriggered] = useState(false);
  const [countryId, setCountryId] = useState('');
  const [stateId, setStateId] = useState(initialValues?.state);
  const [isStateIdChanged, setIsStateIdChanged] = useState(false);
  const [lat, setLat] = useState(initialValues?.latitude || '');
  const [lng, setLng] = useState(initialValues?.longitude || '');

  useEffect(() => {
    const initialValueFun = () => {
      form?.setFieldsValue({
        ...initialValues,
        country: countryId,
      });
    };
    setTimeout(initialValueFun, 300);
  }, [countryId]);

  const onFinishFailed = () => {
    setValidationTriggered(true);
  };

  return (
    <Form
      layout="vertical"
      form={form}
      onFinish={onFinish}
      onFieldsChange={handleShowPrompt}
      disabled={!!isView || libraryDetail?.isArchived}
      onFinishFailed={onFinishFailed}
      className={isView ? 'common-view-form' : 'form'}
      validateTrigger={validationTriggered ? 'onChange' : 'onSubmit'}
    >
      <Row gutter={[16, 0]}>
        <Col
          xs={24}
          sm={12}
          md={12}
          lg={12}
          xl={initialValues ? 6 : 8}
          xxl={initialValues ? 6 : 8}
        >
          <Item
            label="Library Name"
            name="name"
            rules={[{ ...required, message: 'Please Enter Library Name!' }]}
          >
            <Input
              allowClear
              placeholder="Enter Library Name"
              maxLength={200}
            />
          </Item>
        </Col>
        {initialValues && (
          <Col
            xs={24}
            sm={12}
            md={12}
            lg={12}
            xl={initialValues ? 6 : 8}
            xxl={initialValues ? 6 : 8}
          >
            <Item label="Library ID" name="id">
              <Input disabled />
            </Item>
          </Col>
        )}
        <Col
          xs={24}
          sm={12}
          md={12}
          lg={12}
          xl={initialValues ? 6 : 8}
          xxl={initialValues ? 6 : 8}
        >
          <Item
            label="Librarian First Name"
            name="firstName"
            rules={[
              { ...required, message: 'Please Enter Librarian First Name!' },
              name,
            ]}
          >
            <Input
              allowClear
              placeholder="Enter Librarian First Name"
              maxLength={50}
            />
          </Item>
        </Col>
        <Col
          xs={24}
          sm={12}
          md={12}
          lg={12}
          xl={initialValues ? 6 : 8}
          xxl={initialValues ? 6 : 8}
        >
          <Item
            label="Librarian Last Name"
            name="lastName"
            rules={[
              { ...required, message: 'Please Enter Librarian Last Name!' },
              name,
            ]}
          >
            <Input
              allowClear
              placeholder="Enter Librarian Last Name"
              maxLength={50}
            />
          </Item>
        </Col>
        <Col xs={24} sm={12} md={12} lg={12} xl={4} xxl={4}>
          <Item
            label="Preferred Pickup / From"
            name="from"
            rules={[
              {
                required: true,
                message: 'Please Select Preferred Pickup Time!',
              },
            ]}
          >
            <TimePicker use12Hours format={TIME_FORMATS?.REGULAR} />
          </Item>
        </Col>
        <Col xs={24} sm={12} md={12} lg={12} xl={4} xxl={4}>
          <Item
            label="Preferred Return / To"
            name="to"
            rules={[
              {
                required: true,
                message: 'Please Select Preferred Return Time!',
              },
            ]}
          >
            <TimePicker use12Hours format={TIME_FORMATS?.REGULAR} />
          </Item>
        </Col>
        <Col xs={24} sm={12} md={12} lg={12} xl={8} xxl={8}>
          <Item
            label="Contact Details"
            name="contact"
            rules={[
              {
                ...required,
                message: 'Please Enter Contact Details!',
              },
              phone,
            ]}
          >
            <Input
              allowClear
              placeholder="Enter Contact Details"
              maxLength={12}
            />
          </Item>
        </Col>
        <Col xs={24} sm={12} md={12} lg={12} xl={8} xxl={8}>
          <Item
            label="Email ID"
            name="email"
            rules={[{ ...required, message: 'Please Enter Email!' }, email]}
          >
            <Input allowClear placeholder="Enter Email" maxLength={255} />
          </Item>
        </Col>
        <Col xs={24} sm={12} md={12} lg={12} xl={24} xxl={24}>
          <Item
            label="Address"
            name="address"
            rules={[
              {
                ...required,
                message: 'Please Enter Address!',
              },
            ]}
          >
            <Input allowClear placeholder="Enter Address!" maxLength={250} />
          </Item>
        </Col>
        <Col xs={24} sm={12}>
          <Item label="latitude (lat)" name="latitude" rules={[latRule(lng)]}>
            <NumberComponent
              allowClear
              placeholder="Enter Latitude"
              type={NUMBER_FIELD_TYPES?.LOCATION}
              onChange={(e) => setLat(e?.target?.value)}
            />
          </Item>
        </Col>
        <Col xs={24} sm={12}>
          <Item label="longitude (lng)" name="longitude" rules={[lngRule(lat)]}>
            <NumberComponent
              allowClear
              placeholder="Enter Longitude"
              type={NUMBER_FIELD_TYPES?.LOCATION}
              onChange={(e) => setLng(e?.target?.value)}
            />
          </Item>
        </Col>
        {initialValues && (
          <>
            <Col xs={24} sm={12}>
              <Item label="Referral Code" name="referralCode">
                <Input disabled />
              </Item>
            </Col>
            <Col xs={24} sm={12}>
              <Item label="Available Balance" name="availableBalance">
                <Input disabled />
              </Item>
            </Col>
          </>
        )}
        <Col xs={24} sm={12} md={12} lg={12} xl={8} xxl={8}>
          <Item
            label="Country"
            name="country"
            rules={[{ required: true, message: 'Please Select Country!' }]}
          >
            <CountrySelect setCountryId={setCountryId} />
          </Item>
        </Col>
        <Col xs={24} sm={12} md={12} lg={12} xl={8} xxl={8}>
          <Item
            label="State"
            name="state"
            rules={[{ required: true, message: 'Please Select State!' }]}
          >
            <StateSelect
              countryId={countryId}
              setStateId={setStateId}
              userData={libraryDetail}
              onChange={(value) => {
                form?.setFieldsValue({
                  city: null,
                });
                setStateId(value);
                setIsStateIdChanged(true);
              }}
              isView={isView}
            />
          </Item>
        </Col>
        <Col xs={24} sm={12} md={12} lg={12} xl={8} xxl={8}>
          <Item
            label="City"
            name="city"
            rules={[{ required: true, message: 'Please Select City!' }]}
          >
            <CitySelect
              stateId={stateId}
              userData={libraryDetail}
              isView={isView}
              isStateIdChanged={isStateIdChanged}
            />
          </Item>
        </Col>
        {initialValues && libraryDetail?.backUpLibrary && (
          <>
            <div className="form-divider-text">Backup Library Details</div>
            <Divider className="form-divider" />
            <Col xs={24} sm={12} md={12} lg={12} xl={6} xxl={6}>
              <Item label="Library Name" name="backupLibName">
                <Input disabled />
              </Item>
            </Col>
            <Col xs={24} sm={12} md={12} lg={12} xl={6} xxl={6}>
              <Item label="Library ID" name="backupLibId">
                <Input disabled />
              </Item>
            </Col>
            <Col xs={24} sm={12} md={12} lg={12} xl={6} xxl={6}>
              <Item
                label="Librarian First Name"
                name="backupLibLibrarianFirstName"
              >
                <Input disabled />
              </Item>
            </Col>
            <Col xs={24} sm={12} md={12} lg={12} xl={6} xxl={6}>
              <Item
                label="Librarian Last Name"
                name="backupLibLibrarianLastName"
              >
                <Input disabled />
              </Item>
            </Col>
            <Col xs={24} sm={12} md={12} lg={12} xl={4} xxl={4}>
              <Item label="Preferred Pickup / From" name="backupLibFrom">
                <TimePicker
                  use12Hours
                  format={TIME_FORMATS?.REGULAR}
                  disabled
                />
              </Item>
            </Col>
            <Col xs={24} sm={12} md={12} lg={12} xl={4} xxl={4}>
              <Item label="Preferred Return / To" name="backupLibTo">
                <TimePicker
                  use12Hours
                  format={TIME_FORMATS?.REGULAR}
                  disabled
                />
              </Item>
            </Col>
            <Col xs={24} sm={12} md={12} lg={12} xl={8} xxl={8}>
              <Item label="Contact Details" name="backupLibLibrarianContact">
                <Input disabled />
              </Item>
            </Col>
            <Col xs={24} sm={12} md={12} lg={12} xl={8} xxl={8}>
              <Item label="Email ID" name="backupLibLibrarianEmail">
                <Input disabled />
              </Item>
            </Col>
          </>
        )}
      </Row>
      {initialValues && (
        <Row>
          <div className="form-divider-text">Pincode Served By Library</div>
          <Divider className="form-divider" />
          <Col span={24}>
            <Item name="libraryPincode">
              <LibraryPincodeSelect
                initialPincodes={libraryDetail?.pincodes}
                disabled
              />
            </Item>
          </Col>
        </Row>
      )}
    </Form>
  );
};

export default LibraryForm;
