import { gql } from '@apollo/client';

// eslint-disable-next-line import/prefer-default-export
export const ASSIGN_SUBSCRIPTION = gql`
  mutation AssignSubscription(
    $where: UserUniqueInput!
    $data: AssignSubscriptionInput!
  ) {
    assignSubscription(where: $where, data: $data) {
      message
    }
  }
`;

export const UPDATE_NON_RECURRING_SUBSCRIPTION_ADMIN = gql`
  mutation UpdateNonRecurringSubscriptionAdmin(
    $where: UserUniqueInput!
    $data: UpdateNonRecurringSubscriptionAdminInput!
  ) {
    updateNonRecurringSubscriptionAdmin(where: $where, data: $data) {
      message
    }
  }
`;

export const UPDATE_USER_SUBSCRIPTION = gql`
  mutation UpdateUserSubscription(
    $where: UserSubscriptionUniqueInput!
    $data: UpdateUserSubscriptionInput!
  ) {
    updateUserSubscription(where: $where, data: $data) {
      message
    }
  }
`;
