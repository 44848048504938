import { Tabs } from 'antd';
import React, { useContext, useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { AppContext } from '../../../../../AppContext';
import { PERMISSIONS_KEY, ROUTES, TABS } from '../../../../../common/constants';
import { filterTabsByPermissions } from '../../../../../common/utils';

const LibraryTabs = ({ children }) => {
  const history = useHistory();
  const location = useLocation();
  const {
    state: { permissions },
  } = useContext(AppContext);
  const [activeTabKey, setActiveTabKey] = useState(TABS?.DAMAGES?.key);

  const tabList = [
    {
      key: TABS?.DAMAGES?.key,
      label: TABS?.DAMAGES?.label,
      children,
      permissionKey: PERMISSIONS_KEY?.REQUESTS_LIBRARY_DAMAGES,
    },
    {
      key: TABS?.CLOSURE_REQUESTS?.key,
      label: TABS?.CLOSURE_REQUESTS?.label,
      children,
      permissionKey: PERMISSIONS_KEY?.REQUESTS_LIBRARY_CLOSURE_REQUESTS,
    },
    {
      key: TABS?.BAGS?.key,
      label: TABS?.BAGS?.label,
      children,
      permissionKey: PERMISSIONS_KEY?.REQUESTS_LIBRARY_BAGS,
    },
    {
      key: TABS?.NEW_TOYS?.key,
      label: TABS?.NEW_TOYS?.label,
      children,
      permissionKey: PERMISSIONS_KEY?.REQUESTS_LIBRARY_NEW_TOYS,
    },
  ];

  const filteredTabs = filterTabsByPermissions(tabList, permissions);

  useEffect(() => {
    if (location?.pathname?.includes(ROUTES?.DAMAGES)) {
      setActiveTabKey(TABS?.DAMAGES?.key);
    }
    if (location?.pathname?.includes(ROUTES?.CLOSURE_REQUESTS)) {
      setActiveTabKey(TABS?.CLOSURE_REQUESTS?.key);
    }
    if (location?.pathname?.includes(ROUTES?.BAGS)) {
      setActiveTabKey(TABS?.BAGS?.key);
    }
    if (location?.pathname?.includes(ROUTES?.NEW_TOYS)) {
      setActiveTabKey(TABS?.NEW_TOYS?.key);
    }
  }, [location]);

  const onTabChange = (key) => {
    setActiveTabKey(key);
    switch (key) {
      case TABS?.DAMAGES?.key:
        history?.push(
          `${ROUTES?.REQUESTS}${ROUTES?.LIBRARY}${ROUTES?.DAMAGES}`,
        );
        break;
      case TABS?.CLOSURE_REQUESTS?.key:
        history?.push(
          `${ROUTES?.REQUESTS}${ROUTES?.LIBRARY}${ROUTES?.CLOSURE_REQUESTS}`,
        );
        break;
      case TABS?.BAGS?.key:
        history?.push(`${ROUTES?.REQUESTS}${ROUTES?.LIBRARY}${ROUTES?.BAGS}`);
        break;
      case TABS?.NEW_TOYS?.key:
        history?.push(
          `${ROUTES?.REQUESTS}${ROUTES?.LIBRARY}${ROUTES?.NEW_TOYS}`,
        );
        break;
      default:
        break;
    }
  };

  return (
    <div className="card-container">
      <Tabs
        activeKey={activeTabKey}
        type="card"
        className="full-width"
        onChange={(key) => onTabChange(key)}
        items={filteredTabs}
        destroyInactiveTabPane
      />
    </div>
  );
};

export default LibraryTabs;
