import { nth } from 'lodash';
import { useCallback, useEffect, useRef } from 'react';

const useInfiniteScroll = (callbackParam, isActive) => {
  const observer = useRef(null);

  useEffect(() => () => observer?.current?.disconnect(), []);

  const callback = useCallback(
    (entries) => {
      if (entries?.length === 0) {
        return;
      }
      if (nth(entries, 0)?.isIntersecting && isActive) {
        callbackParam();
      }
    },
    [callbackParam, isActive],
  );

  const infiniteScrollRef = useCallback(
    (node) => {
      if (!node) {
        return;
      }

      observer?.current?.disconnect();

      // eslint-disable-next-line no-undef
      observer.current = new IntersectionObserver(callback, {
        threshold: 0.6,
      });
      observer?.current?.observe(node);
    },
    [callback],
  );

  return infiniteScrollRef;
};

export default useInfiniteScroll;
