import { gql } from '@apollo/client';

// eslint-disable-next-line import/prefer-default-export
export const MATERIALS_ADMIN = gql`
  query MaterialsAdmin($filter: MaterialFilter, $sort: MaterialSort) {
    materialsAdmin(filter: $filter, sort: $sort) {
      count
      data {
        id
        name
        isArchived
        createdAt
      }
    }
  }
`;
