import { gql } from '@apollo/client';

// eslint-disable-next-line import/prefer-default-export
export const SYNC_COUPONS = gql`
  mutation SyncCoupons {
    syncCoupons {
      message
    }
  }
`;

export const UPDATE_COUPON = gql`
  mutation UpdateCoupon($where: CouponUniqueInput!, $data: UpdateCouponInput) {
    updateCoupon(where: $where, data: $data) {
      message
    }
  }
`;

export const REQUEST_COUPON_EXPORT = gql`
  mutation RequestCouponExport(
    $data: RequestExportInput!
    $filter: RequestCouponExportFilterInput
  ) {
    requestCouponExport(data: $data, filter: $filter) {
      message
    }
  }
`;

export const CREATE_COUPON = gql`
  mutation CreateCoupon($data: CreateCouponInput!) {
    createCoupon(data: $data) {
      message
    }
  }
`;

export const COUPON_REFERRAL_LINK = gql`
  mutation CouponReferralLink($where: VerifyCouponInput!) {
    couponReferralLink(where: $where) {
      data {
        url
      }
    }
  }
`;
