import { gql } from '@apollo/client';

// eslint-disable-next-line import/prefer-default-export
export const LIBRARIES = gql`
  query Libraries($filter: LibraryFilter, $sort: LibrarySort) {
    libraries(filter: $filter, sort: $sort) {
      count
      data {
        id
        name
      }
    }
  }
`;

export const NEW_ORDERS_ADMIN = gql`
  query NewOrdersAdmin($filter: OrdersAdminFilter, $sort: AdminOrderSort) {
    newOrdersAdmin(filter: $filter, sort: $sort) {
      count
      data {
        libraryId
        name
        libraryNumber
        orderCount
      }
    }
  }
`;

export const PINCODE_WISE_NEW_ORDER_COUNT = gql`
  query PincodeWiseNewOrdersCount($where: LibraryUniqueInput) {
    pincodeWiseNewOrdersCount(where: $where) {
      data {
        userNumber
        orderCount
        pincode
        pincodeId
        firstName
        lastName
        userId
      }
    }
  }
`;

export const NEW_RETURN_ORDER_ADMIN = gql`
  query NewReturnOrderAdmin(
    $filter: OrdersAdminFilter
    $sort: ReturnAdminOrderSort
  ) {
    newReturnOrderAdmin(filter: $filter, sort: $sort) {
      count
      data {
        pincodeId
        pincode
        returnOrderCount
        firstName
        lastName
        userId
        userNumber
      }
    }
  }
`;

export const NEW_RETURN_ORDER_DETAILS_ADMIN = gql`
  query NewReturnOrderDetailsAdmin(
    $where: PincodeUniqueInput!
    $filter: OrdersAdminFilter
    $sort: ReturnAdminOrderSort
  ) {
    newReturnOrderDetailsAdmin(where: $where, filter: $filter, sort: $sort) {
      count
      data {
        firstName
        lastName
        name
        libraryNumber
        returnOrderCount
        userNumber
        userId
      }
    }
  }
`;

export const NEW_ORDERS_ADMIN_V2 = gql`
  query NewOrdersAdminV2($filter: OrderFilter, $sort: OrderSort) {
    newOrdersAdminV2(filter: $filter, sort: $sort) {
      count
      data {
        orderNumber
        id
        deliveryType
        orderItemCount
        deliveryAttachments {
          key
          url
        }
        cancelledBy
        deliveredOn
        deliveryPartner {
          id
          userNumber
          firstName
          lastName
        }
        pincode {
          pincode
        }
        library {
          name
          id
          libraryNumber
        }
        user {
          id
          userNumber
          firstName
          lastName
          pincode {
            pincode
          }
        }
        createdAt
        status
        cancellationData {
          date
          additionalComment
          reason
          type
        }
        comment {
          isResolved
          comment
        }
      }
    }
  }
`;
