import React from 'react';
import { Route, Switch } from 'react-router-dom';
import { ROUTES } from '../../../../common/constants';
import './coupon.less';
import Coupon from './pages/Coupon';

const CouponWrapper = () => (
  <Switch>
    <Route exact path={`${ROUTES?.OFFERS}${ROUTES?.COUPON}`} render={Coupon} />
  </Switch>
);

export default CouponWrapper;
