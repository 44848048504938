import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import AccessControl from '../../../../AccessControl';
import Error404 from '../../../../Error404';
import { PERMISSIONS_KEY, ROUTES } from '../../../../common/constants';
import './garden.less';
import AnnuallyGarden from './pages/AnnuallyGarden';
import EditAnnuallyGarden from './pages/EditAnnuallyGarden';
import EditQuarterlyGarden from './pages/EditQuarterlyGarden';
import EditSemiAnnualGarden from './pages/EditSemiAnnualGarden';
import QuarterlyGarden from './pages/QuarterlyGarden';
import SemiAnnualGarden from './pages/SemiAnnualGarden';

const GardenWrapper = () => (
  <Switch>
    <Route
      exact
      path={`${ROUTES?.SUBSCRIPTION_PLANS}${ROUTES?.GARDEN}`}
      render={() => (
        <AccessControl permissionKey={PERMISSIONS_KEY?.SUBSCRIPTION_PLANS}>
          <Redirect
            to={`${ROUTES?.SUBSCRIPTION_PLANS}${ROUTES?.GARDEN}${ROUTES?.QUARTERLY}`}
          />
        </AccessControl>
      )}
    />
    <Route
      exact
      path={`${ROUTES?.SUBSCRIPTION_PLANS}${ROUTES?.GARDEN}${ROUTES?.QUARTERLY}`}
      render={(props) => (
        <AccessControl permissionKey={PERMISSIONS_KEY?.SUBSCRIPTION_PLANS}>
          <QuarterlyGarden {...props} />
        </AccessControl>
      )}
    />
    <Route
      exact
      path={`${ROUTES?.SUBSCRIPTION_PLANS}${ROUTES?.GARDEN}${ROUTES?.QUARTERLY}/edit`}
      render={(props) => (
        <AccessControl permissionKey={PERMISSIONS_KEY?.SUBSCRIPTION_PLANS}>
          <EditQuarterlyGarden {...props} />
        </AccessControl>
      )}
    />
    <Route
      exact
      path={`${ROUTES?.SUBSCRIPTION_PLANS}${ROUTES?.GARDEN}${ROUTES?.SEMI_ANNUAL}`}
      render={(props) => (
        <AccessControl permissionKey={PERMISSIONS_KEY?.SUBSCRIPTION_PLANS}>
          <SemiAnnualGarden {...props} />
        </AccessControl>
      )}
    />
    <Route
      exact
      path={`${ROUTES?.SUBSCRIPTION_PLANS}${ROUTES?.GARDEN}${ROUTES?.SEMI_ANNUAL}/edit`}
      render={(props) => (
        <AccessControl permissionKey={PERMISSIONS_KEY?.SUBSCRIPTION_PLANS}>
          <EditSemiAnnualGarden {...props} />
        </AccessControl>
      )}
    />
    <Route
      exact
      path={`${ROUTES?.SUBSCRIPTION_PLANS}${ROUTES?.GARDEN}${ROUTES?.ANNUALLY}`}
      render={(props) => (
        <AccessControl permissionKey={PERMISSIONS_KEY?.SUBSCRIPTION_PLANS}>
          <AnnuallyGarden {...props} />
        </AccessControl>
      )}
    />
    <Route
      exact
      path={`${ROUTES?.SUBSCRIPTION_PLANS}${ROUTES?.GARDEN}${ROUTES?.ANNUALLY}/edit`}
      render={(props) => (
        <AccessControl permissionKey={PERMISSIONS_KEY?.SUBSCRIPTION_PLANS}>
          <EditAnnuallyGarden {...props} />
        </AccessControl>
      )}
    />
    <Route path="*" exact component={Error404} />
  </Switch>
);

export default GardenWrapper;
