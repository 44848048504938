import { PlusOutlined } from '@ant-design/icons';
import { Divider, Form, Input, Upload } from 'antd';
import React, { useEffect, useState } from 'react';
import {
  formValidatorRules,
  getBase64,
  validateImage,
} from '../../../../../common/utils';
import ModalComponent from '../../../../../components/ModalComponent';

const { Item } = Form;
const { required } = formValidatorRules;

const TagsModal = ({
  isModalOpen,
  setIsModalOpen,
  onFinish,
  loadings,
  form,
  tags,
  setTags,
  tagsImages,
  setTagsImages,
  setVisible,
  setTagsPreviewImage,
}) => {
  const [isValidateTrigger, setIsValidateTrigger] = useState(false);

  const handleCancel = () => {
    setIsModalOpen(false);
    setTags();
    form?.resetFields();
  };

  useEffect(() => {
    form?.setFieldsValue({ name: tags?.name });
  }, [tags]);

  const handleImageChange = (info) => {
    validateImage(info, form, setTagsImages, 'image');
  };

  const handlePreview = async (fileObj) => {
    let preview;
    if (!fileObj?.url && !fileObj?.preview) {
      preview = await getBase64(fileObj?.originFileObj);
    }
    setVisible(true);
    setTagsPreviewImage(fileObj?.url || preview);
  };

  return (
    <>
      <ModalComponent
        open={isModalOpen}
        onCancel={handleCancel}
        onOk={form?.submit}
        title={tags ? 'Edit Tag' : 'Add New Tag'}
        okText="Save"
        confirmLoading={loadings}
        destroyOnClose
        forceRender
      >
        <Divider />
        <Form
          form={form}
          onFinish={(values) => {
            onFinish(values);
            setIsValidateTrigger(true);
          }}
          autoComplete="off"
          layout="vertical"
          validateTrigger={isValidateTrigger ? 'onChange' : 'onSubmit'}
          onFinishFailed={() => setIsValidateTrigger(true)}
        >
          <Item
            label="Name"
            name="name"
            rules={[{ ...required, message: 'Please Enter Name!' }]}
          >
            <Input allowClear placeholder="Enter Name" maxLength={50} />
          </Item>
          <Item label="Image" name="image">
            <Upload
              listType="picture-card"
              maxCount={1}
              fileList={tagsImages}
              beforeUpload={() => false}
              onPreview={handlePreview}
              onChange={handleImageChange}
              showUploadList={{
                showDownloadIcon: false,
                showPreviewIcon: true,
                showRemoveIcon: true,
              }}
            >
              {tagsImages?.length !== 1 && <PlusOutlined />}
            </Upload>
          </Item>
        </Form>
      </ModalComponent>
    </>
  );
};

export default TagsModal;
