import { DownOutlined } from '@ant-design/icons';
import { Card, Col, List, Row, Tree } from 'antd';
import React from 'react';
import { MODULES, ROUTES, TABS } from '../../../../../common/constants';

const readData = [
  {
    title: MODULES?.DASHBOARD,
    key: ROUTES?.MAIN,
  },
  {
    title: MODULES?.REQUESTS,
    key: ROUTES?.REQUESTS,
    children: [
      {
        title: MODULES?.END_USER,
        key: `${ROUTES?.REQUESTS}${ROUTES?.END_USER}`,
        children: [
          {
            title: TABS?.SUGGESTED_TOY?.label,
            key: `${ROUTES?.REQUESTS}${ROUTES?.END_USER}${ROUTES?.SUGGESTED_TOY}`,
          },
          {
            title: TABS?.CHANGE_ADDRESS?.label,
            key: `${ROUTES?.REQUESTS}${ROUTES?.END_USER}${ROUTES?.CHANGE_ADDRESS}`,
          },
          {
            title: TABS?.REPORT_DAMAGE?.label,
            key: `${ROUTES?.REQUESTS}${ROUTES?.END_USER}${ROUTES?.REPORT_DAMAGE}`,
          },
        ],
      },
    ],
  },
  {
    title: MODULES?.REPORTS,
    key: ROUTES?.REPORTS,
    children: [
      {
        title: MODULES?.HOLDING_PERIOD,
        key: `${ROUTES?.REPORTS}${ROUTES?.HOLDING_PERIOD}`,
      },
    ],
  },
  {
    title: MODULES?.MASTER_LISTS,
    key: ROUTES?.MASTER_LISTS,
    children: [
      {
        title: MODULES?.LIBRARY,
        key: `${ROUTES?.MASTER_LISTS}${ROUTES?.LIBRARY}`,
        children: [
          {
            title: TABS?.BASIC_DETAILS?.label,
            key: `${ROUTES?.MASTER_LISTS}${ROUTES?.LIBRARY}${ROUTES?.BASIC_DETAILS}`,
          },
        ],
      },
      {
        title: MODULES?.TOY,
        key: `${ROUTES?.MASTER_LISTS}${ROUTES?.TOY}`,
      },
    ],
  },
  {
    title: MODULES?.SYSTEM_USERS,
    key: ROUTES?.SYSTEM_USERS,
    children: [
      {
        title: MODULES?.DELIVERY_PARTNERS,
        key: `${ROUTES?.SYSTEM_USERS}${ROUTES?.DELIVERY_PARTNERS}`,
      },
    ],
  },
  {
    title: MODULES?.EXPORTS,
    key: ROUTES?.EXPORTS,
  },
  {
    title: MODULES?.NOTIFICATIONS,
    key: ROUTES?.NOTIFICATIONS,
  },
];

const createData = [
  {
    title: MODULES?.MASTER_LISTS,
    key: ROUTES?.MASTER_LISTS,
    children: [
      {
        title: MODULES?.LIBRARY,
        key: `${ROUTES?.MASTER_LISTS}${ROUTES?.LIBRARY}`,
      },
      {
        title: MODULES?.TOY,
        key: `${ROUTES?.MASTER_LISTS}${ROUTES?.TOY}`,
      },
    ],
  },
  {
    title: MODULES?.SYSTEM_USERS,
    key: ROUTES?.SYSTEM_USERS,
    children: [
      {
        title: MODULES?.DELIVERY_PARTNERS,
        key: `${ROUTES?.SYSTEM_USERS}${ROUTES?.DELIVERY_PARTNERS}`,
      },
    ],
  },
];

const updateData = [
  {
    title: MODULES?.MASTER_LISTS,
    key: ROUTES?.MASTER_LISTS,
    children: [
      {
        title: MODULES?.LIBRARY,
        key: `${ROUTES?.MASTER_LISTS}${ROUTES?.LIBRARY}`,
      },
      {
        title: MODULES?.TOY,
        key: `${ROUTES?.MASTER_LISTS}${ROUTES?.TOY}`,
      },
    ],
  },
  {
    title: MODULES?.SYSTEM_USERS,
    key: ROUTES?.SYSTEM_USERS,
    children: [
      {
        title: MODULES?.DELIVERY_PARTNERS,
        key: `${ROUTES?.SYSTEM_USERS}${ROUTES?.DELIVERY_PARTNERS}`,
      },
    ],
  },
  {
    title: MODULES?.NOTIFICATIONS,
    key: ROUTES?.NOTIFICATIONS,
  },
];

const BasicAdminPermission = () => (
  <Row gutter={[8, 16]}>
    <Col xs={24} sm={24} md={12} lg={12} xl={6} xxl={6}>
      <List.Item>
        <Card title="Read Only">
          <Tree showLine switcherIcon={<DownOutlined />} treeData={readData} />
        </Card>
      </List.Item>
    </Col>
    <Col xs={24} sm={24} md={12} lg={12} xl={6} xxl={6}>
      <List.Item>
        <Card title="Create Only">
          <Tree
            showLine
            switcherIcon={<DownOutlined />}
            treeData={createData}
          />
        </Card>
      </List.Item>
    </Col>
    <Col xs={24} sm={24} md={12} lg={12} xl={6} xxl={6}>
      <List.Item>
        <Card title="Update (edit & other actions)">
          <Tree
            showLine
            switcherIcon={<DownOutlined />}
            treeData={updateData}
          />
        </Card>
      </List.Item>
    </Col>
    <Col xs={24} sm={24} md={12} lg={12} xl={6} xxl={6}>
      <List.Item>
        <Card title="Delete Only">No Delete Permissions</Card>
      </List.Item>
    </Col>
  </Row>
);

export default BasicAdminPermission;
