import { DeleteOutlined, PlusOutlined } from '@ant-design/icons';
import { Col, Divider, Form, Input, Radio, Row, Upload } from 'antd';
import { map } from 'lodash';
import React, { useEffect, useState } from 'react';
import { VIDEO_STATUS, VIDEO_TYPE } from '../../../common/constants';
import {
  formValidatorRules,
  getBase64,
  validateImage,
  validateVideo,
} from '../../../common/utils';
import CommonPreview from '../../../components/CommonPreview';
import ModalComponent from '../../../components/ModalComponent';

const { Item } = Form;
const { TextArea } = Input;
const { Group } = Radio;
const { required, whitespaceNotAllowed, videoTitle, url } = formValidatorRules;

const HomeVideoModal = ({
  title,
  videoData,
  setVideoData,
  videoType,
  setVideoType,
  isModalOpen,
  setIsModalOpen,
  isBtnLoading,
  onFinish,
  thumbnailImage,
  setThumbnailImage,
  form,
  toyVideo,
  setToyVideo,
  toyPreviewImage,
  setToyPreviewImage,
  toyPreviewVideo,
  setToyPreviewVideo,
}) => {
  const [validationTriggered, setValidationTriggered] = useState(false);
  const [visible, setVisible] = useState(false);

  const handleToyImageChange = (info) => {
    validateImage(info, form, setThumbnailImage, 'thumbnailImage');
  };

  const handleVideoChange = async (info) => {
    const res = await validateVideo(info, form, setToyVideo, 'video');
    if (res) {
      setToyPreviewVideo(
        URL.createObjectURL(info?.fileList?.[0]?.originFileObj),
      );
    }
  };

  const handlePreview = async (fileObj) => {
    let preview;
    if (!fileObj?.url && !fileObj?.preview) {
      preview = await getBase64(fileObj?.originFileObj);
    }
    setVisible(true);
    setToyPreviewImage(fileObj?.url || preview);
  };

  useEffect(() => {
    if (videoData) {
      const initialValues = {
        ...videoData,
        ...(videoData?.type === VIDEO_TYPE?.INTERNAL?.value
          ? {
              thumbnailImage: [
                {
                  key: videoData?.thumbnailKey,
                  url: videoData?.thumbnailUrl,
                },
              ],
              video: [
                {
                  key: videoData?.key,
                  url: videoData?.url,
                },
              ],
            }
          : {
              videoUrl: videoData?.url,
            }),
      };
      form?.setFieldsValue(initialValues);
      setVideoType(videoData?.type);
      if (videoData?.type === VIDEO_TYPE?.INTERNAL?.value) {
        setToyPreviewVideo(videoData?.url);
        setThumbnailImage([
          {
            key: videoData?.thumbnailKey,
            url: videoData?.thumbnailUrl,
          },
        ]);
      }
    }
  }, [videoData]);

  const onTypeChange = ({ target: { value } }) => {
    setVideoType(value);
  };

  return (
    <>
      <CommonPreview
        visible={visible}
        setVisible={setVisible}
        image={toyPreviewImage}
        setImage={setToyPreviewImage}
      />
      <ModalComponent
        title={title}
        okText="Save"
        cancelText="Cancel"
        open={isModalOpen}
        onOk={form?.submit}
        onCancel={() => {
          setIsModalOpen(false);
          setThumbnailImage([]);
          setToyVideo([]);
          setToyPreviewVideo(null);
          setToyPreviewImage(null);
          setVideoData(null);
          setVideoType(VIDEO_TYPE?.INTERNAL?.value);
          form?.resetFields();
        }}
        confirmLoading={isBtnLoading}
        forceRender
      >
        <Divider />
        <Form
          form={form}
          layout="vertical"
          onFinish={onFinish}
          onFinishFailed={() => setValidationTriggered(true)}
          validateTrigger={validationTriggered ? 'onChange' : 'onSubmit'}
        >
          <Row>
            <Col xs={24}>
              <Item
                label="Title"
                name="title"
                rules={[
                  { ...required, message: 'Please Enter Title!' },
                  videoTitle,
                ]}
              >
                <Input allowClear placeholder="Enter Title" maxLength={100} />
              </Item>
            </Col>
            <Col xs={24}>
              <Item
                name="description"
                label="Description"
                rules={[whitespaceNotAllowed]}
              >
                <TextArea
                  rows={2}
                  allowClear
                  placeholder="Enter Description"
                  showCount
                  maxLength={700}
                />
              </Item>
            </Col>
            <Col xs={12}>
              <Item
                label="Type"
                name="type"
                initialValue={VIDEO_TYPE?.INTERNAL?.value}
              >
                <Group
                  options={map(VIDEO_TYPE, (record) => record)}
                  onChange={onTypeChange}
                />
              </Item>
            </Col>
            <Col xs={12}>
              <Item
                label="Status"
                name="status"
                initialValue={VIDEO_STATUS?.DRAFT?.value}
              >
                <Group options={map(VIDEO_STATUS, (record) => record)} />
              </Item>
            </Col>
            {videoType === VIDEO_TYPE?.INTERNAL?.value ? (
              <>
                <Col xs={24}>
                  <Item
                    name="thumbnailImage"
                    label="Upload Thumbnail Image"
                    rules={[
                      {
                        required: true,
                        message: 'Please Upload Thumbnail Image!',
                      },
                    ]}
                  >
                    <Upload
                      name="avatar"
                      listType="picture-card"
                      maxCount={1}
                      fileList={thumbnailImage}
                      beforeUpload={() => false}
                      onPreview={handlePreview}
                      onChange={handleToyImageChange}
                      className="avatar-uploader"
                      showUploadList={{
                        showDownloadIcon: false,
                        showPreviewIcon: true,
                        showRemoveIcon: true,
                      }}
                    >
                      {thumbnailImage?.length !== 1 && <PlusOutlined />}
                    </Upload>
                  </Item>
                </Col>
                <Col xs={24}>
                  <Item
                    label="Video"
                    name="video"
                    rules={[
                      { required: true, message: 'Please Upload Video!' },
                    ]}
                  >
                    {!toyPreviewVideo && !toyVideo?.url ? (
                      <Upload
                        listType="picture-card"
                        maxCount={1}
                        fileList={toyVideo}
                        beforeUpload={() => false}
                        onChange={handleVideoChange}
                        showUploadList={{
                          showDownloadIcon: false,
                          showPreviewIcon: false,
                          showRemoveIcon: false,
                        }}
                      >
                        {toyVideo?.length !== 1 && <PlusOutlined />}
                      </Upload>
                    ) : (
                      <>
                        <div className="toy-video-container mb-10">
                          <video
                            width="100%"
                            height="100%"
                            controls
                            src={toyPreviewVideo || toyVideo?.url}
                            className="toy-video-upload"
                          >
                            <track kind="captions" />
                          </video>
                          <DeleteOutlined
                            className="toy-video-delete-icon"
                            onClick={() => {
                              setToyVideo([]);
                              setToyPreviewVideo(null);
                            }}
                          />
                        </div>
                      </>
                    )}
                  </Item>
                </Col>
              </>
            ) : (
              <Col xs={24}>
                <Item
                  label="Video URL"
                  name="videoUrl"
                  rules={[
                    {
                      ...required,
                      message: 'Please Enter Video URL!',
                    },
                    url,
                  ]}
                >
                  <Input allowClear placeholder="Enter Video URL" />
                </Item>
              </Col>
            )}
          </Row>
        </Form>
      </ModalComponent>
    </>
  );
};

export default HomeVideoModal;
