import { gql } from '@apollo/client';

// eslint-disable-next-line import/prefer-default-export
export const CREATE_MATERIAL = gql`
  mutation CreateMaterial($data: CreateMaterialInput!) {
    createMaterial(data: $data) {
      message
    }
  }
`;

export const UPDATE_MATERIAL = gql`
  mutation UpdateMaterial(
    $where: MaterialUniqueInput!
    $data: UpdateMaterialInput
  ) {
    updateMaterial(where: $where, data: $data) {
      message
    }
  }
`;
