import { useLazyQuery } from '@apollo/client';
import { Empty, Select } from 'antd';
import { debounce, uniqBy } from 'lodash';
import React, { useEffect, useState } from 'react';
import { LIMIT, SORT } from '../../../../../common/constants';
import SelectComponent from '../../../../../components/SelectComponent';
import { BRANDS_ADMIN } from '../graphql/Queries';

const { Option } = Select;

const BrandSelect = (props) => {
  const { toyDetail, ...rest } = props;
  const [brandList, setBrandList] = useState([]);
  const [brandsSearchTerm, setBrandsSearchTerm] = useState('');
  const [isAllBrandsFetched, setIsAllBrandsFetched] = useState(false);
  const [isBrandsAllowClear, setIsBrandsAllowClear] = useState(false);
  const [isBrandsDropdownVisible, setIsBrandsDropdownVisible] = useState(false);
  const [isFetchMoreBrandsLoading, setIsFetchMoreBrandsLoading] = useState(
    false,
  );

  const [brands, { loading: isBrandsLoading }] = useLazyQuery(BRANDS_ADMIN, {
    onCompleted: (response) => {
      if (response?.catalogBrandsAdmin?.data?.length < LIMIT) {
        setIsAllBrandsFetched(true);
      }
      setIsFetchMoreBrandsLoading(false);
      if (toyDetail) {
        setBrandList(
          uniqBy(
            [toyDetail?.brand, ...brandList, ...response?.catalogBrandsAdmin?.data],
            'id',
          ),
        );
        return;
      }
      setBrandList([...brandList, ...response?.catalogBrandsAdmin?.data]);
    },
    fetchPolicy: 'network-only',
    onError() { },
  });

  useEffect(() => {
    setBrandsSearchTerm('');
    setBrandList([]);
    brands({
      variables: {
        filter: {
          limit: LIMIT,
          skip: 0,
          search: brandsSearchTerm,
        },
        sort: {
          field: 'name',
          order: SORT?.ASC,
        },
      },
    });
    setIsAllBrandsFetched(false);
  }, [isBrandsDropdownVisible]);

  const handleCategoriesScroll = (event) => {
    if (!isAllBrandsFetched && !isBrandsLoading) {
      const target = event?.target;
      const currentLength = brandList?.length;
      if (
        target?.scrollTop + target?.offsetHeight >=
        target?.scrollHeight - 5
      ) {
        setIsFetchMoreBrandsLoading(true);
        brands({
          variables: {
            filter: {
              limit: LIMIT,
              skip: currentLength,
              search: brandsSearchTerm,
            },
            sort: {
              field: 'name',
              order: SORT?.ASC,
            },
          },
        });
      }
    }
  };

  const handleCategoriesSearch = (searchTerm) => {
    const trimSearch = searchTerm?.trim();
    setIsAllBrandsFetched(false);
    setBrandList([]);
    setBrandsSearchTerm(trimSearch);
    brands({
      variables: {
        filter: {
          limit: LIMIT,
          skip: 0,
          search: trimSearch,
        },
        sort: {
          field: 'name',
          order: SORT?.ASC,
        },
      },
    });
  };

  const handleCategoriesScrollDebounce = debounce(handleCategoriesScroll, 500);
  const handleSearchCategoriesDebounce = debounce(handleCategoriesSearch, 500);

  useEffect(() => {
    handleCategoriesScrollDebounce?.cancel();
  }, [handleCategoriesScrollDebounce]);

  useEffect(() => {
    handleSearchCategoriesDebounce?.cancel();
  }, [handleSearchCategoriesDebounce]);

  useEffect(() => {
    if (isBrandsLoading) {
      setIsBrandsAllowClear(false);
    } else {
      setIsBrandsAllowClear(true);
    }
  }, [isBrandsLoading]);

  return (
    <SelectComponent
      showSearch
      loading={
        (isBrandsLoading && isBrandsDropdownVisible) || isFetchMoreBrandsLoading
      }
      placeholder="Select Brand"
      optionFilterProp="children"
      allowClear={isBrandsAllowClear}
      onPopupScroll={handleCategoriesScrollDebounce}
      onSearch={handleSearchCategoriesDebounce}
      onDropdownVisibleChange={(visible) => setIsBrandsDropdownVisible(visible)}
      filterOption={false}
      notFoundContent={
        !isFetchMoreBrandsLoading && isBrandsLoading ? (
          <span>Loading...</span>
        ) : (
          <Empty image={Empty?.PRESENTED_IMAGE_SIMPLE} />
        )
      }
      {...rest}
    >
      {brandList?.map((brand) => (
        <Option key={brand?.id} value={brand?.id}>
          {brand?.name}
        </Option>
      ))}
    </SelectComponent>
  );
};

export default BrandSelect;
