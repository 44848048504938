import { useLazyQuery } from '@apollo/client';
import { Empty, Select } from 'antd';
import { debounce, uniqBy } from 'lodash';
import React, { useEffect, useState } from 'react';
import { LIMIT, SORT } from '../../../../../common/constants';
import SelectComponent from '../../../../../components/SelectComponent';
import { CONTENT_MASTERS } from '../graphql/Queries';

const { Option } = Select;

const ContentMasterSelect = (props) => {
  const { contentMastersData, ...rest } = props;
  const [contentMasterList, setContentMasterList] = useState([]);
  const [contentMasterSearchTerm, setContentMasterSearchTerm] = useState('');
  const [isAllContentMasterFetched, setIsAllContentMasterFetched] = useState(
    false,
  );
  const [isContentMasterAllowClear, setIsContentMasterAllowClear] = useState(
    false,
  );
  const [
    isContentMasterDropdownVisible,
    setIsContentMasterDropdownVisible,
  ] = useState(false);
  const [
    isFetchMoreContentMasterLoading,
    setIsFetchMoreContentMasterLoading,
  ] = useState(false);

  const [contentMasters, { loading: isContentMasterLoading }] = useLazyQuery(
    CONTENT_MASTERS,
    {
      onCompleted: (response) => {
        if (response?.contentMasters?.data?.length < LIMIT) {
          setIsAllContentMasterFetched(true);
        }
        setIsFetchMoreContentMasterLoading(false);
        if (contentMasters) {
          setContentMasterList(
            uniqBy(
              [
                ...contentMastersData,
                ...contentMasterList,
                ...response?.contentMasters?.data,
              ],
              'id',
            ),
          );
          return;
        }
        setContentMasterList([
          ...contentMasterList,
          ...response?.contentMasters?.data,
        ]);
      },
      fetchPolicy: 'network-only',
      onError() {},
    },
  );

  useEffect(() => {
    setContentMasterSearchTerm('');
    setContentMasterList([]);
    contentMasters({
      variables: {
        filter: {
          limit: LIMIT,
          skip: 0,
          search: contentMasterSearchTerm,
          isArchived: false,
        },
        sort: {
          field: 'key',
          order: SORT?.ASC,
        },
      },
    });
    setIsAllContentMasterFetched(false);
  }, [isContentMasterDropdownVisible]);

  const handleContentMasterScroll = (event) => {
    if (!isAllContentMasterFetched && !isContentMasterLoading) {
      const target = event?.target;
      const currentLength = contentMasterList?.length;
      if (
        target?.scrollTop + target?.offsetHeight >=
        target?.scrollHeight - 5
      ) {
        setIsFetchMoreContentMasterLoading(true);
        contentMasters({
          variables: {
            filter: {
              limit: LIMIT,
              skip: currentLength,
              search: contentMasterSearchTerm,
              isArchived: false,
            },
            sort: {
              field: 'key',
              order: SORT?.ASC,
            },
          },
        });
      }
    }
  };

  const handleContentMasterSearch = (searchTerm) => {
    const trimSearch = searchTerm?.trim();
    setIsAllContentMasterFetched(false);
    setContentMasterList([]);
    setContentMasterSearchTerm(trimSearch);
    contentMasters({
      variables: {
        filter: {
          limit: LIMIT,
          skip: 0,
          search: trimSearch,
          isArchived: false,
        },
        sort: {
          field: 'key',
          order: SORT?.ASC,
        },
      },
    });
  };

  const handleContentMasterScrollDebounce = debounce(
    handleContentMasterScroll,
    500,
  );
  const handleSearchContentMasterDebounce = debounce(
    handleContentMasterSearch,
    500,
  );

  useEffect(() => {
    handleContentMasterScrollDebounce?.cancel();
  }, [handleContentMasterScrollDebounce]);

  useEffect(() => {
    handleSearchContentMasterDebounce?.cancel();
  }, [handleSearchContentMasterDebounce]);

  useEffect(() => {
    if (isContentMasterLoading) {
      setIsContentMasterAllowClear(false);
    } else {
      setIsContentMasterAllowClear(true);
    }
  }, [isContentMasterLoading]);

  return (
    <SelectComponent
      showSearch
      loading={
        (isContentMasterLoading && isContentMasterDropdownVisible) ||
        isFetchMoreContentMasterLoading
      }
      placeholder="Select Content Master"
      optionFilterProp="children"
      allowClear={isContentMasterAllowClear}
      onPopupScroll={handleContentMasterScrollDebounce}
      onSearch={handleSearchContentMasterDebounce}
      onDropdownVisibleChange={(visible) =>
        setIsContentMasterDropdownVisible(visible)
      }
      filterOption={false}
      notFoundContent={
        !isFetchMoreContentMasterLoading && isContentMasterLoading ? (
          <span>Loading...</span>
        ) : (
          <Empty image={Empty?.PRESENTED_IMAGE_SIMPLE} />
        )
      }
      {...rest}
    >
      {contentMasterList?.map((contentMaster) => (
        <Option key={contentMaster?.id} value={contentMaster?.id}>
          {contentMaster?.value}
        </Option>
      ))}
    </SelectComponent>
  );
};

export default ContentMasterSelect;
