import { Card } from 'antd';
import React, { useState } from 'react';
import {
  MODULES,
} from '../../../../common/constants';
import Portal from '../../../../components/Portal';
import BannerTable from '../components/BannerTable';

const Banner = () => {
  const [isBannerModalOpen, setIsBannerModalOpen] = useState(false);
  const [bannerImages, setBannerImages] = useState([]);

  return (
    <>
      <Portal portalId="header-title-content">
        <div className="portal-header">{MODULES?.BANNER}</div>
      </Portal>
      {/* <Portal portalId="header-right-content">
        {hasPermission(
          PERMISSIONS_KEY?.MASTER_LIST_TAG,
          PERMISSION_TYPE?.CREATE,
          permissions,
        ) && (
            <Button
              className="common-button"
              size="middle"
              icon={<PlusOutlined />}
              onClick={() => {
                setIsBannerModalOpen(true);
                setBannerImages([]);
              }}
            >
              Add New Banner
            </Button>
          )}
      </Portal> */}
      {/* <Portal portalId="header-right-content-phones">
        {hasPermission(
          PERMISSIONS_KEY?.MASTER_LIST_TAG,
          PERMISSION_TYPE?.CREATE,
          permissions,
        ) && (
            <Button
              className="common-button"
              size="middle"
              icon={<PlusOutlined />}
              onClick={() => {
                setIsBannerModalOpen(true);
                setBannerImages([]);
              }}
            >
              Add New Banner
            </Button>
          )}
      </Portal> */}
      <Card className="full-height-without-nav">
        <BannerTable
          isBannerModalOpen={isBannerModalOpen}
          setIsBannerModalOpen={setIsBannerModalOpen}
          bannerImages={bannerImages}
          setBannerImages={setBannerImages}
        />
      </Card>
    </>
  );
};

export default Banner;
