import { PlusOutlined } from '@ant-design/icons';
import { Divider, Form, Upload } from 'antd';
import React, { useEffect, useState } from 'react';
import { NUMBER_FIELD_TYPES } from '../../../../../common/constants';
import {
  formValidatorRules,
  getBase64,
  validateImage,
} from '../../../../../common/utils';
import ModalComponent from '../../../../../components/ModalComponent';
import NumberComponent from '../../../../../components/NumberComponent';

const { Item } = Form;
const { minAgeRule, maxAgeRule } = formValidatorRules;

const AgeGroupModal = ({
  isModalOpen,
  setIsModalOpen,
  onFinish,
  loadings,
  form,
  ageGroup,
  setAgeGroup,
  ageGroupImages,
  setAgeGroupImages,
  setVisible,
  setAgePreviewImage,
}) => {
  const [isValidateTrigger, setIsValidateTrigger] = useState(false);
  const [minAge, setMinAge] = useState();
  const [maxAge, setMaxAge] = useState();

  const handleCancel = () => {
    setIsModalOpen(false);
    setAgeGroup();
    form?.resetFields();
    setMinAge();
    setMaxAge();
  };

  useEffect(() => {
    form?.setFieldsValue({
      minAge: ageGroup?.minAge,
      maxAge: ageGroup?.maxAge,
    });
  }, [ageGroup]);

  const handleImageChange = (info) => {
    validateImage(info, form, setAgeGroupImages, 'image');
  };

  const handlePreview = async (fileObj) => {
    let preview;
    if (!fileObj?.url && !fileObj?.preview) {
      preview = await getBase64(fileObj?.originFileObj);
    }
    setVisible(true);
    setAgePreviewImage(fileObj?.url || preview);
  };

  return (
    <>
      <ModalComponent
        open={isModalOpen}
        onCancel={handleCancel}
        onOk={form?.submit}
        title={ageGroup ? 'Edit Age Group' : 'Add New Age Group'}
        okText="Save"
        confirmLoading={loadings}
        destroyOnClose
        forceRender
      >
        <Divider />
        <Form
          form={form}
          onFinish={(values) => {
            onFinish(values);
            setIsValidateTrigger(true);
          }}
          autoComplete="off"
          layout="vertical"
          validateTrigger={isValidateTrigger ? 'onChange' : 'onSubmit'}
          onFinishFailed={() => setIsValidateTrigger(true)}
        >
          <Item
            name="minAge"
            label="Min Age (in months)"
            rules={[
              { required: true, message: 'Please Enter Min Age!' },
              minAgeRule(minAge, maxAge),
            ]}
          >
            <NumberComponent
              allowClear
              placeholder="Enter Min Age"
              isFractional={false}
              type={NUMBER_FIELD_TYPES?.AGE}
              onChange={(e) => setMinAge(e?.target?.value)}
            />
          </Item>
          <Item
            name="maxAge"
            label="Max Age (in months)"
            rules={[
              { required: true, message: 'Please Enter Max Age!' },
              maxAgeRule(minAge, maxAge),
            ]}
          >
            <NumberComponent
              allowClear
              placeholder="Enter Max Age"
              isFractional={false}
              type={NUMBER_FIELD_TYPES?.AGE}
              onChange={(e) => setMaxAge(e?.target?.value)}
            />
          </Item>
          <Item
            label="Image"
            name="image"
            rules={[{ required: true, message: 'Please Upload Image!' }]}
          >
            <Upload
              listType="picture-card"
              maxCount={1}
              fileList={ageGroupImages}
              beforeUpload={() => false}
              onPreview={handlePreview}
              onChange={handleImageChange}
              showUploadList={{
                showDownloadIcon: false,
                showPreviewIcon: true,
                showRemoveIcon: true,
              }}
            >
              {ageGroupImages?.length !== 1 && <PlusOutlined />}
            </Upload>
          </Item>
        </Form>
      </ModalComponent>
    </>
  );
};

export default AgeGroupModal;
