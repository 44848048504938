import { useMutation } from '@apollo/client';
import { Button, Card, Form } from 'antd';
import moment from 'moment-timezone';
import React, { useContext, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { AppContext } from '../../../../../AppContext';
import { ROUTES } from '../../../../../common/constants';
import GoBackButton from '../../../../../components/GoBackButton';
import Portal from '../../../../../components/Portal';
import { CREATE_USER } from '../../../graphql/Mutations';
import AdminForm from '../components/AdminForm';

const CreateAdmin = () => {
  const history = useHistory();
  const [form] = Form.useForm();
  const { dispatch } = useContext(AppContext);
  const [isDisabled, setIsDisabled] = useState(true);
  const [isSubmitLoading, setIsSubmitLoading] = useState(false);

  const [createUser] = useMutation(CREATE_USER, {
    onError: () => {},
  });

  const createAdminFun = async (adminDetails) => {
    setIsSubmitLoading(true);
    dispatch({
      type: 'SET_SHOW_PROMPT',
      data: false,
    });
    const response = await createUser({
      variables: {
        data: {
          firstName: adminDetails?.firstName?.trim(),
          lastName: adminDetails?.lastName?.trim(),
          gender: adminDetails?.gender,
          city: adminDetails?.city,
          state: adminDetails?.state,
          roles: [adminDetails?.role],
          contactNo: adminDetails?.contact?.trim(),
          address: adminDetails?.address?.trim(),
          email: adminDetails?.email?.trim(),
          pincode: adminDetails?.pincode,
          isActive: adminDetails?.status,
          timezone: moment?.tz?.guess(),
        },
      },
    });
    if (response?.data) {
      history?.replace(`${ROUTES?.SYSTEM_USERS}${ROUTES?.ADMINS}`);
    }
    setIsSubmitLoading(false);
  };

  const handleShowPrompt = () => {
    setIsDisabled(false);
    dispatch({
      type: 'SET_SHOW_PROMPT',
      data: true,
    });
  };

  return (
    <>
      <Portal portalId="header-title-content">
        <GoBackButton customLink={ROUTES?.SYSTEM_USERS} />
        <div className="portal-header">Add New Admin</div>
      </Portal>
      <Portal portalId="header-right-content">
        <div className="header-btn-wrapper">
          <Button
            type="primary"
            className="common-button mr-16"
            size="middle"
            onClick={() =>
              history?.push(`${ROUTES?.SYSTEM_USERS}${ROUTES?.ADMINS}`)
            }
          >
            Cancel
          </Button>
          <Button
            className="common-button"
            size="middle"
            onClick={form.submit}
            loading={isSubmitLoading}
            disabled={isDisabled}
          >
            Save
          </Button>
        </div>
      </Portal>
      <Portal portalId="header-right-content-phones">
        <div className="header-btn-wrapper">
          <Button
            type="primary"
            className="common-button mr-16"
            size="middle"
            onClick={() =>
              history?.push(`${ROUTES?.SYSTEM_USERS}${ROUTES?.ADMINS}`)
            }
          >
            Cancel
          </Button>
          <Button
            className="common-button"
            size="middle"
            onClick={form.submit}
            loading={isSubmitLoading}
            disabled={isDisabled}
          >
            Save
          </Button>
        </div>
      </Portal>
      <Card className="full-height-with-nav" title="Admin Details">
        <div className="admin-form">
          <AdminForm
            form={form}
            onFinish={createAdminFun}
            handleShowPrompt={handleShowPrompt}
          />
        </div>
      </Card>
    </>
  );
};

export default CreateAdmin;
