import { Badge, Divider } from 'antd';

import React from 'react';
import {
  ORDER_STATUS,
  RETURN_CONTENT_MASTER_ORDER_ITEM_STATUS,
} from '../../../../../common/constants';
import CommonTable from '../../../../../components/CommonTable';
import ModalComponent from '../../../../../components/ModalComponent';

const ContentMasterModal = ({
  isModalOpen,
  contentHistory,
  handleCancel,
  isReturn = false,
  title = 'Content Logs',
}) => {
  const columns = [
    {
      title: 'STATUS',
      dataIndex: 'status',
      key: 'status',
      ellipsis: true,
      width: 450,
      align: 'left',
      className: 'max-width-column',
      render: (_, record) =>
        isReturn ? (
          RETURN_CONTENT_MASTER_ORDER_ITEM_STATUS?.[record?.status]?.label
        ) : (
          <Badge
            color={ORDER_STATUS?.[record?.status]?.color}
            text={ORDER_STATUS?.[record?.status]?.text}
          />
        ),
    },
  ];

  const expandedRowRender = (data, index) => {
    const nestedColumns = [
      {
        title: 'LABEL',
        dataIndex: 'label',
        key: 'label',
        ellipsis: true,
        align: 'left',
        className: 'max-width-column',
        render: (_, record) => record?.value || '-',
      },
      {
        title: 'OLD QUANTITY',
        dataIndex: 'oldQuantity',
        key: 'oldQuantity',
        ellipsis: true,
        align: 'left',
        className: 'max-width-column',
        render: (_, __, idx) =>
          contentHistory?.[index]?.oldContent?.[idx]?.quantity || '-',
      },
      {
        title: 'NEW QUANTITY',
        dataIndex: 'newQuantity',
        key: 'newQuantity',
        ellipsis: true,
        align: 'left',
        className: 'max-width-column',
        render: (_, record) => record?.quantity || '-',
      },
    ];

    return (
      <CommonTable
        columns={nestedColumns}
        dataSource={data?.newContent}
        pagination={false}
        rowKey={(record) => record?.id}
        className="nested-table"
      />
    );
  };

  return (
    <ModalComponent
      open={isModalOpen}
      onCancel={handleCancel}
      footer={null}
      title={title}
      width={600}
      destroyOnClose
    >
      <Divider />
      <div className="mt-24">
        <CommonTable
          columns={columns}
          data={contentHistory}
          scroll={{ y: 350 }}
          rowKey={(record) => record?.id}
          expandable={{
            expandedRowRender: (record, index) =>
              expandedRowRender(record, index),
          }}
        />
      </div>
    </ModalComponent>
  );
};

export default ContentMasterModal;
