import { FilterFilled, FilterOutlined } from '@ant-design/icons';
import { useLazyQuery, useMutation } from '@apollo/client';
import {
  Badge,
  Button,
  Checkbox,
  DatePicker,
  Divider,
  Empty,
  Modal,
  Radio,
  Tag,
  Tooltip,
} from 'antd';
import { debounce, filter, forEach, isEmpty, map } from 'lodash';
import React, { useContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { AppContext } from '../../../../../AppContext';
import { ReactComponent as EditIcon } from '../../../../../assets/svg/pen-to-square-solid.svg';
import { ReactComponent as ActiveIcon } from '../../../../../assets/svg/user-solid.svg';
import {
  BREAKPOINTS,
  DATE_FORMATS,
  LIMIT,
  PAYMENT_TYPE,
  PERMISSIONS_KEY,
  PERMISSION_TYPE,
  PLAN_NAMES,
  PLAN_NAMES_OPTIONS,
  PLAN_TYPES,
  PLAN_TYPES_OPTIONS,
  ROUTES,
  SORT,
  STATUS,
  USER_FORM_STATUS,
  USER_ROLES,
} from '../../../../../common/constants';
import { formatDate, hasPermission } from '../../../../../common/utils';
import CommonTable from '../../../../../components/CommonTable';
import LoaderComponent from '../../../../../components/LoaderComponent';
import SearchComponent from '../../../../../components/SearchComponent';
import { LIBRARIES } from '../../../../requests/modules/library/graphql/Queries';
import { UPDATE_USER } from '../../../graphql/Mutations';
import { USERS } from '../../../graphql/Queries';

const { RangePicker } = DatePicker;
const { Group } = Radio;

const initialPaginationValue = {
  total: 0,
  current: 1,
  pageSize: 10,
};

const initialTableFilter = {
  skip: 0,
  limit: LIMIT,
  search: '',
};

const initialTableSort = {
  field: 'createdAt',
  order: 'DESC',
};

const initialUsersFilter = {
  limit: LIMIT,
  search: '',
  skip: 0,
};

const initialUsersSort = {
  field: 'createdAt',
  order: 'DESC',
};

const roleFilter = [USER_ROLES?.USER?.key];
let filterScrollDebounceJob = null;

const EndUsersTable = ({ exportFilter, setExportFilter }) => {
  const {
    state: {
      permissions,
      commonPermissions: { isLibraryPermission },
    },
  } = useContext(AppContext);
  const history = useHistory();
  const [endUserList, setEndUserList] = useState([]);
  const [endUserSearchTerm, setEndUserSearchTerm] = useState('');
  const [isEmptyEndUserList, setIsEmptyEndUserList] = useState(false);
  const [endUser, setEndUser] = useState({});
  const [query, setQuery] = useState('');
  const [sortedInfo, setSortedInfo] = useState({});
  const [paginationProp, setPaginationProp] = useState(initialPaginationValue);

  const [isActivePrompts, setIsActivePrompts] = useState(false);

  const [isEndUserLoading, setIsEndUserLoading] = useState(true);
  const [isActiveLoading, setIsActiveLoading] = useState(false);

  const [filterSearch, setFilterSearch] = useState('');
  const [filterList, setFilterList] = useState([]);
  const [whereFilter, setWhereFilter] = useState();
  const [filterLoading, setFilterLoading] = useState(false);
  const [filterTags, setFilterTags] = useState(null);
  const [filterVisible, setFilterVisible] = useState(false);
  const [filterIndex, setFilterIndex] = useState(null);
  const [finalWhereFilters, setFinalWhereFilters] = useState();
  const [finalFilterTags, setFinalFilterTags] = useState(null);
  const [filterIsEnd, setFilterIsEnd] = useState(false);
  const [isEmptyFilterList, setIsEmptyFilterList] = useState(false);

  const [updateUser] = useMutation(UPDATE_USER, {
    onError: () => {},
  });

  const [users] = useLazyQuery(USERS, {
    onCompleted: (response) => {
      setEndUserList([...response?.users?.data]);
      if (response?.users?.count === 0 && initialPaginationValue?.total === 0) {
        setIsEmptyEndUserList(true);
      } else {
        setIsEmptyEndUserList(false);
      }
      const pagination = {
        ...paginationProp,
        defaultPageSize: LIMIT,
        total: response?.users?.count,
      };
      setPaginationProp(pagination);
      setIsEndUserLoading(false);
    },
    fetchPolicy: 'network-only',
    onError() {},
  });

  const [libraries, { loading: isLibrariesLoading }] = useLazyQuery(LIBRARIES, {
    onCompleted: (response) => {
      if (response?.libraries?.data?.length < LIMIT) {
        setFilterIsEnd(true);
      }
      if (response?.libraries?.count === 0) {
        setIsEmptyFilterList(true);
      } else {
        setIsEmptyFilterList(false);
      }
      const optionsCopy = [];
      forEach(response?.libraries?.data, (item) =>
        optionsCopy?.push({ label: item?.name, key: item?.id }),
      );
      setFilterList([...filterList, ...optionsCopy]);
      setFilterLoading(false);
    },
    fetchPolicy: 'network-only',
    onError() {
      setFilterLoading(false);
    },
  });

  const hasUpdatePermission = hasPermission(
    PERMISSIONS_KEY?.SYSTEM_USERS_END_USERS,
    PERMISSION_TYPE?.UPDATE,
    permissions,
  );

  useEffect(() => {
    setPaginationProp({ ...paginationProp, current: 1, skip: 0 });
    setIsEndUserLoading(true);
    users({
      variables: {
        filter: {
          ...initialUsersFilter,
          limit: paginationProp?.pageSize || LIMIT,
          search: endUserSearchTerm,
          roles: whereFilter?.role?.length > 0 ? whereFilter?.role : roleFilter,
        },
        sort: initialUsersSort,
      },
    });
  }, []);

  const handleTableChange = (pagination, tableFilter, sorter) => {
    const { current } = pagination;
    const skip = (current - 1) * (pagination?.pageSize || 0);
    setPaginationProp({ ...paginationProp, ...pagination });
    setIsEndUserLoading(true);
    setSortedInfo(sorter);
    users({
      variables: {
        filter: {
          ...initialUsersFilter,
          skip,
          limit: pagination?.pageSize,
          search: endUserSearchTerm,
          roles: roleFilter,
          dateRange: {
            from: finalWhereFilters?.createdAt?.from?.$d,
            to: finalWhereFilters?.createdAt?.to?.$d,
          },
          isActive: finalWhereFilters?.status,
          types:
            finalWhereFilters?.billingCycle?.length > 0
              ? finalWhereFilters?.billingCycle
              : undefined,
          names:
            finalWhereFilters?.planSubscribed?.length > 0
              ? finalWhereFilters?.planSubscribed
              : undefined,
          libraryIds: finalWhereFilters?.name,
        },
        sort: sorter?.column
          ? {
              field: sorter?.field,
              order: sorter?.order === 'ascend' ? SORT?.ASC : SORT?.DESC,
            }
          : initialUsersSort,
      },
    });
  };

  const handleSearch = (value) => {
    const trimValue = value?.trim();
    setEndUserSearchTerm(trimValue);
    setPaginationProp({ ...paginationProp, current: 1, skip: 0 });
    setIsEndUserLoading(true);
    users({
      variables: {
        filter: {
          ...initialUsersFilter,
          limit: paginationProp?.pageSize || LIMIT,
          search: trimValue,
          roles: whereFilter?.role?.length > 0 ? whereFilter?.role : roleFilter,
          dateRange: {
            from: whereFilter?.createdAt?.from?.$d,
            to: whereFilter?.createdAt?.to?.$d,
          },
          isActive: finalWhereFilters?.status,
          types:
            finalWhereFilters?.billingCycle?.length > 0
              ? finalWhereFilters?.billingCycle
              : undefined,
          names:
            finalWhereFilters?.planSubscribed?.length > 0
              ? finalWhereFilters?.planSubscribed
              : undefined,
          libraryIds: finalWhereFilters?.name,
        },
        sort: sortedInfo?.column
          ? {
              field: sortedInfo?.field,
              order: sortedInfo?.order === 'ascend' ? SORT?.ASC : SORT?.DESC,
            }
          : initialUsersSort,
      },
    });
    setExportFilter({ ...exportFilter, search: trimValue });
  };

  const handleActive = async (id, isActive) => {
    setIsActiveLoading(true);
    const response = await updateUser({
      variables: {
        where: {
          id,
        },
        data: {
          isActive: !isActive,
        },
      },
    });
    if (response?.data) {
      setIsActiveLoading(false);
      setIsEndUserLoading(true);
      setIsActivePrompts(false);
      users({
        variables: {
          filter: {
            ...initialUsersFilter,
            skip:
              (paginationProp?.current - 1) * (paginationProp?.pageSize || 0),
            limit: paginationProp?.pageSize || LIMIT,
            search: endUserSearchTerm,
            roles: roleFilter,
            dateRange: {
              from: finalWhereFilters?.createdAt?.from?.$d,
              to: finalWhereFilters?.createdAt?.to?.$d,
            },
            isActive: finalWhereFilters?.status,
            types:
              finalWhereFilters?.billingCycle?.length > 0
                ? finalWhereFilters?.billingCycle
                : undefined,
            names:
              finalWhereFilters?.planSubscribed?.length > 0
                ? finalWhereFilters?.planSubscribed
                : undefined,
            libraryIds: finalWhereFilters?.name,
          },
          sort: sortedInfo?.column
            ? {
                field: sortedInfo?.field,
                order: sortedInfo?.order === 'ascend' ? SORT?.ASC : SORT?.DESC,
              }
            : initialUsersSort,
        },
      });
    }
    setIsActiveLoading(false);
  };

  const getFilterData = (confirm) => {
    setIsEndUserLoading(true);
    users({
      variables: {
        filter: {
          ...initialUsersFilter,
          limit: paginationProp?.pageSize || LIMIT,
          search: endUserSearchTerm,
          roles: roleFilter,
          dateRange: {
            from: whereFilter?.createdAt?.from?.$d,
            to: whereFilter?.createdAt?.to?.$d,
          },
          isActive:
            whereFilter?.status === true || whereFilter?.status === false
              ? whereFilter?.status
              : undefined,
          types:
            whereFilter?.billingCycle?.length > 0
              ? whereFilter?.billingCycle
              : undefined,
          names:
            whereFilter?.planSubscribed?.length > 0
              ? whereFilter?.planSubscribed
              : undefined,
          libraryIds: whereFilter?.name,
        },
        sort: sortedInfo?.column
          ? {
              field: sortedInfo?.field,
              order: sortedInfo?.order === 'ascend' ? SORT?.ASC : SORT?.DESC,
            }
          : initialUsersSort,
      },
    });
    setFinalWhereFilters({ ...finalWhereFilters, ...whereFilter });
    setExportFilter({ ...exportFilter, ...finalWhereFilters, ...whereFilter });
    setFinalFilterTags(filterTags);
    setPaginationProp({ ...paginationProp, skip: 0, current: 1 });
    if (confirm) {
      confirm();
    }
  };

  const handleReset = (clearFilters, dataIndex, confirm) => {
    const tagsCopy = {
      ...filterTags,
      [dataIndex]: [],
    };
    let whereFiltersCopy;
    if (dataIndex === 'status') {
      whereFiltersCopy = {
        ...whereFilter,
        [dataIndex]: null,
      };
    } else {
      whereFiltersCopy = {
        ...whereFilter,
        [dataIndex]: [],
      };
    }
    setFilterVisible(false);
    setFinalFilterTags({ ...filterTags, ...tagsCopy });
    setFinalWhereFilters({ ...finalWhereFilters, ...whereFiltersCopy });
    setExportFilter({
      ...exportFilter,
      ...finalWhereFilters,
      ...whereFiltersCopy,
    });
    setIsEndUserLoading(true);
    users({
      variables: {
        filter: {
          ...initialUsersFilter,
          limit: paginationProp?.pageSize || LIMIT,
          search: endUserSearchTerm,
          roles: roleFilter,
          dateRange: {
            from: whereFiltersCopy?.createdAt?.from?.$d,
            to: whereFiltersCopy?.createdAt?.to?.$d,
          },
          isActive:
            whereFiltersCopy?.status === true ||
            whereFiltersCopy?.status === false
              ? whereFiltersCopy?.status
              : undefined,
          types:
            whereFiltersCopy?.billingCycle?.length > 0
              ? whereFiltersCopy?.billingCycle
              : undefined,
          names:
            whereFiltersCopy?.planSubscribed?.length > 0
              ? whereFiltersCopy?.planSubscribed
              : undefined,
          libraryIds: whereFiltersCopy?.name,
        },
        sort: sortedInfo?.column
          ? {
              field: sortedInfo?.field,
              order: sortedInfo?.order === 'ascend' ? SORT?.ASC : SORT?.DESC,
            }
          : initialUsersSort,
      },
    });
    setPaginationProp({ ...paginationProp, skip: 0, current: 1 });
    clearFilters();
    setFilterVisible(false);
    if (confirm) {
      confirm();
    }
  };

  useEffect(() => {
    if (filterVisible) {
      setFilterList([]);
      setFilterLoading(true);
      setIsEmptyFilterList(false);
      setFilterTags({ ...filterTags, ...finalFilterTags });
      setWhereFilter({ ...whereFilter, ...finalWhereFilters });
      setFilterIsEnd(false);
      const optionsCopy = [];

      switch (filterIndex) {
        case 'name':
          libraries({
            variables: {
              filter: initialTableFilter,
              sort: initialTableSort,
            },
          });
          break;

        case 'status':
          forEach(STATUS, (item) => {
            optionsCopy?.push({ label: item?.label, key: item?.key });
          });
          setFilterList(optionsCopy);
          setFilterLoading(false);
          break;

        case 'billingCycle':
          forEach(PLAN_TYPES_OPTIONS, (item) => {
            optionsCopy?.push({ label: item?.label, key: item?.value });
          });
          setFilterList(optionsCopy);
          setFilterLoading(false);
          break;

        case 'planSubscribed':
          forEach(PLAN_NAMES_OPTIONS, (item) => {
            optionsCopy?.push({ label: item?.label, key: item?.value });
          });
          setFilterList(optionsCopy);
          setFilterLoading(false);
          break;

        default:
          break;
      }
    }
    if (!filterVisible) {
      setFilterTags(finalFilterTags);
      setWhereFilter(finalWhereFilters);
      setFilterSearch('');
    }
  }, [filterVisible]);

  const onFilterScroll = (event, dataIndex) => {
    if (filterScrollDebounceJob) {
      filterScrollDebounceJob?.cancel();
      filterScrollDebounceJob = null;
    }
    if (!isLibrariesLoading && !filterIsEnd) {
      const target = event?.target;
      const currentLength = filterList?.length;
      if (
        target?.scrollTop + target?.offsetHeight >=
        target?.scrollHeight - 5
      ) {
        filterScrollDebounceJob = debounce(() => {
          setFilterLoading(true);
          switch (dataIndex) {
            case 'name':
              libraries({
                variables: {
                  filter: {
                    limit: LIMIT,
                    skip: currentLength,
                    search: filterSearch,
                  },
                  sort: initialTableSort,
                },
              });
              break;

            default:
              break;
          }
        }, 500);
        filterScrollDebounceJob();
      }
    }
  };

  const handleFilterSearch = (value, dataIndex) => {
    setFilterSearch(value);
    setFilterIsEnd(false);
    setFilterLoading(true);
    setFilterList([]);
    switch (dataIndex) {
      case 'name':
        libraries({
          variables: {
            filter: {
              ...initialTableFilter,
              limit: LIMIT,
              search: value,
            },
            sort: initialTableSort,
          },
        });
        break;

      default:
        break;
    }
  };

  const changeFilter = (newFilterObj, dataIndex) => {
    const {
      target: { value },
    } = newFilterObj;

    const filterTagsCopy = { ...filterTags };
    let newTagList;
    if (whereFilter?.[dataIndex]?.includes(value?.key)) {
      newTagList = {
        ...filterTagsCopy,
        [dataIndex]: filter(filterTagsCopy?.[dataIndex], (item) => {
          if (item?.label) {
            return item?.key !== value?.key;
          }
          return item !== value;
        }),
      };
    } else {
      newTagList = {
        ...filterTagsCopy,
        [dataIndex]: filterTagsCopy?.[dataIndex]
          ? [...filterTagsCopy?.[dataIndex], value]
          : [value],
      };
    }

    const updatedWhereFilter = {
      ...whereFilter,
      [dataIndex]: newTagList?.[dataIndex]?.map((item) => item?.key),
    };
    setWhereFilter(updatedWhereFilter);
    setFilterTags(newTagList);
  };

  const handleDeleteFilter = (value, dataIndex) => {
    let newTagList;

    if (whereFilter?.[dataIndex]?.includes(value?.key)) {
      newTagList = {
        ...filterTags,
        [dataIndex]: filter(filterTags?.[dataIndex], (item) => {
          if (item?.key) {
            return item?.key !== value?.key;
          }
          return item !== value;
        }),
      };
    }

    const updatedWhereFilter = {
      ...whereFilter,
      [dataIndex]: newTagList?.[dataIndex]?.map((item) => item?.key),
    };
    setWhereFilter(updatedWhereFilter);
    setFilterTags(newTagList);
  };

  const onRangePickerChange = (values, dataIndex) => {
    const rangeObj = {
      from: values[0]?.startOf('day'),
      to: values?.[1]?.endOf('day'),
    };

    const filtersCopy = {
      ...whereFilter,
      [dataIndex]: rangeObj,
    };
    if (!values?.length) {
      delete whereFilter?.[dataIndex];
    }
    setWhereFilter(filtersCopy);
  };

  const onRadioChange = (value, dataIndex) => {
    const filtersCopy = {
      ...whereFilter,
      [dataIndex]: value,
    };
    if (!value?.length) {
      delete whereFilter?.[dataIndex];
    }
    setWhereFilter(filtersCopy);
  };

  const filterPopup = (dataIndex, showSearch = true) => ({
    filterDropdown: ({ confirm, clearFilters }) => {
      if (dataIndex === 'createdAt') {
        return (
          <div className="custom-filter-dropdown">
            <RangePicker
              allowClear={false}
              format={DATE_FORMATS?.REGULAR}
              onChange={(values) => onRangePickerChange(values, dataIndex)}
              value={
                whereFilter?.[dataIndex]?.from
                  ? [
                      whereFilter?.[dataIndex]?.from,
                      whereFilter?.[dataIndex]?.to,
                    ]
                  : null
              }
            />
            <Divider className="divider-filter" />
            <div className="d-flex justify-center">
              <Button
                size="small"
                className="reset-button"
                id="roles-filter-reset"
                onClick={() => handleReset(clearFilters, dataIndex, confirm)}
              >
                Reset
              </Button>
              <Button
                size="small"
                className="common-button ok-button"
                id="roles-filter-ok"
                type="primary"
                onClick={() => getFilterData(confirm, dataIndex)}
              >
                Ok
              </Button>
            </div>
          </div>
        );
      }

      if (dataIndex === 'status') {
        return (
          <div className="custom-filter-dropdown">
            <Group
              options={USER_FORM_STATUS}
              value={whereFilter?.[dataIndex]}
              onChange={(e) => onRadioChange(e?.target?.value, dataIndex)}
            />
            <Divider className="divider-filter" />
            <div className="d-flex justify-center">
              <Button
                size="small"
                className="reset-button"
                id="roles-filter-reset"
                onClick={() => handleReset(clearFilters, dataIndex, confirm)}
              >
                Reset
              </Button>
              <Button
                size="small"
                className="common-button ok-button"
                id="roles-filter-ok"
                type="primary"
                onClick={() => getFilterData(confirm, dataIndex)}
              >
                Ok
              </Button>
            </div>
          </div>
        );
      }

      return (
        <div className="custom-filter-dropdown">
          <LoaderComponent
            spinning={filterLoading}
            setHeight={35}
            size="medium"
          >
            {showSearch && (
              <SearchComponent
                className="filter-search"
                id="search-container-id-roles"
                placeholder="Search..."
                name={dataIndex}
                query={filterSearch}
                setQuery={setFilterSearch}
                getData={(value) => handleFilterSearch(value, dataIndex)}
              />
            )}
            {filterTags?.[dataIndex]?.length > 0 && (
              <div className="filter-tags-wrapper">
                {map(filterTags?.[dataIndex], (item) => (
                  <Tag
                    key={item?.key}
                    closable
                    onClose={() => handleDeleteFilter(item, dataIndex)}
                    className="filter-tag"
                  >
                    <span>{item?.label}</span>
                  </Tag>
                ))}
              </div>
            )}
            <div
              className="filter-checkboxes"
              onScroll={(e) => onFilterScroll(e, dataIndex)}
            >
              {filterList?.length > 0
                ? map(filterList, (item) => (
                    <div className="single-checkbox-div" key={item?.key}>
                      <Checkbox
                        value={item}
                        checked={whereFilter?.[dataIndex]?.includes(item?.key)}
                        key={item?.key || item}
                        onChange={(e) => changeFilter(e, dataIndex)}
                        className="single-checkbox"
                      >
                        <span>{item?.label}</span>
                      </Checkbox>
                    </div>
                  ))
                : isEmptyFilterList && (
                    <Empty image={Empty?.PRESENTED_IMAGE_SIMPLE} />
                  )}
            </div>
          </LoaderComponent>
          <Divider className="divider-filter" />
          <div className="d-flex justify-center">
            <Button
              size="small"
              className="reset-button"
              id="roles-filter-reset"
              onClick={() => handleReset(clearFilters, dataIndex, confirm)}
            >
              Reset
            </Button>
            <Button
              size="small"
              className="common-button ok-button"
              id="roles-filter-ok"
              type="primary"
              onClick={() => getFilterData(confirm, dataIndex)}
            >
              Ok
            </Button>
          </div>
        </div>
      );
    },
    filterIcon: () =>
      finalWhereFilters?.[dataIndex]?.length > 0 ||
      !isEmpty(finalWhereFilters?.[dataIndex]) ||
      finalWhereFilters?.[dataIndex] === true ||
      finalWhereFilters?.[dataIndex] === false ? (
        <FilterFilled className="filter-icon filter-filled" />
      ) : (
        <FilterOutlined className="filter-icon" />
      ),
    onFilterDropdownOpenChange: (visible) => {
      setFilterIndex(dataIndex);
      setFilterVisible(visible);
    },
  });

  const columns = [
    {
      title: 'USER NAME',
      dataIndex: 'firstName',
      key: 'firstName',
      ellipsis: true,
      sorter: true,
      width: 250,
      align: 'left',
      // eslint-disable-next-line no-undef
      fixed: window.innerWidth > BREAKPOINTS.desktop ? 'left' : false,
      className: 'max-width-column',
      sortOrder: sortedInfo?.columnKey === 'firstName' && sortedInfo?.order,
      render: (_, record) => (
        <>
          {record?.firstName || record?.lastName ? (
            <div className="table-data-with-id">{`${
              record?.firstName ? record?.firstName : ''
            } ${record?.lastName ? record?.lastName : ''}`}</div>
          ) : (
            '-'
          )}
          {record?.userNumber ? (
            <div className="font-size-12">({record?.userNumber})</div>
          ) : (
            ''
          )}
        </>
      ),
    },
    {
      title: 'EMAIL',
      dataIndex: 'email',
      key: 'email',
      ellipsis: true,
      width: 250,
      sorter: true,
      align: 'left',
      className: 'max-width-column',
      sortOrder: sortedInfo?.columnKey === 'email' && sortedInfo?.order,
      render: (_, record) => record?.email || '-',
    },
    {
      title: 'CONTACT',
      dataIndex: 'contactNo',
      key: 'contactNo',
      ellipsis: true,
      width: 250,
      align: 'left',
      className: 'max-width-column',
      render: (_, record) => record?.contactNo || '-',
    },
    {
      title: 'BILLING CYCLE',
      dataIndex: 'billingCycle',
      key: 'billingCycle',
      ellipsis: true,
      width: 150,
      align: 'left',
      className: 'max-width-column',
      render: (_, record) =>
        PLAN_TYPES?.[record?.subscription?.subscriptionPlan?.type]?.key || '-',
      ...filterPopup('billingCycle', false),
    },
    {
      title: 'PLAN SUBSCRIBED',
      dataIndex: 'planSubscribed',
      key: 'planSubscribed',
      ellipsis: true,
      width: 150,
      align: 'left',
      className: 'max-width-column',
      render: (_, record) =>
        PLAN_NAMES?.[record?.subscription?.subscriptionPlan?.name]?.key || '-',
      ...filterPopup('planSubscribed', false),
    },
    {
      title: 'MAPPED LIBRARY NAME',
      dataIndex: 'mappedLibraryName',
      key: 'mappedLibraryName',
      ellipsis: true,
      width: 250,
      align: 'left',
      className: 'max-width-column',
      onCell: (record) => ({
        onClick: (e) => {
          e?.stopPropagation();
          if (isLibraryPermission && record?.pincode?.library?.id) {
            history?.push(
              `${ROUTES?.MASTER_LISTS}${ROUTES?.LIBRARY}${ROUTES?.BASIC_DETAILS}/view/${record?.pincode?.library?.id}/${record?.pincode?.library?.libraryNumber}`,
            );
          }
        },
        className: `${
          isLibraryPermission &&
          record?.pincode?.library?.id &&
          'pointer table-cell'
        }`,
      }),
      render: (_, record) => (
        <>
          <div className="table-data-with-id">
            {record?.pincode?.library?.name}
          </div>
          <div className="font-size-12">
            ({record?.pincode?.library?.libraryNumber})
          </div>
        </>
      ),
      ...filterPopup('name'),
    },
    {
      title: 'USER SINCE',
      dataIndex: 'createdAt',
      key: 'createdAt',
      width: 150,
      ellipsis: true,
      align: 'left',
      className: 'max-width-column',
      render: (_, record) =>
        formatDate(record?.createdAt, DATE_FORMATS?.REGULAR) || '-',
      ...filterPopup('createdAt'),
    },
    {
      title: 'ACCOUNT DETAILS',
      dataIndex: 'accountDetails',
      key: 'accountDetails',
      ellipsis: true,
      width: 250,
      align: 'left',
      className: 'max-width-column table-tooltip-cell',
      onCell: () => ({
        onClick: (e) => e?.stopPropagation(),
      }),
      render: (_, record) => {
        const bankAccounts = record?.bankAccounts?.filter(
          (account) => account?.isDefault,
        );
        const allBankAccounts = bankAccounts?.map((bankAccount) => {
          if (bankAccount?.type === PAYMENT_TYPE?.ACCOUNT) {
            return `(Name. ${bankAccount?.holderName}) (No. ${bankAccount?.accountNumber}) (IFSC: ${bankAccount?.ifsc})`;
          }
          return ` (UPI ID: ${bankAccount.upiId}) `;
        });
        return (
          <>
            {bankAccounts?.length > 0 ? (
              <Tooltip
                title={allBankAccounts}
                placement="topLeft"
                trigger="click"
                className="pointer"
              >
                {allBankAccounts}
              </Tooltip>
            ) : (
              '-'
            )}
          </>
        );
      },
    },
    {
      title: 'LAT',
      dataIndex: 'lat',
      key: 'lat',
      width: 150,
      ellipsis: true,
      align: 'left',
      className: 'max-width-column',
      render: (_, record) => record?.latLong?.coordinates?.[0] || '-',
    },
    {
      title: 'LNG',
      dataIndex: 'lng',
      key: 'lng',
      width: 150,
      ellipsis: true,
      align: 'left',
      className: 'max-width-column',
      render: (_, record) => record?.latLong?.coordinates?.[1] || '-',
    },
    {
      title: 'STATUS',
      dataIndex: 'status',
      key: 'status',
      ellipsis: true,
      width: 150,
      align: 'left',
      className: 'max-width-column',
      render: (_, record) => (
        <Badge
          color={
            record?.isActive ? STATUS?.ACTIVE?.color : STATUS?.INACTIVE?.color
          }
          text={
            record?.isActive ? STATUS?.ACTIVE?.label : STATUS?.INACTIVE?.label
          }
        />
      ),
      ...filterPopup('status'),
    },
    {
      title: 'ACTIONS',
      dataIndex: 'actions',
      key: 'actions',
      ellipsis: true,
      width: 100,
      // eslint-disable-next-line no-undef
      fixed: window.innerWidth > BREAKPOINTS.desktop ? 'right' : false,
      className: 'cursor-auto',
      onCell: () => ({
        onClick: (e) => e?.stopPropagation(),
      }),
      render: (_, record) => (
        <>
          <div className="action-button">
            <>
              <Tooltip
                title={record?.isActive ? 'Active' : 'Inactive'}
                placement="top"
                zIndex={4}
              >
                <ActiveIcon
                  onClick={(e) => {
                    e?.stopPropagation();
                    setIsActivePrompts(true);
                    setEndUser(record);
                  }}
                  className={
                    record?.isActive
                      ? 'mr-16 pointer svg-icon'
                      : 'mr-16 pointer svg-icon-grey'
                  }
                />
              </Tooltip>
              <Tooltip title="Edit" placement="top" zIndex={4}>
                <EditIcon
                  onClick={(e) => {
                    e?.stopPropagation();
                    history?.push(
                      `${ROUTES?.SYSTEM_USERS}${ROUTES?.END_USERS}${ROUTES?.BASIC_DETAILS}/edit/${record?.id}/${record?.userNumber}`,
                    );
                  }}
                  className="mr-16 pointer svg-icon"
                />
              </Tooltip>
            </>
          </div>
        </>
      ),
    },
  ]?.filter((item) => {
    if (item?.dataIndex === 'actions' && !hasUpdatePermission) {
      return item?.hidden;
    }
    return !item?.hidden;
  });

  const locale = {
    emptyText: isEmptyEndUserList ? '' : <span />,
  };

  return (
    <>
      <Modal
        title="Caution"
        okText="Yes"
        cancelText="No"
        open={isActivePrompts}
        onOk={() => handleActive(endUser?.id, endUser?.isActive)}
        onCancel={() => setIsActivePrompts(false)}
        okButtonProps={{ loading: isActiveLoading }}
      >
        Are you sure you want to
        <strong>
          {' '}
          {!endUser?.isActive
            ? STATUS?.ACTIVE?.label
            : STATUS?.INACTIVE?.label}{' '}
        </strong>
        this end user?
      </Modal>
      <div className="d-flex justify-end mb-16 flex-wrap">
        <SearchComponent
          className="search-box"
          query={query}
          setQuery={setQuery}
          getData={handleSearch}
        />
      </div>
      <CommonTable
        locale={locale}
        columns={columns}
        data={endUserList || []}
        loading={isEndUserLoading}
        onChange={handleTableChange}
        paginationConfig={paginationProp}
        rowKey={(record) => record?.id}
        rowClassName="pointer table-cell"
        onRow={(record) => ({
          onClick: () => {
            history?.push(
              `${ROUTES?.SYSTEM_USERS}${ROUTES?.END_USERS}${ROUTES?.BASIC_DETAILS}/view/${record?.id}/${record?.userNumber}`,
            );
          },
        })}
      />
    </>
  );
};

export default EndUsersTable;
