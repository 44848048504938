import { useLazyQuery, useMutation } from '@apollo/client';
import { Button, Card, Form } from 'antd';
import React, { useContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { AppContext } from '../../../../../AppContext';
import { DATE_FORMATS, ROUTES } from '../../../../../common/constants';
import { formatDate } from '../../../../../common/utils';
import GoBackButton from '../../../../../components/GoBackButton';
import LoaderComponent from '../../../../../components/LoaderComponent';
import Portal from '../../../../../components/Portal';
import { UPDATE_USER } from '../../../graphql/Mutations';
import { USER } from '../../../graphql/Queries';
import AdminForm from '../components/AdminForm';

const EditAdmin = (props) => {
  const { match: { params: { id } = {} } = {} } = props;

  const history = useHistory();
  const [form] = Form.useForm();
  const { dispatch } = useContext(AppContext);
  const [adminDetail, setAdminDetail] = useState({});
  const [isDisabled, setIsDisabled] = useState(true);
  const [isSubmitLoading, setIsSubmitLoading] = useState(false);
  const [isAdminLoading, setIsAdminLoading] = useState(true);

  const [updateUser] = useMutation(UPDATE_USER, {
    onError: () => {},
  });

  const [users] = useLazyQuery(USER, {
    onCompleted: (response) => {
      setAdminDetail(response?.user);
      setIsAdminLoading(false);
    },
    fetchPolicy: 'network-only',
    onError() {},
  });

  useEffect(() => {
    setIsAdminLoading(true);
    setAdminDetail([]);
    users({
      variables: {
        where: {
          id,
        },
      },
    });
  }, []);

  const updateAdminFun = async (adminDetails) => {
    setIsSubmitLoading(true);
    dispatch({
      type: 'SET_SHOW_PROMPT',
      data: false,
    });
    const response = await updateUser({
      variables: {
        where: {
          id,
        },
        data: {
          firstName: adminDetails?.firstName?.trim(),
          lastName: adminDetails?.lastName?.trim(),
          gender: adminDetails?.gender,
          city: adminDetails?.city,
          state: adminDetails?.state,
          roles: [adminDetails?.role],
          contactNo: adminDetails?.contact?.trim(),
          address: adminDetails?.address?.trim(),
          email: adminDetails?.email?.trim(),
          pincode: adminDetails?.pincode,
          isActive: adminDetails?.status,
        },
      },
    });
    if (response?.data) {
      history?.replace(`${ROUTES?.SYSTEM_USERS}${ROUTES?.ADMINS}`);
    }
    setIsSubmitLoading(false);
  };

  const handleShowPrompt = () => {
    setIsDisabled(false);
    dispatch({
      type: 'SET_SHOW_PROMPT',
      data: true,
    });
  };

  const initialValues = {
    ...adminDetail,
    contact: adminDetail?.contactNo,
    role: adminDetail?.roles?.[0],
    status: adminDetail?.isActive,
    state: adminDetail?.state,
    city: adminDetail?.city,
    pincode: adminDetail?.pincode?.pincode,
    isActive: adminDetail?.status,
  };

  return (
    <>
      <Portal portalId="header-title-content">
        <GoBackButton customLink={ROUTES?.SYSTEM_USERS} />
        <div className="portal-header">Edit Admin</div>
      </Portal>
      <Portal portalId="header-right-content">
        <div className="header-btn-wrapper">
          <Button
            type="primary"
            className="common-button mr-16"
            size="middle"
            onClick={() =>
              history?.push(`${ROUTES?.SYSTEM_USERS}${ROUTES?.ADMINS}`)
            }
          >
            Discard Changes
          </Button>
          <Button
            className="common-button"
            size="middle"
            onClick={form.submit}
            loading={isSubmitLoading}
            disabled={isDisabled}
          >
            Save
          </Button>
        </div>
      </Portal>
      <Portal portalId="header-right-content-phones">
        <div className="header-btn-wrapper">
          <Button
            type="primary"
            className="common-button mr-16"
            size="middle"
            onClick={() =>
              history?.push(`${ROUTES?.SYSTEM_USERS}${ROUTES?.ADMINS}`)
            }
          >
            Discard Changes
          </Button>
          <Button
            className="common-button"
            size="middle"
            onClick={form.submit}
            loading={isSubmitLoading}
            disabled={isDisabled}
          >
            Save
          </Button>
        </div>
      </Portal>
      <Card
        className="full-height-with-nav"
        title="Admin Details"
        extra={
          <strong>
            Onboarded On :{' '}
            {formatDate(adminDetail?.createdAt, DATE_FORMATS?.ONBOARD)}
          </strong>
        }
      >
        {isAdminLoading ? (
          <LoaderComponent
            size="large"
            setHeight="60"
            spinning={isAdminLoading}
          />
        ) : (
          <div className="admin-form">
            <AdminForm
              form={form}
              onFinish={updateAdminFun}
              handleShowPrompt={handleShowPrompt}
              initialValues={initialValues}
              adminDetail={adminDetail}
            />
          </div>
        )}
      </Card>
    </>
  );
};

export default EditAdmin;
