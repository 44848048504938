import { CaretLeftOutlined } from '@ant-design/icons';
import React from 'react';
import { useHistory } from 'react-router-dom';

const GoBackButton = ({ customLink = '' }) => {
  const history = useHistory();
  return (
    <div className="mr-16 back-button-wrapper">
      <CaretLeftOutlined
        onClick={() =>
          history?.length > 2 ? history?.goBack() : history?.push(customLink)
        }
      />
    </div>
  );
};

export default GoBackButton;
