import { useLazyQuery } from '@apollo/client';
import { Empty, Select } from 'antd';
import { debounce } from 'lodash';
import React, { useEffect, useState } from 'react';
import { LIMIT, SORT } from '../../../../../common/constants';
import SelectComponent from '../../../../../components/SelectComponent';
import { TOYS_FOR_LIBRARY_INVENTORY } from '../graphql/Queries';

const { Option } = Select;

const ToySelect = (props) => {
  const { libraryId, ...rest } = props;
  const [toyList, setToysList] = useState([]);
  const [toysSearchTerm, setToysSearchTerm] = useState('');
  const [isAllToysFetched, setIsAllToysFetched] = useState(false);
  const [isToysAllowClear, setIsToysAllowClear] = useState(false);
  const [isToysDropdownVisible, setIsToysDropdownVisible] = useState(false);
  const [isFetchMoreToysLoading, setIsFetchMoreToysLoading] = useState(false);
  const [isToysSelected, setIsToysSelected] = useState(false);

  const [toys, { loading: isToysLoading }] = useLazyQuery(
    TOYS_FOR_LIBRARY_INVENTORY,
    {
      onCompleted: (response) => {
        if (response?.toysForLibraryInventory?.data?.length < LIMIT) {
          setIsAllToysFetched(true);
        }
        setIsFetchMoreToysLoading(false);
        setToysList([...toyList, ...response?.toysForLibraryInventory?.data]);
        setIsToysSelected(false);
      },
      fetchPolicy: 'network-only',
      onError() {},
    },
  );

  useEffect(() => {
    if (!isToysDropdownVisible) {
      setToysSearchTerm('');
      setToysList([]);
      toys({
        variables: {
          where: {
            id: libraryId,
          },
          filter: {
            limit: LIMIT,
            skip: 0,
            search: toysSearchTerm,
          },
          sort: {
            field: 'createdAt',
            order: SORT?.DESC,
          },
        },
      });
    }
    setIsAllToysFetched(false);
  }, [isToysDropdownVisible]);

  useEffect(() => {
    if (isToysSelected && isToysDropdownVisible && toysSearchTerm) {
      setToysSearchTerm('');
      setToysList([]);
      toys({
        variables: {
          where: {
            id: libraryId,
          },
          filter: {
            limit: LIMIT,
            skip: 0,
          },
          sort: {
            field: 'createdAt',
            order: SORT?.DESC,
          },
        },
      });
    }
    setIsAllToysFetched(false);
  }, [isToysSelected, isToysDropdownVisible, toysSearchTerm]);

  const handlePincodesScroll = (event) => {
    if (!isAllToysFetched && !isToysLoading) {
      const target = event?.target;
      const currentLength = toyList?.length;
      if (
        target?.scrollTop + target?.offsetHeight >=
        target?.scrollHeight - 5
      ) {
        setIsFetchMoreToysLoading(true);
        toys({
          variables: {
            where: {
              id: libraryId,
            },
            filter: {
              limit: LIMIT,
              skip: currentLength,
              search: toysSearchTerm,
            },
            sort: {
              field: 'createdAt',
              order: SORT?.DESC,
            },
          },
        });
      }
    }
  };

  const handlePincodesSearch = (searchTerm) => {
    const trimSearch = searchTerm?.trim();
    setIsAllToysFetched(false);
    setToysList([]);
    setToysSearchTerm(trimSearch);
    toys({
      variables: {
        where: {
          id: libraryId,
        },
        filter: {
          limit: LIMIT,
          skip: 0,
          search: trimSearch,
        },
        sort: {
          field: 'createdAt',
          order: SORT?.DESC,
        },
      },
    });
  };

  const handlePincodesScrollDebounce = debounce(handlePincodesScroll, 500);
  const handleSearchPincodesDebounce = debounce(handlePincodesSearch, 500);

  useEffect(() => {
    handlePincodesScrollDebounce?.cancel();
  }, [handlePincodesScrollDebounce]);

  useEffect(() => {
    handleSearchPincodesDebounce?.cancel();
  }, [handleSearchPincodesDebounce]);

  useEffect(() => {
    if (isToysLoading) {
      setIsToysAllowClear(false);
    } else {
      setIsToysAllowClear(true);
    }
  }, [isToysLoading]);

  return (
    <SelectComponent
      mode="multiple"
      placeholder="Select Toys"
      onDropdownVisibleChange={(e) => setIsToysDropdownVisible(e)}
      onPopupScroll={handlePincodesScrollDebounce}
      onSearch={handleSearchPincodesDebounce}
      onChange={() => setIsToysAllowClear(true)}
      loading={isFetchMoreToysLoading}
      allowClear={isToysAllowClear}
      filterOption={false}
      notFoundContent={
        !isFetchMoreToysLoading && isToysLoading ? (
          <span>Loading...</span>
        ) : (
          <Empty image={Empty?.PRESENTED_IMAGE_SIMPLE} />
        )
      }
      onSelect={() => setIsToysSelected(true)}
      maxTagCount={false}
      {...rest}
    >
      {toyList?.map((toy) => (
        <Option key={toy?.id} value={toy?.id}>
          {toy?.toyNumber} - {toy?.name}
        </Option>
      ))}
    </SelectComponent>
  );
};

export default ToySelect;
