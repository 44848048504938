import React from 'react';
import { Route, Switch } from 'react-router-dom';
import AccessControl from '../../../AccessControl';
import { PERMISSIONS_KEY, ROUTES } from '../../../common/constants';
import Error404 from '../../../Error404';
import Story from './pages/Story';
import ViewStory from './pages/ViewStory';

const StorysWrapper = () => (
  <Switch>
    <Route
      exact
      path={`${ROUTES?.APP_CONTENT}${ROUTES?.STORY}`}
      render={(props) => (
        <AccessControl permissionKey={PERMISSIONS_KEY?.APP_CONTENT_STORY}>
          <Story {...props} />
        </AccessControl>
      )}
    />
    <Route
      exact
      path={`${ROUTES?.APP_CONTENT}${ROUTES?.STORY}/view/:id`}
      render={(props) => (
        <AccessControl permissionKey={PERMISSIONS_KEY?.APP_CONTENT_STORY}>
          <ViewStory {...props} />
        </AccessControl>
      )}
    />
    <Route path="*" exact component={Error404} />
  </Switch>
);

export default StorysWrapper;
