import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import { ROUTES } from '../../../../common/constants';
import Error404 from '../../../../Error404';
import './all.less';
import AllDelivery from './pages/AllDelivery';
import AllReturn from './pages/AllReturn';

const AllOrdersLibraryWrapper = () => (
  <Switch>
    <Route
      exact
      path={`${ROUTES?.ORDERS}${ROUTES?.ALL}`}
      render={() => (
        <Redirect to={`${ROUTES?.ORDERS}${ROUTES?.ALL}${ROUTES?.DELIVERY}`} />
      )}
    />
    <Route
      exact
      path={`${ROUTES?.ORDERS}${ROUTES?.ALL}${ROUTES?.DELIVERY}`}
      render={(props) => <AllDelivery {...props} />}
    />
    <Route
      exact
      path={`${ROUTES?.ORDERS}${ROUTES?.ALL}${ROUTES?.RETURN}`}
      render={(props) => <AllReturn {...props} />}
    />
    <Route path="*" exact component={Error404} />
  </Switch>
);

export default AllOrdersLibraryWrapper;
