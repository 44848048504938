import { useLazyQuery } from '@apollo/client';
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import {
  BREAKPOINTS,
  DATE_FORMATS,
  LIMIT,
  ROUTES,
  SORT,
} from '../../../common/constants';
import { formatDate } from '../../../common/utils';
import CommonTable from '../../../components/CommonTable';
import SearchComponent from '../../../components/SearchComponent';
import { RACKS } from '../graphql/Queries';

const initialPaginationValue = {
  total: 0,
  current: 1,
  pageSize: 10,
};

const initialFilter = {
  limit: LIMIT,
  search: '',
  skip: 0,
};

const initialSort = {
  field: 'name',
  order: 'ASC',
};

const RackMasterTable = () => {
  const history = useHistory();
  const [rackMasterList, setRackMasterList] = useState([]);
  const [rackMasterSearchTerm, setRackMasterSearchTerm] = useState('');
  const [isEmptyRackMasterList, setIsEmptyRackMasterList] = useState(false);
  const [query, setQuery] = useState('');
  const [sortedInfo, setSortedInfo] = useState({});
  const [paginationProp, setPaginationProp] = useState(initialPaginationValue);
  const [isRackMasterLoading, setIsRackMasterLoading] = useState(true);

  const [racks] = useLazyQuery(RACKS, {
    onCompleted: (response) => {
      setRackMasterList([...response?.racks?.data]);
      if (response?.racks?.count === 0 && initialPaginationValue?.total === 0) {
        setIsEmptyRackMasterList(true);
      } else {
        setIsEmptyRackMasterList(false);
      }
      const pagination = {
        ...paginationProp,
        defaultPageSize: LIMIT,
        total: response?.racks?.count,
      };
      setPaginationProp(pagination);
      setIsRackMasterLoading(false);
    },
    fetchPolicy: 'network-only',
    onError() {},
  });

  useEffect(() => {
    setPaginationProp({ ...paginationProp, current: 1, skip: 0 });
    setIsRackMasterLoading(true);
    racks({
      variables: {
        filter: {
          ...initialFilter,
          limit: paginationProp?.pageSize || LIMIT,
          search: rackMasterSearchTerm,
          isArchived: false,
        },
        sort: sortedInfo?.column
          ? {
              field: sortedInfo?.field,
              order: sortedInfo?.order === 'ascend' ? SORT?.ASC : SORT?.DESC,
            }
          : initialSort,
      },
    });
  }, []);

  const handleTableChange = (pagination, tableFilter, sorter) => {
    const { current } = pagination;
    const skip = (current - 1) * (pagination?.pageSize || 0);
    setPaginationProp({ ...paginationProp, ...pagination });
    setIsRackMasterLoading(true);
    setSortedInfo(sorter);
    racks({
      variables: {
        filter: {
          ...initialFilter,
          skip,
          limit: pagination?.pageSize,
          search: rackMasterSearchTerm,
          isArchived: false,
        },
        sort: sorter?.column
          ? {
              field: sorter?.field,
              order: sorter?.order === 'ascend' ? SORT?.ASC : SORT?.DESC,
            }
          : initialSort,
      },
    });
  };

  const handleSearch = (value) => {
    const trimValue = value?.trim();
    setRackMasterSearchTerm(trimValue);
    setPaginationProp({ ...paginationProp, current: 1, skip: 0 });
    setIsRackMasterLoading(true);
    racks({
      variables: {
        filter: {
          ...initialFilter,
          limit: paginationProp?.pageSize || LIMIT,
          search: trimValue,
          isArchived: false,
        },
        sort: sortedInfo?.column
          ? {
              field: sortedInfo?.field,
              order: sortedInfo?.order === 'ascend' ? SORT?.ASC : SORT?.DESC,
            }
          : initialSort,
      },
    });
  };

  const columns = [
    {
      title: 'RACK NAME',
      dataIndex: 'name',
      key: 'name',
      ellipsis: true,
      sorter: true,
      width: 250,
      align: 'left',
      // eslint-disable-next-line no-undef
      fixed: window.innerWidth > BREAKPOINTS.desktop ? 'left' : false,
      className: 'max-width-column',
      sortOrder: sortedInfo?.columnKey === 'name' && sortedInfo?.order,
      render: (_, record) => record?.name || '-',
    },
    {
      title: 'CREATED AT',
      dataIndex: 'createdAt',
      key: 'createdAt',
      width: 250,
      ellipsis: true,
      align: 'left',
      className: 'max-width-column',
      render: (_, record) =>
        formatDate(record?.createdAt, DATE_FORMATS?.REGULAR) || '-',
    },
  ];

  const locale = {
    emptyText: isEmptyRackMasterList ? '' : <span />,
  };

  return (
    <>
      <div className="d-flex justify-end mb-16 flex-wrap">
        <SearchComponent
          className="search-box"
          query={query}
          setQuery={setQuery}
          getData={handleSearch}
        />
      </div>
      <CommonTable
        locale={locale}
        columns={columns}
        data={rackMasterList || []}
        loading={isRackMasterLoading}
        onChange={handleTableChange}
        paginationConfig={paginationProp}
        rowKey={(record) => record?.id}
        onRow={(record) => ({
          onClick: () => {
            history?.push(`${ROUTES?.RACK_MASTER}/view/${record?.id}`);
          },
        })}
      />
    </>
  );
};

export default RackMasterTable;
