import React from 'react';

function LogoTextComponent(props) {
  const { className, height } = props;
  return (
    <img
      alt="logo"
      className={className}
      src="/theEleFant.png"
      height={height}
    />
  );
}
export default LogoTextComponent;
