import React, { useContext } from 'react';
import { Route, Switch } from 'react-router-dom';
import AccessControl from '../../../../AccessControl';
import { MENU_ITEMS } from '../../../../app/components/sidebar/Sidebar';
import { AppContext } from '../../../../AppContext';
import { MODULES, PERMISSIONS_KEY, ROUTES } from '../../../../common/constants';
import { getRedirectSubmenuItem } from '../../../../common/utils';
import Error404 from '../../../../Error404';
import RedirectControl from '../../../../RedirectControl';
import './endUser.less';
import Inflow from './pages/Inflow';
import Outflow from './pages/Outflow';
import Referral from './pages/Referral';

const tabs = MENU_ITEMS?.find(
  (menuItem) => menuItem?.key === MODULES?.PAYMENTS,
)?.subMenu?.find((menu) => menu?.key === MODULES?.END_USER)?.tabKeys;

const PaymentEndUserWrapper = () => {
  const {
    state: { permissions },
  } = useContext(AppContext);

  const redirectArray = getRedirectSubmenuItem(permissions, tabs);

  return (
    <Switch>
      <Route
        exact
        path={`${ROUTES?.PAYMENTS}${ROUTES?.END_USER}`}
        render={() => (
          <RedirectControl
            redirectArray={redirectArray}
            route={`${ROUTES?.PAYMENTS}${ROUTES?.END_USER}`}
          />
        )}
      />
      <Route
        exact
        path={`${ROUTES?.PAYMENTS}${ROUTES?.END_USER}${ROUTES?.INFLOW}`}
        render={(props) => (
          <AccessControl
            permissionKey={PERMISSIONS_KEY?.PAYMENTS_END_USER_INFLOW}
          >
            <Inflow {...props} />
          </AccessControl>
        )}
      />
      <Route
        exact
        path={`${ROUTES?.PAYMENTS}${ROUTES?.END_USER}${ROUTES?.OUTFLOW}`}
        render={(props) => (
          <AccessControl
            permissionKey={PERMISSIONS_KEY?.PAYMENTS_END_USER_OUTFLOW}
          >
            <Outflow {...props} />
          </AccessControl>
        )}
      />
      <Route
        exact
        path={`${ROUTES?.PAYMENTS}${ROUTES?.END_USER}${ROUTES?.REFERRAL}`}
        render={(props) => (
          <AccessControl
            permissionKey={PERMISSIONS_KEY?.PAYMENTS_END_USER_REFERRAL}
          >
            <Referral {...props} />
          </AccessControl>
        )}
      />
      <Route path="*" exact component={Error404} />
    </Switch>
  );
};

export default PaymentEndUserWrapper;
