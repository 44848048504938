import { ExportOutlined } from '@ant-design/icons';
import { useMutation } from '@apollo/client';
import { Button, Form } from 'antd';
import React, { useContext, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { AppContext } from '../../../../../AppContext';
import {
  EXPORT_TYPES,
  PERMISSIONS_KEY,
  PERMISSION_TYPE,
  ROUTES,
} from '../../../../../common/constants';
import { hasPermission } from '../../../../../common/utils';
import ExportDateRangeModal from '../../../../../components/ExportDateRangeModal';
import GoBackButton from '../../../../../components/GoBackButton';
import Portal from '../../../../../components/Portal';
import InventoryTable from '../components/InventoryTable';
import LibraryTabs from '../components/LibraryTabs';
import { REQUEST_LIBRARY_INVENTORY_EXPORT_ADMIN } from '../graphql/Mutations';

const ViewInventoryDetails = ({
  match: { params: { id, libraryNumber } = {} } = {},
}) => {
  const {
    state: { permissions },
  } = useContext(AppContext);
  const history = useHistory();
  const [form] = Form.useForm();
  const [exportFilter, setExportFilter] = useState();
  const [isExportLoading, setIsExportLoading] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const [requestLibraryInventoryExportAdmin] = useMutation(
    REQUEST_LIBRARY_INVENTORY_EXPORT_ADMIN,
    {
      onError: () => {},
    },
  );

  const handleExport = async (values) => {
    setIsExportLoading(true);
    const res = await requestLibraryInventoryExportAdmin({
      variables: {
        where: {
          id,
        },
        data: {
          type: EXPORT_TYPES?.LIBRARY_INVENTORY,
        },
        filter: {
          search: exportFilter?.search,
          brandIds: exportFilter?.brand,
          categoryIds: exportFilter?.category,
          materialIds: exportFilter?.material,
          ageGroupIds: exportFilter?.ageGroup,
          dateRange: {
            from: values?.date?.[0]?.startOf('day'),
            to: values?.date?.[1]?.endOf('day'),
          },
        },
      },
    });
    if (res?.data) {
      setIsModalOpen(false);
      form?.resetFields();
    }
    setIsExportLoading(false);
  };

  return (
    <>
      <Portal portalId="header-title-content">
        <GoBackButton
          customLink={`${ROUTES?.MASTER_LISTS}${ROUTES?.LIBRARY}`}
        />
        <div className="portal-header">View Library</div>
      </Portal>
      <Portal portalId="header-right-content">
        {hasPermission(
          PERMISSIONS_KEY?.MASTER_LIST_LIBRARY_INVENTORY_DETAILS,
          PERMISSION_TYPE?.UPDATE,
          permissions,
        ) && (
          <Button
            className="common-button"
            size="middle"
            onClick={() =>
              history?.push(
                `${ROUTES?.MASTER_LISTS}${ROUTES?.LIBRARY}${ROUTES?.INVENTORY_DETAILS}/edit/${id}/${libraryNumber}`,
              )
            }
          >
            Edit Library
          </Button>
        )}
        <Button
          className="common-button ml-16"
          size="middle"
          icon={<ExportOutlined />}
          onClick={() => setIsModalOpen(true)}
          loading={isExportLoading}
        >
          Export
        </Button>
      </Portal>
      <Portal portalId="header-right-content-phones">
        {hasPermission(
          PERMISSIONS_KEY?.MASTER_LIST_LIBRARY_INVENTORY_DETAILS,
          PERMISSION_TYPE?.UPDATE,
          permissions,
        ) && (
          <Button
            className="common-button"
            size="middle"
            onClick={() =>
              history?.push(
                `${ROUTES?.MASTER_LISTS}${ROUTES?.LIBRARY}${ROUTES?.INVENTORY_DETAILS}/edit/${id}/${libraryNumber}`,
              )
            }
          >
            Edit Library
          </Button>
        )}
        <Button
          className="common-button ml-16"
          size="middle"
          icon={<ExportOutlined />}
          onClick={() => setIsModalOpen(true)}
          loading={isExportLoading}
        >
          Export
        </Button>
      </Portal>
      <ExportDateRangeModal
        form={form}
        onFinish={handleExport}
        loadings={isExportLoading}
        isModalOpen={isModalOpen}
        setIsModalOpen={setIsModalOpen}
      />
      <LibraryTabs libraryId={id} libraryNumber={libraryNumber} isView>
        <div className="full-height-without-details">
          <InventoryTable
            libraryId={id}
            isView
            exportFilter={exportFilter}
            setExportFilter={setExportFilter}
            handleExport={handleExport}
            isExportLoading={isExportLoading}
            libraryNumber={libraryNumber}
          />
        </div>
      </LibraryTabs>
    </>
  );
};

export default ViewInventoryDetails;
