import { gql } from '@apollo/client';

// eslint-disable-next-line import/prefer-default-export
export const MARK_NOTIFICATION_AS_READ = gql`
  mutation MarkNotificationAsRead(
    $where: MarkNotificationsAsReadWhereInput!
    $data: MarkNotificationsAsReadData
  ) {
    markNotificationAsRead(where: $where, data: $data) {
      message
    }
  }
`;
