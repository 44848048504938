import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import AccessControl from '../../../../AccessControl';
import Error404 from '../../../../Error404';
import { PERMISSIONS_KEY, ROUTES } from '../../../../common/constants';
import './estate.less';
import AnnuallyEstate from './pages/AnnuallyEstate';
import EditAnnuallyEstate from './pages/EditAnnuallyEstate';
import EditQuarterlyEstate from './pages/EditQuarterlyEstate';
import EditSemiAnnualEstate from './pages/EditSemiAnnualEstate';
import QuarterlyEstate from './pages/QuarterlyEstate';
import SemiAnnualEstate from './pages/SemiAnnualEstate';

const EstateWrapper = () => (
  <Switch>
    <Route
      exact
      path={`${ROUTES?.SUBSCRIPTION_PLANS}${ROUTES?.ESTATE}`}
      render={() => (
        <AccessControl permissionKey={PERMISSIONS_KEY?.SUBSCRIPTION_PLANS}>
          <Redirect
            to={`${ROUTES?.SUBSCRIPTION_PLANS}${ROUTES?.ESTATE}${ROUTES?.QUARTERLY}`}
          />
        </AccessControl>
      )}
    />
    <Route
      exact
      path={`${ROUTES?.SUBSCRIPTION_PLANS}${ROUTES?.ESTATE}${ROUTES?.QUARTERLY}`}
      render={(props) => (
        <AccessControl permissionKey={PERMISSIONS_KEY?.SUBSCRIPTION_PLANS}>
          <QuarterlyEstate {...props} />
        </AccessControl>
      )}
    />
    <Route
      exact
      path={`${ROUTES?.SUBSCRIPTION_PLANS}${ROUTES?.ESTATE}${ROUTES?.QUARTERLY}/edit`}
      render={(props) => (
        <AccessControl permissionKey={PERMISSIONS_KEY?.SUBSCRIPTION_PLANS}>
          <EditQuarterlyEstate {...props} />
        </AccessControl>
      )}
    />
    <Route
      exact
      path={`${ROUTES?.SUBSCRIPTION_PLANS}${ROUTES?.ESTATE}${ROUTES?.SEMI_ANNUAL}`}
      render={(props) => (
        <AccessControl permissionKey={PERMISSIONS_KEY?.SUBSCRIPTION_PLANS}>
          <SemiAnnualEstate {...props} />
        </AccessControl>
      )}
    />
    <Route
      exact
      path={`${ROUTES?.SUBSCRIPTION_PLANS}${ROUTES?.ESTATE}${ROUTES?.SEMI_ANNUAL}/edit`}
      render={(props) => (
        <AccessControl permissionKey={PERMISSIONS_KEY?.SUBSCRIPTION_PLANS}>
          <EditSemiAnnualEstate {...props} />
        </AccessControl>
      )}
    />
    <Route
      exact
      path={`${ROUTES?.SUBSCRIPTION_PLANS}${ROUTES?.ESTATE}${ROUTES?.ANNUALLY}`}
      render={(props) => (
        <AccessControl permissionKey={PERMISSIONS_KEY?.SUBSCRIPTION_PLANS}>
          <AnnuallyEstate {...props} />
        </AccessControl>
      )}
    />
    <Route
      exact
      path={`${ROUTES?.SUBSCRIPTION_PLANS}${ROUTES?.ESTATE}${ROUTES?.ANNUALLY}/edit`}
      render={(props) => (
        <AccessControl permissionKey={PERMISSIONS_KEY?.SUBSCRIPTION_PLANS}>
          <EditAnnuallyEstate {...props} />
        </AccessControl>
      )}
    />
    <Route path="*" exact component={Error404} />
  </Switch>
);

export default EstateWrapper;
