import { useLazyQuery, useMutation } from '@apollo/client';
import { Form, Image, Modal, Switch, Tooltip } from 'antd';
import React, { useContext, useEffect, useState } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { AppContext } from '../../../../../AppContext';
import { ReactComponent as ArchiveIcon } from '../../../../../assets/svg/box-archive-solid.svg';
import { ReactComponent as EditIcon } from '../../../../../assets/svg/pen-to-square-solid.svg';
import { ReactComponent as UnarchiveIcon } from '../../../../../assets/svg/right-from-bracket-solid.svg';
import {
  ARCHIVING_STATE,
  BREAKPOINTS,
  DATE_FORMATS,
  LIMIT,
  PERMISSIONS_KEY,
  PERMISSION_TYPE,
  SORT,
} from '../../../../../common/constants';
import {
  fileUpload,
  formatDate,
  hasPermission,
  openNotification,
} from '../../../../../common/utils';
import CommonPreview from '../../../../../components/CommonPreview';
import CommonTable from '../../../../../components/CommonTable';
import SearchComponent from '../../../../../components/SearchComponent';
import { CREATE_TAG, UPDATE_TAG } from '../graphql/Mutations';
import { GET_TAG_IMAGE_SIGNED_PUT_URL, TAGS_ADMIN } from '../graphql/Queries';
import TagsModal from './TagsModal';

const initialPaginationValue = {
  total: 0,
  current: 1,
  pageSize: 10,
};

const initialFilter = {
  limit: LIMIT,
  search: '',
  skip: 0,
};

const initialSort = {
  field: 'name',
  order: 'ASC',
};

const TagsTable = ({
  isTagsModalOpen,
  setIsTagsModalOpen,
  tagsImages,
  setTagsImages,
}) => {
  const {
    state: { permissions },
  } = useContext(AppContext);
  const [form] = Form.useForm();
  const [tagsList, setTagsList] = useState([]);
  const [tagsSearchTerm, setTagsSearchTerm] = useState('');
  const [isEmptyTagsList, setIsEmptyTagsList] = useState(false);
  const [tags, setTags] = useState();
  const [query, setQuery] = useState('');
  const [sortedInfo, setSortedInfo] = useState({});
  const [paginationProp, setPaginationProp] = useState(initialPaginationValue);
  const [isArchivePrompts, setIsArchivePrompts] = useState(false);
  const [isTagsLoading, setIsTagsLoading] = useState(true);
  const [isArchiveLoading, setIsArchiveLoading] = useState(false);
  const [isArchiveFilter, setIsArchiveFilter] = useState(false);
  const [isBtnLoading, setIsBtnLoading] = useState(false);
  const [visible, setVisible] = useState(false);
  const [tagsPreviewImage, setTagsPreviewImage] = useState();

  const [createTag] = useMutation(CREATE_TAG, {
    onError: () => {},
  });

  const [updateTag] = useMutation(UPDATE_TAG, {
    onError: () => {},
  });

  const [getTagImageSignedPutUrl] = useLazyQuery(GET_TAG_IMAGE_SIGNED_PUT_URL, {
    fetchPolicy: 'network-only',
    onError() {
      setIsBtnLoading(false);
    },
  });

  const [tagsAdmin] = useLazyQuery(TAGS_ADMIN, {
    onCompleted: (response) => {
      setTagsList(
        [...response?.tagsAdmin?.data]?.filter(
          (item) => item?.minAge !== 0 || item?.maxAge !== 0,
        ),
      );
      if (
        response?.tagsAdmin?.count === 0 &&
        initialPaginationValue?.total === 0
      ) {
        setIsEmptyTagsList(true);
      } else {
        setIsEmptyTagsList(false);
      }
      const pagination = {
        ...paginationProp,
        defaultPageSize: LIMIT,
        total: response?.tagsAdmin?.count,
      };
      setPaginationProp(pagination);
      setIsTagsLoading(false);
    },
    fetchPolicy: 'network-only',
    onError() {},
  });

  const hasUpdatePermission = hasPermission(
    PERMISSIONS_KEY?.MASTER_LIST_TAG,
    PERMISSION_TYPE?.UPDATE,
    permissions,
  );

  useEffect(() => {
    setPaginationProp({ ...paginationProp, current: 1, skip: 0 });
    setIsTagsLoading(true);
    tagsAdmin({
      variables: {
        filter: {
          ...initialFilter,
          limit: paginationProp?.pageSize || LIMIT,
          search: tagsSearchTerm,
          isArchived: isArchiveFilter,
        },
        sort: sortedInfo?.column
          ? {
              field: sortedInfo?.field,
              order: sortedInfo?.order === 'ascend' ? SORT?.ASC : SORT?.DESC,
            }
          : initialSort,
      },
    });
  }, [isArchiveFilter]);

  const handleTableChange = (pagination, tableFilter, sorter) => {
    const { current } = pagination;
    const skip = (current - 1) * (pagination?.pageSize || 0);
    setPaginationProp({ ...paginationProp, ...pagination });
    setIsTagsLoading(true);
    setSortedInfo(sorter);
    tagsAdmin({
      variables: {
        filter: {
          ...initialFilter,
          skip,
          limit: pagination?.pageSize,
          search: tagsSearchTerm,
          isArchived: isArchiveFilter,
        },
        sort: sorter?.column
          ? {
              field: sorter?.field,
              order: sorter?.order === 'ascend' ? SORT?.ASC : SORT?.DESC,
            }
          : initialSort,
      },
    });
  };

  const handleSearch = (value) => {
    const trimValue = value?.trim();
    setTagsSearchTerm(trimValue);
    setPaginationProp({ ...paginationProp, current: 1, skip: 0 });
    setIsTagsLoading(true);
    tagsAdmin({
      variables: {
        filter: {
          ...initialFilter,
          limit: paginationProp?.pageSize || LIMIT,
          search: trimValue,
          isArchived: isArchiveFilter,
        },
        sort: sortedInfo?.column
          ? {
              field: sortedInfo?.field,
              order: sortedInfo?.order === 'ascend' ? SORT?.ASC : SORT?.DESC,
            }
          : initialSort,
      },
    });
  };

  const handleArchive = async (id, isArchived) => {
    setIsArchiveLoading(true);
    const response = await updateTag({
      variables: {
        where: {
          id,
        },
        data: {
          isArchived: !isArchived,
        },
      },
    });
    if (response?.data) {
      setIsArchiveLoading(false);
      setIsTagsLoading(true);
      setIsArchivePrompts(false);
      setTags();
      tagsAdmin({
        variables: {
          filter: {
            ...initialFilter,
            skip:
              (paginationProp?.current - 1) * (paginationProp?.pageSize || 0),
            limit: paginationProp?.pageSize || LIMIT,
            search: tagsSearchTerm,
            isArchived: isArchiveFilter,
          },
          sort: sortedInfo?.column
            ? {
                field: sortedInfo?.field,
                order: sortedInfo?.order === 'ascend' ? SORT?.ASC : SORT?.DESC,
              }
            : initialSort,
        },
      });
    }
    setIsArchiveLoading(false);
  };

  const handleTag = async (values) => {
    setIsBtnLoading(true);
    const uuid = uuidv4();
    let response;
    let image = '';
    if (values?.image?.length > 0 && !tagsImages?.[0]?.url) {
      const { name } = values?.image?.[0];
      const ext = name?.substring(name?.lastIndexOf('.') + 1);
      const timestamp = Date?.now();
      const filename = name?.split('.')?.slice(0, -1)?.join('.');
      const newFilename = `${timestamp}_${filename}.${ext}`;
      const fileKey = `facilitates/${uuid}/${newFilename}`;

      const res = await getTagImageSignedPutUrl({
        variables: {
          data: {
            fileName: fileKey,
            imageUuid: uuid,
          },
        },
      });
      if (res?.data) {
        try {
          try {
            await fileUpload(
              res?.data?.getTagImageSignedPutUrl?.signedUrl,
              tagsImages?.[0]?.originFileObj,
            );
            image = res?.data?.getTagImageSignedPutUrl?.fileName;
          } catch (error) {
            throw new Error(
              `${tagsImages?.name} upload failed. Please try again.`,
            );
          }
        } catch (error) {
          setIsBtnLoading(false);
          openNotification('error', error.message);
        }
      }
    }
    try {
      if (!tags) {
        response = await createTag({
          variables: {
            data: {
              name: values?.name,
              imageUuid: uuid,
              image: tagsImages?.[0]?.url ? undefined : image,
            },
          },
        });
      } else {
        response = await updateTag({
          variables: {
            where: {
              id: tags?.id,
            },
            data: {
              name: values?.name,
              imageUuid: tags?.imageUuid,
              image: tagsImages?.[0]?.url ? undefined : image,
            },
          },
        });
      }
    } catch (error) {
      setIsBtnLoading(false);
      return error;
    }
    if (response?.data) {
      setIsTagsModalOpen(false);
      setIsBtnLoading(true);
      setTags();
      form?.resetFields();
      tagsAdmin({
        variables: {
          filter: {
            ...initialFilter,
            skip:
              (paginationProp?.current - 1) * (paginationProp?.pageSize || 0),
            limit: paginationProp?.pageSize || LIMIT,
            search: tagsSearchTerm,
            isArchived: isArchiveFilter,
          },
          sort: sortedInfo?.column
            ? {
                field: sortedInfo?.field,
                order: sortedInfo?.order === 'ascend' ? SORT?.ASC : SORT?.DESC,
              }
            : initialSort,
        },
      });
    }
    setIsBtnLoading(false);
  };

  const columns = [
    {
      title: 'NAME',
      dataIndex: 'name',
      key: 'name',
      ellipsis: true,
      width: 250,
      align: 'left',
      // eslint-disable-next-line no-undef
      fixed: window.innerWidth > BREAKPOINTS.desktop ? 'left' : false,
      className: 'max-width-column',
      render: (_, record) => record?.name?.replace(/\s/g, '\u00a0'),
    },
    {
      title: 'IMAGE',
      dataIndex: 'image',
      key: 'image',
      ellipsis: true,
      width: 100,
      align: 'left',
      className: 'max-width-column',
      render: (_, record) => (
        <span>
          {record?.image ? (
            <>
              <Image
                preview={{
                  visible: false,
                }}
                width={20}
                height={20}
                src={record?.image}
                onClick={(e) => {
                  e?.stopPropagation();
                  setVisible(true);
                  setTagsPreviewImage(record?.image);
                }}
                className="pointer"
              />
            </>
          ) : (
            <span>No Image</span>
          )}
        </span>
      ),
    },
    {
      title: 'CREATED AT',
      dataIndex: 'createdAt',
      key: 'createdAt',
      width: 150,
      ellipsis: true,
      align: 'left',
      className: 'max-width-column',
      render: (_, record) =>
        formatDate(record?.createdAt, DATE_FORMATS?.REGULAR) || '-',
    },
    {
      title: 'ACTIONS',
      dataIndex: 'actions',
      key: 'actions',
      ellipsis: true,
      width: 150,
      // eslint-disable-next-line no-undef
      fixed: window.innerWidth > BREAKPOINTS.desktop ? 'right' : false,
      render: (_, record) => (
        <>
          <div className="action-button">
            {isArchiveFilter ? (
              <Tooltip title="Unarchive" placement="top" zIndex={4}>
                <UnarchiveIcon
                  onClick={() => {
                    setIsArchivePrompts(true);
                    setTags(record);
                  }}
                  className="mr-16 pointer svg-icon"
                />
              </Tooltip>
            ) : (
              <>
                <Tooltip title="Edit" placement="top" zIndex={4}>
                  <EditIcon
                    onClick={() => {
                      setIsTagsModalOpen(true);
                      setTags(record);
                      setTagsImages(
                        record?.image ? [{ url: record?.image }] : [],
                      );
                    }}
                    className="mr-16 pointer svg-icon"
                  />
                </Tooltip>
                <Tooltip title="Archive" placement="top" zIndex={4}>
                  <ArchiveIcon
                    onClick={() => {
                      setIsArchivePrompts(true);
                      setTags(record);
                    }}
                    className="mr-16 pointer svg-icon"
                  />
                </Tooltip>
              </>
            )}
          </div>
        </>
      ),
    },
  ]?.filter((item) => {
    if (item?.dataIndex === 'actions' && !hasUpdatePermission) {
      return item?.hidden;
    }
    return !item?.hidden;
  });

  const locale = {
    emptyText: isEmptyTagsList ? '' : <span />,
  };

  return (
    <>
      <Modal
        title="Caution"
        okText="Yes"
        cancelText="No"
        open={isArchivePrompts}
        onOk={() => handleArchive(tags?.id, tags?.isArchived)}
        onCancel={() => {
          setIsArchivePrompts(false);
          setTags();
        }}
        okButtonProps={{ loading: isArchiveLoading }}
      >
        Are you sure you want to
        <strong>
          {' '}
          {!tags?.isArchived
            ? ARCHIVING_STATE?.ARCHIVED
            : ARCHIVING_STATE?.UNARCHIVED}{' '}
        </strong>
        this tag?
      </Modal>
      <CommonPreview
        visible={visible}
        setVisible={setVisible}
        image={tagsPreviewImage}
        setImage={setTagsPreviewImage}
      />
      <TagsModal
        form={form}
        onFinish={handleTag}
        loadings={isBtnLoading}
        isModalOpen={isTagsModalOpen}
        setIsModalOpen={setIsTagsModalOpen}
        tags={tags}
        setTags={setTags}
        tagsImages={tagsImages}
        setTagsImages={setTagsImages}
        setVisible={setVisible}
        setTagsPreviewImage={setTagsPreviewImage}
      />
      <div className="d-flex justify-end mb-16 flex-wrap">
        {hasUpdatePermission && (
          <div className="mr-16 d-flex align-center">
            <h4 className="m-0 mr-8">Only Archived</h4>{' '}
            <Switch
              size="small"
              onChange={(checked) => setIsArchiveFilter(checked)}
            />
          </div>
        )}
        <SearchComponent
          className="search-box"
          query={query}
          setQuery={setQuery}
          getData={handleSearch}
        />
      </div>
      <CommonTable
        locale={locale}
        columns={columns}
        data={tagsList || []}
        loading={isTagsLoading}
        onChange={handleTableChange}
        paginationConfig={paginationProp}
        rowKey={(record) => record?.id}
      />
    </>
  );
};

export default TagsTable;
