import { Button, Result } from 'antd';
import React from 'react';
import { Redirect, useHistory } from 'react-router-dom';
import { ROUTES } from './common/constants';

const RedirectControl = ({ redirectArray = ['/'], route }) => {
  const history = useHistory();

  if (redirectArray?.length === 0) {
    return (
      <Result
        status="403"
        title="403"
        subTitle="Sorry, you are not authorized to access this page."
        extra={
          <Button type="primary" onClick={() => history?.push(ROUTES?.MAIN)}>
            Back Home
          </Button>
        }
      />
    );
  }

  if (redirectArray?.length > 0) {
    const redirectRoute = route
      ? `${route}${redirectArray?.[0]}`
      : redirectArray?.[0];
    return <Redirect to={redirectRoute} />;
  }

  return null;
};

export default RedirectControl;
