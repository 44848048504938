import { PlusOutlined, ReloadOutlined } from '@ant-design/icons';
import { useMutation } from '@apollo/client';
import { Button, Card } from 'antd';
import React, { useContext, useState } from 'react';
import { AppContext } from '../../../AppContext';
import {
  MODULES,
  PERMISSION_TYPE,
  PERMISSIONS_KEY,
} from '../../../common/constants';
import { hasPermission } from '../../../common/utils';
import Portal from '../../../components/Portal';
import UiLabelTable from '../components/UiLabelTable';
import { CREATE_CLOUD_FRONT_CACHE_INVALIDATION } from '../graphql/Mutations';

const UiLabels = () => {
  const {
    state: { permissions },
  } = useContext(AppContext);
  const [isUiLabelModalOpen, setIsUiLabelModalOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const [createCloudFrontCacheInvalidation] = useMutation(
    CREATE_CLOUD_FRONT_CACHE_INVALIDATION,
    {
      onCompleted: () => {
        setIsLoading(false);
      },
      onError: () => {
        setIsLoading(false);
      },
    },
  );

  return (
    <>
      <Portal portalId="header-title-content">
        <div className="portal-header">{MODULES?.UI_LABELS}</div>
      </Portal>
      <Portal portalId="header-right-content">
        {hasPermission(
          PERMISSIONS_KEY?.UI_LABELS,
          PERMISSION_TYPE?.CREATE,
          permissions,
        ) && (
          <Button
            className="common-button"
            size="middle"
            icon={<PlusOutlined />}
            onClick={() => setIsUiLabelModalOpen(true)}
          >
            Add New Ui Label
          </Button>
        )}
        {hasPermission(
          PERMISSIONS_KEY?.UI_LABELS,
          PERMISSION_TYPE?.UPDATE,
          permissions,
        ) && (
          <Button
            className="common-button ml-16"
            size="middle"
            icon={<ReloadOutlined />}
            onClick={createCloudFrontCacheInvalidation}
            loading={isLoading}
          >
            Revalidate Cache
          </Button>
        )}
      </Portal>
      <Portal portalId="header-right-content-phones">
        {hasPermission(
          PERMISSIONS_KEY?.UI_LABELS,
          PERMISSION_TYPE?.CREATE,
          permissions,
        ) && (
          <Button
            className="common-button"
            size="middle"
            icon={<PlusOutlined />}
            onClick={() => setIsUiLabelModalOpen(true)}
          >
            Add New Ui Label
          </Button>
        )}
        {hasPermission(
          PERMISSIONS_KEY?.UI_LABELS,
          PERMISSION_TYPE?.UPDATE,
          permissions,
        ) && (
          <Button
            className="common-button ml-16"
            size="middle"
            icon={<ReloadOutlined />}
            onClick={createCloudFrontCacheInvalidation}
            loading={isLoading}
          >
            Revalidate Cache
          </Button>
        )}
      </Portal>
      <Card className="full-height-without-nav">
        <UiLabelTable
          isUiLabelModalOpen={isUiLabelModalOpen}
          setIsUiLabelModalOpen={setIsUiLabelModalOpen}
        />
      </Card>
    </>
  );
};

export default UiLabels;
