import { gql } from '@apollo/client';

// eslint-disable-next-line import/prefer-default-export
export const REQUEST_HOLDING_PERIOD_EXPORT = gql`
  mutation RequestHoldingPeriodExport(
    $data: RequestExportInput!
    $filter: HoldingPeriodExportFilter
  ) {
    requestHoldingPeriodExport(data: $data, filter: $filter) {
      message
    }
  }
`;

export const CREATE_RETURN_ORDER_ADMIN = gql`
  mutation CreateReturnOrderAdmin($where: OrderItemUniqueInput!) {
    createReturnOrderAdmin(where: $where) {
      message
    }
  }
`;
