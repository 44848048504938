import React from 'react';
import { Route, Switch } from 'react-router-dom';
import AccessControl from '../../../../AccessControl';
import { PERMISSIONS_KEY, ROUTES } from '../../../../common/constants';
import Error404 from '../../../../Error404';
import Pincode from './pages/Pincode';

const PincodeWrapper = () => (
  <Switch>
    <Route
      exact
      path={`${ROUTES?.MASTER_LISTS}${ROUTES?.PINCODE}`}
      render={(props) => (
        <AccessControl permissionKey={PERMISSIONS_KEY?.MASTER_LIST_PINCODE}>
          <Pincode {...props} />
        </AccessControl>
      )}
    />
    <Route path="*" exact component={Error404} />
  </Switch>
);

export default PincodeWrapper;
