import { Empty } from 'antd';
import React from 'react';
import LoaderComponent from '../../../components/LoaderComponent';
import NewToyRequestCard from './NewToyRequestCard';

const NewToyRequestList = ({
  newToyReqList,
  infiniteScrollRef,
  isFetchMoreNewToyReqLoading,
  isEmptyNewToyReqList,
  isNewToyReqLoading,
}) => (
  <div>
    {!isEmptyNewToyReqList &&
      newToyReqList?.map((toyReq) => (
        <NewToyRequestCard
          key={toyReq?.id}
          toyReq={toyReq}
          infiniteScrollRef={infiniteScrollRef}
        />
      ))}
    {(isFetchMoreNewToyReqLoading || isNewToyReqLoading) && (
      <LoaderComponent size="small" setHeight={10} />
    )}
    {isEmptyNewToyReqList && <Empty image={Empty?.PRESENTED_IMAGE_SIMPLE} />}
  </div>
);

export default NewToyRequestList;
