import { ExportOutlined } from '@ant-design/icons';
import { useMutation } from '@apollo/client';
import { Button, Card } from 'antd';
import React, { useState } from 'react';
import { EXPORT_TYPES, MODULES } from '../../../../../common/constants';
import Portal from '../../../../../components/Portal';
import AllDeliveryTable from '../../all/components/AllDeliveryTable';
import { REQUEST_NEW_ORDER_EXPORT } from '../graphql/Mutations';

const New = () => {
  const [exportFilter, setExportFilter] = useState();
  const [isExportLoading, setIsExportLoading] = useState(false);

  const [requestOrderExport] = useMutation(REQUEST_NEW_ORDER_EXPORT, {
    onError: () => {},
  });

  const handleExport = async () => {
    setIsExportLoading(true);
    await requestOrderExport({
      variables: {
        data: {
          type: EXPORT_TYPES?.NEW_ORDERS,
        },
        filter: {
          search: exportFilter?.search,
          deliveryType: exportFilter?.deliveryType,
          orderIds: exportFilter?.orderNumber,
          dateRange: {
            from: exportFilter?.createdAt?.from?.$d,
            to: exportFilter?.createdAt?.to?.$d,
          },
          pincodeIds: exportFilter?.pincodes,
        },
      },
    });
    setIsExportLoading(false);
  };

  return (
    <>
      <Portal portalId="header-title-content">
        <div className="portal-header">{MODULES?.NEW}</div>
      </Portal>
      <Portal portalId="header-right-content">
        <Button
          className="common-button ml-16"
          size="middle"
          icon={<ExportOutlined />}
          onClick={handleExport}
          loading={isExportLoading}
        >
          Export
        </Button>
      </Portal>
      <Portal portalId="header-right-content-phones">
        <Button
          className="common-button ml-16"
          size="middle"
          icon={<ExportOutlined />}
          onClick={handleExport}
          loading={isExportLoading}
        >
          Export
        </Button>
      </Portal>
      <Card className="mobile-with-search-action-container">
        <AllDeliveryTable
          exportFilter={exportFilter}
          setExportFilter={setExportFilter}
        />
      </Card>
    </>
  );
};

export default New;
