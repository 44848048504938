import { useLazyQuery } from '@apollo/client';
import React, { useEffect, useState } from 'react';
import { ROUTES } from '../../../../../common/constants';
import GoBackButton from '../../../../../components/GoBackButton';
import LoaderComponent from '../../../../../components/LoaderComponent';
import Portal from '../../../../../components/Portal';
import EndUsersTabs from '../components/EndUsersTabs';
import OrderInfo from '../components/OrderInfo';
import { USER_ORDER_DETAILS_ADMIN } from '../graphql/Queries';

const ViewOrderDetails = ({
  match: { params: { id, userNumber } = {} } = {},
}) => {
  const [endUserDetail, setEndUserDetail] = useState({});
  const [isEndUserLoading, setIsEndUserLoading] = useState(true);

  const [UserOrderDetailsAdmin] = useLazyQuery(USER_ORDER_DETAILS_ADMIN, {
    onCompleted: (response) => {
      setEndUserDetail(response?.userOrderDetailsAdmin?.data);
      setIsEndUserLoading(false);
    },
    fetchPolicy: 'network-only',
    onError() {},
  });

  useEffect(() => {
    setIsEndUserLoading(true);
    UserOrderDetailsAdmin({
      variables: {
        where: {
          id,
        },
      },
    });
  }, []);

  return (
    <>
      <Portal portalId="header-title-content">
        <GoBackButton
          customLink={`${ROUTES?.SYSTEM_USERS}${ROUTES?.END_USERS}`}
        />
        <div className="portal-header">View End User</div>
      </Portal>
      <EndUsersTabs endUserId={id} endUserNumber={userNumber} isView>
        <div className="full-height-without-details">
          {isEndUserLoading ? (
            <LoaderComponent
              size="large"
              setHeight="60"
              spinning={isEndUserLoading}
            />
          ) : (
            <OrderInfo endUserDetail={endUserDetail} userNumber={userNumber} />
          )}
        </div>
      </EndUsersTabs>
    </>
  );
};

export default ViewOrderDetails;
