import React from 'react';
import { Route, Switch } from 'react-router-dom';
import AccessControl from '../../../../AccessControl';
import { PERMISSIONS_KEY, ROUTES } from '../../../../common/constants';
import Pincode from './pages/Pincode';
import './pincode.less';

const PincodeMappingWrapper = () => (
  <Switch>
    <Route
      exact
      path={`${ROUTES?.MASTER_LISTS}${ROUTES?.PINCODE_MAPPING}`}
      render={(props) => (
        <AccessControl permissionKey={PERMISSIONS_KEY?.MASTER_LIST_PINCODE}>
          <Pincode {...props} />
        </AccessControl>
      )}
    />
  </Switch>
);

export default PincodeMappingWrapper;
