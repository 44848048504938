import { useLazyQuery } from '@apollo/client';
import { Badge, Button } from 'antd';
import { map } from 'lodash';
import { React, useContext, useEffect, useState } from 'react';
import { AppContext } from '../../../AppContext';
import {
  BREAKPOINTS,
  DATE_FORMATS,
  EXPORT,
  EXPORT_JOBS_STATUS,
  EXPORT_TYPES,
  EXPORT_TYPES_SELECT,
  EXPORT_TYPES_SELECT_LIBRARY,
  LIMIT,
  PERMISSION_TYPE,
  SORT,
  SORTING,
  USER_ROLES,
} from '../../../common/constants';
import {
  formatDate,
  handleDownload,
  hasPermission,
} from '../../../common/utils';
import CommonTable from '../../../components/CommonTable';
import SelectComponent from '../../../components/SelectComponent';
import SortDropdown from '../../../components/SortDropdown';
import useInfiniteScroll from '../../../hooks/useInfiniteScroll';
import { EXPORT_JOBS } from '../graphql/Queries';
import ExportList from './ExportList';

const initialPaginationValue = {
  total: 0,
  current: 1,
  pageSize: 10,
};

const initialExportsFilter = {
  limit: LIMIT,
  skip: 0,
};

const initialExportsSort = {
  field: 'createdAt',
  order: 'DESC',
};

const sort = [
  {
    label: SORTING?.DATE_A_Z?.label,
    value: SORTING?.DATE_A_Z?.value,
    field: SORTING?.DATE_A_Z?.field,
    sort: SORTING?.DATE_A_Z?.sort,
  },
  {
    label: SORTING?.DATE_Z_A?.label,
    value: SORTING?.DATE_Z_A?.value,
    field: SORTING?.DATE_Z_A?.field,
    sort: SORTING?.DATE_Z_A?.sort,
  },
];

const ExportTable = () => {
  const {
    state: { isDesktop, permissions },
    getCurrentUser,
  } = useContext(AppContext);
  const { roles } = getCurrentUser() || {};
  const [exportList, setExportList] = useState([]);
  const [isEmptyDeliveryList, setIsEmptyDeliveryList] = useState(false);
  const [sortedInfo, setSortedInfo] = useState({});
  const [paginationProp, setPaginationProp] = useState(initialPaginationValue);
  const [selectedType, setSelectedType] = useState('ALL');

  const [isExportsLoading, setIsExportsLoading] = useState(true);
  const [isFetchMoreExportLoading, setIsFetchMoreExportLoading] = useState(
    false,
  );
  const [isActive, setIsActive] = useState(true);
  const [isAllSortPopoverOpen, setIsAllSortPopoverOpen] = useState(false);

  const [exportJobs] = useLazyQuery(EXPORT_JOBS, {
    onCompleted: (response) => {
      if (isDesktop || !roles?.includes(USER_ROLES?.LIBRARIAN?.key)) {
        setExportList([...response?.exportJobs?.data]);
      } else {
        setExportList([...exportList, ...response?.exportJobs?.data]);
      }
      if (
        response?.exportJobs?.count === 0 &&
        initialPaginationValue?.total === 0
      ) {
        setIsEmptyDeliveryList(true);
      } else {
        setIsEmptyDeliveryList(false);
      }
      if (response?.exportJobs?.data?.length < LIMIT) {
        setIsActive(false);
      }
      const pagination = {
        ...paginationProp,
        defaultPageSize: LIMIT,
        total: response?.exportJobs?.count,
      };
      setPaginationProp(pagination);
      setIsExportsLoading(false);
      setIsFetchMoreExportLoading(false);
    },
    fetchPolicy: 'network-only',
    onError() {},
  });

  useEffect(() => {
    setPaginationProp({ ...paginationProp, current: 1, skip: 0 });
    setIsExportsLoading(true);
    setExportList([]);
    exportJobs({
      variables: {
        filter: {
          ...initialExportsFilter,
          limit: paginationProp?.pageSize || LIMIT,
          type: selectedType === 'ALL' ? undefined : selectedType,
        },
        sort: sortedInfo?.column
          ? {
              field: sortedInfo?.field,
              order: sortedInfo?.order === 'ascend' ? SORT?.ASC : SORT?.DESC,
            }
          : initialExportsSort,
      },
    });
  }, [selectedType, isDesktop]);

  const handleTableChange = (pagination, tableFilter, sorter) => {
    const { current } = pagination;
    const skip = (current - 1) * (pagination?.pageSize || 0);
    setPaginationProp({ ...paginationProp, ...pagination });
    setIsExportsLoading(true);
    setSortedInfo(sorter);
    exportJobs({
      variables: {
        filter: {
          ...initialExportsFilter,
          skip,
          limit: pagination?.pageSize,
          type: selectedType === 'ALL' ? undefined : selectedType,
        },
        sort: sorter?.column
          ? {
              field: sorter?.field,
              order: sorter?.order === 'ascend' ? SORT?.ASC : SORT?.DESC,
            }
          : initialExportsSort,
      },
    });
  };

  const handleInvoicesScroll = () => {
    if (!isFetchMoreExportLoading && !isExportsLoading) {
      const currentLength = exportList?.length;
      setIsExportsLoading(true);
      exportJobs({
        variables: {
          filter: {
            ...initialExportsFilter,
            skip: currentLength,
            limit: paginationProp?.pageSize || LIMIT,
            type: selectedType === 'ALL' ? undefined : selectedType,
          },
          sort: sortedInfo?.column
            ? {
                field: sortedInfo?.field,
                order: sortedInfo?.order === 'ascend' ? SORT?.ASC : SORT?.DESC,
              }
            : initialExportsSort,
        },
      });
    }
  };

  const handleSort = (values) => {
    setPaginationProp({ ...paginationProp, current: 1, skip: 0 });
    setIsExportsLoading(true);
    setIsAllSortPopoverOpen(false);
    setIsActive(true);
    setExportList([]);
    const filteredSort = sort?.filter((item) => item?.value === values?.sort);
    if (filteredSort?.[0]?.field) {
      setSortedInfo({
        ...sortedInfo,
        field: filteredSort?.[0]?.field,
        columnKey: filteredSort?.[0]?.field,
        order: filteredSort?.[0]?.sort === SORT?.ASC ? 'ascend' : 'descend',
        column: filteredSort?.[0]?.field,
      });
    }
    exportJobs({
      variables: {
        filter: {
          ...initialExportsFilter,
          limit: paginationProp?.pageSize || LIMIT,
          type: selectedType === 'ALL' ? undefined : selectedType,
        },
        sort: filteredSort?.[0]
          ? {
              field: filteredSort?.[0]?.field,
              order: filteredSort?.[0]?.sort,
            }
          : initialExportsSort,
      },
    });
  };

  // eslint-disable-next-line react-hooks/rules-of-hooks, no-use-before-define
  const infiniteScrollRef = useInfiniteScroll(handleInvoicesScroll, isActive);

  const columns = [
    {
      title: 'TYPE',
      dataIndex: 'type',
      key: 'type',
      width: 150,
      // eslint-disable-next-line no-undef
      fixed: window.innerWidth > BREAKPOINTS.desktop ? 'left' : false,
      ellipsis: true,
      align: 'left',
      className: 'max-width-column',
      render: (_, record) => EXPORT?.[record?.type],
    },
    {
      title: 'DATE',
      dataIndex: 'createdAt',
      key: 'createdAt',
      width: 150,
      ellipsis: true,
      sorter: true,
      align: 'left',
      className: 'max-width-column',
      render: (_, record) =>
        formatDate(record?.createdAt, DATE_FORMATS?.WITH_TIME) || '-',
      sortOrder: sortedInfo?.columnKey === 'createdAt' && sortedInfo?.order,
    },
    {
      title: 'STATUS',
      dataIndex: 'status',
      key: 'status',
      ellipsis: true,
      width: 150,
      align: 'left',
      className: 'max-width-column',
      render: (_, record) => (
        <Badge
          color={EXPORT_JOBS_STATUS?.[record?.status]?.color}
          text={EXPORT_JOBS_STATUS?.[record?.status]?.text}
        />
      ),
    },
    {
      title: 'ACTIONS',
      dataIndex: 'actions',
      key: 'actions',
      ellipsis: true,
      width: 150,
      // eslint-disable-next-line no-undef
      fixed: window.innerWidth > BREAKPOINTS.desktop ? 'right' : false,
      render: (_, record) => (
        <>
          <div className="action-button">
            <Button
              className="table-action-primary-btn"
              onClick={() =>
                [
                  EXPORT_TYPES?.ORDER_CHALLANS,
                  EXPORT_TYPES?.ORDER_STICKERS,
                ]?.includes(record?.type)
                  ? record?.fileUrls?.map((url) => handleDownload(url))
                  : handleDownload(record?.fileUrl)
              }
              disabled={record?.status !== EXPORT_JOBS_STATUS?.COMPLETED?.value}
            >
              Download
            </Button>
          </div>
        </>
      ),
    },
  ];

  const locale = {
    emptyText: isEmptyDeliveryList ? '' : <span />,
  };

  const exportOptions = EXPORT_TYPES_SELECT?.filter((item) =>
    hasPermission(item?.permissionKey, PERMISSION_TYPE?.READ, permissions),
  )?.map((item) => item);

  return (
    <>
      <div className="d-flex justify-end mb-16 align-center">
        <SelectComponent
          allowClear={false}
          showSearch={false}
          placeholder="Select Export Type"
          className="export-type-select"
          options={
            roles?.includes(USER_ROLES?.LIBRARIAN?.key)
              ? EXPORT_TYPES_SELECT_LIBRARY
              : exportOptions
          }
          value={selectedType}
          onChange={(value) => setSelectedType(value)}
        />
        {!isDesktop && (
          <div className="ml-16 d-flex align-center">
            <SortDropdown
              isOpen={isAllSortPopoverOpen}
              setIsOpen={setIsAllSortPopoverOpen}
              options={map(sort, (item) => item)}
              sortedInfo={sortedInfo}
              setSortedInfo={setSortedInfo}
              onFinish={handleSort}
            />
          </div>
        )}
      </div>
      {isDesktop || !roles?.includes(USER_ROLES?.LIBRARIAN?.key) ? (
        <CommonTable
          locale={locale}
          columns={columns}
          data={exportList || []}
          loading={isExportsLoading}
          onChange={handleTableChange}
          paginationConfig={paginationProp}
          rowKey={(record) => record?.id}
        />
      ) : (
        <div className="mobile-container no-scroll-bar">
          <ExportList
            exportList={exportList}
            handleDownload={handleDownload}
            infiniteScrollRef={infiniteScrollRef}
            isFetchMoreExportLoading={isFetchMoreExportLoading}
            isEmptyDeliveryList={isEmptyDeliveryList}
            isExportsLoading={isExportsLoading}
          />
        </div>
      )}
    </>
  );
};

export default ExportTable;
