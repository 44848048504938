import { ExportOutlined } from '@ant-design/icons';
import { useMutation } from '@apollo/client';
import { Button, Form } from 'antd';
import React, { useState } from 'react';
import {
  EXPORT_TYPES,
  MODULES,
  REFERRAL_TYPE,
  TRANSACTION_HISTORY_TYPES,
} from '../../../../../common/constants';
import ExportDateRangeModal from '../../../../../components/ExportDateRangeModal';
import Portal from '../../../../../components/Portal';
import EndUserTabs from '../components/EndUserTabs';
import ReferralTable from '../components/ReferralTable';
import { REQUEST_TRANSACTION_EXPORT } from '../graphql/Mutations';

const transactionTypes = [
  TRANSACTION_HISTORY_TYPES?.REFERRAL?.value,
  TRANSACTION_HISTORY_TYPES?.ENCASHMENT?.value,
];

const Referral = () => {
  const [form] = Form.useForm();
  const [exportFilter, setExportFilter] = useState({});
  const [isExportLoading, setIsExportLoading] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const [requestTransactionExport] = useMutation(REQUEST_TRANSACTION_EXPORT, {
    onError: () => {},
  });

  const handleExport = async (values) => {
    setIsExportLoading(true);
    const res = await requestTransactionExport({
      variables: {
        data: {
          type: EXPORT_TYPES?.REFERRAL_TRANSACTION,
        },
        filter: {
          search: exportFilter?.search,
          dateRange: {
            from: values?.date?.[0]?.startOf('day'),
            to: values?.date?.[1]?.endOf('day'),
          },
          status:
            exportFilter?.status?.length > 0 ? exportFilter?.status : undefined,
          transactionTypes:
            exportFilter?.description?.flat()?.length > 0
              ? exportFilter?.description?.flat()
              : transactionTypes,
          ...(exportFilter?.billingCycle?.length > 0 && {
            types: exportFilter.billingCycle,
          }),
          ...(exportFilter?.planSubscribed?.length > 0 && {
            names: exportFilter?.planSubscribed,
          }),
        },
      },
    });
    if (res?.data) {
      setIsModalOpen(false);
      form?.resetFields();
    }
    setIsExportLoading(false);
  };

  return (
    <>
      <Portal portalId="header-title-content">
        <div className="portal-header">{MODULES?.END_USER}</div>
      </Portal>
      <Portal portalId="header-right-content">
        <Button
          className="common-button ml-16"
          size="middle"
          icon={<ExportOutlined />}
          onClick={() => setIsModalOpen(true)}
          loading={isExportLoading}
        >
          Export
        </Button>
      </Portal>
      <Portal portalId="header-right-content-phones">
        <Button
          className="common-button ml-16"
          size="middle"
          icon={<ExportOutlined />}
          onClick={() => setIsModalOpen(true)}
          loading={isExportLoading}
        >
          Export
        </Button>
      </Portal>
      <ExportDateRangeModal
        form={form}
        onFinish={handleExport}
        loadings={isExportLoading}
        isModalOpen={isModalOpen}
        setIsModalOpen={setIsModalOpen}
      />
      <EndUserTabs>
        <div className="tab-table">
          <ReferralTable
            exportFilter={exportFilter}
            setExportFilter={setExportFilter}
            transactionTypes={transactionTypes}
            referredByType={REFERRAL_TYPE?.USER}
          />
        </div>
      </EndUserTabs>
    </>
  );
};

export default Referral;
