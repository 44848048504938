import { Card } from 'antd';
import React from 'react';

import { MODULES } from '../../../../../common/constants';
import Portal from '../../../../../components/Portal';
import CouponTable from '../components/CouponTable';

const Coupon = () => (
  <>
    <Portal portalId="header-title-content">
      <div className="portal-header">{MODULES?.COUPON}</div>
    </Portal>
    <Card className="mobile-with-search-container mobile-card-main">
      <CouponTable />
    </Card>
  </>
);

export default Coupon;
