import { gql } from '@apollo/client';

// eslint-disable-next-line import/prefer-default-export
export const LIBRARY_NOTIFICATIONS = gql`
  query LibraryNotifications(
    $filter: NotificationsFilter
    $sort: NotificationsSort
  ) {
    libraryNotifications(filter: $filter, sort: $sort) {
      count
      data {
        title
        description
        createdAt
        id
        type
        readBy
        hasRead
      }
    }
  }
`;
