import { useLazyQuery } from '@apollo/client';
import { Empty, Select } from 'antd';
import { debounce, uniqBy } from 'lodash';
import React, { useEffect, useState } from 'react';
import { LIMIT, SORT } from '../../../common/constants';
import SelectComponent from '../../../components/SelectComponent';
import { MATERIALS } from '../graphql/Queries';

const { Option } = Select;

const MaterialSelect = (props) => {
  const { toyDetail, ...rest } = props;
  const [materialList, setMaterialList] = useState([]);
  const [materialsSearchTerm, setMaterialsSearchTerm] = useState('');
  const [isAllMaterialsFetched, setIsAllMaterialsFetched] = useState(false);
  const [isMaterialsAllowClear, setIsMaterialsAllowClear] = useState(false);
  const [isMaterialsDropdownVisible, setIsMaterialsDropdownVisible] = useState(
    false,
  );
  const [
    isFetchMoreMaterialsLoading,
    setIsFetchMoreMaterialsLoading,
  ] = useState(false);

  const [materials, { loading: isMaterialsLoading }] = useLazyQuery(MATERIALS, {
    onCompleted: (response) => {
      if (response?.materials?.data?.length < LIMIT) {
        setIsAllMaterialsFetched(true);
      }
      setIsFetchMoreMaterialsLoading(false);
      if (toyDetail) {
        setMaterialList(
          uniqBy(
            [
              toyDetail?.material,
              ...materialList,
              ...response?.materials?.data,
            ],
            'id',
          ),
        );
        return;
      }
      setMaterialList([...materialList, ...response?.materials?.data]);
    },
    fetchPolicy: 'network-only',
    onError() {},
  });

  useEffect(() => {
    setMaterialsSearchTerm('');
    setMaterialList([]);
    materials({
      variables: {
        filter: {
          limit: LIMIT,
          skip: 0,
          search: materialsSearchTerm,
        },
        sort: {
          field: 'name',
          order: SORT?.ASC,
        },
      },
    });
    setIsAllMaterialsFetched(false);
  }, [isMaterialsDropdownVisible]);

  const handleCategoriesScroll = (event) => {
    if (!isAllMaterialsFetched && !isMaterialsLoading) {
      const target = event?.target;
      const currentLength = materialList?.length;
      if (
        target?.scrollTop + target?.offsetHeight >=
        target?.scrollHeight - 5
      ) {
        setIsFetchMoreMaterialsLoading(true);
        materials({
          variables: {
            filter: {
              limit: LIMIT,
              skip: currentLength,
              search: materialsSearchTerm,
            },
            sort: {
              field: 'name',
              order: SORT?.ASC,
            },
          },
        });
      }
    }
  };

  const handleCategoriesSearch = (searchTerm) => {
    const trimSearch = searchTerm?.trim();
    setIsAllMaterialsFetched(false);
    setMaterialList([]);
    setMaterialsSearchTerm(trimSearch);
    materials({
      variables: {
        filter: {
          limit: LIMIT,
          skip: 0,
          search: trimSearch,
        },
        sort: {
          field: 'name',
          order: SORT?.ASC,
        },
      },
    });
  };

  const handleCategoriesScrollDebounce = debounce(handleCategoriesScroll, 500);
  const handleSearchCategoriesDebounce = debounce(handleCategoriesSearch, 500);

  useEffect(() => {
    handleCategoriesScrollDebounce?.cancel();
  }, [handleCategoriesScrollDebounce]);

  useEffect(() => {
    handleSearchCategoriesDebounce?.cancel();
  }, [handleSearchCategoriesDebounce]);

  useEffect(() => {
    if (isMaterialsLoading) {
      setIsMaterialsAllowClear(false);
    } else {
      setIsMaterialsAllowClear(true);
    }
  }, [isMaterialsLoading]);

  return (
    <SelectComponent
      showSearch
      loading={
        (isMaterialsLoading && isMaterialsDropdownVisible) ||
        isFetchMoreMaterialsLoading
      }
      placeholder="Select Material"
      optionFilterProp="children"
      allowClear={isMaterialsAllowClear}
      onPopupScroll={handleCategoriesScrollDebounce}
      onSearch={handleSearchCategoriesDebounce}
      onDropdownVisibleChange={(visible) =>
        setIsMaterialsDropdownVisible(visible)
      }
      filterOption={false}
      notFoundContent={
        !isFetchMoreMaterialsLoading && isMaterialsLoading ? (
          <span>Loading...</span>
        ) : (
          <Empty image={Empty?.PRESENTED_IMAGE_SIMPLE} />
        )
      }
      {...rest}
    >
      {materialList?.map((material) => (
        <Option key={material?.id} value={material?.id}>
          {material?.name}
        </Option>
      ))}
    </SelectComponent>
  );
};

export default MaterialSelect;
