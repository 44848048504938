import { DatePicker, Divider, Form } from 'antd';
import dayjs from 'dayjs';
import React, { useEffect, useState } from 'react';
import { DATE_FORMATS } from '../../../../../common/constants';
import ModalComponent from '../../../../../components/ModalComponent';

const { Item } = Form;

const ExpiryDateModal = ({
  isModalOpen,
  setIsModalOpen,
  onFinish,
  loadings,
  endUserDetail,
}) => {
  const [form] = Form.useForm();
  const [isValidateTrigger, setIsValidateTrigger] = useState(false);

  useEffect(() => {
    form?.setFieldsValue({ expireAt: dayjs(endUserDetail?.expireAt) });
  }, [endUserDetail, isModalOpen]);

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  return (
    <>
      <ModalComponent
        open={isModalOpen}
        onCancel={handleCancel}
        onOk={form?.submit}
        title="Edit Expiry Date"
        okText="Save"
        confirmLoading={loadings}
        destroyOnClose
        forceRender
      >
        <Divider />
        <div className="mb-16">
          <Form
            form={form}
            onFinish={onFinish}
            autoComplete="off"
            layout="vertical"
            validateTrigger={isValidateTrigger ? 'onChange' : 'onSubmit'}
            onFinishFailed={() => setIsValidateTrigger(true)}
          >
            <Item
              label="Plan Expiry Date"
              name="expireAt"
              rules={[
                {
                  required: true,
                  message: 'Please Select Plan Expiry Date!',
                },
              ]}
            >
              <DatePicker
                format={DATE_FORMATS?.REGULAR}
                className="full-width"
                placeholder="Plan Expiry Date"
                disabledDate={(current) =>
                  current && current && current <= dayjs()?.startOf('day')
                }
              />
            </Item>
          </Form>
        </div>
      </ModalComponent>
    </>
  );
};

export default ExpiryDateModal;
