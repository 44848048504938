import React from 'react';
import { MODULES } from '../../../common/constants';
import Portal from '../../../components/Portal';
import DamageTable from '../components/DamageTable';
import RaiseRequestTabs from '../components/RaiseRequestTabs';

const Damages = () => (
  <>
    <Portal portalId="header-title-content">
      <div className="portal-header">{MODULES?.RAISE_REQUEST}</div>
    </Portal>
    <RaiseRequestTabs>
      <div className="tab-table">
        <DamageTable />
      </div>
    </RaiseRequestTabs>
  </>
);

export default Damages;
