import React from 'react';
import { Route, Switch } from 'react-router-dom';
import AccessControl from '../../../../AccessControl';
import {
  PERMISSIONS_KEY,
  PERMISSION_TYPE,
  ROUTES,
} from '../../../../common/constants';
import Error404 from '../../../../Error404';
import './library.less';
import BasicDetails from './pages/BasicDetails';
import BasicDetailsEdit from './pages/BasicDetailsEdit';
import InventoryDetails from './pages/InventoryDetails';
import Library from './pages/Library';
import ViewInventoryDetails from './pages/ViewInventoryDetails';
import ViewLibrary from './pages/ViewLibrary';

const ListsLibraryWrapper = () => (
  <Switch>
    <Route
      exact
      path={`${ROUTES?.MASTER_LISTS}${ROUTES?.LIBRARY}`}
      render={(props) => (
        <AccessControl permissionKey={PERMISSIONS_KEY?.MASTER_LIST_LIBRARY}>
          <Library {...props} />{' '}
        </AccessControl>
      )}
    />
    <Route
      exact
      path={`${ROUTES?.MASTER_LISTS}${ROUTES?.LIBRARY}${ROUTES?.BASIC_DETAILS}/add`}
      render={(props) => (
        <AccessControl
          permissionType={PERMISSION_TYPE?.CREATE}
          permissionKey={PERMISSIONS_KEY?.MASTER_LIST_LIBRARY_BASIC_DETAILS}
        >
          <BasicDetails {...props} />
        </AccessControl>
      )}
    />
    <Route
      exact
      path={`${ROUTES?.MASTER_LISTS}${ROUTES?.LIBRARY}${ROUTES?.INVENTORY_DETAILS}/edit/:id/:libraryNumber`}
      render={(props) => (
        <AccessControl
          permissionType={PERMISSION_TYPE?.UPDATE}
          permissionKey={PERMISSIONS_KEY?.MASTER_LIST_LIBRARY_INVENTORY_DETAILS}
        >
          <InventoryDetails {...props} />
        </AccessControl>
      )}
    />
    <Route
      exact
      path={`${ROUTES?.MASTER_LISTS}${ROUTES?.LIBRARY}${ROUTES?.BASIC_DETAILS}/edit/:id/:libraryNumber`}
      render={(props) => (
        <AccessControl
          permissionType={PERMISSION_TYPE?.UPDATE}
          permissionKey={PERMISSIONS_KEY?.MASTER_LIST_LIBRARY_BASIC_DETAILS}
        >
          <BasicDetailsEdit {...props} />{' '}
        </AccessControl>
      )}
    />
    <Route
      exact
      path={`${ROUTES?.MASTER_LISTS}${ROUTES?.LIBRARY}${ROUTES?.BASIC_DETAILS}/view/:id/:libraryNumber`}
      render={(props) => (
        <AccessControl
          permissionKey={PERMISSIONS_KEY?.MASTER_LIST_LIBRARY_BASIC_DETAILS}
        >
          <ViewLibrary {...props} />{' '}
        </AccessControl>
      )}
    />
    <Route
      exact
      path={`${ROUTES?.MASTER_LISTS}${ROUTES?.LIBRARY}${ROUTES?.INVENTORY_DETAILS}/view/:id/:libraryNumber`}
      render={(props) => (
        <AccessControl
          permissionKey={PERMISSIONS_KEY?.MASTER_LIST_LIBRARY_INVENTORY_DETAILS}
        >
          <ViewInventoryDetails {...props} />
        </AccessControl>
      )}
    />
    <Route path="*" exact component={Error404} />
  </Switch>
);

export default ListsLibraryWrapper;
