import { Card } from 'antd';
import React from 'react';
import { MODULES } from '../../../../../common/constants';
import Portal from '../../../../../components/Portal';
import CustomReportsTable from '../components/CustomReportsTable';

const CustomReport = () => (
  <>
    <Portal portalId="header-title-content">
      <div className="portal-header">{MODULES?.CUSTOM_REPORTS}</div>
    </Portal>
    <Card className="full-height-without-nav">
      <CustomReportsTable />
    </Card>
  </>
);

export default CustomReport;
