import { Button, DatePicker, Divider, Form } from 'antd';
import { map } from 'lodash';
import React, { useEffect } from 'react';
import { DATE_FORMATS, STATUS_FILTER } from '../../../common/constants';
import ModalComponent from '../../../components/ModalComponent';
import SelectComponent from '../../../components/SelectComponent';

const { Item } = Form;
const { RangePicker } = DatePicker;

const AllFilterClosureReqModal = ({
  isModalOpen,
  setIsModalOpen,
  onFinish,
  finalWhereFilters,
  setFinalWhereFilters,
}) => {
  const [form] = Form.useForm();

  const handleReset = () => {
    setIsModalOpen(false);
    form?.resetFields();
    setFinalWhereFilters();
    form?.submit();
  };

  useEffect(() => {
    form?.setFieldsValue({
      ...finalWhereFilters,
      createdAt: [
        finalWhereFilters?.createdAt?.from,
        finalWhereFilters?.createdAt?.to,
      ],
      actionTakenOn: [
        finalWhereFilters?.actionTakenOn?.from,
        finalWhereFilters?.actionTakenOn?.to,
      ],
      fromDate: finalWhereFilters?.fromDate?.date,
      toDate: finalWhereFilters?.toDate?.date,
      status: finalWhereFilters?.status,
    });
  }, [isModalOpen]);

  return (
    <>
      <ModalComponent
        open={isModalOpen}
        onCancel={() => setIsModalOpen(false)}
        title="Filter"
        forceRender
        destroyOnClose
        footer={null}
      >
        <Divider />
        <div className="mb-16">
          <Form
            form={form}
            onFinish={onFinish}
            autoComplete="off"
            layout="vertical"
          >
            <Item label="Request Date" name="createdAt">
              <RangePicker
                format={DATE_FORMATS?.REGULAR}
                className="full-width"
                popupClassName="picker-with-scroll"
                placeholder={['From Date', 'To Date']}
              />
            </Item>
            <Item label="From Date" name="fromDate">
              <DatePicker
                format={DATE_FORMATS?.REGULAR}
                className="full-width"
              />
            </Item>
            <Item label="To Date" name="toDate">
              <DatePicker
                format={DATE_FORMATS?.REGULAR}
                className="full-width"
              />
            </Item>
            <Item label="Action Taken On" name="actionTakenOn">
              <RangePicker
                format={DATE_FORMATS?.REGULAR}
                className="full-width"
                popupClassName="picker-with-scroll"
                placeholder={['From Date', 'To Date']}
              />
            </Item>
            <Item label="Status" name="status">
              <SelectComponent
                showSearch={false}
                placeholder="Select Status"
                mode="multiple"
                options={map(STATUS_FILTER, (status) => ({
                  label: status?.label,
                  value: status?.key,
                }))}
              />
            </Item>
          </Form>
          <div className="d-flex justify-end">
            <Button htmlType="submit" onClick={handleReset} className="mr-8">
              Reset
            </Button>
            <Button key="submit" type="primary" onClick={form?.submit}>
              Apply
            </Button>
          </div>
        </div>
      </ModalComponent>
    </>
  );
};

export default AllFilterClosureReqModal;
