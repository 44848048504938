import { gql } from '@apollo/client';

// eslint-disable-next-line import/prefer-default-export
export const LIBRARY_INVENTORY_BULK_UPDATE = gql`
  mutation LibraryInventoryBulkUpdate($data: LibraryInventoryBulkUpdateInput!) {
    libraryInventoryBulkUpdate(data: $data) {
      message
    }
  }
`;

export const UPDATE_LIBRARY_INVENTORY = gql`
  mutation UpdateLibraryInventory(
    $where: LibraryInventoryUniqueInput!
    $data: UpdateLibraryInventoryInput!
  ) {
    updateLibraryInventory(where: $where, data: $data) {
      message
    }
  }
`;

export const REQUEST_LIBRARY_INVENTORY_EXPORT = gql`
  mutation RequestLibraryInventoryExport(
    $data: RequestExportInput!
    $filter: RequestLibraryInventoryExportFilterInput
  ) {
    requestLibraryInventoryExport(data: $data, filter: $filter) {
      message
    }
  }
`;

export const ASSIGN_RACK = gql`
  mutation AssignRack($where: RackUniqueInput!, $data: AssignRackInput) {
    assignRack(where: $where, data: $data) {
      message
    }
  }
`;
