import { gql } from '@apollo/client';

export const CREATE_REQUEST_LIBRARY = gql`
  mutation CreateRequestLibrary($data: CreateRequestLibraryInput) {
    createRequestLibrary(data: $data) {
      message
    }
  }
`;

export const CONFIRM_RETURN_ORDER = gql`
  mutation ConfirmReturnOrder($where: ReturnOrderUniqueInput!) {
    confirmReturnOrder(where: $where) {
      message
    }
  }
`;

export const UPDATE_ORDER_STATUS_LIBRARY = gql`
  mutation UpdateOrderStatusLibrary(
    $where: OrderUniqueInput!
    $data: UpdateOrderStatusLibraryInput!
  ) {
    updateOrderStatusLibrary(where: $where, data: $data) {
      message
    }
  }
`;

export const CANCEL_ORDER_LIBRARY = gql`
  mutation CancelOrderLibrary(
    $where: OrderUniqueInput!
    $data: CancelledOrderByLibraryInput
  ) {
    cancelOrderLibrary(where: $where, data: $data) {
      message
    }
  }
`;

export const UPDATE_ORDER_ITEM_NOTE = gql`
  mutation UpdateOrderItemNote(
    $where: OrderItemUniqueInput!
    $data: UpdateOrderItemNoteInput
  ) {
    updateOrderItemNote(where: $where, data: $data) {
      message
    }
  }
`;

export const REQUEST_ORDER_EXPORT = gql`
  mutation RequestOrderExport(
    $data: RequestExportInput!
    $filter: OrderExportFilter
  ) {
    requestOrderExport(data: $data, filter: $filter) {
      message
    }
  }
`;

export const REQUEST_RETURN_ORDER_EXPORT = gql`
  mutation RequestReturnOrderExport(
    $data: RequestExportInput!
    $filter: ReturnOrderExportFilter
  ) {
    requestReturnOrderExport(data: $data, filter: $filter) {
      message
    }
  }
`;

export const RESET_ORDER_CHALLAN = gql`
  mutation ResetOrderChallan($where: OrderUniqueInput!) {
    resetOrderChallan(where: $where) {
      message
    }
  }
`;

export const RESET_ORDER_ITEM_STICKER = gql`
  mutation ResetOrderItemSticker($where: OrderItemUniqueInput!) {
    resetOrderItemSticker(where: $where) {
      message
    }
  }
`;

export const ORDER_CHALLAN_EXPORT = gql`
  mutation OrderChallanExport(
    $data: RequestExportInput!
    $filter: OrderChallanAndStickerExportInput!
  ) {
    orderChallanExport(data: $data, filter: $filter) {
      message
    }
  }
`;

export const ORDER_ITEM_STICKER_EXPORT = gql`
  mutation OrderItemStickerExport(
    $data: RequestExportInput!
    $filter: OrderChallanAndStickerExportInput!
  ) {
    orderItemStickerExport(data: $data, filter: $filter) {
      message
    }
  }
`;
