import { Tabs } from 'antd';
import React, { useContext, useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { AppContext } from '../../../../../AppContext';
import { PERMISSIONS_KEY, ROUTES, TABS } from '../../../../../common/constants';
import { filterTabsByPermissions } from '../../../../../common/utils';

const EndUserTabs = ({ children }) => {
  const history = useHistory();
  const location = useLocation();
  const {
    state: { permissions },
  } = useContext(AppContext);
  const [activeTabKey, setActiveTabKey] = useState(TABS?.INFLOW?.key);

  const tabList = [
    {
      key: TABS?.INFLOW?.key,
      label: TABS?.INFLOW?.label,
      children,
      permissionKey: PERMISSIONS_KEY?.PAYMENTS_END_USER_INFLOW,
    },
    {
      key: TABS?.OUTFLOW?.key,
      label: TABS?.OUTFLOW?.label,
      children,
      permissionKey: PERMISSIONS_KEY?.PAYMENTS_END_USER_OUTFLOW,
    },
    {
      key: TABS?.REFERRAL?.key,
      label: TABS?.REFERRAL?.label,
      children,
      permissionKey: PERMISSIONS_KEY?.PAYMENTS_END_USER_REFERRAL,
    },
  ];

  const filteredTabs = filterTabsByPermissions(tabList, permissions);

  useEffect(() => {
    if (location?.pathname?.includes(ROUTES?.INFLOW)) {
      setActiveTabKey(TABS?.INFLOW?.key);
    }
    if (location?.pathname?.includes(ROUTES?.OUTFLOW)) {
      setActiveTabKey(TABS?.OUTFLOW?.key);
    }
    if (location?.pathname?.includes(ROUTES?.REFERRAL)) {
      setActiveTabKey(TABS?.REFERRAL?.key);
    }
  }, [location]);

  const onTabChange = (key) => {
    setActiveTabKey(key);
    switch (key) {
      case TABS?.INFLOW?.key:
        history?.push(
          `${ROUTES?.PAYMENTS}${ROUTES?.END_USER}${ROUTES?.INFLOW}`,
        );
        break;
      case TABS?.OUTFLOW?.key:
        history?.push(
          `${ROUTES?.PAYMENTS}${ROUTES?.END_USER}${ROUTES?.OUTFLOW}`,
        );
        break;
      case TABS?.REFERRAL?.key:
        history?.push(
          `${ROUTES?.PAYMENTS}${ROUTES?.END_USER}${ROUTES?.REFERRAL}`,
        );
        break;
      default:
        break;
    }
  };

  return (
    <div className="card-container">
      <Tabs
        activeKey={activeTabKey}
        type="card"
        className="full-width"
        onChange={(key) => onTabChange(key)}
        items={filteredTabs}
        destroyInactiveTabPane
      />
    </div>
  );
};

export default EndUserTabs;
