import React from 'react';
import ModalComponent from '../../../../../components/ModalComponent';
import AllReturnOrderItemCard from './AllReturnOrderItemCard';

const AllReturnOrderItemModal = ({
  order,
  setOrder,
  isModalOpen,
  setIsModalOpen,
  setVisible,
  setToyImages,
}) => (
  <ModalComponent
    open={isModalOpen}
    onCancel={() => {
      setOrder({});
      setIsModalOpen(false);
    }}
    title={`${order?.returnOrder?.returnOrderNumber} - Order Items`}
    footer={null}
    wrapClassName="full-screen-modal"
  >
    <div className="mobile-full-screen-container no-scroll-bar">
      <AllReturnOrderItemCard
        order={order}
        setOrder={setOrder}
        setVisible={setVisible}
        setToyImages={setToyImages}
      />
    </div>
  </ModalComponent>
);

export default AllReturnOrderItemModal;
