import { gql } from '@apollo/client';

// eslint-disable-next-line import/prefer-default-export
export const UPDATE_ORDER_STATUS_LIBRARY = gql`
  mutation UpdateOrderStatusLibrary(
    $where: OrderUniqueInput!
    $data: UpdateOrderStatusLibraryInput!
  ) {
    updateOrderStatusLibrary(where: $where, data: $data) {
      message
    }
  }
`;

export const CANCEL_ORDER_LIBRARY = gql`
  mutation CancelOrderLibrary(
    $where: OrderUniqueInput!
    $data: CancelledOrderByLibraryInput
  ) {
    cancelOrderLibrary(where: $where, data: $data) {
      message
    }
  }
`;

export const UPDATE_ORDER_ITEM_NOTE = gql`
  mutation UpdateOrderItemNote(
    $where: OrderItemUniqueInput!
    $data: UpdateOrderItemNoteInput
  ) {
    updateOrderItemNote(where: $where, data: $data) {
      message
    }
  }
`;

export const UPDATE_ORDER_NOTE = gql`
  mutation UpdateOrderNote(
    $where: OrderUniqueInput!
    $data: UpdateOrderNoteInput
  ) {
    updateOrderNote(where: $where, data: $data) {
      message
    }
  }
`;

export const REQUEST_ORDER_EXPORT_ADMIN = gql`
  mutation RequestOrderExportAdmin(
    $data: RequestExportInput!
    $filter: OrderExportFilter
  ) {
    requestOrderExportAdmin(data: $data, filter: $filter) {
      message
    }
  }
`;

export const REQUEST_RETURN_ORDER_EXPORT_ADMIN = gql`
  mutation RequestReturnOrderExportAdmin(
    $data: RequestExportInput!
    $filter: ReturnOrderExportFilter
  ) {
    requestReturnOrderExportAdmin(data: $data, filter: $filter) {
      message
    }
  }
`;

export const CREATE_COMMENT = gql`
  mutation CreateComment($data: CreateCommentInput!) {
    createComment(data: $data) {
      message
    }
  }
`;

export const UPDATE_COMMENT = gql`
  mutation UpdateComment(
    $where: CommentUniqueInput!
    $data: UpdateCommentInput!
  ) {
    updateComment(where: $where, data: $data) {
      message
    }
  }
`;

export const RESET_ORDER_CHALLAN_ADMIN = gql`
  mutation ResetOrderChallanAdmin($where: OrderUniqueInput!) {
    resetOrderChallanAdmin(where: $where) {
      message
    }
  }
`;

export const RESET_ORDER_ITEM_STICKER_ADMIN = gql`
  mutation ResetOrderItemStickerAdmin($where: OrderItemUniqueInput!) {
    resetOrderItemStickerAdmin(where: $where) {
      message
    }
  }
`;

export const CANCEL_ORDER_ADMIN = gql`
  mutation CancelOrderAdmin(
    $where: OrderUniqueInput!
    $data: CancelledOrderByLibraryInput
  ) {
    cancelOrderAdmin(where: $where, data: $data) {
      message
    }
  }
`;

export const ORDER_CHALLAN_EXPORT_ADMIN = gql`
  mutation OrderChallanExportAdmin(
    $data: RequestExportInput!
    $filter: OrderChallanAndStickerExportInput!
  ) {
    orderChallanExportAdmin(data: $data, filter: $filter) {
      message
    }
  }
`;

export const ORDER_ITEM_STICKER_EXPORT_ADMIN = gql`
  mutation OrderItemStickerExportAdmin(
    $data: RequestExportInput!
    $filter: OrderChallanAndStickerExportInput!
  ) {
    orderItemStickerExportAdmin(data: $data, filter: $filter) {
      message
    }
  }
`;

export const CANCEL_RETURN_ORDER_REQUEST_ADMIN = gql`
  mutation CancelReturnOrderRequestAdmin($where: ReturnOrderItemUniqueInput!) {
    cancelReturnOrderRequestAdmin(where: $where) {
      message
    }
  }
`;
