import { Col, Form, Input, Row } from 'antd';
import React, { useEffect, useState } from 'react';
import { GENDERS } from '../../../../../common/constants';
import { formValidatorRules } from '../../../../../common/utils';
import CountrySelect from '../../../../../components/CountrySelect';
import PincodeSelectName from '../../../../../components/PincodeSelectName';
import SelectComponent from '../../../../../components/SelectComponent';

const { Item } = Form;
const {
  required,
  email,
  name,
  phone,
  whitespaceNotAllowed,
} = formValidatorRules;

const EndUserForm = ({
  form,
  onFinish,
  handleShowPrompt,
  initialValues,
  isView,
  endUserDetail,
}) => {
  const [validationTriggered, setValidationTriggered] = useState(false);
  const [countryId, setCountryId] = useState('');

  useEffect(() => {
    const initialValueFun = () => {
      form?.setFieldsValue({
        ...initialValues,
        country: countryId,
      });
    };
    setTimeout(initialValueFun, 300);
  }, [countryId]);

  const onFinishFailed = () => {
    setValidationTriggered(true);
  };

  return (
    <Form
      layout="vertical"
      form={form}
      onFinish={onFinish}
      onFieldsChange={handleShowPrompt}
      disabled={!!isView}
      onFinishFailed={onFinishFailed}
      className={isView ? 'common-view-form' : 'form'}
      validateTrigger={validationTriggered ? 'onChange' : 'onSubmit'}
    >
      <Row gutter={[16, 0]}>
        <Col xs={24} sm={12} md={12} lg={6} xl={6} xxl={6}>
          <Item
            label="First Name"
            name="firstName"
            rules={[
              { ...required, message: 'Please Enter First Name!' },
              name,
              whitespaceNotAllowed,
            ]}
          >
            <Input allowClear placeholder="Enter First Name" maxLength={50} />
          </Item>
        </Col>
        <Col xs={24} sm={12} md={12} lg={6} xl={6} xxl={6}>
          <Item
            label="Last Name"
            name="lastName"
            rules={[
              { ...required, message: 'Please Enter Last Name!' },
              name,
              whitespaceNotAllowed,
            ]}
          >
            <Input allowClear placeholder="Enter Last Name" maxLength={50} />
          </Item>
        </Col>
        <Col xs={24} sm={12} md={12} lg={6} xl={6} xxl={6}>
          <Item label="User ID" name="id" rules={[required]}>
            <Input disabled />
          </Item>
        </Col>
        <Col xs={24} sm={12} md={12} lg={6} xl={6} xxl={6}>
          <Item
            label="Email ID"
            name="email"
            rules={[
              { ...required, message: 'Please Enter Email!' },
              email,
              whitespaceNotAllowed,
            ]}
          >
            <Input allowClear placeholder="Enter Email" maxLength={255} />
          </Item>
        </Col>
        <Col xs={24} sm={12} md={12} lg={6} xl={6} xxl={6}>
          <Item label="Gender" name="gender">
            <SelectComponent placeholder="Select Gender" options={GENDERS} />
          </Item>
        </Col>
        <Col xs={24} sm={12} md={12} lg={6} xl={6} xxl={6}>
          <Item
            label="Contact Details"
            name="contact"
            rules={[
              {
                ...required,
                message: 'Please Enter Contact Details!',
              },
              phone,
            ]}
          >
            <Input
              allowClear
              placeholder="Enter Contact Details"
              maxLength={12}
            />
          </Item>
        </Col>
        <Col xs={24} sm={12} md={12} lg={6} xl={6} xxl={6}>
          <Item label="Mapped Library" name="mappedLibrary">
            <Input disabled />
          </Item>
        </Col>
        <Col xs={24} sm={12} md={12} lg={6} xl={6} xxl={6}>
          <Item label="Landmark" name="landmark" rules={[whitespaceNotAllowed]}>
            <Input allowClear placeholder="Enter Landmark" maxLength={50} />
          </Item>
        </Col>
        {endUserDetail?.latLong?.coordinates?.[0] &&
          endUserDetail?.latLong?.coordinates?.[1] && (
            <>
              <Col xs={24} sm={12}>
                <Item label="LAT" name="lat">
                  <Input allowClear placeholder="Enter LAT" disabled />
                </Item>
              </Col>
              <Col xs={24} sm={12}>
                <Item label="LNG" name="lng">
                  <Input allowClear placeholder="Enter LNG" disabled />
                </Item>
              </Col>
            </>
          )}
        <Col xs={24} sm={12} md={12} lg={24} xl={24} xxl={24}>
          <Item label="Address" name="address" rules={[whitespaceNotAllowed]}>
            <Input allowClear placeholder="Enter Address" maxLength={250} />
          </Item>
        </Col>
        <Col xs={24} sm={12} md={12} lg={6} xl={6} xxl={6}>
          <Form.Item
            label="Country"
            name="country"
            rules={[{ required: true, message: 'Please Select Country!' }]}
          >
            <CountrySelect setCountryId={setCountryId} />
          </Form.Item>
        </Col>
        <Col xs={24} sm={12} md={12} lg={6} xl={6} xxl={6}>
          <Form.Item label="State" name="state" rules={[whitespaceNotAllowed]}>
            <Input allowClear placeholder="Enter State" maxLength={50} />
          </Form.Item>
        </Col>
        <Col xs={24} sm={12} md={12} lg={6} xl={6} xxl={6}>
          <Form.Item label="City" name="city" rules={[whitespaceNotAllowed]}>
            <Input allowClear placeholder="Enter City" maxLength={50} />
          </Form.Item>
        </Col>
        <Col xs={24} sm={12} md={12} lg={6} xl={6} xxl={6}>
          <Form.Item label="Pincode" name="pincode">
            <PincodeSelectName userData={endUserDetail} disabled />
          </Form.Item>
        </Col>
      </Row>
    </Form>
  );
};

export default EndUserForm;
