import { gql } from '@apollo/client';

// eslint-disable-next-line import/prefer-default-export
export const COUPONS_ADMIN = gql`
  query CouponsAdmin($filter: CouponFilter, $sort: CouponsSort) {
    couponsAdmin(filter: $filter, sort: $sort) {
      count
      data {
        value
        validTo
        validFrom
        type
        note
        name
        maxRedemptions
        library {
          libraryNumber
          id
          name
        }
        id
        description
        isArchived
        commission
        referralCouponType
        code
        createdAt
        isActive
        usedCouponCount
        isReferralCoupon
      }
    }
  }
`;

export const COUPON_REDEMPTIONS = gql`
  query CouponRedemptions(
    $where: CouponUniqueInput!
    $filter: CouponRedemptionsFilter
    $sort: CouponsSort
  ) {
    couponRedemptions(where: $where, filter: $filter, sort: $sort) {
      count
      data {
        id
        user {
          userNumber
          firstName
          lastName
        }
      }
    }
  }
`;

export const COUPON = gql`
  query Coupon($where: CouponUniqueInput!) {
    coupon(where: $where) {
      data {
        code
        commission
        description
        id
        isArchived
        isActive
        maxRedemptions
        name
        note
        type
        validFrom
        validTo
        value
        metaData {
          duration
          minimumAmount
          product {
            id
            type
            subscriptionFeatures {
              memberShipFees
            }
            name
          }
        }
        library {
          id
          name
          libraryNumber
        }
      }
    }
  }
`;
