import { Divider, Form, Input } from 'antd';
import React, { useState } from 'react';
import { NEW_ORDER_REASON } from '../../../../../common/constants';
import { formValidatorRules } from '../../../../../common/utils';
import ModalComponent from '../../../../../components/ModalComponent';
import SelectComponent from '../../../../../components/SelectComponent';

const { Item } = Form;
const { TextArea } = Input;
const { whitespaceNotAllowed } = formValidatorRules;

const AddCommentModal = ({
  isOrderAction,
  isModalOpen,
  setIsModalOpen,
  isBtnLoading,
  onFinish,
  form,
}) => {
  const [validationTriggered, setValidationTriggered] = useState(false);

  return (
    <ModalComponent
      title={isOrderAction ? 'Order not Fulfilled' : 'Order-item Not Fulfilled'}
      okText="Save"
      cancelText="Cancel"
      open={isModalOpen}
      onOk={form?.submit}
      onCancel={() => {
        setIsModalOpen(false);
        form?.resetFields();
      }}
      confirmLoading={isBtnLoading}
    >
      <Divider />
      <Form
        form={form}
        layout="vertical"
        onFinish={onFinish}
        onFinishFailed={() => setValidationTriggered(true)}
        validateTrigger={validationTriggered ? 'onChange' : 'onSubmit'}
      >
        <Item
          name="reason"
          label={
            isOrderAction
              ? 'Reason for Cancellation'
              : 'Reason for not fulfilling the order'
          }
          rules={[{ required: true, message: 'Please Select Reason!' }]}
        >
          <SelectComponent
            placeholder="Select Reason"
            showSearch={false}
            options={NEW_ORDER_REASON}
          />
        </Item>
        <Item
          name="comment"
          label="Additional Comment"
          rules={[whitespaceNotAllowed]}
        >
          <TextArea
            rows={4}
            allowClear
            placeholder="Enter Additional Comment"
            showCount
            maxLength={700}
          />
        </Item>
      </Form>
    </ModalComponent>
  );
};

export default AddCommentModal;
