import { useLazyQuery, useMutation } from '@apollo/client';
import { Button, Form, Tabs } from 'antd';
import React, { useContext, useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { AppContext } from '../../../../../AppContext';
import {
  MODULES,
  PLAN_NAMES,
  PLAN_TYPES,
  ROUTES,
} from '../../../../../common/constants';
import GoBackButton from '../../../../../components/GoBackButton';
import LoaderComponent from '../../../../../components/LoaderComponent';
import Portal from '../../../../../components/Portal';
import PopularPlanSelect from '../../../components/PopularPlanSelect';
import SubscriptionForm from '../../../components/SubscriptionForm';
import {
  SYNC_SUBSCRIPTION_PLANS,
  UPDATE_SUBSCRIPTION_PLAN,
} from '../../../graphql/Mutations';
import { SUBSCRIPTION_PLANS_ADMIN } from '../../../graphql/Queries';

const planVersion = process.env.REACT_APP_PLAN_VERSION;

const NewPlansTabs = () => {
  const [form] = Form.useForm();
  const history = useHistory();
  const location = useLocation();
  const { dispatch } = useContext(AppContext);
  const [isSyncPlanLoading, setIsSyncPlanLoading] = useState(false);
  const [isSync, setIsSync] = useState(false);
  const [activeTabKey, setActiveTabKey] = useState('');
  const [planList, setPlanList] = useState([]);
  const [isPlanLoading, setIsPlanLoading] = useState(true);
  const [isEdit, setIsEdit] = useState(false);
  const [isDisabled, setIsDisabled] = useState(true);
  const [btnLoading, setBtnLoading] = useState(false);

  const [subscriptionPlansAdmin] = useLazyQuery(SUBSCRIPTION_PLANS_ADMIN, {
    onCompleted: (response) => {
      setPlanList(response?.subscriptionPlansAdmin?.data);
      setIsPlanLoading(false);
    },
    fetchPolicy: 'network-only',
    onError() {},
  });

  const [updateSubscriptionPlan] = useMutation(UPDATE_SUBSCRIPTION_PLAN, {
    onError: () => {},
  });

  const [syncSubscriptionPlans] = useMutation(SYNC_SUBSCRIPTION_PLANS, {
    onError: () => {},
  });

  const handleSync = async () => {
    setIsSyncPlanLoading(true);
    const res = await syncSubscriptionPlans();
    if (res?.data) setIsSync(!isSync);
    setIsSyncPlanLoading(false);
  };

  useEffect(() => {
    setIsPlanLoading(true);
    subscriptionPlansAdmin({
      variables: {
        filter: {
          planVersion: `v${planVersion}`,
        },
      },
    });
  }, [isEdit]);

  const onFinish = async (values) => {
    setBtnLoading(true);
    const planDetails = planList.find(
      (plan) =>
        `${PLAN_NAMES?.[plan?.name]?.value?.toLowerCase()}-${PLAN_TYPES?.[
          plan?.type
        ]?.value?.toLowerCase()}` === activeTabKey,
    );
    const response = await updateSubscriptionPlan({
      variables: {
        where: {
          id: planDetails?.id,
        },
        data: {
          bonusDeliveries: parseInt(values?.bonusDeliveries, 10),
          totalKeepCountPerMonth: parseInt(values?.holdingToys, 10),
          totalFreeDeliveryPerMonth: parseInt(values?.freeDeliveries, 10),
          trialApplied: values?.trialApplied,
          membershipFeesApplied: values?.membershipFeesApplied,
          securityDepositApplied: values?.securityDepositApplied,
          trialDays: parseInt(values?.trialDays, 10),
          trialAmount: parseInt(values?.trialAmount, 10),
          actualChargesForSecurityDeposit: parseInt(
            values?.actualChargesForSecurityDeposit,
            10,
          ),
          damageWaiverFeesApplied: values?.damageWaiverFeesApplied,
          damageWaiverFees: parseInt(values?.damageWaiverFees, 10),
          actualChargesForDamageWaiverFees: parseInt(
            values?.actualChargesForDamageWaiverFees,
            10,
          ),
          actualChargesForMemberShipFees: parseInt(
            values?.actualChargesForMemberShipFees,
            10,
          ),
          isUnlimitedFreeDelivery: values?.isUnlimitedFreeDelivery,
        },
      },
    });
    if (response?.data) {
      setIsPlanLoading(true);
      setBtnLoading(false);
      setIsDisabled(true);
      dispatch({
        type: 'SET_SHOW_PROMPT',
        data: false,
      });
      subscriptionPlansAdmin({
        variables: {
          filter: {
            planVersion: `v${planVersion}`,
          },
        },
      });
    }
  };

  const handleDiscard = () => {
    setIsPlanLoading(true);
    subscriptionPlansAdmin({
      variables: {
        filter: {
          planVersion: `v${planVersion}`,
        },
      },
    });
    setIsDisabled(true);
    dispatch({
      type: 'SET_SHOW_PROMPT',
      data: false,
    });
  };

  const getTabKey = (plan) =>
    `${PLAN_NAMES?.[plan?.name]?.value?.toLowerCase()}-${PLAN_TYPES?.[
      plan?.type
    ]?.value?.toLowerCase()}`;

  const tabList = planList?.map((plan) => ({
    key: getTabKey(plan),
    label: `${PLAN_NAMES?.[plan?.name]?.key} - ${
      PLAN_TYPES?.[plan?.type]?.key
    }`,
    children: (
      <SubscriptionForm
        form={form}
        planName={plan?.name}
        planType={plan?.type}
        isView={!isEdit}
        planDetails={plan}
        onFinish={onFinish}
        setIsDisabled={setIsDisabled}
        isNewPlan
      />
    ),
  }));

  useEffect(() => {
    const activeTab =
      tabList.find((tab) => location?.pathname?.includes(tab?.key)) ||
      tabList[0];
    setIsEdit(location?.pathname?.includes('/edit'));
    setActiveTabKey(activeTab?.key);
    const newPlanPath = `${ROUTES.SUBSCRIPTION_PLANS}${ROUTES.NEW_PLANS}`;
    if (location?.pathname === newPlanPath) {
      history.push(
        `${newPlanPath}${activeTab?.key ? `/${activeTab?.key}` : ''}`,
      );
    }
  }, [location, tabList]);

  const onTabChange = (key) => {
    setActiveTabKey(key);
    const path = `${ROUTES.SUBSCRIPTION_PLANS}${ROUTES.NEW_PLANS}/${key}${
      isEdit ? '/edit' : ''
    }`;
    history.push(path);
  };

  return (
    <>
      {!isEdit ? (
        <>
          <Portal portalId="header-title-content">
            <div className="portal-header">{MODULES?.NEW_PLANS}</div>
          </Portal>
          <Portal portalId="header-right-content">
            <div className="header-btn-wrapper">
              <PopularPlanSelect />
              <Button
                loading={isSyncPlanLoading}
                className="common-button mr-16"
                size="middle"
                onClick={handleSync}
              >
                Sync Plan
              </Button>
              <Button
                className="common-button mr-16"
                size="middle"
                onClick={() => {
                  setIsEdit(true);
                  history?.push(
                    `${ROUTES?.SUBSCRIPTION_PLANS}${ROUTES?.NEW_PLANS}/${activeTabKey}/edit`,
                  );
                }}
              >
                Edit Plan
              </Button>
            </div>
          </Portal>
          <Portal portalId="header-right-content-phones">
            <div className="header-btn-wrapper">
              <PopularPlanSelect />
              <Button
                loading={isSyncPlanLoading}
                className="common-button mr-16"
                size="middle"
                onClick={handleSync}
              >
                Sync Plan
              </Button>
              <Button
                className="common-button mr-16"
                size="middle"
                onClick={() => {
                  setIsEdit(true);
                  history?.push(
                    `${ROUTES?.SUBSCRIPTION_PLANS}${ROUTES?.NEW_PLANS}/${activeTabKey}/edit`,
                  );
                }}
              >
                Edit Plan
              </Button>
            </div>
          </Portal>
        </>
      ) : (
        <>
          <Portal portalId="header-title-content">
            <GoBackButton customLink={ROUTES?.SUBSCRIPTION_PLANS} />
            <div className="portal-header">Edit {MODULES?.NEW_PLANS}</div>
          </Portal>
          <Portal portalId="header-right-content">
            <div className="header-btn-wrapper">
              <Button
                type="primary"
                className="common-button mr-16"
                size="middle"
                disabled={btnLoading || isDisabled}
                onClick={handleDiscard}
              >
                Discard Changes
              </Button>
              <Button
                className="common-button"
                size="middle"
                onClick={form.submit}
                disabled={isDisabled}
              >
                Save
              </Button>
            </div>
          </Portal>
          <Portal portalId="header-right-content-phones">
            <div className="header-btn-wrapper">
              <Button
                type="primary"
                className="common-button mr-16"
                size="middle"
                disabled={btnLoading || isDisabled}
                onClick={handleDiscard}
              >
                Discard Changes
              </Button>
              <Button
                className="common-button"
                size="middle"
                onClick={form.submit}
                disabled={isDisabled}
              >
                Save
              </Button>
            </div>
          </Portal>
        </>
      )}
      <div className="card-container">
        {isPlanLoading ? (
          <LoaderComponent
            size="large"
            setHeight="60"
            spinning={isPlanLoading}
          />
        ) : (
          <Tabs
            activeKey={activeTabKey}
            type="card"
            className="full-width"
            onChange={(key) => onTabChange(key)}
            items={tabList}
            destroyInactiveTabPane
          />
        )}
      </div>
    </>
  );
};

export default NewPlansTabs;
