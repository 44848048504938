import { CopyOutlined, FilterFilled, FilterOutlined } from '@ant-design/icons';
import { useLazyQuery, useMutation } from '@apollo/client';
import {
  Badge,
  Button,
  Checkbox,
  DatePicker,
  Divider,
  Empty,
  Form,
  Modal,
  Radio,
  Switch,
  Tag,
  Tooltip,
} from 'antd';
import { debounce, filter, forEach, isEmpty, map } from 'lodash';
import React, { useContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { AppContext } from '../../../../../AppContext';
import { ReactComponent as ArchiveIcon } from '../../../../../assets/svg/box-archive-solid.svg';
import { ReactComponent as EditIcon } from '../../../../../assets/svg/pen-to-square-solid.svg';
import { ReactComponent as ActiveIcon } from '../../../../../assets/svg/tag-solid.svg';
import {
  ARCHIVING_STATE,
  BREAKPOINTS,
  COUPON_TYPE,
  DATE_FORMATS,
  LIMIT,
  PERMISSIONS_KEY,
  PERMISSION_TYPE,
  REFERRAL_TYPE,
  ROUTES,
  SORT,
  STATUS,
  USER_FORM_STATUS,
} from '../../../../../common/constants';
import {
  formatDate,
  handleCopy,
  hasPermission,
} from '../../../../../common/utils';
import CommonTable from '../../../../../components/CommonTable';
import LoaderComponent from '../../../../../components/LoaderComponent';
import SearchComponent from '../../../../../components/SearchComponent';
import { LIBRARIES } from '../../../../../libraryModules/raiseRequest/graphql/Queries';
import { COUPON_REFERRAL_LINK, UPDATE_COUPON } from '../graphql/Mutations';
import { COUPONS_ADMIN } from '../graphql/Queries';
import CouponModal from './CouponModal';
import ShareModal from './ShareModal';
import UsedByModal from './UsedByModal';

const { RangePicker } = DatePicker;
const { Group } = Radio;

const initialPaginationValue = {
  total: 0,
  current: 1,
  pageSize: 10,
};

const initialFilter = {
  limit: LIMIT,
  search: '',
  skip: 0,
};

const initialSort = {
  field: 'createdAt',
  order: 'DESC',
};

const initialTableFilter = {
  skip: 0,
  limit: LIMIT,
  search: '',
};

const initialTableSort = {
  field: 'createdAt',
  order: 'DESC',
};

let filterScrollDebounceJob = null;

const CouponTable = ({ isSync, exportFilter, setExportFilter }) => {
  const {
    state: {
      permissions,
      commonPermissions: { isLibraryPermission },
    },
  } = useContext(AppContext);
  const history = useHistory();
  const [form] = Form.useForm();
  const [couponList, setCouponList] = useState([]);
  const [couponSearchTerm, setGiftCardSearchTerm] = useState('');
  const [isEmptyCouponList, setIsEmptyCouponList] = useState(false);
  const [coupon, setCoupon] = useState({});
  const [query, setQuery] = useState('');
  const [sortedInfo, setSortedInfo] = useState({});
  const [paginationProp, setPaginationProp] = useState(initialPaginationValue);
  const [isArchivePrompts, setIsArchivePrompts] = useState(false);
  const [isCouponModalOpen, setIsCouponModalOpen] = useState(false);
  const [isCouponBtnLoading, setIsCouponBtnLoading] = useState(false);
  const [isActivePrompts, setIsActivePrompts] = useState(false);
  const [isActiveLoading, setIsActiveLoading] = useState(false);
  const [isUsedByModalOpen, setIsUsedByModalOpen] = useState();

  const [isCouponLoading, setIsCouponLoading] = useState(true);
  const [isArchiveLoading, setIsArchiveLoading] = useState(false);
  const [isRequestPrompts, setIsRequestPrompts] = useState(false);
  const [isRequestLoadings, setIsRequestLoadings] = useState(false);
  const [isShareModalOpen, setIsShareModalOpen] = useState(false);
  const [isShareLoading, setIsShareLoading] = useState(false);
  const [shareTitle, setShareTitle] = useState(false);
  const [shareUrl, setShareUrl] = useState(false);

  const [isArchiveFilter, setIsArchiveFilter] = useState(false);
  const [filterSearch, setFilterSearch] = useState('');
  const [filterList, setFilterList] = useState([]);
  const [whereFilter, setWhereFilter] = useState();
  const [filterLoading, setFilterLoading] = useState(false);
  const [filterTags, setFilterTags] = useState(null);
  const [filterVisible, setFilterVisible] = useState(false);
  const [filterIndex, setFilterIndex] = useState(null);
  const [finalWhereFilters, setFinalWhereFilters] = useState();
  const [finalFilterTags, setFinalFilterTags] = useState(null);
  const [filterIsEnd, setFilterIsEnd] = useState(false);
  const [isEmptyFilterList, setIsEmptyFilterList] = useState(false);
  const [referralType, setReferralType] = useState(null);
  const [isDisabled, setIsDisabled] = useState(true);

  const [updateCoupon] = useMutation(UPDATE_COUPON, {
    onError: () => {},
  });

  const [couponReferralLink] = useMutation(COUPON_REFERRAL_LINK, {
    onError: () => {},
  });

  const [couponsAdmin] = useLazyQuery(COUPONS_ADMIN, {
    onCompleted: (response) => {
      setCouponList([...response?.couponsAdmin?.data]);
      if (
        response?.couponsAdmin?.count === 0 &&
        initialPaginationValue?.total === 0
      ) {
        setIsEmptyCouponList(true);
      } else {
        setIsEmptyCouponList(false);
      }
      const pagination = {
        ...paginationProp,
        defaultPageSize: LIMIT,
        total: response?.couponsAdmin?.count,
      };
      setPaginationProp(pagination);
      setIsCouponLoading(false);
    },
    fetchPolicy: 'network-only',
    onError() {},
  });

  const [libraries, { loading: isLibrariesLoading }] = useLazyQuery(LIBRARIES, {
    onCompleted: (response) => {
      if (response?.libraries?.data?.length < LIMIT) {
        setFilterIsEnd(true);
      }
      if (response?.libraries?.count === 0) {
        setIsEmptyFilterList(true);
      } else {
        setIsEmptyFilterList(false);
      }
      const optionsCopy = [];
      forEach(response?.libraries?.data, (item) =>
        optionsCopy?.push({ label: item?.name, key: item?.id }),
      );
      setFilterList([...filterList, ...optionsCopy]);
      setFilterLoading(false);
    },
    fetchPolicy: 'network-only',
    onError() {
      setFilterLoading(false);
    },
  });

  const hasUpdatePermission = hasPermission(
    PERMISSIONS_KEY?.OFFERS_COUPONS,
    PERMISSION_TYPE?.UPDATE,
    permissions,
  );

  useEffect(() => {
    setPaginationProp({ ...paginationProp, current: 1, skip: 0 });
    setIsCouponLoading(true);
    couponsAdmin({
      variables: {
        filter: {
          ...initialFilter,
          limit: paginationProp?.pageSize || LIMIT,
          search: couponSearchTerm,
          isArchived: isArchiveFilter,
          dateRange: {
            from: finalWhereFilters?.createdAt?.from?.$d,
            to: finalWhereFilters?.createdAt?.to?.$d,
          },
          type: finalWhereFilters?.type,
          libraryIds: finalWhereFilters?.name,
          isActive: finalWhereFilters?.status,
        },
        sort: sortedInfo?.column
          ? {
              field: sortedInfo?.field,
              order: sortedInfo?.order === 'ascend' ? SORT?.ASC : SORT?.DESC,
            }
          : initialSort,
      },
    });
    setExportFilter({ ...exportFilter, isArchived: isArchiveFilter });
  }, [isArchiveFilter, isSync]);

  const handleTableChange = (pagination, tableFilter, sorter) => {
    const { current } = pagination;
    const skip = (current - 1) * (pagination?.pageSize || 0);
    setPaginationProp({ ...paginationProp, ...pagination });
    setIsCouponLoading(true);
    setSortedInfo(sorter);
    couponsAdmin({
      variables: {
        filter: {
          ...initialFilter,
          skip,
          limit: pagination?.pageSize,
          search: couponSearchTerm,
          isArchived: isArchiveFilter,
          dateRange: {
            from: finalWhereFilters?.createdAt?.from?.$d,
            to: finalWhereFilters?.createdAt?.to?.$d,
          },
          type: finalWhereFilters?.type,
          libraryIds: finalWhereFilters?.name,
          isActive: finalWhereFilters?.status,
        },
        sort: sorter?.column
          ? {
              field: sorter?.field,
              order: sorter?.order === 'ascend' ? SORT?.ASC : SORT?.DESC,
            }
          : initialSort,
      },
    });
  };

  const handleSearch = (value) => {
    const trimValue = value?.trim();
    setGiftCardSearchTerm(trimValue);
    setPaginationProp({ ...paginationProp, current: 1, skip: 0 });
    setIsCouponLoading(true);
    couponsAdmin({
      variables: {
        filter: {
          ...initialFilter,
          limit: paginationProp?.pageSize || LIMIT,
          search: trimValue,
          isArchived: isArchiveFilter,
          dateRange: {
            from: finalWhereFilters?.createdAt?.from?.$d,
            to: finalWhereFilters?.createdAt?.to?.$d,
          },
          type: finalWhereFilters?.type,
          libraryIds: finalWhereFilters?.name,
          isActive: finalWhereFilters?.status,
        },
        sort: sortedInfo?.column
          ? {
              field: sortedInfo?.field,
              order: sortedInfo?.order === 'ascend' ? SORT?.ASC : SORT?.DESC,
            }
          : initialSort,
      },
    });
    setExportFilter({ ...exportFilter, search: trimValue });
  };

  const handleArchive = async (id, isArchived) => {
    setIsArchiveLoading(true);
    const response = await updateCoupon({
      variables: {
        where: {
          id,
        },
        data: {
          isArchived: !isArchived,
        },
      },
    });
    if (response?.data) {
      setIsArchiveLoading(false);
      setIsCouponLoading(true);
      setIsArchivePrompts(false);
      couponsAdmin({
        variables: {
          filter: {
            ...initialFilter,
            skip:
              (paginationProp?.current - 1) * (paginationProp?.pageSize || 0),
            limit: paginationProp?.pageSize || LIMIT,
            search: couponSearchTerm,
            isArchived: isArchiveFilter,
            dateRange: {
              from: finalWhereFilters?.createdAt?.from?.$d,
              to: finalWhereFilters?.createdAt?.to?.$d,
            },
            type: finalWhereFilters?.type,
            libraryIds: finalWhereFilters?.name,
            isActive: finalWhereFilters?.status,
          },
          sort: {
            ...initialSort,
            field: sortedInfo?.field || 'createdAt',
            order: sortedInfo?.order === 'ascend' ? SORT?.ASC : SORT?.DESC,
          },
        },
      });
    }
    setIsArchiveLoading(false);
  };

  const handleActive = async (id, isActive) => {
    setIsActiveLoading(true);
    const response = await updateCoupon({
      variables: {
        where: {
          id,
        },
        data: {
          isActive: !isActive,
        },
      },
    });
    if (response?.data) {
      setIsActiveLoading(false);
      setIsCouponLoading(true);
      setIsActivePrompts(false);
      couponsAdmin({
        variables: {
          filter: {
            ...initialFilter,
            skip:
              (paginationProp?.current - 1) * (paginationProp?.pageSize || 0),
            limit: paginationProp?.pageSize || LIMIT,
            search: couponSearchTerm,
            isArchived: isArchiveFilter,
            dateRange: {
              from: finalWhereFilters?.createdAt?.from?.$d,
              to: finalWhereFilters?.createdAt?.to?.$d,
            },
            type: finalWhereFilters?.type,
            libraryIds: finalWhereFilters?.name,
            isActive: finalWhereFilters?.status,
          },
          sort: {
            ...initialSort,
            field: sortedInfo?.field || 'createdAt',
            order: sortedInfo?.order === 'ascend' ? SORT?.ASC : SORT?.DESC,
          },
        },
      });
    }
    setIsActiveLoading(false);
  };

  const handleMarkAsReferral = async () => {
    setIsRequestLoadings(true);
    const response = await updateCoupon({
      variables: {
        where: {
          id: coupon?.id,
        },
        data: {
          referralCouponType: referralType,
        },
      },
    });
    if (response?.data) {
      setIsCouponLoading(true);
      setIsRequestPrompts(false);
      form?.resetFields();
      couponsAdmin({
        variables: {
          filter: {
            ...initialFilter,
            skip:
              (paginationProp?.current - 1) * (paginationProp?.pageSize || 0),
            limit: paginationProp?.pageSize || LIMIT,
            search: couponSearchTerm,
            isArchived: isArchiveFilter,
            dateRange: {
              from: finalWhereFilters?.createdAt?.from?.$d,
              to: finalWhereFilters?.createdAt?.to?.$d,
            },
            type: finalWhereFilters?.type,
            libraryIds: finalWhereFilters?.name,
            isActive: finalWhereFilters?.status,
          },
          sort: {
            ...initialSort,
            field: sortedInfo?.field || 'createdAt',
            order: sortedInfo?.order === 'ascend' ? SORT?.ASC : SORT?.DESC,
          },
        },
      });
    }
    setIsRequestLoadings(false);
  };

  const handleCoupon = async (values) => {
    setIsCouponBtnLoading(true);
    const response = await updateCoupon({
      variables: {
        where: {
          id: coupon?.id,
        },
        data: {
          libraryId: !coupon?.library?.id ? values?.libraryName : undefined,
          commission: values?.commission?.trim(),
          note: values?.note?.trim(),
          description: values?.description?.trim(),
        },
      },
    });
    if (response?.data) {
      setIsCouponModalOpen(false);
      setIsCouponLoading(true);
      setIsDisabled(true);
      setCoupon({});
      couponsAdmin({
        variables: {
          filter: {
            ...initialFilter,
            skip:
              (paginationProp?.current - 1) * (paginationProp?.pageSize || 0),
            limit: paginationProp?.pageSize || LIMIT,
            search: couponSearchTerm,
            isArchived: isArchiveFilter,
            dateRange: {
              from: finalWhereFilters?.createdAt?.from?.$d,
              to: finalWhereFilters?.createdAt?.to?.$d,
            },
            type: finalWhereFilters?.type,
            libraryIds: finalWhereFilters?.name,
            isActive: finalWhereFilters?.status,
          },
          sort: {
            ...initialSort,
            field: sortedInfo?.field || 'createdAt',
            order: sortedInfo?.order === 'ascend' ? SORT?.ASC : SORT?.DESC,
          },
        },
      });
    }
    setIsCouponBtnLoading(false);
  };

  const handleShare = async (code) => {
    setIsShareLoading(true);
    const response = await couponReferralLink({
      variables: {
        where: {
          code,
        },
      },
    });
    if (response?.data) {
      setShareUrl(response?.data?.couponReferralLink?.data?.url);
      setShareTitle(
        `Parents, stop buying toys! Try the Elefant Toy Library instead.\nBecome a member today & use my code ${code} at checkout to get Discount!\nClick the link & become a member!`,
      );
      setIsShareLoading(false);
    }
  };

  const getFilterData = (confirm) => {
    setIsCouponLoading(true);
    couponsAdmin({
      variables: {
        filter: {
          ...initialFilter,
          limit: paginationProp?.pageSize || LIMIT,
          search: couponSearchTerm,
          isArchived: isArchiveFilter,
          dateRange: {
            from: whereFilter?.createdAt?.from?.$d,
            to: whereFilter?.createdAt?.to?.$d,
          },
          type:
            whereFilter?.type === COUPON_TYPE?.FLAT?.value ||
            COUPON_TYPE?.PERCENT?.value
              ? whereFilter?.type
              : undefined,
          libraryIds: whereFilter?.name,
          isActive:
            whereFilter?.status === true || whereFilter?.status === false
              ? whereFilter?.status
              : undefined,
        },
        sort: sortedInfo?.column
          ? {
              field: sortedInfo?.field,
              order: sortedInfo?.order === 'ascend' ? SORT?.ASC : SORT?.DESC,
            }
          : initialSort,
      },
    });
    setFinalWhereFilters({ ...finalWhereFilters, ...whereFilter });
    setExportFilter({ ...exportFilter, ...finalWhereFilters, ...whereFilter });
    setFinalFilterTags(filterTags);
    setPaginationProp({ ...paginationProp, skip: 0, current: 1 });
    if (confirm) {
      confirm();
    }
  };

  const handleReset = (clearFilters, dataIndex, confirm) => {
    const tagsCopy = {
      ...filterTags,
      [dataIndex]: [],
    };
    let whereFiltersCopy;
    if (dataIndex === 'type' || dataIndex === 'status') {
      whereFiltersCopy = {
        ...whereFilter,
        [dataIndex]: null,
      };
    } else {
      whereFiltersCopy = {
        ...whereFilter,
        [dataIndex]: [],
      };
    }
    setFilterVisible(false);
    setFinalFilterTags({ ...filterTags, ...tagsCopy });
    setFinalWhereFilters({ ...finalWhereFilters, ...whereFiltersCopy });
    setExportFilter({
      ...exportFilter,
      ...finalWhereFilters,
      ...whereFiltersCopy,
    });
    setIsCouponLoading(true);
    couponsAdmin({
      variables: {
        filter: {
          ...initialFilter,
          limit: paginationProp?.pageSize || LIMIT,
          search: couponSearchTerm,
          isArchived: isArchiveFilter,
          dateRange: {
            from: whereFiltersCopy?.createdAt?.from?.$d,
            to: whereFiltersCopy?.createdAt?.to?.$d,
          },
          type:
            whereFiltersCopy?.type === COUPON_TYPE?.FLAT?.value ||
            COUPON_TYPE?.PERCENT?.value
              ? whereFiltersCopy?.type
              : undefined,
          libraryIds: whereFiltersCopy?.name,
          isActive:
            whereFiltersCopy?.status === true ||
            whereFiltersCopy?.status === false
              ? whereFiltersCopy?.status
              : undefined,
        },
        sort: sortedInfo?.column
          ? {
              field: sortedInfo?.field,
              order: sortedInfo?.order === 'ascend' ? SORT?.ASC : SORT?.DESC,
            }
          : initialSort,
      },
    });
    setPaginationProp({ ...paginationProp, skip: 0, current: 1 });
    clearFilters();
    setFilterVisible(false);
    if (confirm) {
      confirm();
    }
  };

  useEffect(() => {
    if (filterVisible) {
      setFilterList([]);
      setFilterLoading(true);
      setIsEmptyFilterList(false);
      setFilterTags({ ...filterTags, ...finalFilterTags });
      setWhereFilter({ ...whereFilter, ...finalWhereFilters });
      setFilterIsEnd(false);

      switch (filterIndex) {
        case 'name':
          libraries({
            variables: {
              filter: initialTableFilter,
              sort: initialTableSort,
            },
          });
          break;

        default:
          break;
      }
    }
    if (!filterVisible) {
      setFilterTags(finalFilterTags);
      setWhereFilter(finalWhereFilters);
      setFilterSearch('');
    }
  }, [filterVisible]);

  const onFilterScroll = (event, dataIndex) => {
    if (filterScrollDebounceJob) {
      filterScrollDebounceJob?.cancel();
      filterScrollDebounceJob = null;
    }
    if (!isLibrariesLoading && !filterIsEnd) {
      const target = event?.target;
      const currentLength = filterList?.length;
      if (
        target?.scrollTop + target?.offsetHeight >=
        target?.scrollHeight - 5
      ) {
        filterScrollDebounceJob = debounce(() => {
          setFilterLoading(true);
          switch (dataIndex) {
            case 'name':
              libraries({
                variables: {
                  filter: {
                    limit: LIMIT,
                    skip: currentLength,
                    search: filterSearch,
                  },
                  sort: initialTableSort,
                },
              });
              break;

            default:
              break;
          }
        }, 500);
        filterScrollDebounceJob();
      }
    }
  };

  const handleFilterSearch = (value, dataIndex) => {
    setFilterSearch(value);
    setFilterIsEnd(false);
    setFilterLoading(true);
    setFilterList([]);
    switch (dataIndex) {
      case 'name':
        libraries({
          variables: {
            filter: {
              ...initialTableFilter,
              limit: LIMIT,
              search: value,
            },
            sort: initialTableSort,
          },
        });
        break;

      default:
        break;
    }
  };

  const changeFilter = (newFilterObj, dataIndex) => {
    const {
      target: { value },
    } = newFilterObj;

    const filterTagsCopy = { ...filterTags };
    let newTagList;
    if (whereFilter?.[dataIndex]?.includes(value?.key)) {
      newTagList = {
        ...filterTagsCopy,
        [dataIndex]: filter(filterTagsCopy?.[dataIndex], (item) => {
          if (item?.label) {
            return item?.key !== value?.key;
          }
          return item !== value;
        }),
      };
    } else {
      newTagList = {
        ...filterTagsCopy,
        [dataIndex]: filterTagsCopy?.[dataIndex]
          ? [...filterTagsCopy?.[dataIndex], value]
          : [value],
      };
    }

    const updatedWhereFilter = {
      ...whereFilter,
      [dataIndex]: newTagList?.[dataIndex]?.map((item) => item?.key),
    };
    setWhereFilter(updatedWhereFilter);
    setFilterTags(newTagList);
  };

  const handleDeleteFilter = (value, dataIndex) => {
    let newTagList;

    if (whereFilter?.[dataIndex]?.includes(value?.key)) {
      newTagList = {
        ...filterTags,
        [dataIndex]: filter(filterTags?.[dataIndex], (item) => {
          if (item?.key) {
            return item?.key !== value?.key;
          }
          return item !== value;
        }),
      };
    }

    const updatedWhereFilter = {
      ...whereFilter,
      [dataIndex]: newTagList?.[dataIndex]?.map((item) => item?.key),
    };
    setWhereFilter(updatedWhereFilter);
    setFilterTags(newTagList);
  };

  const onRangePickerChange = (values, dataIndex) => {
    const rangeObj = {
      from: values[0]?.startOf('day'),
      to: values?.[1]?.endOf('day'),
    };

    const filtersCopy = {
      ...whereFilter,
      [dataIndex]: rangeObj,
    };
    if (!values?.length) {
      delete whereFilter?.[dataIndex];
    }
    setWhereFilter(filtersCopy);
  };

  const onRadioChange = (value, dataIndex) => {
    const filtersCopy = {
      ...whereFilter,
      [dataIndex]: value,
    };
    if (!value?.length) {
      delete whereFilter?.[dataIndex];
    }
    setWhereFilter(filtersCopy);
  };

  const filterPopup = (dataIndex, showSearch = true) => ({
    filterDropdown: ({ confirm, clearFilters }) => {
      if (dataIndex === 'createdAt') {
        return (
          <div className="custom-filter-dropdown">
            <RangePicker
              allowClear={false}
              format={DATE_FORMATS?.REGULAR}
              onChange={(values) => onRangePickerChange(values, dataIndex)}
              value={
                whereFilter?.[dataIndex]?.from
                  ? [
                      whereFilter?.[dataIndex]?.from,
                      whereFilter?.[dataIndex]?.to,
                    ]
                  : null
              }
            />
            <Divider className="divider-filter" />
            <div className="d-flex justify-center">
              <Button
                size="small"
                className="reset-button"
                id="roles-filter-reset"
                onClick={() => handleReset(clearFilters, dataIndex, confirm)}
              >
                Reset
              </Button>
              <Button
                size="small"
                className="common-button ok-button"
                id="roles-filter-ok"
                type="primary"
                onClick={() => getFilterData(confirm, dataIndex)}
              >
                Ok
              </Button>
            </div>
          </div>
        );
      }

      if (dataIndex === 'type') {
        return (
          <div className="custom-filter-dropdown">
            <Group
              options={map(COUPON_TYPE, (item) => ({
                label: item?.label,
                value: item?.value,
              }))}
              value={whereFilter?.[dataIndex]}
              onChange={(e) => onRadioChange(e?.target?.value, dataIndex)}
            />
            <Divider className="divider-filter" />
            <div className="d-flex justify-center">
              <Button
                size="small"
                className="reset-button"
                id="roles-filter-reset"
                onClick={() => handleReset(clearFilters, dataIndex, confirm)}
              >
                Reset
              </Button>
              <Button
                size="small"
                className="common-button ok-button"
                id="roles-filter-ok"
                type="primary"
                onClick={() => getFilterData(confirm, dataIndex)}
              >
                Ok
              </Button>
            </div>
          </div>
        );
      }

      if (dataIndex === 'status') {
        return (
          <div className="custom-filter-dropdown">
            <Group
              options={USER_FORM_STATUS}
              value={whereFilter?.[dataIndex]}
              onChange={(e) => onRadioChange(e?.target?.value, dataIndex)}
            />
            <Divider className="divider-filter" />
            <div className="d-flex justify-center">
              <Button
                size="small"
                className="reset-button"
                id="roles-filter-reset"
                onClick={() => handleReset(clearFilters, dataIndex, confirm)}
              >
                Reset
              </Button>
              <Button
                size="small"
                className="common-button ok-button"
                id="roles-filter-ok"
                type="primary"
                onClick={() => getFilterData(confirm, dataIndex)}
              >
                Ok
              </Button>
            </div>
          </div>
        );
      }

      return (
        <div className="custom-filter-dropdown">
          <LoaderComponent
            spinning={filterLoading}
            setHeight={35}
            size="medium"
          >
            {showSearch && (
              <SearchComponent
                className="filter-search"
                id="search-container-id-roles"
                placeholder="Search..."
                name={dataIndex}
                query={filterSearch}
                setQuery={setFilterSearch}
                getData={(value) => handleFilterSearch(value, dataIndex)}
              />
            )}
            {filterTags?.[dataIndex]?.length > 0 && (
              <div className="filter-tags-wrapper">
                {map(filterTags?.[dataIndex], (item) => (
                  <Tag
                    key={item?.key}
                    closable
                    onClose={() => handleDeleteFilter(item, dataIndex)}
                    className="filter-tag"
                  >
                    <span>{item?.label}</span>
                  </Tag>
                ))}
              </div>
            )}
            <div
              className="filter-checkboxes"
              onScroll={(e) => onFilterScroll(e, dataIndex)}
            >
              {filterList?.length > 0
                ? map(filterList, (item) => (
                    <div className="single-checkbox-div" key={item?.key}>
                      <Checkbox
                        value={item}
                        checked={whereFilter?.[dataIndex]?.includes(item?.key)}
                        key={item?.key || item}
                        onChange={(e) => changeFilter(e, dataIndex)}
                        className="single-checkbox"
                      >
                        <span>{item?.label}</span>
                      </Checkbox>
                    </div>
                  ))
                : isEmptyFilterList && (
                    <Empty image={Empty?.PRESENTED_IMAGE_SIMPLE} />
                  )}
            </div>
          </LoaderComponent>
          <Divider className="divider-filter" />
          <div className="d-flex justify-center">
            <Button
              size="small"
              className="reset-button"
              id="roles-filter-reset"
              onClick={() => handleReset(clearFilters, dataIndex, confirm)}
            >
              Reset
            </Button>
            <Button
              size="small"
              className="common-button ok-button"
              id="roles-filter-ok"
              type="primary"
              onClick={() => getFilterData(confirm, dataIndex)}
            >
              Ok
            </Button>
          </div>
        </div>
      );
    },
    filterIcon: () =>
      finalWhereFilters?.[dataIndex]?.length > 0 ||
      !isEmpty(finalWhereFilters?.[dataIndex]) ||
      finalWhereFilters?.[dataIndex] === true ||
      finalWhereFilters?.[dataIndex] === false ? (
        <FilterFilled className="filter-icon filter-filled" />
      ) : (
        <FilterOutlined className="filter-icon" />
      ),
    onFilterDropdownOpenChange: (visible) => {
      setFilterIndex(dataIndex);
      setFilterVisible(visible);
    },
  });

  const columns = [
    {
      title: 'CODE',
      dataIndex: 'code',
      key: 'code',
      ellipsis: true,
      width: 200,
      align: 'left',
      // eslint-disable-next-line no-undef
      fixed: window.innerWidth > BREAKPOINTS.desktop ? 'left' : false,
      className: 'max-width-column',
      onCell: () => ({
        onClick: (e) => e?.stopPropagation(),
      }),
      render: (_, record) => (
        <div>
          <span className="pr-8">{record?.code}</span>
          <CopyOutlined
            className="copy-icon"
            onClick={() => handleCopy(record?.code)}
          />
        </div>
      ),
    },
    {
      title: 'NAME',
      dataIndex: 'name',
      key: 'name',
      ellipsis: true,
      width: 250,
      align: 'left',
      className: 'max-width-column',
      render: (_, record) => record?.name || '-',
    },
    {
      title: 'LIBRARY NAME',
      dataIndex: 'name',
      key: 'name',
      ellipsis: true,
      width: 250,
      align: 'left',
      className: `max-width-column`,
      onCell: (record) => ({
        onClick: (e) => {
          e?.stopPropagation();
          return (
            isLibraryPermission &&
            record?.library?.id &&
            history?.push(
              `${ROUTES?.MASTER_LISTS}${ROUTES?.LIBRARY}${ROUTES?.BASIC_DETAILS}/view/${record?.library?.id}/${record?.library?.libraryNumber}`,
            )
          );
        },
        className: `${
          isLibraryPermission && record?.library?.id && 'pointer table-cell'
        }`,
      }),
      render: (_, record) =>
        record?.library?.id ? (
          <>
            <div className="table-data-with-id">{record?.library?.name}</div>
            <div className="font-size-12">
              ({record?.library?.libraryNumber})
            </div>
          </>
        ) : (
          '-'
        ),
      ...filterPopup('name'),
    },
    {
      title: 'COMMISSION',
      dataIndex: 'commission',
      key: 'commission',
      ellipsis: true,
      width: 150,
      align: 'left',
      className: 'max-width-column',
      render: (_, record) => record?.commission || '-',
    },
    {
      title: 'MAXIMUM REDEMPTIONS',
      dataIndex: 'maxRedemptions',
      key: 'maxRedemptions',
      ellipsis: true,
      width: 150,
      align: 'left',
      className: 'max-width-column',
      render: (_, record) => record?.maxRedemptions || '-',
    },
    {
      title: 'START ON',
      dataIndex: 'validFrom',
      key: 'validFrom',
      width: 150,
      ellipsis: true,
      align: 'left',
      className: 'max-width-column',
      render: (_, record) =>
        formatDate(record?.validFrom, DATE_FORMATS?.REGULAR) || '-',
    },
    {
      title: 'EXPIRE ON',
      dataIndex: 'validTo',
      key: 'validTo',
      width: 150,
      ellipsis: true,
      align: 'left',
      className: 'max-width-column',
      render: (_, record) =>
        formatDate(record?.validTo, DATE_FORMATS?.REGULAR) || '-',
    },
    {
      title: 'AMOUNT / PERCENTAGE',
      dataIndex: 'value',
      key: 'value',
      ellipsis: true,
      width: 150,
      align: 'left',
      className: 'max-width-column',
      render: (_, record) =>
        `${record?.value}${
          record?.type === COUPON_TYPE?.PERCENT?.value ? '%' : ''
        }`,
    },
    {
      title: 'USED BY',
      dataIndex: 'usedBy',
      key: 'usedBy',
      ellipsis: true,
      width: 100,
      onCell: (record) => ({
        onClick: (e) => {
          e?.stopPropagation();
          if (record?.usedCouponCount > 0) {
            setCoupon(record);
            setIsUsedByModalOpen(true);
          }
        },
        className: `${record?.usedCouponCount > 0 ? 'used-by' : ''}`,
      }),
      render: (_, record) => <span>{record?.usedCouponCount}</span>,
    },
    {
      title: 'DESCRIPTION',
      dataIndex: 'description',
      key: 'description',
      ellipsis: true,
      width: 150,
      align: 'left',
      className: 'max-width-column table-tooltip-cell',
      onCell: () => ({
        onClick: (e) => e?.stopPropagation(),
      }),
      render: (_, record) => (
        <Tooltip
          title={record?.description ? record?.description : 'No Description'}
          placement="topLeft"
          trigger="click"
          className="pointer"
        >
          {record?.description || '-'}
        </Tooltip>
      ),
    },
    {
      title: 'NOTE',
      dataIndex: 'note',
      key: 'note',
      ellipsis: true,
      width: 150,
      align: 'left',
      className: 'max-width-column table-tooltip-cell',
      onCell: () => ({
        onClick: (e) => e?.stopPropagation(),
      }),
      render: (_, record) => (
        <Tooltip
          title={record?.note ? record?.note : 'No Note'}
          placement="topLeft"
          trigger="click"
          className="pointer"
        >
          {record?.note || '-'}
        </Tooltip>
      ),
    },
    {
      title: 'CREATED AT',
      dataIndex: 'createdAt',
      key: 'createdAt',
      width: 150,
      ellipsis: true,
      align: 'left',
      className: 'max-width-column',
      render: (_, record) =>
        formatDate(record?.createdAt, DATE_FORMATS?.REGULAR) || '-',
      ...filterPopup('createdAt'),
    },
    {
      title: 'TYPE',
      dataIndex: 'type',
      key: 'type',
      ellipsis: true,
      width: 150,
      align: 'left',
      className: 'max-width-column',
      render: (_, record) => COUPON_TYPE?.[record?.type]?.label,
      ...filterPopup('type'),
    },
    {
      title: 'STATUS',
      dataIndex: 'status',
      key: 'status',
      ellipsis: true,
      width: 150,
      align: 'left',
      className: 'max-width-column',
      render: (_, record) => (
        <Badge
          color={
            record?.isActive ? STATUS?.ACTIVE?.color : STATUS?.INACTIVE?.color
          }
          text={
            record?.isActive ? STATUS?.ACTIVE?.label : STATUS?.INACTIVE?.label
          }
        />
      ),
      ...filterPopup('status'),
    },
    {
      title: 'ACTIONS',
      dataIndex: 'actions',
      key: 'actions',
      ellipsis: true,
      width: 150,
      onCell: () => ({
        onClick: (e) => e?.stopPropagation(),
      }),
      render: (_, record) => (
        <>
          <div className="action-button">
            <Tooltip
              title={record?.isActive ? 'Active' : 'Inactive'}
              placement="top"
              zIndex={4}
            >
              <ActiveIcon
                onClick={() => {
                  setIsActivePrompts(true);
                  setCoupon(record);
                }}
                className={
                  record?.isActive
                    ? 'mr-16 pointer svg-icon'
                    : 'mr-16 pointer svg-icon-grey'
                }
              />
            </Tooltip>
            <Tooltip title="Edit" placement="top" zIndex={4}>
              <EditIcon
                onClick={() => {
                  setCoupon(record);
                  setIsCouponModalOpen(true);
                }}
                className="mr-16 pointer svg-icon"
              />
            </Tooltip>
            <Tooltip title="Archive" placement="top" zIndex={4}>
              <ArchiveIcon
                onClick={() => {
                  setIsArchivePrompts(true);
                  setCoupon(record);
                }}
                className="mr-16 pointer svg-icon"
              />
            </Tooltip>
            <div className="mr-16">
              <Button
                type="primary"
                size="small"
                className="table-action-primary-btn"
                onClick={() => {
                  setIsShareModalOpen(true);
                  handleShare(record?.code);
                }}
              >
                Referral Link
              </Button>
            </div>
            <div className="mr-16">
              {record?.referralCouponType?.includes(REFERRAL_TYPE?.USER) ? (
                <Tag color="magenta">Referral User Coupon</Tag>
              ) : (
                <Button
                  type="primary"
                  size="small"
                  className="table-action-primary-btn"
                  onClick={() => {
                    setIsRequestPrompts(true);
                    setCoupon(record);
                    setReferralType(REFERRAL_TYPE?.USER);
                  }}
                >
                  Mark as User Referral
                </Button>
              )}
            </div>
            {record?.referralCouponType?.includes(REFERRAL_TYPE?.LIBRARY) ? (
              <Tag color="magenta">Referral Library Coupon</Tag>
            ) : (
              <Button
                type="primary"
                size="small"
                className="table-action-primary-btn"
                onClick={() => {
                  setIsRequestPrompts(true);
                  setCoupon(record);
                  setReferralType(REFERRAL_TYPE?.LIBRARY);
                }}
              >
                Mark as Library Referral
              </Button>
            )}
          </div>
        </>
      ),
    },
  ]?.filter((item) => {
    if (item?.dataIndex === 'actions' && !hasUpdatePermission) {
      return item?.hidden;
    }
    return !item?.hidden;
  });

  const locale = {
    emptyText: isEmptyCouponList ? '' : <span />,
  };

  return (
    <>
      <Modal
        title="Caution"
        okText="Yes"
        cancelText="No"
        open={isRequestPrompts}
        onOk={handleMarkAsReferral}
        onCancel={() => setIsRequestPrompts(false)}
        okButtonProps={{ loading: isRequestLoadings }}
      >
        Are you sure you want to
        <strong>
          {referralType === REFERRAL_TYPE?.USER
            ? ' Mark as User Referral '
            : ' Mark as Library Referral '}
        </strong>
        this?
      </Modal>
      <Modal
        title="Caution"
        okText="Yes"
        cancelText="No"
        open={isActivePrompts}
        onOk={() => handleActive(coupon?.id, coupon?.isActive)}
        onCancel={() => setIsActivePrompts(false)}
        okButtonProps={{ loading: isActiveLoading }}
      >
        Are you sure you want to
        <strong>
          {' '}
          {!coupon?.isActive
            ? STATUS?.ACTIVE?.label
            : STATUS?.INACTIVE?.label}{' '}
        </strong>
        this coupon?
      </Modal>
      <Modal
        title="Caution"
        okText="Yes"
        cancelText="No"
        open={isArchivePrompts}
        onOk={() => handleArchive(coupon?.id, coupon?.isArchived)}
        onCancel={() => setIsArchivePrompts(false)}
        okButtonProps={{ loading: isArchiveLoading }}
      >
        Are you sure you want to
        <strong>
          {' '}
          {!coupon?.isArchived
            ? ARCHIVING_STATE?.ARCHIVED
            : ARCHIVING_STATE?.UNARCHIVED}{' '}
        </strong>
        this coupon?
      </Modal>
      <ShareModal
        title="Share"
        isModalOpen={isShareModalOpen}
        setIsModalOpen={setIsShareModalOpen}
        isShareLoading={isShareLoading}
        setIsShareLoading={setIsShareLoading}
        shareTitle={shareTitle}
        shareUrl={shareUrl}
      />
      <CouponModal
        form={form}
        onFinish={handleCoupon}
        loadings={isCouponBtnLoading}
        isModalOpen={isCouponModalOpen}
        setIsModalOpen={setIsCouponModalOpen}
        coupon={coupon}
        setCoupon={setCoupon}
        isDisabled={isDisabled}
        setIsDisabled={setIsDisabled}
      />
      <UsedByModal
        isModalOpen={isUsedByModalOpen}
        setIsModalOpen={setIsUsedByModalOpen}
        couponId={coupon?.id}
      />
      <div className="d-flex justify-end mb-16 flex-wrap">
        {hasUpdatePermission && (
          <div className="mr-16 d-flex align-center">
            <h4 className="m-0 mr-8">Only Archived</h4>{' '}
            <Switch
              size="small"
              onChange={(checked) => setIsArchiveFilter(checked)}
            />
          </div>
        )}
        <SearchComponent
          className="search-box"
          query={query}
          setQuery={setQuery}
          getData={handleSearch}
        />
      </div>
      <CommonTable
        locale={locale}
        columns={columns}
        data={couponList || []}
        loading={isCouponLoading}
        onChange={handleTableChange}
        paginationConfig={paginationProp}
        rowKey={(record) => record?.id}
        rowClassName="pointer table-cell"
        onRow={(record) => ({
          onClick: () => {
            history?.push(
              `${ROUTES?.OFFERS}${ROUTES?.COUPON}/view/${record?.id}`,
            );
          },
        })}
      />
    </>
  );
};

export default CouponTable;
