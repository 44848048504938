import React, { useContext } from 'react';
import { Route, Switch } from 'react-router-dom';
import AccessControl from '../../../../AccessControl';
import { MENU_ITEMS } from '../../../../app/components/sidebar/Sidebar';
import { AppContext } from '../../../../AppContext';
import { MODULES, PERMISSIONS_KEY, ROUTES } from '../../../../common/constants';
import { getRedirectSubmenuItem } from '../../../../common/utils';
import Error404 from '../../../../Error404';
import RedirectControl from '../../../../RedirectControl';
import './endUser.less';
import Address from './pages/Address';
import Encashment from './pages/Encashment';
import ReportDamage from './pages/ReportDamage';
import SuggestedToy from './pages/SuggestedToy';

const tabs = MENU_ITEMS?.find(
  (menuItem) => menuItem?.key === MODULES?.REQUESTS,
)?.subMenu?.find((menu) => menu?.key === MODULES?.END_USER)?.tabKeys;

const EndUserWrapper = () => {
  const {
    state: { permissions },
  } = useContext(AppContext);

  const redirectArray = getRedirectSubmenuItem(permissions, tabs);

  return (
    <Switch>
      <Route
        exact
        path={`${ROUTES?.REQUESTS}${ROUTES?.END_USER}`}
        render={() => (
          <RedirectControl
            redirectArray={redirectArray}
            route={`${ROUTES?.REQUESTS}${ROUTES?.END_USER}`}
          />
        )}
      />
      <Route
        exact
        path={`${ROUTES?.REQUESTS}${ROUTES?.END_USER}${ROUTES?.SUGGESTED_TOY}`}
        render={(props) => (
          <AccessControl
            permissionKey={PERMISSIONS_KEY?.REQUESTS_END_USER_SUGGESTED_TOY}
          >
            <SuggestedToy {...props} />
          </AccessControl>
        )}
      />
      <Route
        exact
        path={`${ROUTES?.REQUESTS}${ROUTES?.END_USER}${ROUTES?.CHANGE_ADDRESS}`}
        render={(props) => (
          <AccessControl
            permissionKey={PERMISSIONS_KEY?.REQUESTS_END_USER_CHANGE_ADDRESS}
          >
            <Address {...props} />
          </AccessControl>
        )}
      />
      <Route
        exact
        path={`${ROUTES?.REQUESTS}${ROUTES?.END_USER}${ROUTES?.REPORT_DAMAGE}`}
        render={(props) => (
          <AccessControl
            permissionKey={PERMISSIONS_KEY?.REQUESTS_END_USER_REPORT_DAMAGE}
          >
            <ReportDamage {...props} />{' '}
          </AccessControl>
        )}
      />
      <Route
        exact
        path={`${ROUTES?.REQUESTS}${ROUTES?.END_USER}${ROUTES?.ENCASHMENT}`}
        render={(props) => (
          <AccessControl
            permissionKey={PERMISSIONS_KEY?.REQUESTS_END_USER_ENCASHMENT}
          >
            <Encashment {...props} />
          </AccessControl>
        )}
      />
      <Route path="*" exact component={Error404} />
    </Switch>
  );
};

export default EndUserWrapper;
