import { gql } from '@apollo/client';

// eslint-disable-next-line import/prefer-default-export
export const CREATE_TOY = gql`
  mutation CreateToy($data: CreateToyInput!) {
    createToy(data: $data) {
      message
    }
  }
`;

export const UPDATE_TOY = gql`
  mutation UpdateToy($where: ToyUniqueInput!, $data: UpdateToyInput!) {
    updateToy(where: $where, data: $data) {
      message
    }
  }
`;

export const REQUEST_TOY_EXPORT = gql`
  mutation RequestToyExport(
    $data: RequestExportInput!
    $filter: RequestToyExportFilterInput
  ) {
    requestToyExport(data: $data, filter: $filter) {
      message
    }
  }
`;

export const REQUEST_TOY_DATA_EXPORT = gql`
mutation RequestToyBulkUploadReportExport(
  $data: RequestExportInput!
) {
  requestToyBulkUploadReportExport(data: $data) {
    message
  }
}
`;

export const TOY_BULK_UPDATE = gql`
mutation ToyBulkUpdate($data: ToyBulkUpdateInput!) {
  toyBulkUpdate(data: $data) {
    key
    message
  }
}`