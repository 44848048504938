import { gql } from '@apollo/client';

// eslint-disable-next-line import/prefer-default-export
export const CREATE_BRAND = gql`
  mutation CreateBrand($data: CreateBrandInput!) {
    createBrand(data: $data) {
      message
    }
  }
`;

export const UPDATE_BRAND = gql`
  mutation UpdateBrand($where: BrandUniqueInput!, $data: UpdateBrandInput) {
    updateBrand(where: $where, data: $data) {
      message
    }
  }
`;
