import { gql } from '@apollo/client';

// eslint-disable-next-line import/prefer-default-export
export const CREATE_AGE_GROUP = gql`
  mutation CreateAgeGroup($data: CreateAgeGroupInput!) {
    createAgeGroup(data: $data) {
      message
    }
  }
`;

export const UPDATE_AGE_GROUP = gql`
  mutation UpdateAgeGroup(
    $where: AgeGroupUniqueInput!
    $data: UpdateAgeGroupInput
  ) {
    updateAgeGroup(where: $where, data: $data) {
      message
    }
  }
`;
