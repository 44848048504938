import { useLazyQuery } from '@apollo/client';
import { Card, Form } from 'antd';
import dayjs from 'dayjs';
import React, { useEffect, useState } from 'react';
import { ROUTES } from '../../../../../common/constants';
import GoBackButton from '../../../../../components/GoBackButton';
import LoaderComponent from '../../../../../components/LoaderComponent';
import Portal from '../../../../../components/Portal';
import CouponForm from '../components/CouponForm';
import { COUPON } from '../graphql/Queries';

const ViewCoupon = (props) => {
  const { match: { params: { id } = {} } = {} } = props;
  const [form] = Form.useForm();
  const [couponDetail, setGiftCardDetail] = useState({});
  const [isCouponLoading, setIsCouponLoading] = useState(true);

  const [coupon] = useLazyQuery(COUPON, {
    onCompleted: (response) => {
      setGiftCardDetail(response?.coupon?.data);
      setIsCouponLoading(false);
    },
    fetchPolicy: 'network-only',
    onError() {},
  });

  useEffect(() => {
    setIsCouponLoading(true);
    setGiftCardDetail([]);
    coupon({
      variables: {
        where: {
          id,
        },
      },
    });
  }, []);

  const initialValues = {
    ...couponDetail,
    redemptions: couponDetail?.maxRedemptions,
    price: couponDetail?.value,
    expiresOn: dayjs(couponDetail?.expiredAt),
    startsOn: dayjs(couponDetail?.startedAt),
    libraryId: couponDetail?.library?.id,
    orderValue: couponDetail?.metaData?.minimumAmount,
    duration: couponDetail?.metaData?.duration,
    plan: couponDetail?.metaData?.product?.id,
  };

  return (
    <>
      <Portal portalId="header-title-content">
        <GoBackButton customLink={`${ROUTES?.OFFERS}${ROUTES?.COUPON}`} />
        <div className="portal-header">View Coupon</div>
      </Portal>
      <Card className="full-height-with-nav" title="Coupon Details">
        <div className="toy-form">
          {isCouponLoading ? (
            <LoaderComponent
              size="large"
              setHeight="60"
              spinning={isCouponLoading}
            />
          ) : (
            <div className="admin-form">
              <CouponForm
                form={form}
                initialValues={initialValues}
                isView
                couponDetail={couponDetail}
              />
            </div>
          )}
        </div>
      </Card>
    </>
  );
};

export default ViewCoupon;
