import { useLazyQuery } from '@apollo/client';
import { Button, Card, Form } from 'antd';
import React, { useContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { AppContext } from '../../../../../AppContext';
import {
  DATE_FORMATS,
  PERMISSIONS_KEY,
  PERMISSION_TYPE,
  ROUTES,
} from '../../../../../common/constants';
import { formatDate, hasPermission } from '../../../../../common/utils';
import GoBackButton from '../../../../../components/GoBackButton';
import LoaderComponent from '../../../../../components/LoaderComponent';
import Portal from '../../../../../components/Portal';
import { USER } from '../../../graphql/Queries';
import DeliveryForm from '../components/DeliveryForm';

const ViewAdmin = (props) => {
  const { match: { params: { id } = {} } = {} } = props;
  const {
    state: { permissions },
  } = useContext(AppContext);

  const [form] = Form.useForm();
  const history = useHistory();
  const [deliveryDetail, setDeliveryDetail] = useState({});
  const [isDeliveryLoading, setIsDeliveryLoading] = useState(true);

  const [users] = useLazyQuery(USER, {
    onCompleted: (response) => {
      setDeliveryDetail(response?.user);
      setIsDeliveryLoading(false);
    },
    fetchPolicy: 'network-only',
    onError() {},
  });

  useEffect(() => {
    setIsDeliveryLoading(true);
    setDeliveryDetail([]);
    users({
      variables: {
        where: {
          id,
        },
      },
    });
  }, []);

  const initialValues = {
    ...deliveryDetail,
    contact: deliveryDetail?.contactNo,
    status: deliveryDetail?.isActive,
    state: deliveryDetail?.state,
    city: deliveryDetail?.city,
    pincode: deliveryDetail?.pincode?.pincode,
  };

  return (
    <>
      <Portal portalId="header-title-content">
        <GoBackButton
          customLink={`${ROUTES?.SYSTEM_USERS}${ROUTES?.DELIVERY_PARTNERS}`}
        />
        <div className="portal-header">View Delivery Partner</div>
      </Portal>
      <Portal portalId="header-right-content">
        {hasPermission(
          PERMISSIONS_KEY?.SYSTEM_USERS_DELIVERY_PARTNERS,
          PERMISSION_TYPE?.UPDATE,
          permissions,
        ) && (
          <div className="header-btn-wrapper">
            <Button
              className="common-button mr-16"
              size="middle"
              onClick={() =>
                history?.push(
                  `${ROUTES?.SYSTEM_USERS}${ROUTES?.DELIVERY_PARTNERS}/edit/${id}`,
                )
              }
              disabled={deliveryDetail?.isArchived}
            >
              Edit Delivery Partner
            </Button>
          </div>
        )}
      </Portal>
      <Portal portalId="header-right-content-phones">
        {hasPermission(
          PERMISSIONS_KEY?.SYSTEM_USERS_DELIVERY_PARTNERS,
          PERMISSION_TYPE?.UPDATE,
          permissions,
        ) && (
          <div className="header-btn-wrapper">
            <Button
              className="common-button mr-16"
              size="middle"
              onClick={() =>
                history?.push(
                  `${ROUTES?.SYSTEM_USERS}${ROUTES?.DELIVERY_PARTNERS}/edit/${id}`,
                )
              }
              disabled={deliveryDetail?.isArchived}
            >
              Edit Delivery Partner
            </Button>
          </div>
        )}
      </Portal>
      <Card
        className="full-height-with-nav"
        title="Delivery Partner Details"
        extra={
          <strong>
            Onboarded On :{' '}
            {formatDate(deliveryDetail?.createdAt, DATE_FORMATS?.ONBOARD) ||
              '-'}
          </strong>
        }
      >
        {isDeliveryLoading ? (
          <LoaderComponent
            size="large"
            setHeight="60"
            spinning={isDeliveryLoading}
          />
        ) : (
          <div className="delivery-form">
            <DeliveryForm
              form={form}
              initialValues={initialValues}
              isView
              deliveryDetail={deliveryDetail}
            />
          </div>
        )}
      </Card>
    </>
  );
};

export default ViewAdmin;
