import { gql } from '@apollo/client';

// eslint-disable-next-line import/prefer-default-export
export const CREATE_RACK = gql`
  mutation CreateRack($data: CreateRackInput!) {
    createRack(data: $data) {
      message
    }
  }
`;

export const UPDATE_RACK = gql`
  mutation UpdateRack($where: RackUniqueInput!, $data: UpdateRackInput) {
    updateRack(where: $where, data: $data) {
      message
    }
  }
`;
