import { Tabs } from 'antd';
import React, { useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { ROUTES, TABS } from '../../../../../common/constants';

const ForestTabs = ({ children }) => {
  const history = useHistory();
  const location = useLocation();
  const [activeTabKey, setActiveTabKey] = useState(TABS?.MONTHLY?.key);

  const tabList = [
    {
      key: TABS?.QUARTERLY?.key,
      label: TABS?.QUARTERLY?.label,
      children,
    },
    {
      key: TABS?.SEMI_ANNUAL?.key,
      label: TABS?.SEMI_ANNUAL?.label,
      children,
    },
    {
      key: TABS?.ANNUALLY?.key,
      label: TABS?.ANNUALLY?.label,
      children,
    },
  ];

  useEffect(() => {
    if (location?.pathname?.includes(ROUTES?.QUARTERLY)) {
      setActiveTabKey(TABS?.QUARTERLY?.key);
    }
    if (location?.pathname?.includes(ROUTES?.SEMI_ANNUAL)) {
      setActiveTabKey(TABS?.SEMI_ANNUAL?.key);
    }
    if (location?.pathname?.includes(ROUTES?.ANNUALLY)) {
      setActiveTabKey(TABS?.ANNUALLY?.key);
    }
  }, [location]);

  const onTabChange = (key) => {
    setActiveTabKey(key);
    switch (key) {
      case TABS?.QUARTERLY?.key:
        if (location?.pathname?.includes('/edit')) {
          history?.push(
            `${ROUTES?.SUBSCRIPTION_PLANS}${ROUTES?.FOREST}${ROUTES?.QUARTERLY}/edit`,
          );
        } else {
          history?.push(
            `${ROUTES?.SUBSCRIPTION_PLANS}${ROUTES?.FOREST}${ROUTES?.QUARTERLY}`,
          );
        }
        break;
      case TABS?.SEMI_ANNUAL?.key:
        if (location?.pathname?.includes('/edit')) {
          history?.push(
            `${ROUTES?.SUBSCRIPTION_PLANS}${ROUTES?.FOREST}${ROUTES?.SEMI_ANNUAL}/edit`,
          );
        } else {
          history?.push(
            `${ROUTES?.SUBSCRIPTION_PLANS}${ROUTES?.FOREST}${ROUTES?.SEMI_ANNUAL}`,
          );
        }
        break;
      case TABS?.ANNUALLY?.key:
        if (location?.pathname?.includes('/edit')) {
          history?.push(
            `${ROUTES?.SUBSCRIPTION_PLANS}${ROUTES?.FOREST}${ROUTES?.ANNUALLY}/edit`,
          );
        } else {
          history?.push(
            `${ROUTES?.SUBSCRIPTION_PLANS}${ROUTES?.FOREST}${ROUTES?.ANNUALLY}`,
          );
        }
        break;
      default:
        break;
    }
  };

  return (
    <>
      <div className="card-container">
        <Tabs
          activeKey={activeTabKey}
          type="card"
          className="full-width"
          onChange={(key) => onTabChange(key)}
          items={tabList}
          destroyInactiveTabPane
        />
      </div>
    </>
  );
};

export default ForestTabs;
