import { Empty } from 'antd';
import React from 'react';
import LoaderComponent from '../../../components/LoaderComponent';
import InvoiceCard from './InvoiceCard';

const InvoiceList = ({
  invoiceList,
  handleDownload,
  infiniteScrollRef,
  isFetchMoreInvoicesLoading,
  isEmptyInvoiceList,
  isInvoiceLoading,
}) => (
  <div>
    {!isEmptyInvoiceList &&
      invoiceList?.map((invoice) => (
        <InvoiceCard
          key={invoice?.id}
          invoice={invoice}
          handleDownload={handleDownload}
          infiniteScrollRef={infiniteScrollRef}
        />
      ))}
    {(isFetchMoreInvoicesLoading || isInvoiceLoading) && (
      <LoaderComponent size="small" setHeight={10} />
    )}
    {isEmptyInvoiceList && <Empty image={Empty?.PRESENTED_IMAGE_SIMPLE} />}
  </div>
);

export default InvoiceList;
