import { LockOutlined } from '@ant-design/icons';
import { useMutation } from '@apollo/client';
import { Button, Form, Input } from 'antd';
import React, { useState } from 'react';
import { formValidatorRules } from '../../../common/utils';
import ModalComponent from '../../../components/ModalComponent';
import { CHANGE_PASSWORD } from '../../auth/graphql/Mutations';

const { required, password } = formValidatorRules;

const ChangePasswordModal = ({ visible, setVisible }) => {
  const [form] = Form.useForm();
  const [validationTriggered, setValidationTriggered] = useState(false);
  const [buttonLoading, setButtonLoading] = useState(false);
  const [changePasswordMutate] = useMutation(CHANGE_PASSWORD, {
    onCompleted() {
      setVisible(false);
      setButtonLoading(false);
    },
    // write onError for handing errors
    onError() {
      setButtonLoading(false);
    },
  });

  const handleCancel = () => {
    setVisible(false);
    form?.resetFields();
  };

  const onFinish = async ({
    oldPassword,
    password: newPassword,
    confirmPassword,
  }) => {
    setButtonLoading(true);
    const res = await changePasswordMutate({
      variables: {
        data: {
          oldPassword,
          password: newPassword,
          confirmPassword,
        },
      },
    });
    if (res?.data) {
      setVisible(false);
      form?.resetFields();
    }
  };

  const onFinishFailed = () => {
    setValidationTriggered(true);
  };

  return (
    <ModalComponent
      title="Change Password"
      open={visible}
      onOk={form?.submit}
      onCancel={handleCancel}
      wrapClassName="change-password-modal"
      footer={[
        <div className="d-flex button-section" key="change-password">
          <Button
            loading={buttonLoading}
            type="primary"
            htmlType="submit"
            className="save-btn mr-2 common-button"
            size="middle"
            onClick={form?.submit}
          >
            Change Password
          </Button>
          <Button
            className="cancel-btn"
            onClick={() => {
              setVisible(false);
              form?.resetFields();
            }}
          >
            Cancel
          </Button>
        </div>,
      ]}
    >
      <Form
        name="change-password"
        form={form}
        validateTrigger={validationTriggered ? 'onChange' : 'onSubmit'}
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        autoComplete="off"
      >
        <Form.Item
          name="oldPassword"
          rules={[{ ...required, message: 'Please Enter Old Password!' }]}
          normalize={(value) => value?.split(' ')?.join('')}
        >
          <Input.Password
            className="password-input"
            prefix={<LockOutlined className="icon-color" />}
            placeholder="Old Password"
          />
        </Form.Item>
        <Form.Item
          name="password"
          rules={[{ ...required, message: 'Please Enter Password!' }, password]}
          normalize={(value) => value?.split(' ')?.join('')}
        >
          <Input.Password
            className="password-input"
            prefix={<LockOutlined className="icon-color" />}
            placeholder="Password"
          />
        </Form.Item>
        <Form.Item
          name="confirmPassword"
          dependencies={['password']}
          normalize={(value) => value?.split(' ')?.join('')}
          rules={[
            { ...required, message: 'Please Enter Confirm Password!' },
            ({ getFieldValue }) => ({
              validator(_, value) {
                if (value && value !== getFieldValue('password')) {
                  return Promise?.reject(
                    new Error(
                      'The two passwords that you entered do not match!',
                    ),
                  );
                }
                return Promise?.resolve();
              },
            }),
          ]}
        >
          <Input.Password
            className="password-input"
            prefix={<LockOutlined className="icon-color" />}
            placeholder="Confirm password"
          />
        </Form.Item>
      </Form>
    </ModalComponent>
  );
};

export default ChangePasswordModal;
