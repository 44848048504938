import { Button, Divider, Form, Input, Upload } from 'antd';
import React, { useEffect, useState } from 'react';
import {
  INVOICE_REASON,
  NUMBER_FIELD_TYPES,
} from '../../../../../common/constants';
import { formValidatorRules } from '../../../../../common/utils';
import ModalComponent from '../../../../../components/ModalComponent';
import NumberComponent from '../../../../../components/NumberComponent';
import SelectComponent from '../../../../../components/SelectComponent';
import LibrarySelect from '../../../../lists/modules/library/components/LibrarySelect';

const { Item } = Form;
const { price } = formValidatorRules;

const InvoiceModal = ({
  isModalOpen,
  setIsModalOpen,
  onFinish,
  loadings,
  form,
  invoiceFile,
  setInvoiceFile,
  invoice,
  setInvoice,
  isEditMode,
}) => {
  const [isValidateTrigger, setIsValidateTrigger] = useState(false);

  const handleCancel = () => {
    setIsModalOpen(false);
    setInvoice({});
  };

  const handleChange = (info) => {
    setInvoiceFile(info?.fileList);
  };

  useEffect(() => {
    const initialValues = {
      ...invoice,
      libraryName: invoice?.library?.id,
    };
    form?.setFieldsValue(initialValues);
    if (invoice?.attachment) {
      setInvoiceFile([{ name: invoice?.attachment, url: invoice?.attachment }]);
    }
  }, [invoice]);

  useEffect(() => {
    if (!isEditMode) {
      form?.resetFields();
      setIsValidateTrigger(false);
      setInvoice();
      setInvoiceFile([]);
    }
  }, [isEditMode, isModalOpen]);

  return (
    <>
      <ModalComponent
        open={isModalOpen}
        onCancel={handleCancel}
        onOk={form?.submit}
        title={isEditMode ? 'Edit Invoice' : 'Upload Invoice'}
        okText="Save"
        confirmLoading={loadings}
        destroyOnClose
        forceRender
      >
        <Divider />
        <Form
          form={form}
          onFinish={(values) => {
            onFinish(values);
            setIsValidateTrigger(true);
          }}
          autoComplete="off"
          layout="vertical"
          validateTrigger={isValidateTrigger ? 'onChange' : 'onSubmit'}
          onFinishFailed={() => setIsValidateTrigger(true)}
          className="invoice-form"
        >
          {isEditMode && (
            <Item label="Invoice Number" name="invoiceNumber">
              <Input allowClear disabled />
            </Item>
          )}
          <Item
            label="Library Name"
            name="libraryName"
            rules={[{ required: true, message: 'Please Select Library Name!' }]}
          >
            <LibrarySelect
              className="full-width"
              libraryData={invoice?.library}
              disabled={isEditMode}
              isModalOpen={isModalOpen}
            />
          </Item>
          <Item
            label="Reason"
            name="reason"
            rules={[{ required: true, message: 'Please Select Reason!' }]}
          >
            <SelectComponent
              options={INVOICE_REASON}
              placeholder="Select Reason"
              filterOption={(input, option) =>
                (option?.label ?? '')
                  ?.toLowerCase()
                  ?.includes(input?.toLowerCase())
              }
            />
          </Item>
          <Item
            label="Amount"
            name="amount"
            rules={[{ required: true, message: 'Please Enter Amount!' }, price]}
          >
            <NumberComponent
              className="full-width hide-control"
              placeholder="Enter Amount"
              type={NUMBER_FIELD_TYPES?.PRICE}
            />
          </Item>
          <Item label="Attachment" name="invoiceFile" required>
            <Upload
              maxCount={1}
              fileList={invoiceFile}
              beforeUpload={() => false}
              onChange={handleChange}
              className="attachment"
            >
              {invoiceFile?.length === 0 && (
                <>
                  <Button
                    className={`full-width ${
                      isValidateTrigger &&
                      invoiceFile?.length === 0 &&
                      'upload-error'
                    }`}
                  >
                    Add Attachment
                  </Button>
                  {isValidateTrigger && (
                    <span className="upload-error">Please add attachment!</span>
                  )}
                </>
              )}
            </Upload>
          </Item>
        </Form>
      </ModalComponent>
    </>
  );
};

export default InvoiceModal;
