import { gql } from '@apollo/client';

// eslint-disable-next-line import/prefer-default-export
export const ASSIGN_LIBRARY_TO_PINCODE = gql`
  mutation AssignLibraryToPincode(
    $where: PincodeUniqueInput!
    $data: AssignLibraryToPincodeInput
  ) {
    assignLibraryToPincode(where: $where, data: $data) {
      message
    }
  }
`;

export const REQUEST_PINCODE_EXPORT = gql`
  mutation RequestPincodeExport(
    $data: RequestExportInput!
    $filter: RequestPincodeExportFilterInput
  ) {
    requestPincodeExport(data: $data, filter: $filter) {
      message
    }
  }
`;
