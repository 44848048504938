import { gql } from '@apollo/client';

// eslint-disable-next-line import/prefer-default-export
export const STORY_ADMIN = gql`
  query storyAdmin($filter: StoryFilter, $sort: StorySort) {
    storyAdmin(filter: $filter, sort: $sort) {
      count
      data {
        id
        description
        createdAt
        name
        type
      }
    }
  }
`;

export const VIDEOS_STORYS_ADMIN = gql`
  query VideosStoryAdmin($where: StoryUniqueInput!, $filter: StoryVideosFilter, $sort: StoryVideosSort) {
    videosStoryAdmin(where: $where,filter: $filter, sort: $sort) {
      count
      data {
        createdAt
        description
        id
        key
        thumbnailKey
        metaData {
          durationInSeconds
          extension
          height
          sizeInMb
          width
        }
        thumbnailmetaData {
          durationInSeconds
          extension
          height
          sizeInMb
          width
        }
        status
        type
        url
        thumbnailUrl
        toy {
          id
          toyNumber
          name
        }
      }
    }
  }
`;

export const GET_VIDEO_SIGNED_PUT_URL = gql`
  query GetVideoSignedPutUrl($data: GetVideoSignedPutUrlInput!) {
    getVideoSignedPutUrl(data: $data) {
      fileName
      signedUrl
    }
  }
`;

// eslint-disable-next-line import/prefer-default-export
export const TOYS = gql`
  query Toys($filter: ToysFilter, $sort: ToysSort) {
    toys(filter: $filter, sort: $sort) {
      count
      data {
        createdAt
        isArchived
        toyNumber
        ageGroups {
          id
          maxAge
          minAge
        }
        brand {
          id
          name
        }
        categories {
          id
          name
        }
        tags {
          id
          name
        }
        genres{
          id
          name
        }
        facilitates {
          id
          name
        }
        description
        images {
          url
          size
          order
          key
          contentType
        }
        material {
          id
          name
        }
        name
        id
      }
    }
  }
`;
