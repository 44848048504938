import { ExportOutlined } from '@ant-design/icons';
import { useMutation } from '@apollo/client';
import { Button, Form } from 'antd';
import React, { useContext, useState } from 'react';
import { AppContext } from '../../../../../AppContext';
import {
  EXPORT_TYPES,
  MODULES,
  PERMISSIONS_KEY,
  PERMISSION_TYPE,
  TRANSACTION_HISTORY_TYPES,
} from '../../../../../common/constants';
import { hasPermission } from '../../../../../common/utils';
import ExportDateRangeModal from '../../../../../components/ExportDateRangeModal';
import Portal from '../../../../../components/Portal';
import EndUserTabs from '../components/EndUserTabs';
import OutflowTable from '../components/OutflowTable';
import { REQUEST_TRANSACTION_EXPORT } from '../graphql/Mutations';

const transactionTypes = [
  TRANSACTION_HISTORY_TYPES?.REFUND_DELIVERY_CHARGED?.value,
  TRANSACTION_HISTORY_TYPES?.REFUND_REQUEST?.value,
  TRANSACTION_HISTORY_TYPES?.SECURITY_DEPOSIT?.value,
];

const Outflow = () => {
  const {
    state: { permissions },
  } = useContext(AppContext);
  const [form] = Form.useForm();
  const [isOutflowModalOpen, setIsOutflowModalOpen] = useState(false);
  const [isEditMode, setIsEditMode] = useState(false);
  const [exportFilter, setExportFilter] = useState();
  const [isExportLoading, setIsExportLoading] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const [requestTransactionExport] = useMutation(REQUEST_TRANSACTION_EXPORT, {
    onError: () => {},
  });

  const handleExport = async (values) => {
    setIsExportLoading(true);
    const res = await requestTransactionExport({
      variables: {
        data: {
          type: EXPORT_TYPES?.OUTFLOW,
        },
        filter: {
          search: exportFilter?.search,
          dateRange: {
            from: values?.date?.[0]?.startOf('day'),
            to: values?.date?.[1]?.endOf('day'),
          },
          ...(exportFilter?.subscriptionStatus?.length > 0 && {
            subscriptionStatus: exportFilter?.subscriptionStatus,
          }),
          paidOnDateRange: {
            from: exportFilter?.paidOn?.from?.$d,
            to: exportFilter?.paidOn?.to?.$d,
          },
          status:
            exportFilter?.status?.length > 0 ? exportFilter?.status : undefined,
          transactionTypes:
            exportFilter?.description?.flat()?.length > 0
              ? exportFilter?.description?.flat()
              : transactionTypes,
          types:
            exportFilter?.billingCycle?.length > 0
              ? exportFilter?.billingCycle
              : undefined,
          names:
            exportFilter?.planSubscribed?.length > 0
              ? exportFilter?.planSubscribed
              : undefined,
        },
      },
    });
    if (res?.data) {
      setIsModalOpen(false);
      form?.resetFields();
    }
    setIsExportLoading(false);
  };

  return (
    <>
      <Portal portalId="header-title-content">
        <div className="portal-header">{MODULES?.END_USER}</div>
      </Portal>
      <Portal portalId="header-right-content">
        {hasPermission(
          PERMISSIONS_KEY?.PAYMENTS_END_USER_OUTFLOW,
          PERMISSION_TYPE?.CREATE,
          permissions,
        ) && (
          <Button
            className="common-button"
            size="middle"
            onClick={() => {
              setIsEditMode(false);
              setIsOutflowModalOpen(true);
            }}
          >
            New Refund Request
          </Button>
        )}
        <Button
          className="common-button ml-16"
          size="middle"
          icon={<ExportOutlined />}
          onClick={() => setIsModalOpen(true)}
          loading={isExportLoading}
        >
          Export
        </Button>
      </Portal>
      <Portal portalId="header-right-content-phones">
        {hasPermission(
          PERMISSIONS_KEY?.PAYMENTS_END_USER_OUTFLOW,
          PERMISSION_TYPE?.CREATE,
          permissions,
        ) && (
          <Button
            className="common-button"
            size="middle"
            onClick={() => {
              setIsEditMode(false);
              setIsOutflowModalOpen(true);
            }}
          >
            New Refund Request
          </Button>
        )}
        <Button
          className="common-button ml-16"
          size="middle"
          icon={<ExportOutlined />}
          onClick={() => setIsModalOpen(true)}
          loading={isExportLoading}
        >
          Export
        </Button>
      </Portal>
      <ExportDateRangeModal
        form={form}
        onFinish={handleExport}
        loadings={isExportLoading}
        isModalOpen={isModalOpen}
        setIsModalOpen={setIsModalOpen}
      />
      <EndUserTabs>
        <div className="tab-table">
          <OutflowTable
            isOutflowModalOpen={isOutflowModalOpen}
            setIsOutflowModalOpen={setIsOutflowModalOpen}
            isEditMode={isEditMode}
            setIsEditMode={setIsEditMode}
            exportFilter={exportFilter}
            setExportFilter={setExportFilter}
          />
        </div>
      </EndUserTabs>
    </>
  );
};

export default Outflow;
