import { Col, Divider, Form, Input, Row } from 'antd';
import { map } from 'lodash';
import React, { useEffect, useState } from 'react';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { UI_LABEL_GROUP, UI_LABEL_TYPE } from '../../../common/constants';
import { formValidatorRules, handleInput } from '../../../common/utils';
import ModalComponent from '../../../components/ModalComponent';
import SelectComponent from '../../../components/SelectComponent';

const { Item } = Form;
const { TextArea } = Input;
const { required, whitespaceNotAllowed, url } = formValidatorRules;

const UiLabelModal = ({
  title,
  uiLabelData,
  setUiLabelData,
  isModalOpen,
  setIsModalOpen,
  isBtnLoading,
  onFinish,
  form,
  type,
  setType,
}) => {
  const [validationTriggered, setValidationTriggered] = useState(false);

  useEffect(() => {
    if (uiLabelData) {
      const initialValues = {
        key: uiLabelData?.key,
        description: uiLabelData?.description,
        type: uiLabelData?.type,
        value: uiLabelData?.value,
        group: uiLabelData?.group,
      };
      form?.setFieldsValue(initialValues);
      setType(uiLabelData?.type);
    }
  }, [uiLabelData]);

  return (
    <>
      <ModalComponent
        title={title}
        okText="Save"
        cancelText="Cancel"
        open={isModalOpen}
        onOk={form?.submit}
        onCancel={() => {
          setIsModalOpen(false);
          setUiLabelData(null);
          setType(UI_LABEL_TYPE.STRING.value);
          form?.resetFields();
        }}
        confirmLoading={isBtnLoading}
        forceRender
      >
        <Divider />
        <Form
          form={form}
          layout="vertical"
          onFinish={onFinish}
          onFinishFailed={() => setValidationTriggered(true)}
          validateTrigger={validationTriggered ? 'onChange' : 'onSubmit'}
        >
          <Row gutter={[16, 0]}>
            <Col xs={24} sm={12}>
              <Item
                label="Key"
                name="key"
                rules={[{ ...required, message: 'Please Enter Key!' }]}
              >
                <Input
                  allowClear
                  placeholder="Enter Key"
                  maxLength={50}
                  onInput={(e) => handleInput(e, true)}
                />
              </Item>
            </Col>
            <Col xs={24} sm={12}>
              <Item
                label="Type"
                name="type"
                rules={[
                  {
                    required: true,
                    message: 'Please Select Type!',
                  },
                ]}
                initialValue={UI_LABEL_TYPE.STRING.value}
              >
                <SelectComponent
                  showSearch={false}
                  placeholder="Select Type"
                  allowClear={false}
                  options={map(UI_LABEL_TYPE, (typeDate) => typeDate)}
                  onChange={(value) => {
                    form?.setFieldsValue({ value: '' });
                    setType(value);
                  }}
                />
              </Item>
            </Col>
            <Col xs={24}>
              <Item
                label={`Value ${
                  type === UI_LABEL_TYPE.IMAGE.value ? '(URL)' : ''
                }`}
                name="value"
                rules={[
                  {
                    required: true,
                    message: 'Please Enter Value!',
                  },
                  type === UI_LABEL_TYPE.IMAGE.value && url,
                ]}
              >
                {type !== UI_LABEL_TYPE.HTML.value ? (
                  <Input allowClear placeholder="Enter Value" maxLength={700} />
                ) : (
                  <ReactQuill theme="snow" placeholder="Enter Value" />
                )}
              </Item>
            </Col>
            <Col xs={24}>
              <Item
                name="description"
                label="Description"
                rules={[whitespaceNotAllowed]}
              >
                <TextArea
                  rows={2}
                  allowClear
                  placeholder="Enter Description"
                  showCount
                  maxLength={700}
                />
              </Item>
            </Col>
            <Col xs={24}>
              <Item label="Group" name="group">
                <SelectComponent
                  showSearch={false}
                  placeholder="Select Group"
                  options={map(UI_LABEL_GROUP, (group) => group)}
                />
              </Item>
            </Col>
          </Row>
        </Form>
      </ModalComponent>
    </>
  );
};

export default UiLabelModal;
